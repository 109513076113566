// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CS_Make$Util = require("util/src/CustomScalars/CS_Make.bs.js");
var Common$Thick = require("../../utils/Common.bs.js");
var List = require("@mui/joy/List").default;
var Stack = require("@mui/joy/Stack").default;
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var Tooltip = require("@mui/joy/Tooltip").default;
var ListItem = require("@mui/joy/ListItem").default;
var JsxRuntime = require("react/jsx-runtime");
var IconButton = require("@mui/joy/IconButton").default;
var Typography = require("@mui/joy/Typography").default;
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var DialogContent = require("@mui/joy/DialogContent").default;
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var SquareRounded = require("@mui/icons-material/SquareRounded").default;
var BDVersionHistory_query_graphql$Thick = require("../../__generated__/BDVersionHistory_query_graphql.bs.js");
var KeyboardDoubleArrowRightOutlined = require("@mui/icons-material/KeyboardDoubleArrowRightOutlined").default;

var convertFragment = BDVersionHistory_query_graphql$Thick.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(BDVersionHistory_query_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, BDVersionHistory_query_graphql$Thick.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(BDVersionHistory_query_graphql$Thick.node, convertFragment, fRef);
}

var QueryFragment_lifecycleStatus_decode = BDVersionHistory_query_graphql$Thick.Utils.lifecycleStatus_decode;

var QueryFragment_lifecycleStatus_fromString = BDVersionHistory_query_graphql$Thick.Utils.lifecycleStatus_fromString;

var QueryFragment_versionProvenance_decode = BDVersionHistory_query_graphql$Thick.Utils.versionProvenance_decode;

var QueryFragment_versionProvenance_fromString = BDVersionHistory_query_graphql$Thick.Utils.versionProvenance_fromString;

var QueryFragment = {
  lifecycleStatus_decode: QueryFragment_lifecycleStatus_decode,
  lifecycleStatus_fromString: QueryFragment_lifecycleStatus_fromString,
  versionProvenance_decode: QueryFragment_versionProvenance_decode,
  versionProvenance_fromString: QueryFragment_versionProvenance_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

function BDVersionHistory$VersionHistory(props) {
  var onClose = props.onClose;
  var redirect = props.redirect;
  var content = props.content;
  var len = content.versionHistory.length;
  return JsxRuntime.jsx(DialogContent, {
              children: Caml_option.some(JsxRuntime.jsx(List, {
                        children: Caml_option.some(Belt_Array.mapWithIndex(content.versionHistory, (function (i, item) {
                                    var versionCreatorBfcustEmail = item.versionCreatorBfcustEmail;
                                    var versionCreatorBfcustName = item.versionCreatorBfcustName;
                                    var versionCreatorTeamUserEmail = item.versionCreatorTeamUserEmail;
                                    var versionProvenance = item.versionProvenance;
                                    var versionComment = item.versionComment;
                                    var name = item.name;
                                    var bookingVersionNumber = item.bookingVersionNumber;
                                    var tmp;
                                    if (versionProvenance === "PAY_IN_FULL_COMPLETION" || versionProvenance === "CLAIM_NEW_PASSENGER_INVITATION" || versionProvenance === "ACCOUNT_UPDATE" || versionProvenance === "BOOKING_CHANGE_INDIRECT" || versionProvenance === "BOOKING_CHANGE" || versionProvenance === "BOOKED" || versionProvenance === "MIGRATED") {
                                      switch (versionProvenance) {
                                        case "BOOKED" :
                                            tmp = "Booked by ";
                                            break;
                                        case "BOOKING_CHANGE" :
                                            tmp = "Booking modified by ";
                                            break;
                                        case "BOOKING_CHANGE_INDIRECT" :
                                            tmp = "Booking indirectly modified by ";
                                            break;
                                        case "ACCOUNT_UPDATE" :
                                            tmp = "Modified due to user account update by ";
                                            break;
                                        case "CLAIM_NEW_PASSENGER_INVITATION" :
                                            tmp = "Invitation claimed by ";
                                            break;
                                        case "PAY_IN_FULL_COMPLETION" :
                                            tmp = "Modified due to pay in full completion by ";
                                            break;
                                        case "MIGRATED" :
                                            tmp = "Migrated from external data source";
                                            break;
                                        
                                      }
                                    } else {
                                      tmp = "";
                                    }
                                    var tmp$1;
                                    var exit = 0;
                                    if ((versionProvenance === "PAY_IN_FULL_COMPLETION" || versionProvenance === "CLAIM_NEW_PASSENGER_INVITATION" || versionProvenance === "ACCOUNT_UPDATE" || versionProvenance === "BOOKING_CHANGE_INDIRECT" || versionProvenance === "BOOKING_CHANGE" || versionProvenance === "BOOKED" || versionProvenance === "MIGRATED") && versionProvenance === "MIGRATED") {
                                      tmp$1 = "";
                                    } else {
                                      exit = 1;
                                    }
                                    if (exit === 1) {
                                      tmp$1 = versionCreatorTeamUserEmail !== undefined ? CS_Emails$Util.Email.toString(Caml_option.valFromOption(versionCreatorTeamUserEmail)) : (
                                          versionCreatorBfcustName !== undefined && versionCreatorBfcustEmail !== undefined ? versionCreatorBfcustName + " (" + CS_Emails$Util.Email.toString(Caml_option.valFromOption(versionCreatorBfcustEmail)) + ")" : ""
                                        );
                                    }
                                    return JsxRuntime.jsx(ListItem, {
                                                children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                                          direction: "row",
                                                          sx: {
                                                            py: 1,
                                                            width: "100%"
                                                          },
                                                          children: [
                                                            JsxRuntime.jsx(Stack, {
                                                                  direction: "column",
                                                                  alignItems: "center",
                                                                  sx: {
                                                                    px: 2,
                                                                    position: "relative",
                                                                    "&::before": {
                                                                      content: "\"\"",
                                                                      position: "absolute",
                                                                      top: "25px",
                                                                      left: "26px",
                                                                      width: (i + 1 | 0) === len ? "0px" : "2px",
                                                                      height: "99%",
                                                                      backgroundColor: "divider"
                                                                    }
                                                                  },
                                                                  children: Caml_option.some(JsxRuntime.jsx(SquareRounded, {
                                                                            fontSize: "sm",
                                                                            sx: {
                                                                              border: "2px solid",
                                                                              borderColor: "#F4F4F4",
                                                                              p: "2px",
                                                                              backgroundColor: "#F4F4F4",
                                                                              borderRadius: "50%"
                                                                            }
                                                                          }))
                                                                }),
                                                            JsxRuntime.jsxs(Stack, {
                                                                  direction: "row",
                                                                  justifyContent: "space-between",
                                                                  sx: {
                                                                    width: "100%"
                                                                  },
                                                                  children: [
                                                                    JsxRuntime.jsxs(Stack, {
                                                                          spacing: 1,
                                                                          sx: {
                                                                            pb: 2,
                                                                            width: "100%"
                                                                          },
                                                                          children: [
                                                                            Common$Thick.Lifecycle.makeChip("md", item.lifecycleStatus, bookingVersionNumber),
                                                                            name !== undefined ? JsxRuntime.jsx(Typography, {
                                                                                    level: "body-md",
                                                                                    children: Caml_option.some(name)
                                                                                  }) : null,
                                                                            JsxRuntime.jsx(Typography, {
                                                                                  level: "body-xs",
                                                                                  children: Caml_option.some(versionComment !== undefined ? "Version " + String(bookingVersionNumber) + ": \"" + CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(versionComment)) + "\"" : "Version " + String(bookingVersionNumber))
                                                                                }),
                                                                            JsxRuntime.jsx(Typography, {
                                                                                  level: "body-xs",
                                                                                  children: Caml_option.some(tmp + tmp$1)
                                                                                }),
                                                                            JsxRuntime.jsx(Typography, {
                                                                                  level: "body-xs",
                                                                                  sx: {
                                                                                    fontStyle: "italic"
                                                                                  },
                                                                                  children: Caml_option.some(CS_Make$Util.Dateable.Plain.format(item.versionCreatedAt, "PP p"))
                                                                                })
                                                                          ]
                                                                        }),
                                                                    JsxRuntime.jsx(Stack, {
                                                                          direction: "column",
                                                                          alignItems: "flex-end",
                                                                          sx: {
                                                                            width: "100%"
                                                                          },
                                                                          children: Caml_option.some(JsxRuntime.jsx(Tooltip, {
                                                                                    children: JsxRuntime.jsx(IconButton, {
                                                                                          onClick: (function (param) {
                                                                                              RescriptReactRouter.push(redirect + "/" + String(bookingVersionNumber));
                                                                                              onClose();
                                                                                            }),
                                                                                          variant: "soft",
                                                                                          color: "neutral",
                                                                                          size: "sm",
                                                                                          children: Caml_option.some(JsxRuntime.jsx(KeyboardDoubleArrowRightOutlined, {}))
                                                                                        }),
                                                                                    arrow: true,
                                                                                    placement: "left",
                                                                                    size: "sm",
                                                                                    title: Caml_option.some("View " + (
                                                                                          i === 0 ? "active version" : "version " + String(bookingVersionNumber)
                                                                                        ))
                                                                                  }))
                                                                        })
                                                                  ]
                                                                })
                                                          ]
                                                        }))
                                              }, String(i));
                                  }))),
                        size: "lg",
                        sx: {
                          position: "relative"
                        }
                      }))
            });
}

var VersionHistory = {
  make: BDVersionHistory$VersionHistory
};

function BDVersionHistory(props) {
  var query = use(props.fragmentRefs);
  var match = query.versionHistory;
  var versionHistory = match !== undefined ? Belt_Array.map(match.nodes, (function (node) {
            var bfcu = node.versionCreatorBfcust;
            if (bfcu !== undefined) {
              return {
                      bookingVersionNumber: node.bookingVersionNumber,
                      name: undefined,
                      lifecycleStatus: node.lifecycleStatus,
                      versionComment: node.versionComment,
                      versionProvenance: node.versionProvenance,
                      versionCreatorTeamUserEmail: node.versionCreatorTeamUserEmail,
                      versionCreatorBfcustName: bfcu.fullName,
                      versionCreatorBfcustEmail: bfcu.email,
                      versionCreatedAt: node.versionCreatedAt
                    };
            } else {
              return {
                      bookingVersionNumber: node.bookingVersionNumber,
                      name: undefined,
                      lifecycleStatus: node.lifecycleStatus,
                      versionComment: node.versionComment,
                      versionProvenance: node.versionProvenance,
                      versionCreatorTeamUserEmail: node.versionCreatorTeamUserEmail,
                      versionCreatorBfcustName: undefined,
                      versionCreatorBfcustEmail: undefined,
                      versionCreatedAt: node.versionCreatedAt
                    };
            }
          })) : [];
  var content = {
    versionHistory: versionHistory
  };
  return JsxRuntime.jsx(BDVersionHistory$VersionHistory, {
              content: content,
              redirect: props.redirect,
              onClose: props.onClose
            });
}

var make = BDVersionHistory;

exports.QueryFragment = QueryFragment;
exports.VersionHistory = VersionHistory;
exports.make = make;
/* CS_Make-Util Not a pure module */
