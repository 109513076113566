// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Dialog$Thick = require("../../common/molecules/Dialog.bs.js");
var Stack = require("@mui/joy/Stack").default;
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var Button = require("@mui/joy/Button").default;
var BookingDiff$Thick = require("../../../utils/BookingDiff.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var FormLabel = require("@mui/joy/FormLabel").default;
var PreviewModal$Thick = require("./PreviewModal.bs.js");
var Typography = require("@mui/joy/Typography").default;
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var FormControl = require("@mui/joy/FormControl").default;
var PreviewChanges$Thick = require("./PreviewChanges.bs.js");
var DialogActions = require("@mui/joy/DialogActions").default;
var DialogContent = require("@mui/joy/DialogContent").default;
var FormHelperText = require("@mui/joy/FormHelperText").default;
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");

function AttachReplaceInviteModal(props) {
  var name = props.name;
  var __action = props.action;
  var shouldAttachToIdx = props.shouldAttachToIdx;
  var onClose = props.onClose;
  var action = __action !== undefined ? __action : "attachInvite";
  var match = React.useState(function () {
        return "";
      });
  var setEmail = match[1];
  var email = match[0];
  var match$1 = React.useState(function () {
        return "";
      });
  var setFirstName = match$1[1];
  var firstName = match$1[0];
  var match$2 = React.useState(function () {
        return "";
      });
  var setLastName = match$2[1];
  var lastName = match$2[0];
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var diffStruct = BookingDiff$Thick.parse(url.search);
  var onClose$1 = function () {
    setEmail(function (param) {
          return "";
        });
    setFirstName(function (param) {
          return "";
        });
    setLastName(function (param) {
          return "";
        });
    onClose();
  };
  var match$3 = PreviewChanges$Thick.useQuery(props.generatePreviewFromDiff, diffStruct, onClose$1);
  var clearPreviewFields = match$3.clearPreviewFields;
  var setApplyDiff = match$3.setApplyDiff;
  var setError = match$3.setError;
  var generatePreview = match$3.generatePreview;
  var clearAll = function () {
    clearPreviewFields();
    setEmail(function (param) {
          return "";
        });
    setFirstName(function (param) {
          return "";
        });
    setLastName(function (param) {
          return "";
        });
  };
  var onSubmit = function (e) {
    e.preventDefault();
    var match = CS_Emails$Util.Email.fromPrimitive(email);
    var match$1 = CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(firstName);
    if (match.TAG === "Ok" && match$1.TAG === "Ok") {
      var name = CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(lastName);
      var tmp;
      tmp = name.TAG === "Ok" ? Caml_option.some(name._0) : undefined;
      var paxInvitation_email = match._0;
      var paxInvitation_firstName = match$1._0;
      var paxInvitation = {
        email: paxInvitation_email,
        firstName: paxInvitation_firstName,
        lastName: tmp
      };
      var applyDiff = action === "attachInvite" ? BookingDiff$Thick.Alter.attachAnInvitedPax(diffStruct, shouldAttachToIdx, paxInvitation) : BookingDiff$Thick.Alter.replaceWithInvitedPax(diffStruct, shouldAttachToIdx, paxInvitation);
      setApplyDiff(function (param) {
            return applyDiff;
          });
      generatePreview(applyDiff);
      return ;
    }
    setError(function (param) {
          return "Invalid input. Please try again.";
        });
  };
  var match$4 = action === "attachInvite" ? [
      "Attach a Passenger via Invitation",
      "Please enter the below information for the person you'd like to invite to take this berth.",
      "Attach a Passenger via Invitation",
      "You are proposing to send an invitation to " + email + " to claim this berth."
    ] : [
      "Replace a Passenger via Invitation",
      "Please enter the below information for the person you'd like to replace " + Belt_Option.getWithDefault(name, "this passenger") + ".",
      "Replace a Passenger via Invitation",
      "You are proposing to send an invitation to " + email + " to replace " + Belt_Option.getWithDefault(name, "this passenger") + "."
    ];
  var m = CS_Emails$Util.Email.fromPrimitive(email);
  var tmp;
  tmp = m.TAG === "Ok" ? "User's email address" : m._0;
  var m$1 = CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(firstName);
  var tmp$1;
  tmp$1 = m$1.TAG === "Ok" ? "User's preferred first name" : m$1._0;
  var match$5 = Belt_Result.isOk(CS_Emails$Util.Email.fromPrimitive(email));
  var match$6 = Belt_Result.isOk(CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(firstName));
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs(Dialog$Thick.make, {
                      open_: props.open_,
                      onClose: onClose$1,
                      title: match$4[0],
                      children: [
                        JsxRuntime.jsxs(DialogContent, {
                              children: [
                                JsxRuntime.jsx(Typography, {
                                      level: "title-md",
                                      sx: {
                                        marginBottom: "1rem"
                                      },
                                      children: Caml_option.some(match$4[1])
                                    }),
                                JsxRuntime.jsxs(Stack, {
                                      direction: "column",
                                      spacing: 1,
                                      children: [
                                        JsxRuntime.jsxs(FormControl, {
                                              error: Belt_Result.isError(CS_Emails$Util.Email.fromPrimitive(email)),
                                              children: [
                                                JsxRuntime.jsx(FormLabel, {
                                                      children: "Email address"
                                                    }),
                                                JsxRuntime.jsx(Joy$Util.Input.make, {
                                                      type_: "email",
                                                      required: true,
                                                      value: email,
                                                      onChange: (function (e) {
                                                          var val = e.currentTarget.value;
                                                          setEmail(function (param) {
                                                                return val;
                                                              });
                                                        })
                                                    }),
                                                JsxRuntime.jsx(FormHelperText, {
                                                      children: Caml_option.some(tmp)
                                                    })
                                              ]
                                            }),
                                        JsxRuntime.jsxs(FormControl, {
                                              error: Belt_Result.isError(CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(firstName)),
                                              children: [
                                                JsxRuntime.jsx(FormLabel, {
                                                      children: "First name"
                                                    }),
                                                JsxRuntime.jsx(Joy$Util.Input.make, {
                                                      type_: "text",
                                                      placeholder: "First name",
                                                      required: true,
                                                      value: firstName,
                                                      onChange: (function (e) {
                                                          var val = e.currentTarget.value;
                                                          setFirstName(function (param) {
                                                                return val;
                                                              });
                                                        })
                                                    }),
                                                JsxRuntime.jsx(FormHelperText, {
                                                      children: Caml_option.some(tmp$1)
                                                    })
                                              ]
                                            }),
                                        JsxRuntime.jsxs(FormControl, {
                                              children: [
                                                JsxRuntime.jsx(FormLabel, {
                                                      children: "Last name (optional)"
                                                    }),
                                                JsxRuntime.jsx(Joy$Util.Input.make, {
                                                      type_: "text",
                                                      placeholder: "Last name (optional)",
                                                      value: lastName,
                                                      onChange: (function (e) {
                                                          var val = e.currentTarget.value;
                                                          setLastName(function (param) {
                                                                return val;
                                                              });
                                                        })
                                                    }),
                                                JsxRuntime.jsx(FormHelperText, {
                                                      children: "User's preferred last name"
                                                    })
                                              ]
                                            })
                                      ]
                                    })
                              ]
                            }),
                        JsxRuntime.jsx(DialogActions, {
                              children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                        component: "form",
                                        onSubmit: onSubmit,
                                        children: Caml_option.some(JsxRuntime.jsx(Button, {
                                                  type: "submit",
                                                  disabled: match$5 && match$6 ? false : true,
                                                  children: "Preview invitation"
                                                }))
                                      }))
                            })
                      ]
                    }),
                JsxRuntime.jsx(PreviewModal$Thick.make, {
                      billPayCurrent: props.billPayCurrent,
                      billPayNew: match$3.billPayNew,
                      applyDiff: match$3.applyDiff,
                      title: match$4[2],
                      description: match$4[3],
                      onClose: (function () {
                          clearAll();
                        }),
                      open_: match$3.previewModalOpen,
                      versionUrl: props.versionUrl,
                      error: match$3.error
                    })
              ]
            });
}

var make = AttachReplaceInviteModal;

exports.make = make;
/* react Not a pure module */
