// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Dialog$Thick = require("../../common/molecules/Dialog.bs.js");
var Stack = require("@mui/joy/Stack").default;
var RelayEnv$Thick = require("../../../RelayEnv.bs.js");
var Button = require("@mui/joy/Button").default;
var $$Option = require("@mui/joy/Option").default;
var CS_Decimal$Util = require("util/src/CustomScalars/CS_Decimal.bs.js");
var BillPayInfo$Thick = require("./BillPayInfo.bs.js");
var BookingDiff$Thick = require("../../../utils/BookingDiff.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var FormLabel = require("@mui/joy/FormLabel").default;
var Typography = require("@mui/joy/Typography").default;
var CurrencyInput$Thick = require("../../common/molecules/CurrencyInput.bs.js");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var FormControl = require("@mui/joy/FormControl").default;
var DialogActions = require("@mui/joy/DialogActions").default;
var DialogContent = require("@mui/joy/DialogContent").default;
var RecordP2PPayment$Thick = require("../mutations/RecordP2PPayment.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var BDRecordP2PPaymentModalQuery_graphql$Thick = require("../../../__generated__/BDRecordP2PPaymentModalQuery_graphql.bs.js");

var convertVariables = BDRecordP2PPaymentModalQuery_graphql$Thick.Internal.convertVariables;

var convertResponse = BDRecordP2PPaymentModalQuery_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = BDRecordP2PPaymentModalQuery_graphql$Thick.Internal.convertWrapRawResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, BDRecordP2PPaymentModalQuery_graphql$Thick.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, BDRecordP2PPaymentModalQuery_graphql$Thick.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(BDRecordP2PPaymentModalQuery_graphql$Thick.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(BDRecordP2PPaymentModalQuery_graphql$Thick.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(BDRecordP2PPaymentModalQuery_graphql$Thick.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(BDRecordP2PPaymentModalQuery_graphql$Thick.node, convertVariables);

var Query_accountStatus_decode = BDRecordP2PPaymentModalQuery_graphql$Thick.Utils.accountStatus_decode;

var Query_accountStatus_fromString = BDRecordP2PPaymentModalQuery_graphql$Thick.Utils.accountStatus_fromString;

var Query_billingKind_decode = BDRecordP2PPaymentModalQuery_graphql$Thick.Utils.billingKind_decode;

var Query_billingKind_fromString = BDRecordP2PPaymentModalQuery_graphql$Thick.Utils.billingKind_fromString;

var Query_bookingSessionPassengerType_decode = BDRecordP2PPaymentModalQuery_graphql$Thick.Utils.bookingSessionPassengerType_decode;

var Query_bookingSessionPassengerType_fromString = BDRecordP2PPaymentModalQuery_graphql$Thick.Utils.bookingSessionPassengerType_fromString;

var Query_brandfamRole_decode = BDRecordP2PPaymentModalQuery_graphql$Thick.Utils.brandfamRole_decode;

var Query_brandfamRole_fromString = BDRecordP2PPaymentModalQuery_graphql$Thick.Utils.brandfamRole_fromString;

var Query_custSignonStatus_decode = BDRecordP2PPaymentModalQuery_graphql$Thick.Utils.custSignonStatus_decode;

var Query_custSignonStatus_fromString = BDRecordP2PPaymentModalQuery_graphql$Thick.Utils.custSignonStatus_fromString;

var Query_docGender_decode = BDRecordP2PPaymentModalQuery_graphql$Thick.Utils.docGender_decode;

var Query_docGender_fromString = BDRecordP2PPaymentModalQuery_graphql$Thick.Utils.docGender_fromString;

var Query_elevatorProximity_decode = BDRecordP2PPaymentModalQuery_graphql$Thick.Utils.elevatorProximity_decode;

var Query_elevatorProximity_fromString = BDRecordP2PPaymentModalQuery_graphql$Thick.Utils.elevatorProximity_fromString;

var Query_invitationType_decode = BDRecordP2PPaymentModalQuery_graphql$Thick.Utils.invitationType_decode;

var Query_invitationType_fromString = BDRecordP2PPaymentModalQuery_graphql$Thick.Utils.invitationType_fromString;

var Query_lifecycleStatus_decode = BDRecordP2PPaymentModalQuery_graphql$Thick.Utils.lifecycleStatus_decode;

var Query_lifecycleStatus_fromString = BDRecordP2PPaymentModalQuery_graphql$Thick.Utils.lifecycleStatus_fromString;

var Query_longitudinality_decode = BDRecordP2PPaymentModalQuery_graphql$Thick.Utils.longitudinality_decode;

var Query_longitudinality_fromString = BDRecordP2PPaymentModalQuery_graphql$Thick.Utils.longitudinality_fromString;

var Query_manifestPaxReconciliationStatus_decode = BDRecordP2PPaymentModalQuery_graphql$Thick.Utils.manifestPaxReconciliationStatus_decode;

var Query_manifestPaxReconciliationStatus_fromString = BDRecordP2PPaymentModalQuery_graphql$Thick.Utils.manifestPaxReconciliationStatus_fromString;

var Query_paxDataStatus_decode = BDRecordP2PPaymentModalQuery_graphql$Thick.Utils.paxDataStatus_decode;

var Query_paxDataStatus_fromString = BDRecordP2PPaymentModalQuery_graphql$Thick.Utils.paxDataStatus_fromString;

var Query_pmtDirection_decode = BDRecordP2PPaymentModalQuery_graphql$Thick.Utils.pmtDirection_decode;

var Query_pmtDirection_fromString = BDRecordP2PPaymentModalQuery_graphql$Thick.Utils.pmtDirection_fromString;

var Query_pmtLifecycleStatus_decode = BDRecordP2PPaymentModalQuery_graphql$Thick.Utils.pmtLifecycleStatus_decode;

var Query_pmtLifecycleStatus_fromString = BDRecordP2PPaymentModalQuery_graphql$Thick.Utils.pmtLifecycleStatus_fromString;

var Query_pmtMethod_decode = BDRecordP2PPaymentModalQuery_graphql$Thick.Utils.pmtMethod_decode;

var Query_pmtMethod_fromString = BDRecordP2PPaymentModalQuery_graphql$Thick.Utils.pmtMethod_fromString;

var Query_pmtProcessor_decode = BDRecordP2PPaymentModalQuery_graphql$Thick.Utils.pmtProcessor_decode;

var Query_pmtProcessor_fromString = BDRecordP2PPaymentModalQuery_graphql$Thick.Utils.pmtProcessor_fromString;

var Query_refundability_decode = BDRecordP2PPaymentModalQuery_graphql$Thick.Utils.refundability_decode;

var Query_refundability_fromString = BDRecordP2PPaymentModalQuery_graphql$Thick.Utils.refundability_fromString;

var Query_signonStatus_decode = BDRecordP2PPaymentModalQuery_graphql$Thick.Utils.signonStatus_decode;

var Query_signonStatus_fromString = BDRecordP2PPaymentModalQuery_graphql$Thick.Utils.signonStatus_fromString;

var Query_subtotalCategory_decode = BDRecordP2PPaymentModalQuery_graphql$Thick.Utils.subtotalCategory_decode;

var Query_subtotalCategory_fromString = BDRecordP2PPaymentModalQuery_graphql$Thick.Utils.subtotalCategory_fromString;

var Query_versionProvenance_decode = BDRecordP2PPaymentModalQuery_graphql$Thick.Utils.versionProvenance_decode;

var Query_versionProvenance_fromString = BDRecordP2PPaymentModalQuery_graphql$Thick.Utils.versionProvenance_fromString;

var Query = {
  accountStatus_decode: Query_accountStatus_decode,
  accountStatus_fromString: Query_accountStatus_fromString,
  billingKind_decode: Query_billingKind_decode,
  billingKind_fromString: Query_billingKind_fromString,
  bookingSessionPassengerType_decode: Query_bookingSessionPassengerType_decode,
  bookingSessionPassengerType_fromString: Query_bookingSessionPassengerType_fromString,
  brandfamRole_decode: Query_brandfamRole_decode,
  brandfamRole_fromString: Query_brandfamRole_fromString,
  custSignonStatus_decode: Query_custSignonStatus_decode,
  custSignonStatus_fromString: Query_custSignonStatus_fromString,
  docGender_decode: Query_docGender_decode,
  docGender_fromString: Query_docGender_fromString,
  elevatorProximity_decode: Query_elevatorProximity_decode,
  elevatorProximity_fromString: Query_elevatorProximity_fromString,
  invitationType_decode: Query_invitationType_decode,
  invitationType_fromString: Query_invitationType_fromString,
  lifecycleStatus_decode: Query_lifecycleStatus_decode,
  lifecycleStatus_fromString: Query_lifecycleStatus_fromString,
  longitudinality_decode: Query_longitudinality_decode,
  longitudinality_fromString: Query_longitudinality_fromString,
  manifestPaxReconciliationStatus_decode: Query_manifestPaxReconciliationStatus_decode,
  manifestPaxReconciliationStatus_fromString: Query_manifestPaxReconciliationStatus_fromString,
  paxDataStatus_decode: Query_paxDataStatus_decode,
  paxDataStatus_fromString: Query_paxDataStatus_fromString,
  pmtDirection_decode: Query_pmtDirection_decode,
  pmtDirection_fromString: Query_pmtDirection_fromString,
  pmtLifecycleStatus_decode: Query_pmtLifecycleStatus_decode,
  pmtLifecycleStatus_fromString: Query_pmtLifecycleStatus_fromString,
  pmtMethod_decode: Query_pmtMethod_decode,
  pmtMethod_fromString: Query_pmtMethod_fromString,
  pmtProcessor_decode: Query_pmtProcessor_decode,
  pmtProcessor_fromString: Query_pmtProcessor_fromString,
  refundability_decode: Query_refundability_decode,
  refundability_fromString: Query_refundability_fromString,
  signonStatus_decode: Query_signonStatus_decode,
  signonStatus_fromString: Query_signonStatus_fromString,
  subtotalCategory_decode: Query_subtotalCategory_decode,
  subtotalCategory_fromString: Query_subtotalCategory_fromString,
  versionProvenance_decode: Query_versionProvenance_decode,
  versionProvenance_fromString: Query_versionProvenance_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function BDRecordP2PPaymentModal(props) {
  var _brandFamilySlug = props.brandFamily;
  var onClose = props.onClose;
  var match = React.useState(function () {
        return false;
      });
  var setSearchLoading = match[1];
  var match$1 = React.useState(function () {
        return false;
      });
  var setAutocompleteOpen = match$1[1];
  var match$2 = React.useState(function () {
        return [];
      });
  var setSelectOptions = match$2[1];
  var match$3 = props.generatePreviewFromDiff(BookingDiff$Thick.emptyDiff);
  var match$4 = React.useState(function () {
        return RecordP2PPayment$Thick.Utils.empty;
      });
  var setUpdate = match$4[1];
  var update = match$4[0];
  var createFilter = function (s) {
    return {
            or: [
              {
                fullName: {
                  includesInsensitive: s
                }
              },
              {
                emailString: {
                  includesInsensitive: s
                }
              },
              {
                loyaltyNumString: {
                  includesInsensitive: s
                }
              }
            ]
          };
  };
  var clearAll = function (param) {
    setUpdate(function (param) {
          return RecordP2PPayment$Thick.Utils.empty;
        });
  };
  var cancel = function (param) {
    clearAll();
    onClose();
  };
  var match$5 = RecordP2PPayment$Thick.useMutation(_brandFamilySlug, match$3.voyageSlug, match$3.bookingId, match$3.bookingVersionNumber, cancel, props.refetchBilling);
  var recordP2PPayment = match$5.recordP2PPayment;
  var setError = match$5.setError;
  var error = match$5.error;
  var search = function (val) {
    setSearchLoading(function (param) {
          return true;
        });
    setAutocompleteOpen(function (param) {
          return false;
        });
    setError(function (param) {
          
        });
    $$fetch(RelayEnv$Thick.environment, {
          brandFamily: _brandFamilySlug,
          filter: createFilter(val)
        }, (function (res) {
            if (res.TAG === "Ok") {
              var match = res._0.bfcustUsers;
              if (match !== undefined) {
                var results = Belt_Array.keepMap(match.nodes, (function (node) {
                        var accountId = node.accountId;
                        var fullName = node.fullName;
                        var loyaltyNumString = node.loyaltyNumString;
                        var emailString = node.emailString;
                        return {
                                accountId: accountId,
                                fullName: fullName,
                                loyaltyNumString: loyaltyNumString,
                                emailString: emailString
                              };
                      }));
                setSelectOptions(function (param) {
                      return results;
                    });
                setAutocompleteOpen(function (param) {
                      return true;
                    });
                setSearchLoading(function (param) {
                      return false;
                    });
                return ;
              }
              setError(function (param) {
                    return "No users found.";
                  });
              setSearchLoading(function (param) {
                    return false;
                  });
              return ;
            }
            console.error(res._0);
            setError(function (param) {
                  return "Something went wrong. Please try again.";
                });
            setSearchLoading(function (param) {
                  return false;
                });
          }), undefined, undefined);
  };
  var onSubmit = function (e) {
    e.preventDefault();
    var record = RecordP2PPayment$Thick.Utils.p2pIntermTop2pRecord(update);
    if (record.TAG === "Ok") {
      recordP2PPayment(record._0);
      return ;
    }
    console.error("Please fill out all fields.");
  };
  var val = update.p2pService;
  var val$1 = update.p2pReference;
  var val$2 = update.note;
  var val$3 = update.note;
  return JsxRuntime.jsxs(Dialog$Thick.make, {
              open_: props.open_,
              onClose: onClose,
              title: "Record a P2P Service Payment",
              children: [
                JsxRuntime.jsxs(DialogContent, {
                      children: [
                        JsxRuntime.jsxs(Typography, {
                              level: "title-md",
                              children: [
                                "Please enter all details below to record a peer-to-peer service payment. ",
                                JsxRuntime.jsx(Typography, {
                                      color: "danger",
                                      level: "inherit",
                                      component: "span",
                                      sx: {
                                        fontStyle: "italic"
                                      },
                                      children: "Note: this action will not be added to a draft but will immediately update booking payment details."
                                    })
                              ]
                            }),
                        JsxRuntime.jsxs(Stack, {
                              direction: "column",
                              spacing: 2,
                              sx: {
                                py: 1
                              },
                              children: [
                                JsxRuntime.jsxs(FormControl, {
                                      error: Belt_Option.isNone(update.payer),
                                      children: [
                                        JsxRuntime.jsx(FormLabel, {
                                              children: "Payer"
                                            }),
                                        JsxRuntime.jsx(Joy$Util.JoyAuto.Single.make, {
                                              options: match$2[0],
                                              getOptionLabel: (function (option) {
                                                  var e = option.emailString;
                                                  return option.fullName + (
                                                          e !== undefined ? " " + e : ""
                                                        ) + " (" + option.loyaltyNumString + ")";
                                                }),
                                              isOptionEqualToValue: (function (option, value) {
                                                  return option.accountId === value.accountId;
                                                }),
                                              loading: match[0],
                                              onChange: (function (param, value) {
                                                  if (value !== undefined) {
                                                    var accountId = value.accountId;
                                                    setUpdate(function (param) {
                                                          return {
                                                                  payer: Caml_option.some(accountId),
                                                                  netAmount: update.netAmount,
                                                                  bfFeeAmount: update.bfFeeAmount,
                                                                  lifecycleStatus: update.lifecycleStatus,
                                                                  p2pReference: update.p2pReference,
                                                                  note: update.note,
                                                                  p2pService: update.p2pService,
                                                                  transactionId: update.transactionId
                                                                };
                                                        });
                                                    return ;
                                                  }
                                                  setUpdate(function (param) {
                                                        return {
                                                                payer: undefined,
                                                                netAmount: update.netAmount,
                                                                bfFeeAmount: update.bfFeeAmount,
                                                                lifecycleStatus: update.lifecycleStatus,
                                                                p2pReference: update.p2pReference,
                                                                note: update.note,
                                                                p2pService: update.p2pService,
                                                                transactionId: update.transactionId
                                                              };
                                                      });
                                                }),
                                              onClose: (function () {
                                                  setAutocompleteOpen(function (param) {
                                                        return false;
                                                      });
                                                }),
                                              onInputChange: (function (param, v, param$1) {
                                                  if (v === "") {
                                                    setSelectOptions(function (param) {
                                                          return [];
                                                        });
                                                    setAutocompleteOpen(function (param) {
                                                          return false;
                                                        });
                                                    setError(function (param) {
                                                          
                                                        });
                                                    setSearchLoading(function (param) {
                                                          return false;
                                                        });
                                                  } else {
                                                    search(v);
                                                  }
                                                }),
                                              onOpen: (function () {
                                                  setAutocompleteOpen(function (param) {
                                                        return true;
                                                      });
                                                }),
                                              open: match$1[0],
                                              placeholder: "Search for a Payer"
                                            })
                                      ]
                                    }),
                                JsxRuntime.jsxs(Joy$Util.Grid.make, {
                                      children: [
                                        JsxRuntime.jsx(Joy$Util.Grid.make, {
                                              children: Caml_option.some(JsxRuntime.jsx(CurrencyInput$Thick.make, {
                                                        label: "Net Amount",
                                                        error: (function (v) {
                                                            if (Belt_Result.isError(CS_Decimal$Util.Decimal10_2.fromPrimitive(BillPayInfo$Thick.Utils.padIfNoDecimal(v)))) {
                                                              return true;
                                                            } else {
                                                              return v === "0.00";
                                                            }
                                                          }),
                                                        onChange: (function (amt) {
                                                            setUpdate(function (param) {
                                                                  return {
                                                                          payer: update.payer,
                                                                          netAmount: amt,
                                                                          bfFeeAmount: update.bfFeeAmount,
                                                                          lifecycleStatus: update.lifecycleStatus,
                                                                          p2pReference: update.p2pReference,
                                                                          note: update.note,
                                                                          p2pService: update.p2pService,
                                                                          transactionId: update.transactionId
                                                                        };
                                                                });
                                                          }),
                                                        value: Belt_Option.getWithDefault(update.netAmount, "0.00")
                                                      })),
                                              xs: 6
                                            }),
                                        JsxRuntime.jsx(Joy$Util.Grid.make, {
                                              children: Caml_option.some(JsxRuntime.jsx(CurrencyInput$Thick.make, {
                                                        label: "Fee Amount",
                                                        error: (function (v) {
                                                            return Belt_Result.isError(CS_Decimal$Util.Decimal10_2.fromPrimitive(BillPayInfo$Thick.Utils.padIfNoDecimal(v)));
                                                          }),
                                                        onChange: (function (amt) {
                                                            setUpdate(function (param) {
                                                                  return {
                                                                          payer: update.payer,
                                                                          netAmount: update.netAmount,
                                                                          bfFeeAmount: amt,
                                                                          lifecycleStatus: update.lifecycleStatus,
                                                                          p2pReference: update.p2pReference,
                                                                          note: update.note,
                                                                          p2pService: update.p2pService,
                                                                          transactionId: update.transactionId
                                                                        };
                                                                });
                                                          }),
                                                        value: Belt_Option.getWithDefault(update.bfFeeAmount, "0.00")
                                                      })),
                                              xs: 6
                                            }),
                                        JsxRuntime.jsx(Joy$Util.Grid.make, {
                                              children: Caml_option.some(JsxRuntime.jsxs(FormControl, {
                                                        error: val !== undefined ? Belt_Result.isError(CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(val)) : true,
                                                        children: [
                                                          JsxRuntime.jsx(FormLabel, {
                                                                children: "Service (e.g. Wise or Venmo)"
                                                              }),
                                                          JsxRuntime.jsx(Joy$Util.Input.make, {
                                                                type_: "text",
                                                                required: true,
                                                                value: Belt_Option.getWithDefault(update.p2pService, ""),
                                                                onChange: (function (e) {
                                                                    var val = e.currentTarget.value;
                                                                    if (val === "") {
                                                                      return setUpdate(function (param) {
                                                                                  return {
                                                                                          payer: update.payer,
                                                                                          netAmount: update.netAmount,
                                                                                          bfFeeAmount: update.bfFeeAmount,
                                                                                          lifecycleStatus: update.lifecycleStatus,
                                                                                          p2pReference: update.p2pReference,
                                                                                          note: update.note,
                                                                                          p2pService: undefined,
                                                                                          transactionId: update.transactionId
                                                                                        };
                                                                                });
                                                                    } else {
                                                                      return setUpdate(function (param) {
                                                                                  return {
                                                                                          payer: update.payer,
                                                                                          netAmount: update.netAmount,
                                                                                          bfFeeAmount: update.bfFeeAmount,
                                                                                          lifecycleStatus: update.lifecycleStatus,
                                                                                          p2pReference: update.p2pReference,
                                                                                          note: update.note,
                                                                                          p2pService: val,
                                                                                          transactionId: update.transactionId
                                                                                        };
                                                                                });
                                                                    }
                                                                  })
                                                              })
                                                        ]
                                                      })),
                                              xs: 4
                                            }),
                                        JsxRuntime.jsx(Joy$Util.Grid.make, {
                                              children: Caml_option.some(JsxRuntime.jsxs(FormControl, {
                                                        error: val$1 !== undefined ? Belt_Result.isError(CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(val$1)) : true,
                                                        children: [
                                                          JsxRuntime.jsx(FormLabel, {
                                                                children: "Reference #"
                                                              }),
                                                          JsxRuntime.jsx(Joy$Util.Input.make, {
                                                                type_: "text",
                                                                required: true,
                                                                value: Belt_Option.getWithDefault(update.p2pReference, ""),
                                                                onChange: (function (e) {
                                                                    var val = e.currentTarget.value;
                                                                    if (val === "") {
                                                                      return setUpdate(function (param) {
                                                                                  return {
                                                                                          payer: update.payer,
                                                                                          netAmount: update.netAmount,
                                                                                          bfFeeAmount: update.bfFeeAmount,
                                                                                          lifecycleStatus: update.lifecycleStatus,
                                                                                          p2pReference: undefined,
                                                                                          note: update.note,
                                                                                          p2pService: update.p2pService,
                                                                                          transactionId: update.transactionId
                                                                                        };
                                                                                });
                                                                    } else {
                                                                      return setUpdate(function (param) {
                                                                                  return {
                                                                                          payer: update.payer,
                                                                                          netAmount: update.netAmount,
                                                                                          bfFeeAmount: update.bfFeeAmount,
                                                                                          lifecycleStatus: update.lifecycleStatus,
                                                                                          p2pReference: val,
                                                                                          note: update.note,
                                                                                          p2pService: update.p2pService,
                                                                                          transactionId: update.transactionId
                                                                                        };
                                                                                });
                                                                    }
                                                                  })
                                                              })
                                                        ]
                                                      })),
                                              xs: 4
                                            }),
                                        JsxRuntime.jsx(Joy$Util.Grid.make, {
                                              children: Caml_option.some(JsxRuntime.jsxs(FormControl, {
                                                        error: Belt_Option.isNone(update.lifecycleStatus),
                                                        children: [
                                                          JsxRuntime.jsx(FormLabel, {
                                                                children: "Payment Status"
                                                              }),
                                                          JsxRuntime.jsx(Joy$Util.JoySelect.make, {
                                                                children: Caml_option.some(Belt_Array.mapWithIndex(BillPayInfo$Thick.Utils.lifecycleStatusOptions, (function (i, param) {
                                                                            return JsxRuntime.jsx($$Option, {
                                                                                        value: param[0],
                                                                                        children: Caml_option.some(param[1])
                                                                                      }, String(i));
                                                                          }))),
                                                                onChange: (function (param, v) {
                                                                    if (v === undefined) {
                                                                      return ;
                                                                    }
                                                                    var lifecycleStatus = Caml_option.valFromOption(v);
                                                                    if (lifecycleStatus !== undefined) {
                                                                      return setUpdate(function (param) {
                                                                                  return {
                                                                                          payer: update.payer,
                                                                                          netAmount: update.netAmount,
                                                                                          bfFeeAmount: update.bfFeeAmount,
                                                                                          lifecycleStatus: lifecycleStatus,
                                                                                          p2pReference: update.p2pReference,
                                                                                          note: update.note,
                                                                                          p2pService: update.p2pService,
                                                                                          transactionId: update.transactionId
                                                                                        };
                                                                                });
                                                                    }
                                                                    
                                                                  }),
                                                                placeholder: "Select payment status",
                                                                required: true,
                                                                slotProps: {
                                                                  listbox: {
                                                                    sx: {
                                                                      zIndex: "modal"
                                                                    }
                                                                  }
                                                                },
                                                                value: update.lifecycleStatus
                                                              })
                                                        ]
                                                      })),
                                              xs: 4
                                            }),
                                        JsxRuntime.jsx(Joy$Util.Grid.make, {
                                              children: Caml_option.some(JsxRuntime.jsxs(FormControl, {
                                                        error: val$2 !== undefined ? Belt_Result.isError(CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(val$2)) : true,
                                                        children: [
                                                          JsxRuntime.jsx(FormLabel, {
                                                                children: "Transaction ID"
                                                              }),
                                                          JsxRuntime.jsx(Joy$Util.Input.make, {
                                                                type_: "text",
                                                                required: true,
                                                                value: Belt_Option.getWithDefault(update.transactionId, ""),
                                                                onChange: (function (e) {
                                                                    var val = e.currentTarget.value;
                                                                    if (val === "") {
                                                                      return setUpdate(function (param) {
                                                                                  return {
                                                                                          payer: update.payer,
                                                                                          netAmount: update.netAmount,
                                                                                          bfFeeAmount: update.bfFeeAmount,
                                                                                          lifecycleStatus: update.lifecycleStatus,
                                                                                          p2pReference: update.p2pReference,
                                                                                          note: update.note,
                                                                                          p2pService: update.p2pService,
                                                                                          transactionId: undefined
                                                                                        };
                                                                                });
                                                                    } else {
                                                                      return setUpdate(function (param) {
                                                                                  return {
                                                                                          payer: update.payer,
                                                                                          netAmount: update.netAmount,
                                                                                          bfFeeAmount: update.bfFeeAmount,
                                                                                          lifecycleStatus: update.lifecycleStatus,
                                                                                          p2pReference: update.p2pReference,
                                                                                          note: update.note,
                                                                                          p2pService: update.p2pService,
                                                                                          transactionId: val
                                                                                        };
                                                                                });
                                                                    }
                                                                  })
                                                              })
                                                        ]
                                                      })),
                                              xs: 6
                                            }),
                                        JsxRuntime.jsx(Joy$Util.Grid.make, {
                                              children: Caml_option.some(JsxRuntime.jsxs(FormControl, {
                                                        error: val$3 !== undefined ? Belt_Result.isError(CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(val$3)) : true,
                                                        children: [
                                                          JsxRuntime.jsx(FormLabel, {
                                                                children: "Note"
                                                              }),
                                                          JsxRuntime.jsx(Joy$Util.Input.make, {
                                                                type_: "text",
                                                                required: true,
                                                                value: Belt_Option.getWithDefault(update.note, ""),
                                                                onChange: (function (e) {
                                                                    var val = e.currentTarget.value;
                                                                    if (val === "") {
                                                                      return setUpdate(function (param) {
                                                                                  return {
                                                                                          payer: update.payer,
                                                                                          netAmount: update.netAmount,
                                                                                          bfFeeAmount: update.bfFeeAmount,
                                                                                          lifecycleStatus: update.lifecycleStatus,
                                                                                          p2pReference: update.p2pReference,
                                                                                          note: undefined,
                                                                                          p2pService: update.p2pService,
                                                                                          transactionId: update.transactionId
                                                                                        };
                                                                                });
                                                                    } else {
                                                                      return setUpdate(function (param) {
                                                                                  return {
                                                                                          payer: update.payer,
                                                                                          netAmount: update.netAmount,
                                                                                          bfFeeAmount: update.bfFeeAmount,
                                                                                          lifecycleStatus: update.lifecycleStatus,
                                                                                          p2pReference: update.p2pReference,
                                                                                          note: val,
                                                                                          p2pService: update.p2pService,
                                                                                          transactionId: update.transactionId
                                                                                        };
                                                                                });
                                                                    }
                                                                  })
                                                              })
                                                        ]
                                                      })),
                                              xs: 6
                                            })
                                      ],
                                      container: true,
                                      rowSpacing: 1,
                                      columnSpacing: 1
                                    }),
                                error !== undefined ? JsxRuntime.jsx(Typography, {
                                        color: "danger",
                                        children: Caml_option.some(error)
                                      }) : null
                              ]
                            })
                      ],
                      sx: {
                        maxWidth: "780px"
                      }
                    }),
                JsxRuntime.jsx(DialogActions, {
                      children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                direction: {
                                  xs: "column-reverse",
                                  sm: "row"
                                },
                                component: "form",
                                justifyContent: "space-between",
                                spacing: 1,
                                sx: {
                                  width: "100%"
                                },
                                onSubmit: onSubmit,
                                children: [
                                  JsxRuntime.jsx(Button, {
                                        onClick: cancel,
                                        variant: "outlined",
                                        color: "neutral",
                                        children: "Cancel"
                                      }),
                                  JsxRuntime.jsx(Button, {
                                        loading: match$5.isMutating,
                                        type: "submit",
                                        disabled: Belt_Result.isError(RecordP2PPayment$Thick.Utils.p2pIntermTop2pRecord(update)),
                                        children: "Record"
                                      })
                                ]
                              }))
                    })
              ]
            });
}

var make = BDRecordP2PPaymentModal;

exports.Query = Query;
exports.make = make;
/* use Not a pure module */
