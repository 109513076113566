// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_Set = require("rescript/lib/js/belt_Set.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_MapInt = require("rescript/lib/js/belt_MapInt.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CS_Phone$Util = require("util/src/CustomScalars/CS_Phone.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var RelayEnv$Thick = require("../RelayEnv.bs.js");
var CS_Decimal$Util = require("util/src/CustomScalars/CS_Decimal.bs.js");
var DraftUtils$Thick = require("../components/booking-details/drafts/DraftUtils.bs.js");
var HopperState$Util = require("util/src/HopperState.bs.js");
var CS_ISO3166_1$Util = require("util/src/CustomScalars/CS_ISO3166_1.bs.js");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var CS_SQLDatesAndTimes$Util = require("util/src/CustomScalars/CS_SQLDatesAndTimes.bs.js");
var CS_LoyaltyAndCruiselineNumbers$Util = require("util/src/CustomScalars/CS_LoyaltyAndCruiselineNumbers.bs.js");
var BookingSessionDetail_query_graphql$Thick = require("../__generated__/BookingSessionDetail_query_graphql.bs.js");
var ManageBookingStateCabinQuery_graphql$Thick = require("../__generated__/ManageBookingStateCabinQuery_graphql.bs.js");

var firstName = HopperState$Util.Observable.FormField.makeOptionalString({
      read: (function (param) {
          return param.firstName;
        }),
      write: (function (pax, firstName) {
          var newrecord = Caml_obj.obj_dup(pax);
          newrecord.firstName = firstName;
          return newrecord;
        })
    }, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive, CS_NonemptyStrings$Util.NonEmptyString.toPrimitive, {
      TAG: "Ok",
      _0: undefined
    }, Caml_option.some(undefined), undefined);

var lastName = HopperState$Util.Observable.FormField.makeOptionalString({
      read: (function (param) {
          return param.lastName;
        }),
      write: (function (pax, lastName) {
          var newrecord = Caml_obj.obj_dup(pax);
          newrecord.lastName = lastName;
          return newrecord;
        })
    }, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive, CS_NonemptyStrings$Util.NonEmptyString.toPrimitive, {
      TAG: "Ok",
      _0: undefined
    }, Caml_option.some(undefined), undefined);

var legalDocumentFirstName = HopperState$Util.Observable.FormField.makeOptionalString({
      read: (function (param) {
          return param.legalDocumentFirstName;
        }),
      write: (function (pax, legalDocumentFirstName) {
          var newrecord = Caml_obj.obj_dup(pax);
          newrecord.legalDocumentFirstName = legalDocumentFirstName;
          return newrecord;
        })
    }, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive, CS_NonemptyStrings$Util.NonEmptyString.toPrimitive, {
      TAG: "Ok",
      _0: undefined
    }, Caml_option.some(undefined), undefined);

var legalDocumentLastName = HopperState$Util.Observable.FormField.makeOptionalString({
      read: (function (param) {
          return param.legalDocumentLastName;
        }),
      write: (function (pax, legalDocumentLastName) {
          var newrecord = Caml_obj.obj_dup(pax);
          newrecord.legalDocumentLastName = legalDocumentLastName;
          return newrecord;
        })
    }, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive, CS_NonemptyStrings$Util.NonEmptyString.toPrimitive, {
      TAG: "Ok",
      _0: undefined
    }, Caml_option.some(undefined), undefined);

var legalDocumentBirthdate = HopperState$Util.Observable.FormField.makeOptionalString({
      read: (function (param) {
          return param.legalDocumentBirthdate;
        }),
      write: (function (form, legalDocumentBirthdate) {
          var newrecord = Caml_obj.obj_dup(form);
          newrecord.legalDocumentBirthdate = legalDocumentBirthdate;
          return newrecord;
        })
    }, CS_SQLDatesAndTimes$Util.DateOnly.fromPrimitive, CS_SQLDatesAndTimes$Util.DateOnly.toPrimitive, {
      TAG: "Ok",
      _0: undefined
    }, undefined, undefined);

var legalDocumentCitizenship = HopperState$Util.Observable.FormField.makeOptional({
      read: (function (param) {
          return param.legalDocumentCitizenship;
        }),
      write: (function (pax, legalDocumentCitizenship) {
          var newrecord = Caml_obj.obj_dup(pax);
          var tmp;
          if (pax.residenceCountry.lastTouched === undefined) {
            var init = pax.residenceCountry;
            tmp = {
              initialValue: init.initialValue,
              value: legalDocumentCitizenship.value,
              lastTouched: init.lastTouched
            };
          } else {
            tmp = pax.residenceCountry;
          }
          newrecord.residenceCountry = tmp;
          newrecord.legalDocumentCitizenship = legalDocumentCitizenship;
          return newrecord;
        })
    }, (function (v) {
        return {
                TAG: "Ok",
                _0: v
              };
      }), (function (v) {
        return v;
      }), HopperState$Util.Observable.FormField.makeStringableSchema((function (v) {
            return v;
          }), (function (v) {
            return v;
          })), {
      TAG: "Ok",
      _0: undefined
    }, Caml_option.some(undefined), undefined);

var legalDocumentGender = HopperState$Util.Observable.FormField.makeOptional({
      read: (function (param) {
          return param.legalDocumentGender;
        }),
      write: (function (pax, legalDocumentGender) {
          var newrecord = Caml_obj.obj_dup(pax);
          newrecord.legalDocumentGender = legalDocumentGender;
          return newrecord;
        })
    }, (function (v) {
        return {
                TAG: "Ok",
                _0: v
              };
      }), (function (v) {
        return v;
      }), HopperState$Util.Observable.FormField.makeStringableSchema((function (v) {
            return v;
          }), (function (v) {
            return v;
          })), {
      TAG: "Ok",
      _0: undefined
    }, Caml_option.some(undefined), undefined);

var phone = HopperState$Util.Observable.FormField.makeOptionalString({
      read: (function (param) {
          return param.phone;
        }),
      write: (function (pax, phone) {
          var newrecord = Caml_obj.obj_dup(pax);
          newrecord.phone = phone;
          return newrecord;
        })
    }, (function (v) {
        return {
                TAG: "Ok",
                _0: v
              };
      }), (function (v) {
        return v;
      }), {
      TAG: "Ok",
      _0: undefined
    }, Caml_option.some(undefined), undefined);

var phoneCountryCode = HopperState$Util.Observable.FormField.makeString({
      read: (function (param) {
          return param.phoneCountryCode;
        }),
      write: (function (pax, phoneCountryCode) {
          var newrecord = Caml_obj.obj_dup(pax);
          newrecord.phoneCountryCode = phoneCountryCode;
          return newrecord;
        })
    }, (function (v) {
        return {
                TAG: "Ok",
                _0: v
              };
      }), (function (v) {
        return v;
      }), "+1", undefined);

var postalCode = HopperState$Util.Observable.FormField.makeOptionalString({
      read: (function (param) {
          return param.postalCode;
        }),
      write: (function (pax, postalCode) {
          var newrecord = Caml_obj.obj_dup(pax);
          newrecord.postalCode = postalCode;
          return newrecord;
        })
    }, CS_NonemptyStrings$Util.PostalCode.fromPrimitive, CS_NonemptyStrings$Util.PostalCode.toPrimitive, {
      TAG: "Ok",
      _0: undefined
    }, Caml_option.some(undefined), undefined);

var residenceCountry = HopperState$Util.Observable.FormField.makeOptional({
      read: (function (param) {
          return param.residenceCountry;
        }),
      write: (function (pax, residenceCountry) {
          var newrecord = Caml_obj.obj_dup(pax);
          newrecord.residenceCountry = residenceCountry;
          return newrecord;
        })
    }, (function (v) {
        return {
                TAG: "Ok",
                _0: v
              };
      }), (function (v) {
        return v;
      }), CS_ISO3166_1$Util.Alpha3.schema, {
      TAG: "Ok",
      _0: undefined
    }, Caml_option.some(undefined), undefined);

var halMarinerNumber = HopperState$Util.Observable.FormField.makeOptionalString({
      read: (function (param) {
          return param.halMarinerNumber;
        }),
      write: (function (pax, halMarinerNumber) {
          var newrecord = Caml_obj.obj_dup(pax);
          newrecord.halMarinerNumber = halMarinerNumber;
          return newrecord;
        })
    }, CS_LoyaltyAndCruiselineNumbers$Util.HalMarinerNumber.fromPrimitive, CS_LoyaltyAndCruiselineNumbers$Util.HalMarinerNumber.toPrimitive, {
      TAG: "Ok",
      _0: undefined
    }, Caml_option.some(undefined), undefined);

var wasReferred = HopperState$Util.Observable.FormField.makeOptionalString({
      read: (function (param) {
          return param.wasReferred;
        }),
      write: (function (model, wasReferred) {
          return {
                  passengers: model.passengers,
                  canAddBerth: model.canAddBerth,
                  wasReferred: wasReferred
                };
        })
    }, CS_NonemptyStrings$Util.AccountId.fromPrimitive, CS_NonemptyStrings$Util.AccountId.toPrimitive, {
      TAG: "Ok",
      _0: undefined
    }, Caml_option.some(undefined), undefined);

function blankPass(idx) {
  return {
          paxDataStatus: "TBA",
          idx: idx,
          reorderedIdx: idx,
          accountId: undefined,
          email: undefined,
          fullName: undefined,
          firstName: firstName.blankStore,
          lastName: lastName.blankStore,
          loyaltyNumber: undefined,
          legalDocumentFirstName: legalDocumentFirstName.blankStore,
          legalDocumentLastName: legalDocumentLastName.blankStore,
          legalDocumentBirthdate: legalDocumentBirthdate.blankStore,
          legalDocumentCitizenship: legalDocumentCitizenship.blankStore,
          legalDocumentGender: legalDocumentGender.blankStore,
          phone: phone.blankStore,
          phoneCountryCode: phoneCountryCode.blankStore,
          postalCode: postalCode.blankStore,
          residenceCountry: residenceCountry.blankStore,
          halMarinerNumber: halMarinerNumber.blankStore,
          shouldAttachToIdx: idx,
          invitationEmail: undefined,
          invitationExpiry: undefined,
          invitationExpired: false,
          custodianAccountId: undefined,
          currentTierTitle: undefined,
          numEligibleVoyages: 0,
          cruiselineBookingNumber: undefined,
          paxReconciliationStatus: "UNRECONCILED",
          isNewConnected: false,
          updateUserAccount: false,
          voyageApplication: {
            updateOtherVoyages: Belt_Set.make(CS_Slugs$Util.VoyageSlug.Comparable),
            showVoyageOptions: false,
            voyageOptions: []
          }
        };
}

function blankModel() {
  return {
          passengers: undefined,
          canAddBerth: false,
          wasReferred: wasReferred.blankStore
        };
}

var decodeDocGender = BookingSessionDetail_query_graphql$Thick.Utils.docGender_decode;

function decodeDocGenderExn(g) {
  return Belt_Option.getExn(decodeDocGender(g));
}

function docGenderInputToDocGender(g) {
  return g;
}

function docGenderToString(prim) {
  return prim;
}

var docGenderFromString = BookingSessionDetail_query_graphql$Thick.Utils.docGender_fromString;

var allLegalDocGenders = [
  "F",
  "M",
  "X"
];

function docGenderNiceName(dg) {
  switch (dg) {
    case "M" :
        return "Male on Travel Documents";
    case "F" :
        return "Female on Travel Documents";
    case "X" :
        return "X on Travel Documents";
    
  }
}

var modelUtils = HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.emptyContainer(), wasReferred);

var passUtils = HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.emptyContainer(), firstName), lastName), phone), phoneCountryCode), postalCode), residenceCountry), halMarinerNumber), legalDocumentFirstName), legalDocumentLastName), legalDocumentGender), legalDocumentCitizenship), legalDocumentBirthdate);

function reset(extra) {
  return Curry._2(passUtils.polyReducer, "ResetToInitialValue", extra);
}

function useIsDirty(view) {
  return HopperState$Util.Observable.FormField.useIsDirty(view, passUtils);
}

function paxViewByBerthNum(model, num) {
  return HopperState$Util.Observable.makeView(model, {
              read: (function (model) {
                  return Belt_MapInt.getWithDefault(model.passengers, num, blankPass(num));
                }),
              write: (function (model, pass) {
                  return {
                          passengers: Belt_MapInt.set(model.passengers, num, pass),
                          canAddBerth: model.canAddBerth,
                          wasReferred: model.wasReferred
                        };
                })
            }, undefined);
}

function voyageApplicationView(pass) {
  return HopperState$Util.Observable.makeView(pass, {
              read: (function (pass) {
                  return pass.voyageApplication;
                }),
              write: (function (pass, voyageApplication) {
                  var newrecord = Caml_obj.obj_dup(pass);
                  newrecord.voyageApplication = voyageApplication;
                  return newrecord;
                })
            }, undefined);
}

function toCustomScalar(i, f) {
  var match = Belt_Option.map(i, f);
  if (match !== undefined && match.TAG === "Ok") {
    return Caml_option.some(match._0);
  }
  
}

function initModel(modelConfig) {
  var init_wasReferred = wasReferred.blankStore;
  return wasReferred.setFromValid({
              passengers: undefined,
              canAddBerth: modelConfig.canAddBerth,
              wasReferred: init_wasReferred
            }, modelConfig.wasReferred, "InitialValueAsPrefill");
}

function resetModelFromConfig(v, config) {
  HopperState$Util.Observable.notify(v, (function (param) {
          return initModel(config);
        }));
}

function resetModelToInitialOrDraftValue(extra) {
  return Curry._2(modelUtils.polyReducer, "ResetToInitialValue", extra);
}

function setDraftReferrerAsInitial(v, referrerId) {
  HopperState$Util.Observable.notify(v, (function (m) {
          return wasReferred.setFromValid(wasReferred.setFromValid(m, referrerId, "Value"), referrerId, "InitialValueAsPrefill");
        }));
}

function create(d) {
  var blank = blankPass(d.idx);
  var newrecord = Caml_obj.obj_dup(blank);
  var init = blank.voyageApplication;
  newrecord.voyageApplication = {
    updateOtherVoyages: init.updateOtherVoyages,
    showVoyageOptions: init.showVoyageOptions,
    voyageOptions: d.voyageOptions
  };
  return legalDocumentBirthdate.setFromValid(legalDocumentCitizenship.setFromValid(legalDocumentGender.setFromValid(legalDocumentLastName.setFromValid(legalDocumentFirstName.setFromValid(halMarinerNumber.setFromValid(residenceCountry.setFromValid(postalCode.setFromValid(phoneCountryCode.setFromValid(phone.setFromValid(lastName.setFromValid(firstName.setFromValid((newrecord.isNewConnected = d.isNewConnected, newrecord.paxReconciliationStatus = d.paxReconciliationStatus, newrecord.cruiselineBookingNumber = d.cruiselineBookingNumber, newrecord.numEligibleVoyages = d.numEligibleVoyages, newrecord.currentTierTitle = d.currentTierTitle, newrecord.custodianAccountId = d.custodianAccountId, newrecord.invitationExpired = d.invitationExpired, newrecord.invitationExpiry = d.invitationExpiry, newrecord.invitationEmail = d.invitationEmail, newrecord.shouldAttachToIdx = d.shouldAttachToIdx, newrecord.loyaltyNumber = d.loyaltyNumber, newrecord.fullName = d.fullName, newrecord.email = d.email, newrecord.accountId = d.accountId, newrecord.reorderedIdx = d.reorderedIdx, newrecord.idx = d.idx, newrecord.paxDataStatus = d.paxDataStatus, newrecord), toCustomScalar(d.firstName, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive), "InitialValueAsPrefill"), toCustomScalar(d.lastName, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive), "InitialValueAsPrefill"), Belt_Option.flatMap(d.phone, (function (p) {
                                                        return CS_Phone$Util.Phone.getNumber(p);
                                                      })), "InitialValueAsPrefill"), Belt_Option.getWithDefault(Belt_Option.flatMap(d.phone, (function (p) {
                                                        return CS_Phone$Util.Phone.getCallingCode(p);
                                                      })), "+1"), "InitialValueAsPrefill"), toCustomScalar(d.postalCode, CS_NonemptyStrings$Util.PostalCode.fromPrimitive), "InitialValueAsPrefill"), d.residenceCountry, "InitialValueAsPrefill"), toCustomScalar(d.halMarinerNumber, CS_LoyaltyAndCruiselineNumbers$Util.HalMarinerNumber.fromPrimitive), "InitialValueAsPrefill"), toCustomScalar(d.legalDocumentFirstName, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive), "InitialValueAsPrefill"), toCustomScalar(d.legalDocumentLastName, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive), "InitialValueAsPrefill"), Belt_Option.map(d.legalDocumentGender, decodeDocGenderExn), "InitialValueAsPrefill"), d.legalDocumentCitizenship, "InitialValueAsPrefill"), d.legalDocumentBirthdate, "InitialValueAsPrefill");
}

function toNewConnected(u) {
  var match = legalDocumentFirstName.validate(u, undefined);
  var match$1 = legalDocumentLastName.validate(u, undefined);
  var match$2 = legalDocumentBirthdate.validate(u, undefined);
  var match$3 = legalDocumentGender.validate(u, undefined);
  var match$4 = legalDocumentCitizenship.validate(u, undefined);
  var match$5 = firstName.validate(u, undefined);
  var match$6 = lastName.validate(u, undefined);
  var match$7 = halMarinerNumber.validate(u, undefined);
  var match$8 = u.custodianAccountId;
  if (match.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "Invalid legal document fields"
          };
  }
  var docFirstName = match._0;
  if (docFirstName === undefined) {
    return {
            TAG: "Error",
            _0: "Invalid legal document fields"
          };
  }
  if (match$1.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "Invalid legal document fields"
          };
  }
  var docLastName = match$1._0;
  if (docLastName === undefined) {
    return {
            TAG: "Error",
            _0: "Invalid legal document fields"
          };
  }
  if (match$2.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "Invalid legal document fields"
          };
  }
  var docBirthdate = match$2._0;
  if (docBirthdate === undefined) {
    return {
            TAG: "Error",
            _0: "Invalid legal document fields"
          };
  }
  if (match$3.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "Invalid legal document fields"
          };
  }
  var docGender = match$3._0;
  if (docGender === undefined) {
    return {
            TAG: "Error",
            _0: "Invalid legal document fields"
          };
  }
  if (match$4.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "Invalid legal document fields"
          };
  }
  var docCitizenship = match$4._0;
  if (docCitizenship === undefined) {
    return {
            TAG: "Error",
            _0: "Invalid legal document fields"
          };
  }
  if (match$5.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "Invalid legal document fields"
          };
  }
  var preferredFirstName = match$5._0;
  if (preferredFirstName === undefined) {
    return {
            TAG: "Error",
            _0: "Invalid legal document fields"
          };
  }
  if (match$7.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "Invalid legal document fields"
          };
  }
  var halMarinerNumber$1 = match$7._0;
  if (halMarinerNumber$1 === undefined) {
    return {
            TAG: "Error",
            _0: "Invalid legal document fields"
          };
  }
  if (match$8 === undefined) {
    return {
            TAG: "Error",
            _0: "Invalid legal document fields"
          };
  }
  if (match$6.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "Invalid preferred last name"
          };
  }
  var tmp;
  tmp = match$6.TAG === "Ok" ? match$6._0 : undefined;
  return {
          TAG: "Ok",
          _0: {
            custodianAccountId: Caml_option.valFromOption(match$8),
            docFirstName: Caml_option.valFromOption(docFirstName),
            docLastName: Caml_option.valFromOption(docLastName),
            docGender: docGender,
            docBirthdate: Caml_option.valFromOption(docBirthdate),
            docCitizenship: Caml_option.valFromOption(docCitizenship),
            halMarinerNumber: Caml_option.some(Caml_option.valFromOption(halMarinerNumber$1)),
            preferredFirstName: Caml_option.some(Caml_option.valFromOption(preferredFirstName)),
            preferredLastName: tmp
          }
        };
}

function toPaxPatch(u) {
  var match = u.accountId;
  var match$1 = legalDocumentFirstName.validate(u, undefined);
  var match$2 = legalDocumentLastName.validate(u, undefined);
  var match$3 = legalDocumentGender.validate(u, undefined);
  var match$4 = legalDocumentBirthdate.validate(u, undefined);
  var match$5 = legalDocumentCitizenship.validate(u, undefined);
  if (match === undefined) {
    return {
            TAG: "Error",
            _0: "Invalid patch fields"
          };
  }
  if (match$1.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "Invalid patch fields"
          };
  }
  if (match$2.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "Invalid patch fields"
          };
  }
  if (match$3.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "Invalid patch fields"
          };
  }
  if (match$4.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "Invalid patch fields"
          };
  }
  if (match$5.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "Invalid patch fields"
          };
  }
  var updateOtherVoyages = Belt_Set.toArray(u.voyageApplication.updateOtherVoyages);
  return {
          TAG: "Ok",
          _0: {
            accountId: Caml_option.valFromOption(match),
            pDocFirstName: match$1._0,
            pDocLastName: match$2._0,
            pDocBirthdate: match$4._0,
            pDocGender: match$3._0,
            pDocCitizenship: match$5._0,
            updateUserAccount: u.updateUserAccount,
            updateOtherVoyages: updateOtherVoyages
          }
        };
}

function generatePaxList(dataPaxes, diff, brandFamily, numBerths, state) {
  var toAttachOrReplaceExisting = DraftUtils$Thick.getExistingUsers(diff, brandFamily);
  var nonTBAPaxes = DraftUtils$Thick.replaceDropDetachOrReturn(DraftUtils$Thick.paxify(dataPaxes, diff), diff, toAttachOrReplaceExisting);
  var attachIndex = Belt_Array.reduce(nonTBAPaxes, 0, (function (acc, pax) {
          return Math.max(acc, pax.idx);
        })) + 1 | 0;
  var passengers = DraftUtils$Thick.patchPaxes(Belt_Array.concat(nonTBAPaxes, DraftUtils$Thick.attachOrReturn(DraftUtils$Thick.createTBAs(attachIndex, nonTBAPaxes.length, numBerths, diff), diff, toAttachOrReplaceExisting)), diff);
  var shouldBlockDrop = Belt_Array.keep(passengers, (function (p) {
          return p.paxDataStatus !== "TBA";
        })).length === 1;
  var map = DraftUtils$Thick.postMushIdxMap(passengers);
  var postMushIdxMap = Belt_Array.some(Belt_MapInt.toArray(map), (function (param) {
          return param[0] !== param[1];
        })) ? Caml_option.some(map) : undefined;
  HopperState$Util.Observable.notify(state, (function (m) {
          return {
                  passengers: Belt_MapInt.fromArray(Belt_Array.mapWithIndex(passengers, (function (i, p) {
                              return [
                                      i + 1 | 0,
                                      create(p)
                                    ];
                            }))),
                  canAddBerth: m.canAddBerth,
                  wasReferred: m.wasReferred
                };
        }));
  return {
          shouldBlockDrop: shouldBlockDrop,
          postMushIdxMap: postMushIdxMap
        };
}

function generateAvatarInitials(pax) {
  var match = pax.firstName.value;
  var match$1 = pax.lastName.value;
  var match$2 = pax.legalDocumentFirstName.value;
  var match$3 = pax.legalDocumentLastName.value;
  if (match !== undefined) {
    if (match$1 === undefined) {
      return match[0].toLocaleUpperCase();
    }
    var firstInitial = match[0];
    var lastInitial = match$1[0];
    return (firstInitial + lastInitial).toLocaleUpperCase();
  }
  if (match$1 !== undefined) {
    return match$1[0].toLocaleUpperCase();
  }
  if (match$2 === undefined) {
    return "TBA";
  }
  if (match$3 === undefined) {
    return "TBA";
  }
  var firstInitial$1 = match$2[0];
  var lastInitial$1 = match$3[0];
  return (firstInitial$1 + lastInitial$1).toLocaleUpperCase();
}

function getManualBillingLineIdxMap(i) {
  if (i === undefined) {
    return ;
  }
  var match = i.nodes;
  if (match.length !== 1) {
    return ;
  }
  var match$1 = match[0];
  return Belt_Array.reduce(match$1.billingLine.nodes, undefined, (function (acc, param) {
                if (!param.isManualLine) {
                  return acc;
                }
                var key = CS_NonemptyStrings$Util.NonEmptyString.toString(param.billingLineDescription) + "-" + CS_Decimal$Util.Decimal10_2.toString(param.unsuppressedAmount);
                var arr = Belt_MapString.getWithDefault(acc, key, []);
                return Belt_MapString.set(acc, key, Belt_Array.concat(arr, [param.billingIdx]));
              }));
}

function getManualLineIdx(l, m, d) {
  var key = l.billingLineDescription + "-" + CS_Decimal$Util.Decimal10_2.toString(l.unsuppressedAmount);
  var arr = Belt_MapString.get(m, key);
  if (arr !== undefined) {
    return Belt_Array.get(Belt_Array.keep(arr, (function (idx) {
                      return !Belt_Array.some(Belt_Option.getWithDefault(d.suppressManualBillingLines, []), (function (v) {
                                    return v === idx;
                                  }));
                    })), 0);
  }
  
}

var convertVariables = ManageBookingStateCabinQuery_graphql$Thick.Internal.convertVariables;

var convertResponse = ManageBookingStateCabinQuery_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = ManageBookingStateCabinQuery_graphql$Thick.Internal.convertWrapRawResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, ManageBookingStateCabinQuery_graphql$Thick.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, ManageBookingStateCabinQuery_graphql$Thick.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(ManageBookingStateCabinQuery_graphql$Thick.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(ManageBookingStateCabinQuery_graphql$Thick.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(ManageBookingStateCabinQuery_graphql$Thick.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(ManageBookingStateCabinQuery_graphql$Thick.node, convertVariables);

var CabinQuery = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function generateCabin(dataCabin, diffStruct, searchString, brandFamily, voyage, numBerths, state) {
  var stateroomImage = "https://i.ibb.co/kGcdwMS/stateroomtest.jpg";
  var match = dataCabin.cabin;
  var match$1 = dataCabin.cabin;
  var match$2 = dataCabin.cabin;
  var match$3 = dataCabin.cabin;
  var match$4 = dataCabin.occRange.start;
  var match$5 = dataCabin.occRange.end;
  var initialCabin_cabinNumber = dataCabin.cabinNumber;
  var initialCabin_deck = match !== undefined ? Caml_option.some(match.deckNumber) : undefined;
  var initialCabin_cabinClassSlug = match$1 !== undefined ? Caml_option.some(match$1.voyageClassSlug) : undefined;
  var initialCabin_cabinCategorySlug = match$2 !== undefined ? Caml_option.some(match$2.voyageCategorySlug) : undefined;
  var initialCabin_cabinSubclassSlug = match$3 !== undefined ? Caml_option.some(match$3.voyageSubclassSlug) : undefined;
  var initialCabin_occupancyMin = match$4 !== undefined ? (
      match$4.inclusive ? match$4.value : match$4.value + 1 | 0
    ) : undefined;
  var initialCabin_occupancyMax = match$5 !== undefined ? (
      match$5.inclusive ? match$5.value : match$5.value - 1 | 0
    ) : undefined;
  var initialCabin = {
    cabinNumber: initialCabin_cabinNumber,
    numBerths: numBerths,
    deck: initialCabin_deck,
    cabinClassSlug: initialCabin_cabinClassSlug,
    cabinCategorySlug: initialCabin_cabinCategorySlug,
    cabinSubclassSlug: initialCabin_cabinSubclassSlug,
    occupancyMin: initialCabin_occupancyMin,
    occupancyMax: initialCabin_occupancyMax,
    stateroomImage: stateroomImage
  };
  var match$6 = React.useState(function () {
        
      });
  var setDraftCabin = match$6[1];
  var draftCabin = match$6[0];
  React.useEffect((function () {
          var match = diffStruct.changeCabinU;
          var match$1 = diffStruct.changeCabinB;
          var cabinNumber = match !== undefined ? Caml_option.some(Caml_option.valFromOption(match)) : (
              match$1 !== undefined ? Caml_option.some(match$1.cabinNumber) : undefined
            );
          if (cabinNumber !== undefined) {
            $$fetch(RelayEnv$Thick.environment, {
                  brandFamily: brandFamily,
                  cabinNumber: Caml_option.valFromOption(cabinNumber),
                  voyage: voyage
                }, (function (res) {
                    if (res.TAG === "Ok") {
                      var match = res._0.cabins;
                      if (match !== undefined) {
                        var match$1 = match.nodes;
                        if (match$1.length !== 1) {
                          console.log("No cabin found");
                        } else {
                          var cabin = match$1[0];
                          var match$2 = cabin.occRange;
                          var match$3 = match$2.end;
                          var occMax = match$3 !== undefined ? (
                              match$3.inclusive ? match$3.value : match$3.value - 1 | 0
                            ) : undefined;
                          var match$4 = cabin.occRange;
                          var match$5 = match$4.start;
                          var newCabin_cabinNumber = cabin.cabinNumber;
                          var newCabin_deck = Caml_option.some(cabin.deckNumber);
                          var newCabin_cabinClassSlug = Caml_option.some(cabin.voyageClassSlug);
                          var newCabin_cabinCategorySlug = Caml_option.some(cabin.voyageCategorySlug);
                          var newCabin_cabinSubclassSlug = Caml_option.some(cabin.voyageSubclassSlug);
                          var newCabin_occupancyMin = match$5 !== undefined ? (
                              match$5.inclusive ? match$5.value : match$5.value + 1 | 0
                            ) : undefined;
                          var newCabin = {
                            cabinNumber: newCabin_cabinNumber,
                            numBerths: numBerths,
                            deck: newCabin_deck,
                            cabinClassSlug: newCabin_cabinClassSlug,
                            cabinCategorySlug: newCabin_cabinCategorySlug,
                            cabinSubclassSlug: newCabin_cabinSubclassSlug,
                            occupancyMin: newCabin_occupancyMin,
                            occupancyMax: occMax,
                            stateroomImage: stateroomImage
                          };
                          var canAddBerth = numBerths < Belt_Option.getWithDefault(occMax, 0);
                          HopperState$Util.Observable.notify(state, (function (m) {
                                  return {
                                          passengers: m.passengers,
                                          canAddBerth: canAddBerth,
                                          wasReferred: m.wasReferred
                                        };
                                }));
                          setDraftCabin(function (param) {
                                return newCabin;
                              });
                        }
                      } else {
                        console.log("No cabin found");
                      }
                      return ;
                    }
                    console.error(res._0);
                  }), undefined, undefined);
          } else {
            setDraftCabin(function (param) {
                  
                });
          }
        }), [searchString]);
  if (draftCabin !== undefined) {
    return draftCabin;
  } else {
    return initialCabin;
  }
}

exports.firstName = firstName;
exports.lastName = lastName;
exports.legalDocumentFirstName = legalDocumentFirstName;
exports.legalDocumentLastName = legalDocumentLastName;
exports.legalDocumentBirthdate = legalDocumentBirthdate;
exports.legalDocumentCitizenship = legalDocumentCitizenship;
exports.legalDocumentGender = legalDocumentGender;
exports.phone = phone;
exports.phoneCountryCode = phoneCountryCode;
exports.postalCode = postalCode;
exports.residenceCountry = residenceCountry;
exports.halMarinerNumber = halMarinerNumber;
exports.wasReferred = wasReferred;
exports.blankPass = blankPass;
exports.blankModel = blankModel;
exports.decodeDocGender = decodeDocGender;
exports.decodeDocGenderExn = decodeDocGenderExn;
exports.docGenderInputToDocGender = docGenderInputToDocGender;
exports.docGenderToString = docGenderToString;
exports.docGenderFromString = docGenderFromString;
exports.allLegalDocGenders = allLegalDocGenders;
exports.docGenderNiceName = docGenderNiceName;
exports.modelUtils = modelUtils;
exports.passUtils = passUtils;
exports.reset = reset;
exports.useIsDirty = useIsDirty;
exports.paxViewByBerthNum = paxViewByBerthNum;
exports.voyageApplicationView = voyageApplicationView;
exports.toCustomScalar = toCustomScalar;
exports.initModel = initModel;
exports.resetModelFromConfig = resetModelFromConfig;
exports.resetModelToInitialOrDraftValue = resetModelToInitialOrDraftValue;
exports.setDraftReferrerAsInitial = setDraftReferrerAsInitial;
exports.create = create;
exports.toNewConnected = toNewConnected;
exports.toPaxPatch = toPaxPatch;
exports.generatePaxList = generatePaxList;
exports.generateAvatarInitials = generateAvatarInitials;
exports.getManualBillingLineIdxMap = getManualBillingLineIdxMap;
exports.getManualLineIdx = getManualLineIdx;
exports.CabinQuery = CabinQuery;
exports.generateCabin = generateCabin;
/* firstName Not a pure module */
