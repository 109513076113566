// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Joy$Util = require("util/src/Joy.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Js_string = require("rescript/lib/js/js_string.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Box = require("@mui/joy/Box").default;
var Common$Thick = require("../../utils/Common.bs.js");
var Storage$Util = require("util/src/Storage.bs.js");
var List = require("@mui/joy/List").default;
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var Stack = require("@mui/joy/Stack").default;
var ListItem = require("@mui/joy/ListItem").default;
var JsxRuntime = require("react/jsx-runtime");
var IconButton = require("@mui/joy/IconButton").default;
var Typography = require("@mui/joy/Typography").default;
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var ListDivider = require("@mui/joy/ListDivider").default;
var CabinInventoryFilters$Thick = require("../../utils/CabinInventoryFilters.bs.js");
var ExpandMore = require("@mui/icons-material/ExpandMore").default;
var SegmentSelectorQuery_graphql$Thick = require("../../__generated__/SegmentSelectorQuery_graphql.bs.js");

var convertVariables = SegmentSelectorQuery_graphql$Thick.Internal.convertVariables;

var convertResponse = SegmentSelectorQuery_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = SegmentSelectorQuery_graphql$Thick.Internal.convertWrapRawResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, SegmentSelectorQuery_graphql$Thick.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, SegmentSelectorQuery_graphql$Thick.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(SegmentSelectorQuery_graphql$Thick.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(SegmentSelectorQuery_graphql$Thick.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(SegmentSelectorQuery_graphql$Thick.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(SegmentSelectorQuery_graphql$Thick.node, convertVariables);

var Query = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function SegmentSelector$SegListItem(props) {
  var handleSegmentSelection = props.handleSegmentSelection;
  var voyageSegmentArray = props.voyageSegmentArray;
  var voyageSegment = props.voyageSegment;
  var v = Belt_Array.get(voyageSegmentArray, voyageSegmentArray.length - 1 | 0);
  var label = v !== undefined ? v : voyageSegment;
  var isSelected = Belt_Array.some(props.selectedSegments, (function (s) {
          return s === voyageSegment;
        }));
  return JsxRuntime.jsx(ListItem, {
              children: Caml_option.some(JsxRuntime.jsx(Stack, {
                        direction: "row",
                        justifyContent: "space-between",
                        sx: {
                          width: "100%"
                        },
                        children: Caml_option.some(JsxRuntime.jsx(Joy$Util.ListItemButton.make, {
                                  children: JsxRuntime.jsx(Typography, {
                                        color: isSelected ? "primary" : "",
                                        level: "body-sm",
                                        children: Caml_option.some(Common$Thick.SegDisplay.format(label))
                                      }),
                                  onClick: (function (e) {
                                      handleSegmentSelection(e, voyageSegment);
                                    })
                                }))
                      }))
            });
}

var SegListItem = {
  make: SegmentSelector$SegListItem
};

function SegmentSelector$SegListDropdown(props) {
  var handleSegmentSelection = props.handleSegmentSelection;
  var voyageSegmentArray = props.voyageSegmentArray;
  var voyageSegment = props.voyageSegment;
  var menuKey = "cabins:segment:" + props.voyage + ":" + voyageSegment;
  var match = React.useState(function () {
        var match = Storage$Util.Local.get(menuKey);
        if (match !== undefined) {
          switch (match) {
            case "false" :
                return false;
            case "true" :
                return true;
            default:
              Storage$Util.Local.set(menuKey, "false");
              return false;
          }
        } else {
          Storage$Util.Local.set(menuKey, "false");
          return false;
        }
      });
  var setIsExpanded = match[1];
  var isExpanded = match[0];
  var handleExpand = function (param) {
    var val = isExpanded ? "false" : "true";
    Storage$Util.Local.set(menuKey, val);
    setIsExpanded(function (param) {
          return !isExpanded;
        });
  };
  var v = Belt_Array.get(voyageSegmentArray, voyageSegmentArray.length - 1 | 0);
  var label = v !== undefined ? v : voyageSegment;
  var isSelected = Belt_Array.some(props.selectedSegments, (function (s) {
          return Js_string.startsWith(voyageSegment, s);
        }));
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(ListItem, {
                      children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                direction: "row",
                                justifyContent: "space-between",
                                sx: {
                                  width: "100%"
                                },
                                children: [
                                  JsxRuntime.jsx(Joy$Util.ListItemButton.make, {
                                        children: JsxRuntime.jsx(Typography, {
                                              color: isSelected ? "primary" : "",
                                              level: "body-sm",
                                              children: Caml_option.some(Common$Thick.SegDisplay.format(label))
                                            }),
                                        onClick: (function (e) {
                                            handleSegmentSelection(e, voyageSegment);
                                          })
                                      }),
                                  JsxRuntime.jsx(IconButton, {
                                        onClick: handleExpand,
                                        "aria-label": (
                                          isExpanded ? "Expand" : "Close"
                                        ) + " " + voyageSegment + " menu",
                                        "aria-expanded": isExpanded ? "true" : "false",
                                        children: Caml_option.some(JsxRuntime.jsx(ExpandMore, {
                                                  sx: {
                                                    transform: isExpanded ? "rotate(-180deg)" : "rotate(0deg)",
                                                    transition: "transform 0.2s ease-in-out"
                                                  }
                                                }))
                                      })
                                ]
                              }))
                    }),
                JsxRuntime.jsx(List, {
                      children: Caml_option.some(props.children),
                      sx: {
                        ml: 2,
                        maxHeight: isExpanded ? "500px" : "0px",
                        opacity: isExpanded ? "1" : "0",
                        transition: "max-height 0.2s ease-in-out, opacity 0.3s ease-in-out",
                        paddingBlock: 0
                      }
                    })
              ]
            });
}

var SegListDropdown = {
  make: SegmentSelector$SegListDropdown
};

function renderNode(node, level, voyage, handleSegmentSelection, selectedSegments) {
  var children = node.childSegments.nodes;
  var voyageSegmentArray = node.voyageSegmentArray;
  var voyageSegmentArray$1 = voyageSegmentArray !== undefined ? Belt_Array.keepMap(voyageSegmentArray, (function (v) {
            if (v !== undefined) {
              return v;
            }
            
          })) : [];
  var childComponents = Belt_Array.map(children, (function (childNode) {
          return renderNode(childNode, level + 1 | 0, voyage, handleSegmentSelection, selectedSegments);
        }));
  if (children.length === 0) {
    return JsxRuntime.jsx(SegmentSelector$SegListItem, {
                voyageSegment: node.voyageSegment,
                voyageSegmentArray: voyageSegmentArray$1,
                handleSegmentSelection: handleSegmentSelection,
                selectedSegments: selectedSegments
              }, node.voyageSegment + "-item");
  } else {
    return JsxRuntime.jsx(SegmentSelector$SegListDropdown, {
                voyage: voyage,
                voyageSegment: node.voyageSegment,
                voyageSegmentArray: voyageSegmentArray$1,
                handleSegmentSelection: handleSegmentSelection,
                selectedSegments: selectedSegments,
                children: childComponents
              }, node.voyageSegment + "-dropdown");
  }
}

function generateSegmentList(segments, voyage, handleSegmentSelection, selectedSegments) {
  return Belt_Array.map(segments.nodes, (function (node) {
                return renderNode(node, 1, voyage, handleSegmentSelection, selectedSegments);
              }));
}

function SegmentSelector(props) {
  var clearCabins = props.clearCabins;
  var voyage = props.voyage;
  var segments = use({
        brandFamily: props.brandFamily,
        voyage: voyage
      }, undefined, undefined, undefined);
  var pubPrivSegs = segments.pubPrivSegs;
  var match;
  if (pubPrivSegs !== undefined) {
    var pubNodes = Belt_Array.keepMap(pubPrivSegs.nodes, (function (v) {
            var match = v.rootSegment;
            var match$1 = v.nlevel;
            if (match === "public" && match$1 === 2) {
              return v;
            }
            
          }));
    var privNodes = Belt_Array.keepMap(pubPrivSegs.nodes, (function (v) {
            var match = v.rootSegment;
            var match$1 = v.nlevel;
            if (match === "private" && match$1 === 2) {
              return v;
            }
            
          }));
    match = [
      {
        nodes: pubNodes
      },
      {
        nodes: privNodes
      }
    ];
  } else {
    match = [
      {
        nodes: []
      },
      {
        nodes: []
      }
    ];
  }
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var path = "/" + Belt_Array.joinWith(Belt_List.toArray(url.path), "/", (function (prim) {
          return prim;
        }));
  var qStruct = CabinInventoryFilters$Thick.ColumnFilter.codec.decode(url.search);
  var match$1 = qStruct.segment;
  var selectedSegments;
  if (match$1 !== undefined) {
    switch (match$1.TAG) {
      case "Includes" :
          selectedSegments = [];
          break;
      case "Equals" :
          selectedSegments = [match$1._0];
          break;
      case "InArray" :
          selectedSegments = match$1._0;
          break;
      
    }
  } else {
    selectedSegments = [];
  }
  var match$2 = segments.allSegs;
  var allSegs = match$2 !== undefined ? Belt_Array.map(match$2.nodes, (function (n) {
            return n.voyageSegment;
          })) : [];
  var getAffectedSegments = function (allSegs, clickedSegment) {
    return Belt_Array.keepMap(allSegs, (function (s) {
                  if (Js_string.startsWith(clickedSegment, s)) {
                    return s;
                  }
                  
                }));
  };
  var getNewlySelected = function (selectedSegments, clickedSegment, altPressed) {
    var affectedSegments = getAffectedSegments(allSegs, clickedSegment);
    var allSegmentsIncluded = Belt_Array.every(affectedSegments, (function (s) {
            return Belt_Array.some(selectedSegments, (function (ss) {
                          return ss === s;
                        }));
          }));
    if (altPressed && !allSegmentsIncluded) {
      return Belt_Array.concat(selectedSegments, affectedSegments);
    }
    if (allSegmentsIncluded) {
      return Belt_Array.keepMap(selectedSegments, (function (selected) {
                    if (Belt_Array.some(affectedSegments, (function (affected) {
                              return affected === selected;
                            }))) {
                      return ;
                    } else {
                      return selected;
                    }
                  }));
    } else {
      return affectedSegments;
    }
  };
  var handleSegmentSelection = function (e, clickedSegment) {
    var nativeEvent = e.nativeEvent;
    var altPressed = Belt_Option.getWithDefault(Caml_option.nullable_to_opt(nativeEvent.altKey), false);
    var newlySelected = getNewlySelected(selectedSegments, clickedSegment, altPressed);
    var newQuery_occRange = qStruct.occRange;
    var newQuery_totBerths = qStruct.totBerths;
    var newQuery_segment = {
      TAG: "InArray",
      _0: newlySelected
    };
    var newQuery_class = qStruct.class;
    var newQuery_subclass = qStruct.subclass;
    var newQuery_category = qStruct.category;
    var newQuery_cabin = qStruct.cabin;
    var newQuery_configNote = qStruct.configNote;
    var newQuery_reservationNote = qStruct.reservationNote;
    var newQuery_bookingExist = qStruct.bookingExist;
    var newQuery_cabinStatus = qStruct.cabinStatus;
    var newQuery = {
      occRange: newQuery_occRange,
      totBerths: newQuery_totBerths,
      segment: newQuery_segment,
      class: newQuery_class,
      subclass: newQuery_subclass,
      category: newQuery_category,
      cabin: newQuery_cabin,
      configNote: newQuery_configNote,
      reservationNote: newQuery_reservationNote,
      bookingExist: newQuery_bookingExist,
      cabinStatus: newQuery_cabinStatus
    };
    var newUrl_path = url.path;
    var newUrl_hash = url.hash;
    var newUrl_search = CabinInventoryFilters$Thick.ColumnFilter.codec.encode(newQuery);
    clearCabins();
    RescriptReactRouter.push(path + (
          newUrl_search === "" ? "" : "?"
        ) + newUrl_search);
  };
  return JsxRuntime.jsx(Box, {
              children: Caml_option.some(JsxRuntime.jsxs(List, {
                        children: [
                          JsxRuntime.jsx(Stack, {
                                direction: "row",
                                justifyContent: "space-between",
                                sx: {
                                  width: "100%",
                                  px: 2
                                },
                                children: Caml_option.some(JsxRuntime.jsx(Joy$Util.ListItemButton.make, {
                                          children: JsxRuntime.jsx(Typography, {
                                                color: Caml_obj.notequal(qStruct, CabinInventoryFilters$Thick.$$Storage.empty) ? "" : "primary",
                                                level: "body-sm",
                                                children: "All Staterooms"
                                              }),
                                          onClick: (function (param) {
                                              RescriptReactRouter.push(path);
                                            })
                                        }))
                              }),
                          JsxRuntime.jsx(ListDivider, {}),
                          JsxRuntime.jsxs(Box, {
                                children: [
                                  JsxRuntime.jsx(SegmentSelector$SegListDropdown, {
                                        voyage: CS_Slugs$Util.VoyageSlug.toString(voyage),
                                        voyageSegment: "public",
                                        voyageSegmentArray: [],
                                        handleSegmentSelection: handleSegmentSelection,
                                        selectedSegments: selectedSegments,
                                        children: generateSegmentList(match[0], CS_Slugs$Util.VoyageSlug.toString(voyage), handleSegmentSelection, selectedSegments)
                                      }),
                                  JsxRuntime.jsx(SegmentSelector$SegListDropdown, {
                                        voyage: CS_Slugs$Util.VoyageSlug.toString(voyage),
                                        voyageSegment: "private",
                                        voyageSegmentArray: [],
                                        handleSegmentSelection: handleSegmentSelection,
                                        selectedSegments: selectedSegments,
                                        children: generateSegmentList(match[1], CS_Slugs$Util.VoyageSlug.toString(voyage), handleSegmentSelection, selectedSegments)
                                      })
                                ],
                                sx: {
                                  px: 2
                                }
                              })
                        ]
                      })),
              sx: {
                width: "100%",
                display: "flex",
                flexDirection: "column",
                height: "fit-content"
              }
            });
}

var make = SegmentSelector;

exports.Query = Query;
exports.SegListItem = SegListItem;
exports.SegListDropdown = SegListDropdown;
exports.renderNode = renderNode;
exports.generateSegmentList = generateSegmentList;
exports.make = make;
/* use Not a pure module */
