// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var BrandFamilyPage_query_graphql$Thick = require("../__generated__/BrandFamilyPage_query_graphql.bs.js");

var convertFragment = BrandFamilyPage_query_graphql$Thick.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(BrandFamilyPage_query_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, BrandFamilyPage_query_graphql$Thick.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(BrandFamilyPage_query_graphql$Thick.node, convertFragment, fRef);
}

var QueryFragment_brandFamilyPermission_decode = BrandFamilyPage_query_graphql$Thick.Utils.brandFamilyPermission_decode;

var QueryFragment_brandFamilyPermission_fromString = BrandFamilyPage_query_graphql$Thick.Utils.brandFamilyPermission_fromString;

var QueryFragment = {
  brandFamilyPermission_decode: QueryFragment_brandFamilyPermission_decode,
  brandFamilyPermission_fromString: QueryFragment_brandFamilyPermission_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

function BrandFamilyPage(props) {
  var query = use(props.fragmentRefs);
  var email = query.currentUserEmail;
  var email$1 = email !== undefined ? email : "unknown";
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("h1", {
                      children: "Welcome to " + CS_Slugs$Util.BrandFamilySlug.toString(props.brandFamily) + "!"
                    }),
                JsxRuntime.jsx("p", {
                      children: "Current user email: " + email$1
                    }),
                JsxRuntime.jsx("h2", {
                      children: "This is Initial Production Release of Project Hopper."
                    }),
                JsxRuntime.jsx("p", {
                      children: "In a future release, this page will serve as a dashboard for information from across the entire brand family."
                    }),
                JsxRuntime.jsx("ul", {
                      children: JsxRuntime.jsx("li", {
                            children: JsxRuntime.jsx("a", {
                                  children: "Demo Release 1 Documentation (updated documentation coming Soon™️)",
                                  href: "https://docs.google.com/document/d/19oWnyEb0rJis0x9CiNjixizXkbWTY6wm533Bx-CT33M/edit?usp=sharing",
                                  target: "_blank"
                                })
                          })
                    })
              ]
            });
}

var make = BrandFamilyPage;

exports.QueryFragment = QueryFragment;
exports.make = make;
/* CS_Slugs-Util Not a pure module */
