// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Stack = require("@mui/joy/Stack").default;
var Button = require("@mui/joy/Button").default;
var DraftUtils$Thick = require("./DraftUtils.bs.js");
var HopperState$Util = require("util/src/HopperState.bs.js");
var Checkbox = require("@mui/joy/Checkbox").default;
var BookingDiff$Thick = require("../../../utils/BookingDiff.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var PreviewModal$Thick = require("./PreviewModal.bs.js");
var SharedDrawer$Thick = require("../../common/molecules/SharedDrawer.bs.js");
var Typography = require("@mui/joy/Typography").default;
var CustomerForms$Thick = require("../../common/molecules/CustomerForms.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var PreviewChanges$Thick = require("./PreviewChanges.bs.js");
var DialogActions = require("@mui/joy/DialogActions").default;
var DialogContent = require("@mui/joy/DialogContent").default;
var ManageBookingState$Thick = require("../../../utils/ManageBookingState.bs.js");

function PatchPaxDrawer$SaveOrCancel(props) {
  var applyToUserAcct = props.applyToUserAcct;
  var onClose = props.onClose;
  var versionUrl = props.versionUrl;
  var setError = props.setError;
  var diffStruct = props.diffStruct;
  var passView = props.passView;
  var match = HopperState$Util.Observable.useComputed(passView, (function (p) {
          return [
                  p.idx,
                  p.paxDataStatus,
                  p.isNewConnected,
                  p.isNewConnected ? Belt_Result.isError(ManageBookingState$Thick.toNewConnected(p)) : Belt_Result.isError(ManageBookingState$Thick.toPaxPatch(p))
                ];
        }), undefined, undefined);
  var isNewConnected = match[2];
  var paxDataStatus = match[1];
  var idx = match[0];
  var onSubmit = function (e) {
    e.preventDefault();
    if (isNewConnected) {
      var connected = ManageBookingState$Thick.toNewConnected(HopperState$Util.Observable.dangerouslyRead(passView));
      if (connected.TAG === "Ok") {
        var connected$1 = connected._0;
        var applyDiff;
        if (typeof paxDataStatus !== "object" || paxDataStatus.TAG === "Status") {
          applyDiff = diffStruct;
        } else {
          var match = paxDataStatus._0;
          applyDiff = match === "attach" ? BookingDiff$Thick.Alter.attachNewConnectedPax(diffStruct, idx, connected$1) : (
              match === "replace" ? BookingDiff$Thick.Alter.replaceWithNewConnectedPax(diffStruct, idx, connected$1) : diffStruct
            );
        }
        if (diffStruct === applyDiff) {
          setError(function (param) {
                return "No changes made";
              });
          return ;
        } else {
          DraftUtils$Thick.addToDraft(applyDiff, versionUrl);
          return onClose();
        }
      }
      var err = connected._0;
      return setError(function (param) {
                  return err;
                });
    }
    var patch = ManageBookingState$Thick.toPaxPatch(HopperState$Util.Observable.dangerouslyRead(passView));
    if (patch.TAG === "Ok") {
      var patch$1 = patch._0;
      var patch_accountId = patch$1.accountId;
      var patch_pDocFirstName = patch$1.pDocFirstName;
      var patch_pDocLastName = patch$1.pDocLastName;
      var patch_pDocBirthdate = patch$1.pDocBirthdate;
      var patch_pDocGender = patch$1.pDocGender;
      var patch_pDocCitizenship = patch$1.pDocCitizenship;
      var patch_updateOtherVoyages = patch$1.updateOtherVoyages;
      var patch$2 = {
        accountId: patch_accountId,
        pDocFirstName: patch_pDocFirstName,
        pDocLastName: patch_pDocLastName,
        pDocBirthdate: patch_pDocBirthdate,
        pDocGender: patch_pDocGender,
        pDocCitizenship: patch_pDocCitizenship,
        updateUserAccount: applyToUserAcct,
        updateOtherVoyages: patch_updateOtherVoyages
      };
      var applyDiff$1 = BookingDiff$Thick.Alter.patchPax(diffStruct, idx, patch$2);
      DraftUtils$Thick.addToDraft(applyDiff$1, versionUrl);
      return onClose();
    }
    var err$1 = patch._0;
    setError(function (param) {
          return err$1;
        });
  };
  return JsxRuntime.jsx(DialogActions, {
              children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                        direction: {
                          xs: "column-reverse",
                          sm: "row"
                        },
                        component: "form",
                        justifyContent: "space-between",
                        spacing: 1,
                        sx: {
                          width: "100%",
                          p: 2
                        },
                        onSubmit: onSubmit,
                        children: [
                          JsxRuntime.jsx(Button, {
                                onClick: props.cancel,
                                variant: "outlined",
                                color: "neutral",
                                children: "Cancel"
                              }),
                          JsxRuntime.jsx(Button, {
                                type: "submit",
                                disabled: match[3] || props.loading,
                                children: "Add to draft"
                              })
                        ]
                      }))
            });
}

var SaveOrCancel = {
  make: PatchPaxDrawer$SaveOrCancel
};

function PatchPaxDrawer(props) {
  var fragmentRefs = props.countryFragmentRefs;
  var versionUrl = props.versionUrl;
  var onClose = props.onClose;
  var passView = props.passView;
  var match = HopperState$Util.Observable.useComputed(passView, (function (p) {
          return [
                  p.isNewConnected,
                  p.updateUserAccount
                ];
        }), undefined, undefined);
  var applyToUserAcct = match[1];
  var isNewConnected = match[0];
  var toggleApplyToUserAcct = function () {
    HopperState$Util.Observable.notify(passView, (function (p) {
            var newrecord = Caml_obj.obj_dup(p);
            newrecord.updateUserAccount = !p.updateUserAccount;
            return newrecord;
          }));
  };
  var voyageApplication = ManageBookingState$Thick.voyageApplicationView(passView);
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var diffStruct = BookingDiff$Thick.parse(url.search);
  var match$1 = PreviewChanges$Thick.useQuery(props.generatePreviewFromDiff, diffStruct, onClose);
  var clearPreviewFields = match$1.clearPreviewFields;
  var setError = match$1.setError;
  var setLoading = match$1.setLoading;
  var clearAll = function () {
    clearPreviewFields();
    setLoading(function (param) {
          return false;
        });
    setError(function (param) {
          
        });
  };
  var cancel = function () {
    clearAll();
    onClose();
    HopperState$Util.Observable.notify(passView, (function (p) {
            return ManageBookingState$Thick.reset(p);
          }));
  };
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs(SharedDrawer$Thick.make, {
                      open_: props.open_,
                      title: "Update passenger details",
                      onClose: onClose,
                      children: [
                        JsxRuntime.jsx(DialogContent, {
                              children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                        direction: "column",
                                        spacing: 2,
                                        sx: {
                                          px: 2,
                                          py: 3
                                        },
                                        children: [
                                          isNewConnected ? JsxRuntime.jsx(CustomerForms$Thick.Form.Personal.make, {
                                                  interface: CustomerForms$Thick.Form.Personal.ManageBookingState.$$interface(passView),
                                                  fragmentRefs: fragmentRefs,
                                                  title: "User Profile",
                                                  variant: "Gray"
                                                }) : null,
                                          JsxRuntime.jsx(CustomerForms$Thick.Form.Travel.make, {
                                                interface: CustomerForms$Thick.Form.Travel.ManageBookingState.$$interface(passView),
                                                fragmentRefs: fragmentRefs,
                                                variant: "Gray"
                                              }),
                                          isNewConnected ? null : JsxRuntime.jsxs(Stack, {
                                                  direction: "row",
                                                  alignItems: "center",
                                                  spacing: 1,
                                                  sx: {
                                                    cursor: "pointer"
                                                  },
                                                  onClick: (function (param) {
                                                      toggleApplyToUserAcct();
                                                    }),
                                                  children: [
                                                    JsxRuntime.jsx(Checkbox, {
                                                          checked: applyToUserAcct,
                                                          size: "sm"
                                                        }),
                                                    JsxRuntime.jsx(Typography, {
                                                          level: "body-sm",
                                                          children: "Apply changes to user account"
                                                        })
                                                  ]
                                                }),
                                          JsxRuntime.jsx(CustomerForms$Thick.Form.Voyages.make, {
                                                view: voyageApplication
                                              })
                                        ]
                                      }))
                            }),
                        JsxRuntime.jsx(PatchPaxDrawer$SaveOrCancel, {
                              passView: passView,
                              loading: match$1.loading,
                              diffStruct: diffStruct,
                              setError: setError,
                              versionUrl: versionUrl,
                              onClose: onClose,
                              cancel: cancel,
                              applyToUserAcct: applyToUserAcct
                            })
                      ]
                    }),
                JsxRuntime.jsx(PreviewModal$Thick.make, {
                      billPayCurrent: props.billPayCurrent,
                      billPayNew: match$1.billPayNew,
                      applyDiff: match$1.applyDiff,
                      title: "Update passenger details",
                      description: "Preview changes for update to passenger details.",
                      onClose: (function () {
                          clearAll();
                        }),
                      open_: match$1.previewModalOpen,
                      versionUrl: versionUrl,
                      error: match$1.error
                    })
              ]
            });
}

var make = PatchPaxDrawer;

exports.SaveOrCancel = SaveOrCancel;
exports.make = make;
/* @mui/joy/Stack Not a pure module */
