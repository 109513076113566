// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var ReactRelay = require("react-relay");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");

function makeRefetchVariables(_billingContactAccountId, _brandFamilySlug, _fareClassSlug, _voyageSlug) {
  return {
          _billingContactAccountId: _billingContactAccountId,
          _brandFamilySlug: _brandFamilySlug,
          _fareClassSlug: _fareClassSlug,
          _voyageSlug: _voyageSlug
        };
}

var Types = {
  makeRefetchVariables: makeRefetchVariables
};

var variablesConverter = {"__root":{"_voyageSlug":{"c":"Util.CustomScalars.VoyageSlug.Exn"},"_fareClassSlug":{"c":"Util.CustomScalars.FareClassSlug.Exn"},"_brandFamilySlug":{"c":"Util.CustomScalars.BrandFamilySlug.Exn"},"_billingContactAccountId":{"c":"Util.CustomScalars.AccountId.Exn"}}};

var variablesConverterMap = {
  "Util.CustomScalars.AccountId.Exn": CS_NonemptyStrings$Util.AccountId.Exn.serialize,
  "Util.CustomScalars.BrandFamilySlug.Exn": CS_Slugs$Util.BrandFamilySlug.Exn.serialize,
  "Util.CustomScalars.FareClassSlug.Exn": CS_Slugs$Util.FareClassSlug.Exn.serialize,
  "Util.CustomScalars.VoyageSlug.Exn": CS_Slugs$Util.VoyageSlug.Exn.serialize
};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, variablesConverterMap, undefined);
}

var wrapResponseConverter = {"__root":{"suggestBookingIds":{"ca":"Util.CustomScalars.BookingId.Exn"}}};

var wrapResponseConverterMap = {
  "Util.CustomScalars.BookingId.Exn": CS_NonemptyStrings$Util.BookingId.Exn.serialize
};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, wrapResponseConverterMap, null);
}

var responseConverter = {"__root":{"suggestBookingIds":{"ca":"Util.CustomScalars.BookingId.Exn"}}};

var responseConverterMap = {
  "Util.CustomScalars.BookingId.Exn": CS_NonemptyStrings$Util.BookingId.Exn.parse
};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, responseConverterMap, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: variablesConverterMap,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: wrapResponseConverterMap,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: responseConverterMap,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse
};

var Utils = {};

var node = ((function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "_billingContactAccountId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "_brandFamilySlug"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "_fareClassSlug"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "_voyageSlug"
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_billingContactAccountId",
        "variableName": "_billingContactAccountId"
      },
      {
        "kind": "Variable",
        "name": "_brandFamilySlug",
        "variableName": "_brandFamilySlug"
      },
      {
        "kind": "Variable",
        "name": "_fareClassSlug",
        "variableName": "_fareClassSlug"
      },
      {
        "kind": "Variable",
        "name": "_voyageSlug",
        "variableName": "_voyageSlug"
      }
    ],
    "kind": "ScalarField",
    "name": "suggestBookingIds",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteOrBookModalSuggestQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "DeleteOrBookModalSuggestQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "762afc1230ef9ec3fc56242dd03bd4f8",
    "id": null,
    "metadata": {},
    "name": "DeleteOrBookModalSuggestQuery",
    "operationKind": "query",
    "text": "query DeleteOrBookModalSuggestQuery(\n  $_brandFamilySlug: BrandFamilySlug!\n  $_voyageSlug: VoyageSlug!\n  $_fareClassSlug: FareClassSlug!\n  $_billingContactAccountId: AccountId!\n) {\n  suggestBookingIds(_brandFamilySlug: $_brandFamilySlug, _voyageSlug: $_voyageSlug, _fareClassSlug: $_fareClassSlug, _billingContactAccountId: $_billingContactAccountId)\n}\n"
  }
};
})());

function load(environment, variables, fetchPolicy, fetchKey, networkCacheConfig) {
  return ReactRelay.loadQuery(environment, node, convertVariables(variables), {
              fetchKey: fetchKey,
              fetchPolicy: fetchPolicy,
              networkCacheConfig: networkCacheConfig
            });
}

function queryRefToObservable(token) {
  return Caml_option.nullable_to_opt(token.source);
}

function queryRefToPromise(token) {
  return new Promise((function (resolve, param) {
                var o = queryRefToObservable(token);
                if (o !== undefined) {
                  Caml_option.valFromOption(o).subscribe({
                        complete: (function () {
                            resolve({
                                  TAG: "Ok",
                                  _0: undefined
                                });
                          })
                      });
                  return ;
                } else {
                  return resolve({
                              TAG: "Error",
                              _0: undefined
                            });
                }
              }));
}

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
exports.load = load;
exports.queryRefToObservable = queryRefToObservable;
exports.queryRefToPromise = queryRefToPromise;
/* node Not a pure module */
