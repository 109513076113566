// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var JsxRuntime = require("react/jsx-runtime");

function AppLink(props) {
  var searchParams = props.searchParams;
  var __textColor = props.textColor;
  var __underline = props.underline;
  var __color = props.color;
  var href = props.to;
  var color = __color !== undefined ? __color : "primary";
  var underline = __underline !== undefined ? __underline : "hover";
  var textColor = __textColor !== undefined ? __textColor : "";
  var isTargetBlank = Belt_Option.getWithDefault(props.forceTargetBlank, false);
  var target = isTargetBlank ? "_blank" : undefined;
  var href$1;
  if (href !== undefined) {
    if (searchParams !== undefined) {
      var dict = Object.fromEntries(searchParams);
      var searchParams$1 = new URLSearchParams(dict);
      var search = searchParams$1.toString();
      href$1 = href + "?" + search;
    } else {
      href$1 = href;
    }
  } else {
    href$1 = undefined;
  }
  return JsxRuntime.jsx(Joy$Util.Link.make, {
              children: props.children,
              href: href$1,
              underline: underline,
              textColor: textColor,
              color: color,
              target: target,
              onClick: props.onClick
            });
}

var Dict;

var make = AppLink;

exports.Dict = Dict;
exports.make = make;
/* Joy-Util Not a pure module */
