// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thick = require("../../../utils/Common.bs.js");
var RelayRuntime = require("relay-runtime");
var RelayEnv$Thick = require("../../../RelayEnv.bs.js");
var ReactState$Util = require("util/src/ReactState.bs.js");
var RemoteData$Util = require("util/src/RemoteData.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");

function useMutation(refetch, redirectUrl, refetchAppConfig, onSuccess) {
  var localRequestState = React.useState(function () {
        return "NotAsked";
      });
  var isMutating = ReactState$Util.getter(localRequestState) === "Loading";
  var match = React.useState(function () {
        return "";
      });
  var email = match[0];
  var match$1 = React.useState(function () {
        return "";
      });
  var password = match$1[0];
  var match$2 = React.useState(function () {
        
      });
  var setError = match$2[1];
  var onSubmit = function (e) {
    e.preventDefault();
    if (email === "" || password === "" || !Common$Thick.Email.isValid(email)) {
      return ;
    } else {
      setError(function (param) {
            
          });
      return RemoteData$Util.$$Promise.makeStatefulEffect(Common$Thick.cookieLogin({
                      allegedEmail: email,
                      allegedPass: password
                    }, Caml_option.some((function () {
                            refetch();
                            refetchAppConfig();
                            RelayRuntime.commitLocalUpdate(RelayEnv$Thick.environment, (function (store) {
                                    store.invalidateStore();
                                  }));
                            if (onSuccess !== undefined) {
                              onSuccess();
                            }
                            RescriptReactRouter.push(redirectUrl);
                          })), Caml_option.some((function (eString) {
                            setError(function (param) {
                                  console.log(eString);
                                  return eString;
                                });
                          }))), localRequestState);
    }
  };
  return {
          isMutating: isMutating,
          email: email,
          setEmail: match[1],
          password: password,
          setPassword: match$1[1],
          error: match$2[0],
          onSubmit: onSubmit,
          setError: setError
        };
}

exports.useMutation = useMutation;
/* react Not a pure module */
