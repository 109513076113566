// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Stack = require("@mui/joy/Stack").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var MergeAccounts$Thick = require("../../utils/MergeAccounts.bs.js");
var MergeabilityCheck$Thick = require("./MergeabilityCheck.bs.js");
var CurrentUserSearchInput$Thick = require("../common/molecules/CurrentUserSearchInput.bs.js");

function MergeAccountSelection(props) {
  var state = props.state;
  var brandFamily = props.brandFamily;
  return JsxRuntime.jsxs(Stack, {
              spacing: 2,
              children: [
                JsxRuntime.jsx(Typography, {
                      level: "h4",
                      component: "h2",
                      children: "Select Accounts to Merge"
                    }),
                JsxRuntime.jsxs(Stack, {
                      direction: "row",
                      spacing: 1,
                      children: [
                        JsxRuntime.jsx(CurrentUserSearchInput$Thick.make, {
                              view: {
                                TAG: "Direct",
                                _0: state
                              },
                              label: "Source Account",
                              input: MergeAccounts$Thick.sourceAccountId,
                              brandFamily: brandFamily,
                              claimedAndPrefilledOnly: false,
                              excludeStatuses: [
                                "DELETED",
                                "INVITED"
                              ]
                            }),
                        JsxRuntime.jsx(CurrentUserSearchInput$Thick.make, {
                              view: {
                                TAG: "Direct",
                                _0: state
                              },
                              label: "Destination Account",
                              input: MergeAccounts$Thick.destAccountId,
                              brandFamily: brandFamily,
                              claimedAndPrefilledOnly: false,
                              excludeStatuses: [
                                "DELETED",
                                "INVITED"
                              ]
                            }),
                        JsxRuntime.jsx(MergeabilityCheck$Thick.make, {
                              view: state,
                              brandFamily: brandFamily
                            })
                      ]
                    })
              ]
            });
}

var make = MergeAccountSelection;

exports.make = make;
/* @mui/joy/Stack Not a pure module */
