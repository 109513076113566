// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Dialog$Thick = require("../common/molecules/Dialog.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var DateOnly$Util = require("util/src/DateOnly.bs.js");
var Spinner$Thick = require("../common/atoms/Spinner.bs.js");
var Stack = require("@mui/joy/Stack").default;
var BSDrawer$Thick = require("./BSDrawer.bs.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var Button = require("@mui/joy/Button").default;
var BSPInputs$Thick = require("./BSPInputs.bs.js");
var HopperState$Util = require("util/src/HopperState.bs.js");
var CS_ISO3166_1$Util = require("util/src/CustomScalars/CS_ISO3166_1.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var BookingSession$Thick = require("../../utils/BookingSession.bs.js");
var DialogActions = require("@mui/joy/DialogActions").default;
var DialogContent = require("@mui/joy/DialogContent").default;
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var Save = require("@mui/icons-material/Save").default;
var CS_SQLDatesAndTimes$Util = require("util/src/CustomScalars/CS_SQLDatesAndTimes.bs.js");
var BSForms_query_graphql$Thick = require("../../__generated__/BSForms_query_graphql.bs.js");
var CurrentUserSearchInput$Thick = require("../common/molecules/CurrentUserSearchInput.bs.js");
var WarningAmber = require("@mui/icons-material/WarningAmber").default;

function BSForms$CreateUpdateDrawer$WithPrefill(props) {
  var form = props.form;
  var match = BookingSession$Thick.Query.FetchOne.use({
        bookingSessionId: props.extantBookingSessionId
      }, "store-and-network", props.fetchKey, undefined);
  var frag = BookingSession$Thick.Query.Single.useOpt(Belt_Option.map(match.bookingSession, (function (param) {
              return param.fragmentRefs;
            })));
  var doPrefill = function () {
    return Belt_Option.map(frag, (function (sess) {
                  BookingSession$Thick.prefill(form, sess);
                }));
  };
  React.useEffect((function () {
          doPrefill();
        }), [Belt_Option.getWithDefault(JSON.stringify(frag), "")]);
  return null;
}

var WithPrefill = {
  make: BSForms$CreateUpdateDrawer$WithPrefill
};

function BSForms$CreateUpdateDrawer$WithSomePaxForm$ExistingUser(props) {
  return JsxRuntime.jsx(CurrentUserSearchInput$Thick.Optional.make, {
              view: {
                TAG: "Direct",
                _0: props.paxForm
              },
              placeholder: "Search users",
              label: "User Account",
              required: true,
              input: BookingSession$Thick.existingUserAccountId,
              brandFamily: props.brandFamily,
              claimedAndPrefilledOnly: false,
              debounce: 1000
            });
}

var ExistingUser = {
  make: BSForms$CreateUpdateDrawer$WithSomePaxForm$ExistingUser
};

function BSForms$CreateUpdateDrawer$WithSomePaxForm$NewInvitation(props) {
  var onClose = props.onClose;
  var paxForm = props.paxForm;
  var userInfo = HopperState$Util.Observable.useComputed(paxForm, (function (param) {
          var npf = param.newPreferredFirstName.value;
          var ie = param.invitationEmail.value;
          if (npf !== undefined && ie !== undefined) {
            return [
                    npf + Belt_Option.mapWithDefault(param.newPreferredLastName.value, "", (function (v) {
                            return " " + v;
                          })),
                    ie
                  ];
          }
          
        }), undefined, undefined);
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs(Stack, {
                      direction: "column",
                      spacing: 2,
                      children: [
                        JsxRuntime.jsxs(Stack, {
                              direction: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                              children: [
                                JsxRuntime.jsx(Stack, {
                                      direction: "column",
                                      children: Caml_option.some(userInfo !== undefined ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                                  children: [
                                                    JsxRuntime.jsx(Typography, {
                                                          level: "body-sm",
                                                          noWrap: true,
                                                          children: Caml_option.some(userInfo[0])
                                                        }),
                                                    JsxRuntime.jsx(Typography, {
                                                          level: "body-sm",
                                                          noWrap: true,
                                                          children: Caml_option.some(userInfo[1])
                                                        })
                                                  ]
                                                }) : JsxRuntime.jsx(Typography, {
                                                  level: "body-sm",
                                                  children: "No user info provided."
                                                }))
                                    }),
                                JsxRuntime.jsx(Stack, {
                                      children: Caml_option.some(JsxRuntime.jsx(Button, {
                                                onClick: Caml_option.some(props.onOpen),
                                                variant: "solid",
                                                size: "sm",
                                                children: "Edit"
                                              }))
                                    })
                              ]
                            }),
                        JsxRuntime.jsx(Typography, {
                              level: "body-sm",
                              sx: {
                                fontStyle: "italic"
                              },
                              children: "Note: an invitation will be sent when session is successfully booked."
                            })
                      ]
                    }),
                JsxRuntime.jsxs(Dialog$Thick.make, {
                      open_: props.modalOpen,
                      onClose: onClose,
                      title: "Invite a new user",
                      children: [
                        JsxRuntime.jsx(DialogContent, {
                              children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                        direction: "column",
                                        spacing: 1,
                                        children: [
                                          JsxRuntime.jsx(BSPInputs$Thick.$$Text.Optional.make, {
                                                view: {
                                                  TAG: "Direct",
                                                  _0: paxForm
                                                },
                                                input: BookingSession$Thick.invitationEmail,
                                                label: "Invitation Email",
                                                helperText: "Email address to which an invitation will be sent",
                                                required: true,
                                                fullWidth: true
                                              }),
                                          JsxRuntime.jsx(BSPInputs$Thick.$$Text.Optional.make, {
                                                view: {
                                                  TAG: "Direct",
                                                  _0: paxForm
                                                },
                                                input: BookingSession$Thick.newPreferredFirstName,
                                                label: "Preferred First Name",
                                                helperText: "Guest's preferred first name",
                                                required: true,
                                                fullWidth: true
                                              }),
                                          JsxRuntime.jsx(BSPInputs$Thick.$$Text.Optional.make, {
                                                view: {
                                                  TAG: "Direct",
                                                  _0: paxForm
                                                },
                                                input: BookingSession$Thick.newPreferredLastName,
                                                label: "Preferred Last Name",
                                                helperText: "Guest's preferred last name",
                                                required: true,
                                                fullWidth: true
                                              })
                                        ]
                                      }))
                            }),
                        JsxRuntime.jsx(DialogActions, {
                              children: Caml_option.some(JsxRuntime.jsx(Button, {
                                        onClick: onClose,
                                        variant: "soft",
                                        children: "Done Editing"
                                      }))
                            })
                      ]
                    })
              ]
            });
}

var NewInvitation = {
  make: BSForms$CreateUpdateDrawer$WithSomePaxForm$NewInvitation
};

function BSForms$CreateUpdateDrawer$WithSomePaxForm$NewConnectedUser(props) {
  var onClose = props.onClose;
  var fragmentRefs = props.fragmentRefs;
  var paxForm = props.paxForm;
  var userInfo = HopperState$Util.Observable.useComputed(paxForm, (function (param) {
          var nc = param.newDocCitizenship.value;
          var ndl = param.newDocLastname.value;
          var ndf = param.newDocFirstname.value;
          var match = Belt_Option.map(param.newDocBirthdate.value, CS_SQLDatesAndTimes$Util.DateOnly.fromPrimitive);
          if (ndf !== undefined && ndl !== undefined && nc !== undefined && match !== undefined && match.TAG === "Ok") {
            return [
                    Belt_Option.getWithDefault(param.newPreferredFirstName.value, ndf) + Belt_Option.mapWithDefault(param.newPreferredLastName.value, " " + ndl, (function (v) {
                            return " " + v;
                          })),
                    CS_ISO3166_1$Util.Alpha3.toString(Caml_option.valFromOption(nc)) + " - DOB " + DateOnly$Util.format(match._0, "MM/dd/yyyy")
                  ];
          }
          
        }), undefined, undefined);
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs(Stack, {
                      direction: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      children: [
                        JsxRuntime.jsx(Stack, {
                              direction: "column",
                              children: Caml_option.some(userInfo !== undefined ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                          children: [
                                            JsxRuntime.jsx(Typography, {
                                                  level: "body-sm",
                                                  noWrap: true,
                                                  children: Caml_option.some(userInfo[0])
                                                }),
                                            JsxRuntime.jsx(Typography, {
                                                  level: "body-sm",
                                                  noWrap: true,
                                                  children: Caml_option.some(userInfo[1])
                                                })
                                          ]
                                        }) : JsxRuntime.jsx(Typography, {
                                          level: "body-sm",
                                          children: "No user info provided."
                                        }))
                            }),
                        JsxRuntime.jsx(Stack, {
                              children: Caml_option.some(JsxRuntime.jsx(Button, {
                                        onClick: Caml_option.some(props.onOpen),
                                        variant: "solid",
                                        size: "sm",
                                        children: "Edit"
                                      }))
                            })
                      ]
                    }),
                JsxRuntime.jsxs(Dialog$Thick.make, {
                      open_: props.modalOpen,
                      onClose: onClose,
                      title: "Create a new connected account",
                      children: [
                        JsxRuntime.jsx(DialogContent, {
                              children: Caml_option.some(JsxRuntime.jsxs(Joy$Util.Grid.make, {
                                        children: [
                                          JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                children: Caml_option.some(JsxRuntime.jsx(CurrentUserSearchInput$Thick.Optional.make, {
                                                          view: {
                                                            TAG: "Direct",
                                                            _0: paxForm
                                                          },
                                                          placeholder: "Search users",
                                                          label: "Custodian Account",
                                                          helperText: "This user will be created as an account managed by the custodian supplied here",
                                                          required: true,
                                                          input: BookingSession$Thick.newCustodianAccountId,
                                                          brandFamily: props.brandFamily,
                                                          claimedAndPrefilledOnly: true
                                                        })),
                                                xs: 12
                                              }),
                                          JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                children: Caml_option.some(JsxRuntime.jsx(BSPInputs$Thick.$$Text.Optional.make, {
                                                          view: {
                                                            TAG: "Direct",
                                                            _0: paxForm
                                                          },
                                                          input: BookingSession$Thick.newDocFirstname,
                                                          label: "Legal Document First Name",
                                                          helperText: "Last name as it appears on the guest's travel documents",
                                                          required: true,
                                                          fullWidth: true
                                                        })),
                                                xs: 12,
                                                md: 6
                                              }),
                                          JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                children: Caml_option.some(JsxRuntime.jsx(BSPInputs$Thick.$$Text.Optional.make, {
                                                          view: {
                                                            TAG: "Direct",
                                                            _0: paxForm
                                                          },
                                                          input: BookingSession$Thick.newDocLastname,
                                                          label: "Legal Document Last Name",
                                                          helperText: "Last name as it appears on guest's travel documents",
                                                          required: true,
                                                          fullWidth: true
                                                        })),
                                                xs: 12,
                                                md: 6
                                              }),
                                          JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                children: Caml_option.some(JsxRuntime.jsx(BSPInputs$Thick.$$Date.make, {
                                                          view: {
                                                            TAG: "Direct",
                                                            _0: paxForm
                                                          },
                                                          input: BookingSession$Thick.newDocBirthdate,
                                                          label: "Legal Document Birthdate",
                                                          helperText: "Birthdate as it appears on guest's travel documents",
                                                          placeholder: "Birthdate",
                                                          required: true,
                                                          fullWidth: true
                                                        })),
                                                xs: 12,
                                                md: 6
                                              }),
                                          JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                children: Caml_option.some(JsxRuntime.jsx(BSPInputs$Thick.Country.make, {
                                                          view: {
                                                            TAG: "Direct",
                                                            _0: paxForm
                                                          },
                                                          input: BookingSession$Thick.newDocCitizenship,
                                                          label: "Legal Document Citizenship",
                                                          helperText: "Citizenship as it appears on guest's travel documents",
                                                          countryFragment: fragmentRefs,
                                                          placeholder: "Select...",
                                                          required: true,
                                                          fullWidth: true
                                                        })),
                                                xs: 12,
                                                md: 6
                                              }),
                                          JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                children: Caml_option.some(JsxRuntime.jsx(BSPInputs$Thick.Select.make, {
                                                          view: {
                                                            TAG: "Direct",
                                                            _0: paxForm
                                                          },
                                                          placeholder: "Select...",
                                                          label: "Legal Document Gender",
                                                          helperText: "Gender as it appears on guest's travel documents",
                                                          input: BookingSession$Thick.newDocGender,
                                                          options: Belt_Array.map(BookingSession$Thick.Query.allLegalDocGenders, (function (dg) {
                                                                  return {
                                                                          primitive: dg,
                                                                          key: BookingSession$Thick.Query.docGenderToString(dg),
                                                                          niceName: BookingSession$Thick.Query.docGenderNiceName(dg)
                                                                        };
                                                                })),
                                                          required: true,
                                                          fullWidth: true
                                                        })),
                                                xs: 12,
                                                md: 6
                                              }),
                                          JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                children: Caml_option.some(JsxRuntime.jsx(BSPInputs$Thick.$$Text.Optional.make, {
                                                          view: {
                                                            TAG: "Direct",
                                                            _0: paxForm
                                                          },
                                                          input: BookingSession$Thick.newDocHalMarinerNumber,
                                                          label: "HAL Mariner Number",
                                                          helperText: "Holland America Mariner number (if any)",
                                                          required: false,
                                                          fullWidth: true
                                                        })),
                                                xs: 12,
                                                md: 6
                                              }),
                                          JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                children: Caml_option.some(JsxRuntime.jsx(BSPInputs$Thick.Phone.make, {
                                                          countryFragment: fragmentRefs,
                                                          view: paxForm,
                                                          phone: BookingSession$Thick.phone,
                                                          phoneCountryCode: BookingSession$Thick.phoneCountryCode,
                                                          required: false,
                                                          label: "Phone Number",
                                                          helperText: "Guest's primary phone number"
                                                        })),
                                                xs: 12,
                                                md: 6
                                              }),
                                          JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                children: Caml_option.some(JsxRuntime.jsx(BSPInputs$Thick.$$Text.Optional.make, {
                                                          view: {
                                                            TAG: "Direct",
                                                            _0: paxForm
                                                          },
                                                          input: BookingSession$Thick.newDocPostalCode,
                                                          label: "Current Postal Code",
                                                          helperText: "Guests's current residence postal code",
                                                          required: false,
                                                          fullWidth: true
                                                        })),
                                                xs: 12,
                                                md: 6
                                              }),
                                          JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                children: Caml_option.some(JsxRuntime.jsx(BSPInputs$Thick.Country.make, {
                                                          view: {
                                                            TAG: "Direct",
                                                            _0: paxForm
                                                          },
                                                          input: BookingSession$Thick.newDocResidenceCountry,
                                                          label: "Country of Residence",
                                                          helperText: "Guest's current country of residence",
                                                          countryFragment: fragmentRefs,
                                                          placeholder: "Select..",
                                                          required: false,
                                                          fullWidth: true
                                                        })),
                                                xs: 12,
                                                md: 6
                                              }),
                                          JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                children: Caml_option.some(JsxRuntime.jsx(BSPInputs$Thick.$$Text.Optional.make, {
                                                          view: {
                                                            TAG: "Direct",
                                                            _0: paxForm
                                                          },
                                                          input: BookingSession$Thick.newPreferredFirstName,
                                                          label: "Preferred First Name",
                                                          helperText: "Guest's preferred first name",
                                                          required: false,
                                                          fullWidth: true
                                                        })),
                                                xs: 12,
                                                md: 6
                                              }),
                                          JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                children: Caml_option.some(JsxRuntime.jsx(BSPInputs$Thick.$$Text.Optional.make, {
                                                          view: {
                                                            TAG: "Direct",
                                                            _0: paxForm
                                                          },
                                                          input: BookingSession$Thick.newPreferredLastName,
                                                          label: "Preferred Last Name",
                                                          helperText: "Guest's preferred last name",
                                                          required: false,
                                                          fullWidth: true
                                                        })),
                                                xs: 12,
                                                md: 6
                                              })
                                        ],
                                        container: true,
                                        spacing: {
                                          TAG: "Fixed",
                                          _0: 2
                                        },
                                        sx: {
                                          width: "100%"
                                        }
                                      }))
                            }),
                        JsxRuntime.jsx(DialogActions, {
                              children: Caml_option.some(JsxRuntime.jsx(Button, {
                                        onClick: onClose,
                                        variant: "soft",
                                        children: "Done Editing"
                                      }))
                            })
                      ]
                    })
              ]
            });
}

var NewConnectedUser = {
  make: BSForms$CreateUpdateDrawer$WithSomePaxForm$NewConnectedUser
};

function BSForms$CreateUpdateDrawer$WithSomePaxForm(props) {
  var parentForm = props.parentForm;
  var paxIdx = props.paxIdx;
  var brandFamily = props.brandFamily;
  var paxForm = BookingSession$Thick.paxExn(props.paxForm);
  var match = HopperState$Util.Observable.FormField.use(paxForm, BookingSession$Thick.bookingSessionPassengerType, undefined);
  var paxType = match[0].value;
  var modalOpen = HopperState$Util.Observable.useComputed(parentForm, (function (f) {
          var idx = f.focusedPax;
          if (idx !== undefined) {
            return idx === paxIdx;
          } else {
            return false;
          }
        }), undefined, undefined);
  var onClose = function (param) {
    HopperState$Util.Observable.notify(parentForm, (function (f) {
            return {
                    target: f.target,
                    billingContactAccountId: f.billingContactAccountId,
                    isBfcustFacing: f.isBfcustFacing,
                    bookingChannelSlug: f.bookingChannelSlug,
                    fareClassSlug: f.fareClassSlug,
                    cabinNumber: f.cabinNumber,
                    forOccupancy: f.forOccupancy,
                    pifIntention: f.pifIntention,
                    descriptiveNote: f.descriptiveNote,
                    focusedPax: undefined,
                    pax: f.pax,
                    privateReservationNote: f.privateReservationNote
                  };
          }));
  };
  var onOpen = function (param) {
    HopperState$Util.Observable.notify(parentForm, (function (f) {
            return {
                    target: f.target,
                    billingContactAccountId: f.billingContactAccountId,
                    isBfcustFacing: f.isBfcustFacing,
                    bookingChannelSlug: f.bookingChannelSlug,
                    fareClassSlug: f.fareClassSlug,
                    cabinNumber: f.cabinNumber,
                    forOccupancy: f.forOccupancy,
                    pifIntention: f.pifIntention,
                    descriptiveNote: f.descriptiveNote,
                    focusedPax: paxIdx,
                    pax: f.pax,
                    privateReservationNote: f.privateReservationNote
                  };
          }));
  };
  if (paxType === undefined) {
    return null;
  }
  switch (paxType) {
    case "EXISTING_USER" :
        return JsxRuntime.jsx(BSForms$CreateUpdateDrawer$WithSomePaxForm$ExistingUser, {
                    paxForm: paxForm,
                    brandFamily: brandFamily
                  });
    case "NEW_INVITATION" :
        return JsxRuntime.jsx(BSForms$CreateUpdateDrawer$WithSomePaxForm$NewInvitation, {
                    paxForm: paxForm,
                    modalOpen: modalOpen,
                    onOpen: onOpen,
                    onClose: onClose
                  });
    case "NEW_CONNECTED_USER" :
        return JsxRuntime.jsx(BSForms$CreateUpdateDrawer$WithSomePaxForm$NewConnectedUser, {
                    paxForm: paxForm,
                    fragmentRefs: props.fragmentRefs,
                    brandFamily: brandFamily,
                    modalOpen: modalOpen,
                    onOpen: onOpen,
                    onClose: onClose
                  });
    
  }
}

var WithSomePaxForm = {
  ExistingUser: ExistingUser,
  NewInvitation: NewInvitation,
  NewConnectedUser: NewConnectedUser,
  make: BSForms$CreateUpdateDrawer$WithSomePaxForm
};

function BSForms$CreateUpdateDrawer$PaxForm(props) {
  var paxIdx = props.paxIdx;
  var form = props.form;
  var paxForm = BookingSession$Thick.paxView(form, paxIdx);
  var hasPax = HopperState$Util.Observable.useComputed(paxForm, Belt_Option.isSome, undefined, undefined);
  var paxHasError = HopperState$Util.Observable.useComputed(paxForm, (function (o) {
          if (o !== undefined) {
            return Belt_Result.isError(BookingSession$Thick.validatePax(o, undefined));
          } else {
            return false;
          }
        }), undefined, undefined);
  var hasPaxType = HopperState$Util.Observable.useComputed(paxForm, (function (f) {
          return Belt_Option.getWithDefault(Belt_Option.map(f, (function (p) {
                            return p.bookingSessionPassengerType.value !== undefined;
                          })), false);
        }), undefined, undefined);
  var options = Belt_Array.concat([{
          primitive: undefined,
          key: "dummypax",
          niceName: "Leave this passenger blank"
        }], Belt_Array.map(BookingSession$Thick.Query.getLegalPaxTypesForPaxIdx(paxIdx), (function (lpt) {
              return {
                      primitive: lpt,
                      key: BookingSession$Thick.Query.paxTypeToString(lpt),
                      niceName: BookingSession$Thick.Query.paxTypeNiceName(lpt)
                    };
            })));
  var showError = paxHasError && hasPaxType;
  return JsxRuntime.jsxs(Stack, {
              direction: "column",
              spacing: 1,
              sx: {
                p: 2,
                backgroundColor: showError ? "danger.200" : "primary.200",
                borderRadius: "sm",
                width: "275px"
              },
              children: [
                JsxRuntime.jsx(Typography, {
                      startDecorator: Caml_option.some(showError ? JsxRuntime.jsx(WarningAmber, {}) : null),
                      level: "body-md",
                      sx: {
                        fontWeight: "bold"
                      },
                      children: Caml_option.some("Passenger " + String(paxIdx))
                    }),
                JsxRuntime.jsx(BSPInputs$Thick.Select.make, {
                      view: {
                        TAG: "Optional",
                        _0: paxForm,
                        _1: BookingSession$Thick.blankPax(paxIdx)
                      },
                      label: "Passenger Type",
                      input: BookingSession$Thick.bookingSessionPassengerType,
                      options: options,
                      afterSelect: (function () {
                          HopperState$Util.Observable.notify(form, (function (f) {
                                  return {
                                          target: f.target,
                                          billingContactAccountId: f.billingContactAccountId,
                                          isBfcustFacing: f.isBfcustFacing,
                                          bookingChannelSlug: f.bookingChannelSlug,
                                          fareClassSlug: f.fareClassSlug,
                                          cabinNumber: f.cabinNumber,
                                          forOccupancy: f.forOccupancy,
                                          pifIntention: f.pifIntention,
                                          descriptiveNote: f.descriptiveNote,
                                          focusedPax: paxIdx,
                                          pax: f.pax,
                                          privateReservationNote: f.privateReservationNote
                                        };
                                }));
                        })
                    }),
                hasPax ? JsxRuntime.jsx(BSForms$CreateUpdateDrawer$WithSomePaxForm, {
                        paxForm: paxForm,
                        fragmentRefs: props.fragmentRefs,
                        brandFamily: props.brandFamily,
                        paxIdx: paxIdx,
                        parentForm: form
                      }) : null
              ]
            });
}

var PaxForm = {
  make: BSForms$CreateUpdateDrawer$PaxForm
};

function BSForms$CreateUpdateDrawer$WithSomeForm$SaveButton(props) {
  var form = props.form;
  var triggerRefetch = props.triggerRefetch;
  var isEditContext = props.isEditContext;
  var validatedForm = BookingSession$Thick.useValidatedForm(form, props.brandFamily, props.voyage, undefined, undefined);
  var formHasErrors = Belt_Result.isError(validatedForm);
  var match = BookingSession$Thick.Query.Save.use();
  var isMutating = match[1];
  var mut = match[0];
  var isDirty = BookingSession$Thick.useIsDirty(form);
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(Button, {
                      onClick: (function (param) {
                          if (validatedForm.TAG !== "Ok") {
                            return BookingSession$Thick.markAsTouched(form);
                          }
                          mut({
                                input: validatedForm._0
                              }, undefined, undefined, undefined, (function (response, param) {
                                  var match = response.saveBookingSession;
                                  if (match === undefined) {
                                    return ;
                                  }
                                  var match$1 = match.boolean;
                                  if (match$1 !== undefined && match$1) {
                                    return triggerRefetch();
                                  }
                                  
                                }), undefined, undefined);
                        }),
                      startDecorator: Caml_option.some(JsxRuntime.jsx(Save, {})),
                      variant: "soft",
                      color: formHasErrors ? "warning" : "primary",
                      size: "lg",
                      disabled: isMutating,
                      children: Caml_option.some(isMutating ? "Loading" : (
                              isEditContext ? "Update Booking Session" : "Save New Booking Session"
                            ))
                    }),
                JsxRuntime.jsx(Typography, {
                      level: "body-sm",
                      children: Caml_option.some(formHasErrors ? "This form has errors and cannot be saved. Click the Save button to show all error messages." : (
                              isEditContext ? (
                                  isDirty ? "This form has been modified from its original version" : "This form has not been modified"
                                ) : "Save New Form"
                            ))
                    })
              ]
            });
}

var SaveButton = {
  make: BSForms$CreateUpdateDrawer$WithSomeForm$SaveButton
};

var convertFragment = BSForms_query_graphql$Thick.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(BSForms_query_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, BSForms_query_graphql$Thick.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(BSForms_query_graphql$Thick.node, convertFragment, fRef);
}

var QueryFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

function BSForms$CreateUpdateDrawer$WithSomeForm$BookingChannelFareClass(props) {
  var form = props.form;
  var data = use(props.bcfcFragmentRef);
  var match = data.bookingChannelFareClasses;
  var match$1 = match !== undefined ? Belt_Array.reduce(match.nodes, [
          [],
          undefined
        ], (function (acc, param) {
            var bookingChannelSlug = param.bookingChannelSlug;
            var fcSlug = CS_Slugs$Util.FareClassSlug.toString(param.fareClassSlug);
            var map = acc[1];
            var bcOpts = acc[0];
            var fcOpt = {
              primitive: fcSlug,
              key: fcSlug,
              niceName: fcSlug
            };
            var bcOpt = {
              primitive: bookingChannelSlug,
              key: bookingChannelSlug,
              niceName: bookingChannelSlug
            };
            var bcOpts$1 = Belt_Array.some(bcOpts, (function (s) {
                    return s.key === bookingChannelSlug;
                  })) ? bcOpts : Belt_Array.concat(bcOpts, [bcOpt]);
            var fcs = Belt_MapString.getWithDefault(map, bookingChannelSlug, []);
            var bcToFcArray = fcs.length !== 0 ? (
                Belt_Array.some(fcs, (function (s) {
                        return s.key === fcSlug;
                      })) ? fcs : Belt_Array.concat(fcs, [fcOpt])
              ) : [fcOpt];
            var map$1 = Belt_MapString.set(map, bookingChannelSlug, bcToFcArray);
            return [
                    bcOpts$1,
                    map$1
                  ];
          })) : [
      [],
      undefined
    ];
  var bcView = HopperState$Util.Observable.makeView(form, BookingSession$Thick.bookingChannelSlug.lens("Value"), undefined);
  var selectedBsSlug = HopperState$Util.Observable.useComputed(bcView, (function (s) {
          return s;
        }), undefined, undefined);
  HopperState$Util.Observable.useSubscribeOnChange(bcView, (function (param) {
          HopperState$Util.Observable.notify(form, (function (f) {
                  return BookingSession$Thick.fareClassSlug.set(f, "", undefined);
                }));
        }), undefined, undefined);
  var fcOptions = Belt_MapString.getWithDefault(match$1[1], selectedBsSlug, []);
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(BSPInputs$Thick.Select.make, {
                      view: {
                        TAG: "Direct",
                        _0: form
                      },
                      label: "Booking Channel",
                      input: BookingSession$Thick.bookingChannelSlug,
                      options: match$1[0],
                      required: true,
                      overrideErrorText: "Please select"
                    }),
                JsxRuntime.jsx(BSPInputs$Thick.Select.make, {
                      view: {
                        TAG: "Direct",
                        _0: form
                      },
                      label: "Fare Class",
                      input: BookingSession$Thick.fareClassSlug,
                      options: fcOptions,
                      required: true,
                      disabled: fcOptions.length === 0,
                      overrideErrorText: "Please select"
                    })
              ]
            });
}

var BookingChannelFareClass = {
  QueryFragment: QueryFragment,
  make: BSForms$CreateUpdateDrawer$WithSomeForm$BookingChannelFareClass
};

function BSForms$CreateUpdateDrawer$WithSomeForm(props) {
  var brandFamily = props.brandFamily;
  var fetchKey = props.fetchKey;
  var fragmentRefs = props.fragmentRefs;
  var form = BookingSession$Thick.formExn(props.form);
  var extantBookingSessionId = HopperState$Util.Observable.useComputed(form, (function (param) {
          var target = param.target;
          if (typeof target !== "object") {
            return ;
          } else {
            return Caml_option.some(target._0);
          }
        }), undefined, undefined);
  BookingSession$Thick.setCabinFromPrivateReservationNote(form, brandFamily, props.voyage);
  var match = HopperState$Util.Observable.FormField.use(form, BookingSession$Thick.forOccupancy, undefined);
  return JsxRuntime.jsxs(React.Suspense, {
              children: [
                Belt_Option.mapWithDefault(extantBookingSessionId, null, (function (extantBookingSessionId) {
                        return JsxRuntime.jsx(BSForms$CreateUpdateDrawer$WithPrefill, {
                                    form: form,
                                    extantBookingSessionId: extantBookingSessionId,
                                    fetchKey: fetchKey
                                  });
                      })),
                JsxRuntime.jsx(DialogContent, {
                      children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                direction: "column",
                                sx: {
                                  p: 2,
                                  gap: 2
                                },
                                children: [
                                  JsxRuntime.jsxs(Stack, {
                                        direction: "row",
                                        sx: {
                                          flexWrap: "wrap",
                                          gap: 2
                                        },
                                        children: [
                                          JsxRuntime.jsx(BSPInputs$Thick.$$Text.make, {
                                                view: {
                                                  TAG: "Direct",
                                                  _0: form
                                                },
                                                input: BookingSession$Thick.descriptiveNote,
                                                label: "Internal Memo",
                                                required: false,
                                                width: "250px"
                                              }),
                                          JsxRuntime.jsx(CurrentUserSearchInput$Thick.make, {
                                                view: {
                                                  TAG: "Direct",
                                                  _0: form
                                                },
                                                placeholder: "Search users",
                                                label: "Billing Contact",
                                                required: true,
                                                width: "250px",
                                                input: BookingSession$Thick.billingContactAccountId,
                                                brandFamily: brandFamily,
                                                claimedAndPrefilledOnly: true,
                                                debounce: 1000
                                              }),
                                          JsxRuntime.jsx(BSPInputs$Thick.$$Text.make, {
                                                view: {
                                                  TAG: "Direct",
                                                  _0: form
                                                },
                                                input: BookingSession$Thick.cabinNumber,
                                                label: "Cabin Number",
                                                required: true,
                                                width: "120px",
                                                overrideErrorText: "Please enter cabin number"
                                              }),
                                          JsxRuntime.jsx(BSPInputs$Thick.Select.make, {
                                                view: {
                                                  TAG: "Direct",
                                                  _0: form
                                                },
                                                label: "Occ",
                                                input: BookingSession$Thick.forOccupancy,
                                                options: [
                                                  {
                                                    primitive: 1,
                                                    key: "one",
                                                    niceName: "1"
                                                  },
                                                  {
                                                    primitive: 2,
                                                    key: "two",
                                                    niceName: "2"
                                                  },
                                                  {
                                                    primitive: 3,
                                                    key: "three",
                                                    niceName: "3"
                                                  },
                                                  {
                                                    primitive: 4,
                                                    key: "four",
                                                    niceName: "4"
                                                  }
                                                ],
                                                required: true
                                              }),
                                          JsxRuntime.jsx(BSForms$CreateUpdateDrawer$WithSomeForm$BookingChannelFareClass, {
                                                form: form,
                                                bcfcFragmentRef: props.bcfcFragmentRef
                                              }),
                                          JsxRuntime.jsx(BSPInputs$Thick.Bool.make, {
                                                view: {
                                                  TAG: "Direct",
                                                  _0: form
                                                },
                                                input: BookingSession$Thick.pifIntention,
                                                label: "Intends PIF?",
                                                required: true
                                              })
                                        ]
                                      }),
                                  JsxRuntime.jsx(Stack, {
                                        direction: "row",
                                        sx: {
                                          flexWrap: "wrap",
                                          gap: 2
                                        },
                                        children: Caml_option.some(Belt_Array.map(Belt_Array.range(1, match[0].value), (function (paxIdx) {
                                                    return JsxRuntime.jsx(BSForms$CreateUpdateDrawer$PaxForm, {
                                                                form: form,
                                                                paxIdx: paxIdx,
                                                                fragmentRefs: fragmentRefs,
                                                                brandFamily: brandFamily
                                                              }, String(paxIdx));
                                                  })))
                                      })
                                ]
                              })),
                      sx: {
                        backgroundColor: "primary.50"
                      }
                    })
              ],
              fallback: Caml_option.some(JsxRuntime.jsx(Spinner$Thick.make, {}))
            });
}

var WithSomeForm = {
  SaveButton: SaveButton,
  BookingChannelFareClass: BookingChannelFareClass,
  make: BSForms$CreateUpdateDrawer$WithSomeForm
};

function BSForms$CreateUpdateDrawer(props) {
  var refetchList = props.refetchList;
  var form = props.form;
  var voyage = props.voyage;
  var brandFamily = props.brandFamily;
  var open_ = HopperState$Util.Observable.useComputed(form, Belt_Option.isSome, undefined, undefined);
  var match = HopperState$Util.Observable.useComputed(form, (function (o) {
          if (o === undefined) {
            return [
                    "",
                    true
                  ];
          }
          var tmp = o.target;
          if (typeof tmp !== "object") {
            return [
                    "Create Booking Session",
                    true
                  ];
          } else {
            return [
                    "Edit Booking Session",
                    false
                  ];
          }
        }), undefined, undefined);
  var newFetchKey = function () {
    return (crypto.randomUUID());
  };
  var match$1 = React.useState(newFetchKey);
  var triggerRefetch = refetchList;
  var onClose = function (param) {
    HopperState$Util.Observable.notify(form, (function (param) {
            
          }));
  };
  return JsxRuntime.jsx(BSDrawer$Thick.make, {
              open_: open_,
              title: match[0],
              onClose: onClose,
              children: open_ ? JsxRuntime.jsx(BSForms$CreateUpdateDrawer$WithSomeForm, {
                      form: form,
                      fragmentRefs: props.fragmentRefs,
                      fetchKey: match$1[0],
                      bcfcFragmentRef: props.bcfcFragmentRef,
                      brandFamily: brandFamily,
                      voyage: voyage
                    }) : null,
              form: form,
              brandFamily: brandFamily,
              voyage: voyage,
              triggerRefetch: triggerRefetch,
              openErrorModal: props.openErrorModal,
              state: props.state,
              filter: props.filter
            });
}

var CreateUpdateDrawer = {
  WithPrefill: WithPrefill,
  WithSomePaxForm: WithSomePaxForm,
  PaxForm: PaxForm,
  WithSomeForm: WithSomeForm,
  make: BSForms$CreateUpdateDrawer
};

exports.CreateUpdateDrawer = CreateUpdateDrawer;
/* react Not a pure module */
