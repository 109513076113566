// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var Stack = require("@mui/joy/Stack").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var CustomerTable$Thick = require("../components/customers/CustomerTable.bs.js");

function CustomerAccountsPage(props) {
  return JsxRuntime.jsxs(Stack, {
              spacing: 2,
              children: [
                JsxRuntime.jsx(Typography, {
                      level: "h3",
                      component: "h1",
                      children: "Customer Accounts"
                    }),
                JsxRuntime.jsx(Stack, {
                      direction: "row",
                      spacing: 1,
                      children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                direction: "column",
                                alignItems: "flex-start",
                                spacing: 1,
                                sx: {
                                  width: "100%"
                                },
                                children: Caml_option.some(JsxRuntime.jsx(CustomerTable$Thick.make, {
                                          fragmentRefs: props.fragmentRefs,
                                          brandFamily: props.brandFamily,
                                          state: props.state,
                                          custPerPage: props.custPerPage
                                        }))
                              }))
                    })
              ]
            });
}

var make = CustomerAccountsPage;

exports.make = make;
/* @mui/joy/Stack Not a pure module */
