// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Dialog$Thick = require("../../common/molecules/Dialog.bs.js");
var Stack = require("@mui/joy/Stack").default;
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var RelayEnv$Thick = require("../../../RelayEnv.bs.js");
var Button = require("@mui/joy/Button").default;
var BookingDiff$Thick = require("../../../utils/BookingDiff.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Belt_HashMapString = require("rescript/lib/js/belt_HashMapString.js");
var PreviewModal$Thick = require("./PreviewModal.bs.js");
var Typography = require("@mui/joy/Typography").default;
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var PreviewChanges$Thick = require("./PreviewChanges.bs.js");
var DialogActions = require("@mui/joy/DialogActions").default;
var DialogContent = require("@mui/joy/DialogContent").default;
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var ChangeBCModalQuery_graphql$Thick = require("../../../__generated__/ChangeBCModalQuery_graphql.bs.js");

var convertVariables = ChangeBCModalQuery_graphql$Thick.Internal.convertVariables;

var convertResponse = ChangeBCModalQuery_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = ChangeBCModalQuery_graphql$Thick.Internal.convertWrapRawResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, ChangeBCModalQuery_graphql$Thick.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, ChangeBCModalQuery_graphql$Thick.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(ChangeBCModalQuery_graphql$Thick.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(ChangeBCModalQuery_graphql$Thick.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(ChangeBCModalQuery_graphql$Thick.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(ChangeBCModalQuery_graphql$Thick.node, convertVariables);

var Query_accountStatus_decode = ChangeBCModalQuery_graphql$Thick.Utils.accountStatus_decode;

var Query_accountStatus_fromString = ChangeBCModalQuery_graphql$Thick.Utils.accountStatus_fromString;

var Query_billingKind_decode = ChangeBCModalQuery_graphql$Thick.Utils.billingKind_decode;

var Query_billingKind_fromString = ChangeBCModalQuery_graphql$Thick.Utils.billingKind_fromString;

var Query_bookingSessionPassengerType_decode = ChangeBCModalQuery_graphql$Thick.Utils.bookingSessionPassengerType_decode;

var Query_bookingSessionPassengerType_fromString = ChangeBCModalQuery_graphql$Thick.Utils.bookingSessionPassengerType_fromString;

var Query_brandfamRole_decode = ChangeBCModalQuery_graphql$Thick.Utils.brandfamRole_decode;

var Query_brandfamRole_fromString = ChangeBCModalQuery_graphql$Thick.Utils.brandfamRole_fromString;

var Query_custSignonStatus_decode = ChangeBCModalQuery_graphql$Thick.Utils.custSignonStatus_decode;

var Query_custSignonStatus_fromString = ChangeBCModalQuery_graphql$Thick.Utils.custSignonStatus_fromString;

var Query_docGender_decode = ChangeBCModalQuery_graphql$Thick.Utils.docGender_decode;

var Query_docGender_fromString = ChangeBCModalQuery_graphql$Thick.Utils.docGender_fromString;

var Query_elevatorProximity_decode = ChangeBCModalQuery_graphql$Thick.Utils.elevatorProximity_decode;

var Query_elevatorProximity_fromString = ChangeBCModalQuery_graphql$Thick.Utils.elevatorProximity_fromString;

var Query_invitationType_decode = ChangeBCModalQuery_graphql$Thick.Utils.invitationType_decode;

var Query_invitationType_fromString = ChangeBCModalQuery_graphql$Thick.Utils.invitationType_fromString;

var Query_lifecycleStatus_decode = ChangeBCModalQuery_graphql$Thick.Utils.lifecycleStatus_decode;

var Query_lifecycleStatus_fromString = ChangeBCModalQuery_graphql$Thick.Utils.lifecycleStatus_fromString;

var Query_longitudinality_decode = ChangeBCModalQuery_graphql$Thick.Utils.longitudinality_decode;

var Query_longitudinality_fromString = ChangeBCModalQuery_graphql$Thick.Utils.longitudinality_fromString;

var Query_manifestPaxReconciliationStatus_decode = ChangeBCModalQuery_graphql$Thick.Utils.manifestPaxReconciliationStatus_decode;

var Query_manifestPaxReconciliationStatus_fromString = ChangeBCModalQuery_graphql$Thick.Utils.manifestPaxReconciliationStatus_fromString;

var Query_paxDataStatus_decode = ChangeBCModalQuery_graphql$Thick.Utils.paxDataStatus_decode;

var Query_paxDataStatus_fromString = ChangeBCModalQuery_graphql$Thick.Utils.paxDataStatus_fromString;

var Query_pmtDirection_decode = ChangeBCModalQuery_graphql$Thick.Utils.pmtDirection_decode;

var Query_pmtDirection_fromString = ChangeBCModalQuery_graphql$Thick.Utils.pmtDirection_fromString;

var Query_pmtLifecycleStatus_decode = ChangeBCModalQuery_graphql$Thick.Utils.pmtLifecycleStatus_decode;

var Query_pmtLifecycleStatus_fromString = ChangeBCModalQuery_graphql$Thick.Utils.pmtLifecycleStatus_fromString;

var Query_pmtMethod_decode = ChangeBCModalQuery_graphql$Thick.Utils.pmtMethod_decode;

var Query_pmtMethod_fromString = ChangeBCModalQuery_graphql$Thick.Utils.pmtMethod_fromString;

var Query_pmtProcessor_decode = ChangeBCModalQuery_graphql$Thick.Utils.pmtProcessor_decode;

var Query_pmtProcessor_fromString = ChangeBCModalQuery_graphql$Thick.Utils.pmtProcessor_fromString;

var Query_refundability_decode = ChangeBCModalQuery_graphql$Thick.Utils.refundability_decode;

var Query_refundability_fromString = ChangeBCModalQuery_graphql$Thick.Utils.refundability_fromString;

var Query_signonStatus_decode = ChangeBCModalQuery_graphql$Thick.Utils.signonStatus_decode;

var Query_signonStatus_fromString = ChangeBCModalQuery_graphql$Thick.Utils.signonStatus_fromString;

var Query_subtotalCategory_decode = ChangeBCModalQuery_graphql$Thick.Utils.subtotalCategory_decode;

var Query_subtotalCategory_fromString = ChangeBCModalQuery_graphql$Thick.Utils.subtotalCategory_fromString;

var Query_versionProvenance_decode = ChangeBCModalQuery_graphql$Thick.Utils.versionProvenance_decode;

var Query_versionProvenance_fromString = ChangeBCModalQuery_graphql$Thick.Utils.versionProvenance_fromString;

var Query = {
  accountStatus_decode: Query_accountStatus_decode,
  accountStatus_fromString: Query_accountStatus_fromString,
  billingKind_decode: Query_billingKind_decode,
  billingKind_fromString: Query_billingKind_fromString,
  bookingSessionPassengerType_decode: Query_bookingSessionPassengerType_decode,
  bookingSessionPassengerType_fromString: Query_bookingSessionPassengerType_fromString,
  brandfamRole_decode: Query_brandfamRole_decode,
  brandfamRole_fromString: Query_brandfamRole_fromString,
  custSignonStatus_decode: Query_custSignonStatus_decode,
  custSignonStatus_fromString: Query_custSignonStatus_fromString,
  docGender_decode: Query_docGender_decode,
  docGender_fromString: Query_docGender_fromString,
  elevatorProximity_decode: Query_elevatorProximity_decode,
  elevatorProximity_fromString: Query_elevatorProximity_fromString,
  invitationType_decode: Query_invitationType_decode,
  invitationType_fromString: Query_invitationType_fromString,
  lifecycleStatus_decode: Query_lifecycleStatus_decode,
  lifecycleStatus_fromString: Query_lifecycleStatus_fromString,
  longitudinality_decode: Query_longitudinality_decode,
  longitudinality_fromString: Query_longitudinality_fromString,
  manifestPaxReconciliationStatus_decode: Query_manifestPaxReconciliationStatus_decode,
  manifestPaxReconciliationStatus_fromString: Query_manifestPaxReconciliationStatus_fromString,
  paxDataStatus_decode: Query_paxDataStatus_decode,
  paxDataStatus_fromString: Query_paxDataStatus_fromString,
  pmtDirection_decode: Query_pmtDirection_decode,
  pmtDirection_fromString: Query_pmtDirection_fromString,
  pmtLifecycleStatus_decode: Query_pmtLifecycleStatus_decode,
  pmtLifecycleStatus_fromString: Query_pmtLifecycleStatus_fromString,
  pmtMethod_decode: Query_pmtMethod_decode,
  pmtMethod_fromString: Query_pmtMethod_fromString,
  pmtProcessor_decode: Query_pmtProcessor_decode,
  pmtProcessor_fromString: Query_pmtProcessor_fromString,
  refundability_decode: Query_refundability_decode,
  refundability_fromString: Query_refundability_fromString,
  signonStatus_decode: Query_signonStatus_decode,
  signonStatus_fromString: Query_signonStatus_fromString,
  subtotalCategory_decode: Query_subtotalCategory_decode,
  subtotalCategory_fromString: Query_subtotalCategory_fromString,
  versionProvenance_decode: Query_versionProvenance_decode,
  versionProvenance_fromString: Query_versionProvenance_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function ChangeBCModal(props) {
  var brandFamily = props.brandFamily;
  var currentAcctId = props.currentAcctId;
  var onClose = props.onClose;
  var match = React.useState(function () {
        return [];
      });
  var setSelectOptions = match[1];
  var selectOptions = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setAutocompleteOpen = match$1[1];
  var match$2 = React.useState(function () {
        
      });
  var setBcAcct = match$2[1];
  var bcAcct = match$2[0];
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var diffStruct = BookingDiff$Thick.parse(url.search);
  var match$3 = PreviewChanges$Thick.useQuery(props.generatePreviewFromDiff, diffStruct, onClose);
  var clearPreviewFields = match$3.clearPreviewFields;
  var setApplyDiff = match$3.setApplyDiff;
  var setError = match$3.setError;
  var error = match$3.error;
  var setLoading = match$3.setLoading;
  var loading = match$3.loading;
  var generatePreview = match$3.generatePreview;
  var clearAll = function () {
    clearPreviewFields();
    setAutocompleteOpen(function (param) {
          return false;
        });
    setBcAcct(function (param) {
          
        });
  };
  var onSubmit = function (e) {
    e.preventDefault();
    if (bcAcct !== undefined) {
      var applyDiff = BookingDiff$Thick.Alter.changeBillingContact(diffStruct, Caml_option.some(Caml_option.valFromOption(bcAcct)));
      setApplyDiff(function (param) {
            return applyDiff;
          });
      generatePreview(applyDiff);
      return ;
    }
    setError(function (param) {
          return "Please select a billing contact.";
        });
  };
  var selectMap = React.useMemo((function () {
          return Belt_HashMapString.fromArray(Belt_Array.map(selectOptions, (function (val) {
                            var match = val.email;
                            var tmp = match === "" ? "" : " (" + val.email + ")";
                            return [
                                    CS_NonemptyStrings$Util.AccountId.toString(val.accountId),
                                    val.fullName + tmp
                                  ];
                          })));
        }), [selectOptions]);
  var toBc = React.useMemo((function () {
          if (bcAcct === undefined) {
            return "";
          }
          var bcName = Belt_HashMapString.get(selectMap, CS_NonemptyStrings$Util.AccountId.toString(Caml_option.valFromOption(bcAcct)));
          if (bcName !== undefined) {
            return " to " + bcName;
          } else {
            return "";
          }
        }), [bcAcct]);
  var createFilter = function (s) {
    return {
            fullName: {
              includesInsensitive: s
            },
            accountStatus: {
              in: [
                "CLAIMED",
                "PREFILLED"
              ]
            }
          };
  };
  var search = function (val) {
    setLoading(function (param) {
          return true;
        });
    setAutocompleteOpen(function (param) {
          return false;
        });
    setError(function (param) {
          
        });
    $$fetch(RelayEnv$Thick.environment, {
          brandFamily: brandFamily,
          filter: createFilter(val)
        }, (function (res) {
            if (res.TAG === "Ok") {
              var match = res._0.bfcustUsers;
              if (match !== undefined) {
                var results = Belt_Array.keepMap(match.nodes, (function (node) {
                        var accountId = node.accountId;
                        var fullName = node.fullName;
                        var email = node.email;
                        var email$1 = email !== undefined ? CS_Emails$Util.Email.toString(Caml_option.valFromOption(email)) : "";
                        var n = {
                          accountId: accountId,
                          fullName: fullName,
                          email: email$1
                        };
                        if (currentAcctId === undefined) {
                          return n;
                        }
                        var currentAcctId$1 = CS_NonemptyStrings$Util.AccountId.fromPrimitive(currentAcctId);
                        if (currentAcctId$1.TAG === "Ok" && Caml_obj.equal(currentAcctId$1._0, accountId)) {
                          return ;
                        } else {
                          return n;
                        }
                      }));
                setSelectOptions(function (param) {
                      return results;
                    });
                setAutocompleteOpen(function (param) {
                      return true;
                    });
                setLoading(function (param) {
                      return false;
                    });
                return ;
              }
              setError(function (param) {
                    return "No users found.";
                  });
              setLoading(function (param) {
                    return false;
                  });
              return ;
            }
            console.error(res._0);
            setError(function (param) {
                  return "Something went wrong. Please try again.";
                });
            setLoading(function (param) {
                  return false;
                });
          }), undefined, undefined);
  };
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs(Dialog$Thick.make, {
                      open_: props.open_,
                      onClose: onClose,
                      title: "Change Billing Contact",
                      children: [
                        JsxRuntime.jsxs(DialogContent, {
                              children: [
                                JsxRuntime.jsx(Typography, {
                                      level: "title-md",
                                      sx: {
                                        marginBottom: "1rem"
                                      },
                                      children: "Search for a user to add as the billing contact on this booking."
                                    }),
                                JsxRuntime.jsxs(Stack, {
                                      direction: "column",
                                      spacing: 2,
                                      children: [
                                        JsxRuntime.jsx(Joy$Util.JoyAuto.Single.make, {
                                              options: selectOptions,
                                              getOptionLabel: (function (option) {
                                                  var match = option.email;
                                                  var tmp = match === "" ? "" : " (" + option.email + ")";
                                                  return option.fullName + tmp;
                                                }),
                                              isOptionEqualToValue: (function (option, value) {
                                                  return option.accountId === value.accountId;
                                                }),
                                              loading: loading,
                                              onChange: (function (param, value) {
                                                  if (value !== undefined) {
                                                    var accountId = value.accountId;
                                                    setBcAcct(function (param) {
                                                          return Caml_option.some(accountId);
                                                        });
                                                    return ;
                                                  }
                                                  setBcAcct(function (param) {
                                                        
                                                      });
                                                }),
                                              onClose: (function () {
                                                  setAutocompleteOpen(function (param) {
                                                        return false;
                                                      });
                                                }),
                                              onInputChange: (function (param, v, param$1) {
                                                  if (v === "") {
                                                    setSelectOptions(function (param) {
                                                          return [];
                                                        });
                                                    setAutocompleteOpen(function (param) {
                                                          return false;
                                                        });
                                                    setError(function (param) {
                                                          
                                                        });
                                                    setLoading(function (param) {
                                                          return false;
                                                        });
                                                  } else {
                                                    search(v);
                                                  }
                                                }),
                                              onOpen: (function () {
                                                  setAutocompleteOpen(function (param) {
                                                        return true;
                                                      });
                                                }),
                                              open: match$1[0],
                                              placeholder: "Search for a Billing Contact"
                                            }),
                                        error !== undefined ? JsxRuntime.jsx(Typography, {
                                                color: "danger",
                                                children: Caml_option.some(error)
                                              }) : JsxRuntime.jsx(JsxRuntime.Fragment, {})
                                      ]
                                    })
                              ],
                              component: "form",
                              onSubmit: onSubmit
                            }),
                        JsxRuntime.jsx(DialogActions, {
                              children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                        component: "form",
                                        onSubmit: onSubmit,
                                        children: Caml_option.some(JsxRuntime.jsx(Button, {
                                                  loading: loading,
                                                  type: "submit",
                                                  disabled: Belt_Option.isNone(bcAcct),
                                                  children: "Change Billing Contact"
                                                }))
                                      }))
                            })
                      ]
                    }),
                JsxRuntime.jsx(PreviewModal$Thick.make, {
                      billPayCurrent: props.billPayCurrent,
                      billPayNew: match$3.billPayNew,
                      applyDiff: match$3.applyDiff,
                      title: "Change Billing Contact",
                      description: "You are proposing to change the billing contact on this booking" + toBc + ".",
                      onClose: (function () {
                          clearAll();
                        }),
                      open_: match$3.previewModalOpen,
                      versionUrl: props.versionUrl,
                      error: error
                    })
              ]
            });
}

var make = ChangeBCModal;

exports.Query = Query;
exports.make = make;
/* use Not a pure module */
