// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var ReactRelay = require("react-relay");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");

function makeRefetchVariables(accountId, brandFamilySlug) {
  return {
          accountId: accountId,
          brandFamilySlug: brandFamilySlug
        };
}

var Types = {
  makeRefetchVariables: makeRefetchVariables
};

var variablesConverter = {"__root":{"brandFamilySlug":{"c":"Util.CustomScalars.BrandFamilySlug.Exn"},"accountId":{"c":"Util.CustomScalars.AccountId.Exn"}}};

var variablesConverterMap = {
  "Util.CustomScalars.AccountId.Exn": CS_NonemptyStrings$Util.AccountId.Exn.serialize,
  "Util.CustomScalars.BrandFamilySlug.Exn": CS_Slugs$Util.BrandFamilySlug.Exn.serialize
};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, variablesConverterMap, undefined);
}

var wrapResponseConverter = {"__root":{"bfcustUser":{"f":""}}};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, undefined, null);
}

var responseConverter = {"__root":{"bfcustUser":{"f":""}}};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, undefined, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: variablesConverterMap,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: undefined,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: undefined,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse
};

var Utils = {};

var node = ((function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "accountId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "brandFamilySlug"
},
v2 = [
  {
    "kind": "Variable",
    "name": "accountId",
    "variableName": "accountId"
  },
  {
    "kind": "Variable",
    "name": "brandFamilySlug",
    "variableName": "brandFamilySlug"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voyageSlug",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CustomersStateFetchOneQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "BfcustUser",
        "kind": "LinkedField",
        "name": "bfcustUser",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CustomersState_customer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CustomersStateFetchOneQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "BfcustUser",
        "kind": "LinkedField",
        "name": "bfcustUser",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "phone",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "residenceCountry",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "postalCode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "halMarinerNumber",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "legalDocumentFirstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "legalDocumentLastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "legalDocumentGender",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "legalDocumentBirthdate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "legalDocumentCitizenship",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "custAcquisitionSlug",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accountStatus",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accountId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "custodianAccountId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "invitationEmail",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "invitationExpiry",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "loyaltyNumber",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "migratedEmail",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BfcustUser",
            "kind": "LinkedField",
            "name": "custodian",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "filter",
                "value": {
                  "bookingVersionLifecycleStatus": {
                    "equalTo": "ACTIVE"
                  }
                }
              },
              {
                "kind": "Literal",
                "name": "orderBy",
                "value": [
                  "BOOKING_VERSION_NUMBER_DESC"
                ]
              }
            ],
            "concreteType": "PassengersConnection",
            "kind": "LinkedField",
            "name": "passenger_record",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Passenger",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "bookingVersionNumber",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "bookingId",
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BookingVersion",
                    "kind": "LinkedField",
                    "name": "booking_version",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BrandFamilyVoyage",
                        "kind": "LinkedField",
                        "name": "voyage",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "voyageName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "voyStartDate",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "voyEndDate",
                            "storageKey": null
                          },
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "passenger_record(filter:{\"bookingVersionLifecycleStatus\":{\"equalTo\":\"ACTIVE\"}},orderBy:[\"BOOKING_VERSION_NUMBER_DESC\"])"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BfcustLoyaltyStatus",
            "kind": "LinkedField",
            "name": "loyalty_status",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "currentTierTitle",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "pendingTierTitle",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "numPendingVoyages",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "numEligibleVoyages",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "pendingVoyageSlugs",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "eligibleVoyageSlugs",
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PrefilledBfcustAccountLink",
            "kind": "LinkedField",
            "name": "prefilledAccountLink",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bfcustRedemptionLink",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "expiresAfterDateOnly",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isExpired",
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "78c452f1ef48ad15e3abc34cd2b0f379",
    "id": null,
    "metadata": {},
    "name": "CustomersStateFetchOneQuery",
    "operationKind": "query",
    "text": "query CustomersStateFetchOneQuery(\n  $brandFamilySlug: BrandFamilySlug!\n  $accountId: AccountId!\n) {\n  bfcustUser(brandFamilySlug: $brandFamilySlug, accountId: $accountId) {\n    ...CustomersState_customer\n    id\n  }\n}\n\nfragment CustomersState_customer on BfcustUser {\n  fullName\n  firstName\n  lastName\n  phone\n  residenceCountry\n  postalCode\n  halMarinerNumber\n  legalDocumentFirstName\n  legalDocumentLastName\n  legalDocumentGender\n  legalDocumentBirthdate\n  legalDocumentCitizenship\n  custAcquisitionSlug\n  email\n  accountStatus\n  accountId\n  custodianAccountId\n  invitationEmail\n  invitationExpiry\n  loyaltyNumber\n  migratedEmail\n  custodian {\n    fullName\n    id\n  }\n  passenger_record(filter: {bookingVersionLifecycleStatus: {equalTo: ACTIVE}}, orderBy: [BOOKING_VERSION_NUMBER_DESC]) {\n    nodes {\n      bookingVersionNumber\n      bookingId\n      voyageSlug\n      id\n    }\n    ...VoyageDetails_passengersfrag\n  }\n  loyalty_status {\n    currentTierTitle\n    pendingTierTitle\n    numPendingVoyages\n    numEligibleVoyages\n    pendingVoyageSlugs\n    eligibleVoyageSlugs\n    id\n  }\n  prefilledAccountLink {\n    bfcustRedemptionLink\n    expiresAfterDateOnly\n    isExpired\n    id\n  }\n}\n\nfragment VoyageDetails_passengersfrag on PassengersConnection {\n  nodes {\n    booking_version {\n      voyage {\n        voyageSlug\n        voyageName\n        voyStartDate\n        voyEndDate\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})());

function load(environment, variables, fetchPolicy, fetchKey, networkCacheConfig) {
  return ReactRelay.loadQuery(environment, node, convertVariables(variables), {
              fetchKey: fetchKey,
              fetchPolicy: fetchPolicy,
              networkCacheConfig: networkCacheConfig
            });
}

function queryRefToObservable(token) {
  return Caml_option.nullable_to_opt(token.source);
}

function queryRefToPromise(token) {
  return new Promise((function (resolve, param) {
                var o = queryRefToObservable(token);
                if (o !== undefined) {
                  Caml_option.valFromOption(o).subscribe({
                        complete: (function () {
                            resolve({
                                  TAG: "Ok",
                                  _0: undefined
                                });
                          })
                      });
                  return ;
                } else {
                  return resolve({
                              TAG: "Error",
                              _0: undefined
                            });
                }
              }));
}

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
exports.load = load;
exports.queryRefToObservable = queryRefToObservable;
exports.queryRefToPromise = queryRefToPromise;
/* node Not a pure module */
