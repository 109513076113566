// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Dialog$Thick = require("../common/molecules/Dialog.bs.js");
var Card = require("@mui/joy/Card").default;
var Stack = require("@mui/joy/Stack").default;
var AppAlert$Thick = require("../common/atoms/AppAlert.bs.js");
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var Button = require("@mui/joy/Button").default;
var Divider = require("@mui/joy/Divider").default;
var HopperState$Util = require("util/src/HopperState.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var MergeAccounts$Thick = require("../../utils/MergeAccounts.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var DialogContent = require("@mui/joy/DialogContent").default;
var ArrowForward = require("@mui/icons-material/ArrowForward").default;
var CS_LoyaltyAndCruiselineNumbers$Util = require("util/src/CustomScalars/CS_LoyaltyAndCruiselineNumbers.bs.js");

function MergeModal$MergeSummary$MergeDetail(props) {
  return JsxRuntime.jsxs(Typography, {
              level: "body-md",
              fontWeight: "bold",
              children: [
                props.title + ":",
                " ",
                JsxRuntime.jsx(Typography, {
                      component: "span",
                      fontWeight: "normal",
                      children: Caml_option.some(props.result)
                    })
              ]
            });
}

var MergeDetail = {
  make: MergeModal$MergeSummary$MergeDetail
};

function MergeModal$MergeSummary(props) {
  var summary = props.summary;
  return JsxRuntime.jsxs(Stack, {
              direction: "column",
              children: [
                JsxRuntime.jsx(Typography, {
                      level: "body-lg",
                      sx: {
                        pb: 1
                      },
                      children: "Merge Summary:"
                    }),
                JsxRuntime.jsx(MergeModal$MergeSummary$MergeDetail, {
                      title: "Pax history records updated to destination user",
                      result: String(summary.ctPaxHistory)
                    }),
                JsxRuntime.jsx(MergeModal$MergeSummary$MergeDetail, {
                      title: "Booking versions where billing contact updated to destination user",
                      result: String(summary.ctBookingVersions)
                    }),
                JsxRuntime.jsx(MergeModal$MergeSummary$MergeDetail, {
                      title: "Passenger records updated to destination user",
                      result: String(summary.ctPaxVersion)
                    }),
                JsxRuntime.jsx(MergeModal$MergeSummary$MergeDetail, {
                      title: "Appearances of passenger in a booking version updated to destination user",
                      result: String(summary.ctBookingVersionPax)
                    }),
                JsxRuntime.jsx(MergeModal$MergeSummary$MergeDetail, {
                      title: "Payments where the payer was updated to destination user",
                      result: String(summary.ctPayment)
                    }),
                JsxRuntime.jsx(MergeModal$MergeSummary$MergeDetail, {
                      title: "Source user cabin holds released",
                      result: String(summary.ctCabinHoldsCleared)
                    }),
                JsxRuntime.jsx(MergeModal$MergeSummary$MergeDetail, {
                      title: "Cabin hold log entries where holder updated to destination user",
                      result: String(summary.ctCabinHoldLogs)
                    }),
                JsxRuntime.jsx(MergeModal$MergeSummary$MergeDetail, {
                      title: "Source user booking sessions deleted",
                      result: String(summary.ctBookingSessionsDeleted)
                    }),
                JsxRuntime.jsx(MergeModal$MergeSummary$MergeDetail, {
                      title: "Passengers staged in booking sessions updated to destination user",
                      result: String(summary.ctBookingSessionExistingPax)
                    }),
                JsxRuntime.jsx(MergeModal$MergeSummary$MergeDetail, {
                      title: "Unclaimed accounts reparented to destination user",
                      result: String(summary.ctReparentedConnectedAccts)
                    }),
                JsxRuntime.jsx(MergeModal$MergeSummary$MergeDetail, {
                      title: "User accounts deleted",
                      result: String(summary.ctDeletedUsers)
                    })
              ]
            });
}

var MergeSummary = {
  MergeDetail: MergeDetail,
  make: MergeModal$MergeSummary
};

function MergeModal$MergeResult(props) {
  var result = props.result;
  var tmp;
  if (typeof result !== "object") {
    tmp = null;
  } else {
    switch (result.TAG) {
      case "Merged" :
          tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
                children: [
                  JsxRuntime.jsx(Typography, {
                        level: "body-lg",
                        children: "Accounts successfully merged."
                      }),
                  JsxRuntime.jsx(MergeModal$MergeSummary, {
                        summary: result._0
                      })
                ]
              });
          break;
      case "MergedWithWarnings" :
          var match = result._0;
          tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
                children: [
                  JsxRuntime.jsx(Typography, {
                        level: "body-lg",
                        children: "Accounts successfully merged, with warnings."
                      }),
                  JsxRuntime.jsx(MergeModal$MergeSummary, {
                        summary: match[0]
                      }),
                  JsxRuntime.jsx(AppAlert$Thick.make, {
                        message: JsxRuntime.jsxs(Stack, {
                              direction: "column",
                              spacing: 1,
                              children: [
                                JsxRuntime.jsx(Typography, {
                                      level: "body-md",
                                      children: "Warnings:"
                                    }),
                                Belt_Array.mapWithIndex(match[1], (function (i, w) {
                                        return JsxRuntime.jsx(Typography, {
                                                    level: "body-sm",
                                                    children: Caml_option.some(w)
                                                  }, String(i));
                                      }))
                              ]
                            }),
                        type_: "warning"
                      })
                ]
              });
          break;
      case "MergeError" :
          tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
                children: [
                  JsxRuntime.jsx(Typography, {
                        level: "body-lg",
                        children: "Merge unsuccessful."
                      }),
                  JsxRuntime.jsx(AppAlert$Thick.make, {
                        message: JsxRuntime.jsxs(Stack, {
                              direction: "column",
                              spacing: 1,
                              children: [
                                JsxRuntime.jsx(Typography, {
                                      level: "body-md",
                                      children: "Errors:"
                                    }),
                                Belt_Array.mapWithIndex(result._0, (function (i, e) {
                                        return JsxRuntime.jsx(Typography, {
                                                    level: "body-sm",
                                                    children: Caml_option.some(e)
                                                  }, String(i));
                                      }))
                              ]
                            }),
                        type_: "error"
                      })
                ]
              });
          break;
      
    }
  }
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                tmp,
                JsxRuntime.jsx(Button, {
                      onClick: props.clearAll,
                      color: "primary",
                      size: "lg",
                      children: "New Account Merge"
                    })
              ]
            });
}

var MergeResult = {
  make: MergeModal$MergeResult
};

function MergeModal(props) {
  var view = props.view;
  var onClose = props.onClose;
  var match = HopperState$Util.Observable.useComputed(view, (function (v) {
          return [
                  v.sourceAcct,
                  v.destAcct,
                  v.mergedStatus
                ];
        }), undefined, undefined);
  var mergedStatus = match[2];
  var destAcct = match[1];
  var srcAcct = match[0];
  var onReturn = function (m) {
    HopperState$Util.Observable.notify(view, (function (v) {
            return {
                    source: v.source,
                    dest: v.dest,
                    url: v.url,
                    sourceAcct: v.sourceAcct,
                    destAcct: v.destAcct,
                    mergeability: v.mergeability,
                    mergedStatus: m,
                    mergeModal: v.mergeModal
                  };
          }));
  };
  var match$1 = MergeAccounts$Thick.useMerge(props.brandFamily, onReturn);
  var merge = match$1.merge;
  var error = match$1.error;
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var clearUrl_path = url.path;
  var clearUrl_hash = url.hash;
  var clearUrl = {
    path: clearUrl_path,
    hash: clearUrl_hash,
    search: ""
  };
  var clearAll = function (param) {
    HopperState$Util.Observable.notify(view, (function (param) {
            return MergeAccounts$Thick.blankModel(clearUrl);
          }));
  };
  var onClick = function (param) {
    if (srcAcct !== undefined && destAcct !== undefined) {
      return merge(srcAcct.accountId, destAcct.accountId);
    }
    
  };
  var onClose$1;
  var exit = 0;
  if (typeof mergedStatus !== "object") {
    onClose$1 = onClose;
  } else {
    switch (mergedStatus.TAG) {
      case "Merged" :
      case "MergedWithWarnings" :
          exit = 1;
          break;
      case "MergeError" :
          onClose$1 = onClose;
          break;
      
    }
  }
  if (exit === 1) {
    onClose$1 = (function () {
        clearAll();
        onClose();
      });
  }
  var title;
  if (typeof mergedStatus !== "object") {
    title = "Merge Accounts";
  } else {
    switch (mergedStatus.TAG) {
      case "Merged" :
          title = "Merge Successful";
          break;
      case "MergedWithWarnings" :
          title = "Merged with Warnings";
          break;
      case "MergeError" :
          title = "Merge Error";
          break;
      
    }
  }
  var tmp;
  var exit$1 = 0;
  if (srcAcct !== undefined && destAcct !== undefined) {
    var tmp$1;
    if (error !== undefined) {
      tmp$1 = JsxRuntime.jsx(Typography, {
            color: "danger",
            level: "body-md",
            children: Caml_option.some(error)
          });
    } else if (typeof mergedStatus !== "object") {
      var email = srcAcct.email;
      var email$1 = destAcct.email;
      tmp$1 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
            children: [
              JsxRuntime.jsx(Typography, {
                    level: "body-lg",
                    children: "You are about to perform the following account merge:"
                  }),
              JsxRuntime.jsxs(Stack, {
                    direction: "row",
                    justifyContent: "space-between",
                    spacing: 2,
                    children: [
                      JsxRuntime.jsxs(Card, {
                            children: [
                              JsxRuntime.jsx(Typography, {
                                    level: "title-md",
                                    children: "Source Account:"
                                  }),
                              JsxRuntime.jsx(Divider, {}),
                              JsxRuntime.jsx(Typography, {
                                    level: "body-md",
                                    children: Caml_option.some(srcAcct.fullName + " " + (
                                          email !== undefined ? "<" + CS_Emails$Util.Email.toString(Caml_option.valFromOption(email)) + "> " : ""
                                        ) + "[" + CS_LoyaltyAndCruiselineNumbers$Util.LoyaltyNumber.toString(srcAcct.loyaltyNumber) + "]")
                                  })
                            ],
                            variant: "outlined"
                          }),
                      JsxRuntime.jsx(Stack, {
                            direction: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            children: Caml_option.some(JsxRuntime.jsx(ArrowForward, {}))
                          }),
                      JsxRuntime.jsxs(Card, {
                            children: [
                              JsxRuntime.jsx(Typography, {
                                    level: "title-md",
                                    children: "Destination Account:"
                                  }),
                              JsxRuntime.jsx(Divider, {}),
                              JsxRuntime.jsx(Typography, {
                                    level: "body-md",
                                    children: Caml_option.some(destAcct.fullName + " " + (
                                          email$1 !== undefined ? "<" + CS_Emails$Util.Email.toString(Caml_option.valFromOption(email$1)) + "> " : ""
                                        ) + "[" + CS_LoyaltyAndCruiselineNumbers$Util.LoyaltyNumber.toString(destAcct.loyaltyNumber) + "]")
                                  })
                            ],
                            variant: "outlined"
                          })
                    ]
                  }),
              JsxRuntime.jsx(Typography, {
                    level: "body-lg",
                    children: "This action cannot be undone. Are you sure you want to proceed?"
                  }),
              JsxRuntime.jsx(Button, {
                    loading: match$1.loading,
                    onClick: onClick,
                    color: "danger",
                    children: "Merge Accounts"
                  })
            ]
          });
    } else {
      tmp$1 = JsxRuntime.jsx(MergeModal$MergeResult, {
            result: mergedStatus,
            clearAll: clearAll
          });
    }
    tmp = JsxRuntime.jsx(Stack, {
          direction: "column",
          spacing: 2,
          children: Caml_option.some(tmp$1)
        });
  } else {
    exit$1 = 1;
  }
  if (exit$1 === 1) {
    tmp = JsxRuntime.jsx(Typography, {
          level: "body-lg",
          children: "Select source and destination accounts to merge."
        });
  }
  return JsxRuntime.jsx(Dialog$Thick.make, {
              open_: props.open_,
              onClose: onClose$1,
              title: title,
              children: JsxRuntime.jsx(DialogContent, {
                    children: Caml_option.some(tmp)
                  })
            });
}

var make = MergeModal;

exports.MergeSummary = MergeSummary;
exports.MergeResult = MergeResult;
exports.make = make;
/* Dialog-Thick Not a pure module */
