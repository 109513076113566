// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var CS_Make$Util = require("util/src/CustomScalars/CS_Make.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var DeckMapUtilListRefetchQuery_graphql$Thick = require("./DeckMapUtilListRefetchQuery_graphql.bs.js");

var Types = {};

var fragmentConverter = {"__root":{"voyageCabins_nodes_voyageClassSlug":{"c":"Util.CustomScalars.CabinClassSlug.Exn"},"voyageCabins_nodes_voyageCategorySlug":{"c":"Util.CustomScalars.CabinCategorySlug.Exn"},"voyageCabins_nodes_segment_colorHex":{"b":""},"voyageCabins_nodes_salesMaxBerth":{"b":""},"voyageCabins_nodes_reservationNoteMd":{"c":"Util.CustomScalars.NonemptyString.Exn"},"voyageCabins_nodes_minBerths":{"b":""},"voyageCabins_nodes_maxBerths":{"b":""},"voyageCabins_nodes_deckNumber":{"c":"Util.CustomScalars.DeckNumber.Exn"},"voyageCabins_nodes_configurationNoteMd":{"c":"Util.CustomScalars.NonemptyString.Exn"},"voyageCabins_nodes_cabinNumber":{"c":"Util.CustomScalars.CabinNumber.Exn"},"voyageCabins_nodes_cabinFetchedAt":{"c":"Util.CustomScalars.SQLDateTime.Exn"},"voyageCabins_nodes_booking_nodes_voyageSlug":{"c":"Util.CustomScalars.VoyageSlug.Exn"},"voyageCabins_nodes_booking_nodes_brandFamilySlug":{"c":"Util.CustomScalars.BrandFamilySlug.Exn"},"voyageCabins_nodes_booking_nodes_bookingVersion_nodes_preferences_generalPref":{"c":"Util.CustomScalars.NonemptyString.Exn"},"voyageCabins_nodes_booking_nodes_bookingVersion_nodes_preferences_cabinPlacementRequest":{"c":"Util.CustomScalars.NonemptyString.Exn"},"voyageCabins_nodes_booking_nodes_bookingVersion_nodes_placementComment":{"c":"Util.CustomScalars.NonemptyString.Exn"},"voyageCabins_nodes_booking_nodes_bookingVersion_nodes_passenger_nodes_idx":{"b":""},"voyageCabins_nodes_booking_nodes_bookingVersion_nodes_numBerths":{"b":""},"voyageCabins_nodes_booking_nodes_bookingVersion_nodes_bookingVersionNumber":{"b":""},"voyageCabins_nodes_booking_nodes_bookingId":{"c":"Util.CustomScalars.BookingId.Exn"}}};

var fragmentConverterMap = {
  "Util.CustomScalars.BookingId.Exn": CS_NonemptyStrings$Util.BookingId.Exn.parse,
  "Util.CustomScalars.NonemptyString.Exn": CS_NonemptyStrings$Util.NonEmptyString.Exn.parse,
  "Util.CustomScalars.BrandFamilySlug.Exn": CS_Slugs$Util.BrandFamilySlug.Exn.parse,
  "Util.CustomScalars.VoyageSlug.Exn": CS_Slugs$Util.VoyageSlug.Exn.parse,
  "Util.CustomScalars.SQLDateTime.Exn": CS_Make$Util.Dateable.Plain.Exn.parse,
  "Util.CustomScalars.CabinNumber.Exn": CS_NonemptyStrings$Util.CabinNumber.Exn.parse,
  "Util.CustomScalars.DeckNumber.Exn": CS_NonemptyStrings$Util.DeckNumber.Exn.parse,
  "Util.CustomScalars.CabinCategorySlug.Exn": CS_Slugs$Util.CabinCategorySlug.Exn.parse,
  "Util.CustomScalars.CabinClassSlug.Exn": CS_Slugs$Util.CabinClassSlug.Exn.parse
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

function elevatorProximity_decode($$enum) {
  if ($$enum === "NEARELEVATORS" || $$enum === "FARELEVATORS") {
    return $$enum;
  }
  
}

function elevatorProximity_fromString(str) {
  return elevatorProximity_decode(str);
}

function longitudinality_decode($$enum) {
  if ($$enum === "AFT" || $$enum === "FORE" || $$enum === "MID") {
    return $$enum;
  }
  
}

function longitudinality_fromString(str) {
  return longitudinality_decode(str);
}

var Utils = {
  elevatorProximity_decode: elevatorProximity_decode,
  elevatorProximity_fromString: elevatorProximity_fromString,
  longitudinality_decode: longitudinality_decode,
  longitudinality_fromString: longitudinality_fromString
};

function makeNode(rescript_graphql_node_DeckMapUtilListRefetchQuery) {
  return ((function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "condition",
    "value": {
      "lifecycleStatus": "ACTIVE"
    }
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "brandFamily"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "voyage"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": rescript_graphql_node_DeckMapUtilListRefetchQuery
    }
  },
  "name": "DeckMapUtilList_query",
  "selections": [
    {
      "alias": "voyageCabins",
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "brandFamilySlug",
              "variableName": "brandFamily"
            },
            {
              "kind": "Variable",
              "name": "voyageSlug",
              "variableName": "voyage"
            }
          ],
          "kind": "ObjectValue",
          "name": "condition"
        }
      ],
      "concreteType": "CabinsConnection",
      "kind": "LinkedField",
      "name": "cabins",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Cabin",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "deckNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cabinNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "voyageClassSlug",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "voyageCategorySlug",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "voyageSegment",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Segment",
              "kind": "LinkedField",
              "name": "segment",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "colorHex",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isHeld",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isBooked",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "minBerths",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "maxBerths",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "salesMaxBerth",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "reservationNoteMd",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "configurationNoteMd",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cabinFetchedAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": (v0/*: any*/),
              "concreteType": "BookingsConnection",
              "kind": "LinkedField",
              "name": "booking",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Booking",
                  "kind": "LinkedField",
                  "name": "nodes",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "bookingId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "brandFamilySlug",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "voyageSlug",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": (v0/*: any*/),
                      "concreteType": "BookingVersionsConnection",
                      "kind": "LinkedField",
                      "name": "bookingVersion",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "BookingVersion",
                          "kind": "LinkedField",
                          "name": "nodes",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "bookingVersionNumber",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "BookingPreference",
                              "kind": "LinkedField",
                              "name": "preferences",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "bedsTogetherPref",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "cabinLongitudePref",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "cabinElevatorPref",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "cabinPlacementRequest",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "earlyDinnerSeatingPref",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "generalPref",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "PassengersConnection",
                              "kind": "LinkedField",
                              "name": "passenger",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "Passenger",
                                  "kind": "LinkedField",
                                  "name": "nodes",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "idx",
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "BfcustUser",
                                      "kind": "LinkedField",
                                      "name": "bfcustUser",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "alias": null,
                                          "args": null,
                                          "kind": "ScalarField",
                                          "name": "fullName",
                                          "storageKey": null
                                        }
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "numBerths",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "finalPlacement",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "placementComment",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": "bookingVersion(condition:{\"lifecycleStatus\":\"ACTIVE\"},first:1)"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": "booking(condition:{\"lifecycleStatus\":\"ACTIVE\"},first:1)"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})());
}

var node = makeNode(DeckMapUtilListRefetchQuery_graphql$Thick.node);

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
