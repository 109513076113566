// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Js_exn = require("rescript/lib/js/js_exn.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Belt_Int = require("rescript/lib/js/belt_Int.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var AppChip$Util = require("util/src/AppChip.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var DateOnly$Util = require("util/src/DateOnly.bs.js");
var Stack = require("@mui/joy/Stack").default;
var Reporting$Util = require("util/src/Reporting.bs.js");
var RemoteData$Util = require("util/src/RemoteData.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Caml_js_exceptions = require("rescript/lib/js/caml_js_exceptions.js");
var Typography = require("@mui/joy/Typography").default;

function VoyageDashboardPage(props) {
  var voyageData = props.voyageData;
  var voyage = props.voyage;
  var url = process.env.GRAPHILE_THICK_ABSPATH;
  var voyslugstr = CS_Slugs$Util.VoyageSlug.toString(voyage);
  var windowStart = String((function (year) {
            return year - 1 | 0;
          })(Belt_Option.getExn(Belt_Int.fromString(DateOnly$Util.format(voyageData.voyStartDate, "yyyy"))))) + "-01-01";
  var windowEnd = DateOnly$Util.format(voyageData.voyStartDate, "yyyy-MM-dd");
  var useDashboardQuery = function (query, rotateOpt) {
    var rotate = rotateOpt !== undefined ? rotateOpt : false;
    return Reporting$Util.Graphql.useTable(url, query, rotate);
  };
  var makeDownloadButton = function (fileName, query, headersOverride) {
    var action = Reporting$Util.Graphql.getDownloadActionForTableAsCSV(url, query, CS_Slugs$Util.VoyageSlug.toString(voyage) + "_" + fileName, undefined, undefined, headersOverride);
    return JsxRuntime.jsx("button", {
                children: fileName,
                onClick: (function (param) {
                    action();
                  })
              });
  };
  var renderTable = function (tab, withHeadersOpt, divAfterOpt, firstColOpt) {
    var withHeaders = withHeadersOpt !== undefined ? withHeadersOpt : false;
    var divAfter = divAfterOpt !== undefined ? divAfterOpt : [];
    var firstCol = firstColOpt !== undefined ? firstColOpt : true;
    if (typeof tab !== "object") {
      return null;
    }
    if (tab.TAG !== "Success") {
      return "There was an error loading the query";
    }
    var tab$1 = tab._0;
    if (tab$1 !== undefined) {
      return JsxRuntime.jsxs("table", {
                  children: [
                    withHeaders ? JsxRuntime.jsx("thead", {
                            children: JsxRuntime.jsx("tr", {
                                  children: Belt_Array.mapWithIndex(tab$1.headers, (function (ind, v) {
                                          return JsxRuntime.jsx("th", {
                                                      children: v,
                                                      style: ind === 0 && firstCol ? ({
                                                            border: "1px solid #ccc",
                                                            borderRight: "5px double #ccc",
                                                            fontSize: "14px",
                                                            padding: "10px",
                                                            textAlign: "right"
                                                          }) : (
                                                          divAfter.includes(ind) ? ({
                                                                border: "1px solid #ccc",
                                                                borderRight: "5px double #ccc",
                                                                fontSize: "14px",
                                                                padding: "10px",
                                                                textAlign: "left"
                                                              }) : ({
                                                                border: "1px solid #ccc",
                                                                fontSize: "14px",
                                                                padding: "10px",
                                                                textAlign: "left"
                                                              })
                                                        )
                                                    });
                                        })),
                                  style: {
                                    background: "#3498db",
                                    color: "white",
                                    fontWeight: "bold"
                                  }
                                })
                          }) : null,
                    JsxRuntime.jsx("tbody", {
                          children: Belt_Array.mapWithIndex(tab$1.rows, (function (rowInd, row) {
                                  return JsxRuntime.jsx("tr", {
                                              children: Belt_Array.mapWithIndex(tab$1.headers, (function (ind, hdr) {
                                                      return JsxRuntime.jsx("td", {
                                                                  children: Reporting$Util.getHeader(row, hdr),
                                                                  style: ind === 0 && firstCol ? ({
                                                                        border: "1px solid #ccc",
                                                                        borderRight: "5px double #777",
                                                                        fontSize: "14px",
                                                                        fontWeight: "800",
                                                                        padding: "10px",
                                                                        textAlign: "right"
                                                                      }) : Object.assign({}, divAfter.includes(ind) ? ({
                                                                              border: "1px solid #ccc",
                                                                              borderRight: "5px double #777",
                                                                              fontSize: "14px",
                                                                              padding: "10px",
                                                                              textAlign: "left"
                                                                            }) : ({
                                                                              border: "1px solid #ccc",
                                                                              fontSize: "14px",
                                                                              padding: "10px",
                                                                              textAlign: "left"
                                                                            }), Reporting$Util.getHeader(row, hdr).startsWith("($") ? ({
                                                                              color: "red"
                                                                            }) : ({}))
                                                                });
                                                    })),
                                              style: rowInd % 2 === 0 ? ({
                                                    background: "white"
                                                  }) : ({
                                                    background: "#eee"
                                                  })
                                            });
                                }))
                        })
                  ],
                  style: {
                    borderCollapse: "collapse"
                  }
                });
    } else {
      return "No Rows to display ";
    }
  };
  var allBookingsByStatusQuery = "\n  {\n    query {\n        reportVoyBookingsRollup(\n      _voyageSlug: \"" + voyslugstr + "\"\n      filterKey: NONE\n      rollupKey: LIFECYCLE_STATUS\n    ) {\n      nodes {\n        status: rollupValue\n        totalBookings: numBookings\n        totalBerths\n      }\n    }\n    }\n  }\n  ";
  var completedPaymentsByPmtMethodQuery = "\n  {\n    query {\n        reportVoyPaymentRollup(\n      _voyageSlug: \"" + voyslugstr + "\"\n      filterKey: COMPLETED_PAYMENTS\n      rollupKey: PMT_METHOD\n    ) {\n      nodes {\n        paymentMethod: rollupValue\n        incomingTotal: netIncoming\n        outgoingTotal: netOutgoing\n        processorFees: netBfFees\n\n      }\n    }\n    }\n  }\n  ";
  var allPaxByUpperLowerQuery = "\n  {\n    query {\n        reportVoyPaxRollup(\n      _voyageSlug: \"" + voyslugstr + "\"\n      filterKey: ACTIVE_BOOKINGS\n      rollupKey: UPPER_LOWER\n    ) {\n      nodes {\n        berthType: rollupValue\n        nonTBAPax: numPax\n\n      }\n    }\n    }\n  }\n  ";
  var pendingPaymentsByPmtMethodQuery = "\n  {\n    query {\n        reportVoyPaymentRollup(\n      _voyageSlug: \"" + voyslugstr + "\"\n      filterKey: PENDING_PAYMENTS\n      rollupKey: PMT_METHOD\n    ) {\n      nodes {\n        paymentMethod: rollupValue\n        incomingTotal: netIncoming\n        outgoingTotal: netOutgoing\n        processorFees: netBfFees\n\n      }\n    }\n    }\n  }\n  ";
  var netBillingsQuery = "\n  {\n    query {\n        reportVoyBillingsRollup(\n      _voyageSlug: \"" + voyslugstr + "\"\n      filterKey: NONE\n      rollupKey: NONE\n    ) {\n      nodes {\n        netBillings: totalBilled\n        netBaseFare: totalBaseFares\n        netDiscounts: totalDynamicPricing\n        netFees: totalChangeFee\n        suppressedFees: totalSuppressed\n        netManualBilling: totalManual\n      }\n    }\n    }\n  }\n  ";
  var fareClassSalesQuery = "\n  {\n    query {\n        reportVoyBookingsRollup(\n      _voyageSlug: \"" + voyslugstr + "\"\n      filterKey: ACTIVE_BOOKINGS\n      rollupKey: FARE_CLASS_SLUG\n    ) {\n      nodes {\n        fareClass:rollupValue\n        activeBookings:numBookings\n        activeBerths:totalBerths\n        netBillings\n      }\n    }\n    }\n  }\n  ";
  var fareClassSalesCanceledQuery = "\n  {\n    query {\n        reportVoyBookingsRollup(\n      _voyageSlug: \"" + voyslugstr + "\"\n      filterKey: CANCELED_BOOKINGS\n      rollupKey: FARE_CLASS_SLUG\n    ) {\n      nodes {\n        fareClass:rollupValue\n        canceledBookings:numBookings\n        canceledBerths:totalBerths\n        netBillings\n      }\n    }\n    }\n  }\n  ";
  var inventoryByClassQuery = "\n  {\n    query {\n        reportVoyInventoryRollup(\n      _voyageSlug: \"" + voyslugstr + "\"\n      filterKey: NONE\n      rollupKey: CLASS\n    ) {\n      nodes {\n        class:rollupValue\n        booked:totalBookedCabins\n        available:totalAvailCabins\n        total:totalCabins\n        retailBooked:retailBookedCabins\n        retailAvail:retailAvailCabins\n        waitlist:waitlistTotalCabins\n        accessisbleHold:accessibleHoldTotalCabins\n        privateBooked:privateBookedCabins\n        privateAvail:privateAvailCabins\n      }\n    }\n    }\n  }\n  ";
  var inventoryByCategoryQuery = "\n  {\n    query {\n        reportVoyInventoryRollup(\n      _voyageSlug: \"" + voyslugstr + "\"\n      filterKey: NONE\n      rollupKey: CATEGORY\n    ) {\n      nodes {\n        class:rollupValue\n        booked:totalBookedCabins\n        available:totalAvailCabins\n        total:totalCabins\n        retailBooked:retailBookedCabins\n        retailAvail:retailAvailCabins\n        waitlist:waitlistTotalCabins\n        accessisbleHold:accessibleHoldTotalCabins\n        privateBooked:privateBookedCabins\n        privateAvail:privateAvailCabins\n      }\n    }\n    }\n  }\n  ";
  var discountsQuery = "\n  {\n    query {\n        reportVoyBillingsRollup(\n      _voyageSlug: \"" + voyslugstr + "\"\n      filterKey: DYNAMIC_PRICING\n      rollupKey: FARE_RULE_SLUG\n    ) {\n      nodes {\n        type: rollupValue\n        netBillings: totalBilled\n        count:numBillings\n      }\n    }\n    }\n  }\n  ";
  var feesQuery = "\n  {\n    query {\n        reportVoyBillingsRollup(\n      _voyageSlug: \"" + voyslugstr + "\"\n      filterKey: CHANGE_FEES\n      rollupKey: FARE_RULE_SLUG\n    ) {\n      nodes {\n        type: rollupValue\n        netBillings: totalBilled\n        count:numBillings\n      }\n    }\n    }\n  }\n  ";
  var dinnerSeatingQuery = "\n  {\n    query {\n        reportVoyBookingsRollup(\n      _voyageSlug: \"" + voyslugstr + "\"\n      filterKey: ACTIVE_BOOKINGS\n      rollupKey: PREFER_EARLY_DINNER\n    ) {\n      nodes {\n        preference: rollupValue\n        bookings: numBookings\n        berths:totalBerths\n      }\n    }\n    }\n  }\n  ";
  var custAcquisitionQuery = "\n  {\n    query {\n        reportVoyPaxRollup(\n      _voyageSlug: \"" + voyslugstr + "\"\n      filterKey: ACTIVE_BOOKINGS\n      rollupKey: ACQUISITION_SLUG\n    ) {\n      nodes {\n        channel: rollupValue\n        count: numPax\n        newPax: numFirstTime\n        returners: numReturning\n\n      }\n    }\n    }\n  }\n  ";
  var loyaltyQuery = "\n  {\n    query {\n        reportVoyPaxRollup(\n      _voyageSlug: \"" + voyslugstr + "\"\n      filterKey: ACTIVE_BOOKINGS\n      rollupKey: LOYALTY_TIER_SLUG\n    ) {\n      nodes {\n        channel: rollupValue\n        count: numPax\n\n      }\n    }\n    }\n  }\n  ";
  var initAllBookingsByStatus = useDashboardQuery(allBookingsByStatusQuery, undefined);
  var initCompletedPaymentsByPmtMethod = useDashboardQuery(completedPaymentsByPmtMethodQuery, undefined);
  var initAllPaxByUpperLower = useDashboardQuery(allPaxByUpperLowerQuery, undefined);
  var initPendingPaymentsByPmtMethod = useDashboardQuery(pendingPaymentsByPmtMethodQuery, undefined);
  var initNetBillings = useDashboardQuery(netBillingsQuery, undefined);
  var initFareClassSales = useDashboardQuery(fareClassSalesQuery, undefined);
  var initFareClassSalesCanceled = useDashboardQuery(fareClassSalesCanceledQuery, undefined);
  var initInventoryByClass = useDashboardQuery(inventoryByClassQuery, undefined);
  var initInventoryByCategory = useDashboardQuery(inventoryByCategoryQuery, undefined);
  var initDiscounts = useDashboardQuery(discountsQuery, undefined);
  var initFees = useDashboardQuery(feesQuery, undefined);
  var initDinnerSeating = useDashboardQuery(dinnerSeatingQuery, undefined);
  var initCustAcquisition = useDashboardQuery(custAcquisitionQuery, undefined);
  var initLoyalty = useDashboardQuery(loyaltyQuery, undefined);
  var match = React.useState(function () {
        return initAllBookingsByStatus;
      });
  var setAllBookingsByStatus = match[1];
  var match$1 = React.useState(function () {
        return initCompletedPaymentsByPmtMethod;
      });
  var setCompletedPaymentsByPmtMethod = match$1[1];
  var match$2 = React.useState(function () {
        return initAllPaxByUpperLower;
      });
  var setAllPaxByUpperLower = match$2[1];
  var match$3 = React.useState(function () {
        return initPendingPaymentsByPmtMethod;
      });
  var setPendingPaymentsByPmtMethod = match$3[1];
  var match$4 = React.useState(function () {
        return initNetBillings;
      });
  var setNetBillings = match$4[1];
  var match$5 = React.useState(function () {
        return initFareClassSales;
      });
  var setFareClassSales = match$5[1];
  var match$6 = React.useState(function () {
        return initFareClassSalesCanceled;
      });
  var setFareClassSalesCanceled = match$6[1];
  var match$7 = React.useState(function () {
        return initInventoryByClass;
      });
  var setInventoryByClass = match$7[1];
  var match$8 = React.useState(function () {
        return initInventoryByCategory;
      });
  var setInventoryByCategory = match$8[1];
  var match$9 = React.useState(function () {
        return initDiscounts;
      });
  var setDiscounts = match$9[1];
  var match$10 = React.useState(function () {
        return initFees;
      });
  var setFees = match$10[1];
  var match$11 = React.useState(function () {
        return initDinnerSeating;
      });
  var setDinnerSeating = match$11[1];
  var match$12 = React.useState(function () {
        return initCustAcquisition;
      });
  var setCustAcquisition = match$12[1];
  var match$13 = React.useState(function () {
        return initLoyalty;
      });
  var setLoyalty = match$13[1];
  var match$14 = React.useState(function () {
        return false;
      });
  var setLoading = match$14[1];
  React.useEffect((function () {
          var fetchData = async function (url, gqlQuery, setterFn) {
            try {
              setLoading(function (param) {
                    return true;
                  });
              var table = await RemoteData$Util.$$Promise.mapSuccess(Reporting$Util.Graphql.request(url, gqlQuery), (function (v) {
                      return Reporting$Util.flatten(v);
                    }));
              setterFn(function (param) {
                    return table;
                  });
              return setLoading(function (param) {
                          return false;
                        });
            }
            catch (raw_obj){
              var obj = Caml_js_exceptions.internalToOCamlException(raw_obj);
              if (obj.RE_EXN_ID === Js_exn.$$Error) {
                console.error(obj._1);
                return setLoading(function (param) {
                            return false;
                          });
              }
              throw obj;
            }
          };
          Promise.all([
                fetchData(url, allBookingsByStatusQuery, setAllBookingsByStatus),
                fetchData(url, completedPaymentsByPmtMethodQuery, setCompletedPaymentsByPmtMethod),
                fetchData(url, allPaxByUpperLowerQuery, setAllPaxByUpperLower),
                fetchData(url, pendingPaymentsByPmtMethodQuery, setPendingPaymentsByPmtMethod),
                fetchData(url, netBillingsQuery, setNetBillings),
                fetchData(url, fareClassSalesQuery, setFareClassSales),
                fetchData(url, fareClassSalesCanceledQuery, setFareClassSalesCanceled),
                fetchData(url, inventoryByClassQuery, setInventoryByClass),
                fetchData(url, inventoryByCategoryQuery, setInventoryByCategory),
                fetchData(url, discountsQuery, setDiscounts),
                fetchData(url, feesQuery, setFees),
                fetchData(url, dinnerSeatingQuery, setDinnerSeating),
                fetchData(url, custAcquisitionQuery, setCustAcquisition),
                fetchData(url, loyaltyQuery, setLoyalty)
              ]);
        }), [voyage]);
  return JsxRuntime.jsxs(Stack, {
              spacing: 1,
              children: [
                JsxRuntime.jsx(Typography, {
                      level: "h1",
                      children: Caml_option.some("Welcome to " + voyageData.voyageName + ": a " + CS_Slugs$Util.BrandFamilySlug.toString(props.brandFamily) + " voyage!")
                    }),
                JsxRuntime.jsxs(Stack, {
                      direction: "row",
                      spacing: 1,
                      useFlexGap: true,
                      flexWrap: "wrap",
                      sx: {
                        overflow: "hidden"
                      },
                      children: [
                        JsxRuntime.jsx(AppChip$Util.make, {
                              children: "Voyage Start Date: " + DateOnly$Util.format(voyageData.voyStartDate, "yyyy/MM/dd"),
                              color: "Admin"
                            }),
                        JsxRuntime.jsx(AppChip$Util.make, {
                              children: "Months remaining: " + String(DateOnly$Util.monthsUntil(voyageData.voyStartDate)),
                              color: "Admin"
                            }),
                        JsxRuntime.jsx(AppChip$Util.make, {
                              children: "Weeks remaining: " + String(DateOnly$Util.weeksUntil(voyageData.voyStartDate)),
                              color: "Admin"
                            }),
                        JsxRuntime.jsx(AppChip$Util.make, {
                              children: "Days remaining: " + String(DateOnly$Util.daysUntil(voyageData.voyStartDate)),
                              color: "Admin"
                            })
                      ]
                    }),
                match$14[0] ? "Loading. . ." : JsxRuntime.jsxs(Stack, {
                        direction: "row",
                        spacing: 2,
                        children: [
                          JsxRuntime.jsxs("div", {
                                children: [
                                  JsxRuntime.jsx("h3", {
                                        children: "Booking Totals"
                                      }),
                                  renderTable(match[0], true, undefined, undefined),
                                  JsxRuntime.jsx("br", {}),
                                  renderTable(match$2[0], true, undefined, undefined),
                                  JsxRuntime.jsx("h3", {
                                        children: "Completed Payments"
                                      }),
                                  renderTable(match$1[0], true, undefined, undefined),
                                  JsxRuntime.jsx("h3", {
                                        children: "Pending Payments"
                                      }),
                                  renderTable(match$3[0], true, undefined, undefined),
                                  JsxRuntime.jsx("h3", {
                                        children: "Billings"
                                      }),
                                  renderTable(match$4[0], true, undefined, false),
                                  JsxRuntime.jsx("h3", {
                                        children: "Fare Class Breakdown"
                                      }),
                                  renderTable(match$5[0], true, undefined, undefined),
                                  JsxRuntime.jsx("br", {}),
                                  renderTable(match$6[0], true, undefined, undefined),
                                  JsxRuntime.jsx("h3", {
                                        children: "Inventory by Class"
                                      }),
                                  renderTable(match$7[0], true, [
                                        3,
                                        5,
                                        6,
                                        7
                                      ], undefined),
                                  JsxRuntime.jsx("h3", {
                                        children: "Inventory by Category"
                                      }),
                                  renderTable(match$8[0], true, [
                                        3,
                                        5,
                                        6,
                                        7
                                      ], undefined),
                                  JsxRuntime.jsx("h3", {
                                        children: "Discounts"
                                      }),
                                  renderTable(match$9[0], true, undefined, undefined),
                                  JsxRuntime.jsx("h3", {
                                        children: "Fees"
                                      }),
                                  renderTable(match$10[0], true, undefined, undefined),
                                  JsxRuntime.jsx("h3", {
                                        children: "Dinner Seating Preferences"
                                      }),
                                  renderTable(match$11[0], true, undefined, undefined),
                                  JsxRuntime.jsx("h3", {
                                        children: "Customer Acquisition"
                                      }),
                                  renderTable(match$12[0], true, undefined, undefined),
                                  JsxRuntime.jsx("h3", {
                                        children: "Passengers by Loyalty Tier"
                                      }),
                                  renderTable(match$13[0], true, undefined, undefined)
                                ]
                              }),
                          JsxRuntime.jsxs("div", {
                                children: [
                                  JsxRuntime.jsx("h3", {
                                        children: "Downloadable Reports"
                                      }),
                                  makeDownloadButton("Booking and Cancellation Curves", "\n            query {\n              reportVoyBookingAndCancelCurves(\n                _voyageSlug: \"" + voyslugstr + "\"\n                bucket: DAY\n                _fareClassSlug: \"retail\"\n                windowEnd: \"" + windowEnd + "\"\n                windowStart: \"" + windowStart + "\"\n              ) {\n                nodes {\n                  activityDate\n                  bookingStarts\n                  berthsInBookingStarts\n                  numBookings\n                  totalBerths\n                  numCanceled\n                  totalCanceledBerths\n                  netIncomingPayments\n                  netOutgoingPayments\n                }\n              }\n            }\n          ", undefined),
                                  JsxRuntime.jsx("br", {}),
                                  makeDownloadButton("All Booked Pax", "\n            query {\n              reportVoyAllBookedPax(\n              _fareClassSlug: \"\", _voyageSlug: \"" + voyslugstr + "\",_includeAccountStatus:[]) {\n                nodes {\n                  loyaltyNumber\n                  bookingId\n                  bookingCreatedAt\n                  paxIdx\n                  cruiselineBookingNumber\n                  cabinCategory\n                  cabinNumber\n                  cabinSalesMaxOccupancy\n                  cabinCruiselineMaxOccupancy\n                  cruiselineBookingNumberSent\n                  displayFirstName\n                  displayLastName\n                  docFirstName\n                  docLastName\n                  docGender\n                  docBirthdate\n                  docCitizenship\n                  residenceCountry\n                  email\n                  invitationEmail\n                  phone\n                  postalCode\n                  inventorySegment\n                  fareClass\n                  bookingCreatedAt\n                  userAccountStatus\n                  howHeard\n                  bookingDinnerSeating\n                  bookingBedConfiguration\n                  currentTier\n                  previousCruises\n                  previousCruiseCount\n                  pendingTier\n                  pendingCruises\n                  pendingCruiseCount\n                  ageAsOfSailing\n                  billingContactName\n                  billingContactEmail\n                  accountId\n                  migratedEmail\n                  migratedUsername\n                }\n              }\n            }\n          ", undefined),
                                  JsxRuntime.jsx("br", {}),
                                  makeDownloadButton("Booked Pax No History", "\n            query {\n              reportVoyAllBookedPax(\n              _fareClassSlug: \"\",\n              _voyageSlug: \"" + voyslugstr + "\"\n              _includeAccountStatus:[]) {\n                nodes {\n                  loyaltyNumber\n                  paxIdx\n                  cruiselineBookingNumber\n                  cruiselineBookingNumberSent\n                  displayFirstName\n                  displayLastName\n                  docFirstName\n                  docLastName\n                  docGender\n                  docBirthdate\n                  docCitizenship\n                  postalCode\n                  residenceCountry\n                  email\n                  phone\n                  bookingCreatedAt\n                  bookingId\n                  halMarinerNumber\n                }\n              }\n            }\n          ", undefined),
                                  JsxRuntime.jsx("br", {}),
                                  makeDownloadButton("Cabin Inventory", "\n            query {\n              reportVoyCabinInventory(_voyageSlug: \"" + voyslugstr + "\") {\n                nodes {\n                  cabinNumber\n                  holdExpiresAt\n                  salesMinBerth\n                  salesMaxBerth\n                  totalBerths\n                  cabinDeck\n                  cabinConfigurationNotes\n                  reservedByBooking\n                  heldByCustomer\n                  heldByTeamMember\n                  cabinCategorySlug\n                  inventorySegment\n                  cabinReservationNotes\n                }\n              }\n            }\n          ", undefined),
                                  JsxRuntime.jsx("br", {}),
                                  makeDownloadButton("Billed and Paid by Booking", "\n            query {\n              reportVoyBilledAndPaidByBooking(_voyageSlug: \"" + voyslugstr + "\") {\n                nodes {\n                  bookingId\n                  bookingCreatedAt\n                  fareClassSlug\n                  numBerths\n                  billingContactFirstName\n                  billingContactLastName\n                  billingContactEmail\n                  billingContactPhone\n                  bookingStatus\n                  totPaid\n                  totBilled\n                  hasPifDiscount\n                  fullyPaid\n                  pifDiscountAmount\n                  hasPifIntention\n                  pifIntentionAmount\n                }\n              }\n            }\n          ", undefined),
                                  JsxRuntime.jsx("br", {}),
                                  makeDownloadButton("Payment Plan Status", "\n            query {\n              bookingPaymentPlans(\n              filter: {voyageSlug: {equalTo: \"" + voyslugstr + "\"}}\n              ) {\n                nodes {\n                  bookingId\n                  balance: totalBalanceDue\n                  overdueByDays\n                  overdueAmount\n                  maximalDuePeriodIdx\n                  nextAmountDueAfter\n                  nextAmountDue\n                  secondNextAmountDueAfter\n                  secondNextAmountDue\n                  booking_version {\n                    billingContact {\n                      email\n                      fullName\n                      phone\n                    }\n                  }\n                  paymentTypes\n                }\n              }\n            }\n          ", Js_dict.fromArray([
                                            [
                                              "bookingId",
                                              "BookingId"
                                            ],
                                            [
                                              "balance",
                                              "TotalBalanceDue"
                                            ],
                                            [
                                              "overdueByDays",
                                              "OverdueByDays"
                                            ],
                                            [
                                              "overdueAmount",
                                              "OverdueAmt"
                                            ],
                                            [
                                              "maximalDuePeriodIdx",
                                              "MaxDuePeriod"
                                            ],
                                            [
                                              "nextAmounttDueAfter",
                                              "NextAmtDueAfter"
                                            ],
                                            [
                                              "nextAmountDue",
                                              "NextAmtDue"
                                            ],
                                            [
                                              "secondNextAmounttDueAfter",
                                              "SecondNextAmtDueAfter"
                                            ],
                                            [
                                              "secondNextAmountDue",
                                              "SecondNextAmtDue"
                                            ],
                                            [
                                              "booking_version.billingContact.email",
                                              "BillingEmail"
                                            ],
                                            [
                                              "booking_version.billingContact.fullName",
                                              "BillingFullname"
                                            ],
                                            [
                                              "booking_version.billingContact.phone",
                                              "BillingPhone"
                                            ],
                                            [
                                              "paymentTypes",
                                              "PaymentTypesUsed"
                                            ]
                                          ])),
                                  JsxRuntime.jsx("br", {}),
                                  makeDownloadButton("Private Block Breakdown", "\n            query {\n              reportVoyPrivateBlockBreakdown(_voyageSlug: \"" + voyslugstr + "\") {\n                nodes {\n                  ClassCatID:voyageClassSlug\n                  CabinClassName:voyageCategorySlug\n                  CabinDeck:cabinDeck\n                  CabinNumber:cabinNumber\n                  CabinPrivateReservation:cabinReservationNotes\n                  InventorySegment:inventorySegment\n                  SegmentRevenueClassification:emptyColOldCompat\n                  SegmentNotes:emptyColOldCompat\n                  CabinInventoryBucket:inventoryBucketOldCompat\n                  CabinInventoryBucketNotes:emptyColOldCompat\n                  BookingStatus:bookingStatusOldCompat\n                  PublicBookingID:bookingId\n                  PaxList:paxListOldCompat\n                  ContractOccupancy:totBerths\n                  PrimName:billingContactName\n                  PrimAndComment:billingContactAndNoteOldCompat\n                  ReportGenerationTimestamp:reportGenerationTimestampOldCompat\n                  NumGuests:numBerths\n                }\n              }\n            }\n          ", undefined),
                                  JsxRuntime.jsx("br", {}),
                                  makeDownloadButton("Simple Dupechecker", "\n            query {\n              reportVoySimpleDupechecker(_voyageSlug: \"" + voyslugstr + "\") {\n                nodes {\n                  bdayLastNameDupeKey\n                  numberOfOccurences\n                  bookingId\n                  bookingStatus\n                  loyaltyNumber\n                  paxIdx\n                  preferredFirstName\n                  preferredLastName\n                  docFirstName\n                  docLastName\n                  email\n                  docGender\n                  phone\n                  accountStatus\n                  numEligibleCruises\n                  lastCruise\n                  pastCruises\n                }\n              }\n            }\n          ", undefined),
                                  JsxRuntime.jsx("br", {}),
                                  makeDownloadButton("Billing Lines by Booking", "\n              query {\n                bookingBillinglines(\n                filter: {voyageSlug: {equalTo: \"" + voyslugstr + "\"},bookingVersionLifecycleStatus: {in: [ACTIVE, CANCELED]}}\n              ) {\n                nodes {\n                  bookingId\n                  bookingVersionLifecycleStatus\n                  billingKind\n                  fareRuleSlug\n                  billingLineDescription\n                  lineAmount\n                }\n              }}\n          ", undefined),
                                  JsxRuntime.jsx("br", {}),
                                  makeDownloadButton("Placement Requests", "\n              query {\n                  reportVoyBookings(\n              _fareClassSlug: \"\"\n              _lifecycleStatus: \"active\"\n              _voyageSlug: \"" + voyslugstr + "\"\n              filter: {\n                or: [\n                  {cabinElevatorPref: {isNull: false}},\n                  {cabinLongitudePref: {isNull: false}},\n                  {cabinPlacementRequest: {isNull: false}},\n                  {placementComment: {isNull: false}},\n                  {generalPref: {isNull: false}}\n                ]\n\n              }\n            ) {\n              nodes {\n                bookingCreatedAt\n                cabinNumber\n                cabinCategory\n                cabinDeckNumber\n                bookingId\n                numBerths\n                inventorySegment\n                fareClassSlug\n                bedsTogetherPref\n                earlyDinnerSeatingPref\n                cabinLongitudePref\n                cabinElevatorPref\n                cabinPlacementRequest\n                generalPref\n                placementComment\n                finalPlacement\n                billingContactName\n                billingContactEmail\n              }\n            }}\n          ", undefined),
                                  JsxRuntime.jsx("br", {}),
                                  makeDownloadButton("Bookings with TBAs", "\n              query {\n                  reportVoyBookings(\n                  _fareClassSlug: \"\"\n                  _lifecycleStatus: \"active\"\n                  _voyageSlug: \"" + voyslugstr + "\"\n                  filter: {tbaCount: {greaterThan: 0}}\n                ) {\n                  nodes {\n                    bookingId\n                    numBerths\n                    tbaCount\n                    billingContactEmail\n                    billingContactName\n                  }\n              }}\n          ", undefined),
                                  JsxRuntime.jsx("br", {}),
                                  makeDownloadButton("Dinner Seating & Bed Configuration by Booking", "\n              query {\n                  reportVoyAllBookedPax(\n                  _fareClassSlug: \"\",\n                  _voyageSlug: \"" + voyslugstr + "\",\n                  _includeAccountStatus:[]) {\n              nodes {\n                bookingId\n                cruiselineBookingNumber\n                cabinCategory\n                cabinNumber\n                displayFirstName\n                displayLastName\n                bookingNumBerths\n                bookingDinnerSeating\n                bookingBedConfiguration\n                fareClass\n              }\n            }}\n          ", undefined),
                                  JsxRuntime.jsx("br", {}),
                                  makeDownloadButton("HAL Reconciliation Manifest", "\n              query {\n                  reportVoyAllBookedPax(\n                  _fareClassSlug: \"\",\n                  _voyageSlug: \"" + voyslugstr + "\"\n                  _includeAccountStatus: [CLAIMED,UNCLAIMED,PREFILLED,TRANSFERRING]\n                  ) {\n              nodes {\n                cruiselineBookingNumber\n                cabinCategory\n                cabinNumber\n                paxIdx\n          			docLastFirst\n                docBirthdate\n                docGender\n                docCitizenship\n          			dineTime\n                loyaltyNumber\n              }\n            }}\n          ", undefined),
                                  JsxRuntime.jsx("br", {}),
                                  makeDownloadButton("Email Sync Data", "\n              query emaillist {\n            reportVoyEmailSyncList(_voyageSlug: \"" + voyslugstr + "\") {\n              totalCount\n              nodes {\n                EMAIL: email\n                FULLNAME: fullDisplayName\n                PAXIDX: paxIdx\n                BOOKINGID: bookingId\n                ISBC: isBc\n                ACCTSTATUS: acctStatus\n                PRIMARYNAME: primaryName\n                NUMGUESTS: numGuests\n                ALLPAXNAME: allPaxName\n                CABINNUMBE: cabinNumber\n                CABINCAT: cabinCategory\n                CABINCLASS: cabinClass\n                CABINSUBCL: cabinSubclass\n                RECORDTYPE: recordType\n                INVTSTATUS: invtStatus\n                NUMTBAS: numTbas\n                HALNUMBER: halNumber\n                FARECLASS: fareClass\n                BEDSSTATUS: bedsStatus\n                DINNERSEAT: dinnerSeat\n                LOYALTYNUM: loyaltyNumber\n                ACCTEMAIL: accountEmail\n              }\n            }\n          }\n          ", undefined),
                                  JsxRuntime.jsx("br", {}),
                                  makeDownloadButton("OBB Rollup by Category", "\n              query obbrollup {\n            reportVoyInventoryDoubleRollup(\n              _voyageSlug: \"" + voyslugstr + "\"\n              filterKey: NONE\n              rollupKey: CATEGORY\n              rollupKey2: SALES_MAX_BERTH\n              segmentFilter: [\"public.obb\"]\n            ) {\n              nodes {\n                Category: rollupValue\n                MaxBerths: rollupValue2\n                OBBCabins: totalCabins\n                BookedOBBCbns: totalBookedCabins\n              }\n            }\n          }\n          ", undefined)
                                ]
                              })
                        ]
                      })
              ]
            });
}

var make = VoyageDashboardPage;

exports.make = make;
/* react Not a pure module */
