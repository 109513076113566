// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CS_Make$Util = require("util/src/CustomScalars/CS_Make.bs.js");
var Common$Thick = require("../../../utils/Common.bs.js");
var Dialog$Thick = require("../../common/molecules/Dialog.bs.js");
var Menu = require("@mui/joy/Menu").default;
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var AppAlert$Thick = require("../../common/atoms/AppAlert.bs.js");
var BDButton$Thick = require("../atoms/BDButton.bs.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var Button = require("@mui/joy/Button").default;
var Switch = require("@mui/joy/Switch").default;
var CS_Decimal$Util = require("util/src/CustomScalars/CS_Decimal.bs.js");
var Tooltip = require("@mui/joy/Tooltip").default;
var DraftUtils$Thick = require("../drafts/DraftUtils.bs.js");
var Dropdown = require("@mui/joy/Dropdown").default;
var MenuItem = require("@mui/joy/MenuItem").default;
var BookingDiff$Thick = require("../../../utils/BookingDiff.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var IconButton = require("@mui/joy/IconButton").default;
var MenuButton = require("@mui/joy/MenuButton").default;
var Typography = require("@mui/joy/Typography").default;
var BDRefundModal$Thick = require("../drafts/BDRefundModal.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var BDBillPayTable$Thick = require("./BDBillPayTable.bs.js");
var DialogActions = require("@mui/joy/DialogActions").default;
var DialogContent = require("@mui/joy/DialogContent").default;
var Add = require("@mui/icons-material/Add").default;
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var BDMovePaymentModal$Thick = require("../drafts/BDMovePaymentModal.bs.js");
var ManageBookingState$Thick = require("../../../utils/ManageBookingState.bs.js");
var Check = require("@mui/icons-material/Check").default;
var Replay = require("@mui/icons-material/Replay").default;
var ManageBookingContext$Thick = require("../../../context/ManageBookingContext.bs.js");
var MoreHoriz = require("@mui/icons-material/MoreHoriz").default;
var OpenInNew = require("@mui/icons-material/OpenInNew").default;
var AddManualBillingLineModal$Thick = require("../drafts/AddManualBillingLineModal.bs.js");
var InfoOutlined = require("@mui/icons-material/InfoOutlined").default;
var ArrowDropDown = require("@mui/icons-material/ArrowDropDown").default;
var DeleteForever = require("@mui/icons-material/DeleteForever").default;
var KeyboardReturn = require("@mui/icons-material/KeyboardReturn").default;
var SuppressAutoBillingLineModal$Thick = require("../drafts/SuppressAutoBillingLineModal.bs.js");
var ModeEditOutlined = require("@mui/icons-material/ModeEditOutlined").default;
var ReplayCircleFilled = require("@mui/icons-material/ReplayCircleFilled").default;
var BDRecordPostCancellationBillingModal$Thick = require("./BDRecordPostCancellationBillingModal.bs.js");

function BDInvoiceDetails$TableLine$BillingActions(props) {
  var versionUrl = props.versionUrl;
  var versionSuppressions = props.versionSuppressions;
  var versionTimestamp = props.versionTimestamp;
  var bl = props.bl;
  var shouldRender = props.shouldRender;
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var diffStruct = BookingDiff$Thick.parse(url.search);
  var match = ManageBookingContext$Thick.Context.use();
  var match$1 = React.useState(function () {
        return false;
      });
  var setConfirmModalOpen = match$1[1];
  var match$2 = React.useState(function () {
        return false;
      });
  var setModalOpen = match$2[1];
  var id = bl.passthruId;
  var toSuppressManual;
  if (id !== undefined) {
    toSuppressManual = {
      TAG: "Remove",
      _0: id
    };
  } else {
    var idx = ManageBookingState$Thick.getManualLineIdx(bl, match.manualBillingLineIdxMap, diffStruct);
    toSuppressManual = idx !== undefined ? ({
          TAG: "Suppress",
          _0: idx
        }) : undefined;
  }
  var match$3 = React.useState(function () {
        
      });
  var setSuppressError = match$3[1];
  var suppressError = match$3[0];
  var id$1 = bl.passthruId;
  var toSuppressAuto = id$1 !== undefined ? id$1 : (crypto.randomUUID());
  var isManual = bl.isManualLine;
  var match$4 = bl.isManualLine;
  var match$5 = bl.fareRuleSlug;
  var autoLine;
  if (match$4 || match$5 === undefined) {
    autoLine = "CannotSuppress";
  } else {
    var fareRuleSlug = Caml_option.valFromOption(match$5);
    if ((bl.ruleApplicationCount + bl.suppressedCount | 0) === 1) {
      var hookFiredAt = bl.hookFiredAt;
      var hookFiredAt$1;
      if (hookFiredAt !== undefined) {
        var hookFiredAt$2 = Caml_option.valFromOption(hookFiredAt);
        hookFiredAt$1 = CS_Make$Util.Dateable.Plain.isBefore(hookFiredAt$2, versionTimestamp) || Caml_obj.equal(hookFiredAt$2, versionTimestamp) ? Caml_option.some(hookFiredAt$2) : undefined;
      } else {
        hookFiredAt$1 = undefined;
      }
      Belt_MapString.has(Belt_Option.getWithDefault(diffStruct.suppressAutoBillingLines, undefined), toSuppressAuto);
      var versionSuppressions$1;
      if (hookFiredAt$1 !== undefined) {
        var str = CS_Slugs$Util.FareRuleSlug.toString(fareRuleSlug) + "-" + CS_Make$Util.Dateable.Plain.toPrimitive(Caml_option.valFromOption(hookFiredAt$1));
        var count = Belt_MapString.get(versionSuppressions, str);
        versionSuppressions$1 = count !== undefined ? count : 0;
      } else {
        versionSuppressions$1 = 0;
      }
      autoLine = {
        TAG: "Toggle",
        _0: {
          fareRuleSlug: fareRuleSlug,
          hookFiredAt: hookFiredAt$1,
          nSuppressed: bl.suppressedCount === 1 ? 0 : 1,
          versionSuppressions: versionSuppressions$1
        }
      };
    } else {
      var hookFiredAt$3 = bl.hookFiredAt;
      var hookFiredAt$4;
      if (hookFiredAt$3 !== undefined) {
        var hookFiredAt$5 = Caml_option.valFromOption(hookFiredAt$3);
        hookFiredAt$4 = CS_Make$Util.Dateable.Plain.isBefore(hookFiredAt$5, versionTimestamp) || Caml_obj.equal(hookFiredAt$5, versionTimestamp) ? Caml_option.some(hookFiredAt$5) : undefined;
      } else {
        hookFiredAt$4 = undefined;
      }
      Belt_MapString.has(Belt_Option.getWithDefault(diffStruct.suppressAutoBillingLines, undefined), toSuppressAuto);
      var versionSuppressions$2;
      if (hookFiredAt$4 !== undefined) {
        var str$1 = CS_Slugs$Util.FareRuleSlug.toString(fareRuleSlug) + "-" + CS_Make$Util.Dateable.Plain.toPrimitive(Caml_option.valFromOption(hookFiredAt$4));
        var count$1 = Belt_MapString.get(versionSuppressions, str$1);
        versionSuppressions$2 = count$1 !== undefined ? count$1 : 0;
      } else {
        versionSuppressions$2 = 0;
      }
      autoLine = {
        TAG: "Select",
        _0: {
          fareRuleSlug: fareRuleSlug,
          hookFiredAt: hookFiredAt$4,
          currentSuppressed: bl.suppressedCount,
          currentApplied: bl.ruleApplicationCount,
          versionSuppressions: versionSuppressions$2
        }
      };
    }
  }
  if (!shouldRender) {
    if (shouldRender) {
      return JsxRuntime.jsx("td", {});
    } else {
      return null;
    }
  }
  if (isManual) {
    var suppressManualLineOnClick = function (param) {
      setSuppressError(function (param) {
            
          });
      if (toSuppressManual === undefined) {
        return setSuppressError(function (param) {
                    return "Error suppressing manual billing line";
                  });
      }
      var applyDiff = BookingDiff$Thick.Alter.suppressManualBillingLine(diffStruct, toSuppressManual);
      DraftUtils$Thick.addToDraft(applyDiff, versionUrl);
      setConfirmModalOpen(function (param) {
            return false;
          });
    };
    return JsxRuntime.jsxs("td", {
                children: [
                  JsxRuntime.jsx(Stack, {
                        direction: "row",
                        justifyContent: "flex-end",
                        children: Caml_option.some(JsxRuntime.jsx(Tooltip, {
                                  children: JsxRuntime.jsx(IconButton, {
                                        onClick: (function (param) {
                                            setConfirmModalOpen(function (param) {
                                                  return true;
                                                });
                                          }),
                                        color: "danger",
                                        children: Caml_option.some(JsxRuntime.jsx(DeleteForever, {
                                                  fontSize: "xl"
                                                }))
                                      }),
                                  arrow: true,
                                  placement: "top",
                                  size: "sm",
                                  title: "Delete"
                                }))
                      }),
                  JsxRuntime.jsxs(Dialog$Thick.make, {
                        open_: match$1[0],
                        onClose: (function () {
                            setConfirmModalOpen(function (param) {
                                  return false;
                                });
                          }),
                        title: "Delete Manual Billing Line",
                        children: [
                          JsxRuntime.jsx(DialogContent, {
                                children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                          spacing: 2,
                                          children: [
                                            JsxRuntime.jsx(Typography, {
                                                  textColor: "text.tertiary",
                                                  children: Caml_option.some("Are you sure you want to delete the manual billing line " + bl.billingLineDescription + " for " + Common$Thick.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(bl.lineAmount)) + " in this draft? To recover, you may click the back button in your browser or discard your draft.")
                                                }),
                                            suppressError !== undefined ? JsxRuntime.jsx(AppAlert$Thick.make, {
                                                    message: suppressError,
                                                    type_: "error"
                                                  }) : null
                                          ]
                                        }))
                              }),
                          JsxRuntime.jsx(DialogActions, {
                                children: Caml_option.some(JsxRuntime.jsx(Button, {
                                          onClick: (function (param) {
                                              suppressManualLineOnClick();
                                            }),
                                          color: "danger",
                                          children: "Delete"
                                        }))
                              })
                        ]
                      })
                ]
              });
  }
  if (typeof autoLine !== "object") {
    if (shouldRender) {
      return JsxRuntime.jsx("td", {});
    } else {
      return null;
    }
  }
  if (autoLine.TAG === "Toggle") {
    var match$6 = autoLine._0;
    var versionSuppressions$3 = match$6.versionSuppressions;
    var nSuppressed = match$6.nSuppressed;
    var hookFiredAt$6 = match$6.hookFiredAt;
    var fareRuleSlug$1 = match$6.fareRuleSlug;
    return JsxRuntime.jsx("td", {
                children: JsxRuntime.jsx(Stack, {
                      direction: "row",
                      justifyContent: "flex-end",
                      children: Caml_option.some(JsxRuntime.jsx(Tooltip, {
                                children: JsxRuntime.jsx(Switch, {
                                      checked: nSuppressed !== 0,
                                      size: "sm",
                                      onChange: (function (param) {
                                          var applyDiff = BookingDiff$Thick.Alter.suppressAutoBillingLine(diffStruct, {
                                                fareRuleSlug: fareRuleSlug$1,
                                                hookFiredAt: hookFiredAt$6,
                                                nSuppressed: nSuppressed
                                              }, toSuppressAuto, versionSuppressions$3);
                                          DraftUtils$Thick.addToDraft(applyDiff, versionUrl);
                                        })
                                    }),
                                arrow: true,
                                placement: "top",
                                size: "sm",
                                title: Caml_option.some(nSuppressed !== 1 ? "Include" : "Omit")
                              }))
                    })
              });
  }
  var match$7 = autoLine._0;
  return JsxRuntime.jsxs("td", {
              children: [
                JsxRuntime.jsx(Stack, {
                      direction: "row",
                      justifyContent: "flex-end",
                      children: Caml_option.some(JsxRuntime.jsx(Tooltip, {
                                children: JsxRuntime.jsx(IconButton, {
                                      onClick: (function (param) {
                                          setModalOpen(function (param) {
                                                return true;
                                              });
                                        }),
                                      children: Caml_option.some(JsxRuntime.jsx(ModeEditOutlined, {
                                                fontSize: "xl"
                                              }))
                                    }),
                                arrow: true,
                                placement: "top",
                                size: "sm",
                                title: "Edit"
                              }))
                    }),
                JsxRuntime.jsx(SuppressAutoBillingLineModal$Thick.make, {
                      open_: match$2[0],
                      onClose: (function () {
                          setModalOpen(function (param) {
                                return false;
                              });
                        }),
                      billPayCurrent: props.billPayRec,
                      versionUrl: versionUrl,
                      passthruId: toSuppressAuto,
                      billingLineDescription: bl.billingLineDescription,
                      generatePreviewFromDiff: props.generatePreviewFromDiff,
                      fareRuleSlug: match$7.fareRuleSlug,
                      hookFiredAt: match$7.hookFiredAt,
                      currentApplied: match$7.currentApplied,
                      currentSuppressed: match$7.currentSuppressed,
                      versionSuppressions: match$7.versionSuppressions
                    })
              ]
            });
}

var BillingActions = {
  make: BDInvoiceDetails$TableLine$BillingActions
};

function BDInvoiceDetails$TableLine(props) {
  var isCanceled = props.isCanceled;
  var moveSelectedPayment = props.moveSelectedPayment;
  var openRefundModal = props.openRefundModal;
  var shouldRender = props.shouldRender;
  var line = props.line;
  if (line.TAG === "Billing") {
    var bl = line._0;
    var shouldStrikeThru = !bl.isManualLine && bl.ruleApplicationCount === 0 && Belt_Option.isSome(bl.fareRuleSlug);
    var match = bl.suppressedCount > 0;
    var match$1 = bl.ruleApplicationCount > 0;
    var displayedAmount;
    var exit = 0;
    if (match && match$1) {
      displayedAmount = JsxRuntime.jsxs(Stack, {
            direction: "row",
            spacing: 1,
            children: [
              JsxRuntime.jsx(Joy$Util.TextOverflow.make, {
                    value: Common$Thick.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(bl.unsuppressedAmount)),
                    level: "inherit",
                    fontWeight: "inherit",
                    fontStyle: "italic",
                    nonVariantColor: "neutral.500",
                    textDecoration: "line-through"
                  }),
              JsxRuntime.jsx(Joy$Util.TextOverflow.make, {
                    value: Common$Thick.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(bl.lineAmount)),
                    level: "inherit",
                    color: "inherit",
                    fontWeight: "inherit"
                  })
            ]
          });
    } else {
      exit = 1;
    }
    if (exit === 1) {
      displayedAmount = JsxRuntime.jsx(Joy$Util.TextOverflow.make, {
            value: shouldStrikeThru ? Common$Thick.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(bl.unsuppressedAmount)) : Common$Thick.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(bl.lineAmount)),
            level: "inherit",
            fontWeight: "inherit",
            fontStyle: CS_Decimal$Util.Decimal10_2.toFloat(bl.lineAmount) <= 0.0 ? "italic" : "normal",
            nonVariantColor: shouldStrikeThru ? "neutral.500" : "inherit",
            textDecoration: shouldStrikeThru ? "line-through" : "none"
          });
    }
    return JsxRuntime.jsxs("tr", {
                children: [
                  JsxRuntime.jsx("td", {
                        children: JsxRuntime.jsx(Joy$Util.TextOverflow.make, {
                              value: shouldStrikeThru ? Belt_Option.getWithDefault(bl.unsuppressedDescription, bl.billingLineDescription) : bl.billingLineDescription,
                              level: "inherit",
                              fontWeight: "inherit",
                              fontStyle: CS_Decimal$Util.Decimal10_2.toFloat(bl.lineAmount) <= 0.0 ? "italic" : "normal",
                              nonVariantColor: shouldStrikeThru ? "neutral.500" : "inherit",
                              textDecoration: shouldStrikeThru ? "line-through" : "none"
                            })
                      }),
                  JsxRuntime.jsx("td", {
                        children: JsxRuntime.jsx(Stack, {
                              direction: "row",
                              justifyContent: "flex-end",
                              sx: {
                                width: "100%"
                              },
                              children: Caml_option.some(displayedAmount)
                            })
                      }),
                  JsxRuntime.jsx(BDInvoiceDetails$TableLine$BillingActions, {
                        shouldRender: shouldRender,
                        generatePreviewFromDiff: props.generatePreviewFromDiff,
                        billPayRec: props.billPayRec,
                        bl: bl,
                        versionTimestamp: props.versionTimestamp,
                        versionSuppressions: props.versionSuppressions,
                        versionUrl: props.versionUrl
                      })
                ]
              });
  }
  var pl = line._0;
  var url = pl.paymentMethodImage;
  var tmp;
  if (shouldRender || isCanceled) {
    var match$2 = pl.processor;
    var match$3 = pl.processorTransactionId;
    var tmp$1;
    tmp$1 = match$2 !== undefined && (match$2 === "INCREASE_COM" || match$2 === "BRAINTREEPAYMENTS_COM") && match$2 === "INCREASE_COM" && match$3 !== undefined ? JsxRuntime.jsx(Stack, {
            direction: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            spacing: 1,
            children: Caml_option.some(JsxRuntime.jsx(Joy$Util.Link.make, {
                      children: JsxRuntime.jsx(Tooltip, {
                            children: JsxRuntime.jsx(OpenInNew, {
                                  fontSize: "md"
                                }),
                            arrow: true,
                            size: "sm",
                            title: "View transaction on increase.com"
                          }),
                      href: "https://dashboard.increase.com/transactions/" + CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(match$3)),
                      target: "_blank"
                    }))
          }) : null;
    var match$4 = Caml_obj.equal(pl.pmtMethod, "CREDIT_CARD");
    var match$5 = CS_Decimal$Util.Decimal10_2.toFloat(pl.amount) > 0.0;
    var match$6 = CS_Decimal$Util.Decimal10_2.toFloat(pl.refundableAmt) > 0.0 || CS_Decimal$Util.Decimal10_2.toFloat(pl.refundableFeeAmt) > 0.0;
    tmp = JsxRuntime.jsx("td", {
          children: JsxRuntime.jsxs(Stack, {
                direction: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                spacing: 1,
                children: [
                  tmp$1,
                  JsxRuntime.jsxs(Dropdown, {
                        children: [
                          JsxRuntime.jsx(MenuButton, {
                                children: Caml_option.some(JsxRuntime.jsx(MoreHoriz, {})),
                                size: "md",
                                slotProps: {
                                  root: {
                                    color: "neutral"
                                  }
                                },
                                slots: {
                                  root: IconButton
                                }
                              }),
                          JsxRuntime.jsxs(Menu, {
                                children: [
                                  openRefundModal !== undefined && match$4 && match$5 && match$6 ? JsxRuntime.jsx(MenuItem, {
                                          children: "Log refund",
                                          onClick: (function (param) {
                                              openRefundModal();
                                            })
                                        }) : null,
                                  moveSelectedPayment !== undefined && !isCanceled ? JsxRuntime.jsx(MenuItem, {
                                          children: "Move payment",
                                          onClick: (function (param) {
                                              moveSelectedPayment(pl);
                                            })
                                        }) : null
                                ],
                                size: "sm",
                                sx: {
                                  zIndex: "modal"
                                }
                              })
                        ]
                      })
                ]
              })
        });
  } else {
    var match$7 = pl.processor;
    var match$8 = pl.processorTransactionId;
    tmp = match$7 !== undefined && (match$7 === "INCREASE_COM" || match$7 === "BRAINTREEPAYMENTS_COM") && match$7 === "INCREASE_COM" && match$8 !== undefined ? JsxRuntime.jsx(Stack, {
            direction: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            spacing: 1,
            children: Caml_option.some(JsxRuntime.jsx(Joy$Util.Link.make, {
                      children: JsxRuntime.jsx(Tooltip, {
                            children: JsxRuntime.jsx(OpenInNew, {
                                  fontSize: "md"
                                }),
                            arrow: true,
                            size: "sm",
                            title: "View transaction on increase.com"
                          }),
                      href: "https://dashboard.increase.com/transactions/" + CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(match$8)),
                      target: "_blank"
                    }))
          }) : null;
  }
  return JsxRuntime.jsxs("tr", {
              children: [
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Joy$Util.TextOverflow.make, {
                            value: pl.shouldRenderStatus ? Common$Thick.PmtLifecycle.toString(pl.lifecycleStatus) : pl.date,
                            level: "inherit",
                            color: "inherit",
                            fontWeight: "inherit",
                            fontStyle: CS_Decimal$Util.Decimal10_2.toFloat(pl.amount) <= 0.0 ? "italic" : "normal"
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Joy$Util.TextOverflow.make, {
                            value: pl.payer,
                            color: "inherit",
                            fontWeight: "inherit",
                            fontStyle: CS_Decimal$Util.Decimal10_2.toFloat(pl.amount) <= 0.0 ? "italic" : "normal"
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsxs(Stack, {
                            direction: "row",
                            alignItems: "center",
                            children: [
                              url !== undefined ? JsxRuntime.jsx(Stack, {
                                      direction: "column",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      sx: {
                                        width: "1.5rem",
                                        height: "1.5rem",
                                        pr: "4px"
                                      },
                                      children: Caml_option.some(JsxRuntime.jsx("img", {
                                                style: {
                                                  width: "100%"
                                                },
                                                alt: "payment method",
                                                src: url
                                              }))
                                    }) : null,
                              JsxRuntime.jsxs(Stack, {
                                    direction: "column",
                                    children: [
                                      JsxRuntime.jsx(Joy$Util.TextOverflow.make, {
                                            value: pl.paymentMethod,
                                            level: "inherit",
                                            color: "inherit",
                                            fontWeight: "inherit",
                                            fontStyle: CS_Decimal$Util.Decimal10_2.toFloat(pl.amount) <= 0.0 ? "italic" : "normal"
                                          }),
                                      CS_Decimal$Util.Decimal10_2.toFloat(pl.bfFeeAmount) > 0.00 ? JsxRuntime.jsx(Typography, {
                                              level: "body-xs",
                                              noWrap: true,
                                              textColor: "neutral.500",
                                              fontWeight: "inherit",
                                              children: "Fee incurred"
                                            }) : null
                                    ]
                                  })
                            ]
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsxs(Stack, {
                            direction: "row",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            sx: {
                              width: "100%"
                            },
                            children: [
                              JsxRuntime.jsxs(Stack, {
                                    direction: "column",
                                    alignItems: "flex-end",
                                    children: [
                                      JsxRuntime.jsx(Joy$Util.TextOverflow.make, {
                                            value: Common$Thick.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(pl.amount)),
                                            level: "inherit",
                                            color: "inherit",
                                            fontWeight: "inherit",
                                            fontStyle: CS_Decimal$Util.Decimal10_2.toFloat(pl.amount) <= 0.0 ? "italic" : "normal"
                                          }),
                                      CS_Decimal$Util.Decimal10_2.toFloat(pl.bfFeeAmount) > 0.00 ? JsxRuntime.jsx(Joy$Util.TextOverflow.make, {
                                              value: Common$Thick.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(pl.bfFeeAmount)),
                                              startDecorator: Caml_option.some(JsxRuntime.jsx(Tooltip, {
                                                        children: JsxRuntime.jsx(IconButton, {
                                                              sx: {
                                                                minHeight: "0",
                                                                minWidth: "0",
                                                                "&:hover": {
                                                                  background: "none"
                                                                }
                                                              },
                                                              children: Caml_option.some(JsxRuntime.jsx(InfoOutlined, {
                                                                        fontSize: "xs"
                                                                      }))
                                                            }),
                                                        sx: {
                                                          maxWidth: "250px"
                                                        },
                                                        title: "Total does not include this additional payment processor fee."
                                                      })),
                                              level: "body-xs",
                                              fontWeight: "inherit",
                                              nonVariantColor: "neutral.500"
                                            }) : null
                                    ]
                                  }),
                              Caml_obj.equal(pl.pmtMethod, "CREDIT_CARD") && Caml_obj.notequal(pl.refundableAmt, pl.amount) ? (
                                  CS_Decimal$Util.Decimal10_2.toFloat(pl.amount) > 0.0 ? (
                                      CS_Decimal$Util.Decimal10_2.toFloat(pl.refundableAmt) > 0.0 ? JsxRuntime.jsx(Tooltip, {
                                              children: JsxRuntime.jsx(Replay, {}),
                                              arrow: true,
                                              placement: "top",
                                              size: "sm",
                                              sx: {
                                                justifySelf: "flex-start",
                                                cursor: "pointer"
                                              },
                                              title: "Partially refunded payment"
                                            }) : JsxRuntime.jsx(Tooltip, {
                                              children: JsxRuntime.jsx(ReplayCircleFilled, {}),
                                              arrow: true,
                                              placement: "top",
                                              size: "sm",
                                              sx: {
                                                justifySelf: "flex-start",
                                                cursor: "pointer"
                                              },
                                              title: "Fully refunded payment"
                                            })
                                    ) : JsxRuntime.jsx(Tooltip, {
                                          children: JsxRuntime.jsx(KeyboardReturn, {}),
                                          arrow: true,
                                          placement: "top",
                                          size: "sm",
                                          sx: {
                                            justifySelf: "flex-start",
                                            cursor: "pointer"
                                          },
                                          title: "Credit card refund"
                                        })
                                ) : null
                            ]
                          })
                    }),
                tmp
              ]
            });
}

var TableLine = {
  BillingActions: BillingActions,
  make: BDInvoiceDetails$TableLine
};

function BDInvoiceDetails$InvoiceDetails(props) {
  var openRecordWireModal = props.openRecordWireModal;
  var openRecordP2PModal = props.openRecordP2PModal;
  var openRecordFccModal = props.openRecordFccModal;
  var openRecordCheckModal = props.openRecordCheckModal;
  var refetchBilling = props.refetchBilling;
  var brandFamily = props.brandFamily;
  var currentUserEmail = props.currentUserEmail;
  var versionSuppressions = props.versionSuppressions;
  var versionTimestamp = props.versionTimestamp;
  var generatePreviewFromDiff = props.generatePreviewFromDiff;
  var versionUrl = props.versionUrl;
  var canManage = props.canManage;
  var snapshot = props.snapshot;
  var isCanceled = props.isCanceled;
  var isActive = props.isActive;
  var content = props.content;
  var shouldRender = isActive && !snapshot && canManage;
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var diffStruct = BookingDiff$Thick.parse(url.search);
  var lines = diffStruct.addManualBillingLines;
  var passthruId = "add-" + (
    lines !== undefined ? String(Belt_MapString.size(Caml_option.valFromOption(lines))) : "0"
  );
  var match = React.useState(function () {
        return false;
      });
  var setModalOpen = match[1];
  var match$1 = React.useState(function () {
        return false;
      });
  var setPCModalOpen = match$1[1];
  var match$2 = React.useState(function () {
        
      });
  var setSelectedPaymentLine = match$2[1];
  var selectedPaymentLine = match$2[0];
  var match$3 = React.useState(function () {
        
      });
  var setSelectedPmtToMove = match$3[1];
  var match$4 = React.useState(function () {
        return false;
      });
  var setMovePaymentModalOpen = match$4[1];
  var match$5 = React.useState(function () {
        return false;
      });
  var setRefundModalOpen = match$5[1];
  var moveSelectedPayment = function (pl) {
    setSelectedPaymentLine(function (param) {
          return pl;
        });
    setSelectedPmtToMove(function (param) {
          return Caml_option.some(pl.publicPmtId);
        });
    setMovePaymentModalOpen(function (param) {
          return true;
        });
  };
  var billingColumns = [
    {
      heading: "Description",
      width: "8rem"
    },
    {
      heading: "Amount",
      width: "5rem"
    },
    {
      heading: "Actions",
      width: "1rem"
    }
  ];
  var paymentColumns = [
    {
      heading: "Date",
      width: "3rem"
    },
    {
      heading: "Payer",
      width: "3rem"
    },
    {
      heading: "Payment Method",
      width: "4rem"
    },
    {
      heading: "Amount",
      width: "3rem"
    },
    {
      heading: "Actions",
      width: "2rem"
    }
  ];
  var match$6 = shouldRender ? [
      billingColumns,
      paymentColumns
    ] : [
      Belt_Array.keep(billingColumns, (function (column) {
              return column.heading !== "Actions";
            })),
      Belt_Array.keep(paymentColumns, (function (column) {
              if (isCanceled) {
                return true;
              } else {
                return column.heading !== "Actions";
              }
            }))
    ];
  var zeroed = CS_Decimal$Util.Decimal10_2.Exn.fromPrimitive("0.00");
  return JsxRuntime.jsxs(Stack, {
              direction: "column",
              spacing: 4,
              children: [
                JsxRuntime.jsxs(Stack, {
                      children: [
                        JsxRuntime.jsxs(Stack, {
                              direction: "row",
                              justifyContent: "space-between",
                              children: [
                                JsxRuntime.jsx(Typography, {
                                      level: "h4",
                                      component: "h4",
                                      children: "Booking Charges"
                                    }),
                                shouldRender ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                        children: [
                                          JsxRuntime.jsx(BDButton$Thick.make, {
                                                endDecorator: Caml_option.some(JsxRuntime.jsx(Add, {
                                                          fontSize: "sm",
                                                          sx: {
                                                            color: "text.secondary"
                                                          }
                                                        })),
                                                onClick: (function (param) {
                                                    setModalOpen(function (param) {
                                                          return true;
                                                        });
                                                  }),
                                                text: "Add new charge"
                                              }),
                                          JsxRuntime.jsx(AddManualBillingLineModal$Thick.make, {
                                                open_: match[0],
                                                onClose: (function () {
                                                    setModalOpen(function (param) {
                                                          return false;
                                                        });
                                                  }),
                                                billPayCurrent: content,
                                                versionUrl: versionUrl,
                                                passthruId: passthruId,
                                                generatePreviewFromDiff: generatePreviewFromDiff
                                              })
                                        ]
                                      }) : null,
                                isCanceled && canManage ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                        children: [
                                          JsxRuntime.jsx(BDButton$Thick.make, {
                                                endDecorator: Caml_option.some(JsxRuntime.jsx(Add, {
                                                          fontSize: "sm",
                                                          sx: {
                                                            color: "text.secondary"
                                                          }
                                                        })),
                                                onClick: (function (param) {
                                                    setPCModalOpen(function (param) {
                                                          return true;
                                                        });
                                                  }),
                                                text: "Add new charge"
                                              }),
                                          JsxRuntime.jsx(BDRecordPostCancellationBillingModal$Thick.make, {
                                                open_: match$1[0],
                                                onClose: (function () {
                                                    setPCModalOpen(function (param) {
                                                          return false;
                                                        });
                                                  }),
                                                brandFamily: brandFamily,
                                                refetchBilling: refetchBilling,
                                                generatePreviewFromDiff: generatePreviewFromDiff
                                              })
                                        ]
                                      }) : null
                              ]
                            }),
                        content.billing.billingLines.length !== 0 ? JsxRuntime.jsx(BDBillPayTable$Thick.make, {
                                columns: match$6[0],
                                totalDescription: "Total Billed",
                                total: content.billing.billingLineSubtotal,
                                pendingDescription: "Pending Billed",
                                pendingTotal: zeroed,
                                isActive: isActive,
                                snapshot: snapshot,
                                canManage: canManage,
                                children: Belt_Array.mapWithIndex(content.billing.billingLines, (function (i, line) {
                                        return JsxRuntime.jsx(BDInvoiceDetails$TableLine, {
                                                    line: {
                                                      TAG: "Billing",
                                                      _0: line
                                                    },
                                                    shouldRender: shouldRender,
                                                    billPayRec: content,
                                                    versionUrl: versionUrl,
                                                    generatePreviewFromDiff: generatePreviewFromDiff,
                                                    versionTimestamp: versionTimestamp,
                                                    versionSuppressions: versionSuppressions,
                                                    isCanceled: isCanceled
                                                  }, String(i));
                                      }))
                              }) : JsxRuntime.jsx(Stack, {
                                direction: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                sx: {
                                  height: "7rem"
                                },
                                children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                          level: "title-lg",
                                          component: "p",
                                          textColor: "neutral.500",
                                          children: Caml_option.some(isActive && !snapshot ? "No charges have been added yet." : "No charges added.")
                                        }))
                              })
                      ]
                    }),
                JsxRuntime.jsxs(Stack, {
                      children: [
                        JsxRuntime.jsxs(Stack, {
                              direction: "row",
                              justifyContent: "space-between",
                              children: [
                                JsxRuntime.jsx(Typography, {
                                      level: "h4",
                                      component: "h4",
                                      children: "Payments"
                                    }),
                                currentUserEmail !== undefined ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                        children: [
                                          JsxRuntime.jsxs(Stack, {
                                                direction: "row",
                                                spacing: 1,
                                                children: [
                                                  JsxRuntime.jsxs(Dropdown, {
                                                        children: [
                                                          JsxRuntime.jsx(MenuButton, {
                                                                children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                                          level: "body-sm",
                                                                          textColor: "inherit",
                                                                          children: "Log Manual Payment"
                                                                        })),
                                                                color: "neutral",
                                                                endDecorator: Caml_option.some(JsxRuntime.jsx(ArrowDropDown, {})),
                                                                size: "sm",
                                                                variant: "outlined",
                                                                sx: {
                                                                  fontWeight: "400",
                                                                  boxShadow: "1px 1px 2px 0px #E5E5E5",
                                                                  border: "1px solid",
                                                                  borderColor: "#100D0C4D",
                                                                  borderRadius: "2px",
                                                                  backgroundColor: "background.surface",
                                                                  display: "flex",
                                                                  justifyContent: "center",
                                                                  alignItems: "center",
                                                                  paddingInline: "0.5rem",
                                                                  opacity: "1",
                                                                  "&:hover": {
                                                                    backgroundColor: "#E5E5E5"
                                                                  },
                                                                  "& .MuiButton-endDecorator": {
                                                                    marginLeft: "0rem"
                                                                  }
                                                                }
                                                              }),
                                                          JsxRuntime.jsxs(Menu, {
                                                                children: [
                                                                  JsxRuntime.jsx(MenuItem, {
                                                                        children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                                                  level: "body-sm",
                                                                                  children: "Log check payment"
                                                                                })),
                                                                        disabled: Belt_Option.isNone(openRecordCheckModal),
                                                                        onClick: (function (param) {
                                                                            if (openRecordCheckModal !== undefined) {
                                                                              return openRecordCheckModal();
                                                                            }
                                                                            
                                                                          })
                                                                      }, "Log check payment"),
                                                                  JsxRuntime.jsx(MenuItem, {
                                                                        children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                                                  level: "body-sm",
                                                                                  children: "Log FCC payment"
                                                                                })),
                                                                        disabled: Belt_Option.isNone(openRecordFccModal),
                                                                        onClick: (function (param) {
                                                                            if (openRecordFccModal !== undefined) {
                                                                              return openRecordFccModal();
                                                                            }
                                                                            
                                                                          })
                                                                      }, "Log FCC payment"),
                                                                  JsxRuntime.jsx(MenuItem, {
                                                                        children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                                                  level: "body-sm",
                                                                                  children: "Log peer-to-peer payment (e.g. Wise)"
                                                                                })),
                                                                        disabled: Belt_Option.isNone(openRecordP2PModal),
                                                                        onClick: (function (param) {
                                                                            if (openRecordP2PModal !== undefined) {
                                                                              return openRecordP2PModal();
                                                                            }
                                                                            
                                                                          })
                                                                      }, "Log p2p payment"),
                                                                  JsxRuntime.jsx(MenuItem, {
                                                                        children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                                                  level: "body-sm",
                                                                                  children: "Log incoming wire payment"
                                                                                })),
                                                                        disabled: Belt_Option.isNone(openRecordWireModal),
                                                                        onClick: (function (param) {
                                                                            if (openRecordWireModal !== undefined) {
                                                                              return openRecordWireModal();
                                                                            }
                                                                            
                                                                          })
                                                                      }, "Log wire payment")
                                                                ],
                                                                placement: "bottom-end",
                                                                sx: {
                                                                  zIndex: "modal"
                                                                }
                                                              })
                                                        ]
                                                      }),
                                                  JsxRuntime.jsx(BDButton$Thick.make, {
                                                        endDecorator: Caml_option.some(JsxRuntime.jsx(Add, {
                                                                  fontSize: "sm",
                                                                  sx: {
                                                                    color: "text.secondary"
                                                                  }
                                                                })),
                                                        onClick: (function (param) {
                                                            setRefundModalOpen(function (param) {
                                                                  return true;
                                                                });
                                                          }),
                                                        text: "Log refund"
                                                      })
                                                ]
                                              }),
                                          JsxRuntime.jsx(BDRefundModal$Thick.make, {
                                                open_: match$5[0],
                                                onClose: (function () {
                                                    setRefundModalOpen(function (param) {
                                                          return false;
                                                        });
                                                    setSelectedPaymentLine(function (param) {
                                                          
                                                        });
                                                  }),
                                                currentUserEmail: Caml_option.valFromOption(currentUserEmail),
                                                prefill: selectedPaymentLine,
                                                clearPrefill: (function () {
                                                    setSelectedPaymentLine(function (param) {
                                                          
                                                        });
                                                  }),
                                                brandFamily: brandFamily,
                                                billPayCurrent: content,
                                                versionUrl: versionUrl,
                                                generatePreviewFromDiff: generatePreviewFromDiff,
                                                isCanceled: isCanceled,
                                                refetchBilling: refetchBilling
                                              })
                                        ]
                                      }) : null
                              ]
                            }),
                        content.payment.paymentLines.length !== 0 ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                children: [
                                  JsxRuntime.jsx(BDBillPayTable$Thick.make, {
                                        columns: match$6[1],
                                        totalDescription: "Total Paid",
                                        total: content.payment.completedPaymentSubtotal,
                                        pendingDescription: "Pending",
                                        pendingTotal: content.arePendingPayments ? content.payment.pendingPaymentSubtotal : zeroed,
                                        isActive: isActive,
                                        snapshot: snapshot,
                                        canManage: canManage,
                                        children: Belt_Array.mapWithIndex(content.payment.paymentLines, (function (i, line) {
                                                return JsxRuntime.jsx(BDInvoiceDetails$TableLine, {
                                                            line: {
                                                              TAG: "Payment",
                                                              _0: line
                                                            },
                                                            shouldRender: shouldRender,
                                                            billPayRec: content,
                                                            versionUrl: versionUrl,
                                                            generatePreviewFromDiff: generatePreviewFromDiff,
                                                            versionTimestamp: versionTimestamp,
                                                            versionSuppressions: versionSuppressions,
                                                            openRefundModal: (function () {
                                                                setSelectedPaymentLine(function (param) {
                                                                      return line;
                                                                    });
                                                                setRefundModalOpen(function (param) {
                                                                      return true;
                                                                    });
                                                              }),
                                                            moveSelectedPayment: moveSelectedPayment,
                                                            isCanceled: isCanceled
                                                          }, String(i));
                                              })),
                                        showCanceledActions: isCanceled
                                      }),
                                  JsxRuntime.jsx(BDMovePaymentModal$Thick.make, {
                                        open_: match$4[0],
                                        onClose: (function () {
                                            setMovePaymentModalOpen(function (param) {
                                                  return false;
                                                });
                                          }),
                                        pmtToMove: match$3[0],
                                        pmtLine: selectedPaymentLine,
                                        brandFamily: brandFamily,
                                        billPayCurrent: content,
                                        versionUrl: versionUrl,
                                        generatePreviewFromDiff: generatePreviewFromDiff
                                      })
                                ]
                              }) : JsxRuntime.jsx(Stack, {
                                direction: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                sx: {
                                  height: "7rem"
                                },
                                children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                          level: "title-lg",
                                          component: "p",
                                          textColor: "neutral.500",
                                          children: Caml_option.some(isActive && !snapshot ? "No payments have been made yet." : "No payments made.")
                                        }))
                              }),
                        JsxRuntime.jsx(Sheet, {
                              sx: {
                                border: "1px solid",
                                borderColor: "divider",
                                borderRadius: "4px",
                                p: 1,
                                my: 2
                              },
                              children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                        direction: "row",
                                        justifyContent: "flex-start",
                                        children: [
                                          JsxRuntime.jsx(Check, {
                                                sx: {
                                                  color: "#037E3A",
                                                  pr: 1
                                                }
                                              }),
                                          JsxRuntime.jsxs(Stack, {
                                                direction: "column",
                                                children: [
                                                  JsxRuntime.jsx(Typography, {
                                                        level: "title-md",
                                                        textColor: "#037E3A",
                                                        children: "Remaining Unpaid Balance"
                                                      }),
                                                  JsxRuntime.jsx(Typography, {
                                                        level: "title-md",
                                                        textColor: "text.secondary",
                                                        children: Caml_option.some(Common$Thick.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(content.totalAmount)))
                                                      }),
                                                  content.arePendingPayments ? JsxRuntime.jsx(Typography, {
                                                          level: "title-sm",
                                                          sx: {
                                                            fontStyle: "italic"
                                                          },
                                                          textColor: "text.secondary",
                                                          fontWeight: "normal",
                                                          children: Caml_option.some(Common$Thick.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(content.totalAmountIncludingPending)) + " after pending payments")
                                                        }) : null
                                                ]
                                              })
                                        ]
                                      }))
                            })
                      ]
                    })
              ]
            });
}

var InvoiceDetails = {
  make: BDInvoiceDetails$InvoiceDetails
};

function BDInvoiceDetails(props) {
  var billPayRec = props.billPayRec;
  if (billPayRec !== undefined) {
    return JsxRuntime.jsx(BDInvoiceDetails$InvoiceDetails, {
                content: billPayRec,
                isActive: props.isActive,
                isCanceled: props.isCanceled,
                snapshot: props.snapshot,
                canManage: props.canManage,
                versionUrl: props.versionUrl,
                generatePreviewFromDiff: props.generatePreviewFromDiff,
                versionTimestamp: props.versionTimestamp,
                versionSuppressions: props.versionSuppressions,
                currentUserEmail: props.currentUserEmail,
                brandFamily: props.brandFamily,
                refetchBilling: props.refetchBilling,
                openRecordCheckModal: props.openRecordCheckModal,
                openRecordFccModal: props.openRecordFccModal,
                openRecordP2PModal: props.openRecordP2PModal,
                openRecordWireModal: props.openRecordWireModal
              });
  } else {
    return JsxRuntime.jsx(Stack, {
                direction: "column",
                justifyContent: "center",
                alignItems: "center",
                sx: {
                  height: "7rem"
                },
                children: Caml_option.some(JsxRuntime.jsx(Typography, {
                          level: "title-lg",
                          component: "p",
                          textColor: "neutral.500",
                          children: "No billing & payment information available."
                        }))
              });
  }
}

var make = BDInvoiceDetails;

exports.TableLine = TableLine;
exports.InvoiceDetails = InvoiceDetails;
exports.make = make;
/* react Not a pure module */
