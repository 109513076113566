// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");

var defaultContextWhichIsBroken = React.createContext("ERROR");

function use() {
  return React.useContext(defaultContextWhichIsBroken);
}

var make = defaultContextWhichIsBroken.Provider;

var Provider = {
  make: make
};

var Context = {
  defaultContextWhichIsBroken: defaultContextWhichIsBroken,
  use: use,
  Provider: Provider
};

exports.Context = Context;
/* defaultContextWhichIsBroken Not a pure module */
