// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml = require("rescript/lib/js/caml.js");
var React = require("react");
var Belt_Set = require("rescript/lib/js/belt_Set.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_MapInt = require("rescript/lib/js/belt_MapInt.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Box = require("@mui/joy/Box").default;
var Belt_MapDict = require("rescript/lib/js/belt_MapDict.js");
var CS_Make$Util = require("util/src/CustomScalars/CS_Make.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var Stack = require("@mui/joy/Stack").default;
var Table = require("@mui/joy/Table").default;
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var Belt_SetString = require("rescript/lib/js/belt_SetString.js");
var Belt_SortArray = require("rescript/lib/js/belt_SortArray.js");
var Button = require("@mui/joy/Button").default;
var Drawer = require("@mui/joy/Drawer").default;
var BSPInputs$Thick = require("../components/booking-sessions/BSPInputs.bs.js");
var RemoteData$Util = require("util/src/RemoteData.bs.js");
var Divider = require("@mui/joy/Divider").default;
var HopperState$Util = require("util/src/HopperState.bs.js");
var DeckMapUtil$Thick = require("../utils/DeckMapUtil.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var DialogTitle = require("@mui/joy/DialogTitle").default;
var DialogContent = require("@mui/joy/DialogContent").default;
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var Refresh = require("@mui/icons-material/Refresh").default;
var OpenInNew = require("@mui/icons-material/OpenInNew").default;
var SwapHoriz = require("@mui/icons-material/SwapHoriz").default;
var CheckCircle = require("@mui/icons-material/CheckCircle").default;
var WarningAmber = require("@mui/icons-material/WarningAmber").default;

function DeckMapPage$InitJsDeckMap(props) {
  var cabinBgColorPalette = props.cabinBgColorPalette;
  var voyageCabins = props.voyageCabins;
  var state = props.state;
  var willBeLoadedAfter = DeckMapUtil$Thick.useWillBeLoadedAfter(state);
  var match = HopperState$Util.Observable.useComputed(state, (function (model) {
          return [
                  [
                    DeckMapUtil$Thick.deckLeft.getValue(model),
                    DeckMapUtil$Thick.deckRight.getValue(model)
                  ],
                  model.borderColoring,
                  Belt_SetString.toArray(model.hideSegments)
                ];
        }), undefined, undefined);
  var segmentsToHide = match[2];
  var match$1 = React.useState(function () {
        
      });
  var setTimeoutId = match$1[1];
  var timeoutId = match$1[0];
  var match$2 = React.useState(function () {
        return 0.0;
      });
  var setRefreshedAt = match$2[1];
  var blobs = DeckMapUtil$Thick.Interop.genBlobs(voyageCabins, props.cabinPredicates);
  var borderPredicatesMap = DeckMapUtil$Thick.Interop.genBorderPredicatesMap(DeckMapUtil$Thick.borderPallete, match[1]);
  var init = function () {
    DeckMapUtil$Thick.Interop.init(blobs, cabinBgColorPalette, borderPredicatesMap, segmentsToHide, (function (c) {
            HopperState$Util.Observable.notify(state, (function (model) {
                    return {
                            deckLeft: model.deckLeft,
                            deckRight: model.deckRight,
                            loadedDecks: model.loadedDecks,
                            mapsAreLoading: model.mapsAreLoading,
                            url: model.url,
                            hoverCabin: Belt_Option.map(c, (function (param) {
                                    return [
                                            param[0].apiResponse,
                                            param[1]
                                          ];
                                  })),
                            borderColoring: model.borderColoring,
                            hideSegments: model.hideSegments,
                            cabinX: model.cabinX,
                            cabinY: model.cabinY,
                            swapDrawer: model.swapDrawer
                          };
                  }));
          }), [
          HopperState$Util.Observable.asSetter(HopperState$Util.Observable.makeView(state, {
                    read: (function (param) {
                        return param.cabinX;
                      }),
                    write: (function (m, cabinX) {
                        return {
                                deckLeft: m.deckLeft,
                                deckRight: m.deckRight,
                                loadedDecks: m.loadedDecks,
                                mapsAreLoading: m.mapsAreLoading,
                                url: m.url,
                                hoverCabin: m.hoverCabin,
                                borderColoring: m.borderColoring,
                                hideSegments: m.hideSegments,
                                cabinX: cabinX,
                                cabinY: m.cabinY,
                                swapDrawer: m.swapDrawer
                              };
                      })
                  }, undefined)),
          HopperState$Util.Observable.asSetter(HopperState$Util.Observable.makeView(state, {
                    read: (function (param) {
                        return param.cabinY;
                      }),
                    write: (function (m, cabinY) {
                        return {
                                deckLeft: m.deckLeft,
                                deckRight: m.deckRight,
                                loadedDecks: m.loadedDecks,
                                mapsAreLoading: m.mapsAreLoading,
                                url: m.url,
                                hoverCabin: m.hoverCabin,
                                borderColoring: m.borderColoring,
                                hideSegments: m.hideSegments,
                                cabinX: m.cabinX,
                                cabinY: cabinY,
                                swapDrawer: m.swapDrawer
                              };
                      })
                  }, undefined)),
          (function () {
              return Belt_Option.isSome(HopperState$Util.Observable.dangerouslyRead(state).swapDrawer);
            }),
          (function () {
              HopperState$Util.Observable.notify(state, (function (m) {
                      return {
                              deckLeft: m.deckLeft,
                              deckRight: m.deckRight,
                              loadedDecks: m.loadedDecks,
                              mapsAreLoading: m.mapsAreLoading,
                              url: m.url,
                              hoverCabin: m.hoverCabin,
                              borderColoring: m.borderColoring,
                              hideSegments: m.hideSegments,
                              cabinX: m.cabinX,
                              cabinY: m.cabinY,
                              swapDrawer: DeckMapUtil$Thick.blankSwapDrawer
                            };
                    }));
            })
        ]);
  };
  React.useLayoutEffect((function () {
          Belt_Option.getWithDefault(Belt_Option.map(timeoutId, (function (timeout) {
                      clearTimeout(timeout);
                    })), undefined);
          var exit = 0;
          if (willBeLoadedAfter !== undefined && Date.now() > willBeLoadedAfter) {
            HopperState$Util.Observable.notify(state, (function (model) {
                    return {
                            deckLeft: model.deckLeft,
                            deckRight: model.deckRight,
                            loadedDecks: model.loadedDecks,
                            mapsAreLoading: false,
                            url: model.url,
                            hoverCabin: model.hoverCabin,
                            borderColoring: model.borderColoring,
                            hideSegments: model.hideSegments,
                            cabinX: model.cabinX,
                            cabinY: model.cabinY,
                            swapDrawer: model.swapDrawer
                          };
                  }));
            init();
          } else {
            exit = 1;
          }
          if (exit === 1) {
            HopperState$Util.Observable.notify(state, (function (model) {
                    return {
                            deckLeft: model.deckLeft,
                            deckRight: model.deckRight,
                            loadedDecks: model.loadedDecks,
                            mapsAreLoading: true,
                            url: model.url,
                            hoverCabin: model.hoverCabin,
                            borderColoring: model.borderColoring,
                            hideSegments: model.hideSegments,
                            cabinX: model.cabinX,
                            cabinY: model.cabinY,
                            swapDrawer: model.swapDrawer
                          };
                  }));
            setTimeoutId(function (param) {
                  return Caml_option.some(setTimeout((function () {
                                    setRefreshedAt(function (param) {
                                          return Date.now();
                                        });
                                  }), 22));
                });
          }
          
        }), Belt_Array.concat(match[0], [String(match$2[0])]));
  React.useEffect((function () {
          if (HopperState$Util.Observable.dangerouslyRead(state).mapsAreLoading) {
            
          } else {
            DeckMapUtil$Thick.Interop.redraw(blobs, cabinBgColorPalette, borderPredicatesMap, segmentsToHide);
          }
        }), [
        JSON.stringify(voyageCabins),
        JSON.stringify(borderPredicatesMap),
        JSON.stringify(segmentsToHide)
      ]);
  return null;
}

var InitJsDeckMap = {
  make: DeckMapPage$InitJsDeckMap
};

function DeckMapPage$CabinDetailsToolip$Body(props) {
  var match = props.cabin;
  var match$1 = match.booking.nodes;
  var bookingData;
  if (match$1.length !== 1) {
    bookingData = undefined;
  } else {
    var booking = match$1[0];
    var match$2 = booking.bookingVersion.nodes;
    if (match$2.length !== 1) {
      bookingData = undefined;
    } else {
      var bookingVersion = match$2[0];
      bookingData = [
        booking,
        bookingVersion
      ];
    }
  }
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx("h2", {
                      children: CS_Slugs$Util.CabinCategorySlug.toString(match.voyageCategorySlug).toLocaleUpperCase() + " | " + CS_NonemptyStrings$Util.CabinNumber.toString(match.cabinNumber) + " | " + match.voyageSegment + " | " + (
                        match.isBooked ? "booked" : "UNbooked"
                      )
                    }),
                JsxRuntime.jsxs("p", {
                      children: [
                        "Reserved By Booking: ",
                        JsxRuntime.jsx("strong", {
                              children: Belt_Option.mapWithDefault(bookingData, "NOT RESERVED", (function (param) {
                                      return CS_NonemptyStrings$Util.BookingId.toString(param[0].bookingId) + " (version " + String(param[1].bookingVersionNumber) + ")";
                                    }))
                            }),
                        JsxRuntime.jsx("br", {}),
                        "Occupancy: ",
                        JsxRuntime.jsx("strong", {
                              children: "min " + String(match.minBerths) + " / max " + String(match.maxBerths)
                            })
                      ]
                    }),
                JsxRuntime.jsx("h4", {
                      children: "Configuration Note"
                    }),
                JsxRuntime.jsx("pre", {
                      children: Belt_Option.mapWithDefault(match.configurationNoteMd, null, CS_NonemptyStrings$Util.NonEmptyString.toEl),
                      style: {
                        whiteSpace: "pre-wrap"
                      }
                    }),
                JsxRuntime.jsx("h4", {
                      children: "Reservation Note"
                    }),
                JsxRuntime.jsx("pre", {
                      children: Belt_Option.mapWithDefault(match.reservationNoteMd, null, CS_NonemptyStrings$Util.NonEmptyString.toEl),
                      style: {
                        whiteSpace: "pre-wrap"
                      }
                    }),
                Belt_Option.mapWithDefault(bookingData, null, (function (param) {
                        var match = param[1];
                        var preferences = match.preferences;
                        var placementComment = match.placementComment;
                        var numBerths = match.numBerths;
                        var allidxs = Belt_Array.range(1, numBerths);
                        var paxmap = Belt_MapInt.fromArray(Belt_Array.map(match.passenger.nodes, (function (param) {
                                    return [
                                            param.idx,
                                            Belt_Option.getExn(param.bfcustUser).fullName
                                          ];
                                  })));
                        var tmp;
                        if (preferences !== undefined) {
                          var cabinElevatorPref = preferences.cabinElevatorPref;
                          var exit = 0;
                          if (cabinElevatorPref !== undefined || preferences.cabinLongitudePref !== undefined) {
                            exit = 1;
                          } else {
                            tmp = "None";
                          }
                          if (exit === 1) {
                            tmp = Belt_Option.mapWithDefault(preferences.cabinLongitudePref, "", (function (v) {
                                    return v + " ";
                                  })) + Belt_Option.mapWithDefault(cabinElevatorPref, "", (function (v) {
                                    return v;
                                  }));
                          }
                          
                        } else {
                          tmp = "None";
                        }
                        var tmp$1;
                        if (preferences !== undefined) {
                          var cpr = preferences.cabinPlacementRequest;
                          tmp$1 = cpr !== undefined ? CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(cpr)) : "None";
                        } else {
                          tmp$1 = "None";
                        }
                        var tmp$2;
                        if (preferences !== undefined) {
                          var gpr = preferences.generalPref;
                          tmp$2 = gpr !== undefined ? CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(gpr)) : "None";
                        } else {
                          tmp$2 = "None";
                        }
                        return JsxRuntime.jsxs("p", {
                                    children: [
                                      String(numBerths) + " Guests: ",
                                      JsxRuntime.jsx("strong", {
                                            children: Belt_Array.map(allidxs, (function (idx) {
                                                      return Belt_Option.mapWithDefault(Belt_MapInt.get(paxmap, idx), String(idx) + ". TBA", (function (fullName) {
                                                                    return String(idx) + ". " + fullName;
                                                                  }));
                                                    })).join(", ")
                                          }),
                                      JsxRuntime.jsx("br", {}),
                                      "Placement Keywords: ",
                                      JsxRuntime.jsx("strong", {
                                            children: tmp
                                          }),
                                      JsxRuntime.jsx("br", {}),
                                      "Placement Request: ",
                                      JsxRuntime.jsx("strong", {
                                            children: tmp$1
                                          }),
                                      JsxRuntime.jsx("br", {}),
                                      "User Notes: ",
                                      JsxRuntime.jsx("strong", {
                                            children: tmp$2
                                          }),
                                      JsxRuntime.jsx("br", {}),
                                      "Placement Notes: ",
                                      JsxRuntime.jsxs("strong", {
                                            children: [
                                              placementComment !== undefined ? CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(placementComment)) : "None",
                                              match.finalPlacement ? JsxRuntime.jsx("em", {
                                                      children: " ***Placement Finalized***"
                                                    }) : null
                                            ]
                                          })
                                    ]
                                  });
                      }))
              ]
            });
}

var Body = {
  make: DeckMapPage$CabinDetailsToolip$Body
};

function DeckMapPage$CabinDetailsToolip$Actual(props) {
  var match = props.position;
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(DeckMapPage$CabinDetailsToolip$Body, {
                    cabin: props.cabin
                  }),
              style: {
                backgroundColor: "lightgoldenrodyellow",
                border: "1px solid black",
                fontSize: "0.7em",
                left: String(match.left + 33) + "px",
                maxWidth: "250px",
                padding: "8px",
                position: "absolute",
                top: String(match.top - 44) + "px"
              }
            });
}

var Actual = {
  make: DeckMapPage$CabinDetailsToolip$Actual
};

function DeckMapPage$CabinDetailsToolip(props) {
  var hoverCabin = HopperState$Util.Observable.useComputed(props.state, (function (param) {
          return param.hoverCabin;
        }), undefined, undefined);
  return Belt_Option.mapWithDefault(hoverCabin, null, (function (param) {
                return JsxRuntime.jsx(DeckMapPage$CabinDetailsToolip$Actual, {
                            cabin: param[0],
                            position: param[1]
                          });
              }));
}

var CabinDetailsToolip = {
  Body: Body,
  Actual: Actual,
  make: DeckMapPage$CabinDetailsToolip
};

function occsIssue(param, targetBerths, ox, oy) {
  if (targetBerths >= param.minBerths && targetBerths <= param.maxBerths) {
    return ;
  } else {
    return {
            TAG: "Error",
            _0: {
              errorMsg: "Cabin " + CS_NonemptyStrings$Util.CabinNumber.toString(param.cabinNumber) + " cannot accommodate " + String(targetBerths) + " berths",
              errorT: "OccIssue",
              ox: ox,
              oy: oy
            }
          };
  }
}

function bookingNumBerths(c) {
  var match = c.booking.nodes;
  if (match.length !== 1) {
    return ;
  }
  var match$1 = match[0];
  var match$2 = match$1.bookingVersion.nodes;
  if (match$2.length !== 1) {
    return ;
  }
  var match$3 = match$2[0];
  if (c.isBooked) {
    return match$3.numBerths;
  }
  
}

function checkCabinNumbers(voyageCabinsMap, ox, oy) {
  var ox$1 = Belt_Option.flatMap(ox, (function (n) {
          return Belt_MapDict.get(voyageCabinsMap, n, CS_NonemptyStrings$Util.CabinNumber.Comparable.cmp);
        }));
  var oy$1 = Belt_Option.flatMap(oy, (function (n) {
          return Belt_MapDict.get(voyageCabinsMap, n, CS_NonemptyStrings$Util.CabinNumber.Comparable.cmp);
        }));
  var swapcmp;
  if (ox$1 !== undefined && oy$1 !== undefined) {
    if (Caml_obj.equal(ox$1, oy$1)) {
      swapcmp = {
        TAG: "Error",
        _0: {
          errorMsg: "You've selected cabin " + CS_NonemptyStrings$Util.CabinNumber.toString(ox$1.cabinNumber) + " twice",
          errorT: "CabinsNotDistinct",
          ox: ox$1,
          oy: oy$1
        }
      };
    } else {
      var segmentsAgree = ox$1.voyageSegment === oy$1.voyageSegment;
      var match = bookingNumBerths(ox$1);
      var match$1 = bookingNumBerths(oy$1);
      var exit = 0;
      var booked;
      var unbooked;
      var bookedBerths;
      if (ox$1.isBooked) {
        if (oy$1.isBooked) {
          if (match !== undefined && match$1 !== undefined) {
            var match$2 = occsIssue(ox$1, match$1, ox$1, oy$1);
            var match$3 = occsIssue(oy$1, match, ox$1, oy$1);
            swapcmp = match$2 !== undefined ? match$2 : (
                match$3 !== undefined ? match$3 : ({
                      TAG: "Ok",
                      _0: {
                        cabinDescription: "Both " + CS_NonemptyStrings$Util.CabinNumber.toString(ox$1.cabinNumber) + " and " + CS_NonemptyStrings$Util.CabinNumber.toString(oy$1.cabinNumber) + " are booked and their occupancies are compatible for swap",
                        nBooked: 2,
                        segmentsAgree: segmentsAgree,
                        bookingSwapLink: DeckMapUtil$Thick.getBookedSwapLink(ox$1, oy$1),
                        x: ox$1,
                        y: oy$1
                      }
                    })
              );
          } else {
            exit = 1;
          }
        } else if (match !== undefined) {
          booked = ox$1;
          unbooked = oy$1;
          bookedBerths = match;
          exit = 2;
        } else {
          exit = 1;
        }
      } else if (oy$1.isBooked) {
        if (match$1 !== undefined) {
          booked = oy$1;
          unbooked = ox$1;
          bookedBerths = match$1;
          exit = 2;
        } else {
          exit = 1;
        }
      } else {
        swapcmp = {
          TAG: "Ok",
          _0: {
            cabinDescription: "Neither cabin is booked",
            nBooked: 0,
            segmentsAgree: segmentsAgree,
            bookingSwapLink: undefined,
            x: ox$1,
            y: oy$1
          }
        };
      }
      switch (exit) {
        case 1 :
            swapcmp = {
              TAG: "Error",
              _0: {
                errorMsg: "Something went wrong while we were comparing these cabins [" + CS_NonemptyStrings$Util.CabinNumber.toString(ox$1.cabinNumber) + ";" + CS_NonemptyStrings$Util.CabinNumber.toString(oy$1.cabinNumber) + "]",
                errorT: "UnexpectedComparisonState",
                ox: ox$1,
                oy: oy$1
              }
            };
            break;
        case 2 :
            swapcmp = Belt_Option.getWithDefault(occsIssue(unbooked, bookedBerths, ox$1, oy$1), {
                  TAG: "Ok",
                  _0: {
                    cabinDescription: "Cabin " + CS_NonemptyStrings$Util.CabinNumber.toString(booked.cabinNumber) + " is booked; " + CS_NonemptyStrings$Util.CabinNumber.toString(unbooked.cabinNumber) + " is not",
                    nBooked: 1,
                    segmentsAgree: segmentsAgree,
                    bookingSwapLink: DeckMapUtil$Thick.getUnbookedSwapLink(booked, unbooked.cabinNumber),
                    x: ox$1,
                    y: oy$1
                  }
                });
            break;
        
      }
    }
  } else {
    swapcmp = {
      TAG: "Error",
      _0: {
        errorMsg: "You haven't selected CabinX and CabinY, so we cannot swap anything",
        errorT: "DidntSelectCabins",
        ox: ox$1,
        oy: oy$1
      }
    };
  }
  return [
          swapcmp,
          [
            ox$1,
            oy$1
          ]
        ];
}

function DeckMapPage$SwapControls$Drawer$TagSwapMarker(props) {
  return JsxRuntime.jsx("span", {
              children: "✲",
              style: {
                color: "BlueViolet",
                fontSize: "1.15em"
              }
            });
}

var TagSwapMarker = {
  make: DeckMapPage$SwapControls$Drawer$TagSwapMarker
};

function DeckMapPage$SwapControls$Drawer$CabinTable(props) {
  var match = props.swapok;
  var y = match.y;
  var x = match.x;
  var trow = function (rowHeader, accessor, isTagSwappedOpt, backgroundColorOpt) {
    var isTagSwapped = isTagSwappedOpt !== undefined ? isTagSwappedOpt : false;
    var backgroundColor = backgroundColorOpt !== undefined ? backgroundColorOpt : "inherit";
    return JsxRuntime.jsxs("tr", {
                children: [
                  JsxRuntime.jsxs("td", {
                        children: [
                          isTagSwapped ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                  children: [
                                    JsxRuntime.jsx(DeckMapPage$SwapControls$Drawer$TagSwapMarker, {}),
                                    " "
                                  ]
                                }) : null,
                          rowHeader
                        ],
                        style: {
                          fontWeight: "bold"
                        }
                      }),
                  JsxRuntime.jsx("td", {
                        children: accessor(x)
                      }),
                  JsxRuntime.jsx("td", {
                        children: accessor(y)
                      })
                ],
                style: {
                  backgroundColor: backgroundColor
                }
              });
  };
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(Typography, {
                      level: "h3",
                      sx: {
                        fontWeight: "bold"
                      },
                      children: "Cabin Details"
                    }),
                JsxRuntime.jsxs(Table, {
                      sx: {
                        "& th": {
                          py: 0
                        },
                        "& td": {
                          py: 0
                        }
                      },
                      children: [
                        JsxRuntime.jsx("thead", {
                              children: JsxRuntime.jsxs("tr", {
                                    children: [
                                      JsxRuntime.jsx("th", {
                                            children: "Property"
                                          }),
                                      JsxRuntime.jsx("th", {
                                            children: "Cabin X"
                                          }),
                                      JsxRuntime.jsx("th", {
                                            children: "Cabin Y"
                                          })
                                    ]
                                  })
                            }),
                        JsxRuntime.jsxs("tbody", {
                              children: [
                                trow("Occupancy Range", (function (c) {
                                        return String(c.minBerths) + "-" + String(c.maxBerths);
                                      }), undefined, undefined),
                                trow("Booking", (function (c) {
                                        var match = c.booking.nodes;
                                        if (match.length !== 1) {
                                          return JsxRuntime.jsx("span", {
                                                      children: "unbooked",
                                                      style: {
                                                        color: "lightgrey"
                                                      }
                                                    });
                                        }
                                        var match$1 = match[0];
                                        return CS_NonemptyStrings$Util.BookingId.toEl(match$1.bookingId);
                                      }), undefined, undefined),
                                trow("Inventory Segment", (function (c) {
                                        return c.voyageSegment;
                                      }), true, match.segmentsAgree ? undefined : "LightGoldenRodYellow"),
                                trow("Reservation Note", (function (c) {
                                        return Belt_Option.mapWithDefault(c.reservationNoteMd, null, CS_NonemptyStrings$Util.NonEmptyString.toEl);
                                      }), true, undefined)
                              ]
                            })
                      ]
                    })
              ]
            });
}

var CabinTable = {
  make: DeckMapPage$SwapControls$Drawer$CabinTable
};

function DeckMapPage$SwapControls$Drawer$InventoryTags(props) {
  var voyage = props.voyage;
  var brandFamily = props.brandFamily;
  var swapDrawer = props.swapDrawer;
  var match = props.swapok;
  var y = match.y;
  var x = match.x;
  var segmentsAgree = match.segmentsAgree;
  var match$1 = HopperState$Util.Observable.useComputed(swapDrawer, (function (d) {
          var inventoryTagsMutation = d.inventoryTagsMutation;
          return [
                  Belt_Result.isOk(DeckMapUtil$Thick.overrideSegment.validate(d, "ValidateInputValue")),
                  RemoteData$Util.isLoading(inventoryTagsMutation),
                  inventoryTagsMutation
                ];
        }), undefined, undefined);
  var inventoryTagsMutation = match$1[2];
  var canExecute = segmentsAgree || match$1[0];
  var doSwap = RemoteData$Util.Relay.useMutationWithNotify(DeckMapUtil$Thick.Query.SwapInventoryTagsMutation.use, (function (res) {
          var match = res.swapInventoryTags;
          if (match === undefined) {
            return {
                    TAG: "Failure",
                    _0: "Could not update swap tags at this time."
                  };
          }
          var message = match.nullableErrorMessage;
          if (message !== undefined && message !== "") {
            return {
                    TAG: "Failure",
                    _0: message
                  };
          } else {
            return {
                    TAG: "Success",
                    _0: false
                  };
          }
        }), (function (inventoryTagsMutation) {
          HopperState$Util.Observable.notify(swapDrawer, (function (d) {
                  return {
                          overrideSegment: d.overrideSegment,
                          inventoryTagsMutation: inventoryTagsMutation
                        };
                }));
        }));
  var tmp;
  tmp = typeof inventoryTagsMutation !== "object" ? null : (
      inventoryTagsMutation.TAG === "Success" ? JsxRuntime.jsx(Typography, {
              startDecorator: Caml_option.some(JsxRuntime.jsx(CheckCircle, {})),
              level: "body-md",
              sx: {
                color: "Aquamarine"
              },
              children: "Your changes have been applied"
            }) : JsxRuntime.jsx(Typography, {
              startDecorator: Caml_option.some(JsxRuntime.jsx(WarningAmber, {})),
              level: "body-md",
              sx: {
                color: "IndianRed"
              },
              children: Caml_option.some(inventoryTagsMutation._0)
            })
    );
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(Typography, {
                      level: "h3",
                      sx: {
                        fontWeight: "bold"
                      },
                      children: "Inventory Tags"
                    }),
                JsxRuntime.jsxs(Typography, {
                      level: "body-md",
                      children: [
                        "Swap the inventory tags (",
                        JsxRuntime.jsx(DeckMapPage$SwapControls$Drawer$TagSwapMarker, {}),
                        ") between these pieces of inventory."
                      ]
                    }),
                segmentsAgree ? null : JsxRuntime.jsx(BSPInputs$Thick.$$Text.make, {
                        view: {
                          TAG: "Direct",
                          _0: swapDrawer
                        },
                        input: DeckMapUtil$Thick.overrideSegment,
                        label: "Override Inventory Segment Disagreement"
                      }),
                segmentsAgree ? null : JsxRuntime.jsx(Typography, {
                        startDecorator: Caml_option.some(JsxRuntime.jsx(WarningAmber, {})),
                        level: "body-md",
                        sx: {
                          color: "IndianRed"
                        },
                        children: "Segments don't agree: type OVERRIDE to swap"
                      }),
                JsxRuntime.jsx(Button, {
                      onClick: (function (param) {
                          return doSwap({
                                      input: {
                                        onBrandFamily: brandFamily,
                                        onVoyage: voyage,
                                        cabinX: x.cabinNumber,
                                        cabinY: y.cabinNumber,
                                        swapVoyageSegment: true,
                                        swapReservationNote: true
                                      }
                                    }, undefined, undefined, undefined, undefined);
                        }),
                      startDecorator: Caml_option.some(JsxRuntime.jsx(SwapHoriz, {})),
                      variant: "soft",
                      color: segmentsAgree ? "primary" : "warning",
                      size: "lg",
                      disabled: !canExecute || match$1[1],
                      children: Caml_option.some(segmentsAgree ? "Swap Inventory Tags" : "Swap Inventory Tags with Override")
                    }),
                tmp
              ]
            });
}

var InventoryTags = {
  make: DeckMapPage$SwapControls$Drawer$InventoryTags
};

function openInNew(link) {
  return (window.open(link, "_blank"));
}

function DeckMapPage$SwapControls$Drawer$BookingSwap(props) {
  var refetch = props.refetch;
  var match = props.swapok;
  var nBooked = match.nBooked;
  var swapOrMove = nBooked === 2 ? "swap" : "move";
  var bookingOrBookings = nBooked === 2 ? "bookings" : "booking";
  return Belt_Option.mapWithDefault(match.bookingSwapLink, null, (function (bookingSwapLink) {
                return JsxRuntime.jsxs(JsxRuntime.Fragment, {
                            children: [
                              JsxRuntime.jsx(Typography, {
                                    level: "h3",
                                    sx: {
                                      fontWeight: "bold"
                                    },
                                    children: Caml_option.some("Booking " + swapOrMove)
                                  }),
                              JsxRuntime.jsx(Typography, {
                                    level: "body-md",
                                    children: Caml_option.some("To " + swapOrMove + " the " + bookingOrBookings + ", you \n              will need to open a new tab to the booking modification page. There you\n              can review the billing impact of a cabin " + swapOrMove + ".\n              .")
                                  }),
                              JsxRuntime.jsx(Button, {
                                    onClick: (function (param) {
                                        return openInNew(bookingSwapLink);
                                      }),
                                    startDecorator: Caml_option.some(JsxRuntime.jsx(OpenInNew, {})),
                                    variant: "soft",
                                    color: "primary",
                                    size: "lg",
                                    disabled: false,
                                    children: "Open Modbooking"
                                  }),
                              JsxRuntime.jsx(Button, {
                                    onClick: (function (param) {
                                        return refetch();
                                      }),
                                    startDecorator: Caml_option.some(JsxRuntime.jsx(Refresh, {})),
                                    variant: "soft",
                                    color: "secondary",
                                    size: "lg",
                                    disabled: false,
                                    children: "Refresh the map to see my changes"
                                  })
                            ]
                          });
              }));
}

var BookingSwap = {
  openInNew: openInNew,
  make: DeckMapPage$SwapControls$Drawer$BookingSwap
};

function DeckMapPage$SwapControls$Drawer$DrawerBody(props) {
  var swapcmp = props.swapcmp;
  var tmp;
  if (swapcmp.TAG === "Ok") {
    var match = swapcmp._0;
    tmp = "Swap Cabin " + CS_NonemptyStrings$Util.CabinNumber.toString(match.x.cabinNumber) + " with Cabin " + CS_NonemptyStrings$Util.CabinNumber.toString(match.y.cabinNumber);
  } else {
    tmp = "Not Swappable";
  }
  var tmp$1;
  if (swapcmp.TAG === "Ok") {
    var swapok = swapcmp._0;
    tmp$1 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsx(DeckMapPage$SwapControls$Drawer$CabinTable, {
                  swapok: swapok
                }),
            JsxRuntime.jsx(DeckMapPage$SwapControls$Drawer$InventoryTags, {
                  swapok: swapok,
                  swapDrawer: props.swapDrawer,
                  brandFamily: props.brandFamily,
                  voyage: props.voyage
                }),
            JsxRuntime.jsx(DeckMapPage$SwapControls$Drawer$BookingSwap, {
                  swapok: swapok,
                  refetch: props.refetch
                })
          ]
        });
  } else {
    tmp$1 = JsxRuntime.jsx("h3", {
          children: swapcmp._0.errorMsg,
          style: {
            color: "red"
          }
        });
  }
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(Box, {
                      children: Caml_option.some(JsxRuntime.jsx(DialogTitle, {
                                children: Caml_option.some(tmp),
                                sx: {
                                  margin: "auto"
                                }
                              })),
                      sx: {
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: 0.5,
                        p: 1,
                        width: "calc(100% - 16px)"
                      }
                    }),
                JsxRuntime.jsx(Divider, {}),
                JsxRuntime.jsx(DialogContent, {
                      children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                direction: "column",
                                sx: {
                                  p: 2,
                                  gap: 2
                                },
                                children: Caml_option.some(tmp$1)
                              })),
                      sx: {
                        backgroundColor: "primary.50"
                      }
                    })
              ]
            });
}

var DrawerBody = {
  make: DeckMapPage$SwapControls$Drawer$DrawerBody
};

function DeckMapPage$SwapControls$Drawer(props) {
  var state = props.state;
  var match = HopperState$Util.Observable.useComputed(state, (function (param) {
          return [
                  Belt_Option.isSome(param.swapDrawer),
                  param.cabinX,
                  param.cabinY
                ];
        }), undefined, undefined);
  var open = match[0];
  var onClose = function (param) {
    HopperState$Util.Observable.notify(state, (function (m) {
            return {
                    deckLeft: m.deckLeft,
                    deckRight: m.deckRight,
                    loadedDecks: m.loadedDecks,
                    mapsAreLoading: m.mapsAreLoading,
                    url: m.url,
                    hoverCabin: m.hoverCabin,
                    borderColoring: m.borderColoring,
                    hideSegments: m.hideSegments,
                    cabinX: m.cabinX,
                    cabinY: m.cabinY,
                    swapDrawer: undefined
                  };
          }));
  };
  var drawerView = HopperState$Util.Observable.makeView(state, {
        read: (function (m) {
            return m.swapDrawer;
          }),
        write: (function (m, swapDrawer) {
            return {
                    deckLeft: m.deckLeft,
                    deckRight: m.deckRight,
                    loadedDecks: m.loadedDecks,
                    mapsAreLoading: m.mapsAreLoading,
                    url: m.url,
                    hoverCabin: m.hoverCabin,
                    borderColoring: m.borderColoring,
                    hideSegments: m.hideSegments,
                    cabinX: m.cabinX,
                    cabinY: m.cabinY,
                    swapDrawer: swapDrawer
                  };
          })
      }, undefined);
  var match$1 = checkCabinNumbers(props.voyageCabinsMap, match[1], match[2]);
  return JsxRuntime.jsx(Drawer, {
              children: Caml_option.some(open ? JsxRuntime.jsx(DeckMapPage$SwapControls$Drawer$DrawerBody, {
                          brandFamily: props.brandFamily,
                          voyage: props.voyage,
                          swapcmp: match$1[0],
                          swapDrawer: HopperState$Util.Observable.makeExnOptionView(drawerView),
                          refetch: props.refetch
                        }) : null),
              open: open,
              anchor: "right",
              disablePortal: true,
              onClose: onClose,
              size: "md",
              slotProps: {
                backdrop: {
                  sx: {
                    backdropFilter: "none"
                  }
                },
                content: {
                  sx: {
                    borderTopLeftRadius: "4px",
                    borderTopRightRadius: "4px",
                    ml: "8px",
                    mr: "8px"
                  }
                }
              }
            });
}

var Drawer$1 = {
  TagSwapMarker: TagSwapMarker,
  CabinTable: CabinTable,
  InventoryTags: InventoryTags,
  BookingSwap: BookingSwap,
  DrawerBody: DrawerBody,
  make: DeckMapPage$SwapControls$Drawer
};

function DeckMapPage$SwapControls$CabinTitle(props) {
  var cabin = props.cabin;
  return JsxRuntime.jsxs("p", {
              children: [
                props.title + ": ",
                cabin !== undefined ? CS_Slugs$Util.CabinCategorySlug.toString(cabin.voyageCategorySlug).toLocaleUpperCase() + " " + CS_NonemptyStrings$Util.CabinNumber.toString(cabin.cabinNumber) + " (" + String(cabin.minBerths) + "-" + String(cabin.maxBerths) + ")" : "none selected",
                JsxRuntime.jsx("br", {})
              ]
            });
}

var CabinTitle = {
  make: DeckMapPage$SwapControls$CabinTitle
};

function DeckMapPage$SwapControls(props) {
  var state = props.state;
  var match = HopperState$Util.Observable.useComputed(state, (function (param) {
          return [
                  param.cabinX,
                  param.cabinY
                ];
        }), undefined, undefined);
  var match$1 = checkCabinNumbers(props.voyageCabinsMap, match[0], match[1]);
  var match$2 = match$1[1];
  var cabinY = match$2[1];
  var cabinX = match$2[0];
  var swapcmp = match$1[0];
  var bothSelected = Belt_Option.isSome(cabinX) && Belt_Option.isSome(cabinY);
  var swapSummary;
  swapSummary = swapcmp.TAG === "Ok" ? swapcmp._0.cabinDescription : swapcmp._0.errorMsg;
  return JsxRuntime.jsx(JsxRuntime.Fragment, {
              children: Caml_option.some(JsxRuntime.jsxs("div", {
                        children: [
                          JsxRuntime.jsx("h3", {
                                children: "Swap Ctrl"
                              }),
                          JsxRuntime.jsx(DeckMapPage$SwapControls$CabinTitle, {
                                cabin: cabinX,
                                title: "CabinX(q)"
                              }),
                          JsxRuntime.jsx(DeckMapPage$SwapControls$CabinTitle, {
                                cabin: cabinY,
                                title: "CabinY(w)"
                              }),
                          JsxRuntime.jsxs("p", {
                                children: [
                                  "Swappability status: ",
                                  JsxRuntime.jsx("br", {}),
                                  JsxRuntime.jsx("em", {
                                        children: swapSummary,
                                        style: bothSelected && Belt_Result.isError(swapcmp) ? ({
                                              color: "red"
                                            }) : ({})
                                      })
                                ]
                              }),
                          JsxRuntime.jsx("button", {
                                children: "Open Swap Drawer",
                                onClick: (function (param) {
                                    HopperState$Util.Observable.notify(state, (function (m) {
                                            return {
                                                    deckLeft: m.deckLeft,
                                                    deckRight: m.deckRight,
                                                    loadedDecks: m.loadedDecks,
                                                    mapsAreLoading: m.mapsAreLoading,
                                                    url: m.url,
                                                    hoverCabin: m.hoverCabin,
                                                    borderColoring: m.borderColoring,
                                                    hideSegments: m.hideSegments,
                                                    cabinX: m.cabinX,
                                                    cabinY: m.cabinY,
                                                    swapDrawer: DeckMapUtil$Thick.blankSwapDrawer
                                                  };
                                          }));
                                  })
                              }),
                          " ",
                          JsxRuntime.jsx("button", {
                                children: "Clear X & Y",
                                onClick: (function (param) {
                                    HopperState$Util.Observable.notify(state, (function (m) {
                                            return {
                                                    deckLeft: m.deckLeft,
                                                    deckRight: m.deckRight,
                                                    loadedDecks: m.loadedDecks,
                                                    mapsAreLoading: m.mapsAreLoading,
                                                    url: m.url,
                                                    hoverCabin: m.hoverCabin,
                                                    borderColoring: m.borderColoring,
                                                    hideSegments: m.hideSegments,
                                                    cabinX: undefined,
                                                    cabinY: undefined,
                                                    swapDrawer: m.swapDrawer
                                                  };
                                          }));
                                  })
                              })
                        ]
                      }))
            });
}

var SwapControls = {
  checkCabinNumbers: checkCabinNumbers,
  bookingNumBerths: bookingNumBerths,
  occsIssue: occsIssue,
  Drawer: Drawer$1,
  CabinTitle: CabinTitle,
  make: DeckMapPage$SwapControls
};

function DeckMapPage$SegmentVisibility(props) {
  var allSegmentColors = props.allSegmentColors;
  var match = HopperState$Util.Observable.useReadWrite(HopperState$Util.Observable.makeView(props.state, {
            read: (function (m) {
                return m.hideSegments;
              }),
            write: (function (m, hideSegments) {
                return {
                        deckLeft: m.deckLeft,
                        deckRight: m.deckRight,
                        loadedDecks: m.loadedDecks,
                        mapsAreLoading: m.mapsAreLoading,
                        url: m.url,
                        hoverCabin: m.hoverCabin,
                        borderColoring: m.borderColoring,
                        hideSegments: hideSegments,
                        cabinX: m.cabinX,
                        cabinY: m.cabinY,
                        swapDrawer: m.swapDrawer
                      };
              })
          }, undefined), undefined);
  var setHiddenSegments = match[1];
  var hiddenSegments = match[0];
  var list = Belt_Array.map(Belt_SortArray.stableSortBy(Belt_MapString.toArray(props.visibleSegmentsToCounts), (function (param, param$1) {
              return Caml.int_compare(param$1[1], param[1]);
            })), (function (param) {
          var slug = param[0];
          return {
                  slug: slug,
                  count: param[1],
                  hex: Belt_MapString.getExn(allSegmentColors, slug)
                };
        }));
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx("h3", {
                      children: "Segment Visibility"
                    }),
                JsxRuntime.jsx("div", {
                      children: Belt_Array.map(list, (function (param) {
                              var slug = param.slug;
                              var isHidden = Belt_SetString.has(hiddenSegments, slug);
                              return JsxRuntime.jsxs("div", {
                                          children: [
                                            JsxRuntime.jsx("div", {
                                                  style: {
                                                    backgroundColor: "#" + param.hex,
                                                    height: "20px",
                                                    width: "20px"
                                                  }
                                                }),
                                            JsxRuntime.jsx("div", {
                                                  children: JsxRuntime.jsx("label", {
                                                        children: slug + " (" + String(param.count) + ")",
                                                        htmlFor: "checkbox-" + slug
                                                      }),
                                                  style: {
                                                    fontSize: "12px",
                                                    overflowX: "hidden",
                                                    textDecoration: isHidden ? "line-through" : "none",
                                                    whiteSpace: "nowrap",
                                                    width: "200px"
                                                  }
                                                }),
                                            JsxRuntime.jsx("div", {
                                                  children: JsxRuntime.jsx("input", {
                                                        id: "checkbox-" + slug,
                                                        checked: isHidden ? false : true,
                                                        type: "checkbox",
                                                        onChange: (function (param) {
                                                            setHiddenSegments(function (segs) {
                                                                  if (isHidden) {
                                                                    return Belt_SetString.remove(segs, slug);
                                                                  } else {
                                                                    return Belt_SetString.add(segs, slug);
                                                                  }
                                                                });
                                                          })
                                                      })
                                                })
                                          ],
                                          style: {
                                            display: "flex",
                                            alignItems: "center",
                                            flexDirection: "row",
                                            gap: "0.5em"
                                          }
                                        }, slug);
                            })),
                      style: {
                        display: "flex",
                        flexDirection: "column"
                      }
                    })
              ]
            });
}

var SegmentVisibility = {
  make: DeckMapPage$SegmentVisibility
};

function DeckMapPage$BorderColoring(props) {
  var match = HopperState$Util.Observable.useReadWrite(HopperState$Util.Observable.makeView(props.state, {
            read: (function (m) {
                return m.borderColoring;
              }),
            write: (function (m, borderColoring) {
                return {
                        deckLeft: m.deckLeft,
                        deckRight: m.deckRight,
                        loadedDecks: m.loadedDecks,
                        mapsAreLoading: m.mapsAreLoading,
                        url: m.url,
                        hoverCabin: m.hoverCabin,
                        borderColoring: borderColoring,
                        hideSegments: m.hideSegments,
                        cabinX: m.cabinX,
                        cabinY: m.cabinY,
                        swapDrawer: m.swapDrawer
                      };
              })
          }, undefined), undefined);
  var setBorderingColorings = match[1];
  var borderColorings = match[0];
  var predNames = Belt_Array.keepMap(props.cabinPredicates, (function (param) {
          if (param.type_ === "Border") {
            return param.name;
          }
          
        }));
  var set = Belt_SetString.fromArray(Belt_MapInt.valuesToArray(borderColorings));
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx("h3", {
                      children: "Border Highlights"
                    }),
                JsxRuntime.jsx("table", {
                      children: JsxRuntime.jsx("tbody", {
                            children: Belt_Array.mapWithIndex(DeckMapUtil$Thick.borderPallete, (function (i, param) {
                                    return JsxRuntime.jsxs("tr", {
                                                children: [
                                                  JsxRuntime.jsx("td", {
                                                        style: {
                                                          backgroundColor: "#" + param.hex,
                                                          height: "100%",
                                                          minWidth: "20px"
                                                        }
                                                      }),
                                                  JsxRuntime.jsx("td", {
                                                        children: JsxRuntime.jsxs("select", {
                                                              children: [
                                                                JsxRuntime.jsx("option", {}),
                                                                Belt_Array.map(predNames, (function (key) {
                                                                        return JsxRuntime.jsx("option", {
                                                                                    children: key,
                                                                                    disabled: Belt_SetString.has(set, key)
                                                                                  }, key);
                                                                      }))
                                                              ],
                                                              value: Belt_Option.getWithDefault(Belt_MapInt.get(borderColorings, i), ""),
                                                              onChange: (function (e) {
                                                                  var target = e.target;
                                                                  var value = target.value;
                                                                  setBorderingColorings(function (map) {
                                                                        if (value === "") {
                                                                          return Belt_MapInt.remove(map, i);
                                                                        } else {
                                                                          return Belt_MapInt.set(map, i, value);
                                                                        }
                                                                      });
                                                                })
                                                            })
                                                      }),
                                                  JsxRuntime.jsx("td", {
                                                        children: JsxRuntime.jsx("button", {
                                                              children: "🗑️",
                                                              disabled: Belt_Option.isNone(Belt_MapInt.get(borderColorings, i)),
                                                              onClick: (function (param) {
                                                                  setBorderingColorings(function (map) {
                                                                        return Belt_MapInt.remove(map, i);
                                                                      });
                                                                })
                                                            }),
                                                        style: {
                                                          width: "37px"
                                                        }
                                                      })
                                                ]
                                              }, param.name);
                                  }))
                          })
                    })
              ]
            });
}

var BorderColoring = {
  make: DeckMapPage$BorderColoring
};

function DeckMapPage$RefreshedAtAndRefetch(props) {
  var refetch = props.refetch;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx("h3", {
                      children: "Refresh"
                    }),
                Belt_Option.mapWithDefault(props.refreshedAt, null, (function (refreshedAt) {
                        return JsxRuntime.jsx("p", {
                                    children: "The map was last refreshed on " + CS_Make$Util.Dateable.Plain.format(refreshedAt, "MM/dd/yyyy") + " at " + CS_Make$Util.Dateable.Plain.format(refreshedAt, "hh:mm:ss b") + ". Inventory tag swapping will automatically refresh this page, \n            but modifications to bookings will require a refresh to be visible\n            here."
                                  });
                      })),
                JsxRuntime.jsx("button", {
                      children: "Refresh the cabin data!",
                      onClick: (function (param) {
                          refetch();
                        })
                    })
              ]
            });
}

var RefreshedAtAndRefetch = {
  make: DeckMapPage$RefreshedAtAndRefetch
};

function DeckMapPage$OneDeck(props) {
  var setDeck = props.setDeck;
  var disableDeck = props.disableDeck;
  var deckName = props.deckName;
  var width = "275px";
  var setLoadedDecks = HopperState$Util.Observable.asSetter(DeckMapUtil$Thick.loadedDecks(props.state));
  React.useEffect((function () {
          return (function () {
                    setLoadedDecks(function (loadedDecks) {
                          return Belt_MapString.remove(loadedDecks, deckName);
                        });
                  });
        }), [deckName]);
  var data = Belt_MapString.get(DeckMapUtil$Thick.NADM.deckUrls, deckName);
  return JsxRuntime.jsxs("div", {
              children: [
                Belt_Array.map(DeckMapUtil$Thick.NADM.decks, (function (name) {
                        return JsxRuntime.jsxs(React.Fragment, {
                                    children: [
                                      JsxRuntime.jsx("button", {
                                            children: "Deck " + name,
                                            style: name === deckName ? ({
                                                  border: "3px solid green"
                                                }) : ({
                                                  border: "1px solid black"
                                                }),
                                            disabled: disableDeck === name,
                                            onClick: (function (param) {
                                                setDeck(function (param) {
                                                      return name;
                                                    });
                                              })
                                          }),
                                      " "
                                    ]
                                  }, name);
                      })),
                data !== undefined ? JsxRuntime.jsx("object", {
                        className: "shipdeck",
                        style: {
                          paddingBottom: "88px"
                        },
                        data: data,
                        type: "image/svg+xml",
                        width: width,
                        onLoad: (function (param) {
                            setLoadedDecks(function (loadedDecks) {
                                  return Belt_MapString.set(loadedDecks, deckName, Date.now());
                                });
                          })
                      }) : "Deck not found"
              ],
              style: {
                width: width
              }
            });
}

var OneDeck = {
  make: DeckMapPage$OneDeck
};

function DeckMapPage(props) {
  var state = props.state;
  var match = HopperState$Util.Observable.FormField.useState(state, DeckMapUtil$Thick.deckLeft, undefined);
  var left = match[0];
  var match$1 = HopperState$Util.Observable.FormField.useState(state, DeckMapUtil$Thick.deckRight, undefined);
  var right = match$1[0];
  var match$2 = DeckMapUtil$Thick.Query.List.useRefetchable(props.fragmentRefs);
  var refetch = match$2[1];
  var refetch$1 = function (param) {
    refetch(DeckMapUtil$Thick.Query.List.makeRefetchVariables(undefined, undefined), "store-and-network", undefined);
  };
  HopperState$Util.Observable.useSubscribeOnChange(state, (function (param) {
          var swapDrawer = param.swapDrawer;
          if (swapDrawer === undefined) {
            return ;
          }
          var match = swapDrawer.inventoryTagsMutation;
          if (typeof match !== "object" || !(match.TAG === "Success" && !match._0)) {
            return ;
          } else {
            refetch$1();
            return HopperState$Util.Observable.notify(state, (function (m) {
                          return {
                                  deckLeft: m.deckLeft,
                                  deckRight: m.deckRight,
                                  loadedDecks: m.loadedDecks,
                                  mapsAreLoading: m.mapsAreLoading,
                                  url: m.url,
                                  hoverCabin: m.hoverCabin,
                                  borderColoring: m.borderColoring,
                                  hideSegments: m.hideSegments,
                                  cabinX: m.cabinX,
                                  cabinY: m.cabinY,
                                  swapDrawer: Belt_Option.mapWithDefault(m.swapDrawer, undefined, (function (swapDrawer) {
                                          return {
                                                  overrideSegment: swapDrawer.overrideSegment,
                                                  inventoryTagsMutation: RemoteData$Util.mapSuccess(swapDrawer.inventoryTagsMutation, (function (param) {
                                                          return true;
                                                        }))
                                                };
                                        }))
                                };
                        }));
          }
        }), undefined, undefined);
  var voyageCabins = Belt_Option.mapWithDefault(match$2[0].voyageCabins, [], (function (param) {
          return param.nodes;
        }));
  var refreshedAt = Belt_Option.map(Belt_Array.get(voyageCabins, 0), (function (param) {
          return param.cabinFetchedAt;
        }));
  var voyageCabinsMap = Belt_MapDict.fromArray(Belt_Array.map(voyageCabins, (function (cab) {
              return [
                      cab.cabinNumber,
                      cab
                    ];
            })), CS_NonemptyStrings$Util.CabinNumber.Comparable.cmp);
  var match$3 = Belt_Array.reduce(voyageCabins, [
        Belt_Set.make(CS_Slugs$Util.CabinCategorySlug.Comparable),
        Belt_Set.make(CS_Slugs$Util.CabinClassSlug.Comparable),
        undefined,
        undefined
      ], (function (param, param$1) {
          var voyageSegment = param$1.voyageSegment;
          var deckNumber = param$1.deckNumber;
          var carryPres = param[3];
          return [
                  Belt_Set.add(param[0], param$1.voyageCategorySlug),
                  Belt_Set.add(param[1], param$1.voyageClassSlug),
                  Belt_MapString.set(param[2], voyageSegment, Belt_Option.getExn(Belt_Option.map(param$1.segment, (function (param) {
                                  return param.colorHex;
                                })))),
                  CS_NonemptyStrings$Util.DeckNumber.toString(deckNumber) === left || CS_NonemptyStrings$Util.DeckNumber.toString(deckNumber) === right ? Belt_MapString.update(carryPres, voyageSegment, (function (ocount) {
                            return Belt_Option.getWithDefault(ocount, 0) + 1 | 0;
                          })) : carryPres
                ];
        }));
  var allSegmentColors = match$3[2];
  var cabinPredicates = DeckMapUtil$Thick.cabinPredicates(Belt_Set.toArray(match$3[0]), Belt_Set.toArray(match$3[1]));
  var cabinBgColorPalette = Object.fromEntries(Belt_Array.map(Belt_MapString.toArray(allSegmentColors), (function (param) {
              var name = param[0];
              return [
                      name,
                      {
                        hex: param[1],
                        name: name
                      }
                    ];
            })));
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs("section", {
                      children: [
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(DeckMapPage$SegmentVisibility, {
                                      state: state,
                                      allSegmentColors: allSegmentColors,
                                      visibleSegmentsToCounts: match$3[3]
                                    }),
                                JsxRuntime.jsx("br", {}),
                                JsxRuntime.jsx(DeckMapPage$BorderColoring, {
                                      state: state,
                                      cabinPredicates: cabinPredicates
                                    }),
                                JsxRuntime.jsx("br", {}),
                                JsxRuntime.jsx(DeckMapPage$RefreshedAtAndRefetch, {
                                      refetch: refetch$1,
                                      refreshedAt: refreshedAt
                                    })
                              ],
                              id: "controls",
                              style: {
                                maxWidth: "333px"
                              }
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(DeckMapPage$OneDeck, {
                                    deckName: left,
                                    disableDeck: right,
                                    setDeck: match[1],
                                    state: state
                                  }),
                              id: "deckLeft",
                              style: {
                                marginLeft: "55px"
                              }
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(DeckMapPage$OneDeck, {
                                    deckName: right,
                                    disableDeck: left,
                                    setDeck: match$1[1],
                                    state: state
                                  }),
                              id: "deckLeft",
                              style: {
                                marginLeft: "55px"
                              }
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("h3", {
                                            children: "Keyboard Shortcuts"
                                          }),
                                      JsxRuntime.jsx("div", {
                                            id: "kbsc"
                                          }),
                                      JsxRuntime.jsx(DeckMapPage$SwapControls, {
                                            state: state,
                                            voyageCabinsMap: voyageCabinsMap
                                          })
                                    ],
                                    style: {
                                      maxWidth: "333px",
                                      position: "fixed",
                                      top: "100px"
                                    }
                                  }),
                              id: "stats",
                              style: {
                                marginLeft: "55px"
                              }
                            }),
                        JsxRuntime.jsx(DeckMapPage$InitJsDeckMap, {
                              state: state,
                              voyageCabins: voyageCabins,
                              cabinPredicates: cabinPredicates,
                              cabinBgColorPalette: cabinBgColorPalette
                            }),
                        JsxRuntime.jsx(DeckMapPage$CabinDetailsToolip, {
                              state: state
                            })
                      ],
                      id: "kbsc-bind-area",
                      style: {
                        display: "flex",
                        flexDirection: "row"
                      }
                    }),
                JsxRuntime.jsx("div", {
                      id: "spacer",
                      style: {
                        height: "400px"
                      }
                    }),
                JsxRuntime.jsx(DeckMapPage$SwapControls$Drawer, {
                      brandFamily: props.brandFamily,
                      voyage: props.voyage,
                      state: state,
                      voyageCabinsMap: voyageCabinsMap,
                      refetch: refetch$1
                    })
              ]
            });
}

var make = DeckMapPage;

exports.InitJsDeckMap = InitJsDeckMap;
exports.CabinDetailsToolip = CabinDetailsToolip;
exports.SwapControls = SwapControls;
exports.SegmentVisibility = SegmentVisibility;
exports.BorderColoring = BorderColoring;
exports.RefreshedAtAndRefetch = RefreshedAtAndRefetch;
exports.OneDeck = OneDeck;
exports.make = make;
/* react Not a pure module */
