// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var RelayEnv$Thick = require("../../../RelayEnv.bs.js");
var BSPInputs$Thick = require("../../booking-sessions/BSPInputs.bs.js");
var HopperState$Util = require("util/src/HopperState.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var CS_LoyaltyAndCruiselineNumbers$Util = require("util/src/CustomScalars/CS_LoyaltyAndCruiselineNumbers.bs.js");
var CurrentUserSearchInputQuery_graphql$Thick = require("../../../__generated__/CurrentUserSearchInputQuery_graphql.bs.js");
var CurrentUserSearchInputByIdQuery_graphql$Thick = require("../../../__generated__/CurrentUserSearchInputByIdQuery_graphql.bs.js");

var convertVariables = CurrentUserSearchInputQuery_graphql$Thick.Internal.convertVariables;

var convertResponse = CurrentUserSearchInputQuery_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = CurrentUserSearchInputQuery_graphql$Thick.Internal.convertWrapRawResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, CurrentUserSearchInputQuery_graphql$Thick.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, CurrentUserSearchInputQuery_graphql$Thick.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(CurrentUserSearchInputQuery_graphql$Thick.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(CurrentUserSearchInputQuery_graphql$Thick.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(CurrentUserSearchInputQuery_graphql$Thick.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(CurrentUserSearchInputQuery_graphql$Thick.node, convertVariables);

var Query_accountStatus_decode = CurrentUserSearchInputQuery_graphql$Thick.Utils.accountStatus_decode;

var Query_accountStatus_fromString = CurrentUserSearchInputQuery_graphql$Thick.Utils.accountStatus_fromString;

var Query_billingKind_decode = CurrentUserSearchInputQuery_graphql$Thick.Utils.billingKind_decode;

var Query_billingKind_fromString = CurrentUserSearchInputQuery_graphql$Thick.Utils.billingKind_fromString;

var Query_bookingSessionPassengerType_decode = CurrentUserSearchInputQuery_graphql$Thick.Utils.bookingSessionPassengerType_decode;

var Query_bookingSessionPassengerType_fromString = CurrentUserSearchInputQuery_graphql$Thick.Utils.bookingSessionPassengerType_fromString;

var Query_brandfamRole_decode = CurrentUserSearchInputQuery_graphql$Thick.Utils.brandfamRole_decode;

var Query_brandfamRole_fromString = CurrentUserSearchInputQuery_graphql$Thick.Utils.brandfamRole_fromString;

var Query_custSignonStatus_decode = CurrentUserSearchInputQuery_graphql$Thick.Utils.custSignonStatus_decode;

var Query_custSignonStatus_fromString = CurrentUserSearchInputQuery_graphql$Thick.Utils.custSignonStatus_fromString;

var Query_docGender_decode = CurrentUserSearchInputQuery_graphql$Thick.Utils.docGender_decode;

var Query_docGender_fromString = CurrentUserSearchInputQuery_graphql$Thick.Utils.docGender_fromString;

var Query_elevatorProximity_decode = CurrentUserSearchInputQuery_graphql$Thick.Utils.elevatorProximity_decode;

var Query_elevatorProximity_fromString = CurrentUserSearchInputQuery_graphql$Thick.Utils.elevatorProximity_fromString;

var Query_invitationType_decode = CurrentUserSearchInputQuery_graphql$Thick.Utils.invitationType_decode;

var Query_invitationType_fromString = CurrentUserSearchInputQuery_graphql$Thick.Utils.invitationType_fromString;

var Query_lifecycleStatus_decode = CurrentUserSearchInputQuery_graphql$Thick.Utils.lifecycleStatus_decode;

var Query_lifecycleStatus_fromString = CurrentUserSearchInputQuery_graphql$Thick.Utils.lifecycleStatus_fromString;

var Query_longitudinality_decode = CurrentUserSearchInputQuery_graphql$Thick.Utils.longitudinality_decode;

var Query_longitudinality_fromString = CurrentUserSearchInputQuery_graphql$Thick.Utils.longitudinality_fromString;

var Query_manifestPaxReconciliationStatus_decode = CurrentUserSearchInputQuery_graphql$Thick.Utils.manifestPaxReconciliationStatus_decode;

var Query_manifestPaxReconciliationStatus_fromString = CurrentUserSearchInputQuery_graphql$Thick.Utils.manifestPaxReconciliationStatus_fromString;

var Query_paxDataStatus_decode = CurrentUserSearchInputQuery_graphql$Thick.Utils.paxDataStatus_decode;

var Query_paxDataStatus_fromString = CurrentUserSearchInputQuery_graphql$Thick.Utils.paxDataStatus_fromString;

var Query_pmtDirection_decode = CurrentUserSearchInputQuery_graphql$Thick.Utils.pmtDirection_decode;

var Query_pmtDirection_fromString = CurrentUserSearchInputQuery_graphql$Thick.Utils.pmtDirection_fromString;

var Query_pmtLifecycleStatus_decode = CurrentUserSearchInputQuery_graphql$Thick.Utils.pmtLifecycleStatus_decode;

var Query_pmtLifecycleStatus_fromString = CurrentUserSearchInputQuery_graphql$Thick.Utils.pmtLifecycleStatus_fromString;

var Query_pmtMethod_decode = CurrentUserSearchInputQuery_graphql$Thick.Utils.pmtMethod_decode;

var Query_pmtMethod_fromString = CurrentUserSearchInputQuery_graphql$Thick.Utils.pmtMethod_fromString;

var Query_pmtProcessor_decode = CurrentUserSearchInputQuery_graphql$Thick.Utils.pmtProcessor_decode;

var Query_pmtProcessor_fromString = CurrentUserSearchInputQuery_graphql$Thick.Utils.pmtProcessor_fromString;

var Query_refundability_decode = CurrentUserSearchInputQuery_graphql$Thick.Utils.refundability_decode;

var Query_refundability_fromString = CurrentUserSearchInputQuery_graphql$Thick.Utils.refundability_fromString;

var Query_signonStatus_decode = CurrentUserSearchInputQuery_graphql$Thick.Utils.signonStatus_decode;

var Query_signonStatus_fromString = CurrentUserSearchInputQuery_graphql$Thick.Utils.signonStatus_fromString;

var Query_subtotalCategory_decode = CurrentUserSearchInputQuery_graphql$Thick.Utils.subtotalCategory_decode;

var Query_subtotalCategory_fromString = CurrentUserSearchInputQuery_graphql$Thick.Utils.subtotalCategory_fromString;

var Query_versionProvenance_decode = CurrentUserSearchInputQuery_graphql$Thick.Utils.versionProvenance_decode;

var Query_versionProvenance_fromString = CurrentUserSearchInputQuery_graphql$Thick.Utils.versionProvenance_fromString;

var Query = {
  accountStatus_decode: Query_accountStatus_decode,
  accountStatus_fromString: Query_accountStatus_fromString,
  billingKind_decode: Query_billingKind_decode,
  billingKind_fromString: Query_billingKind_fromString,
  bookingSessionPassengerType_decode: Query_bookingSessionPassengerType_decode,
  bookingSessionPassengerType_fromString: Query_bookingSessionPassengerType_fromString,
  brandfamRole_decode: Query_brandfamRole_decode,
  brandfamRole_fromString: Query_brandfamRole_fromString,
  custSignonStatus_decode: Query_custSignonStatus_decode,
  custSignonStatus_fromString: Query_custSignonStatus_fromString,
  docGender_decode: Query_docGender_decode,
  docGender_fromString: Query_docGender_fromString,
  elevatorProximity_decode: Query_elevatorProximity_decode,
  elevatorProximity_fromString: Query_elevatorProximity_fromString,
  invitationType_decode: Query_invitationType_decode,
  invitationType_fromString: Query_invitationType_fromString,
  lifecycleStatus_decode: Query_lifecycleStatus_decode,
  lifecycleStatus_fromString: Query_lifecycleStatus_fromString,
  longitudinality_decode: Query_longitudinality_decode,
  longitudinality_fromString: Query_longitudinality_fromString,
  manifestPaxReconciliationStatus_decode: Query_manifestPaxReconciliationStatus_decode,
  manifestPaxReconciliationStatus_fromString: Query_manifestPaxReconciliationStatus_fromString,
  paxDataStatus_decode: Query_paxDataStatus_decode,
  paxDataStatus_fromString: Query_paxDataStatus_fromString,
  pmtDirection_decode: Query_pmtDirection_decode,
  pmtDirection_fromString: Query_pmtDirection_fromString,
  pmtLifecycleStatus_decode: Query_pmtLifecycleStatus_decode,
  pmtLifecycleStatus_fromString: Query_pmtLifecycleStatus_fromString,
  pmtMethod_decode: Query_pmtMethod_decode,
  pmtMethod_fromString: Query_pmtMethod_fromString,
  pmtProcessor_decode: Query_pmtProcessor_decode,
  pmtProcessor_fromString: Query_pmtProcessor_fromString,
  refundability_decode: Query_refundability_decode,
  refundability_fromString: Query_refundability_fromString,
  signonStatus_decode: Query_signonStatus_decode,
  signonStatus_fromString: Query_signonStatus_fromString,
  subtotalCategory_decode: Query_subtotalCategory_decode,
  subtotalCategory_fromString: Query_subtotalCategory_fromString,
  versionProvenance_decode: Query_versionProvenance_decode,
  versionProvenance_fromString: Query_versionProvenance_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

var convertVariables$1 = CurrentUserSearchInputByIdQuery_graphql$Thick.Internal.convertVariables;

var convertResponse$1 = CurrentUserSearchInputByIdQuery_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse$1 = CurrentUserSearchInputByIdQuery_graphql$Thick.Internal.convertWrapRawResponse;

var use$1 = RescriptRelay_Query.useQuery(convertVariables$1, CurrentUserSearchInputByIdQuery_graphql$Thick.node, convertResponse$1);

var useLoader$1 = RescriptRelay_Query.useLoader(convertVariables$1, CurrentUserSearchInputByIdQuery_graphql$Thick.node, (function (prim) {
        return prim;
      }));

var usePreloaded$1 = RescriptRelay_Query.usePreloaded(CurrentUserSearchInputByIdQuery_graphql$Thick.node, convertResponse$1, (function (prim) {
        return prim;
      }));

var $$fetch$1 = RescriptRelay_Query.$$fetch(CurrentUserSearchInputByIdQuery_graphql$Thick.node, convertResponse$1, convertVariables$1);

var fetchPromised$1 = RescriptRelay_Query.fetchPromised(CurrentUserSearchInputByIdQuery_graphql$Thick.node, convertResponse$1, convertVariables$1);

var retain$1 = RescriptRelay_Query.retain(CurrentUserSearchInputByIdQuery_graphql$Thick.node, convertVariables$1);

var Query2 = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables$1,
  convertResponse: convertResponse$1,
  convertWrapRawResponse: convertWrapRawResponse$1,
  use: use$1,
  useLoader: useLoader$1,
  usePreloaded: usePreloaded$1,
  $$fetch: $$fetch$1,
  fetchPromised: fetchPromised$1,
  retain: retain$1
};

function CurrentUserSearchInput(props) {
  var debounce = props.debounce;
  var __overrideErrorText = props.overrideErrorText;
  var excludeStatuses = props.excludeStatuses;
  var __claimedAndPrefilledOnly = props.claimedAndPrefilledOnly;
  var brandFamily = props.brandFamily;
  var input = props.input;
  var __fullWidth = props.fullWidth;
  var __disabled = props.disabled;
  var __required = props.required;
  var __size = props.size;
  var view = props.view;
  var size = __size !== undefined ? __size : "md";
  var required = __required !== undefined ? __required : false;
  var disabled = __disabled !== undefined ? __disabled : false;
  var fullWidth = __fullWidth !== undefined ? __fullWidth : false;
  var claimedAndPrefilledOnly = __claimedAndPrefilledOnly !== undefined ? __claimedAndPrefilledOnly : true;
  var overrideErrorText = __overrideErrorText !== undefined ? __overrideErrorText : "Please search and select";
  var match;
  match = view.TAG === "Direct" ? HopperState$Util.Observable.FormField.use(view._0, input, undefined) : HopperState$Util.Observable.FormField.useOption(view._0, input, view._1, undefined, undefined);
  var selectedId = match[0].value;
  var match$1 = React.useState(function () {
        return [];
      });
  var setOptions = match$1[1];
  var createFilter = function (s) {
    var accountStatus = claimedAndPrefilledOnly ? ({
          in: [
            "CLAIMED",
            "PREFILLED"
          ]
        }) : (
        excludeStatuses !== undefined ? ({
              notIn: excludeStatuses
            }) : undefined
      );
    return {
            brandFamilySlug: {
              equalTo: CS_Slugs$Util.BrandFamilySlug.toString(brandFamily)
            },
            accountStatus: accountStatus,
            or: [
              {
                fullName: {
                  includesInsensitive: s
                }
              },
              {
                emailString: {
                  includesInsensitive: s
                }
              },
              {
                loyaltyNumString: {
                  includesInsensitive: s
                }
              }
            ]
          };
  };
  var match$2 = React.useState(function () {
        return false;
      });
  var setLoading = match$2[1];
  var match$3 = React.useState(function () {
        
      });
  var setError = match$3[1];
  var clearOptions = function () {
    setOptions(function (param) {
          return [];
        });
  };
  var debounceTimerRef = React.useRef(null);
  var search = function (value) {
    setLoading(function (param) {
          return true;
        });
    setOptions(function (param) {
          return [];
        });
    setError(function (param) {
          
        });
    var search$1 = function (value) {
      $$fetch(RelayEnv$Thick.environment, {
            brandFamily: brandFamily,
            filter: createFilter(value)
          }, (function (res) {
              if (res.TAG === "Ok") {
                var match = res._0.bfcustUsers;
                if (match !== undefined) {
                  var results = Belt_Array.keepMap(match.nodes, (function (node) {
                          var email = node.email;
                          var niceName = email !== undefined ? node.fullName + " <" + CS_Emails$Util.Email.toString(Caml_option.valFromOption(email)) + "> [" + CS_LoyaltyAndCruiselineNumbers$Util.LoyaltyNumber.toString(node.loyaltyNumber) + "]" : node.fullName + " [" + CS_LoyaltyAndCruiselineNumbers$Util.LoyaltyNumber.toString(node.loyaltyNumber) + "]";
                          return {
                                  primitive: CS_NonemptyStrings$Util.AccountId.toString(node.accountId),
                                  key: CS_NonemptyStrings$Util.AccountId.toString(node.accountId),
                                  niceName: niceName
                                };
                        }));
                  setOptions(function (param) {
                        return results;
                      });
                  setLoading(function (param) {
                        return false;
                      });
                  return ;
                }
                setError(function (param) {
                      return "No users found.";
                    });
                setLoading(function (param) {
                      return false;
                    });
                return ;
              }
              console.error(res._0);
              setError(function (param) {
                    return "Something went wrong. Please try again.";
                  });
              setLoading(function (param) {
                    return false;
                  });
            }), undefined, undefined);
    };
    if (debounce === undefined) {
      return search$1(value);
    }
    var timer = debounceTimerRef.current;
    if (!(timer == null)) {
      clearTimeout(timer);
    }
    debounceTimerRef.current = setTimeout((function () {
            search$1(value);
          }), debounce);
  };
  React.useEffect((function () {
          var accountId = CS_NonemptyStrings$Util.AccountId.fromPrimitive(selectedId);
          if (accountId.TAG === "Ok") {
            $$fetch$1(RelayEnv$Thick.environment, {
                  accountId: accountId._0,
                  brandFamily: brandFamily
                }, (function (res) {
                    if (res.TAG === "Ok") {
                      var match = res._0.bfcustUsers;
                      if (match === undefined) {
                        return ;
                      }
                      var results = Belt_Array.keepMap(match.nodes, (function (node) {
                              var email = node.email;
                              var niceName = email !== undefined ? node.fullName + " <" + CS_Emails$Util.Email.toString(Caml_option.valFromOption(email)) + "> [" + CS_LoyaltyAndCruiselineNumbers$Util.LoyaltyNumber.toString(node.loyaltyNumber) + "]" : node.fullName + " [" + CS_LoyaltyAndCruiselineNumbers$Util.LoyaltyNumber.toString(node.loyaltyNumber) + "]";
                              return {
                                      primitive: CS_NonemptyStrings$Util.AccountId.toString(node.accountId),
                                      key: CS_NonemptyStrings$Util.AccountId.toString(node.accountId),
                                      niceName: niceName
                                    };
                            }));
                      setOptions(function (param) {
                            return results;
                          });
                      return ;
                    }
                    console.error(res._0);
                  }), undefined, undefined);
          }
          
        }), [selectedId]);
  return JsxRuntime.jsx(BSPInputs$Thick.SelectSearch.make, {
              view: view,
              placeholder: props.placeholder,
              label: props.label,
              helperText: props.helperText,
              size: size,
              required: required,
              disabled: disabled,
              fullWidth: fullWidth,
              width: props.width,
              loading: match$2[0],
              setLoading: setLoading,
              input: input,
              options: match$1[0],
              clearOptions: clearOptions,
              search: search,
              overrideErrorText: overrideErrorText,
              actionButton: props.actionButton
            });
}

function CurrentUserSearchInput$Optional(props) {
  var debounce = props.debounce;
  var __overrideErrorText = props.overrideErrorText;
  var __claimedAndPrefilledOnly = props.claimedAndPrefilledOnly;
  var brandFamily = props.brandFamily;
  var input = props.input;
  var __fullWidth = props.fullWidth;
  var __disabled = props.disabled;
  var __required = props.required;
  var __size = props.size;
  var view = props.view;
  var size = __size !== undefined ? __size : "md";
  var required = __required !== undefined ? __required : false;
  var disabled = __disabled !== undefined ? __disabled : false;
  var fullWidth = __fullWidth !== undefined ? __fullWidth : false;
  var claimedAndPrefilledOnly = __claimedAndPrefilledOnly !== undefined ? __claimedAndPrefilledOnly : true;
  var overrideErrorText = __overrideErrorText !== undefined ? __overrideErrorText : "Please search and select";
  var match;
  match = view.TAG === "Direct" ? HopperState$Util.Observable.FormField.use(view._0, input, undefined) : HopperState$Util.Observable.FormField.useOption(view._0, input, view._1, undefined, undefined);
  var selectedId = match[0].value;
  var match$1 = React.useState(function () {
        return [];
      });
  var setOptions = match$1[1];
  var createFilter = function (s) {
    var accountStatus = claimedAndPrefilledOnly ? ({
          in: [
            "CLAIMED",
            "PREFILLED"
          ]
        }) : undefined;
    return {
            brandFamilySlug: {
              equalTo: CS_Slugs$Util.BrandFamilySlug.toString(brandFamily)
            },
            accountStatus: accountStatus,
            or: [
              {
                fullName: {
                  includesInsensitive: s
                }
              },
              {
                emailString: {
                  includesInsensitive: s
                }
              },
              {
                loyaltyNumString: {
                  includesInsensitive: s
                }
              }
            ]
          };
  };
  var match$2 = React.useState(function () {
        return false;
      });
  var setLoading = match$2[1];
  var match$3 = React.useState(function () {
        
      });
  var setError = match$3[1];
  var clearOptions = function () {
    setOptions(function (param) {
          return [];
        });
  };
  var debounceTimerRef = React.useRef(null);
  var search = function (value) {
    setLoading(function (param) {
          return true;
        });
    setOptions(function (param) {
          return [];
        });
    setError(function (param) {
          
        });
    var search$1 = function (value) {
      $$fetch(RelayEnv$Thick.environment, {
            brandFamily: brandFamily,
            filter: createFilter(value)
          }, (function (res) {
              if (res.TAG === "Ok") {
                var match = res._0.bfcustUsers;
                if (match !== undefined) {
                  var results = Belt_Array.keepMap(match.nodes, (function (node) {
                          var email = node.email;
                          var niceName = email !== undefined ? node.fullName + " <" + CS_Emails$Util.Email.toString(Caml_option.valFromOption(email)) + "> [" + CS_LoyaltyAndCruiselineNumbers$Util.LoyaltyNumber.toString(node.loyaltyNumber) + "]" : node.fullName + " [" + CS_LoyaltyAndCruiselineNumbers$Util.LoyaltyNumber.toString(node.loyaltyNumber) + "]";
                          return {
                                  primitive: CS_NonemptyStrings$Util.AccountId.toString(node.accountId),
                                  key: CS_NonemptyStrings$Util.AccountId.toString(node.accountId),
                                  niceName: niceName
                                };
                        }));
                  setOptions(function (param) {
                        return results;
                      });
                  setLoading(function (param) {
                        return false;
                      });
                  return ;
                }
                setError(function (param) {
                      return "No users found.";
                    });
                setLoading(function (param) {
                      return false;
                    });
                return ;
              }
              console.error(res._0);
              setError(function (param) {
                    return "Something went wrong. Please try again.";
                  });
              setLoading(function (param) {
                    return false;
                  });
            }), undefined, undefined);
    };
    if (debounce === undefined) {
      return search$1(value);
    }
    var timer = debounceTimerRef.current;
    if (!(timer == null)) {
      clearTimeout(timer);
    }
    debounceTimerRef.current = setTimeout((function () {
            search$1(value);
          }), debounce);
  };
  React.useEffect((function () {
          var accountId = CS_NonemptyStrings$Util.AccountId.fromPrimitive(Belt_Option.getWithDefault(selectedId, ""));
          if (accountId.TAG === "Ok") {
            $$fetch$1(RelayEnv$Thick.environment, {
                  accountId: accountId._0,
                  brandFamily: brandFamily
                }, (function (res) {
                    if (res.TAG === "Ok") {
                      var match = res._0.bfcustUsers;
                      if (match === undefined) {
                        return ;
                      }
                      var results = Belt_Array.keepMap(match.nodes, (function (node) {
                              var email = node.email;
                              var niceName = email !== undefined ? node.fullName + " <" + CS_Emails$Util.Email.toString(Caml_option.valFromOption(email)) + "> [" + CS_LoyaltyAndCruiselineNumbers$Util.LoyaltyNumber.toString(node.loyaltyNumber) + "]" : node.fullName + " [" + CS_LoyaltyAndCruiselineNumbers$Util.LoyaltyNumber.toString(node.loyaltyNumber) + "]";
                              return {
                                      primitive: CS_NonemptyStrings$Util.AccountId.toString(node.accountId),
                                      key: CS_NonemptyStrings$Util.AccountId.toString(node.accountId),
                                      niceName: niceName
                                    };
                            }));
                      setOptions(function (param) {
                            return results;
                          });
                      return ;
                    }
                    console.error(res._0);
                  }), undefined, undefined);
          }
          
        }), [selectedId]);
  return JsxRuntime.jsx(BSPInputs$Thick.SelectSearch.Optional.make, {
              view: view,
              placeholder: props.placeholder,
              label: props.label,
              helperText: props.helperText,
              size: size,
              required: required,
              disabled: disabled,
              fullWidth: fullWidth,
              width: props.width,
              loading: match$2[0],
              setLoading: setLoading,
              input: input,
              options: match$1[0],
              clearOptions: clearOptions,
              search: search,
              overrideErrorText: overrideErrorText,
              actionButton: props.actionButton
            });
}

var Optional = {
  make: CurrentUserSearchInput$Optional
};

var make = CurrentUserSearchInput;

exports.Query = Query;
exports.Query2 = Query2;
exports.make = make;
exports.Optional = Optional;
/* use Not a pure module */
