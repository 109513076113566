// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var ReactRelay = require("react-relay");
var CS_Make$Util = require("util/src/CustomScalars/CS_Make.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");

function makeRefetchVariables(brandFamily, cabinNumber, voyage) {
  return {
          brandFamily: brandFamily,
          cabinNumber: cabinNumber,
          voyage: voyage
        };
}

var Types = {
  makeRefetchVariables: makeRefetchVariables
};

var variablesConverter = {"__root":{"voyage":{"c":"Util.CustomScalars.VoyageSlug.Exn"},"cabinNumber":{"c":"Util.CustomScalars.CabinNumber.Exn"},"brandFamily":{"c":"Util.CustomScalars.BrandFamilySlug.Exn"}}};

var variablesConverterMap = {
  "Util.CustomScalars.BrandFamilySlug.Exn": CS_Slugs$Util.BrandFamilySlug.Exn.serialize,
  "Util.CustomScalars.CabinNumber.Exn": CS_NonemptyStrings$Util.CabinNumber.Exn.serialize,
  "Util.CustomScalars.VoyageSlug.Exn": CS_Slugs$Util.VoyageSlug.Exn.serialize
};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, variablesConverterMap, undefined);
}

var wrapResponseConverter = {"__root":{"cabins_nodes_voyageCategorySlug":{"c":"Util.CustomScalars.CabinCategorySlug.Exn"},"cabins_nodes_occRange_start_value":{"b":""},"cabins_nodes_occRange_end_value":{"b":""},"cabins_nodes_holdExpiresAt":{"c":"Util.CustomScalars.SQLDateTime.Exn"},"cabins_nodes_deckNumber":{"c":"Util.CustomScalars.DeckNumber.Exn"},"cabins_nodes_cabinNumber":{"c":"Util.CustomScalars.CabinNumber.Exn"},"cabins_nodes_booking_nodes_bookingVersion_nodes_bookingVersionNumber":{"b":""},"cabins_nodes_booking_nodes_bookingId":{"c":"Util.CustomScalars.BookingId.Exn"}}};

var wrapResponseConverterMap = {
  "Util.CustomScalars.BookingId.Exn": CS_NonemptyStrings$Util.BookingId.Exn.serialize,
  "Util.CustomScalars.CabinNumber.Exn": CS_NonemptyStrings$Util.CabinNumber.Exn.serialize,
  "Util.CustomScalars.DeckNumber.Exn": CS_NonemptyStrings$Util.DeckNumber.Exn.serialize,
  "Util.CustomScalars.SQLDateTime.Exn": CS_Make$Util.Dateable.Plain.Exn.serialize,
  "Util.CustomScalars.CabinCategorySlug.Exn": CS_Slugs$Util.CabinCategorySlug.Exn.serialize
};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, wrapResponseConverterMap, null);
}

var responseConverter = {"__root":{"cabins_nodes_voyageCategorySlug":{"c":"Util.CustomScalars.CabinCategorySlug.Exn"},"cabins_nodes_occRange_start_value":{"b":""},"cabins_nodes_occRange_end_value":{"b":""},"cabins_nodes_holdExpiresAt":{"c":"Util.CustomScalars.SQLDateTime.Exn"},"cabins_nodes_deckNumber":{"c":"Util.CustomScalars.DeckNumber.Exn"},"cabins_nodes_cabinNumber":{"c":"Util.CustomScalars.CabinNumber.Exn"},"cabins_nodes_booking_nodes_bookingVersion_nodes_bookingVersionNumber":{"b":""},"cabins_nodes_booking_nodes_bookingId":{"c":"Util.CustomScalars.BookingId.Exn"}}};

var responseConverterMap = {
  "Util.CustomScalars.BookingId.Exn": CS_NonemptyStrings$Util.BookingId.Exn.parse,
  "Util.CustomScalars.CabinNumber.Exn": CS_NonemptyStrings$Util.CabinNumber.Exn.parse,
  "Util.CustomScalars.DeckNumber.Exn": CS_NonemptyStrings$Util.DeckNumber.Exn.parse,
  "Util.CustomScalars.SQLDateTime.Exn": CS_Make$Util.Dateable.Plain.Exn.parse,
  "Util.CustomScalars.CabinCategorySlug.Exn": CS_Slugs$Util.CabinCategorySlug.Exn.parse
};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, responseConverterMap, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: variablesConverterMap,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: wrapResponseConverterMap,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: responseConverterMap,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse
};

var Utils = {};

var node = ((function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "brandFamily"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cabinNumber"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "voyage"
},
v3 = {
  "kind": "Literal",
  "name": "first",
  "value": 1
},
v4 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "brandFamilySlug",
        "variableName": "brandFamily"
      },
      {
        "kind": "Variable",
        "name": "cabinNumber",
        "variableName": "cabinNumber"
      },
      {
        "kind": "Variable",
        "name": "voyageSlug",
        "variableName": "voyage"
      }
    ],
    "kind": "ObjectValue",
    "name": "condition"
  },
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voyageCategorySlug",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deckNumber",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cabinNumber",
  "storageKey": null
},
v8 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "inclusive",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
],
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "IntRange",
  "kind": "LinkedField",
  "name": "occRange",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "IntRangeBound",
      "kind": "LinkedField",
      "name": "start",
      "plural": false,
      "selections": (v8/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "IntRangeBound",
      "kind": "LinkedField",
      "name": "end",
      "plural": false,
      "selections": (v8/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voyageSegment",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "heldByBfcust",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "heldByTeamUserEmail",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "holdExpiresAt",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isHeld",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "loyaltyNumString",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emailString",
  "storageKey": null
},
v18 = [
  {
    "kind": "Literal",
    "name": "condition",
    "value": {
      "lifecycleStatus": "ACTIVE"
    }
  },
  (v3/*: any*/)
],
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bookingId",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bookingVersionNumber",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ChangeCabinModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "CabinsConnection",
        "kind": "LinkedField",
        "name": "cabins",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Cabin",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "BfcustUser",
                "kind": "LinkedField",
                "name": "heldByBfcustUser",
                "plural": false,
                "selections": [
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v18/*: any*/),
                "concreteType": "BookingsConnection",
                "kind": "LinkedField",
                "name": "booking",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Booking",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      (v19/*: any*/),
                      {
                        "alias": null,
                        "args": (v18/*: any*/),
                        "concreteType": "BookingVersionsConnection",
                        "kind": "LinkedField",
                        "name": "bookingVersion",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "BookingVersion",
                            "kind": "LinkedField",
                            "name": "nodes",
                            "plural": true,
                            "selections": [
                              (v20/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "bookingVersion(condition:{\"lifecycleStatus\":\"ACTIVE\"},first:1)"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "booking(condition:{\"lifecycleStatus\":\"ACTIVE\"},first:1)"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "ChangeCabinModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "CabinsConnection",
        "kind": "LinkedField",
        "name": "cabins",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Cabin",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "BfcustUser",
                "kind": "LinkedField",
                "name": "heldByBfcustUser",
                "plural": false,
                "selections": [
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v21/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v18/*: any*/),
                "concreteType": "BookingsConnection",
                "kind": "LinkedField",
                "name": "booking",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Booking",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      (v19/*: any*/),
                      {
                        "alias": null,
                        "args": (v18/*: any*/),
                        "concreteType": "BookingVersionsConnection",
                        "kind": "LinkedField",
                        "name": "bookingVersion",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "BookingVersion",
                            "kind": "LinkedField",
                            "name": "nodes",
                            "plural": true,
                            "selections": [
                              (v20/*: any*/),
                              (v21/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "bookingVersion(condition:{\"lifecycleStatus\":\"ACTIVE\"},first:1)"
                      },
                      (v21/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "booking(condition:{\"lifecycleStatus\":\"ACTIVE\"},first:1)"
              },
              (v21/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e7ad5165bf524f7718a90ce865615d7f",
    "id": null,
    "metadata": {},
    "name": "ChangeCabinModalQuery",
    "operationKind": "query",
    "text": "query ChangeCabinModalQuery(\n  $brandFamily: BrandFamilySlug!\n  $voyage: VoyageSlug!\n  $cabinNumber: CabinNumber!\n) {\n  cabins(condition: {brandFamilySlug: $brandFamily, voyageSlug: $voyage, cabinNumber: $cabinNumber}, first: 1) {\n    nodes {\n      voyageCategorySlug\n      deckNumber\n      cabinNumber\n      occRange {\n        start {\n          inclusive\n          value\n        }\n        end {\n          inclusive\n          value\n        }\n      }\n      voyageSegment\n      heldByBfcust\n      heldByTeamUserEmail\n      holdExpiresAt\n      isHeld\n      heldByBfcustUser {\n        loyaltyNumString\n        fullName\n        emailString\n        id\n      }\n      booking(condition: {lifecycleStatus: ACTIVE}, first: 1) {\n        nodes {\n          bookingId\n          bookingVersion(condition: {lifecycleStatus: ACTIVE}, first: 1) {\n            nodes {\n              bookingVersionNumber\n              id\n            }\n          }\n          id\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})());

function load(environment, variables, fetchPolicy, fetchKey, networkCacheConfig) {
  return ReactRelay.loadQuery(environment, node, convertVariables(variables), {
              fetchKey: fetchKey,
              fetchPolicy: fetchPolicy,
              networkCacheConfig: networkCacheConfig
            });
}

function queryRefToObservable(token) {
  return Caml_option.nullable_to_opt(token.source);
}

function queryRefToPromise(token) {
  return new Promise((function (resolve, param) {
                var o = queryRefToObservable(token);
                if (o !== undefined) {
                  Caml_option.valFromOption(o).subscribe({
                        complete: (function () {
                            resolve({
                                  TAG: "Ok",
                                  _0: undefined
                                });
                          })
                      });
                  return ;
                } else {
                  return resolve({
                              TAG: "Error",
                              _0: undefined
                            });
                }
              }));
}

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
exports.load = load;
exports.queryRefToObservable = queryRefToObservable;
exports.queryRefToPromise = queryRefToPromise;
/* node Not a pure module */
