// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var JsxRuntime = require("react/jsx-runtime");
var MergeRundown$Thick = require("../components/merge-accounts/MergeRundown.bs.js");
var Typography = require("@mui/joy/Typography").default;
var AccountDetailFetcher$Thick = require("../components/merge-accounts/AccountDetailFetcher.bs.js");
var MergeAccountSelection$Thick = require("../components/merge-accounts/MergeAccountSelection.bs.js");

function MergeAccountsPage(props) {
  var state = props.state;
  var brandFamily = props.brandFamily;
  return JsxRuntime.jsxs(Stack, {
              spacing: 2,
              children: [
                JsxRuntime.jsx(Typography, {
                      level: "h3",
                      component: "h1",
                      children: "Merge Accounts"
                    }),
                JsxRuntime.jsx(Stack, {
                      direction: "row",
                      spacing: 1,
                      children: Caml_option.some(JsxRuntime.jsx(Sheet, {
                                sx: {
                                  p: 3,
                                  borderRadius: "sm"
                                },
                                children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                          direction: "column",
                                          alignItems: "flex-start",
                                          spacing: 2,
                                          children: [
                                            JsxRuntime.jsx(MergeAccountSelection$Thick.make, {
                                                  brandFamily: brandFamily,
                                                  state: state
                                                }),
                                            JsxRuntime.jsx(AccountDetailFetcher$Thick.make, {
                                                  view: state,
                                                  brandFamily: brandFamily
                                                }),
                                            JsxRuntime.jsx(MergeRundown$Thick.make, {
                                                  view: state,
                                                  brandFamily: brandFamily
                                                })
                                          ]
                                        }))
                              }))
                    })
              ]
            });
}

var make = MergeAccountsPage;

exports.make = make;
/* @mui/joy/Sheet Not a pure module */
