// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var DateFns = require("date-fns");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var BDMenu$Thick = require("./atoms/BDMenu.bs.js");
var Common$Thick = require("../../utils/Common.bs.js");
var DateFns$Util = require("util/src/DateFns.bs.js");
var Chip = require("@mui/joy/Chip").default;
var Menu = require("@mui/joy/Menu").default;
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var Avatar = require("@mui/joy/Avatar").default;
var Divider = require("@mui/joy/Divider").default;
var Tooltip = require("@mui/joy/Tooltip").default;
var HopperState$Util = require("util/src/HopperState.bs.js");
var Dropdown = require("@mui/joy/Dropdown").default;
var ListItem = require("@mui/joy/ListItem").default;
var MenuItem = require("@mui/joy/MenuItem").default;
var AddBerthBtn$Thick = require("./drafts/AddBerthBtn.bs.js");
var BookingDiff$Thick = require("../../utils/BookingDiff.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var PreviewModal$Thick = require("./drafts/PreviewModal.bs.js");
var IconButton = require("@mui/joy/IconButton").default;
var MenuButton = require("@mui/joy/MenuButton").default;
var Typography = require("@mui/joy/Typography").default;
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var ListDivider = require("@mui/joy/ListDivider").default;
var BDContentBlock$Thick = require("./molecules/BDContentBlock.bs.js");
var PatchPaxDrawer$Thick = require("./drafts/PatchPaxDrawer.bs.js");
var PreviewChanges$Thick = require("./drafts/PreviewChanges.bs.js");
var ManageBookingState$Thick = require("../../utils/ManageBookingState.bs.js");
var MoreHoriz = require("@mui/icons-material/MoreHoriz").default;
var AttachReplaceInviteModal$Thick = require("./drafts/AttachReplaceInviteModal.bs.js");
var InfoOutlined = require("@mui/icons-material/InfoOutlined").default;
var AttachReplaceExistingModal$Thick = require("./drafts/AttachReplaceExistingModal.bs.js");
var ResendNewPaxInvitationModal$Thick = require("./drafts/ResendNewPaxInvitationModal.bs.js");
var RevokeNewPaxInvitationModal$Thick = require("./drafts/RevokeNewPaxInvitationModal.bs.js");
var AttachReplaceNewConnectedModal$Thick = require("./drafts/AttachReplaceNewConnectedModal.bs.js");
var BDBerthsPassengersQuery_graphql$Thick = require("../../__generated__/BDBerthsPassengersQuery_graphql.bs.js");

var convertVariables = BDBerthsPassengersQuery_graphql$Thick.Internal.convertVariables;

var convertResponse = BDBerthsPassengersQuery_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = BDBerthsPassengersQuery_graphql$Thick.Internal.convertWrapRawResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, BDBerthsPassengersQuery_graphql$Thick.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, BDBerthsPassengersQuery_graphql$Thick.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(BDBerthsPassengersQuery_graphql$Thick.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(BDBerthsPassengersQuery_graphql$Thick.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(BDBerthsPassengersQuery_graphql$Thick.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(BDBerthsPassengersQuery_graphql$Thick.node, convertVariables);

var FetchAllBfCustUsers_accountStatus_decode = BDBerthsPassengersQuery_graphql$Thick.Utils.accountStatus_decode;

var FetchAllBfCustUsers_accountStatus_fromString = BDBerthsPassengersQuery_graphql$Thick.Utils.accountStatus_fromString;

var FetchAllBfCustUsers_billingKind_decode = BDBerthsPassengersQuery_graphql$Thick.Utils.billingKind_decode;

var FetchAllBfCustUsers_billingKind_fromString = BDBerthsPassengersQuery_graphql$Thick.Utils.billingKind_fromString;

var FetchAllBfCustUsers_bookingSessionPassengerType_decode = BDBerthsPassengersQuery_graphql$Thick.Utils.bookingSessionPassengerType_decode;

var FetchAllBfCustUsers_bookingSessionPassengerType_fromString = BDBerthsPassengersQuery_graphql$Thick.Utils.bookingSessionPassengerType_fromString;

var FetchAllBfCustUsers_brandfamRole_decode = BDBerthsPassengersQuery_graphql$Thick.Utils.brandfamRole_decode;

var FetchAllBfCustUsers_brandfamRole_fromString = BDBerthsPassengersQuery_graphql$Thick.Utils.brandfamRole_fromString;

var FetchAllBfCustUsers_custSignonStatus_decode = BDBerthsPassengersQuery_graphql$Thick.Utils.custSignonStatus_decode;

var FetchAllBfCustUsers_custSignonStatus_fromString = BDBerthsPassengersQuery_graphql$Thick.Utils.custSignonStatus_fromString;

var FetchAllBfCustUsers_docGender_decode = BDBerthsPassengersQuery_graphql$Thick.Utils.docGender_decode;

var FetchAllBfCustUsers_docGender_fromString = BDBerthsPassengersQuery_graphql$Thick.Utils.docGender_fromString;

var FetchAllBfCustUsers_elevatorProximity_decode = BDBerthsPassengersQuery_graphql$Thick.Utils.elevatorProximity_decode;

var FetchAllBfCustUsers_elevatorProximity_fromString = BDBerthsPassengersQuery_graphql$Thick.Utils.elevatorProximity_fromString;

var FetchAllBfCustUsers_invitationType_decode = BDBerthsPassengersQuery_graphql$Thick.Utils.invitationType_decode;

var FetchAllBfCustUsers_invitationType_fromString = BDBerthsPassengersQuery_graphql$Thick.Utils.invitationType_fromString;

var FetchAllBfCustUsers_lifecycleStatus_decode = BDBerthsPassengersQuery_graphql$Thick.Utils.lifecycleStatus_decode;

var FetchAllBfCustUsers_lifecycleStatus_fromString = BDBerthsPassengersQuery_graphql$Thick.Utils.lifecycleStatus_fromString;

var FetchAllBfCustUsers_longitudinality_decode = BDBerthsPassengersQuery_graphql$Thick.Utils.longitudinality_decode;

var FetchAllBfCustUsers_longitudinality_fromString = BDBerthsPassengersQuery_graphql$Thick.Utils.longitudinality_fromString;

var FetchAllBfCustUsers_manifestPaxReconciliationStatus_decode = BDBerthsPassengersQuery_graphql$Thick.Utils.manifestPaxReconciliationStatus_decode;

var FetchAllBfCustUsers_manifestPaxReconciliationStatus_fromString = BDBerthsPassengersQuery_graphql$Thick.Utils.manifestPaxReconciliationStatus_fromString;

var FetchAllBfCustUsers_paxDataStatus_decode = BDBerthsPassengersQuery_graphql$Thick.Utils.paxDataStatus_decode;

var FetchAllBfCustUsers_paxDataStatus_fromString = BDBerthsPassengersQuery_graphql$Thick.Utils.paxDataStatus_fromString;

var FetchAllBfCustUsers_pmtDirection_decode = BDBerthsPassengersQuery_graphql$Thick.Utils.pmtDirection_decode;

var FetchAllBfCustUsers_pmtDirection_fromString = BDBerthsPassengersQuery_graphql$Thick.Utils.pmtDirection_fromString;

var FetchAllBfCustUsers_pmtLifecycleStatus_decode = BDBerthsPassengersQuery_graphql$Thick.Utils.pmtLifecycleStatus_decode;

var FetchAllBfCustUsers_pmtLifecycleStatus_fromString = BDBerthsPassengersQuery_graphql$Thick.Utils.pmtLifecycleStatus_fromString;

var FetchAllBfCustUsers_pmtMethod_decode = BDBerthsPassengersQuery_graphql$Thick.Utils.pmtMethod_decode;

var FetchAllBfCustUsers_pmtMethod_fromString = BDBerthsPassengersQuery_graphql$Thick.Utils.pmtMethod_fromString;

var FetchAllBfCustUsers_pmtProcessor_decode = BDBerthsPassengersQuery_graphql$Thick.Utils.pmtProcessor_decode;

var FetchAllBfCustUsers_pmtProcessor_fromString = BDBerthsPassengersQuery_graphql$Thick.Utils.pmtProcessor_fromString;

var FetchAllBfCustUsers_refundability_decode = BDBerthsPassengersQuery_graphql$Thick.Utils.refundability_decode;

var FetchAllBfCustUsers_refundability_fromString = BDBerthsPassengersQuery_graphql$Thick.Utils.refundability_fromString;

var FetchAllBfCustUsers_signonStatus_decode = BDBerthsPassengersQuery_graphql$Thick.Utils.signonStatus_decode;

var FetchAllBfCustUsers_signonStatus_fromString = BDBerthsPassengersQuery_graphql$Thick.Utils.signonStatus_fromString;

var FetchAllBfCustUsers_subtotalCategory_decode = BDBerthsPassengersQuery_graphql$Thick.Utils.subtotalCategory_decode;

var FetchAllBfCustUsers_subtotalCategory_fromString = BDBerthsPassengersQuery_graphql$Thick.Utils.subtotalCategory_fromString;

var FetchAllBfCustUsers_versionProvenance_decode = BDBerthsPassengersQuery_graphql$Thick.Utils.versionProvenance_decode;

var FetchAllBfCustUsers_versionProvenance_fromString = BDBerthsPassengersQuery_graphql$Thick.Utils.versionProvenance_fromString;

var FetchAllBfCustUsers = {
  accountStatus_decode: FetchAllBfCustUsers_accountStatus_decode,
  accountStatus_fromString: FetchAllBfCustUsers_accountStatus_fromString,
  billingKind_decode: FetchAllBfCustUsers_billingKind_decode,
  billingKind_fromString: FetchAllBfCustUsers_billingKind_fromString,
  bookingSessionPassengerType_decode: FetchAllBfCustUsers_bookingSessionPassengerType_decode,
  bookingSessionPassengerType_fromString: FetchAllBfCustUsers_bookingSessionPassengerType_fromString,
  brandfamRole_decode: FetchAllBfCustUsers_brandfamRole_decode,
  brandfamRole_fromString: FetchAllBfCustUsers_brandfamRole_fromString,
  custSignonStatus_decode: FetchAllBfCustUsers_custSignonStatus_decode,
  custSignonStatus_fromString: FetchAllBfCustUsers_custSignonStatus_fromString,
  docGender_decode: FetchAllBfCustUsers_docGender_decode,
  docGender_fromString: FetchAllBfCustUsers_docGender_fromString,
  elevatorProximity_decode: FetchAllBfCustUsers_elevatorProximity_decode,
  elevatorProximity_fromString: FetchAllBfCustUsers_elevatorProximity_fromString,
  invitationType_decode: FetchAllBfCustUsers_invitationType_decode,
  invitationType_fromString: FetchAllBfCustUsers_invitationType_fromString,
  lifecycleStatus_decode: FetchAllBfCustUsers_lifecycleStatus_decode,
  lifecycleStatus_fromString: FetchAllBfCustUsers_lifecycleStatus_fromString,
  longitudinality_decode: FetchAllBfCustUsers_longitudinality_decode,
  longitudinality_fromString: FetchAllBfCustUsers_longitudinality_fromString,
  manifestPaxReconciliationStatus_decode: FetchAllBfCustUsers_manifestPaxReconciliationStatus_decode,
  manifestPaxReconciliationStatus_fromString: FetchAllBfCustUsers_manifestPaxReconciliationStatus_fromString,
  paxDataStatus_decode: FetchAllBfCustUsers_paxDataStatus_decode,
  paxDataStatus_fromString: FetchAllBfCustUsers_paxDataStatus_fromString,
  pmtDirection_decode: FetchAllBfCustUsers_pmtDirection_decode,
  pmtDirection_fromString: FetchAllBfCustUsers_pmtDirection_fromString,
  pmtLifecycleStatus_decode: FetchAllBfCustUsers_pmtLifecycleStatus_decode,
  pmtLifecycleStatus_fromString: FetchAllBfCustUsers_pmtLifecycleStatus_fromString,
  pmtMethod_decode: FetchAllBfCustUsers_pmtMethod_decode,
  pmtMethod_fromString: FetchAllBfCustUsers_pmtMethod_fromString,
  pmtProcessor_decode: FetchAllBfCustUsers_pmtProcessor_decode,
  pmtProcessor_fromString: FetchAllBfCustUsers_pmtProcessor_fromString,
  refundability_decode: FetchAllBfCustUsers_refundability_decode,
  refundability_fromString: FetchAllBfCustUsers_refundability_fromString,
  signonStatus_decode: FetchAllBfCustUsers_signonStatus_decode,
  signonStatus_fromString: FetchAllBfCustUsers_signonStatus_fromString,
  subtotalCategory_decode: FetchAllBfCustUsers_subtotalCategory_decode,
  subtotalCategory_fromString: FetchAllBfCustUsers_subtotalCategory_fromString,
  versionProvenance_decode: FetchAllBfCustUsers_versionProvenance_decode,
  versionProvenance_fromString: FetchAllBfCustUsers_versionProvenance_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function BDBerthsPassengers$BPMenu(props) {
  return JsxRuntime.jsxs(BDMenu$Thick.make, {
              text: "More Actions",
              disabled: true,
              children: [
                JsxRuntime.jsx(MenuItem, {
                      children: "Action 1"
                    }),
                JsxRuntime.jsx(MenuItem, {
                      children: "Action 2"
                    }),
                JsxRuntime.jsx(MenuItem, {
                      children: "Action 3"
                    })
              ]
            });
}

var BPMenu = {
  make: BDBerthsPassengers$BPMenu
};

function BDBerthsPassengers$PaxMenu(props) {
  var setPatchPaxDrawerOpen = props.setPatchPaxDrawerOpen;
  var accountId = props.accountId;
  var generatePreviewFromDiff = props.generatePreviewFromDiff;
  var brandFamily = props.brandFamily;
  var shouldAttachToIdx = props.shouldAttachToIdx;
  var blockDrop = props.blockDrop;
  var name = props.name;
  var versionUrl = props.versionUrl;
  var billPayRec = props.billPayRec;
  var idx = props.idx;
  var status = props.status;
  var match = React.useState(function () {
        return "drop";
      });
  var setOpt = match[1];
  var opt = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setAttachReplaceExistingModalOpen = match$1[1];
  var match$2 = React.useState(function () {
        return false;
      });
  var setAttachReplaceInviteModalOpen = match$2[1];
  var match$3 = React.useState(function () {
        return "attachExisting";
      });
  var setAttachReplaceExistingAction = match$3[1];
  var match$4 = React.useState(function () {
        return false;
      });
  var setResendInvitationConfirmModalOpen = match$4[1];
  var match$5 = React.useState(function () {
        return false;
      });
  var setRevokeInvitationConfirmModalOpen = match$5[1];
  var match$6 = React.useState(function () {
        return false;
      });
  var setAttachReplaceNewConnectedModalOpen = match$6[1];
  var match$7 = React.useState(function () {
        return "attachNewConnected";
      });
  var setAttachReplaceNewConnectedAction = match$7[1];
  var match$8 = React.useState(function () {
        return "attachInvite";
      });
  var setAttachReplaceInviteAction = match$8[1];
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var diffStruct = BookingDiff$Thick.parse(url.search);
  var openAttachExistingModal = function () {
    setAttachReplaceExistingAction(function (param) {
          return "attachExisting";
        });
    setAttachReplaceExistingModalOpen(function (param) {
          return true;
        });
  };
  var openReplaceExistingModal = function () {
    setAttachReplaceExistingAction(function (param) {
          return "replaceExisting";
        });
    setAttachReplaceExistingModalOpen(function (param) {
          return true;
        });
  };
  var openAttachInviteModal = function () {
    setAttachReplaceInviteAction(function (param) {
          return "attachInvite";
        });
    setAttachReplaceInviteModalOpen(function (param) {
          return true;
        });
  };
  var openReplaceInviteModal = function () {
    setAttachReplaceInviteAction(function (param) {
          return "replaceInvite";
        });
    setAttachReplaceInviteModalOpen(function (param) {
          return true;
        });
  };
  var openAttachNewConnectedModal = function () {
    setAttachReplaceNewConnectedAction(function (param) {
          return "attachNewConnected";
        });
    setAttachReplaceNewConnectedModalOpen(function (param) {
          return true;
        });
  };
  var openReplaceNewConnectedModal = function () {
    setAttachReplaceNewConnectedAction(function (param) {
          return "replaceNewConnected";
        });
    setAttachReplaceNewConnectedModalOpen(function (param) {
          return true;
        });
  };
  var match$9 = PreviewChanges$Thick.useQuery(generatePreviewFromDiff, diffStruct, (function () {
          
        }));
  var clearPreviewFields = match$9.clearPreviewFields;
  var setApplyDiff = match$9.setApplyDiff;
  var generatePreview = match$9.generatePreview;
  var dropDetachOnClick = function (action) {
    setOpt(function (param) {
          return action;
        });
    var dropDetachApplyDiff = action === "detach" ? BookingDiff$Thick.Alter.detachSingleBerth(diffStruct, idx) : BookingDiff$Thick.Alter.dropSingleBerth(diffStruct, idx);
    setApplyDiff(function (param) {
          return dropDetachApplyDiff;
        });
    generatePreview(dropDetachApplyDiff);
  };
  var match$10 = React.useMemo((function () {
          if (opt === "detach") {
            var name$1 = name !== undefined ? name + " from this berth" : "a passenger from this berth";
            return [
                    "Detach Passenger",
                    "You are proposing to detach " + name$1 + ". Please carefully review the changes below."
                  ];
          }
          var name$2 = name !== undefined ? "the berth occupied by " + name : "a berth";
          return [
                  "Drop Berth",
                  "You are proposing to drop " + name$2 + " from this booking. Please carefully review the changes below."
                ];
        }), [opt]);
  var tmp;
  if (typeof status !== "object") {
    tmp = true;
  } else if (status.TAG === "Status") {
    var tmp$1 = status._0;
    tmp = (tmp$1 === "INCOMPLETE" || tmp$1 === "CONFIRMED" || tmp$1 === "INVITED") && tmp$1 === "INVITED" ? true : false;
  } else {
    tmp = status._0 === "invite";
  }
  var cannotMakePax1 = tmp || props.reorderedIdx === 1;
  if (!props.isActive) {
    return null;
  }
  if (props.snapshot) {
    return null;
  }
  if (!props.canManage) {
    return null;
  }
  var tmp$2;
  var exit = 0;
  if (typeof status !== "object") {
    tmp$2 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsx(ListDivider, {}),
            JsxRuntime.jsx(ListItem, {
                  children: Caml_option.some(JsxRuntime.jsx(Typography, {
                            level: "body-xs",
                            textColor: "neutral.600",
                            textTransform: "uppercase",
                            fontWeight: "700",
                            children: "Attach a Passenger"
                          }))
                }),
            JsxRuntime.jsx(MenuItem, {
                  children: "Existing Passenger",
                  onClick: (function (param) {
                      openAttachExistingModal();
                    })
                }),
            JsxRuntime.jsx(MenuItem, {
                  children: "Via Invitation",
                  onClick: (function (param) {
                      openAttachInviteModal();
                    })
                }),
            JsxRuntime.jsx(MenuItem, {
                  children: "Create New Connected Account",
                  onClick: (function (param) {
                      openAttachNewConnectedModal();
                    })
                })
          ]
        });
  } else if (status.TAG === "Status") {
    var tmp$3 = status._0;
    if ((tmp$3 === "INCOMPLETE" || tmp$3 === "CONFIRMED" || tmp$3 === "INVITED") && tmp$3 === "INVITED" && accountId !== undefined) {
      tmp$2 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
            children: [
              JsxRuntime.jsx(MenuItem, {
                    children: "Resend Invitation",
                    onClick: (function (param) {
                        setResendInvitationConfirmModalOpen(function (param) {
                              return true;
                            });
                      })
                  }),
              JsxRuntime.jsx(MenuItem, {
                    children: "Revoke Invitation",
                    disabled: blockDrop,
                    onClick: (function (param) {
                        setRevokeInvitationConfirmModalOpen(function (param) {
                              return true;
                            });
                      })
                  })
            ]
          });
    } else {
      exit = 1;
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp$2 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsx(MenuItem, {
                  children: "Update Passenger Details",
                  onClick: (function (param) {
                      setPatchPaxDrawerOpen(function (param) {
                            return true;
                          });
                    })
                }),
            JsxRuntime.jsx(ListDivider, {}),
            JsxRuntime.jsx(ListItem, {
                  children: Caml_option.some(JsxRuntime.jsx(Typography, {
                            level: "body-xs",
                            textColor: "neutral.500",
                            textTransform: "uppercase",
                            fontWeight: "700",
                            children: "Replace Passenger"
                          }))
                }),
            JsxRuntime.jsx(MenuItem, {
                  children: "With an Existing Passenger",
                  onClick: (function (param) {
                      openReplaceExistingModal();
                    })
                }),
            JsxRuntime.jsx(MenuItem, {
                  children: "Via Invitation",
                  onClick: (function (param) {
                      openReplaceInviteModal();
                    })
                }),
            JsxRuntime.jsx(MenuItem, {
                  children: "With a New Connected Account",
                  onClick: (function (param) {
                      openReplaceNewConnectedModal();
                    })
                }),
            JsxRuntime.jsx(ListDivider, {}),
            JsxRuntime.jsx(MenuItem, {
                  children: "Detach Passenger",
                  disabled: blockDrop,
                  onClick: (function (param) {
                      dropDetachOnClick("detach");
                    })
                })
          ]
        });
  }
  var tmp$4;
  tmp$4 = typeof status !== "object" ? shouldAttachToIdx : idx;
  var tmp$5;
  tmp$5 = typeof status !== "object" ? shouldAttachToIdx : idx;
  var tmp$6;
  tmp$6 = typeof status !== "object" ? shouldAttachToIdx : idx;
  var tmp$7;
  if (accountId !== undefined) {
    var id = Caml_option.valFromOption(accountId);
    tmp$7 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsx(ResendNewPaxInvitationModal$Thick.make, {
                  open_: match$4[0],
                  refetch: props.refetch,
                  onClose: (function () {
                      setResendInvitationConfirmModalOpen(function (param) {
                            return false;
                          });
                    }),
                  accountId: id,
                  brandFamily: brandFamily,
                  invitee: name
                }),
            JsxRuntime.jsx(RevokeNewPaxInvitationModal$Thick.make, {
                  open_: match$5[0],
                  onClose: (function () {
                      setRevokeInvitationConfirmModalOpen(function (param) {
                            return false;
                          });
                    }),
                  accountId: id,
                  invitee: name,
                  generatePreviewFromDiff: generatePreviewFromDiff,
                  shouldAttachToIdx: idx,
                  billPayCurrent: billPayRec,
                  versionUrl: versionUrl
                })
          ]
        });
  } else {
    tmp$7 = null;
  }
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs(Dropdown, {
                      children: [
                        JsxRuntime.jsx(MenuButton, {
                              children: Caml_option.some(JsxRuntime.jsx(MoreHoriz, {})),
                              size: "md",
                              slotProps: {
                                root: {
                                  color: "neutral"
                                }
                              },
                              slots: {
                                root: IconButton
                              }
                            }),
                        JsxRuntime.jsxs(Menu, {
                              children: [
                                JsxRuntime.jsx(MenuItem, {
                                      children: "Drop Berth",
                                      disabled: blockDrop,
                                      onClick: (function (param) {
                                          dropDetachOnClick("drop");
                                        })
                                    }),
                                cannotMakePax1 ? null : JsxRuntime.jsx(MenuItem, {
                                        children: "Make Primary (pax1)",
                                        disabled: false,
                                        onClick: (function (param) {
                                            var makePax1Diff = BookingDiff$Thick.Alter.makePax1(diffStruct, idx);
                                            setApplyDiff(function (param) {
                                                  return makePax1Diff;
                                                });
                                            generatePreview(makePax1Diff);
                                          })
                                      }),
                                tmp$2
                              ],
                              size: "sm"
                            })
                      ]
                    }),
                JsxRuntime.jsx(PreviewModal$Thick.make, {
                      billPayCurrent: billPayRec,
                      billPayNew: match$9.billPayNew,
                      applyDiff: match$9.applyDiff,
                      title: match$10[0],
                      description: match$10[1],
                      onClose: (function () {
                          clearPreviewFields();
                        }),
                      open_: match$9.previewModalOpen,
                      versionUrl: versionUrl,
                      error: match$9.error
                    }),
                JsxRuntime.jsx(AttachReplaceExistingModal$Thick.make, {
                      open_: match$1[0],
                      onClose: (function () {
                          setAttachReplaceExistingModalOpen(function (param) {
                                return false;
                              });
                        }),
                      billPayCurrent: billPayRec,
                      versionUrl: versionUrl,
                      shouldAttachToIdx: tmp$4,
                      brandFamily: brandFamily,
                      generatePreviewFromDiff: generatePreviewFromDiff,
                      action: match$3[0],
                      name: name,
                      voyage: props.voyage
                    }),
                JsxRuntime.jsx(AttachReplaceInviteModal$Thick.make, {
                      open_: match$2[0],
                      onClose: (function () {
                          setAttachReplaceInviteModalOpen(function (param) {
                                return false;
                              });
                        }),
                      billPayCurrent: billPayRec,
                      versionUrl: versionUrl,
                      shouldAttachToIdx: tmp$5,
                      generatePreviewFromDiff: generatePreviewFromDiff,
                      action: match$8[0],
                      name: name
                    }),
                JsxRuntime.jsx(AttachReplaceNewConnectedModal$Thick.make, {
                      open_: match$6[0],
                      onClose: (function () {
                          setAttachReplaceNewConnectedModalOpen(function (param) {
                                return false;
                              });
                        }),
                      billPayCurrent: billPayRec,
                      brandFamily: brandFamily,
                      versionUrl: versionUrl,
                      shouldAttachToIdx: tmp$6,
                      generatePreviewFromDiff: generatePreviewFromDiff,
                      action: match$7[0],
                      name: name,
                      fragmentRefs: props.fragmentRefs,
                      countryFragmentRefs: props.countryFragmentRefs
                    }),
                tmp$7
              ]
            });
}

var PaxMenu = {
  make: BDBerthsPassengers$PaxMenu
};

function BDBerthsPassengers$BerthsPassengers$ContactCard(props) {
  var countryFragmentRefs = props.countryFragmentRefs;
  var voyage = props.voyage;
  var generatePreviewFromDiff = props.generatePreviewFromDiff;
  var brandFamily = props.brandFamily;
  var shouldBlockDrop = props.shouldBlockDrop;
  var versionUrl = props.versionUrl;
  var billPayRec = props.billPayRec;
  var passenger = ManageBookingState$Thick.paxViewByBerthNum(props.state, props.berthNum);
  var match = HopperState$Util.Observable.useComputed(passenger, (function (p) {
          var match = p.paxDataStatus;
          var tmp;
          tmp = typeof match !== "object" ? false : shouldBlockDrop;
          return [
                  p.idx,
                  p.reorderedIdx,
                  p.shouldAttachToIdx,
                  p.accountId,
                  p.paxDataStatus,
                  p.fullName,
                  p.firstName.value,
                  p.lastName.value,
                  p.email,
                  p.legalDocumentFirstName.value,
                  p.legalDocumentLastName.value,
                  p.legalDocumentBirthdate.value,
                  p.invitationExpiry,
                  p.invitationEmail,
                  p.paxReconciliationStatus,
                  p.cruiselineBookingNumber,
                  p.loyaltyNumber,
                  p.currentTierTitle,
                  p.numEligibleVoyages,
                  p.halMarinerNumber.value,
                  ManageBookingState$Thick.generateAvatarInitials(p),
                  tmp
                ];
        }), undefined, undefined);
  var halMarinerNumber = match[19];
  var currentTierTitle = match[17];
  var loyaltyNumber = match[16];
  var cruiselineBookingNumber = match[15];
  var paxReconciliationStatus = match[14];
  var invitationExpiry = match[12];
  var legalDocumentBirthdate = match[11];
  var legalDocumentLastName = match[10];
  var legalDocumentFirstName = match[9];
  var email = match[8];
  var lastName = match[7];
  var firstName = match[6];
  var fullName = match[5];
  var paxDataStatus = match[4];
  var reorderedIdx = match[1];
  var idx = match[0];
  var match$1;
  if (legalDocumentFirstName !== undefined && legalDocumentLastName !== undefined && firstName !== undefined && lastName !== undefined && (legalDocumentFirstName !== firstName || legalDocumentLastName !== lastName)) {
    var legalName = legalDocumentFirstName + " " + legalDocumentLastName;
    match$1 = [
      true,
      legalName
    ];
  } else {
    match$1 = [
      false,
      ""
    ];
  }
  var match$2 = React.useState(function () {
        return false;
      });
  var setPatchPaxDrawerOpen = match$2[1];
  var tmp;
  tmp = typeof paxDataStatus !== "object" ? "neutral" : "primary";
  var tmp$1;
  tmp$1 = typeof paxDataStatus !== "object" ? "neutral.400" : "text.primary";
  var tmp$2;
  var exit = 0;
  if (cruiselineBookingNumber !== undefined && !((paxReconciliationStatus === "UNRECONCILED" || paxReconciliationStatus === "SUGGESTED" || paxReconciliationStatus === "APPROVED" || paxReconciliationStatus === "AUTO_MATCHED" || paxReconciliationStatus === "POSSIBLY_STALE") && paxReconciliationStatus === "SUGGESTED")) {
    exit = 1;
  } else {
    tmp$2 = null;
  }
  if (exit === 1) {
    var tmp$3;
    if (paxReconciliationStatus === "UNRECONCILED" || paxReconciliationStatus === "SUGGESTED" || paxReconciliationStatus === "APPROVED" || paxReconciliationStatus === "AUTO_MATCHED" || paxReconciliationStatus === "POSSIBLY_STALE") {
      switch (paxReconciliationStatus) {
        case "AUTO_MATCHED" :
        case "APPROVED" :
            tmp$3 = "success";
            break;
        case "SUGGESTED" :
            tmp$3 = "neutral";
            break;
        case "UNRECONCILED" :
            tmp$3 = "danger";
            break;
        case "POSSIBLY_STALE" :
            tmp$3 = "warning";
            break;
        
      }
    } else {
      tmp$3 = "neutral";
    }
    tmp$2 = JsxRuntime.jsx(Tooltip, {
          children: JsxRuntime.jsx(Chip, {
                children: Caml_option.some(JsxRuntime.jsx(Typography, {
                          level: "body-xs",
                          fontWeight: "normal",
                          children: Caml_option.some(cruiselineBookingNumber)
                        })),
                color: tmp$3
              }),
          arrow: true,
          placement: "top",
          size: "sm",
          sx: {
            justifySelf: "flex-start",
            cursor: "pointer"
          },
          title: Caml_option.some("Cruiseline Booking Number (" + Common$Thick.PaxReconciliationStatus.toString(paxReconciliationStatus) + ")")
        });
  }
  var tmp$4;
  if (typeof paxDataStatus !== "object") {
    tmp$4 = "Email";
  } else if (paxDataStatus.TAG === "Status") {
    var tmp$5 = paxDataStatus._0;
    tmp$4 = (tmp$5 === "INCOMPLETE" || tmp$5 === "CONFIRMED" || tmp$5 === "INVITED") && tmp$5 === "INVITED" ? "Invited" : "Email";
  } else {
    tmp$4 = paxDataStatus._0 === "invite" ? "To invite" : "Email";
  }
  var tmp$6;
  var exit$1 = 0;
  if (typeof paxDataStatus !== "object") {
    exit$1 = 1;
  } else if (paxDataStatus.TAG === "Status") {
    var tmp$7 = paxDataStatus._0;
    if ((tmp$7 === "INCOMPLETE" || tmp$7 === "CONFIRMED" || tmp$7 === "INVITED") && tmp$7 === "INVITED") {
      tmp$6 = JsxRuntime.jsx(Typography, {
            level: "body-sm",
            noWrap: true,
            sx: {
              color: "text.tertiary",
              fontStyle: "italic"
            },
            fontWeight: "normal",
            children: Caml_option.some(Belt_Option.getWithDefault(match[13], "--"))
          });
    } else {
      exit$1 = 1;
    }
  } else if (paxDataStatus._0 === "invite") {
    tmp$6 = JsxRuntime.jsx(Typography, {
          level: "body-sm",
          noWrap: true,
          sx: {
            color: "text.tertiary",
            fontStyle: "italic"
          },
          fontWeight: "normal",
          children: Caml_option.some(Belt_Option.getWithDefault(email, "--"))
        });
  } else {
    exit$1 = 1;
  }
  if (exit$1 === 1) {
    tmp$6 = JsxRuntime.jsx(Typography, {
          level: "body-sm",
          noWrap: true,
          sx: {
            color: "text.primary"
          },
          fontWeight: "normal",
          children: Caml_option.some(email !== undefined ? email : "--")
        });
  }
  var tmp$8;
  var exit$2 = 0;
  if (typeof paxDataStatus !== "object" || paxDataStatus.TAG !== "Status") {
    exit$2 = 1;
  } else {
    var tmp$9 = paxDataStatus._0;
    if ((tmp$9 === "INCOMPLETE" || tmp$9 === "CONFIRMED" || tmp$9 === "INVITED") && tmp$9 === "INVITED" && invitationExpiry !== undefined) {
      tmp$8 = JsxRuntime.jsx(Tooltip, {
            children: Common$Thick.PaxDataStatus.makeChip("md", paxDataStatus),
            arrow: true,
            placement: "top",
            size: "sm",
            sx: {
              cursor: "pointer"
            },
            title: Caml_option.some(invitationExpiry)
          });
    } else {
      exit$2 = 1;
    }
  }
  if (exit$2 === 1) {
    tmp$8 = Common$Thick.PaxDataStatus.makeChip("md", paxDataStatus);
  }
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(Sheet, {
                      sx: {
                        p: 2,
                        border: "1px solid",
                        borderRadius: "4px",
                        borderColor: "divider"
                      },
                      children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                direction: "column",
                                spacing: 2,
                                children: [
                                  JsxRuntime.jsxs(Stack, {
                                        direction: "row",
                                        justifyContent: "space-between",
                                        children: [
                                          JsxRuntime.jsxs(Stack, {
                                                direction: "row",
                                                alignItems: "center",
                                                spacing: 1,
                                                children: [
                                                  JsxRuntime.jsx(Avatar, {
                                                        children: Caml_option.some(match[20]),
                                                        color: tmp
                                                      }),
                                                  JsxRuntime.jsxs(Stack, {
                                                        direction: "row",
                                                        spacing: 2,
                                                        children: [
                                                          JsxRuntime.jsxs(Stack, {
                                                                direction: "row",
                                                                children: [
                                                                  JsxRuntime.jsx(Typography, {
                                                                        level: "body-md",
                                                                        sx: {
                                                                          color: tmp$1
                                                                        },
                                                                        fontWeight: "normal",
                                                                        children: Caml_option.some(fullName !== undefined ? fullName : "TBA")
                                                                      }),
                                                                  match$1[0] ? JsxRuntime.jsx(Stack, {
                                                                          direction: "row",
                                                                          sx: {
                                                                            flex: 1,
                                                                            ml: "4px"
                                                                          },
                                                                          children: Caml_option.some(JsxRuntime.jsx(Tooltip, {
                                                                                    children: JsxRuntime.jsx(Typography, {
                                                                                          level: "body-md",
                                                                                          children: Caml_option.some(JsxRuntime.jsx("sup", {
                                                                                                    children: JsxRuntime.jsx(InfoOutlined, {
                                                                                                          color: "info",
                                                                                                          fontSize: "inherit"
                                                                                                        })
                                                                                                  }))
                                                                                        }),
                                                                                    arrow: true,
                                                                                    placement: "top",
                                                                                    size: "sm",
                                                                                    sx: {
                                                                                      justifySelf: "flex-start",
                                                                                      cursor: "pointer"
                                                                                    },
                                                                                    title: Caml_option.some("Traveling as " + match$1[1])
                                                                                  }))
                                                                        }) : null
                                                                ]
                                                              }),
                                                          tmp$2
                                                        ]
                                                      })
                                                ]
                                              }),
                                          JsxRuntime.jsxs(Stack, {
                                                direction: "row",
                                                alignItems: "center",
                                                spacing: 2,
                                                children: [
                                                  idx === reorderedIdx ? (
                                                      idx === 1 ? JsxRuntime.jsx(Chip, {
                                                              children: "Primary (pax1)",
                                                              size: "sm",
                                                              sx: {
                                                                color: "primary.400",
                                                                backgroundColor: "primary.100",
                                                                borderRadius: "xs",
                                                                height: "1.5rem"
                                                              }
                                                            }) : null
                                                    ) : JsxRuntime.jsx(Chip, {
                                                          children: Caml_option.some(reorderedIdx === 1 ? "Primary (pax1) on apply" : "Pax" + String(reorderedIdx) + " on apply"),
                                                          size: "sm",
                                                          sx: {
                                                            color: "warning.400",
                                                            backgroundColor: "#FFF9EB",
                                                            borderRadius: "xs",
                                                            height: "1.5rem"
                                                          }
                                                        }),
                                                  JsxRuntime.jsx(BDBerthsPassengers$PaxMenu, {
                                                        snapshot: props.snapshot,
                                                        isActive: props.isActive,
                                                        status: paxDataStatus,
                                                        canManage: props.canManage,
                                                        idx: idx,
                                                        reorderedIdx: reorderedIdx,
                                                        billPayRec: billPayRec,
                                                        versionUrl: versionUrl,
                                                        name: fullName,
                                                        blockDrop: match[21],
                                                        shouldAttachToIdx: match[2],
                                                        brandFamily: brandFamily,
                                                        generatePreviewFromDiff: generatePreviewFromDiff,
                                                        voyage: voyage,
                                                        accountId: match[3],
                                                        refetch: props.refetch,
                                                        setPatchPaxDrawerOpen: setPatchPaxDrawerOpen,
                                                        fragmentRefs: props.fragmentRefs,
                                                        countryFragmentRefs: countryFragmentRefs
                                                      })
                                                ]
                                              })
                                        ]
                                      }),
                                  JsxRuntime.jsxs(Stack, {
                                        direction: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        children: [
                                          JsxRuntime.jsxs(Stack, {
                                                direction: "row",
                                                spacing: 2,
                                                children: [
                                                  JsxRuntime.jsxs(Stack, {
                                                        direction: "row",
                                                        spacing: 1,
                                                        children: [
                                                          JsxRuntime.jsx(Typography, {
                                                                level: "body-sm",
                                                                noWrap: true,
                                                                sx: {
                                                                  color: "neutral.500"
                                                                },
                                                                fontWeight: "normal",
                                                                children: Caml_option.some(tmp$4)
                                                              }),
                                                          tmp$6
                                                        ]
                                                      }),
                                                  JsxRuntime.jsx(Divider, {
                                                        orientation: "vertical"
                                                      }),
                                                  JsxRuntime.jsxs(Stack, {
                                                        direction: "row",
                                                        spacing: 1,
                                                        children: [
                                                          JsxRuntime.jsx(Typography, {
                                                                level: "body-sm",
                                                                noWrap: true,
                                                                sx: {
                                                                  color: "neutral.500"
                                                                },
                                                                fontWeight: "normal",
                                                                children: "Birthdate"
                                                              }),
                                                          JsxRuntime.jsx(Typography, {
                                                                level: "body-sm",
                                                                noWrap: true,
                                                                sx: {
                                                                  color: "text.primary"
                                                                },
                                                                fontWeight: "normal",
                                                                children: Caml_option.some(legalDocumentBirthdate !== undefined ? DateFns.format(Belt_Result.getExn(DateFns$Util.parseISO(legalDocumentBirthdate)), "MM/dd/yyyy") : "--")
                                                              })
                                                        ]
                                                      })
                                                ]
                                              }),
                                          tmp$8
                                        ]
                                      }),
                                  JsxRuntime.jsxs(Stack, {
                                        direction: "row",
                                        spacing: 2,
                                        children: [
                                          JsxRuntime.jsxs(Stack, {
                                                direction: "row",
                                                spacing: 1,
                                                children: [
                                                  JsxRuntime.jsx(Typography, {
                                                        level: "body-sm",
                                                        noWrap: true,
                                                        sx: {
                                                          color: "neutral.500"
                                                        },
                                                        fontWeight: "normal",
                                                        children: "Loyalty #"
                                                      }),
                                                  JsxRuntime.jsx(Typography, {
                                                        level: "body-sm",
                                                        noWrap: true,
                                                        sx: {
                                                          color: "text.primary"
                                                        },
                                                        fontWeight: "normal",
                                                        children: Caml_option.some(loyaltyNumber !== undefined ? loyaltyNumber : "--")
                                                      })
                                                ]
                                              }),
                                          JsxRuntime.jsx(Divider, {
                                                orientation: "vertical"
                                              }),
                                          JsxRuntime.jsxs(Stack, {
                                                direction: "row",
                                                spacing: 1,
                                                children: [
                                                  JsxRuntime.jsx(Typography, {
                                                        level: "body-sm",
                                                        noWrap: true,
                                                        sx: {
                                                          color: "neutral.500"
                                                        },
                                                        fontWeight: "normal",
                                                        children: "Loyalty Tier"
                                                      }),
                                                  JsxRuntime.jsx(Typography, {
                                                        level: "body-sm",
                                                        noWrap: true,
                                                        sx: {
                                                          color: "text.primary"
                                                        },
                                                        fontWeight: "normal",
                                                        children: Caml_option.some(currentTierTitle !== undefined ? currentTierTitle + " (" + String(match[18]) + ")" : "--")
                                                      })
                                                ]
                                              }),
                                          JsxRuntime.jsx(Divider, {
                                                orientation: "vertical"
                                              }),
                                          JsxRuntime.jsxs(Stack, {
                                                direction: "row",
                                                spacing: 1,
                                                children: [
                                                  JsxRuntime.jsx(Typography, {
                                                        level: "body-sm",
                                                        noWrap: true,
                                                        sx: {
                                                          color: "neutral.500"
                                                        },
                                                        fontWeight: "normal",
                                                        children: "HAL Mariner"
                                                      }),
                                                  JsxRuntime.jsx(Typography, {
                                                        level: "body-sm",
                                                        noWrap: true,
                                                        sx: {
                                                          color: "text.primary"
                                                        },
                                                        fontWeight: "normal",
                                                        children: Caml_option.some(halMarinerNumber !== undefined ? halMarinerNumber : "--")
                                                      })
                                                ]
                                              })
                                        ]
                                      })
                                ]
                              }))
                    }),
                JsxRuntime.jsx(PatchPaxDrawer$Thick.make, {
                      passView: passenger,
                      voyage: voyage,
                      brandFamily: brandFamily,
                      open_: match$2[0],
                      onClose: (function () {
                          setPatchPaxDrawerOpen(function (param) {
                                return false;
                              });
                        }),
                      billPayCurrent: billPayRec,
                      versionUrl: versionUrl,
                      generatePreviewFromDiff: generatePreviewFromDiff,
                      countryFragmentRefs: countryFragmentRefs
                    })
              ]
            });
}

var ContactCard = {
  make: BDBerthsPassengers$BerthsPassengers$ContactCard
};

function BDBerthsPassengers$BerthsPassengers(props) {
  var state = props.state;
  var countryFragmentRefs = props.countryFragmentRefs;
  var fragmentRefs = props.fragmentRefs;
  var refetch = props.refetch;
  var shouldBlockDrop = props.shouldBlockDrop;
  var voyage = props.voyage;
  var generatePreviewFromDiff = props.generatePreviewFromDiff;
  var brandFamily = props.brandFamily;
  var versionUrl = props.versionUrl;
  var billPayRec = props.billPayRec;
  var canManage = props.canManage;
  var snapshot = props.snapshot;
  var isActive = props.isActive;
  var actions = isActive && !(snapshot || !canManage) ? JsxRuntime.jsxs(Stack, {
          direction: "row",
          spacing: 1,
          children: [
            JsxRuntime.jsx(AddBerthBtn$Thick.make, {
                  billPayCurrent: billPayRec,
                  versionUrl: versionUrl,
                  disabled: !props.canAddBerth,
                  generatePreviewFromDiff: generatePreviewFromDiff
                }),
            JsxRuntime.jsx(BDBerthsPassengers$BPMenu, {})
          ]
        }) : null;
  var range = Belt_Array.range(1, props.numBerths);
  return JsxRuntime.jsx(BDContentBlock$Thick.make, {
              title: "Berths & Passengers",
              actions: Caml_option.some(actions),
              children: JsxRuntime.jsx(Stack, {
                    spacing: 1,
                    children: Caml_option.some(Belt_Array.map(range, (function (i) {
                                return JsxRuntime.jsx(BDBerthsPassengers$BerthsPassengers$ContactCard, {
                                            snapshot: snapshot,
                                            isActive: isActive,
                                            canManage: canManage,
                                            billPayRec: billPayRec,
                                            versionUrl: versionUrl,
                                            shouldBlockDrop: shouldBlockDrop,
                                            brandFamily: brandFamily,
                                            generatePreviewFromDiff: generatePreviewFromDiff,
                                            voyage: voyage,
                                            refetch: refetch,
                                            fragmentRefs: fragmentRefs,
                                            countryFragmentRefs: countryFragmentRefs,
                                            state: state,
                                            berthNum: i
                                          }, "pax-" + String(i));
                              })))
                  })
            });
}

var BerthsPassengers = {
  ContactCard: ContactCard,
  make: BDBerthsPassengers$BerthsPassengers
};

function BDBerthsPassengers(props) {
  return JsxRuntime.jsx(BDBerthsPassengers$BerthsPassengers, {
              isActive: props.isActive,
              snapshot: props.snapshot,
              canManage: props.canManage,
              billPayRec: props.billPayRec,
              versionUrl: props.versionUrl,
              brandFamily: props.brandFamily,
              canAddBerth: props.canAddBerth,
              generatePreviewFromDiff: props.generatePreviewFromDiff,
              voyage: props.voyage,
              shouldBlockDrop: props.shouldBlockDrop,
              refetch: props.refetch,
              fragmentRefs: props.fragmentRefs,
              countryFragmentRefs: props.countryFragmentRefs,
              state: props.state,
              numBerths: props.numBerths
            });
}

var make = BDBerthsPassengers;

exports.FetchAllBfCustUsers = FetchAllBfCustUsers;
exports.BPMenu = BPMenu;
exports.PaxMenu = PaxMenu;
exports.BerthsPassengers = BerthsPassengers;
exports.make = make;
/* use Not a pure module */
