// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CS_Decimal$Util = require("util/src/CustomScalars/CS_Decimal.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");

var Types = {};

var variablesConverter = {"recordManualPaymentInput":{"_voyageSlug":{"c":"Util.CustomScalars.VoyageSlug.Exn"},"_transactionId":{"c":"Util.CustomScalars.NonemptyString.Exn"},"_pmtSubtype":{"c":"Util.CustomScalars.NonemptyString.Exn"},"_pmtNote":{"c":"Util.CustomScalars.NonemptyString.Exn"},"_pmtMethodIdentifier":{"c":"Util.CustomScalars.NonemptyString.Exn"},"_payer":{"c":"Util.CustomScalars.AccountId.Exn"},"_netAmount":{"c":"Util.CustomScalars.UndenominatedCurrency.Exn"},"_brandFamilySlug":{"c":"Util.CustomScalars.BrandFamilySlug.Exn"},"_bookingVersionNumber":{"b":""},"_bookingId":{"c":"Util.CustomScalars.BookingId.Exn"},"_bfFeeAmount":{"c":"Util.CustomScalars.UndenominatedCurrency.Exn"}},"__root":{"input":{"r":"recordManualPaymentInput"}}};

var variablesConverterMap = {
  "Util.CustomScalars.UndenominatedCurrency.Exn": CS_Decimal$Util.Decimal10_2.Exn.serialize,
  "Util.CustomScalars.BookingId.Exn": CS_NonemptyStrings$Util.BookingId.Exn.serialize,
  "Util.CustomScalars.BrandFamilySlug.Exn": CS_Slugs$Util.BrandFamilySlug.Exn.serialize,
  "Util.CustomScalars.AccountId.Exn": CS_NonemptyStrings$Util.AccountId.Exn.serialize,
  "Util.CustomScalars.NonemptyString.Exn": CS_NonemptyStrings$Util.NonEmptyString.Exn.serialize,
  "Util.CustomScalars.VoyageSlug.Exn": CS_Slugs$Util.VoyageSlug.Exn.serialize
};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, variablesConverterMap, undefined);
}

var wrapResponseConverter = {};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, undefined, null);
}

var responseConverter = {};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, undefined, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: variablesConverterMap,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: undefined,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: undefined,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse
};

function pmtLifecycleStatus_decode($$enum) {
  if ($$enum === "CANCELLED" || $$enum === "COMPLETED" || $$enum === "PENDING" || $$enum === "NASCENT" || $$enum === "ERROR") {
    return $$enum;
  }
  
}

function pmtLifecycleStatus_fromString(str) {
  return pmtLifecycleStatus_decode(str);
}

function pmtMethod_decode($$enum) {
  if ($$enum === "FCC" || $$enum === "CHECK" || $$enum === "CREDIT_CARD" || $$enum === "WIRE" || $$enum === "RTP" || $$enum === "SAME_DAY_ACH" || $$enum === "P2P") {
    return $$enum;
  }
  
}

function pmtMethod_fromString(str) {
  return pmtMethod_decode(str);
}

var Utils = {
  pmtLifecycleStatus_decode: pmtLifecycleStatus_decode,
  pmtLifecycleStatus_fromString: pmtLifecycleStatus_fromString,
  pmtMethod_decode: pmtMethod_decode,
  pmtMethod_fromString: pmtMethod_fromString
};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RecordManualPaymentPayload",
    "kind": "LinkedField",
    "name": "recordManualPayment",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "boolean",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RecordP2PPayment_RecordP2PPaymentMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RecordP2PPayment_RecordP2PPaymentMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5f8615ec03600e94f3b91accf62f813c",
    "id": null,
    "metadata": {},
    "name": "RecordP2PPayment_RecordP2PPaymentMutation",
    "operationKind": "mutation",
    "text": "mutation RecordP2PPayment_RecordP2PPaymentMutation(\n  $input: RecordManualPaymentInput!\n) {\n  recordManualPayment(input: $input) {\n    boolean\n  }\n}\n"
  }
};
})());

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
