// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thick = require("../utils/Common.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var Table = require("@mui/joy/Table").default;
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var Button = require("@mui/joy/Button").default;
var Tooltip = require("@mui/joy/Tooltip").default;
var JsxRuntime = require("react/jsx-runtime");
var IconButton = require("@mui/joy/IconButton").default;
var Typography = require("@mui/joy/Typography").default;
var AlterUserForm$Thick = require("../components/team/AlterUserForm.bs.js");
var InviteUserForm$Thick = require("../components/team/InviteUserForm.bs.js");
var RoleGrantsView$Thick = require("../components/dataview/RoleGrantsView.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var RevokeInvitation$Thick = require("../components/team/mutations/RevokeInvitation.bs.js");
var VoyageGrantsView$Thick = require("../components/dataview/VoyageGrantsView.bs.js");
var Add = require("@mui/icons-material/Add").default;
var BrandPermProtected$Thick = require("../components/wrappers/BrandPermProtected.bs.js");
var TeamPageQuery_graphql$Thick = require("../__generated__/TeamPageQuery_graphql.bs.js");
var TeamPage_user_graphql$Thick = require("../__generated__/TeamPage_user_graphql.bs.js");
var TeamPage_query_graphql$Thick = require("../__generated__/TeamPage_query_graphql.bs.js");
var ManageAccountsOutlined = require("@mui/icons-material/ManageAccountsOutlined").default;
var PersonAddDisabledOutlined = require("@mui/icons-material/PersonAddDisabledOutlined").default;

var convertFragment = TeamPage_user_graphql$Thick.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(TeamPage_user_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, TeamPage_user_graphql$Thick.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(TeamPage_user_graphql$Thick.node, convertFragment, fRef);
}

var UserFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

function TeamPage$TableRow(props) {
  var __setRevokeError = props.setRevokeError;
  var __pending = props.pending;
  var refreshTeam = props.refreshTeam;
  var fragment = props.fragment;
  var pending = __pending !== undefined ? __pending : false;
  var setRevokeError = __setRevokeError !== undefined ? Caml_option.valFromOption(__setRevokeError) : undefined;
  var user = use(fragment);
  var match = React.useState(function () {
        return false;
      });
  var setModalIsOpen = match[1];
  var match$1 = React.useState(function () {
        return false;
      });
  var setCopied = match$1[1];
  var match$2 = RevokeInvitation$Thick.useMutation(props.brandFamily, user.email, refreshTeam);
  var revokeOnClick = match$2.onClick;
  var tmp;
  var exit = 0;
  if (pending && setRevokeError !== undefined) {
    tmp = JsxRuntime.jsx(Stack, {
          direction: "row",
          justifyContent: "flex-end",
          sx: {
            width: "100%"
          },
          children: Caml_option.some(JsxRuntime.jsx(Tooltip, {
                    children: JsxRuntime.jsx(IconButton, {
                          onClick: (function (e) {
                              revokeOnClick(e, setRevokeError);
                            }),
                          disabled: match$2.isMutating,
                          children: Caml_option.some(JsxRuntime.jsx(PersonAddDisabledOutlined, {
                                    color: "danger"
                                  }))
                        }),
                    arrow: true,
                    size: "sm",
                    title: "Revoke Invitation"
                  }))
        });
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = JsxRuntime.jsx(Stack, {
          direction: "row",
          justifyContent: "flex-end",
          sx: {
            width: "100%"
          },
          children: Caml_option.some(JsxRuntime.jsx(Tooltip, {
                    children: JsxRuntime.jsx(IconButton, {
                          onClick: (function (param) {
                              setModalIsOpen(function (param) {
                                    return true;
                                  });
                            }),
                          children: Caml_option.some(JsxRuntime.jsx(ManageAccountsOutlined, {}))
                        }),
                    arrow: true,
                    size: "sm",
                    title: "Manage User"
                  }))
        });
  }
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Tooltip, {
                            children: JsxRuntime.jsx(Typography, {
                                  level: "inherit",
                                  noWrap: true,
                                  children: Caml_option.some(CS_Emails$Util.Email.toString(user.email))
                                }),
                            arrow: true,
                            size: "sm",
                            sx: {
                              cursor: "pointer",
                              width: "fit-content"
                            },
                            title: Caml_option.some(match$1[0] ? "Copied to clipboard!" : CS_Emails$Util.Email.toString(user.email)),
                            onClick: (function (param) {
                                return Common$Thick.copyToClipboard(CS_Emails$Util.Email.toString(user.email), setCopied);
                              })
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(RoleGrantsView$Thick.Chips.make, {
                            fragment: user.userRoleGrants.fragmentRefs
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(VoyageGrantsView$Thick.Chips.make, {
                            fragment: user.userVoyageGrants.fragmentRefs
                          })
                    }),
                JsxRuntime.jsxs(BrandPermProtected$Thick.make, {
                      fragmentRefs: props.permFrag,
                      neededPerm: "MANAGE_USER",
                      children: [
                        JsxRuntime.jsx("td", {
                              children: tmp
                            }),
                        JsxRuntime.jsx(AlterUserForm$Thick.make, {
                              fragment: fragment,
                              isOpen: match[0],
                              onClose: (function () {
                                  setModalIsOpen(function (param) {
                                        return false;
                                      });
                                }),
                              refreshTeam: refreshTeam
                            })
                      ]
                    })
              ]
            });
}

var TableRow = {
  make: TeamPage$TableRow
};

var convertFragment$1 = TeamPage_query_graphql$Thick.Internal.convertFragment;

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(TeamPage_query_graphql$Thick.node, convertFragment$1, fRef);
}

function useOpt$1(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, TeamPage_query_graphql$Thick.node, convertFragment$1);
}

function readResolverFragment$1(fRef) {
  return RescriptRelay_Fragment.read(TeamPage_query_graphql$Thick.node, convertFragment$1, fRef);
}

var makeRefetchVariables = TeamPageQuery_graphql$Thick.Types.makeRefetchVariables;

var convertRefetchVariables = TeamPageQuery_graphql$Thick.Internal.convertVariables;

function useRefetchable(fRef) {
  return RescriptRelay_Fragment.useRefetchableFragment(TeamPage_query_graphql$Thick.node, convertFragment$1, convertRefetchVariables, fRef);
}

var QueryFragment_brandfamRole_decode = TeamPage_query_graphql$Thick.Utils.brandfamRole_decode;

var QueryFragment_brandfamRole_fromString = TeamPage_query_graphql$Thick.Utils.brandfamRole_fromString;

var QueryFragment_signonStatus_decode = TeamPage_query_graphql$Thick.Utils.signonStatus_decode;

var QueryFragment_signonStatus_fromString = TeamPage_query_graphql$Thick.Utils.signonStatus_fromString;

var QueryFragment = {
  brandfamRole_decode: QueryFragment_brandfamRole_decode,
  brandfamRole_fromString: QueryFragment_brandfamRole_fromString,
  signonStatus_decode: QueryFragment_signonStatus_decode,
  signonStatus_fromString: QueryFragment_signonStatus_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$1,
  use: use$1,
  useOpt: useOpt$1,
  readResolverFragment: readResolverFragment$1,
  makeRefetchVariables: makeRefetchVariables,
  convertRefetchVariables: convertRefetchVariables,
  useRefetchable: useRefetchable
};

function TeamPage(props) {
  var brandFamily = props.brandFamily;
  var fragmentRefs = props.fragmentRefs;
  var match = useRefetchable(fragmentRefs);
  var refetch = match[1];
  var query = match[0];
  var match$1 = Common$Thick.useStateExtended(function () {
        return false;
      });
  var setDialogOpen = match$1[1];
  var match$2 = React.useState(function () {
        
      });
  var setRevokeError = match$2[1];
  var revokeError = match$2[0];
  var refreshTeam = function (param) {
    return refetch(makeRefetchVariables(undefined), "store-and-network", undefined);
  };
  var match$3 = query.team;
  var team = match$3 !== undefined ? Belt_Array.map(match$3.nodes, (function (node) {
            return JsxRuntime.jsx("tr", {
                        children: JsxRuntime.jsx(TeamPage$TableRow, {
                              fragment: node.fragmentRefs,
                              permFrag: query.fragmentRefs,
                              refreshTeam: refreshTeam,
                              brandFamily: brandFamily
                            })
                      }, node.id);
          })) : [];
  var match$4 = query.pending;
  var pending = match$4 !== undefined ? Belt_Array.map(match$4.nodes, (function (node) {
            return JsxRuntime.jsx("tr", {
                        children: JsxRuntime.jsx(TeamPage$TableRow, {
                              fragment: node.fragmentRefs,
                              permFrag: query.fragmentRefs,
                              refreshTeam: refreshTeam,
                              pending: true,
                              brandFamily: brandFamily,
                              setRevokeError: Caml_option.some(setRevokeError)
                            })
                      }, node.id);
          })) : [];
  var match$5 = team.length;
  var match$6 = pending.length;
  return JsxRuntime.jsxs(Stack, {
              spacing: 2,
              children: [
                JsxRuntime.jsx(Typography, {
                      level: "h3",
                      component: "h1",
                      children: Caml_option.some(CS_Slugs$Util.BrandFamilySlug.toString(brandFamily) + " Team")
                    }),
                JsxRuntime.jsx(Stack, {
                      direction: "row",
                      spacing: 1,
                      children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                direction: "column",
                                alignItems: "flex-start",
                                spacing: 2,
                                children: [
                                  match$5 !== 0 ? JsxRuntime.jsx(Stack, {
                                          direction: "row",
                                          spacing: 1,
                                          useFlexGap: true,
                                          flexWrap: "wrap",
                                          sx: {
                                            display: "flex"
                                          },
                                          children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                                    direction: "column",
                                                    spacing: 1,
                                                    sx: {
                                                      flex: 1
                                                    },
                                                    children: Caml_option.some(JsxRuntime.jsxs(Sheet, {
                                                              variant: "outlined",
                                                              sx: {
                                                                pt: 1,
                                                                borderRadius: "sm"
                                                              },
                                                              children: [
                                                                JsxRuntime.jsxs(Table, {
                                                                      stripe: "odd",
                                                                      hoverRow: true,
                                                                      sx: {
                                                                        captionSide: "top",
                                                                        flex: 1,
                                                                        "& tbody": {
                                                                          bgcolor: "background.surface"
                                                                        }
                                                                      },
                                                                      children: [
                                                                        JsxRuntime.jsx("thead", {
                                                                              children: JsxRuntime.jsxs("tr", {
                                                                                    children: [
                                                                                      JsxRuntime.jsx("th", {
                                                                                            children: "Email",
                                                                                            style: {
                                                                                              width: "10rem"
                                                                                            }
                                                                                          }),
                                                                                      JsxRuntime.jsx("th", {
                                                                                            children: "Roles",
                                                                                            style: {
                                                                                              width: "10rem"
                                                                                            }
                                                                                          }),
                                                                                      JsxRuntime.jsx("th", {
                                                                                            children: "Voyages",
                                                                                            style: {
                                                                                              width: "10rem"
                                                                                            }
                                                                                          }),
                                                                                      JsxRuntime.jsx(BrandPermProtected$Thick.make, {
                                                                                            fragmentRefs: query.fragmentRefs,
                                                                                            neededPerm: "MANAGE_USER",
                                                                                            children: JsxRuntime.jsx("th", {
                                                                                                  style: {
                                                                                                    width: "5rem"
                                                                                                  }
                                                                                                })
                                                                                          })
                                                                                    ]
                                                                                  })
                                                                            }),
                                                                        JsxRuntime.jsx("tbody", {
                                                                              children: team
                                                                            })
                                                                      ]
                                                                    }),
                                                                JsxRuntime.jsx(Stack, {
                                                                      sx: {
                                                                        p: 1,
                                                                        width: "fit-content"
                                                                      },
                                                                      children: Caml_option.some(JsxRuntime.jsxs(BrandPermProtected$Thick.make, {
                                                                                fragmentRefs: query.fragmentRefs,
                                                                                neededPerm: "ADD_USER",
                                                                                children: [
                                                                                  JsxRuntime.jsx(Button, {
                                                                                        onClick: (function (param) {
                                                                                            setDialogOpen(function (param) {
                                                                                                  return true;
                                                                                                });
                                                                                          }),
                                                                                        startDecorator: Caml_option.some(JsxRuntime.jsx(Add, {})),
                                                                                        "aria-label": "Invite User",
                                                                                        variant: "outlined",
                                                                                        size: "sm",
                                                                                        children: "Invite User"
                                                                                      }),
                                                                                  JsxRuntime.jsx(InviteUserForm$Thick.make, {
                                                                                        fragment: fragmentRefs,
                                                                                        open_: match$1[0],
                                                                                        onClose: (function () {
                                                                                            setDialogOpen(function (param) {
                                                                                                  return false;
                                                                                                });
                                                                                          }),
                                                                                        brandFamily: brandFamily,
                                                                                        refreshTeam: refreshTeam,
                                                                                        permFrag: query.fragmentRefs
                                                                                      })
                                                                                ]
                                                                              }))
                                                                    })
                                                              ]
                                                            }))
                                                  }))
                                        }) : JsxRuntime.jsx("p", {
                                          children: "No team members"
                                        }),
                                  JsxRuntime.jsxs(Stack, {
                                        spacing: 1,
                                        children: [
                                          JsxRuntime.jsx(Typography, {
                                                level: "h3",
                                                component: "h2",
                                                children: "Pending Invitations"
                                              }),
                                          match$6 !== 0 ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                                  children: [
                                                    JsxRuntime.jsx(Sheet, {
                                                          variant: "outlined",
                                                          sx: {
                                                            pt: 1,
                                                            borderRadius: "sm"
                                                          },
                                                          children: Caml_option.some(JsxRuntime.jsxs(Table, {
                                                                    stripe: "odd",
                                                                    hoverRow: true,
                                                                    sx: {
                                                                      captionSide: "top",
                                                                      "& tbody": {
                                                                        bgcolor: "background.surface"
                                                                      }
                                                                    },
                                                                    children: [
                                                                      JsxRuntime.jsx("thead", {
                                                                            children: JsxRuntime.jsxs("tr", {
                                                                                  children: [
                                                                                    JsxRuntime.jsx("th", {
                                                                                          children: "Email",
                                                                                          style: {
                                                                                            width: "10rem"
                                                                                          }
                                                                                        }),
                                                                                    JsxRuntime.jsx("th", {
                                                                                          children: "Roles",
                                                                                          style: {
                                                                                            width: "10rem"
                                                                                          }
                                                                                        }),
                                                                                    JsxRuntime.jsx("th", {
                                                                                          children: "Voyages",
                                                                                          style: {
                                                                                            width: "10rem"
                                                                                          }
                                                                                        }),
                                                                                    JsxRuntime.jsx(BrandPermProtected$Thick.make, {
                                                                                          fragmentRefs: query.fragmentRefs,
                                                                                          neededPerm: "MANAGE_USER",
                                                                                          children: JsxRuntime.jsx("th", {
                                                                                                style: {
                                                                                                  width: "5rem"
                                                                                                }
                                                                                              })
                                                                                        })
                                                                                  ]
                                                                                })
                                                                          }),
                                                                      JsxRuntime.jsx("tbody", {
                                                                            children: pending
                                                                          })
                                                                    ]
                                                                  }))
                                                        }),
                                                    revokeError !== undefined ? JsxRuntime.jsx(Typography, {
                                                            color: "danger",
                                                            level: "body-sm",
                                                            children: Caml_option.some(revokeError)
                                                          }) : JsxRuntime.jsx(JsxRuntime.Fragment, {})
                                                  ]
                                                }) : JsxRuntime.jsx("p", {
                                                  children: "No pending invitations"
                                                })
                                        ]
                                      })
                                ]
                              }))
                    })
              ]
            });
}

var make = TeamPage;

exports.UserFragment = UserFragment;
exports.TableRow = TableRow;
exports.QueryFragment = QueryFragment;
exports.make = make;
/* react Not a pure module */
