// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml = require("rescript/lib/js/caml.js");
var React = require("react");
var Belt_Int = require("rescript/lib/js/belt_Int.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Belt_SetString = require("rescript/lib/js/belt_SetString.js");
var QueryString$Util = require("util/src/QueryString.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");

var incl = {
  id: "incl",
  desc: "includes",
  helpText: "A word or phrase"
};

var eq = {
  id: "eq",
  desc: "is equal to",
  helpText: "A word or phrase"
};

var oneof = {
  id: "oneof",
  desc: "is one of",
  helpText: "A comma-separated list"
};

var StringImpl = {
  incl: incl,
  eq: eq,
  oneof: oneof
};

var sortedVerbs = [
  incl,
  eq,
  oneof
];

function queryStringParam(verbParam) {
  return QueryString$Util.Codec.tuple2(verbParam, QueryString$Util.Codec.required(QueryString$Util.Codec.string));
}

function decode(verb, userInputT) {
  var match = userInputT.trim().length;
  if (match !== 0) {
    if (Caml_obj.equal(verb, incl)) {
      return {
              TAG: "Ok",
              _0: {
                TAG: "Includes",
                _0: userInputT
              }
            };
    } else if (Caml_obj.equal(verb, eq)) {
      return {
              TAG: "Ok",
              _0: {
                TAG: "Equals",
                _0: userInputT
              }
            };
    } else if (Caml_obj.equal(verb, oneof)) {
      return {
              TAG: "Ok",
              _0: {
                TAG: "InArray",
                _0: Belt_Array.keep(Belt_Array.map(userInputT.split(","), (function (prim) {
                            return prim.trim();
                          })), (function (s) {
                        return s !== "";
                      }))
              }
            };
    } else {
      return {
              TAG: "Error",
              _0: "Verb not found"
            };
    }
  } else {
    return {
            TAG: "Error",
            _0: "Must provide a value to filter"
          };
  }
}

function encode(filterT) {
  switch (filterT.TAG) {
    case "Includes" :
        return [
                incl,
                filterT._0
              ];
    case "Equals" :
        return [
                eq,
                filterT._0
              ];
    case "InArray" :
        return [
                oneof,
                filterT._0.join(",")
              ];
    
  }
}

function toRelay(filterT) {
  switch (filterT.TAG) {
    case "Includes" :
        return {
                includesInsensitive: filterT._0
              };
    case "Equals" :
        return {
                equalToInsensitive: filterT._0
              };
    case "InArray" :
        return {
                in: filterT._0
              };
    
  }
}

var $$String = {
  sortedVerbs: sortedVerbs,
  queryStringParam: queryStringParam,
  decode: decode,
  encode: encode,
  toRelay: toRelay
};

var sortedVerbs$1 = [
  eq,
  incl,
  oneof
];

var StringRestricted = {
  queryStringParam: queryStringParam,
  decode: decode,
  encode: encode,
  toRelay: toRelay,
  sortedVerbs: sortedVerbs$1
};

var sortedVerbs$2 = [
  eq,
  oneof
];

function queryStringParam$1(verbParam) {
  return QueryString$Util.Codec.tuple2(verbParam, QueryString$Util.Codec.required(QueryString$Util.Codec.string));
}

function decode$1(verb, userInputT) {
  var match = userInputT.trim().length;
  if (match !== 0) {
    if (Caml_obj.equal(verb, eq)) {
      return {
              TAG: "Ok",
              _0: {
                TAG: "Equals",
                _0: userInputT
              }
            };
    } else if (Caml_obj.equal(verb, oneof)) {
      return {
              TAG: "Ok",
              _0: {
                TAG: "InArray",
                _0: Belt_Array.keep(Belt_Array.map(userInputT.split(","), (function (prim) {
                            return prim.trim();
                          })), (function (s) {
                        return s !== "";
                      }))
              }
            };
    } else {
      return {
              TAG: "Error",
              _0: "Verb not found"
            };
    }
  } else {
    return {
            TAG: "Error",
            _0: "Must provide a value to filter"
          };
  }
}

function encode$1(filterT) {
  if (filterT.TAG === "Equals") {
    return [
            eq,
            filterT._0
          ];
  } else {
    return [
            oneof,
            filterT._0.join(",")
          ];
  }
}

function toRelay$1(filterT) {
  if (filterT.TAG === "Equals") {
    return {
            equalTo: filterT._0.toLowerCase()
          };
  } else {
    return {
            in: filterT._0
          };
  }
}

var Slug = {
  sortedVerbs: sortedVerbs$2,
  queryStringParam: queryStringParam$1,
  decode: decode$1,
  encode: encode$1,
  toRelay: toRelay$1
};

var contains = {
  id: "contains",
  desc: "contains element",
  helpText: "\"A number"
};

var upto = {
  id: "upto",
  desc: "is up to and including",
  helpText: "\"A number"
};

var inrng = {
  id: "inrng",
  desc: "is in range",
  helpText: "\"A hypen-separated range"
};

var above = {
  id: "andabove",
  desc: "includes and is above",
  helpText: "\"A number"
};

var sortedVerbs$3 = [
  contains,
  upto,
  inrng,
  above
];

function queryStringParam$2(verbParam) {
  return QueryString$Util.Codec.tuple2(verbParam, QueryString$Util.Codec.required(QueryString$Util.Codec.string));
}

function encode$2(filterT) {
  switch (filterT.TAG) {
    case "ContainsElement" :
        return [
                contains,
                String(filterT._0)
              ];
    case "UpToAndIncluding" :
        return [
                upto,
                String(filterT._0)
              ];
    case "IncludingAndAbove" :
        return [
                above,
                String(filterT._0)
              ];
    case "RangeInclusive" :
        var match = filterT._0;
        return [
                inrng,
                String(match[0]) + "-" + String(match[1])
              ];
    
  }
}

function decode$2(verb, string) {
  var plainInt = Belt_Int.fromString(string);
  var match = Belt_Array.map(string.split("-"), Belt_Int.fromString);
  var twoInts;
  if (match.length !== 2) {
    twoInts = undefined;
  } else {
    var li = match[0];
    if (li !== undefined) {
      var ri = match[1];
      twoInts = ri !== undefined ? [
          li,
          ri
        ] : undefined;
    } else {
      twoInts = undefined;
    }
  }
  if (plainInt !== undefined && twoInts === undefined) {
    if (Caml_obj.equal(verb, contains)) {
      return {
              TAG: "Ok",
              _0: {
                TAG: "ContainsElement",
                _0: plainInt
              }
            };
    } else if (Caml_obj.equal(verb, upto)) {
      return {
              TAG: "Ok",
              _0: {
                TAG: "UpToAndIncluding",
                _0: plainInt
              }
            };
    } else if (Caml_obj.equal(verb, above)) {
      return {
              TAG: "Ok",
              _0: {
                TAG: "IncludingAndAbove",
                _0: plainInt
              }
            };
    } else {
      return {
              TAG: "Error",
              _0: "Invalid Range Filter"
            };
    }
  }
  if (twoInts !== undefined && Caml_obj.equal(verb, inrng)) {
    return {
            TAG: "Ok",
            _0: {
              TAG: "RangeInclusive",
              _0: [
                twoInts[0],
                twoInts[1]
              ]
            }
          };
  } else {
    return {
            TAG: "Error",
            _0: "Invalid Range Filter"
          };
  }
}

function toRelay$2(intermediate) {
  switch (intermediate.TAG) {
    case "ContainsElement" :
        return {
                containsElement: intermediate._0
              };
    case "UpToAndIncluding" :
        return {
                lessThanOrEqualTo: {
                  start: {
                    value: 0,
                    inclusive: false
                  },
                  end: {
                    value: intermediate._0,
                    inclusive: true
                  }
                }
              };
    case "IncludingAndAbove" :
        return {
                lessThanOrEqualTo: {
                  start: {
                    value: intermediate._0,
                    inclusive: false
                  },
                  end: {
                    value: 9999999,
                    inclusive: true
                  }
                }
              };
    case "RangeInclusive" :
        var match = intermediate._0;
        return {
                contains: {
                  start: {
                    value: match[0],
                    inclusive: true
                  },
                  end: {
                    value: match[1],
                    inclusive: true
                  }
                }
              };
    
  }
}

var IntRange = {
  sortedVerbs: sortedVerbs$3,
  queryStringParam: queryStringParam$2,
  decode: decode$2,
  encode: encode$2,
  toRelay: toRelay$2
};

var isTrue = {
  id: "isTrue",
  desc: "is true",
  helpText: "no input needed"
};

var isFalse = {
  id: "isFalse",
  desc: "is false",
  helpText: "no input needed"
};

var sortedVerbs$4 = [
  isTrue,
  isFalse
];

function queryStringParam$3(verbParam) {
  return QueryString$Util.Codec.tuple2(verbParam, QueryString$Util.Codec.required(QueryString$Util.Codec.string));
}

function encode$3(filterT) {
  if (filterT.TAG === "True") {
    return [
            isTrue,
            "true"
          ];
  } else {
    return [
            isFalse,
            "false"
          ];
  }
}

function decode$3(verb, userInputT) {
  if (Caml_obj.equal(verb, isTrue)) {
    return {
            TAG: "Ok",
            _0: {
              TAG: "True",
              _0: "true"
            }
          };
  } else if (Caml_obj.equal(verb, isFalse)) {
    return {
            TAG: "Ok",
            _0: {
              TAG: "False",
              _0: "false"
            }
          };
  } else {
    return {
            TAG: "Error",
            _0: "Invalid Bool Filter"
          };
  }
}

function toRelay$3(filter) {
  if (filter.TAG === "True") {
    return {
            equalTo: true
          };
  } else {
    return {
            equalTo: false
          };
  }
}

var Bool = {
  sortedVerbs: sortedVerbs$4,
  queryStringParam: queryStringParam$3,
  decode: decode$3,
  encode: encode$3,
  toRelay: toRelay$3
};

var equals = {
  id: "equals",
  desc: "is equal to",
  helpText: "A number"
};

var lte = {
  id: "lte",
  desc: "is up to and including",
  helpText: "A number"
};

var gte = {
  id: "gte",
  desc: "includes and is above",
  helpText: "A number"
};

var lt = {
  id: "lt",
  desc: "is below",
  helpText: "A number"
};

var gt = {
  id: "gt",
  desc: "is above",
  helpText: "A number"
};

var sortedVerbs$5 = [
  equals,
  lte,
  gte,
  lt,
  gt
];

function queryStringParam$4(verbParam) {
  return QueryString$Util.Codec.tuple2(verbParam, QueryString$Util.Codec.required(QueryString$Util.Codec.string));
}

function encode$4(filterT) {
  switch (filterT.TAG) {
    case "Equals" :
        return [
                equals,
                String(filterT._0)
              ];
    case "LTE" :
        return [
                lte,
                String(filterT._0)
              ];
    case "GTE" :
        return [
                gte,
                String(filterT._0)
              ];
    case "LT" :
        return [
                lt,
                String(filterT._0)
              ];
    case "GT" :
        return [
                gt,
                String(filterT._0)
              ];
    
  }
}

function decode$4(verb, string) {
  var i = Belt_Int.fromString(string);
  if (i !== undefined) {
    if (Caml_obj.equal(verb, equals)) {
      return {
              TAG: "Ok",
              _0: {
                TAG: "Equals",
                _0: i
              }
            };
    } else if (Caml_obj.equal(verb, lte)) {
      return {
              TAG: "Ok",
              _0: {
                TAG: "LTE",
                _0: i
              }
            };
    } else if (Caml_obj.equal(verb, gte)) {
      return {
              TAG: "Ok",
              _0: {
                TAG: "GTE",
                _0: i
              }
            };
    } else if (Caml_obj.equal(verb, lt)) {
      return {
              TAG: "Ok",
              _0: {
                TAG: "LT",
                _0: i
              }
            };
    } else if (Caml_obj.equal(verb, gt)) {
      return {
              TAG: "Ok",
              _0: {
                TAG: "GT",
                _0: i
              }
            };
    } else {
      return {
              TAG: "Error",
              _0: "Invalid Int Filter"
            };
    }
  } else {
    return {
            TAG: "Error",
            _0: "Invalid Int Filter"
          };
  }
}

function toRelay$4(filter) {
  switch (filter.TAG) {
    case "Equals" :
        return {
                equalTo: filter._0
              };
    case "LTE" :
        return {
                lessThanOrEqualTo: filter._0
              };
    case "GTE" :
        return {
                greaterThanOrEqualTo: filter._0
              };
    case "LT" :
        return {
                lessThan: filter._0
              };
    case "GT" :
        return {
                greaterThan: filter._0
              };
    
  }
}

var Int = {
  sortedVerbs: sortedVerbs$5,
  queryStringParam: queryStringParam$4,
  decode: decode$4,
  encode: encode$4,
  toRelay: toRelay$4
};

var empty = {
  occRange: undefined,
  totBerths: undefined,
  segment: undefined,
  class: undefined,
  subclass: undefined,
  category: undefined,
  cabin: undefined,
  configNote: undefined,
  reservationNote: undefined,
  bookingExist: undefined,
  cabinStatus: undefined
};

function toRelay$5(t) {
  return {
          voyageClassSlug: Belt_Option.map(t.class, toRelay$1),
          voyageSubclassSlug: Belt_Option.map(t.subclass, toRelay$1),
          voyageCategorySlug: Belt_Option.map(t.category, toRelay$1),
          voyageSegment: Belt_Option.map(t.segment, toRelay),
          cabinNumber: Belt_Option.map(t.cabin, toRelay),
          totBerths: Belt_Option.map(t.totBerths, toRelay$4),
          occRange: Belt_Option.map(t.occRange, toRelay$2),
          reservationNoteMd: Belt_Option.map(t.reservationNote, toRelay),
          configurationNoteMd: Belt_Option.map(t.configNote, toRelay),
          isBooked: Belt_Option.map(t.bookingExist, toRelay$3),
          cabinStatus: Belt_Option.map(t.cabinStatus, toRelay)
        };
}

var $$Storage = {
  empty: empty,
  toRelay: toRelay$5
};

function cmp(lhs, rhs) {
  if (typeof lhs !== "object") {
    if (typeof rhs !== "object") {
      return 0;
    } else {
      return -1;
    }
  } else if (typeof rhs !== "object") {
    return 1;
  } else {
    return Caml.string_compare(lhs.column.id, rhs.column.id);
  }
}

function eq$1(lhs, rhs) {
  return cmp(lhs, rhs) === 0;
}

function make(id, niceName, equalToOptions, filter, set, get) {
  var verbToken = QueryString$Util.Codec.required(QueryString$Util.Codec.customStringable({
            legalVals: filter.sortedVerbs,
            toString: (function (v) {
                return v.id;
              })
          }));
  return {
          id: id,
          niceName: niceName,
          sortedVerbs: filter.sortedVerbs,
          equalToOptions: equalToOptions,
          isBool: Belt_Array.some(filter.sortedVerbs, (function (v) {
                  return v.id === "isTrue";
                })),
          queryParam: QueryString$Util.Param.Singular.make(id, (function (a) {
                  return a;
                }), QueryString$Util.Codec.map(filter.queryStringParam(verbToken), {
                    decode: (function (param) {
                        return filter.decode(param[0], param[1]);
                      }),
                    encode: filter.encode
                  }), false, set, get),
          isset: (function (storage) {
              if (Caml_obj.equal(get(storage), get(empty))) {
                return ;
              } else {
                return Belt_Option.map(get(storage), (function (a) {
                              return filter.encode(a);
                            }));
              }
            }),
          differs: (function (lhs, rhs) {
              return Caml_obj.notequal(get(lhs), get(rhs));
            }),
          apply: (function (storage, verb, input) {
              var storable = filter.decode(verb, input);
              if (storable.TAG === "Ok") {
                return {
                        TAG: "Ok",
                        _0: set(storage, Caml_option.some(storable._0))
                      };
              } else {
                return storable;
              }
            })
        };
}

var occRange = make("cq.occRange", "Sales Occupancy Range", undefined, IntRange, (function (t, occRange) {
        return {
                occRange: occRange,
                totBerths: t.totBerths,
                segment: t.segment,
                class: t.class,
                subclass: t.subclass,
                category: t.category,
                cabin: t.cabin,
                configNote: t.configNote,
                reservationNote: t.reservationNote,
                bookingExist: t.bookingExist,
                cabinStatus: t.cabinStatus
              };
      }), (function (t) {
        return t.occRange;
      }));

var totBerths = make("cq.totBerths", "Total Berths", undefined, Int, (function (t, totBerths) {
        return {
                occRange: t.occRange,
                totBerths: totBerths,
                segment: t.segment,
                class: t.class,
                subclass: t.subclass,
                category: t.category,
                cabin: t.cabin,
                configNote: t.configNote,
                reservationNote: t.reservationNote,
                bookingExist: t.bookingExist,
                cabinStatus: t.cabinStatus
              };
      }), (function (t) {
        return t.totBerths;
      }));

var segment = make("cq.segment", "Segment", undefined, $$String, (function (t, segment) {
        return {
                occRange: t.occRange,
                totBerths: t.totBerths,
                segment: segment,
                class: t.class,
                subclass: t.subclass,
                category: t.category,
                cabin: t.cabin,
                configNote: t.configNote,
                reservationNote: t.reservationNote,
                bookingExist: t.bookingExist,
                cabinStatus: t.cabinStatus
              };
      }), (function (t) {
        return t.segment;
      }));

var $$class = make("cq.class", "Class", undefined, Slug, (function (t, $$class) {
        return {
                occRange: t.occRange,
                totBerths: t.totBerths,
                segment: t.segment,
                class: $$class,
                subclass: t.subclass,
                category: t.category,
                cabin: t.cabin,
                configNote: t.configNote,
                reservationNote: t.reservationNote,
                bookingExist: t.bookingExist,
                cabinStatus: t.cabinStatus
              };
      }), (function (t) {
        return t.class;
      }));

var subclass = make("cq.subclass", "Subclass", undefined, Slug, (function (t, subclass) {
        return {
                occRange: t.occRange,
                totBerths: t.totBerths,
                segment: t.segment,
                class: t.class,
                subclass: subclass,
                category: t.category,
                cabin: t.cabin,
                configNote: t.configNote,
                reservationNote: t.reservationNote,
                bookingExist: t.bookingExist,
                cabinStatus: t.cabinStatus
              };
      }), (function (t) {
        return t.subclass;
      }));

var category = make("cq.category", "Category", undefined, Slug, (function (t, category) {
        return {
                occRange: t.occRange,
                totBerths: t.totBerths,
                segment: t.segment,
                class: t.class,
                subclass: t.subclass,
                category: category,
                cabin: t.cabin,
                configNote: t.configNote,
                reservationNote: t.reservationNote,
                bookingExist: t.bookingExist,
                cabinStatus: t.cabinStatus
              };
      }), (function (t) {
        return t.category;
      }));

var cabin = make("cq.cabin", "Cabin", undefined, {
      sortedVerbs: sortedVerbs$1,
      queryStringParam: queryStringParam,
      decode: decode,
      encode: encode,
      toRelay: toRelay
    }, (function (t, cabin) {
        return {
                occRange: t.occRange,
                totBerths: t.totBerths,
                segment: t.segment,
                class: t.class,
                subclass: t.subclass,
                category: t.category,
                cabin: cabin,
                configNote: t.configNote,
                reservationNote: t.reservationNote,
                bookingExist: t.bookingExist,
                cabinStatus: t.cabinStatus
              };
      }), (function (t) {
        return t.cabin;
      }));

var configNote = make("cq.configNote", "Configuration Note", undefined, $$String, (function (t, configNote) {
        return {
                occRange: t.occRange,
                totBerths: t.totBerths,
                segment: t.segment,
                class: t.class,
                subclass: t.subclass,
                category: t.category,
                cabin: t.cabin,
                configNote: configNote,
                reservationNote: t.reservationNote,
                bookingExist: t.bookingExist,
                cabinStatus: t.cabinStatus
              };
      }), (function (t) {
        return t.configNote;
      }));

var reservationNote = make("cq.reservationNote", "Reservation Note", undefined, $$String, (function (t, reservationNote) {
        return {
                occRange: t.occRange,
                totBerths: t.totBerths,
                segment: t.segment,
                class: t.class,
                subclass: t.subclass,
                category: t.category,
                cabin: t.cabin,
                configNote: t.configNote,
                reservationNote: reservationNote,
                bookingExist: t.bookingExist,
                cabinStatus: t.cabinStatus
              };
      }), (function (t) {
        return t.reservationNote;
      }));

var isBooked = make("cq.isBooked", "Is Booked", undefined, Bool, (function (t, bookingExist) {
        return {
                occRange: t.occRange,
                totBerths: t.totBerths,
                segment: t.segment,
                class: t.class,
                subclass: t.subclass,
                category: t.category,
                cabin: t.cabin,
                configNote: t.configNote,
                reservationNote: t.reservationNote,
                bookingExist: bookingExist,
                cabinStatus: t.cabinStatus
              };
      }), (function (t) {
        return t.bookingExist;
      }));

var cabinStatus = make("cq.cabinStatus", "Cabin Status", [
      "Available",
      "Booked",
      "Held"
    ], $$String, (function (t, cabinStatus) {
        return {
                occRange: t.occRange,
                totBerths: t.totBerths,
                segment: t.segment,
                class: t.class,
                subclass: t.subclass,
                category: t.category,
                cabin: t.cabin,
                configNote: t.configNote,
                reservationNote: t.reservationNote,
                bookingExist: t.bookingExist,
                cabinStatus: cabinStatus
              };
      }), (function (t) {
        return t.cabinStatus;
      }));

var sortedFilters = [
  cabinStatus,
  occRange,
  totBerths,
  segment,
  $$class,
  subclass,
  category,
  cabin,
  configNote,
  reservationNote
];

var codec = QueryString$Util.Parser.make(Belt_Array.map(sortedFilters, (function (a) {
            return a.queryParam;
          })), empty);

function mapCondition(prev, $$new) {
  return function (conditions) {
    return Belt_Array.keepMap(conditions, (function (cond) {
                  if (eq$1(cond, prev)) {
                    return $$new;
                  } else {
                    return cond;
                  }
                }));
  };
}

function mapChangeFn(setState, fn) {
  return function (cond, a) {
    setState(mapCondition(cond, fn(cond, a)));
  };
}

function useState() {
  var match = React.useState(function () {
        return [];
      });
  var setState = match[1];
  var state = match[0];
  var setState$1 = function (fn) {
    setState(function (prevs) {
          return fn(prevs);
        });
  };
  var isLegalVerb = function (t, verb) {
    return Belt_Array.keep(t.sortedVerbs, (function (v) {
                  return Caml_obj.equal(v, verb);
                })).length !== 0;
  };
  var canAddCondition = function (cur) {
    if (Belt_Array.some(cur, (function (cond) {
              return eq$1(cond, "Empty");
            }))) {
      return false;
    } else {
      return sortedFilters.length > cur.length;
    }
  };
  var addCondition = function (conditions) {
    if (canAddCondition(conditions)) {
      return Belt_Array.concat(conditions, ["Empty"]);
    } else {
      return conditions;
    }
  };
  var alreadyFilteredOnCols = function (cur) {
    return Belt_SetString.fromArray(Belt_Array.keepMap(cur, (function (cond) {
                      if (typeof cond !== "object") {
                        return ;
                      } else {
                        return cond.column.id;
                      }
                    })));
  };
  var apply = function (conditions) {
    return Belt_Array.reduce(conditions, empty, (function (carry, cond) {
                  if (typeof cond !== "object") {
                    return carry;
                  }
                  var verb = cond.verb;
                  if (verb === undefined) {
                    return carry;
                  }
                  var noun = cond.noun;
                  if (noun === undefined) {
                    return carry;
                  }
                  var updatedStorage = cond.column.apply(carry, verb, noun);
                  if (updatedStorage.TAG === "Ok") {
                    return updatedStorage._0;
                  } else {
                    return carry;
                  }
                }));
  };
  var changeColumn = function (cond, newColumn) {
    if (typeof cond !== "object") {
      return {
              TAG: "Condition",
              column: newColumn
            };
    }
    var verb = cond.verb;
    if (verb !== undefined && isLegalVerb(newColumn, verb)) {
      return {
              TAG: "Condition",
              column: newColumn,
              verb: verb,
              noun: cond.noun
            };
    } else {
      return {
              TAG: "Condition",
              column: newColumn,
              noun: cond.noun
            };
    }
  };
  var changeVerb = function (cond, newVerb) {
    if (typeof cond !== "object") {
      return cond;
    }
    var column = cond.column;
    if (isLegalVerb(column, newVerb)) {
      return {
              TAG: "Condition",
              column: column,
              verb: newVerb,
              noun: cond.noun
            };
    } else {
      return cond;
    }
  };
  var changeNoun = function (cond, newNoun) {
    var match = newNoun.trim();
    if (typeof cond !== "object") {
      return cond;
    }
    var column = cond.column;
    if (match === "") {
      return {
              TAG: "Condition",
              column: column,
              verb: cond.verb
            };
    } else {
      return {
              TAG: "Condition",
              column: column,
              verb: cond.verb,
              noun: match
            };
    }
  };
  var getOptionalQueryRepr = function (storage) {
    var enc = codec.encode(storage);
    if (enc === "") {
      return ;
    } else {
      return enc;
    }
  };
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var dec = codec.decode(url.search);
  var fs = apply(state);
  return {
          conditions: state,
          addCondition: canAddCondition(state) ? (function () {
                setState$1(addCondition);
              }) : undefined,
          dropCondition: (function (drop) {
              setState$1(mapCondition(drop, undefined));
            }),
          isDisabled: (function (param) {
              return Belt_SetString.has(alreadyFilteredOnCols(state), param.id);
            }),
          urlFilters: [
            dec,
            getOptionalQueryRepr(dec)
          ],
          conditionFilters: [
            fs,
            getOptionalQueryRepr(fs)
          ],
          setColumn: mapChangeFn(setState$1, changeColumn),
          setVerb: mapChangeFn(setState$1, changeVerb),
          setNoun: mapChangeFn(setState$1, changeNoun),
          check: (function (condition) {
              if (typeof condition !== "object") {
                return {
                        TAG: "Ok",
                        _0: undefined
                      };
              }
              var verb = condition.verb;
              if (verb === undefined) {
                return {
                        TAG: "Ok",
                        _0: undefined
                      };
              }
              var noun = condition.noun;
              if (noun !== undefined) {
                return Belt_Result.map(condition.column.apply(empty, verb, noun), (function (param) {
                              
                            }));
              } else {
                return {
                        TAG: "Ok",
                        _0: undefined
                      };
              }
            }),
          setToStorage: (function (storage) {
              setState$1(function (prevConds) {
                    if (Caml_obj.equal(apply(prevConds), storage)) {
                      return prevConds;
                    } else {
                      return Belt_Array.reduce(sortedFilters, [], (function (carry, column) {
                                    var match = column.isset(storage);
                                    if (match !== undefined) {
                                      return Belt_Array.concat(carry, [{
                                                    TAG: "Condition",
                                                    column: column,
                                                    verb: match[0],
                                                    noun: match[1]
                                                  }]);
                                    } else {
                                      return carry;
                                    }
                                  }));
                    }
                  });
            })
        };
}

var ColumnFilter = {
  cmp: cmp,
  eq: eq$1,
  make: make,
  occRange: occRange,
  totBerths: totBerths,
  segment: segment,
  $$class: $$class,
  subclass: subclass,
  category: category,
  cabin: cabin,
  configNote: configNote,
  reservationNote: reservationNote,
  isBooked: isBooked,
  cabinStatus: cabinStatus,
  sortedFilters: sortedFilters,
  codec: codec,
  mapCondition: mapCondition,
  mapChangeFn: mapChangeFn,
  useState: useState
};

var codec$1 = QueryString$Util.Parser.make([QueryString$Util.Param.Singular.make("cq.page", (function (a) {
              return a;
            }), QueryString$Util.Codec.scalar(QueryString$Util.Codec.required(QueryString$Util.Codec.$$int)), undefined, (function (param, opt) {
              return Belt_Option.getWithDefault(opt, 1);
            }), (function (ext) {
              return ext;
            }))], 1);

var Pagination = {
  codec: codec$1
};

var Relay;

exports.Relay = Relay;
exports.StringImpl = StringImpl;
exports.$$String = $$String;
exports.StringRestricted = StringRestricted;
exports.Slug = Slug;
exports.IntRange = IntRange;
exports.Bool = Bool;
exports.Int = Int;
exports.$$Storage = $$Storage;
exports.ColumnFilter = ColumnFilter;
exports.Pagination = Pagination;
/* occRange Not a pure module */
