// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Stack = require("@mui/joy/Stack").default;
var AppAlert$Thick = require("../common/atoms/AppAlert.bs.js");
var Tooltip = require("@mui/joy/Tooltip").default;
var HopperState$Util = require("util/src/HopperState.bs.js");
var BookingDiff$Thick = require("../../utils/BookingDiff.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var PreviewModal$Thick = require("./drafts/PreviewModal.bs.js");
var IconButton = require("@mui/joy/IconButton").default;
var BDContentBlock$Thick = require("./molecules/BDContentBlock.bs.js");
var PreviewChanges$Thick = require("./drafts/PreviewChanges.bs.js");
var Add = require("@mui/icons-material/Add").default;
var ManageBookingState$Thick = require("../../utils/ManageBookingState.bs.js");
var CircularProgress = require("@mui/joy/CircularProgress").default;
var Restore = require("@mui/icons-material/Restore").default;
var CurrentUserSearchInput$Thick = require("../common/molecules/CurrentUserSearchInput.bs.js");
var CheckReferrerStatusOnVoyage$Thick = require("./drafts/queries/CheckReferrerStatusOnVoyage.bs.js");
var RemoveCircleOutlineOutlined = require("@mui/icons-material/RemoveCircleOutlineOutlined").default;

function BDReferrer(props) {
  var diffStruct = props.diffStruct;
  var brandFamily = props.brandFamily;
  var state = props.state;
  var match = HopperState$Util.Observable.useComputed(state, (function (m) {
          return [
                  m.wasReferred.value,
                  Belt_Option.isSome(m.wasReferred.value) && Caml_obj.equal(m.wasReferred.initialValue, m.wasReferred.value) ? "Remove" : "Add"
                ];
        }), undefined, undefined);
  var action = match[1];
  var wasReferred = match[0];
  var match$1 = PreviewChanges$Thick.useQuery(props.generatePreviewFromDiff, diffStruct, (function () {
          
        }));
  var clearPreviewFields = match$1.clearPreviewFields;
  var setApplyDiff = match$1.setApplyDiff;
  var previewLoading = match$1.loading;
  var generatePreview = match$1.generatePreview;
  var onCheckSuccess = function (allegedReferrer) {
    var applyDiff = BookingDiff$Thick.Alter.addWasReferred(diffStruct, allegedReferrer);
    setApplyDiff(function (param) {
          return applyDiff;
        });
    generatePreview(applyDiff);
  };
  var match$2 = CheckReferrerStatusOnVoyage$Thick.useQuery(brandFamily, props.voyage, wasReferred, onCheckSuccess);
  var checkAndPreviewReferrer = match$2.checkAndPreviewReferrer;
  var setCheckError = match$2.setError;
  var checkError = match$2.error;
  var checkLoading = match$2.loading;
  var actionButton = JsxRuntime.jsxs(Stack, {
        direction: "row",
        spacing: 1,
        children: [
          JsxRuntime.jsx(Tooltip, {
                children: JsxRuntime.jsx(IconButton, {
                      onClick: (function (e) {
                          e.preventDefault();
                          if (action === "Add") {
                            return checkAndPreviewReferrer();
                          }
                          var applyDiff = BookingDiff$Thick.Alter.removeWasReferred(diffStruct);
                          setApplyDiff(function (param) {
                                return applyDiff;
                              });
                          generatePreview(applyDiff);
                        }),
                      loading: previewLoading || checkLoading,
                      variant: previewLoading || checkLoading || action !== "Add" ? "soft" : "solid",
                      color: previewLoading || checkLoading ? "neutral" : (
                          action === "Add" ? "primary" : "danger"
                        ),
                      disabled: previewLoading || checkLoading || wasReferred === undefined,
                      children: Caml_option.some(previewLoading || checkLoading ? JsxRuntime.jsx(CircularProgress, {}) : (
                              action === "Add" ? JsxRuntime.jsx(Add, {}) : JsxRuntime.jsx(RemoveCircleOutlineOutlined, {})
                            ))
                    }),
                arrow: true,
                size: "sm",
                title: Caml_option.some(action === "Add" ? "Add Referrer" : "Remove Referrer")
              }),
          action === "Add" && Belt_Option.isSome(wasReferred) ? JsxRuntime.jsx(Tooltip, {
                  children: JsxRuntime.jsx(IconButton, {
                        onClick: (function (param) {
                            setCheckError(function (param) {
                                  
                                });
                            HopperState$Util.Observable.notify(state, (function (m) {
                                    return ManageBookingState$Thick.resetModelToInitialOrDraftValue(m);
                                  }));
                          }),
                        variant: "soft",
                        color: "neutral",
                        children: Caml_option.some(JsxRuntime.jsx(Restore, {}))
                      }),
                  arrow: true,
                  size: "sm",
                  title: "Reset to initial value"
                }) : null
        ]
      });
  var previewAfterApply = function (diff) {
    var id = diff.addWasReferred;
    if (id !== undefined) {
      return ManageBookingState$Thick.setDraftReferrerAsInitial(state, Caml_option.some(Caml_option.valFromOption(id)));
    } else {
      return ManageBookingState$Thick.setDraftReferrerAsInitial(state, undefined);
    }
  };
  React.useEffect((function () {
          var match = diffStruct.addWasReferred;
          var match$1 = diffStruct.removeWasReferred;
          if (match !== undefined) {
            ManageBookingState$Thick.setDraftReferrerAsInitial(state, Caml_option.some(Caml_option.valFromOption(match)));
          } else if (match$1 !== undefined) {
            ManageBookingState$Thick.setDraftReferrerAsInitial(state, undefined);
          }
          
        }), []);
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(BDContentBlock$Thick.make, {
                      title: "Referrer",
                      children: JsxRuntime.jsxs(Stack, {
                            direction: "column",
                            spacing: 1,
                            children: [
                              JsxRuntime.jsx(CurrentUserSearchInput$Thick.Optional.make, {
                                    view: {
                                      TAG: "Direct",
                                      _0: state
                                    },
                                    placeholder: "Search users",
                                    label: "Referrer",
                                    input: ManageBookingState$Thick.wasReferred,
                                    brandFamily: brandFamily,
                                    claimedAndPrefilledOnly: false,
                                    actionButton: Caml_option.some(actionButton)
                                  }),
                              checkError !== undefined ? JsxRuntime.jsx(AppAlert$Thick.make, {
                                      message: checkError,
                                      type_: "error"
                                    }) : null
                            ]
                          })
                    }),
                JsxRuntime.jsx(PreviewModal$Thick.make, {
                      billPayCurrent: props.billPayCurrent,
                      billPayNew: match$1.billPayNew,
                      applyDiff: match$1.applyDiff,
                      title: (
                        action === "Add" ? "Add" : "Remove"
                      ) + " Referrer",
                      description: "You are proposing to " + (
                        action === "Add" ? "add" : "remove"
                      ) + " a referrer to this booking. Please carefully review the changes below.",
                      onClose: (function () {
                          clearPreviewFields();
                        }),
                      open_: match$1.previewModalOpen,
                      versionUrl: props.versionUrl,
                      error: match$1.error,
                      afterApply: previewAfterApply
                    })
              ]
            });
}

var make = BDReferrer;

exports.make = make;
/* react Not a pure module */
