// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var ReactRelay = require("react-relay");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");

function makeRefetchVariables(brandFamily) {
  return {
          brandFamily: brandFamily
        };
}

var Types = {
  makeRefetchVariables: makeRefetchVariables
};

var variablesConverter = {"__root":{"brandFamily":{"c":"Util.CustomScalars.BrandFamilySlug.Exn"}}};

var variablesConverterMap = {
  "Util.CustomScalars.BrandFamilySlug.Exn": CS_Slugs$Util.BrandFamilySlug.Exn.serialize
};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, variablesConverterMap, undefined);
}

var wrapResponseConverter = {"__root":{"":{"f":""}}};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, undefined, null);
}

var responseConverter = {"__root":{"":{"f":""}}};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, undefined, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: variablesConverterMap,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: undefined,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: undefined,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse
};

var Utils = {};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "brandFamily"
  }
],
v1 = {
  "fields": [
    {
      "kind": "Variable",
      "name": "brandFamilySlug",
      "variableName": "brandFamily"
    }
  ],
  "kind": "ObjectValue",
  "name": "condition"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "UserRoleGrantsConnection",
  "kind": "LinkedField",
  "name": "userRoleGrants",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserRoleGrant",
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "brandfamRole",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasRole",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "UserVoyageGrantsConnection",
  "kind": "LinkedField",
  "name": "userVoyageGrants",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserVoyageGrant",
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "voyageSlug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasVoyage",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "brandFamilySlug",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TeamPageQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "brandFamily",
            "variableName": "brandFamily"
          }
        ],
        "kind": "FragmentSpread",
        "name": "TeamPage_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TeamPageQuery",
    "selections": [
      {
        "alias": "team",
        "args": [
          (v1/*: any*/),
          {
            "kind": "Literal",
            "name": "filter",
            "value": {
              "signonStatus": {
                "notEqualTo": "INVITED"
              }
            }
          }
        ],
        "concreteType": "BrandFamilyUsersConnection",
        "kind": "LinkedField",
        "name": "brandFamilyUsers",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BrandFamilyUser",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "signonStatus",
                "storageKey": null
              },
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "pending",
        "args": [
          (v1/*: any*/),
          {
            "kind": "Literal",
            "name": "filter",
            "value": {
              "signonStatus": {
                "equalTo": "INVITED"
              }
            }
          }
        ],
        "concreteType": "BrandFamilyUsersConnection",
        "kind": "LinkedField",
        "name": "brandFamilyUsers",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BrandFamilyUser",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          (v1/*: any*/)
        ],
        "concreteType": "CurrentUserProfilesConnection",
        "kind": "LinkedField",
        "name": "currentUserProfiles",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CurrentUserProfile",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "brandFamilyRoles",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "forBrandFamilySlug",
            "variableName": "brandFamily"
          }
        ],
        "kind": "ScalarField",
        "name": "currentUserCanForBrand",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currentUserSignonStatus",
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1de526a27c9f914ac126b4e4b8e57f3b",
    "id": null,
    "metadata": {},
    "name": "TeamPageQuery",
    "operationKind": "query",
    "text": "query TeamPageQuery(\n  $brandFamily: BrandFamilySlug!\n) {\n  ...TeamPage_query_2PeJLC\n}\n\nfragment AlterUserForm_user on BrandFamilyUser {\n  email\n  brandFamilySlug\n  userRoleGrants {\n    ...RoleGrantsView_rolegrant\n  }\n  userVoyageGrants {\n    ...VoyageGrantsView_voyagegrant\n  }\n}\n\nfragment BrandPermProtected_query_2PeJLC on Query {\n  currentUserCanForBrand(forBrandFamilySlug: $brandFamily)\n  currentUserSignonStatus\n}\n\nfragment RoleGrantsView_rolegrant on UserRoleGrantsConnection {\n  nodes {\n    brandfamRole\n    hasRole\n  }\n}\n\nfragment TeamPage_query_2PeJLC on Query {\n  team: brandFamilyUsers(condition: {brandFamilySlug: $brandFamily}, filter: {signonStatus: {notEqualTo: INVITED}}) {\n    nodes {\n      id\n      signonStatus\n      ...TeamPage_user\n      ...AlterUserForm_user\n    }\n  }\n  pending: brandFamilyUsers(condition: {brandFamilySlug: $brandFamily}, filter: {signonStatus: {equalTo: INVITED}}) {\n    nodes {\n      id\n      ...TeamPage_user\n      ...AlterUserForm_user\n    }\n  }\n  currentUserProfiles(condition: {brandFamilySlug: $brandFamily}) {\n    nodes {\n      brandFamilyRoles\n    }\n  }\n  ...BrandPermProtected_query_2PeJLC\n}\n\nfragment TeamPage_user on BrandFamilyUser {\n  email\n  userRoleGrants {\n    ...RoleGrantsView_rolegrant\n  }\n  userVoyageGrants {\n    ...VoyageGrantsView_voyagegrant\n  }\n}\n\nfragment VoyageGrantsView_voyagegrant on UserVoyageGrantsConnection {\n  nodes {\n    voyageSlug\n    hasVoyage\n  }\n}\n"
  }
};
})());

function load(environment, variables, fetchPolicy, fetchKey, networkCacheConfig) {
  return ReactRelay.loadQuery(environment, node, convertVariables(variables), {
              fetchKey: fetchKey,
              fetchPolicy: fetchPolicy,
              networkCacheConfig: networkCacheConfig
            });
}

function queryRefToObservable(token) {
  return Caml_option.nullable_to_opt(token.source);
}

function queryRefToPromise(token) {
  return new Promise((function (resolve, param) {
                var o = queryRefToObservable(token);
                if (o !== undefined) {
                  Caml_option.valFromOption(o).subscribe({
                        complete: (function () {
                            resolve({
                                  TAG: "Ok",
                                  _0: undefined
                                });
                          })
                      });
                  return ;
                } else {
                  return resolve({
                              TAG: "Error",
                              _0: undefined
                            });
                }
              }));
}

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
exports.load = load;
exports.queryRefToObservable = queryRefToObservable;
exports.queryRefToPromise = queryRefToPromise;
/* node Not a pure module */
