// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var CS_Phone$Util = require("util/src/CustomScalars/CS_Phone.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var CS_ISO3166_1$Util = require("util/src/CustomScalars/CS_ISO3166_1.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var CS_SQLDatesAndTimes$Util = require("util/src/CustomScalars/CS_SQLDatesAndTimes.bs.js");
var CS_LoyaltyAndCruiselineNumbers$Util = require("util/src/CustomScalars/CS_LoyaltyAndCruiselineNumbers.bs.js");

var Types = {};

var variablesConverter = {"newPrefilledBfcustInput":{"residenceCountry":{"c":"Util.CustomScalars.ISO3166_1.Alpha3.Exn"},"preferredLastName":{"c":"Util.CustomScalars.NonemptyString.Exn"},"preferredFirstName":{"c":"Util.CustomScalars.NonemptyString.Exn"},"postalCode":{"c":"Util.CustomScalars.PostalCode.Exn"},"phone":{"c":"Util.CustomScalars.Phone.Exn"},"halMarinerNumber":{"c":"Util.CustomScalars.HalMarinerNumber.Exn"},"email":{"c":"Util.CustomScalars.Email.Exn"},"docLastName":{"c":"Util.CustomScalars.NonemptyString.Exn"},"docFirstName":{"c":"Util.CustomScalars.NonemptyString.Exn"},"docCitizenship":{"c":"Util.CustomScalars.ISO3166_1.Alpha3.Exn"},"docBirthdate":{"c":"Util.CustomScalars.SQLDate.Exn"},"custAcquisitionSlug":{"c":"Util.CustomScalars.Slug.Exn"}},"createPrefilledBfcustUserInput":{"toBrandFamilySlug":{"c":"Util.CustomScalars.BrandFamilySlug.Exn"},"newPrefill":{"r":"newPrefilledBfcustInput"}},"__root":{"input":{"r":"createPrefilledBfcustUserInput"}}};

var variablesConverterMap = {
  "Util.CustomScalars.BrandFamilySlug.Exn": CS_Slugs$Util.BrandFamilySlug.Exn.serialize,
  "Util.CustomScalars.Slug.Exn": CS_Slugs$Util.Slug.Exn.serialize,
  "Util.CustomScalars.SQLDate.Exn": CS_SQLDatesAndTimes$Util.DateOnly.Exn.serialize,
  "Util.CustomScalars.ISO3166_1.Alpha3.Exn": CS_ISO3166_1$Util.Alpha3.Exn.serialize,
  "Util.CustomScalars.NonemptyString.Exn": CS_NonemptyStrings$Util.NonEmptyString.Exn.serialize,
  "Util.CustomScalars.Email.Exn": CS_Emails$Util.Email.Exn.serialize,
  "Util.CustomScalars.HalMarinerNumber.Exn": CS_LoyaltyAndCruiselineNumbers$Util.HalMarinerNumber.Exn.serialize,
  "Util.CustomScalars.Phone.Exn": CS_Phone$Util.PhoneImpl.Exn.serialize,
  "Util.CustomScalars.PostalCode.Exn": CS_NonemptyStrings$Util.PostalCode.Exn.serialize
};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, variablesConverterMap, undefined);
}

var wrapResponseConverter = {"__root":{"createPrefilledBfcustUser_result_createdAccount":{"c":"Util.CustomScalars.AccountId.Exn"}}};

var wrapResponseConverterMap = {
  "Util.CustomScalars.AccountId.Exn": CS_NonemptyStrings$Util.AccountId.Exn.serialize
};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, wrapResponseConverterMap, null);
}

var responseConverter = {"__root":{"createPrefilledBfcustUser_result_createdAccount":{"c":"Util.CustomScalars.AccountId.Exn"}}};

var responseConverterMap = {
  "Util.CustomScalars.AccountId.Exn": CS_NonemptyStrings$Util.AccountId.Exn.parse
};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, responseConverterMap, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: variablesConverterMap,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: wrapResponseConverterMap,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: responseConverterMap,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse
};

function createBfcustUserErr_decode($$enum) {
  if ($$enum === "USER_CANT_BE_CREATED_ASK_SUPPORT" || $$enum === "EMAIL_ALREADY_IN_USE" || $$enum === "BRANDFAM_ACTION_NOT_PERMITTED") {
    return $$enum;
  }
  
}

function createBfcustUserErr_fromString(str) {
  return createBfcustUserErr_decode(str);
}

function docGender_decode($$enum) {
  if ($$enum === "F" || $$enum === "M" || $$enum === "X") {
    return $$enum;
  }
  
}

function docGender_fromString(str) {
  return docGender_decode(str);
}

var Utils = {
  createBfcustUserErr_decode: createBfcustUserErr_decode,
  createBfcustUserErr_fromString: createBfcustUserErr_fromString,
  docGender_decode: docGender_decode,
  docGender_fromString: docGender_fromString
};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreatePrefilledBfcustUserPayload",
    "kind": "LinkedField",
    "name": "createPrefilledBfcustUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CreatePrefilledBfcustUserRecord",
        "kind": "LinkedField",
        "name": "result",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nullableError",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAccount",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CustomersState_PersistPrefillMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CustomersState_PersistPrefillMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ba412493e77f961cd55a752c26ed3f97",
    "id": null,
    "metadata": {},
    "name": "CustomersState_PersistPrefillMutation",
    "operationKind": "mutation",
    "text": "mutation CustomersState_PersistPrefillMutation(\n  $input: CreatePrefilledBfcustUserInput!\n) {\n  createPrefilledBfcustUser(input: $input) {\n    result {\n      nullableError\n      createdAccount\n    }\n  }\n}\n"
  }
};
})());

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
