// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var RelayEnv$Thick = require("../../../../RelayEnv.bs.js");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var CheckReferrerStatusOnVoyageQuery_graphql$Thick = require("../../../../__generated__/CheckReferrerStatusOnVoyageQuery_graphql.bs.js");

var convertVariables = CheckReferrerStatusOnVoyageQuery_graphql$Thick.Internal.convertVariables;

var convertResponse = CheckReferrerStatusOnVoyageQuery_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = CheckReferrerStatusOnVoyageQuery_graphql$Thick.Internal.convertWrapRawResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, CheckReferrerStatusOnVoyageQuery_graphql$Thick.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, CheckReferrerStatusOnVoyageQuery_graphql$Thick.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(CheckReferrerStatusOnVoyageQuery_graphql$Thick.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(CheckReferrerStatusOnVoyageQuery_graphql$Thick.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(CheckReferrerStatusOnVoyageQuery_graphql$Thick.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(CheckReferrerStatusOnVoyageQuery_graphql$Thick.node, convertVariables);

var Query = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function useQuery(referralBrand, referralVoyage, wasReferred, onCheckSuccess) {
  var match = React.useState(function () {
        return false;
      });
  var setLoading = match[1];
  var match$1 = React.useState(function () {
        
      });
  var setError = match$1[1];
  var checkAndPreviewReferrer = function (param) {
    var match = Belt_Option.map(wasReferred, CS_NonemptyStrings$Util.AccountId.fromPrimitive);
    if (match !== undefined && match.TAG === "Ok") {
      var allegedReferrer = match._0;
      setLoading(function (param) {
            return true;
          });
      setError(function (param) {
            
          });
      return $$fetch(RelayEnv$Thick.environment, {
                  allegedReferrer: allegedReferrer,
                  referralBrand: referralBrand,
                  referralVoyage: referralVoyage
                }, (function (res) {
                    if (res.TAG === "Ok") {
                      var match = res._0.checkReferrerStatusOnVoyage;
                      if (match !== undefined) {
                        if (match) {
                          onCheckSuccess(allegedReferrer);
                        } else {
                          setError(function (param) {
                                return "The referrer you selected cannot be added to this booking. Please try again.";
                              });
                        }
                      } else {
                        setError(function (param) {
                              return "An error occurred while checking the referrer status. Please try again.";
                            });
                      }
                    } else {
                      console.error(res._0);
                      setError(function (param) {
                            return "An error occurred while checking the referrer status. Please try again.";
                          });
                    }
                    setLoading(function (param) {
                          return false;
                        });
                  }), undefined, undefined);
    }
    setError(function (param) {
          return "The id you selected is not a valid Account Id. Please try again.";
        });
  };
  return {
          loading: match[0],
          error: match$1[0],
          setError: setError,
          checkAndPreviewReferrer: checkAndPreviewReferrer
        };
}

exports.Query = Query;
exports.useQuery = useQuery;
/* use Not a pure module */
