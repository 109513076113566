// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Dialog$Thick = require("../common/molecules/Dialog.bs.js");
var Radio = require("@mui/joy/Radio").default;
var Stack = require("@mui/joy/Stack").default;
var AppAlert$Thick = require("../common/atoms/AppAlert.bs.js");
var RelayEnv$Thick = require("../../RelayEnv.bs.js");
var Button = require("@mui/joy/Button").default;
var JsxRuntime = require("react/jsx-runtime");
var FormLabel = require("@mui/joy/FormLabel").default;
var RadioGroup = require("@mui/joy/RadioGroup").default;
var Typography = require("@mui/joy/Typography").default;
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var FormControl = require("@mui/joy/FormControl").default;
var DialogActions = require("@mui/joy/DialogActions").default;
var DialogContent = require("@mui/joy/DialogContent").default;
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var CircularProgress = require("@mui/joy/CircularProgress").default;
var DeleteOrBookModalQuery_graphql$Thick = require("../../__generated__/DeleteOrBookModalQuery_graphql.bs.js");
var DeleteOrBookModalSuggestQuery_graphql$Thick = require("../../__generated__/DeleteOrBookModalSuggestQuery_graphql.bs.js");
var DeleteOrBookModal_BookMutation_graphql$Thick = require("../../__generated__/DeleteOrBookModal_BookMutation_graphql.bs.js");
var DeleteOrBookModal_AlterBookingSessionDeleteMutation_graphql$Thick = require("../../__generated__/DeleteOrBookModal_AlterBookingSessionDeleteMutation_graphql.bs.js");

var convertVariables = DeleteOrBookModal_AlterBookingSessionDeleteMutation_graphql$Thick.Internal.convertVariables;

var convertResponse = DeleteOrBookModal_AlterBookingSessionDeleteMutation_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = DeleteOrBookModal_AlterBookingSessionDeleteMutation_graphql$Thick.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables, DeleteOrBookModal_AlterBookingSessionDeleteMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, DeleteOrBookModal_AlterBookingSessionDeleteMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse);

var DeleteBookingSessionMutation = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

var convertVariables$1 = DeleteOrBookModal_BookMutation_graphql$Thick.Internal.convertVariables;

var convertResponse$1 = DeleteOrBookModal_BookMutation_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse$1 = DeleteOrBookModal_BookMutation_graphql$Thick.Internal.convertWrapRawResponse;

var commitMutation$1 = RescriptRelay_Mutation.commitMutation(convertVariables$1, DeleteOrBookModal_BookMutation_graphql$Thick.node, convertResponse$1, convertWrapRawResponse$1);

var use$1 = RescriptRelay_Mutation.useMutation(convertVariables$1, DeleteOrBookModal_BookMutation_graphql$Thick.node, convertResponse$1, convertWrapRawResponse$1);

var BookMutation_bookingSessionError_decode = DeleteOrBookModal_BookMutation_graphql$Thick.Utils.bookingSessionError_decode;

var BookMutation_bookingSessionError_fromString = DeleteOrBookModal_BookMutation_graphql$Thick.Utils.bookingSessionError_fromString;

var BookMutation = {
  bookingSessionError_decode: BookMutation_bookingSessionError_decode,
  bookingSessionError_fromString: BookMutation_bookingSessionError_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables$1,
  convertResponse: convertResponse$1,
  convertWrapRawResponse: convertWrapRawResponse$1,
  commitMutation: commitMutation$1,
  use: use$1
};

var convertVariables$2 = DeleteOrBookModalQuery_graphql$Thick.Internal.convertVariables;

var convertResponse$2 = DeleteOrBookModalQuery_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse$2 = DeleteOrBookModalQuery_graphql$Thick.Internal.convertWrapRawResponse;

var use$2 = RescriptRelay_Query.useQuery(convertVariables$2, DeleteOrBookModalQuery_graphql$Thick.node, convertResponse$2);

var useLoader = RescriptRelay_Query.useLoader(convertVariables$2, DeleteOrBookModalQuery_graphql$Thick.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(DeleteOrBookModalQuery_graphql$Thick.node, convertResponse$2, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(DeleteOrBookModalQuery_graphql$Thick.node, convertResponse$2, convertVariables$2);

var fetchPromised = RescriptRelay_Query.fetchPromised(DeleteOrBookModalQuery_graphql$Thick.node, convertResponse$2, convertVariables$2);

var retain = RescriptRelay_Query.retain(DeleteOrBookModalQuery_graphql$Thick.node, convertVariables$2);

var ValidateQuery_bookingSessionError_decode = DeleteOrBookModalQuery_graphql$Thick.Utils.bookingSessionError_decode;

var ValidateQuery_bookingSessionError_fromString = DeleteOrBookModalQuery_graphql$Thick.Utils.bookingSessionError_fromString;

var ValidateQuery = {
  bookingSessionError_decode: ValidateQuery_bookingSessionError_decode,
  bookingSessionError_fromString: ValidateQuery_bookingSessionError_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables$2,
  convertResponse: convertResponse$2,
  convertWrapRawResponse: convertWrapRawResponse$2,
  use: use$2,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

var convertVariables$3 = DeleteOrBookModalSuggestQuery_graphql$Thick.Internal.convertVariables;

var convertResponse$3 = DeleteOrBookModalSuggestQuery_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse$3 = DeleteOrBookModalSuggestQuery_graphql$Thick.Internal.convertWrapRawResponse;

var use$3 = RescriptRelay_Query.useQuery(convertVariables$3, DeleteOrBookModalSuggestQuery_graphql$Thick.node, convertResponse$3);

var useLoader$1 = RescriptRelay_Query.useLoader(convertVariables$3, DeleteOrBookModalSuggestQuery_graphql$Thick.node, (function (prim) {
        return prim;
      }));

var usePreloaded$1 = RescriptRelay_Query.usePreloaded(DeleteOrBookModalSuggestQuery_graphql$Thick.node, convertResponse$3, (function (prim) {
        return prim;
      }));

var $$fetch$1 = RescriptRelay_Query.$$fetch(DeleteOrBookModalSuggestQuery_graphql$Thick.node, convertResponse$3, convertVariables$3);

var fetchPromised$1 = RescriptRelay_Query.fetchPromised(DeleteOrBookModalSuggestQuery_graphql$Thick.node, convertResponse$3, convertVariables$3);

var retain$1 = RescriptRelay_Query.retain(DeleteOrBookModalSuggestQuery_graphql$Thick.node, convertVariables$3);

var SuggestionsQuery = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables$3,
  convertResponse: convertResponse$3,
  convertWrapRawResponse: convertWrapRawResponse$3,
  use: use$3,
  useLoader: useLoader$1,
  usePreloaded: usePreloaded$1,
  $$fetch: $$fetch$1,
  fetchPromised: fetchPromised$1,
  retain: retain$1
};

function DeleteOrBookModal(props) {
  var filter = props.filter;
  var state = props.state;
  var refetchList = props.refetchList;
  var voyage = props.voyage;
  var brandFamily = props.brandFamily;
  var focusedSession = props.focusedSession;
  var onClose = props.onClose;
  var match = focusedSession !== undefined ? (
      focusedSession.TAG === "Delete" ? [
          "Delete Booking Session",
          "Are you sure you want to delete this booking session?",
          "Delete"
        ] : [
          "Attempt Booking",
          "You are about to attempt to book this session. Are you sure?",
          "Attempt Booking"
        ]
    ) : [
      "No session selected",
      "Please select a booking session in order to delete or book!",
      "Close"
    ];
  var match$1 = use();
  var $$delete = match$1[0];
  var match$2 = use$1();
  var book = match$2[0];
  var match$3 = React.useState(function () {
        
      });
  var setError = match$3[1];
  var error = match$3[0];
  var match$4 = React.useState(function () {
        
      });
  var setSuccess = match$4[1];
  var success = match$4[0];
  var match$5 = React.useState(function () {
        return false;
      });
  var setValidateLoading = match$5[1];
  var match$6 = React.useState(function () {
        
      });
  var setValidationResult = match$6[1];
  var validationResult = match$6[0];
  var match$7 = React.useState(function () {
        return "";
      });
  var setProposedBookingId = match$7[1];
  var proposedBookingId = match$7[0];
  var match$8 = React.useState(function () {
        
      });
  var setIdSuggestions = match$8[1];
  var idSuggestions = match$8[0];
  var match$9 = React.useState(function () {
        return false;
      });
  var setIdSuggestionsLoading = match$9[1];
  var onClose$1 = function () {
    onClose();
    setError(function (param) {
          
        });
    setSuccess(function (param) {
          
        });
    setProposedBookingId(function (param) {
          return "";
        });
    setIdSuggestions(function (param) {
          
        });
    setValidationResult(function (param) {
          
        });
  };
  var fetchSuggestions = function (session) {
    setIdSuggestionsLoading(function (param) {
          return true;
        });
    $$fetch$1(RelayEnv$Thick.environment, {
          _billingContactAccountId: session.bcId,
          _brandFamilySlug: brandFamily,
          _fareClassSlug: session.fcSlug,
          _voyageSlug: voyage
        }, (function (res) {
            if (res.TAG === "Ok") {
              var sessOptions = res._0.suggestBookingIds;
              if (sessOptions !== undefined) {
                var options = Belt_Array.keepMap(sessOptions, (function (v) {
                        if (v !== undefined) {
                          return CS_NonemptyStrings$Util.BookingId.toString(Caml_option.valFromOption(v));
                        }
                        
                      }));
                setIdSuggestions(function (param) {
                      return options;
                    });
              }
              
            }
            setIdSuggestionsLoading(function (param) {
                  return false;
                });
          }), undefined, undefined);
  };
  React.useEffect((function () {
          if (focusedSession !== undefined && focusedSession.TAG !== "Delete") {
            fetchSuggestions(focusedSession._0);
          }
          
        }), [JSON.stringify(focusedSession)]);
  var tmp;
  tmp = focusedSession !== undefined && !(focusedSession.TAG === "Delete" || success !== undefined) ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsxs(FormControl, {
                  error: proposedBookingId !== "" && Belt_Result.isError(CS_NonemptyStrings$Util.BookingId.fromPrimitive(proposedBookingId)),
                  children: [
                    JsxRuntime.jsx(FormLabel, {
                          children: "Proposed Booking ID (optional)"
                        }),
                    JsxRuntime.jsx(Joy$Util.Input.make, {
                          type_: "text",
                          required: false,
                          value: proposedBookingId,
                          onChange: (function (e) {
                              var value = e.currentTarget.value;
                              setProposedBookingId(function (param) {
                                    return value;
                                  });
                            })
                        })
                  ]
                }),
            match$9[0] ? JsxRuntime.jsx(Typography, {
                    color: "inherit",
                    startDecorator: Caml_option.some(JsxRuntime.jsx(CircularProgress, {
                              color: "primary",
                              size: "sm",
                              variant: "solid"
                            })),
                    level: "body-xs",
                    sx: {
                      fontStyle: "italic"
                    },
                    children: "Fetching suggestions..."
                  }) : null,
            idSuggestions !== undefined && idSuggestions.length !== 0 ? JsxRuntime.jsxs(FormControl, {
                    sx: {
                      width: "fit-content"
                    },
                    children: [
                      JsxRuntime.jsx(FormLabel, {
                            children: "Booking ID suggestions:"
                          }),
                      JsxRuntime.jsx(RadioGroup, {
                            value: proposedBookingId,
                            onChange: (function (e) {
                                var value = e.currentTarget.value;
                                setProposedBookingId(function (param) {
                                      return value;
                                    });
                              }),
                            children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                      spacing: 1,
                                      children: Caml_option.some(Belt_Array.map(idSuggestions, (function (suggestion) {
                                                  return JsxRuntime.jsx(Radio, {
                                                              value: suggestion,
                                                              label: suggestion,
                                                              size: "sm"
                                                            }, suggestion);
                                                })))
                                    }))
                          })
                    ]
                  }) : null
          ]
        }) : null;
  var tmp$1;
  tmp$1 = validationResult !== undefined ? (
      typeof validationResult !== "object" ? JsxRuntime.jsx(AppAlert$Thick.make, {
              message: "This booking session is valid!",
              type_: "success"
            }) : JsxRuntime.jsx(AppAlert$Thick.make, {
              message: validationResult._0,
              type_: "error"
            })
    ) : null;
  var tmp$2;
  if (success !== undefined) {
    tmp$2 = (function (param) {
        onClose$1();
      });
  } else if (focusedSession !== undefined) {
    if (focusedSession.TAG === "Delete") {
      var sessionId = focusedSession._0;
      tmp$2 = (function (e) {
          e.preventDefault();
          $$delete({
                input: {
                  _bookingSessionId: sessionId,
                  _brandFamilySlug: brandFamily,
                  _voyageSlug: voyage
                }
              }, undefined, undefined, undefined, (function (response, param) {
                  var match = response.deleteBookingSession;
                  if (match !== undefined) {
                    var match$1 = match.boolean;
                    if (match$1 !== undefined && match$1) {
                      onClose$1();
                      return refetchList(state, filter);
                    }
                    
                  }
                  setError(function (param) {
                        return "An error occurred while deleting the booking session.";
                      });
                }), undefined, undefined);
        });
    } else {
      var sessId = focusedSession._0.sessId;
      tmp$2 = (function (e) {
          e.preventDefault();
          var bookingId = CS_NonemptyStrings$Util.BookingId.fromPrimitive(proposedBookingId);
          var _bookingId;
          _bookingId = bookingId.TAG === "Ok" ? Caml_option.some(bookingId._0) : undefined;
          setValidationResult(function (param) {
                
              });
          setError(function (param) {
                
              });
          book({
                input: {
                  _brandFamilySlug: brandFamily,
                  _voyageSlug: voyage,
                  _bookingSessionId: sessId,
                  _bookingId: _bookingId
                }
              }, undefined, undefined, undefined, (function (response, param) {
                  var match = response.book;
                  if (match !== undefined) {
                    var match$1 = match.createBookingResult;
                    if (match$1 !== undefined) {
                      var bookingId = match$1.bookingId;
                      var exit = 0;
                      if (bookingId !== undefined) {
                        var bookingVersionNumber = match$1.bookingVersionNumber;
                        if (bookingVersionNumber !== undefined) {
                          if (match$1.success) {
                            var bookingId$1 = Caml_option.valFromOption(bookingId);
                            setSuccess(function (param) {
                                  return {
                                          bookingId: bookingId$1,
                                          bookingVersionNumber: bookingVersionNumber
                                        };
                                });
                            return refetchList(state, filter);
                          }
                          exit = 2;
                        } else {
                          exit = 2;
                        }
                      } else {
                        exit = 2;
                      }
                      if (exit === 2) {
                        var error = match$1.nullableError;
                        if (error !== undefined && !match$1.success) {
                          return setError(function (param) {
                                      return error;
                                    });
                        }
                        
                      }
                      
                    }
                    
                  }
                  setError(function (param) {
                        return "An error occurred while booking the session.";
                      });
                }), undefined, undefined);
        });
    }
  } else {
    tmp$2 = (function (param) {
        onClose$1();
      });
  }
  var tmp$3;
  tmp$3 = success !== undefined ? "success" : (
      focusedSession !== undefined && focusedSession.TAG === "Delete" ? "danger" : "primary"
    );
  var tmp$4;
  if (focusedSession !== undefined && !(focusedSession.TAG === "Delete" || success !== undefined)) {
    var sessId$1 = focusedSession._0.sessId;
    tmp$4 = JsxRuntime.jsx(Button, {
          loading: match$5[0],
          onClick: (function (e) {
              e.preventDefault();
              var bookingId = CS_NonemptyStrings$Util.BookingId.fromPrimitive(proposedBookingId);
              var _targetBookingId;
              _targetBookingId = bookingId.TAG === "Ok" ? Caml_option.some(bookingId._0) : undefined;
              setValidationResult(function (param) {
                    
                  });
              setError(function (param) {
                    
                  });
              setValidateLoading(function (param) {
                    return true;
                  });
              $$fetch(RelayEnv$Thick.environment, {
                    _bookingSessionIds: [Caml_option.some(sessId$1)],
                    _brandFamilySlug: brandFamily,
                    _targetBookingId: _targetBookingId,
                    _voyageSlug: voyage
                  }, (function (res) {
                      var exit = 0;
                      if (res.TAG === "Ok") {
                        var match = res._0.validateBookingSessions;
                        if (match !== undefined) {
                          var match$1 = match.nodes;
                          if (match$1.length !== 1) {
                            exit = 1;
                          } else {
                            var match$2 = match$1[0];
                            var nullableErr = match$2.nullableErr;
                            var isValid = match$2.isValid;
                            if (nullableErr !== undefined) {
                              setError(function (param) {
                                    return nullableErr;
                                  });
                            } else {
                              setValidationResult(function (param) {
                                    if (Caml_obj.equal(isValid, true)) {
                                      return "Valid";
                                    } else {
                                      return {
                                              TAG: "Invalid",
                                              _0: "The booking session is invalid\n              and cannot be booked."
                                            };
                                    }
                                  });
                            }
                          }
                        } else {
                          exit = 1;
                        }
                      } else {
                        exit = 1;
                      }
                      if (exit === 1) {
                        setValidationResult(function (param) {
                              return {
                                      TAG: "Invalid",
                                      _0: "An error occurred while validating the booking session."
                                    };
                            });
                      }
                      setValidateLoading(function (param) {
                            return false;
                          });
                    }), undefined, undefined);
            }),
          variant: "outlined",
          size: "lg",
          children: "Validate Booking"
        });
  } else {
    tmp$4 = null;
  }
  return JsxRuntime.jsxs(Dialog$Thick.make, {
              open_: props.open_,
              onClose: onClose$1,
              title: match[0],
              children: [
                JsxRuntime.jsx(DialogContent, {
                      children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                spacing: 2,
                                children: [
                                  success !== undefined ? JsxRuntime.jsxs(Stack, {
                                          direction: "column",
                                          spacing: 1,
                                          children: [
                                            JsxRuntime.jsx(Typography, {
                                                  color: "success",
                                                  children: "You have successfully booked this session!"
                                                }),
                                            JsxRuntime.jsx(Typography, {
                                                  component: "span",
                                                  fontWeight: "bold",
                                                  children: Caml_option.some("Booking Id: " + CS_NonemptyStrings$Util.BookingId.toString(success.bookingId))
                                                }),
                                            JsxRuntime.jsx(Typography, {
                                                  component: "span",
                                                  fontWeight: "bold",
                                                  children: Caml_option.some("Version: " + String(success.bookingVersionNumber))
                                                })
                                          ]
                                        }) : JsxRuntime.jsx(Typography, {
                                          children: Caml_option.some(match[1])
                                        }),
                                  tmp,
                                  error !== undefined ? JsxRuntime.jsx(AppAlert$Thick.make, {
                                          message: error,
                                          type_: "error"
                                        }) : null,
                                  tmp$1
                                ]
                              })),
                      sx: {
                        maxWidth: "500px"
                      }
                    }),
                JsxRuntime.jsxs(DialogActions, {
                      children: [
                        JsxRuntime.jsx(Button, {
                              loading: match$2[1] || match$1[1],
                              onClick: tmp$2,
                              color: tmp$3,
                              size: "lg",
                              children: Caml_option.some(success !== undefined ? "Close" : match[2])
                            }),
                        tmp$4
                      ]
                    })
              ]
            });
}

var make = DeleteOrBookModal;

exports.DeleteBookingSessionMutation = DeleteBookingSessionMutation;
exports.BookMutation = BookMutation;
exports.ValidateQuery = ValidateQuery;
exports.SuggestionsQuery = SuggestionsQuery;
exports.make = make;
/* commitMutation Not a pure module */
