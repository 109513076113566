// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var Spinner$Thick = require("../components/common/atoms/Spinner.bs.js");
var HopperState$Util = require("util/src/HopperState.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var MergeAccounts$Thick = require("../utils/MergeAccounts.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var DashboardLayout$Thick = require("../layouts/DashboardLayout.bs.js");
var MergeAccountsPage$Thick = require("../pages/MergeAccountsPage.bs.js");
var OrgProtectedRoute$Thick = require("../components/wrappers/OrgProtectedRoute.bs.js");
var BrandPermProtected$Thick = require("../components/wrappers/BrandPermProtected.bs.js");
var MergeAccountsRouteQuery_graphql$Thick = require("../__generated__/MergeAccountsRouteQuery_graphql.bs.js");

var convertVariables = MergeAccountsRouteQuery_graphql$Thick.Internal.convertVariables;

var convertResponse = MergeAccountsRouteQuery_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = MergeAccountsRouteQuery_graphql$Thick.Internal.convertWrapRawResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, MergeAccountsRouteQuery_graphql$Thick.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, MergeAccountsRouteQuery_graphql$Thick.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(MergeAccountsRouteQuery_graphql$Thick.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(MergeAccountsRouteQuery_graphql$Thick.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(MergeAccountsRouteQuery_graphql$Thick.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(MergeAccountsRouteQuery_graphql$Thick.node, convertVariables);

var Query = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function MergeAccountsRoute(props) {
  var brandFamily = props.brandFamily;
  var data = use({
        brandFamily: brandFamily
      }, undefined, undefined, undefined);
  var fragmentRefs = data.fragmentRefs;
  var state = HopperState$Util.Observable.useStore((function () {
          return MergeAccounts$Thick.blankModel(RescriptReactRouter.dangerouslyGetInitialUrl(undefined, undefined));
        }), true);
  HopperState$Util.Observable.useManagedQueryString(state, MergeAccounts$Thick.QueryString.queryStateLens, {
        read: (function (m) {
            return m.url;
          }),
        write: (function (m, url) {
            return {
                    source: m.source,
                    dest: m.dest,
                    url: url,
                    sourceAcct: m.sourceAcct,
                    destAcct: m.destAcct,
                    mergeability: m.mergeability,
                    mergedStatus: m.mergedStatus,
                    mergeModal: m.mergeModal
                  };
          })
      }, MergeAccounts$Thick.QueryString.getParser(RescriptReactRouter.dangerouslyGetInitialUrl(undefined, undefined)), (function (param) {
          var path = param.path;
          if (!path) {
            return false;
          }
          var match = path.tl;
          if (match && match.hd === "merge-accounts" && !match.tl) {
            return true;
          } else {
            return false;
          }
        }), 555);
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  HopperState$Util.Observable.notify(state, (function (s) {
          return {
                  source: s.source,
                  dest: s.dest,
                  url: url,
                  sourceAcct: s.sourceAcct,
                  destAcct: s.destAcct,
                  mergeability: s.mergeability,
                  mergedStatus: s.mergedStatus,
                  mergeModal: s.mergeModal
                };
        }));
  return JsxRuntime.jsx(DashboardLayout$Thick.make, {
              fragmentRefs: fragmentRefs,
              children: JsxRuntime.jsx(React.Suspense, {
                    children: Caml_option.some(JsxRuntime.jsx(OrgProtectedRoute$Thick.make, {
                              fragmentRefs: fragmentRefs,
                              children: JsxRuntime.jsx(BrandPermProtected$Thick.make, {
                                    fragmentRefs: fragmentRefs,
                                    neededPerm: "VIEW_CUSTOMER_DETAILS",
                                    children: JsxRuntime.jsx(MergeAccountsPage$Thick.make, {
                                          brandFamily: brandFamily,
                                          state: state
                                        }),
                                    isRoute: true,
                                    redirect: "/" + CS_Slugs$Util.BrandFamilySlug.toString(brandFamily)
                                  })
                            })),
                    fallback: Caml_option.some(JsxRuntime.jsx(Spinner$Thick.make, {}))
                  }),
              brandFamily: Caml_option.some(brandFamily)
            });
}

var make = MergeAccountsRoute;

exports.Query = Query;
exports.make = make;
/* use Not a pure module */
