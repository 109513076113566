// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_MapInt = require("rescript/lib/js/belt_MapInt.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var Belt_SetString = require("rescript/lib/js/belt_SetString.js");
var HopperState$Util = require("util/src/HopperState.bs.js");
var QueryString$Util = require("util/src/QueryString.bs.js");
var DeckMapLegacyJs = require("./DeckMapLegacy.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var DeckMapUtilList_query_graphql$Thick = require("../__generated__/DeckMapUtilList_query_graphql.bs.js");
var DeckMapUtilListRefetchQuery_graphql$Thick = require("../__generated__/DeckMapUtilListRefetchQuery_graphql.bs.js");
var DeckMapUtil_SwapInventoryTagsMutation_graphql$Thick = require("../__generated__/DeckMapUtil_SwapInventoryTagsMutation_graphql.bs.js");

var convertFragment = DeckMapUtilList_query_graphql$Thick.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(DeckMapUtilList_query_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, DeckMapUtilList_query_graphql$Thick.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(DeckMapUtilList_query_graphql$Thick.node, convertFragment, fRef);
}

var makeRefetchVariables = DeckMapUtilListRefetchQuery_graphql$Thick.Types.makeRefetchVariables;

var convertRefetchVariables = DeckMapUtilListRefetchQuery_graphql$Thick.Internal.convertVariables;

function useRefetchable(fRef) {
  return RescriptRelay_Fragment.useRefetchableFragment(DeckMapUtilList_query_graphql$Thick.node, convertFragment, convertRefetchVariables, fRef);
}

var List_elevatorProximity_decode = DeckMapUtilList_query_graphql$Thick.Utils.elevatorProximity_decode;

var List_elevatorProximity_fromString = DeckMapUtilList_query_graphql$Thick.Utils.elevatorProximity_fromString;

var List_longitudinality_decode = DeckMapUtilList_query_graphql$Thick.Utils.longitudinality_decode;

var List_longitudinality_fromString = DeckMapUtilList_query_graphql$Thick.Utils.longitudinality_fromString;

var List = {
  elevatorProximity_decode: List_elevatorProximity_decode,
  elevatorProximity_fromString: List_elevatorProximity_fromString,
  longitudinality_decode: List_longitudinality_decode,
  longitudinality_fromString: List_longitudinality_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment,
  makeRefetchVariables: makeRefetchVariables,
  convertRefetchVariables: convertRefetchVariables,
  useRefetchable: useRefetchable
};

var convertVariables = DeckMapUtil_SwapInventoryTagsMutation_graphql$Thick.Internal.convertVariables;

var convertResponse = DeckMapUtil_SwapInventoryTagsMutation_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = DeckMapUtil_SwapInventoryTagsMutation_graphql$Thick.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables, DeckMapUtil_SwapInventoryTagsMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse);

var use$1 = RescriptRelay_Mutation.useMutation(convertVariables, DeckMapUtil_SwapInventoryTagsMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse);

var SwapInventoryTagsMutation = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use$1
};

var Query = {
  List: List,
  SwapInventoryTagsMutation: SwapInventoryTagsMutation
};

var nadmDeckUrlsImpl = DeckMapLegacyJs.nadmDeckUrls;

function init(prim0, prim1, prim2, prim3, prim4, prim5) {
  DeckMapLegacyJs.init(prim0, prim1, prim2, prim3, prim4, prim5);
}

function redraw(prim0, prim1, prim2, prim3) {
  DeckMapLegacyJs.draw(prim0, prim1, prim2, prim3);
}

function genBlob(preds, apiResponse) {
  return {
          predicates: Object.fromEntries(Belt_Array.map(preds, (function (param) {
                      return [
                              param.name,
                              param.eval(apiResponse)
                            ];
                    }))),
          cabinNumber: CS_NonemptyStrings$Util.CabinNumber.toString(apiResponse.cabinNumber),
          cabinClass: CS_Slugs$Util.CabinCategorySlug.toString(apiResponse.voyageCategorySlug).toLocaleUpperCase(),
          apiResponse: apiResponse
        };
}

function genBlobs(voyageCabins, preds) {
  return Object.fromEntries(Belt_Array.map(Belt_Array.map(voyageCabins, (function (extra) {
                        return genBlob(preds, extra);
                      })), (function (blob) {
                    return [
                            blob.cabinNumber,
                            blob
                          ];
                  })));
}

function genBorderPredicatesMap(borderPalette, pmap) {
  var nEntries = borderPalette.length;
  return Object.fromEntries(Belt_Array.keepMap(Belt_MapInt.toArray(pmap), (function (param) {
                    var i = param[0];
                    if (i >= 0 && i < nEntries) {
                      return [
                              param[1],
                              Belt_Option.getExn(Belt_Option.map(Belt_Array.get(borderPalette, i), (function (param) {
                                          return param.hex;
                                        })))
                            ];
                    }
                    
                  })));
}

var Interop = {
  nadmDeckUrlsImpl: nadmDeckUrlsImpl,
  init: init,
  redraw: redraw,
  genBlob: genBlob,
  genBlobs: genBlobs,
  genBorderPredicatesMap: genBorderPredicatesMap
};

var borderPallete = [
  {
    hex: "FF00FF",
    name: "a"
  },
  {
    hex: "00FF00",
    name: "b"
  },
  {
    hex: "000090",
    name: "c"
  },
  {
    hex: "FF0000",
    name: "d"
  },
  {
    hex: "FFFF00",
    name: "e"
  }
];

function getBaseModbookingLink(c) {
  var match = c.booking.nodes;
  if (match.length !== 1) {
    return ;
  }
  var match$1 = match[0];
  var match$2 = match$1.bookingVersion.nodes;
  if (match$2.length !== 1) {
    return ;
  }
  var match$3 = match$2[0];
  return "/" + CS_Slugs$Util.BrandFamilySlug.toString(match$1.brandFamilySlug) + "/" + CS_Slugs$Util.VoyageSlug.toString(match$1.voyageSlug) + "/bookings/" + CS_NonemptyStrings$Util.BookingId.toString(match$1.bookingId) + "/" + String(match$3.bookingVersionNumber);
}

function getUnbookedSwapLink(c, o) {
  return Belt_Option.map(getBaseModbookingLink(c), (function (link) {
                return link + "?dp.changeCabinU=" + CS_NonemptyStrings$Util.CabinNumber.toString(o);
              }));
}

function getBookedSwapLink(x, y) {
  var match = getBaseModbookingLink(x);
  if (match === undefined) {
    return ;
  }
  var match$1 = y.booking.nodes;
  if (match$1.length !== 1) {
    return ;
  }
  var match$2 = match$1[0];
  var match$3 = match$2.bookingVersion.nodes;
  if (match$3.length !== 1) {
    return ;
  }
  var match$4 = match$3[0];
  return match + "?dp.changeCabinB=" + CS_NonemptyStrings$Util.BookingId.toString(match$2.bookingId) + "+" + String(match$4.bookingVersionNumber) + "+" + CS_NonemptyStrings$Util.CabinNumber.toString(y.cabinNumber);
}

function cabinPredicates(allCategorySlugs, allClassSlugs) {
  var string = function (prim) {
    return prim;
  };
  return Belt_Array.concatMany([
              [
                {
                  name: "Inventory Segment",
                  eval: (function (c) {
                      return c.voyageSegment;
                    }),
                  type_: "Other"
                },
                {
                  name: "booked",
                  eval: (function (c) {
                      return c.isBooked;
                    }),
                  type_: "Border"
                },
                {
                  name: "UNbooked",
                  eval: (function (c) {
                      return !c.isBooked;
                    }),
                  type_: "Border"
                },
                {
                  name: "placementCOMPLETE",
                  eval: (function (c) {
                      var match = c.booking.nodes;
                      if (match.length !== 1) {
                        return false;
                      }
                      var match$1 = match[0];
                      var match$2 = match$1.bookingVersion.nodes;
                      if (match$2.length !== 1) {
                        return false;
                      } else {
                        return match$2[0].finalPlacement;
                      }
                    }),
                  type_: "Border"
                },
                {
                  name: "placementNOTcomplete",
                  eval: (function (c) {
                      var match = c.booking.nodes;
                      if (match.length !== 1) {
                        return true;
                      }
                      var match$1 = match[0];
                      var match$2 = match$1.bookingVersion.nodes;
                      if (match$2.length !== 1) {
                        return true;
                      }
                      var match$3 = match$2[0];
                      return !match$3.finalPlacement;
                    }),
                  type_: "Border"
                },
                {
                  name: "Open Modbooking",
                  eval: (function (c) {
                      return Belt_Option.mapWithDefault(getBaseModbookingLink(c), null, string);
                    }),
                  type_: "Other"
                }
              ],
              Belt_Array.map(allCategorySlugs, (function (cat) {
                      return {
                              name: "cat=" + CS_Slugs$Util.CabinCategorySlug.toString(cat).toLocaleUpperCase(),
                              eval: (function (c) {
                                  return Caml_obj.equal(cat, c.voyageCategorySlug);
                                }),
                              type_: "Border"
                            };
                    })),
              Belt_Array.map(allClassSlugs, (function (cls) {
                      return {
                              name: "class=" + CS_Slugs$Util.CabinClassSlug.toString(cls),
                              eval: (function (c) {
                                  return Caml_obj.equal(cls, c.voyageClassSlug);
                                }),
                              type_: "Border"
                            };
                    })),
              Belt_Array.map([
                    2,
                    3,
                    4
                  ], (function (o) {
                      return {
                              name: "maxOcc=" + String(o),
                              eval: (function (c) {
                                  return o === c.maxBerths;
                                }),
                              type_: "Border"
                            };
                    }))
            ]);
}

var deckUrls = Belt_MapString.fromArray(Object.entries(nadmDeckUrlsImpl));

var decks = Object.keys(nadmDeckUrlsImpl);

function validateDeckNumber(i) {
  if (Belt_MapString.has(deckUrls, i)) {
    return {
            TAG: "Ok",
            _0: i
          };
  } else {
    return {
            TAG: "Error",
            _0: "That deck does not exist"
          };
  }
}

var NADM = {
  deckUrls: deckUrls,
  decks: decks,
  validateDeckNumber: validateDeckNumber
};

var deckLeft = HopperState$Util.Observable.FormField.makeString({
      read: (function (param) {
          return param.deckLeft;
        }),
      write: (function (model, deckLeft) {
          return {
                  deckLeft: deckLeft,
                  deckRight: model.deckRight,
                  loadedDecks: model.loadedDecks,
                  mapsAreLoading: model.mapsAreLoading,
                  url: model.url,
                  hoverCabin: model.hoverCabin,
                  borderColoring: model.borderColoring,
                  hideSegments: model.hideSegments,
                  cabinX: model.cabinX,
                  cabinY: model.cabinY,
                  swapDrawer: model.swapDrawer
                };
        })
    }, validateDeckNumber, (function (i) {
        return i;
      }), Belt_Array.getExn(decks, 0), undefined);

var deckRight = HopperState$Util.Observable.FormField.makeString({
      read: (function (param) {
          return param.deckRight;
        }),
      write: (function (model, deckRight) {
          return {
                  deckLeft: model.deckLeft,
                  deckRight: deckRight,
                  loadedDecks: model.loadedDecks,
                  mapsAreLoading: model.mapsAreLoading,
                  url: model.url,
                  hoverCabin: model.hoverCabin,
                  borderColoring: model.borderColoring,
                  hideSegments: model.hideSegments,
                  cabinX: model.cabinX,
                  cabinY: model.cabinY,
                  swapDrawer: model.swapDrawer
                };
        })
    }, validateDeckNumber, (function (i) {
        return i;
      }), Belt_Array.getExn(decks, 1), undefined);

function blankModel(url) {
  return {
          deckLeft: deckLeft.blankStore,
          deckRight: deckRight.blankStore,
          loadedDecks: undefined,
          mapsAreLoading: true,
          url: url,
          hoverCabin: undefined,
          borderColoring: undefined,
          hideSegments: undefined,
          cabinX: undefined,
          cabinY: undefined,
          swapDrawer: undefined
        };
}

var overrideSegment = HopperState$Util.Observable.FormField.makeString({
      read: (function (param) {
          return param.overrideSegment;
        }),
      write: (function (model, overrideSegment) {
          return {
                  overrideSegment: overrideSegment,
                  inventoryTagsMutation: model.inventoryTagsMutation
                };
        })
    }, (function (v) {
        if (v === "OVERRIDE") {
          return {
                  TAG: "Ok",
                  _0: v
                };
        } else {
          return {
                  TAG: "Error",
                  _0: "To override type OVERRIDE (in all caps)"
                };
        }
      }), (function (i) {
        return i;
      }), "", undefined);

var blankSwapDrawer_overrideSegment = overrideSegment.blankStore;

var blankSwapDrawer = {
  overrideSegment: blankSwapDrawer_overrideSegment,
  inventoryTagsMutation: "NotAsked"
};

function useWillBeLoadedAfter(view) {
  return HopperState$Util.Observable.useComputed(view, (function (model) {
                var loadedDecks = model.loadedDecks;
                var match = Belt_MapString.get(loadedDecks, deckLeft.getValue(model));
                var match$1 = Belt_MapString.get(loadedDecks, deckRight.getValue(model));
                if (match !== undefined && match$1 !== undefined) {
                  return Math.max(match, match$1);
                }
                
              }), undefined, undefined);
}

function loadedDecks(view) {
  return HopperState$Util.Observable.makeView(view, {
              read: (function (model) {
                  return model.loadedDecks;
                }),
              write: (function (model, loadedDecks) {
                  return {
                          deckLeft: model.deckLeft,
                          deckRight: model.deckRight,
                          loadedDecks: loadedDecks,
                          mapsAreLoading: model.mapsAreLoading,
                          url: model.url,
                          hoverCabin: model.hoverCabin,
                          borderColoring: model.borderColoring,
                          hideSegments: model.hideSegments,
                          cabinX: model.cabinX,
                          cabinY: model.cabinY,
                          swapDrawer: model.swapDrawer
                        };
                })
            }, undefined);
}

function queryStateLens_read(m) {
  return m;
}

function queryStateLens_write(param, m) {
  return m;
}

var queryStateLens = {
  read: queryStateLens_read,
  write: queryStateLens_write
};

function getParser(url) {
  var fieldToString = function (field) {
    return "dk." + field;
  };
  var mapRequired = function (ff, field, codec) {
    return QueryString$Util.Param.Singular.make(field, fieldToString, codec, undefined, (function (m, o) {
                  return ff.set(m, Belt_Option.getWithDefault(o, ff.blankValue), undefined);
                }), (function (m) {
                  return Caml_option.some(ff.getValue(m));
                }));
  };
  return QueryString$Util.Parser.makeExtended([
              mapRequired(deckLeft, "left", QueryString$Util.Codec.scalar(QueryString$Util.Codec.required(QueryString$Util.Codec.string))),
              mapRequired(deckRight, "right", QueryString$Util.Codec.scalar(QueryString$Util.Codec.required(QueryString$Util.Codec.string))),
              QueryString$Util.Param.Multiple.AsIntMap.make("border", fieldToString, QueryString$Util.Codec.tuple2(QueryString$Util.Codec.required(QueryString$Util.Codec.$$int), QueryString$Util.Codec.required(QueryString$Util.Codec.string)), (function (m, om) {
                      return {
                              deckLeft: m.deckLeft,
                              deckRight: m.deckRight,
                              loadedDecks: m.loadedDecks,
                              mapsAreLoading: m.mapsAreLoading,
                              url: m.url,
                              hoverCabin: m.hoverCabin,
                              borderColoring: Belt_Option.getWithDefault(om, undefined),
                              hideSegments: m.hideSegments,
                              cabinX: m.cabinX,
                              cabinY: m.cabinY,
                              swapDrawer: m.swapDrawer
                            };
                    }), (function (m) {
                      return Caml_option.some(m.borderColoring);
                    }), (function (a) {
                      return a;
                    }), (function (a) {
                      return a;
                    })),
              QueryString$Util.Param.Singular.make("hide", fieldToString, QueryString$Util.Codec.array(QueryString$Util.Codec.required(QueryString$Util.Codec.string)), undefined, (function (m, om) {
                      return {
                              deckLeft: m.deckLeft,
                              deckRight: m.deckRight,
                              loadedDecks: m.loadedDecks,
                              mapsAreLoading: m.mapsAreLoading,
                              url: m.url,
                              hoverCabin: m.hoverCabin,
                              borderColoring: m.borderColoring,
                              hideSegments: Belt_Option.mapWithDefault(om, undefined, Belt_SetString.fromArray),
                              cabinX: m.cabinX,
                              cabinY: m.cabinY,
                              swapDrawer: m.swapDrawer
                            };
                    }), (function (m) {
                      return Belt_SetString.toArray(m.hideSegments);
                    })),
              QueryString$Util.Param.Singular.make("cabXY", fieldToString, QueryString$Util.Codec.tuple2(QueryString$Util.Codec.customScalar(CS_NonemptyStrings$Util.CabinNumber), QueryString$Util.Codec.customScalar(CS_NonemptyStrings$Util.CabinNumber)), undefined, (function (m, otup) {
                      return Belt_Option.mapWithDefault(otup, {
                                  deckLeft: m.deckLeft,
                                  deckRight: m.deckRight,
                                  loadedDecks: m.loadedDecks,
                                  mapsAreLoading: m.mapsAreLoading,
                                  url: m.url,
                                  hoverCabin: m.hoverCabin,
                                  borderColoring: m.borderColoring,
                                  hideSegments: m.hideSegments,
                                  cabinX: undefined,
                                  cabinY: undefined,
                                  swapDrawer: m.swapDrawer
                                }, (function (param) {
                                    return {
                                            deckLeft: m.deckLeft,
                                            deckRight: m.deckRight,
                                            loadedDecks: m.loadedDecks,
                                            mapsAreLoading: m.mapsAreLoading,
                                            url: m.url,
                                            hoverCabin: m.hoverCabin,
                                            borderColoring: m.borderColoring,
                                            hideSegments: m.hideSegments,
                                            cabinX: param[0],
                                            cabinY: param[1],
                                            swapDrawer: m.swapDrawer
                                          };
                                  }));
                    }), (function (param) {
                      var cabinY = param.cabinY;
                      var cabinX = param.cabinX;
                      if (cabinX !== undefined || cabinY !== undefined) {
                        return [
                                cabinX,
                                cabinY
                              ];
                      }
                      
                    })),
              QueryString$Util.Param.Singular.make("doswap", fieldToString, QueryString$Util.Codec.scalar(QueryString$Util.Codec.required(QueryString$Util.Codec.bool)), undefined, (function (m, oo) {
                      console.log("SDFOTTOKJ got called");
                      return {
                              deckLeft: m.deckLeft,
                              deckRight: m.deckRight,
                              loadedDecks: m.loadedDecks,
                              mapsAreLoading: m.mapsAreLoading,
                              url: m.url,
                              hoverCabin: m.hoverCabin,
                              borderColoring: m.borderColoring,
                              hideSegments: m.hideSegments,
                              cabinX: m.cabinX,
                              cabinY: m.cabinY,
                              swapDrawer: Belt_Option.getWithDefault(oo, false) ? Belt_Option.getWithDefault(m.swapDrawer, blankSwapDrawer) : undefined
                            };
                    }), (function (m) {
                      return Belt_Option.isSome(m.swapDrawer);
                    }))
            ], blankModel(url));
}

var QueryString = {
  queryStateLens: queryStateLens,
  getParser: getParser
};

exports.Query = Query;
exports.Interop = Interop;
exports.borderPallete = borderPallete;
exports.getBaseModbookingLink = getBaseModbookingLink;
exports.getUnbookedSwapLink = getUnbookedSwapLink;
exports.getBookedSwapLink = getBookedSwapLink;
exports.cabinPredicates = cabinPredicates;
exports.NADM = NADM;
exports.deckLeft = deckLeft;
exports.deckRight = deckRight;
exports.blankModel = blankModel;
exports.overrideSegment = overrideSegment;
exports.blankSwapDrawer = blankSwapDrawer;
exports.useWillBeLoadedAfter = useWillBeLoadedAfter;
exports.loadedDecks = loadedDecks;
exports.QueryString = QueryString;
/* commitMutation Not a pure module */
