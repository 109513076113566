// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");

var Types = {};

var fragmentConverter = {"__root":{"numBerths":{"b":""},"cabinNumberString":{"c":"Util.CustomScalars.NonemptyString.Exn"},"cabinNumber":{"c":"Util.CustomScalars.CabinNumber.Exn"},"bookingVersion_nodes_bookingVersionNumber":{"b":""},"bookingId":{"c":"Util.CustomScalars.BookingId.Exn"}}};

var fragmentConverterMap = {
  "Util.CustomScalars.BookingId.Exn": CS_NonemptyStrings$Util.BookingId.Exn.parse,
  "Util.CustomScalars.CabinNumber.Exn": CS_NonemptyStrings$Util.CabinNumber.Exn.parse,
  "Util.CustomScalars.NonemptyString.Exn": CS_NonemptyStrings$Util.NonEmptyString.Exn.parse
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

function lifecycleStatus_decode($$enum) {
  if ($$enum === "CANCELED" || $$enum === "STAGED" || $$enum === "DRAFT" || $$enum === "ACTIVE" || $$enum === "HISTORY") {
    return $$enum;
  }
  
}

function lifecycleStatus_fromString(str) {
  return lifecycleStatus_decode(str);
}

function paxDataStatus_decode($$enum) {
  if ($$enum === "INCOMPLETE" || $$enum === "CONFIRMED" || $$enum === "INVITED") {
    return $$enum;
  }
  
}

function paxDataStatus_fromString(str) {
  return paxDataStatus_decode(str);
}

var Utils = {
  lifecycleStatus_decode: lifecycleStatus_decode,
  lifecycleStatus_fromString: lifecycleStatus_fromString,
  paxDataStatus_decode: paxDataStatus_decode,
  paxDataStatus_fromString: paxDataStatus_fromString
};

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BookingsPage_booking",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bookingId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cabinNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cabinNumberString",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lifecycleStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numBerths",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BfcustUser",
      "kind": "LinkedField",
      "name": "billingContact",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "emailString",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "loyaltyNumString",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BookingPassengerStub",
      "kind": "LinkedField",
      "name": "passengerStubs",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullDisplayName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullDocumentName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paxDataStatus",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "PRIMARY_KEY_DESC"
        }
      ],
      "concreteType": "BookingVersionsConnection",
      "kind": "LinkedField",
      "name": "bookingVersion",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BookingVersion",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "bookingVersionNumber",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "bookingVersion(orderBy:\"PRIMARY_KEY_DESC\")"
    }
  ],
  "type": "Booking",
  "abstractKey": null
};

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* RescriptRelay Not a pure module */
