// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var RelayEnv$Thick = require("../RelayEnv.bs.js");
var HopperState$Util = require("util/src/HopperState.bs.js");
var QueryString$Util = require("util/src/QueryString.bs.js");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var MergeAccountsCheckQuery_graphql$Thick = require("../__generated__/MergeAccountsCheckQuery_graphql.bs.js");
var MergeAccountsSearchQuery_graphql$Thick = require("../__generated__/MergeAccountsSearchQuery_graphql.bs.js");
var MergeAccounts_MergeAccountsMutation_graphql$Thick = require("../__generated__/MergeAccounts_MergeAccountsMutation_graphql.bs.js");

function fieldToString(field) {
  return "merge." + field;
}

var sourceAccountId = HopperState$Util.Observable.FormField.makeString({
      read: (function (param) {
          return param.source;
        }),
      write: (function (form, source) {
          return {
                  source: source,
                  dest: form.dest,
                  url: form.url,
                  sourceAcct: form.sourceAcct,
                  destAcct: form.destAcct,
                  mergeability: form.mergeability,
                  mergedStatus: form.mergedStatus,
                  mergeModal: form.mergeModal
                };
        })
    }, CS_NonemptyStrings$Util.AccountId.fromPrimitive, CS_NonemptyStrings$Util.AccountId.toPrimitive, "", undefined);

var destAccountId = HopperState$Util.Observable.FormField.makeString({
      read: (function (param) {
          return param.dest;
        }),
      write: (function (form, dest) {
          return {
                  source: form.source,
                  dest: dest,
                  url: form.url,
                  sourceAcct: form.sourceAcct,
                  destAcct: form.destAcct,
                  mergeability: form.mergeability,
                  mergedStatus: form.mergedStatus,
                  mergeModal: form.mergeModal
                };
        })
    }, CS_NonemptyStrings$Util.AccountId.fromPrimitive, CS_NonemptyStrings$Util.AccountId.toPrimitive, "", undefined);

function blankModel(url) {
  return {
          source: sourceAccountId.blankStore,
          dest: destAccountId.blankStore,
          url: url,
          sourceAcct: undefined,
          destAcct: undefined,
          mergeability: "NotChecked",
          mergedStatus: "NotAttempted",
          mergeModal: undefined
        };
}

var formUtils = HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.emptyContainer(), sourceAccountId), destAccountId);

function queryStateLens_read(m) {
  return m;
}

function queryStateLens_write(param, m) {
  return m;
}

var queryStateLens = {
  read: queryStateLens_read,
  write: queryStateLens_write
};

function getParser(url) {
  var formField = function (param, field, token) {
    var changedInputLens = param.changedInputLens;
    var chained = HopperState$Util.Lens.chain(queryStateLens, {
          read: (function (form) {
              return changedInputLens.read(form);
            }),
          write: (function (form, val) {
              return changedInputLens.write(form, val);
            })
        });
    return QueryString$Util.Param.Singular.make(field, fieldToString, QueryString$Util.Codec.scalar(token), undefined, (function (parent, opt) {
                  return Belt_Option.mapWithDefault(opt, parent, (function (value) {
                                return chained.write(parent, value);
                              }));
                }), (function (v) {
                  return Caml_option.some(chained.read(v));
                }));
  };
  var sourceAccountId$1 = formField(sourceAccountId, "src", QueryString$Util.Codec.string);
  var destAccountId$1 = formField(destAccountId, "dest", QueryString$Util.Codec.string);
  return QueryString$Util.Parser.makeExtended([
              sourceAccountId$1,
              destAccountId$1
            ], blankModel(url));
}

var QueryString = {
  queryStateLens: queryStateLens,
  getParser: getParser
};

var convertVariables = MergeAccountsSearchQuery_graphql$Thick.Internal.convertVariables;

var convertResponse = MergeAccountsSearchQuery_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = MergeAccountsSearchQuery_graphql$Thick.Internal.convertWrapRawResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, MergeAccountsSearchQuery_graphql$Thick.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, MergeAccountsSearchQuery_graphql$Thick.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(MergeAccountsSearchQuery_graphql$Thick.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(MergeAccountsSearchQuery_graphql$Thick.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(MergeAccountsSearchQuery_graphql$Thick.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(MergeAccountsSearchQuery_graphql$Thick.node, convertVariables);

var FetchOneQuery_accountStatus_decode = MergeAccountsSearchQuery_graphql$Thick.Utils.accountStatus_decode;

var FetchOneQuery_accountStatus_fromString = MergeAccountsSearchQuery_graphql$Thick.Utils.accountStatus_fromString;

var FetchOneQuery_docGender_decode = MergeAccountsSearchQuery_graphql$Thick.Utils.docGender_decode;

var FetchOneQuery_docGender_fromString = MergeAccountsSearchQuery_graphql$Thick.Utils.docGender_fromString;

var FetchOneQuery_lifecycleStatus_decode = MergeAccountsSearchQuery_graphql$Thick.Utils.lifecycleStatus_decode;

var FetchOneQuery_lifecycleStatus_fromString = MergeAccountsSearchQuery_graphql$Thick.Utils.lifecycleStatus_fromString;

var FetchOneQuery = {
  accountStatus_decode: FetchOneQuery_accountStatus_decode,
  accountStatus_fromString: FetchOneQuery_accountStatus_fromString,
  docGender_decode: FetchOneQuery_docGender_decode,
  docGender_fromString: FetchOneQuery_docGender_fromString,
  lifecycleStatus_decode: FetchOneQuery_lifecycleStatus_decode,
  lifecycleStatus_fromString: FetchOneQuery_lifecycleStatus_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

var decodeDocGender = MergeAccountsSearchQuery_graphql$Thick.Utils.docGender_decode;

function decodeDocGenderExn(g) {
  return Belt_Option.getExn(decodeDocGender(g));
}

function docGenderToString(prim) {
  return prim;
}

var docGenderFromString = MergeAccountsSearchQuery_graphql$Thick.Utils.docGender_fromString;

var allLegalDocGenders = [
  "F",
  "M",
  "X"
];

var decodeLifecycleStatus = MergeAccountsSearchQuery_graphql$Thick.Utils.lifecycleStatus_decode;

function decodeLifecycleStatusExn(g) {
  return Belt_Option.getExn(decodeLifecycleStatus(g));
}

function lifecycleStatusToString(prim) {
  return prim;
}

var lifecycleStatusFromString = MergeAccountsSearchQuery_graphql$Thick.Utils.lifecycleStatus_fromString;

var decodeAccountStatus = MergeAccountsSearchQuery_graphql$Thick.Utils.accountStatus_decode;

function decodeAccountStatusExn(g) {
  return Belt_Option.getExn(decodeAccountStatus(g));
}

function accountStatusToString(prim) {
  return prim;
}

var accountStatusFromString = MergeAccountsSearchQuery_graphql$Thick.Utils.accountStatus_fromString;

function wrangleBfCustUser(u) {
  var custodian = u.custodian;
  var custodian$1 = custodian !== undefined ? ({
        custodianName: custodian.fullName,
        custodianEmail: custodian.email
      }) : undefined;
  var connectedAccounts = Belt_Array.map(u.connected_accounts.nodes, (function (ca) {
          return {
                  connectedName: ca.fullName,
                  connectedLoyaltyNumber: ca.loyaltyNumber
                };
        }));
  var ownedBookings = Belt_Array.map(u.bookingVersions.nodes, (function (bv) {
          return {
                  voyageSlug: bv.voyageSlug,
                  bookingId: bv.bookingId,
                  bookingVersionNumber: bv.bookingVersionNumber,
                  lifecycleStatus: Belt_Option.getExn(decodeLifecycleStatus(bv.lifecycleStatus))
                };
        }));
  var passengerRecords = Belt_Array.map(u.passenger_record.nodes, (function (pr) {
          return {
                  passBookingId: pr.bookingId,
                  passBookingVersionNumber: pr.bookingVersionNumber,
                  passVoyageSlug: pr.voyageSlug,
                  passBookingLifecycleStatus: Belt_Option.getExn(decodeLifecycleStatus(pr.bookingVersionLifecycleStatus)),
                  passLegalDocFirstName: pr.docFirstName,
                  passLegalDocLastName: pr.docLastName,
                  passLegalDocBirthdate: pr.docBirthdate,
                  passLegalDocGender: Belt_Option.map(pr.docGender, decodeDocGenderExn),
                  passLegalDocCitizenship: pr.docCitizenship
                };
        }));
  var payments = Belt_Array.map(u.payment.nodes, (function (p) {
          return {
                  payVoyageSlug: p.voyageSlug,
                  payBookingId: p.bookingId,
                  createdAt: p.createdAt,
                  amount: p.netAmount,
                  description: p.bfcustPmtMethodDescription
                };
        }));
  var match = u.loyalty_status;
  var pendingSlugs;
  if (match !== undefined) {
    var slugs = match.pendingVoyageSlugs;
    pendingSlugs = slugs !== undefined ? Belt_Array.keepMap(slugs, (function (s) {
              return s;
            })) : [];
  } else {
    pendingSlugs = [];
  }
  var match$1 = u.loyalty_status;
  var eligibleSlugs;
  if (match$1 !== undefined) {
    var slugs$1 = match$1.eligibleVoyageSlugs;
    eligibleSlugs = slugs$1 !== undefined ? Belt_Array.keepMap(slugs$1, (function (s) {
              return s;
            })) : [];
  } else {
    eligibleSlugs = [];
  }
  var passengerHistory = Belt_Array.concat(eligibleSlugs, pendingSlugs);
  return {
          accountId: u.accountId,
          accountStatus: Belt_Option.getExn(decodeAccountStatus(u.accountStatus)),
          fullName: u.fullName,
          preferredFirstName: u.firstName,
          preferredLastName: u.lastName,
          email: u.email,
          loyaltyNumber: u.loyaltyNumber,
          halMarinerNumber: u.halMarinerNumber,
          phone: u.phone,
          postalCode: u.postalCode,
          residenceCountry: u.residenceCountry,
          acquisitionSlug: u.custAcquisitionSlug,
          legalDocFirstName: u.legalDocumentFirstName,
          legalDocLastName: u.legalDocumentLastName,
          legalDocBirthdate: u.legalDocumentBirthdate,
          legalDocGender: Belt_Option.map(u.legalDocumentGender, decodeDocGenderExn),
          legalDocCitizenship: u.legalDocumentCitizenship,
          custodian: custodian$1,
          connectedAccounts: connectedAccounts,
          ownedBookings: ownedBookings,
          passengerRecords: passengerRecords,
          payments: payments,
          passengerHistory: passengerHistory
        };
}

function createMergeFilter(s) {
  return {
          accountStatus: {
            notIn: [
              "DELETED",
              "INVITED"
            ]
          },
          or: [
            {
              fullName: {
                includesInsensitive: s
              }
            },
            {
              emailString: {
                includesInsensitive: s
              }
            },
            {
              loyaltyNumString: {
                includesInsensitive: s
              }
            }
          ]
        };
}

function useFetchOne(brandFamily) {
  var match = React.useState(function () {
        
      });
  var setError = match[1];
  var match$1 = React.useState(function () {
        return false;
      });
  var setLoading = match$1[1];
  var fetchOne = function (accountId, onSuccess) {
    setLoading(function (param) {
          return true;
        });
    setError(function (param) {
          
        });
    $$fetch(RelayEnv$Thick.environment, {
          accountId: accountId,
          brandFamily: brandFamily
        }, (function (res) {
            if (res.TAG === "Ok") {
              var match = res._0.bfcustUsers;
              if (match !== undefined) {
                var match$1 = match.nodes;
                if (match$1.length === 1) {
                  var node = match$1[0];
                  var result = wrangleBfCustUser(node);
                  onSuccess(result);
                  setLoading(function (param) {
                        return false;
                      });
                  return ;
                }
                
              }
              setError(function (param) {
                    return "No users found.";
                  });
              setLoading(function (param) {
                    return false;
                  });
              return ;
            }
            console.error(res._0);
            setError(function (param) {
                  return "Something went wrong. Please try again.";
                });
            setLoading(function (param) {
                  return false;
                });
          }), undefined, undefined);
  };
  return {
          loading: match$1[0],
          setLoading: setLoading,
          error: match[0],
          setError: setError,
          fetchOne: fetchOne
        };
}

var convertVariables$1 = MergeAccountsCheckQuery_graphql$Thick.Internal.convertVariables;

var convertResponse$1 = MergeAccountsCheckQuery_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse$1 = MergeAccountsCheckQuery_graphql$Thick.Internal.convertWrapRawResponse;

var use$1 = RescriptRelay_Query.useQuery(convertVariables$1, MergeAccountsCheckQuery_graphql$Thick.node, convertResponse$1);

var useLoader$1 = RescriptRelay_Query.useLoader(convertVariables$1, MergeAccountsCheckQuery_graphql$Thick.node, (function (prim) {
        return prim;
      }));

var usePreloaded$1 = RescriptRelay_Query.usePreloaded(MergeAccountsCheckQuery_graphql$Thick.node, convertResponse$1, (function (prim) {
        return prim;
      }));

var $$fetch$1 = RescriptRelay_Query.$$fetch(MergeAccountsCheckQuery_graphql$Thick.node, convertResponse$1, convertVariables$1);

var fetchPromised$1 = RescriptRelay_Query.fetchPromised(MergeAccountsCheckQuery_graphql$Thick.node, convertResponse$1, convertVariables$1);

var retain$1 = RescriptRelay_Query.retain(MergeAccountsCheckQuery_graphql$Thick.node, convertVariables$1);

var CheckQuery_accountMergeError_decode = MergeAccountsCheckQuery_graphql$Thick.Utils.accountMergeError_decode;

var CheckQuery_accountMergeError_fromString = MergeAccountsCheckQuery_graphql$Thick.Utils.accountMergeError_fromString;

var CheckQuery_accountMergeWarning_decode = MergeAccountsCheckQuery_graphql$Thick.Utils.accountMergeWarning_decode;

var CheckQuery_accountMergeWarning_fromString = MergeAccountsCheckQuery_graphql$Thick.Utils.accountMergeWarning_fromString;

var CheckQuery = {
  accountMergeError_decode: CheckQuery_accountMergeError_decode,
  accountMergeError_fromString: CheckQuery_accountMergeError_fromString,
  accountMergeWarning_decode: CheckQuery_accountMergeWarning_decode,
  accountMergeWarning_fromString: CheckQuery_accountMergeWarning_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables$1,
  convertResponse: convertResponse$1,
  convertWrapRawResponse: convertWrapRawResponse$1,
  use: use$1,
  useLoader: useLoader$1,
  usePreloaded: usePreloaded$1,
  $$fetch: $$fetch$1,
  fetchPromised: fetchPromised$1,
  retain: retain$1
};

function mergeErrorToString(prim) {
  return prim;
}

function mergeWarningToString(prim) {
  return prim;
}

function useCheck(brandFamily) {
  var match = React.useState(function () {
        return false;
      });
  var setLoading = match[1];
  var match$1 = React.useState(function () {
        
      });
  var setError = match$1[1];
  var check = function (source, dest, onReturn) {
    setLoading(function (param) {
          return true;
        });
    setError(function (param) {
          
        });
    $$fetch$1(RelayEnv$Thick.environment, {
          _brandFamilySlug: brandFamily,
          _destAccountId: dest,
          _sourceAccountIds: [Caml_option.some(source)]
        }, (function (res) {
            if (res.TAG === "Ok") {
              var match = res._0.checkAccountMerge;
              if (match !== undefined) {
                var match$1 = match.nodes;
                if (match$1.length === 1) {
                  var match$2 = match$1[0];
                  var mergeable = match$2.mergeable;
                  if (mergeable !== undefined) {
                    var warnings = match$2.warnings;
                    var errors = match$2.errors;
                    var warnings$1 = warnings !== undefined ? Belt_Array.keepMap(warnings, (function (s) {
                              if (s !== undefined) {
                                return s;
                              }
                              
                            })) : [];
                    var errors$1 = errors !== undefined ? Belt_Array.keepMap(errors, (function (s) {
                              if (s !== undefined) {
                                return s;
                              }
                              
                            })) : [];
                    var match$3 = warnings$1.length;
                    var match$4 = errors$1.length;
                    if (mergeable) {
                      var exit = 0;
                      if (match$3 !== 0 || match$4 !== 0) {
                        exit = 2;
                      } else {
                        onReturn("CanMerge");
                        setLoading(function (param) {
                              return false;
                            });
                        return ;
                      }
                      if (exit === 2) {
                        onReturn({
                              TAG: "MergeWithWarnings",
                              _0: warnings$1
                            });
                        setLoading(function (param) {
                              return false;
                            });
                        return ;
                      }
                      
                    } else {
                      onReturn({
                            TAG: "CannotMerge",
                            _0: errors$1
                          });
                      setLoading(function (param) {
                            return false;
                          });
                      return ;
                    }
                  }
                  
                }
                
              }
              setError(function (param) {
                    return "Something went wrong checking mergeability. Please try again.";
                  });
              return ;
            }
            console.error(res._0);
            setError(function (param) {
                  return "Something went wrong checking mergeability. Please try again.";
                });
            setLoading(function (param) {
                  return false;
                });
          }), undefined, undefined);
  };
  return {
          loading: match[0],
          error: match$1[0],
          setError: setError,
          check: check
        };
}

var convertVariables$2 = MergeAccounts_MergeAccountsMutation_graphql$Thick.Internal.convertVariables;

var convertResponse$2 = MergeAccounts_MergeAccountsMutation_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse$2 = MergeAccounts_MergeAccountsMutation_graphql$Thick.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables$2, MergeAccounts_MergeAccountsMutation_graphql$Thick.node, convertResponse$2, convertWrapRawResponse$2);

var use$2 = RescriptRelay_Mutation.useMutation(convertVariables$2, MergeAccounts_MergeAccountsMutation_graphql$Thick.node, convertResponse$2, convertWrapRawResponse$2);

var MergeMutation_accountMergeError_decode = MergeAccounts_MergeAccountsMutation_graphql$Thick.Utils.accountMergeError_decode;

var MergeMutation_accountMergeError_fromString = MergeAccounts_MergeAccountsMutation_graphql$Thick.Utils.accountMergeError_fromString;

var MergeMutation_accountMergeWarning_decode = MergeAccounts_MergeAccountsMutation_graphql$Thick.Utils.accountMergeWarning_decode;

var MergeMutation_accountMergeWarning_fromString = MergeAccounts_MergeAccountsMutation_graphql$Thick.Utils.accountMergeWarning_fromString;

var MergeMutation = {
  accountMergeError_decode: MergeMutation_accountMergeError_decode,
  accountMergeError_fromString: MergeMutation_accountMergeError_fromString,
  accountMergeWarning_decode: MergeMutation_accountMergeWarning_decode,
  accountMergeWarning_fromString: MergeMutation_accountMergeWarning_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables$2,
  convertResponse: convertResponse$2,
  convertWrapRawResponse: convertWrapRawResponse$2,
  commitMutation: commitMutation,
  use: use$2
};

function wrangleMergeResults(m) {
  return {
          ctPaxHistory: Belt_Option.getWithDefault(m.ctPaxHistory, 0),
          ctBookingVersions: Belt_Option.getWithDefault(m.ctBookingVersions, 0),
          ctPaxVersion: Belt_Option.getWithDefault(m.ctPaxVersion, 0),
          ctBookingVersionPax: Belt_Option.getWithDefault(m.ctBookingVersionPax, 0),
          ctPayment: Belt_Option.getWithDefault(m.ctPayment, 0),
          ctCabinHoldsCleared: Belt_Option.getWithDefault(m.ctCabinHoldsCleared, 0),
          ctCabinHoldLogs: Belt_Option.getWithDefault(m.ctCabinHoldLogs, 0),
          ctBookingSessionsDeleted: Belt_Option.getWithDefault(m.ctBookingSessionsDeleted, 0),
          ctBookingSessionExistingPax: Belt_Option.getWithDefault(m.ctBookingSessionExistingPax, 0),
          ctReparentedConnectedAccts: Belt_Option.getWithDefault(m.ctReparentedConnectedAccts, 0),
          ctDeletedUsers: Belt_Option.getWithDefault(m.ctDeletedUsers, 0)
        };
}

function useMerge(brandFamily, onReturn) {
  var match = use$2();
  var mut = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setError = match$1[1];
  var merge = function (source, dest) {
    setError(function (param) {
          
        });
    mut({
          input: {
            _brandFamilySlug: brandFamily,
            _sourceAccountIds: [Caml_option.some(source)],
            _destAccountId: dest
          }
        }, undefined, undefined, undefined, (function (res, err) {
            if (err === undefined) {
              var match = res.mergeAccounts;
              if (match === undefined) {
                return ;
              }
              var match$1 = match.results;
              if (match$1 === undefined) {
                return ;
              }
              if (match$1.length !== 1) {
                return ;
              }
              var results = match$1[0];
              var merged = results.success;
              if (merged === undefined) {
                return ;
              }
              var warnings = results.warnings;
              var errors = results.errors;
              var warnings$1 = warnings !== undefined ? Belt_Array.keepMap(warnings, (function (s) {
                        if (s !== undefined) {
                          return s;
                        }
                        
                      })) : [];
              var errors$1 = errors !== undefined ? Belt_Array.keepMap(errors, (function (s) {
                        if (s !== undefined) {
                          return s;
                        }
                        
                      })) : [];
              var match$2 = warnings$1.length;
              var match$3 = errors$1.length;
              if (merged) {
                if (match$2 === 0 && match$3 === 0) {
                  onReturn({
                        TAG: "Merged",
                        _0: wrangleMergeResults(results)
                      });
                  return ;
                }
                onReturn({
                      TAG: "MergedWithWarnings",
                      _0: [
                        wrangleMergeResults(results),
                        warnings$1
                      ]
                    });
                return ;
              }
              onReturn({
                    TAG: "MergeError",
                    _0: errors$1
                  });
              return ;
            }
            console.error(err);
            setError(function (param) {
                  return "Something went wrong merging accounts. Please try again.";
                });
          }), undefined, undefined);
  };
  return {
          loading: match[1],
          error: match$1[0],
          setError: setError,
          merge: merge
        };
}

var blankMergeSummary = {
  ctPaxHistory: 0,
  ctBookingVersions: 0,
  ctPaxVersion: 0,
  ctBookingVersionPax: 0,
  ctPayment: 0,
  ctCabinHoldsCleared: 0,
  ctCabinHoldLogs: 0,
  ctBookingSessionsDeleted: 0,
  ctBookingSessionExistingPax: 0,
  ctReparentedConnectedAccts: 0,
  ctDeletedUsers: 0
};

var makeSchema = formUtils.makeSchema;

exports.fieldToString = fieldToString;
exports.blankMergeSummary = blankMergeSummary;
exports.sourceAccountId = sourceAccountId;
exports.destAccountId = destAccountId;
exports.blankModel = blankModel;
exports.makeSchema = makeSchema;
exports.formUtils = formUtils;
exports.QueryString = QueryString;
exports.FetchOneQuery = FetchOneQuery;
exports.decodeDocGender = decodeDocGender;
exports.decodeDocGenderExn = decodeDocGenderExn;
exports.docGenderToString = docGenderToString;
exports.docGenderFromString = docGenderFromString;
exports.allLegalDocGenders = allLegalDocGenders;
exports.decodeLifecycleStatus = decodeLifecycleStatus;
exports.decodeLifecycleStatusExn = decodeLifecycleStatusExn;
exports.lifecycleStatusToString = lifecycleStatusToString;
exports.lifecycleStatusFromString = lifecycleStatusFromString;
exports.decodeAccountStatus = decodeAccountStatus;
exports.decodeAccountStatusExn = decodeAccountStatusExn;
exports.accountStatusToString = accountStatusToString;
exports.accountStatusFromString = accountStatusFromString;
exports.wrangleBfCustUser = wrangleBfCustUser;
exports.createMergeFilter = createMergeFilter;
exports.useFetchOne = useFetchOne;
exports.CheckQuery = CheckQuery;
exports.mergeErrorToString = mergeErrorToString;
exports.mergeWarningToString = mergeWarningToString;
exports.useCheck = useCheck;
exports.MergeMutation = MergeMutation;
exports.wrangleMergeResults = wrangleMergeResults;
exports.useMerge = useMerge;
/* sourceAccountId Not a pure module */
