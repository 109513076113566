// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var ReactRelay = require("react-relay");
var CS_UUID$Util = require("util/src/CustomScalars/CS_UUID.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");

function makeRefetchVariables(_bookingSessionIds, _brandFamilySlug, _targetBookingId, _voyageSlug) {
  return {
          _bookingSessionIds: _bookingSessionIds,
          _brandFamilySlug: _brandFamilySlug,
          _targetBookingId: _targetBookingId,
          _voyageSlug: _voyageSlug
        };
}

var Types = {
  makeRefetchVariables: makeRefetchVariables
};

var variablesConverter = {"__root":{"_voyageSlug":{"c":"Util.CustomScalars.VoyageSlug.Exn"},"_targetBookingId":{"c":"Util.CustomScalars.BookingId.Exn"},"_brandFamilySlug":{"c":"Util.CustomScalars.BrandFamilySlug.Exn"},"_bookingSessionIds":{"ca":"Util.CustomScalars.ObjId.Exn"}}};

var variablesConverterMap = {
  "Util.CustomScalars.ObjId.Exn": CS_UUID$Util.ObjId.Exn.serialize,
  "Util.CustomScalars.BrandFamilySlug.Exn": CS_Slugs$Util.BrandFamilySlug.Exn.serialize,
  "Util.CustomScalars.BookingId.Exn": CS_NonemptyStrings$Util.BookingId.Exn.serialize,
  "Util.CustomScalars.VoyageSlug.Exn": CS_Slugs$Util.VoyageSlug.Exn.serialize
};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, variablesConverterMap, undefined);
}

var wrapResponseConverter = {};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, undefined, null);
}

var responseConverter = {};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, undefined, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: variablesConverterMap,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: undefined,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: undefined,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse
};

function bookingSessionError_decode($$enum) {
  if ($$enum === "NO_PAX" || $$enum === "INVALID_PAYMENT_PLAN" || $$enum === "NEW_UNCLAIMED_USER_WITH_NULL_CUSTODIAN_ACCOUNT_ID" || $$enum === "USER_DELETED_STATUS" || $$enum === "AMBIGUOUS_PAX_TARGETS" || $$enum === "PAX_INVALID_AGE_FOR_PAX1" || $$enum === "PAX_IDX_INVALID" || $$enum === "PAX_HAS_ANOTHER_ACTIVE_BOOKING" || $$enum === "PAX_ACCT_INVALID" || $$enum === "BILLING_CONTACT_HAS_TOO_MANY_BOOKINGS" || $$enum === "INVALID_BILLING_CONTACT" || $$enum === "INVALID_CABIN_VARS" || $$enum === "INVALID_CABIN" || $$enum === "INVALID_FARE_CLASS" || $$enum === "BOOKING_ID_ALREADY_IN_USE" || $$enum === "REFERRER_MULTIBALL_ERROR") {
    return $$enum;
  }
  
}

function bookingSessionError_fromString(str) {
  return bookingSessionError_decode(str);
}

var Utils = {
  bookingSessionError_decode: bookingSessionError_decode,
  bookingSessionError_fromString: bookingSessionError_fromString
};

var node = ((function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "_bookingSessionIds"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "_brandFamilySlug"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "_targetBookingId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "_voyageSlug"
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_bookingSessionIds",
        "variableName": "_bookingSessionIds"
      },
      {
        "kind": "Variable",
        "name": "_brandFamilySlug",
        "variableName": "_brandFamilySlug"
      },
      {
        "kind": "Variable",
        "name": "_targetBookingId",
        "variableName": "_targetBookingId"
      },
      {
        "kind": "Variable",
        "name": "_voyageSlug",
        "variableName": "_voyageSlug"
      }
    ],
    "concreteType": "BookingSessionValidationResultsConnection",
    "kind": "LinkedField",
    "name": "validateBookingSessions",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BookingSessionValidationResult",
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isValid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nullableErr",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteOrBookModalQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "DeleteOrBookModalQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "bbce3fcdc849a7783bac5de910f4bced",
    "id": null,
    "metadata": {},
    "name": "DeleteOrBookModalQuery",
    "operationKind": "query",
    "text": "query DeleteOrBookModalQuery(\n  $_bookingSessionIds: [ObjId]!\n  $_brandFamilySlug: BrandFamilySlug!\n  $_voyageSlug: VoyageSlug!\n  $_targetBookingId: BookingId\n) {\n  validateBookingSessions(_bookingSessionIds: $_bookingSessionIds, _brandFamilySlug: $_brandFamilySlug, _voyageSlug: $_voyageSlug, _targetBookingId: $_targetBookingId) {\n    nodes {\n      isValid\n      nullableErr\n    }\n  }\n}\n"
  }
};
})());

function load(environment, variables, fetchPolicy, fetchKey, networkCacheConfig) {
  return ReactRelay.loadQuery(environment, node, convertVariables(variables), {
              fetchKey: fetchKey,
              fetchPolicy: fetchPolicy,
              networkCacheConfig: networkCacheConfig
            });
}

function queryRefToObservable(token) {
  return Caml_option.nullable_to_opt(token.source);
}

function queryRefToPromise(token) {
  return new Promise((function (resolve, param) {
                var o = queryRefToObservable(token);
                if (o !== undefined) {
                  Caml_option.valFromOption(o).subscribe({
                        complete: (function () {
                            resolve({
                                  TAG: "Ok",
                                  _0: undefined
                                });
                          })
                      });
                  return ;
                } else {
                  return resolve({
                              TAG: "Error",
                              _0: undefined
                            });
                }
              }));
}

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
exports.load = load;
exports.queryRefToObservable = queryRefToObservable;
exports.queryRefToPromise = queryRefToPromise;
/* node Not a pure module */
