// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Dialog$Thick = require("../common/molecules/Dialog.bs.js");
var Stack = require("@mui/joy/Stack").default;
var AppAlert$Thick = require("../common/atoms/AppAlert.bs.js");
var Button = require("@mui/joy/Button").default;
var BSPInputs$Thick = require("../booking-sessions/BSPInputs.bs.js");
var HopperState$Util = require("util/src/HopperState.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var CustomersState$Thick = require("../../utils/CustomersState.bs.js");
var DialogActions = require("@mui/joy/DialogActions").default;
var DialogContent = require("@mui/joy/DialogContent").default;
var ConnectedToPrefilled$Thick = require("./mutations/ConnectedToPrefilled.bs.js");

function ConnectedToPrefilledForm$WithSomeForm(props) {
  var onClose = props.onClose;
  var onSelectUserToUpdate = props.onSelectUserToUpdate;
  var brandFamily = props.brandFamily;
  var setError = props.setError;
  var error = props.error;
  var success = props.success;
  var convert = props.convert;
  var state = props.state;
  var form = props.form;
  var match = HopperState$Util.Observable.useComputed(form, (function (f) {
          return [
                  f.accountId,
                  f.label,
                  f.targetEmail.value
                ];
        }), undefined, undefined);
  var label = match[1];
  var accountId = match[0];
  var validatedForm = HopperState$Util.Observable.useComputed(form, (function (f) {
          return CustomersState$Thick.validateForConvertToPrefilled(f, brandFamily, undefined);
        }), undefined, undefined);
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(DialogContent, {
                      children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                spacing: 2,
                                children: [
                                  JsxRuntime.jsx(Typography, {
                                        textColor: "text.tertiary",
                                        children: Caml_option.some("You are proposing to convert the connected account " + label + " to a prefilled account. Please enter an email address for this new account.")
                                      }),
                                  JsxRuntime.jsx(Stack, {
                                        spacing: 1,
                                        children: Caml_option.some(JsxRuntime.jsx(BSPInputs$Thick.$$Text.Optional.make, {
                                                  view: {
                                                    TAG: "Direct",
                                                    _0: form
                                                  },
                                                  input: CustomersState$Thick.targetEmail,
                                                  label: "Email Address",
                                                  helperText: "The email address for the account",
                                                  disabled: success,
                                                  fullWidth: true
                                                }))
                                      }),
                                  error !== undefined ? JsxRuntime.jsx(AppAlert$Thick.make, {
                                          message: error,
                                          type_: "error"
                                        }) : null,
                                  success ? JsxRuntime.jsx(AppAlert$Thick.make, {
                                          message: "The account " + label + " has been successfully converted to a prefilled account with the email address " + Belt_Option.getWithDefault(match[2], "") + "! You may now edit details or close this window.",
                                          type_: "success"
                                        }) : null
                                ]
                              }))
                    }),
                JsxRuntime.jsx(DialogActions, {
                      children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                component: "form",
                                onSubmit: (function (e) {
                                    e.preventDefault();
                                    if (success) {
                                      onClose();
                                      onSelectUserToUpdate(accountId);
                                      return ;
                                    }
                                    var input = CustomersState$Thick.validateForConvertToPrefilled(HopperState$Util.Observable.dangerouslyRead(form), brandFamily, undefined);
                                    if (input.TAG === "Ok") {
                                      return convert(input._0, state);
                                    }
                                    var err = input._0;
                                    setError(function (param) {
                                          return err;
                                        });
                                  }),
                                children: Caml_option.some(JsxRuntime.jsx(Button, {
                                          loading: props.loading,
                                          type: "submit",
                                          color: success ? "success" : "primary",
                                          disabled: Belt_Result.isError(validatedForm),
                                          children: Caml_option.some(success ? "Edit User Details" : "Convert to Prefilled Account")
                                        }))
                              }))
                    }),
                JsxRuntime.jsx("div", {})
              ]
            });
}

var WithSomeForm = {
  make: ConnectedToPrefilledForm$WithSomeForm
};

function ConnectedToPrefilledForm(props) {
  var onClose = props.onClose;
  var form = props.form;
  var hasForm = HopperState$Util.Observable.useComputed(form, Belt_Option.isSome, undefined, undefined);
  var match = ConnectedToPrefilled$Thick.useMutation(props.refetch);
  var setSuccess = match.setSuccess;
  var setError = match.setError;
  var onClose$1 = function () {
    onClose();
    setError(function (param) {
          
        });
    setSuccess(function (param) {
          return false;
        });
  };
  return JsxRuntime.jsx(Dialog$Thick.make, {
              open_: props.open_,
              onClose: onClose$1,
              title: "Convert Connected Account to Prefilled Account",
              children: hasForm ? JsxRuntime.jsx(ConnectedToPrefilledForm$WithSomeForm, {
                      form: CustomersState$Thick.convertExn(form),
                      state: props.state,
                      convert: match.convert,
                      success: match.success,
                      loading: match.loading,
                      error: match.error,
                      setError: setError,
                      brandFamily: props.brandFamily,
                      onSelectUserToUpdate: props.onSelectUserToUpdate,
                      onClose: onClose$1
                    }) : null
            });
}

var make = ConnectedToPrefilledForm;

exports.WithSomeForm = WithSomeForm;
exports.make = make;
/* Dialog-Thick Not a pure module */
