// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Joy$Util = require("util/src/Joy.bs.js");
var Js_array = require("rescript/lib/js/js_array.js");
var DateFns = require("date-fns");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CS_Make$Util = require("util/src/CustomScalars/CS_Make.bs.js");
var Common$Thick = require("../../utils/Common.bs.js");
var DateFns$Util = require("util/src/DateFns.bs.js");
var CS_Phone$Util = require("util/src/CustomScalars/CS_Phone.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var Table = require("@mui/joy/Table").default;
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var Button = require("@mui/joy/Button").default;
var CS_Decimal$Util = require("util/src/CustomScalars/CS_Decimal.bs.js");
var Divider = require("@mui/joy/Divider").default;
var HopperState$Util = require("util/src/HopperState.bs.js");
var MergeModal$Thick = require("./MergeModal.bs.js");
var CS_ISO3166_1$Util = require("util/src/CustomScalars/CS_ISO3166_1.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Accordion = require("@mui/joy/Accordion").default;
var Typography = require("@mui/joy/Typography").default;
var MergeAccounts$Thick = require("../../utils/MergeAccounts.bs.js");
var AccordionGroup = require("@mui/joy/AccordionGroup").default;
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var CS_SQLDatesAndTimes$Util = require("util/src/CustomScalars/CS_SQLDatesAndTimes.bs.js");
var AccordionDetails = require("@mui/joy/AccordionDetails").default;
var AccordionSummary = require("@mui/joy/AccordionSummary").default;
var CS_LoyaltyAndCruiselineNumbers$Util = require("util/src/CustomScalars/CS_LoyaltyAndCruiselineNumbers.bs.js");

var blankResult = "[None]";

function MergeRundown$MergeAccordions$MergeAccordion(props) {
  var children = props.children;
  var __grid = props.grid;
  var __defaultExpanded = props.defaultExpanded;
  var defaultExpanded = __defaultExpanded !== undefined ? __defaultExpanded : false;
  var grid = __grid !== undefined ? __grid : true;
  return JsxRuntime.jsxs(Accordion, {
              children: [
                JsxRuntime.jsx(AccordionSummary, {
                      children: Caml_option.some(props.title)
                    }),
                JsxRuntime.jsx(AccordionDetails, {
                      children: Caml_option.some(grid ? JsxRuntime.jsx(Joy$Util.Grid.make, {
                                  children: Caml_option.some(children),
                                  container: true,
                                  rowSpacing: 2,
                                  columnSpacing: 2
                                }) : children)
                    })
              ],
              defaultExpanded: defaultExpanded,
              sx: {
                width: "100%"
              }
            });
}

var MergeAccordion = {
  make: MergeRundown$MergeAccordions$MergeAccordion
};

function MergeRundown$MergeAccordions$PersonalDetail(props) {
  var result = props.result;
  return JsxRuntime.jsxs(Typography, {
              level: "body-sm",
              fontWeight: "bold",
              children: [
                props.title + ":",
                " ",
                JsxRuntime.jsx(Typography, {
                      component: "span",
                      sx: {
                        fontStyle: result === blankResult ? "italic" : "normal"
                      },
                      fontWeight: "normal",
                      children: Caml_option.some(result)
                    })
              ]
            });
}

var PersonalDetail = {
  make: MergeRundown$MergeAccordions$PersonalDetail
};

function MergeRundown$MergeAccordions$AccountType(props) {
  return JsxRuntime.jsxs(Stack, {
              direction: "column",
              spacing: 1,
              children: [
                JsxRuntime.jsx(Typography, {
                      level: "title-md",
                      fontWeight: "bold",
                      children: Caml_option.some(props.type_)
                    }),
                JsxRuntime.jsx(Stack, {
                      direction: "column",
                      children: Caml_option.some(props.children)
                    })
              ]
            });
}

var AccountType = {
  make: MergeRundown$MergeAccordions$AccountType
};

function MergeRundown$MergeAccordions$PersonalDetailTable(props) {
  var dest = props.dest;
  var src = props.src;
  return JsxRuntime.jsxs(Table, {
              stripe: "odd",
              hoverRow: true,
              sx: {
                captionSide: "top",
                width: "auto",
                "& tbody": {
                  bgcolor: "background.surface"
                },
                "--TableCell-paddingY": "2px",
                "--TableCell-height": "25px"
              },
              size: "sm",
              children: [
                JsxRuntime.jsx("thead", {
                      children: JsxRuntime.jsxs("tr", {
                            children: [
                              JsxRuntime.jsx("th", {
                                    children: JsxRuntime.jsx(Typography, {
                                          level: "title-md",
                                          fontWeight: "bold",
                                          children: "Source Account"
                                        })
                                  }),
                              JsxRuntime.jsx("th", {
                                    children: JsxRuntime.jsx(Typography, {
                                          level: "title-md",
                                          fontWeight: "bold",
                                          children: "Destination Account"
                                        })
                                  })
                            ]
                          })
                    }),
                JsxRuntime.jsxs("tbody", {
                      children: [
                        JsxRuntime.jsxs("tr", {
                              children: [
                                JsxRuntime.jsx("td", {
                                      children: JsxRuntime.jsx(MergeRundown$MergeAccordions$PersonalDetail, {
                                            title: "Email",
                                            result: Belt_Option.mapWithDefault(src.email, blankResult, CS_Emails$Util.Email.toString)
                                          })
                                    }),
                                JsxRuntime.jsx("td", {
                                      children: JsxRuntime.jsx(MergeRundown$MergeAccordions$PersonalDetail, {
                                            title: "Email",
                                            result: Belt_Option.mapWithDefault(dest.email, blankResult, CS_Emails$Util.Email.toString)
                                          })
                                    })
                              ]
                            }),
                        JsxRuntime.jsxs("tr", {
                              children: [
                                JsxRuntime.jsx("td", {
                                      children: JsxRuntime.jsx(MergeRundown$MergeAccordions$PersonalDetail, {
                                            title: "Account Status",
                                            result: MergeAccounts$Thick.accountStatusToString(src.accountStatus)
                                          })
                                    }),
                                JsxRuntime.jsx("td", {
                                      children: JsxRuntime.jsx(MergeRundown$MergeAccordions$PersonalDetail, {
                                            title: "Account Status",
                                            result: MergeAccounts$Thick.accountStatusToString(dest.accountStatus)
                                          })
                                    })
                              ]
                            }),
                        JsxRuntime.jsxs("tr", {
                              children: [
                                JsxRuntime.jsx("td", {
                                      children: JsxRuntime.jsx(MergeRundown$MergeAccordions$PersonalDetail, {
                                            title: "Pref First Name",
                                            result: CS_NonemptyStrings$Util.NonEmptyString.toString(src.preferredFirstName)
                                          })
                                    }),
                                JsxRuntime.jsx("td", {
                                      children: JsxRuntime.jsx(MergeRundown$MergeAccordions$PersonalDetail, {
                                            title: "Pref First Name",
                                            result: CS_NonemptyStrings$Util.NonEmptyString.toString(dest.preferredFirstName)
                                          })
                                    })
                              ]
                            }),
                        JsxRuntime.jsxs("tr", {
                              children: [
                                JsxRuntime.jsx("td", {
                                      children: JsxRuntime.jsx(MergeRundown$MergeAccordions$PersonalDetail, {
                                            title: "Pref Last Name",
                                            result: Belt_Option.mapWithDefault(src.preferredLastName, blankResult, CS_NonemptyStrings$Util.NonEmptyString.toString)
                                          })
                                    }),
                                JsxRuntime.jsx("td", {
                                      children: JsxRuntime.jsx(MergeRundown$MergeAccordions$PersonalDetail, {
                                            title: "Pref Last Name",
                                            result: Belt_Option.mapWithDefault(dest.preferredLastName, blankResult, CS_NonemptyStrings$Util.NonEmptyString.toString)
                                          })
                                    })
                              ]
                            }),
                        JsxRuntime.jsxs("tr", {
                              children: [
                                JsxRuntime.jsx("td", {
                                      children: JsxRuntime.jsx(MergeRundown$MergeAccordions$PersonalDetail, {
                                            title: "Loyalty #",
                                            result: CS_LoyaltyAndCruiselineNumbers$Util.LoyaltyNumber.toString(src.loyaltyNumber)
                                          })
                                    }),
                                JsxRuntime.jsx("td", {
                                      children: JsxRuntime.jsx(MergeRundown$MergeAccordions$PersonalDetail, {
                                            title: "Loyalty #",
                                            result: CS_LoyaltyAndCruiselineNumbers$Util.LoyaltyNumber.toString(dest.loyaltyNumber)
                                          })
                                    })
                              ]
                            }),
                        JsxRuntime.jsxs("tr", {
                              children: [
                                JsxRuntime.jsx("td", {
                                      children: JsxRuntime.jsx(MergeRundown$MergeAccordions$PersonalDetail, {
                                            title: "HAL Mariner #",
                                            result: Belt_Option.mapWithDefault(src.halMarinerNumber, blankResult, CS_LoyaltyAndCruiselineNumbers$Util.HalMarinerNumber.toString)
                                          })
                                    }),
                                JsxRuntime.jsx("td", {
                                      children: JsxRuntime.jsx(MergeRundown$MergeAccordions$PersonalDetail, {
                                            title: "HAL Mariner #",
                                            result: Belt_Option.mapWithDefault(dest.halMarinerNumber, blankResult, CS_LoyaltyAndCruiselineNumbers$Util.HalMarinerNumber.toString)
                                          })
                                    })
                              ]
                            }),
                        JsxRuntime.jsxs("tr", {
                              children: [
                                JsxRuntime.jsx("td", {
                                      children: JsxRuntime.jsx(MergeRundown$MergeAccordions$PersonalDetail, {
                                            title: "Phone",
                                            result: Belt_Option.mapWithDefault(src.phone, blankResult, CS_Phone$Util.Phone.toString)
                                          })
                                    }),
                                JsxRuntime.jsx("td", {
                                      children: JsxRuntime.jsx(MergeRundown$MergeAccordions$PersonalDetail, {
                                            title: "Phone",
                                            result: Belt_Option.mapWithDefault(dest.phone, blankResult, CS_Phone$Util.Phone.toString)
                                          })
                                    })
                              ]
                            }),
                        JsxRuntime.jsxs("tr", {
                              children: [
                                JsxRuntime.jsx("td", {
                                      children: JsxRuntime.jsx(MergeRundown$MergeAccordions$PersonalDetail, {
                                            title: "Postal Code",
                                            result: Belt_Option.mapWithDefault(src.postalCode, blankResult, CS_NonemptyStrings$Util.PostalCode.toString)
                                          })
                                    }),
                                JsxRuntime.jsx("td", {
                                      children: JsxRuntime.jsx(MergeRundown$MergeAccordions$PersonalDetail, {
                                            title: "Postal Code",
                                            result: Belt_Option.mapWithDefault(dest.postalCode, blankResult, CS_NonemptyStrings$Util.PostalCode.toString)
                                          })
                                    })
                              ]
                            }),
                        JsxRuntime.jsxs("tr", {
                              children: [
                                JsxRuntime.jsx("td", {
                                      children: JsxRuntime.jsx(MergeRundown$MergeAccordions$PersonalDetail, {
                                            title: "Residence Country",
                                            result: Belt_Option.mapWithDefault(src.residenceCountry, blankResult, CS_ISO3166_1$Util.Alpha3.toString)
                                          })
                                    }),
                                JsxRuntime.jsx("td", {
                                      children: JsxRuntime.jsx(MergeRundown$MergeAccordions$PersonalDetail, {
                                            title: "Residence Country",
                                            result: Belt_Option.mapWithDefault(dest.residenceCountry, blankResult, CS_ISO3166_1$Util.Alpha3.toString)
                                          })
                                    })
                              ]
                            }),
                        JsxRuntime.jsxs("tr", {
                              children: [
                                JsxRuntime.jsx("td", {
                                      children: JsxRuntime.jsx(MergeRundown$MergeAccordions$PersonalDetail, {
                                            title: "Acquisition Channel",
                                            result: Belt_Option.mapWithDefault(src.acquisitionSlug, blankResult, CS_Slugs$Util.Slug.toString)
                                          })
                                    }),
                                JsxRuntime.jsx("td", {
                                      children: JsxRuntime.jsx(MergeRundown$MergeAccordions$PersonalDetail, {
                                            title: "Acquisition Channel",
                                            result: Belt_Option.mapWithDefault(dest.acquisitionSlug, blankResult, CS_Slugs$Util.Slug.toString)
                                          })
                                    })
                              ]
                            }),
                        JsxRuntime.jsxs("tr", {
                              children: [
                                JsxRuntime.jsx("td", {
                                      children: JsxRuntime.jsx(MergeRundown$MergeAccordions$PersonalDetail, {
                                            title: "Doc First Name",
                                            result: Belt_Option.mapWithDefault(src.legalDocFirstName, blankResult, CS_NonemptyStrings$Util.NonEmptyString.toString)
                                          })
                                    }),
                                JsxRuntime.jsx("td", {
                                      children: JsxRuntime.jsx(MergeRundown$MergeAccordions$PersonalDetail, {
                                            title: "Doc First Name",
                                            result: Belt_Option.mapWithDefault(dest.legalDocFirstName, blankResult, CS_NonemptyStrings$Util.NonEmptyString.toString)
                                          })
                                    })
                              ]
                            }),
                        JsxRuntime.jsxs("tr", {
                              children: [
                                JsxRuntime.jsx("td", {
                                      children: JsxRuntime.jsx(MergeRundown$MergeAccordions$PersonalDetail, {
                                            title: "Doc Last Name",
                                            result: Belt_Option.mapWithDefault(src.legalDocLastName, blankResult, CS_NonemptyStrings$Util.NonEmptyString.toString)
                                          })
                                    }),
                                JsxRuntime.jsx("td", {
                                      children: JsxRuntime.jsx(MergeRundown$MergeAccordions$PersonalDetail, {
                                            title: "Doc Last Name",
                                            result: Belt_Option.mapWithDefault(dest.legalDocLastName, blankResult, CS_NonemptyStrings$Util.NonEmptyString.toString)
                                          })
                                    })
                              ]
                            }),
                        JsxRuntime.jsxs("tr", {
                              children: [
                                JsxRuntime.jsx("td", {
                                      children: JsxRuntime.jsx(MergeRundown$MergeAccordions$PersonalDetail, {
                                            title: "Doc Birthdate",
                                            result: Belt_Option.getWithDefault(Belt_Option.map(src.legalDocBirthdate, (function (d) {
                                                        return DateFns.format(Belt_Result.getExn(DateFns$Util.parseISO(CS_SQLDatesAndTimes$Util.DateOnly.toPrimitive(d))), "MM/dd/yyyy");
                                                      })), blankResult)
                                          })
                                    }),
                                JsxRuntime.jsx("td", {
                                      children: JsxRuntime.jsx(MergeRundown$MergeAccordions$PersonalDetail, {
                                            title: "Doc Birthdate",
                                            result: Belt_Option.getWithDefault(Belt_Option.map(dest.legalDocBirthdate, (function (d) {
                                                        return DateFns.format(Belt_Result.getExn(DateFns$Util.parseISO(CS_SQLDatesAndTimes$Util.DateOnly.toPrimitive(d))), "MM/dd/yyyy");
                                                      })), blankResult)
                                          })
                                    })
                              ]
                            }),
                        JsxRuntime.jsxs("tr", {
                              children: [
                                JsxRuntime.jsx("td", {
                                      children: JsxRuntime.jsx(MergeRundown$MergeAccordions$PersonalDetail, {
                                            title: "Doc Gender",
                                            result: Belt_Option.mapWithDefault(src.legalDocGender, blankResult, MergeAccounts$Thick.docGenderToString)
                                          })
                                    }),
                                JsxRuntime.jsx("td", {
                                      children: JsxRuntime.jsx(MergeRundown$MergeAccordions$PersonalDetail, {
                                            title: "Doc Gender",
                                            result: Belt_Option.mapWithDefault(dest.legalDocGender, blankResult, MergeAccounts$Thick.docGenderToString)
                                          })
                                    })
                              ]
                            }),
                        JsxRuntime.jsxs("tr", {
                              children: [
                                JsxRuntime.jsx("td", {
                                      children: JsxRuntime.jsx(MergeRundown$MergeAccordions$PersonalDetail, {
                                            title: "Doc Citizenship",
                                            result: Belt_Option.mapWithDefault(src.legalDocCitizenship, blankResult, CS_ISO3166_1$Util.Alpha3.toString)
                                          })
                                    }),
                                JsxRuntime.jsx("td", {
                                      children: JsxRuntime.jsx(MergeRundown$MergeAccordions$PersonalDetail, {
                                            title: "Doc Citizenship",
                                            result: Belt_Option.mapWithDefault(dest.legalDocCitizenship, blankResult, CS_ISO3166_1$Util.Alpha3.toString)
                                          })
                                    })
                              ]
                            })
                      ]
                    })
              ]
            });
}

var PersonalDetailTable = {
  make: MergeRundown$MergeAccordions$PersonalDetailTable
};

function MergeRundown$MergeAccordions$ConnectedAccts(props) {
  var acct = props.acct;
  var match = acct.connectedAccounts.length;
  return JsxRuntime.jsx(MergeRundown$MergeAccordions$AccountType, {
              type_: props.type_,
              children: match !== 0 ? Belt_Array.mapWithIndex(acct.connectedAccounts, (function (i, ca) {
                        return JsxRuntime.jsx(Typography, {
                                    level: "body-sm",
                                    children: Caml_option.some(ca.connectedName + " (" + CS_LoyaltyAndCruiselineNumbers$Util.LoyaltyNumber.toString(ca.connectedLoyaltyNumber) + ")")
                                  }, String(i));
                      })) : JsxRuntime.jsx(Typography, {
                      level: "body-sm",
                      sx: {
                        fontStyle: "italic"
                      },
                      children: Caml_option.some(blankResult)
                    })
            });
}

var ConnectedAccts = {
  make: MergeRundown$MergeAccordions$ConnectedAccts
};

function MergeRundown$MergeAccordions$OwnedBookings(props) {
  var acct = props.acct;
  var match = acct.ownedBookings.length;
  return JsxRuntime.jsx(MergeRundown$MergeAccordions$AccountType, {
              type_: props.type_,
              children: match !== 0 ? Belt_Array.mapWithIndex(acct.ownedBookings, (function (i, ob) {
                        return JsxRuntime.jsx(Typography, {
                                    level: "body-sm",
                                    children: Caml_option.some(CS_Slugs$Util.VoyageSlug.toString(ob.voyageSlug) + ": " + CS_NonemptyStrings$Util.BookingId.toString(ob.bookingId) + " ver " + String(ob.bookingVersionNumber) + " [" + MergeAccounts$Thick.lifecycleStatusToString(ob.lifecycleStatus) + "]")
                                  }, String(i));
                      })) : JsxRuntime.jsx(Typography, {
                      level: "body-sm",
                      sx: {
                        fontStyle: "italic"
                      },
                      children: Caml_option.some(blankResult)
                    })
            });
}

var OwnedBookings = {
  make: MergeRundown$MergeAccordions$OwnedBookings
};

function MergeRundown$MergeAccordions$PaymentRecords(props) {
  var acct = props.acct;
  var match = acct.payments.length;
  return JsxRuntime.jsx(MergeRundown$MergeAccordions$AccountType, {
              type_: props.type_,
              children: match !== 0 ? Belt_Array.mapWithIndex(acct.payments, (function (i, p) {
                        return JsxRuntime.jsx(Typography, {
                                    level: "body-sm",
                                    children: Caml_option.some(DateFns.format(CS_Make$Util.Dateable.Plain.toDate(p.createdAt), "MM/dd/yyyy") + ": " + Common$Thick.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(p.amount)) + " " + Belt_Option.mapWithDefault(p.description, "", (function (v) {
                                                return "(" + CS_NonemptyStrings$Util.NonEmptyString.toString(v) + ")";
                                              })) + " - " + CS_Slugs$Util.VoyageSlug.toString(p.payVoyageSlug) + ": " + CS_NonemptyStrings$Util.BookingId.toString(p.payBookingId))
                                  }, String(i));
                      })) : JsxRuntime.jsx(Typography, {
                      level: "body-sm",
                      sx: {
                        fontStyle: "italic"
                      },
                      children: Caml_option.some(blankResult)
                    })
            });
}

var PaymentRecords = {
  make: MergeRundown$MergeAccordions$PaymentRecords
};

function MergeRundown$MergeAccordions$PassengerRecords(props) {
  var acct = props.acct;
  var match = acct.passengerRecords.length;
  return JsxRuntime.jsx(MergeRundown$MergeAccordions$AccountType, {
              type_: props.type_,
              children: match !== 0 ? JsxRuntime.jsx(Stack, {
                      spacing: 1,
                      children: Caml_option.some(Belt_Array.mapWithIndex(acct.passengerRecords, (function (i, pr) {
                                  return JsxRuntime.jsxs(Stack, {
                                              children: [
                                                JsxRuntime.jsx(Typography, {
                                                      level: "title-md",
                                                      children: Caml_option.some(CS_Slugs$Util.VoyageSlug.toString(pr.passVoyageSlug) + ": " + CS_NonemptyStrings$Util.BookingId.toString(pr.passBookingId) + " ver " + String(pr.passBookingVersionNumber) + " [" + MergeAccounts$Thick.lifecycleStatusToString(pr.passBookingLifecycleStatus) + "]")
                                                    }),
                                                JsxRuntime.jsx(MergeRundown$MergeAccordions$PersonalDetail, {
                                                      title: "Doc First Name",
                                                      result: Belt_Option.mapWithDefault(pr.passLegalDocFirstName, blankResult, CS_NonemptyStrings$Util.NonEmptyString.toString)
                                                    }),
                                                JsxRuntime.jsx(MergeRundown$MergeAccordions$PersonalDetail, {
                                                      title: "Doc Last Name",
                                                      result: Belt_Option.mapWithDefault(pr.passLegalDocLastName, blankResult, CS_NonemptyStrings$Util.NonEmptyString.toString)
                                                    }),
                                                JsxRuntime.jsx(MergeRundown$MergeAccordions$PersonalDetail, {
                                                      title: "Doc Birthdate",
                                                      result: Belt_Option.getWithDefault(Belt_Option.map(pr.passLegalDocBirthdate, (function (d) {
                                                                  return DateFns.format(Belt_Result.getExn(DateFns$Util.parseISO(CS_SQLDatesAndTimes$Util.DateOnly.toPrimitive(d))), "MM/dd/yyyy");
                                                                })), blankResult)
                                                    }),
                                                JsxRuntime.jsx(MergeRundown$MergeAccordions$PersonalDetail, {
                                                      title: "Doc Gender",
                                                      result: Belt_Option.mapWithDefault(pr.passLegalDocGender, blankResult, MergeAccounts$Thick.docGenderToString)
                                                    }),
                                                JsxRuntime.jsx(MergeRundown$MergeAccordions$PersonalDetail, {
                                                      title: "Doc Citizenship",
                                                      result: Belt_Option.mapWithDefault(pr.passLegalDocCitizenship, blankResult, CS_ISO3166_1$Util.Alpha3.toString)
                                                    })
                                              ]
                                            }, String(i));
                                })))
                    }) : JsxRuntime.jsx(Typography, {
                      level: "body-sm",
                      sx: {
                        fontStyle: "italic"
                      },
                      children: Caml_option.some(blankResult)
                    })
            });
}

var PassengerRecords = {
  make: MergeRundown$MergeAccordions$PassengerRecords
};

function MergeRundown$MergeAccordions$PassengerHistory(props) {
  var acct = props.acct;
  var match = acct.passengerHistory.length;
  return JsxRuntime.jsx(MergeRundown$MergeAccordions$AccountType, {
              type_: props.type_,
              children: match !== 0 ? Belt_Array.mapWithIndex(acct.passengerHistory, (function (i, voyageSlug) {
                        return JsxRuntime.jsx(Typography, {
                                    level: "body-sm",
                                    children: Caml_option.some(CS_Slugs$Util.VoyageSlug.toString(voyageSlug))
                                  }, String(i));
                      })) : JsxRuntime.jsx(Typography, {
                      level: "body-sm",
                      sx: {
                        fontStyle: "italic"
                      },
                      children: Caml_option.some(blankResult)
                    })
            });
}

var PassengerHistory = {
  make: MergeRundown$MergeAccordions$PassengerHistory
};

function MergeRundown$MergeAccordions(props) {
  var dest = props.dest;
  var src = props.src;
  var srcCustodian = Belt_Option.getWithDefault(Belt_Option.map(src.custodian, (function (v) {
              return v.custodianName + " " + Belt_Option.mapWithDefault(v.custodianEmail, "", (function (e) {
                            return "(" + CS_Emails$Util.Email.toString(e) + ")";
                          }));
            })), blankResult);
  var destCustodian = Belt_Option.getWithDefault(Belt_Option.map(dest.custodian, (function (v) {
              return v.custodianName + " " + Belt_Option.mapWithDefault(v.custodianEmail, "", (function (e) {
                            return "(" + CS_Emails$Util.Email.toString(e) + ")";
                          }));
            })), blankResult);
  var custodianTitle = "Custodian" + (
    src.custodian !== undefined || dest.custodian !== undefined ? " (" + (
        src.custodian !== undefined ? "✔️" : "N/A"
      ) + " => " + (
        dest.custodian !== undefined ? "✔️" : "N/A"
      ) + ")" : " (N/A)"
  );
  var connectedTitle = (
    (dest.accountStatus === "UNCLAIMED" || dest.accountStatus === "TRANSFERRING") && src.connectedAccounts.length !== 0 ? "❌ " : ""
  ) + "Connected Accounts (" + String(src.connectedAccounts.length) + " => " + String(dest.connectedAccounts.length) + ")";
  var bcBookingsTitle = (
    (dest.accountStatus === "UNCLAIMED" || dest.accountStatus === "TRANSFERRING") && src.ownedBookings.length !== 0 ? "❌ " : ""
  ) + "Owned Bookings (" + String(src.ownedBookings.length) + " => " + String(dest.ownedBookings.length) + ")";
  var overlappingPaxRecords = Belt_Array.reduce(src.passengerRecords, false, (function (a, b) {
          if (a) {
            return true;
          } else {
            return Belt_Array.keepMap(dest.passengerRecords, (function (d) {
                          if (Caml_obj.equal(b.passVoyageSlug, d.passVoyageSlug) && Caml_obj.equal(b.passBookingId, d.passBookingId) && b.passBookingVersionNumber === d.passBookingVersionNumber || Caml_obj.equal(b.passVoyageSlug, d.passVoyageSlug) && b.passBookingLifecycleStatus === "ACTIVE" && d.passBookingLifecycleStatus === "ACTIVE") {
                            return d;
                          }
                          
                        })).length !== 0;
          }
        }));
  var paxRecordsTitle = (
    overlappingPaxRecords ? "❌ " : ""
  ) + "Passenger Records (" + String(src.passengerRecords.length) + " => " + String(dest.passengerRecords.length) + ")";
  var paymentsTitle = (
    (dest.accountStatus === "UNCLAIMED" || dest.accountStatus === "TRANSFERRING") && src.payments.length !== 0 ? "⚠️ " : ""
  ) + "Payments (" + String(src.payments.length) + " => " + String(dest.payments.length) + ")";
  var overlappingPaxHist = Belt_Array.reduce(src.passengerHistory, false, (function (a, b) {
          if (a) {
            return true;
          } else {
            return Js_array.indexOf(b, dest.passengerHistory) > -1;
          }
        }));
  var paxHistoryTitle = (
    overlappingPaxHist ? "❌ " : ""
  ) + "Passenger History (" + String(src.passengerHistory.length) + " => " + String(dest.passengerHistory.length) + ")";
  return JsxRuntime.jsx(Sheet, {
              sx: {
                width: "100%"
              },
              children: Caml_option.some(JsxRuntime.jsxs(AccordionGroup, {
                        children: [
                          JsxRuntime.jsx(MergeRundown$MergeAccordions$MergeAccordion, {
                                title: "User Profile",
                                defaultExpanded: true,
                                grid: false,
                                children: JsxRuntime.jsx(MergeRundown$MergeAccordions$PersonalDetailTable, {
                                      src: src,
                                      dest: dest
                                    })
                              }),
                          JsxRuntime.jsxs(MergeRundown$MergeAccordions$MergeAccordion, {
                                title: custodianTitle,
                                children: [
                                  JsxRuntime.jsx(Joy$Util.Grid.make, {
                                        children: Caml_option.some(JsxRuntime.jsx(MergeRundown$MergeAccordions$AccountType, {
                                                  type_: "Source Account",
                                                  children: JsxRuntime.jsx(Typography, {
                                                        level: "body-sm",
                                                        sx: {
                                                          fontStyle: srcCustodian === blankResult ? "italic" : "normal"
                                                        },
                                                        children: Caml_option.some(srcCustodian)
                                                      })
                                                })),
                                        xs: 6
                                      }),
                                  JsxRuntime.jsx(Joy$Util.Grid.make, {
                                        children: Caml_option.some(JsxRuntime.jsx(MergeRundown$MergeAccordions$AccountType, {
                                                  type_: "Destination Account",
                                                  children: JsxRuntime.jsx(Typography, {
                                                        level: "body-sm",
                                                        sx: {
                                                          fontStyle: destCustodian === blankResult ? "italic" : "normal"
                                                        },
                                                        children: Caml_option.some(destCustodian)
                                                      })
                                                })),
                                        xs: 6
                                      })
                                ]
                              }),
                          JsxRuntime.jsxs(MergeRundown$MergeAccordions$MergeAccordion, {
                                title: connectedTitle,
                                children: [
                                  JsxRuntime.jsx(Joy$Util.Grid.make, {
                                        children: Caml_option.some(JsxRuntime.jsx(MergeRundown$MergeAccordions$ConnectedAccts, {
                                                  type_: "Source Account",
                                                  acct: src
                                                })),
                                        xs: 6
                                      }),
                                  JsxRuntime.jsx(Joy$Util.Grid.make, {
                                        children: Caml_option.some(JsxRuntime.jsx(MergeRundown$MergeAccordions$ConnectedAccts, {
                                                  type_: "Destination Account",
                                                  acct: dest
                                                })),
                                        xs: 6
                                      })
                                ]
                              }),
                          JsxRuntime.jsxs(MergeRundown$MergeAccordions$MergeAccordion, {
                                title: bcBookingsTitle,
                                children: [
                                  JsxRuntime.jsx(Joy$Util.Grid.make, {
                                        children: Caml_option.some(JsxRuntime.jsx(MergeRundown$MergeAccordions$OwnedBookings, {
                                                  type_: "Source Account",
                                                  acct: src
                                                })),
                                        xs: 6
                                      }),
                                  JsxRuntime.jsx(Joy$Util.Grid.make, {
                                        children: Caml_option.some(JsxRuntime.jsx(MergeRundown$MergeAccordions$OwnedBookings, {
                                                  type_: "Destination Account",
                                                  acct: dest
                                                })),
                                        xs: 6
                                      })
                                ]
                              }),
                          JsxRuntime.jsxs(MergeRundown$MergeAccordions$MergeAccordion, {
                                title: paxRecordsTitle,
                                children: [
                                  JsxRuntime.jsx(Joy$Util.Grid.make, {
                                        children: Caml_option.some(JsxRuntime.jsx(MergeRundown$MergeAccordions$PassengerRecords, {
                                                  type_: "Source Account",
                                                  acct: src
                                                })),
                                        xs: 6
                                      }),
                                  JsxRuntime.jsx(Joy$Util.Grid.make, {
                                        children: Caml_option.some(JsxRuntime.jsx(MergeRundown$MergeAccordions$PassengerRecords, {
                                                  type_: "Destination Account",
                                                  acct: dest
                                                })),
                                        xs: 6
                                      })
                                ]
                              }),
                          JsxRuntime.jsxs(MergeRundown$MergeAccordions$MergeAccordion, {
                                title: paymentsTitle,
                                children: [
                                  JsxRuntime.jsx(Joy$Util.Grid.make, {
                                        children: Caml_option.some(JsxRuntime.jsx(MergeRundown$MergeAccordions$PaymentRecords, {
                                                  type_: "Source Account",
                                                  acct: src
                                                })),
                                        xs: 6
                                      }),
                                  JsxRuntime.jsx(Joy$Util.Grid.make, {
                                        children: Caml_option.some(JsxRuntime.jsx(MergeRundown$MergeAccordions$PaymentRecords, {
                                                  type_: "Destination Account",
                                                  acct: dest
                                                })),
                                        xs: 6
                                      })
                                ]
                              }),
                          JsxRuntime.jsxs(MergeRundown$MergeAccordions$MergeAccordion, {
                                title: paxHistoryTitle,
                                children: [
                                  JsxRuntime.jsx(Joy$Util.Grid.make, {
                                        children: Caml_option.some(JsxRuntime.jsx(MergeRundown$MergeAccordions$PassengerHistory, {
                                                  type_: "Source Account",
                                                  acct: src
                                                })),
                                        xs: 6
                                      }),
                                  JsxRuntime.jsx(Joy$Util.Grid.make, {
                                        children: Caml_option.some(JsxRuntime.jsx(MergeRundown$MergeAccordions$PassengerHistory, {
                                                  type_: "Destination Account",
                                                  acct: dest
                                                })),
                                        xs: 6
                                      })
                                ]
                              })
                        ],
                        color: "neutral",
                        variant: "plain",
                        size: "lg"
                      }))
            });
}

var MergeAccordions = {
  MergeAccordion: MergeAccordion,
  PersonalDetail: PersonalDetail,
  AccountType: AccountType,
  PersonalDetailTable: PersonalDetailTable,
  ConnectedAccts: ConnectedAccts,
  OwnedBookings: OwnedBookings,
  PaymentRecords: PaymentRecords,
  PassengerRecords: PassengerRecords,
  PassengerHistory: PassengerHistory,
  make: MergeRundown$MergeAccordions
};

function MergeRundown(props) {
  var view = props.view;
  var match = HopperState$Util.Observable.useComputed(view, (function (v) {
          return [
                  v.sourceAcct,
                  v.destAcct,
                  v.mergeability,
                  Belt_Option.isSome(v.mergeModal)
                ];
        }), undefined, undefined);
  var destAcct = match[1];
  var srcAcct = match[0];
  var openMergeModal = function () {
    if (srcAcct === undefined) {
      return ;
    }
    if (destAcct === undefined) {
      return ;
    }
    var mergeDest = destAcct.accountId;
    var mergeSrc = srcAcct.accountId;
    HopperState$Util.Observable.notify(view, (function (v) {
            return {
                    source: v.source,
                    dest: v.dest,
                    url: v.url,
                    sourceAcct: v.sourceAcct,
                    destAcct: v.destAcct,
                    mergeability: v.mergeability,
                    mergedStatus: v.mergedStatus,
                    mergeModal: {
                      mergeSrc: mergeSrc,
                      mergeDest: mergeDest
                    }
                  };
          }));
  };
  var closeMergeModal = function () {
    HopperState$Util.Observable.notify(view, (function (v) {
            return {
                    source: v.source,
                    dest: v.dest,
                    url: v.url,
                    sourceAcct: v.sourceAcct,
                    destAcct: v.destAcct,
                    mergeability: v.mergeability,
                    mergedStatus: v.mergedStatus,
                    mergeModal: undefined
                  };
          }));
  };
  if (srcAcct === undefined) {
    return null;
  }
  if (destAcct === undefined) {
    return null;
  }
  var tmp;
  var tmp$1 = match[2];
  tmp = typeof tmp$1 !== "object" || tmp$1.TAG !== "CannotMerge" ? false : true;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs(Stack, {
                      direction: "column",
                      spacing: 2,
                      sx: {
                        width: "100%"
                      },
                      children: [
                        JsxRuntime.jsx(Divider, {}),
                        JsxRuntime.jsxs(Stack, {
                              spacing: 1,
                              children: [
                                JsxRuntime.jsx(Typography, {
                                      level: "h4",
                                      component: "h2",
                                      children: "Account Details"
                                    }),
                                JsxRuntime.jsx(Typography, {
                                      level: "body-sm",
                                      children: "Carefully review the below details of the two accounts you plan to merge."
                                    })
                              ]
                            }),
                        JsxRuntime.jsx(MergeRundown$MergeAccordions, {
                              src: srcAcct,
                              dest: destAcct
                            }),
                        JsxRuntime.jsx(Button, {
                              onClick: (function (param) {
                                  openMergeModal();
                                }),
                              color: "danger",
                              size: "lg",
                              disabled: tmp,
                              children: "Merge Accounts"
                            })
                      ]
                    }),
                JsxRuntime.jsx(MergeModal$Thick.make, {
                      open_: match[3],
                      onClose: closeMergeModal,
                      brandFamily: props.brandFamily,
                      view: view
                    })
              ]
            });
}

var make = MergeRundown;

exports.blankResult = blankResult;
exports.MergeAccordions = MergeAccordions;
exports.make = make;
/* Joy-Util Not a pure module */
