// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var Spinner$Thick = require("../components/common/atoms/Spinner.bs.js");
var HopperState$Util = require("util/src/HopperState.bs.js");
var DeckMapPage$Thick = require("../pages/DeckMapPage.bs.js");
var DeckMapUtil$Thick = require("../utils/DeckMapUtil.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var DashboardLayout$Thick = require("../layouts/DashboardLayout.bs.js");
var OrgProtectedRoute$Thick = require("../components/wrappers/OrgProtectedRoute.bs.js");
var BrandProtectedRoute$Thick = require("../components/wrappers/BrandProtectedRoute.bs.js");
var VoyageProtectedRoute$Thick = require("../components/wrappers/VoyageProtectedRoute.bs.js");
var DeckMapRouteQuery_graphql$Thick = require("../__generated__/DeckMapRouteQuery_graphql.bs.js");

var convertVariables = DeckMapRouteQuery_graphql$Thick.Internal.convertVariables;

var convertResponse = DeckMapRouteQuery_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = DeckMapRouteQuery_graphql$Thick.Internal.convertWrapRawResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, DeckMapRouteQuery_graphql$Thick.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, DeckMapRouteQuery_graphql$Thick.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(DeckMapRouteQuery_graphql$Thick.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(DeckMapRouteQuery_graphql$Thick.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(DeckMapRouteQuery_graphql$Thick.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(DeckMapRouteQuery_graphql$Thick.node, convertVariables);

var Query = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function DeckMapRoute(props) {
  var voyage = props.voyage;
  var brandFamily = props.brandFamily;
  var data = use({
        brandFamily: brandFamily,
        voyage: voyage
      }, undefined, undefined, undefined);
  var fragmentRefs = data.fragmentRefs;
  var state = HopperState$Util.Observable.useStore((function () {
          return DeckMapUtil$Thick.blankModel(RescriptReactRouter.dangerouslyGetInitialUrl(undefined, undefined));
        }), true);
  HopperState$Util.Observable.useManagedQueryString(state, DeckMapUtil$Thick.QueryString.queryStateLens, {
        read: (function (m) {
            return m.url;
          }),
        write: (function (m, url) {
            return {
                    deckLeft: m.deckLeft,
                    deckRight: m.deckRight,
                    loadedDecks: m.loadedDecks,
                    mapsAreLoading: m.mapsAreLoading,
                    url: url,
                    hoverCabin: m.hoverCabin,
                    borderColoring: m.borderColoring,
                    hideSegments: m.hideSegments,
                    cabinX: m.cabinX,
                    cabinY: m.cabinY,
                    swapDrawer: m.swapDrawer
                  };
          })
      }, DeckMapUtil$Thick.QueryString.getParser(RescriptReactRouter.dangerouslyGetInitialUrl(undefined, undefined)), (function (param) {
          var path = param.path;
          if (!path) {
            return false;
          }
          var match = path.tl;
          if (!match) {
            return false;
          }
          var match$1 = match.tl;
          if (match$1 && match$1.hd === "deck-map" && !match$1.tl) {
            return match.hd === CS_Slugs$Util.VoyageSlug.toString(voyage);
          } else {
            return false;
          }
        }), 555);
  return JsxRuntime.jsx(DashboardLayout$Thick.make, {
              fragmentRefs: fragmentRefs,
              children: JsxRuntime.jsx(React.Suspense, {
                    children: Caml_option.some(JsxRuntime.jsx(OrgProtectedRoute$Thick.make, {
                              fragmentRefs: fragmentRefs,
                              children: JsxRuntime.jsx(BrandProtectedRoute$Thick.make, {
                                    fragmentRefs: fragmentRefs,
                                    brandFamily: brandFamily,
                                    children: JsxRuntime.jsx(VoyageProtectedRoute$Thick.make, {
                                          fragmentRefs: fragmentRefs,
                                          brandFamily: brandFamily,
                                          voyage: voyage,
                                          children: JsxRuntime.jsx(DeckMapPage$Thick.make, {
                                                fragmentRefs: fragmentRefs,
                                                brandFamily: brandFamily,
                                                voyage: voyage,
                                                state: state
                                              })
                                        })
                                  })
                            })),
                    fallback: Caml_option.some(JsxRuntime.jsx(Spinner$Thick.make, {}))
                  }),
              brandFamily: Caml_option.some(brandFamily),
              voyage: Caml_option.some(voyage)
            });
}

var make = DeckMapRoute;

exports.Query = Query;
exports.make = make;
/* use Not a pure module */
