// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var Spinner$Thick = require("../components/common/atoms/Spinner.bs.js");
var TeamPage$Thick = require("../pages/TeamPage.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var DashboardLayout$Thick = require("../layouts/DashboardLayout.bs.js");
var OrgProtectedRoute$Thick = require("../components/wrappers/OrgProtectedRoute.bs.js");
var BrandPermProtected$Thick = require("../components/wrappers/BrandPermProtected.bs.js");
var BrandProtectedRoute$Thick = require("../components/wrappers/BrandProtectedRoute.bs.js");
var TeamRouteQuery_graphql$Thick = require("../__generated__/TeamRouteQuery_graphql.bs.js");

var convertVariables = TeamRouteQuery_graphql$Thick.Internal.convertVariables;

var convertResponse = TeamRouteQuery_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = TeamRouteQuery_graphql$Thick.Internal.convertWrapRawResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, TeamRouteQuery_graphql$Thick.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, TeamRouteQuery_graphql$Thick.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(TeamRouteQuery_graphql$Thick.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(TeamRouteQuery_graphql$Thick.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(TeamRouteQuery_graphql$Thick.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(TeamRouteQuery_graphql$Thick.node, convertVariables);

var Query = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function TeamRoute(props) {
  var brandFamily = props.brandFamily;
  var data = use({
        brandFamily: brandFamily
      }, undefined, undefined, undefined);
  var fragmentRefs = data.fragmentRefs;
  return JsxRuntime.jsx(DashboardLayout$Thick.make, {
              fragmentRefs: fragmentRefs,
              children: JsxRuntime.jsx(React.Suspense, {
                    children: Caml_option.some(JsxRuntime.jsx(OrgProtectedRoute$Thick.make, {
                              fragmentRefs: fragmentRefs,
                              children: JsxRuntime.jsx(BrandProtectedRoute$Thick.make, {
                                    fragmentRefs: fragmentRefs,
                                    brandFamily: brandFamily,
                                    children: JsxRuntime.jsx(BrandPermProtected$Thick.make, {
                                          fragmentRefs: fragmentRefs,
                                          neededPerm: "VIEW_ALL_USERS",
                                          children: JsxRuntime.jsx(TeamPage$Thick.make, {
                                                fragmentRefs: fragmentRefs,
                                                brandFamily: brandFamily
                                              }),
                                          isRoute: true,
                                          redirect: "/" + CS_Slugs$Util.BrandFamilySlug.toString(brandFamily)
                                        })
                                  })
                            })),
                    fallback: Caml_option.some(JsxRuntime.jsx(Spinner$Thick.make, {}))
                  }),
              brandFamily: Caml_option.some(brandFamily)
            });
}

var make = TeamRoute;

exports.Query = Query;
exports.make = make;
/* use Not a pure module */
