// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");

var Types = {};

var variablesConverter = {"mergeAccountsInput":{"_sourceAccountIds":{"ca":"Util.CustomScalars.AccountId.Exn"},"_destAccountId":{"c":"Util.CustomScalars.AccountId.Exn"},"_brandFamilySlug":{"c":"Util.CustomScalars.BrandFamilySlug.Exn"}},"__root":{"input":{"r":"mergeAccountsInput"}}};

var variablesConverterMap = {
  "Util.CustomScalars.BrandFamilySlug.Exn": CS_Slugs$Util.BrandFamilySlug.Exn.serialize,
  "Util.CustomScalars.AccountId.Exn": CS_NonemptyStrings$Util.AccountId.Exn.serialize
};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, variablesConverterMap, undefined);
}

var wrapResponseConverter = {"__root":{"mergeAccounts_results_ctReparentedConnectedAccts":{"b":""},"mergeAccounts_results_ctPayment":{"b":""},"mergeAccounts_results_ctPaxVersion":{"b":""},"mergeAccounts_results_ctPaxHistory":{"b":""},"mergeAccounts_results_ctDeletedUsers":{"b":""},"mergeAccounts_results_ctCabinHoldsCleared":{"b":""},"mergeAccounts_results_ctCabinHoldLogs":{"b":""},"mergeAccounts_results_ctBookingVersions":{"b":""},"mergeAccounts_results_ctBookingVersionPax":{"b":""},"mergeAccounts_results_ctBookingSessionsDeleted":{"b":""},"mergeAccounts_results_ctBookingSessionExistingPax":{"b":""}}};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, undefined, null);
}

var responseConverter = {"__root":{"mergeAccounts_results_ctReparentedConnectedAccts":{"b":""},"mergeAccounts_results_ctPayment":{"b":""},"mergeAccounts_results_ctPaxVersion":{"b":""},"mergeAccounts_results_ctPaxHistory":{"b":""},"mergeAccounts_results_ctDeletedUsers":{"b":""},"mergeAccounts_results_ctCabinHoldsCleared":{"b":""},"mergeAccounts_results_ctCabinHoldLogs":{"b":""},"mergeAccounts_results_ctBookingVersions":{"b":""},"mergeAccounts_results_ctBookingVersionPax":{"b":""},"mergeAccounts_results_ctBookingSessionsDeleted":{"b":""},"mergeAccounts_results_ctBookingSessionExistingPax":{"b":""}}};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, undefined, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: variablesConverterMap,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: undefined,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: undefined,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse
};

function accountMergeError_decode($$enum) {
  if ($$enum === "UNCLAIMED_ACCOUNT_WOULD_BECOME_CUSTODIAN" || $$enum === "NON_ACTIVE_ACCOUNT" || $$enum === "INVALID_ACCOUNT_ID" || $$enum === "CONFLICTING_PAX_HISTORY" || $$enum === "CONFLICTING_BOOKING_VERSION_PAX" || $$enum === "CONFLICTING_VOYAGE_PAX" || $$enum === "NO_DEST_ACCOUNT" || $$enum === "NO_SOURCE_ACCOUNTS" || $$enum === "UNCLAIMED_ACCOUNT_WOULD_BECOME_BILLING_CONTACT") {
    return $$enum;
  }
  
}

function accountMergeError_fromString(str) {
  return accountMergeError_decode(str);
}

function accountMergeWarning_decode($$enum) {
  if ($$enum === "EXCEEDS_VOYAGE_BILLING_CONTACT_LIMIT" || $$enum === "UNCLAIMED_ACCOUNT_WOULD_BECOME_PAYER") {
    return $$enum;
  }
  
}

function accountMergeWarning_fromString(str) {
  return accountMergeWarning_decode(str);
}

var Utils = {
  accountMergeError_decode: accountMergeError_decode,
  accountMergeError_fromString: accountMergeError_fromString,
  accountMergeWarning_decode: accountMergeWarning_decode,
  accountMergeWarning_fromString: accountMergeWarning_fromString
};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "MergeAccountsPayload",
    "kind": "LinkedField",
    "name": "mergeAccounts",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MergeAccountsRecord",
        "kind": "LinkedField",
        "name": "results",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "success",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "errors",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "warnings",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ctPaxHistory",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ctBookingVersions",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ctPaxVersion",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ctBookingVersionPax",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ctPayment",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ctCabinHoldsCleared",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ctCabinHoldLogs",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ctBookingSessionsDeleted",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ctBookingSessionExistingPax",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ctReparentedConnectedAccts",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ctDeletedUsers",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MergeAccounts_MergeAccountsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MergeAccounts_MergeAccountsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "70b950c43e24e09b2e4ef348dcace587",
    "id": null,
    "metadata": {},
    "name": "MergeAccounts_MergeAccountsMutation",
    "operationKind": "mutation",
    "text": "mutation MergeAccounts_MergeAccountsMutation(\n  $input: MergeAccountsInput!\n) {\n  mergeAccounts(input: $input) {\n    results {\n      success\n      errors\n      warnings\n      ctPaxHistory\n      ctBookingVersions\n      ctPaxVersion\n      ctBookingVersionPax\n      ctPayment\n      ctCabinHoldsCleared\n      ctCabinHoldLogs\n      ctBookingSessionsDeleted\n      ctBookingSessionExistingPax\n      ctReparentedConnectedAccts\n      ctDeletedUsers\n    }\n  }\n}\n"
  }
};
})());

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
