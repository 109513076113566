// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


function getBackgroundColor(color) {
  switch (color) {
    case "Neutral" :
        return "#F0F3F5";
    case "Warning" :
        return "#FFC82C";
    case "Warning2" :
        return "#FFF9EB";
    case "Danger" :
        return "#DE4F48";
    case "Danger2" :
        return "#FBF2F2";
    case "Success" :
        return "#E7FDF1";
    case "Clear" :
        return "transparent";
    case "Admin" :
        return "#3498db";
    
  }
}

function getColorNoSuccess(color) {
  switch (color) {
    case "Warning2" :
        return "#916A08";
    case "Danger" :
        return "#FFFFFF";
    case "Danger2" :
        return "#BF1414";
    default:
      return "#000000";
  }
}

function getColorWithSuccess(color) {
  switch (color) {
    case "Warning2" :
        return "#916A08";
    case "Danger" :
        return "#FFFFFF";
    case "Danger2" :
        return "#BF1414";
    case "Success" :
        return "#037E3A";
    case "Admin" :
        return "white";
    default:
      return "#000000";
  }
}

function getBorderColor(color) {
  switch (color) {
    case "Neutral" :
        return "#93A5B1";
    case "Warning2" :
        return "#DBA313";
    case "Clear" :
        return "transparent";
    default:
      return getColorNoSuccess(color);
  }
}

var NotificationColor = {
  getBackgroundColor: getBackgroundColor,
  getColorNoSuccess: getColorNoSuccess,
  getColorWithSuccess: getColorWithSuccess,
  getBorderColor: getBorderColor
};

exports.NotificationColor = NotificationColor;
/* No side effect */
