// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Stack = require("@mui/joy/Stack").default;
var JsxRuntime = require("react/jsx-runtime");

function SelectSearchInput(props) {
  var actionButton = props.actionButton;
  var search = props.search;
  var clearOptions = props.clearOptions;
  var setLoading = props.setLoading;
  var getOptionFromKey = props.getOptionFromKey;
  var getOptionLabel = props.getOptionLabel;
  var set = props.set;
  var value = props.value;
  var match = React.useState(function () {
        return false;
      });
  var setListboxOpen = match[1];
  return JsxRuntime.jsxs(Stack, {
              direction: "row",
              spacing: 1,
              children: [
                JsxRuntime.jsx(Joy$Util.JoyAuto.Single.make, {
                      options: props.options,
                      disabled: props.disabled,
                      getOptionLabel: getOptionLabel,
                      loading: props.loading,
                      onChange: (function (param, val) {
                          var match = Belt_Option.map(val, getOptionFromKey);
                          if (match === undefined) {
                            return ;
                          }
                          var v = Caml_option.valFromOption(match);
                          if (v !== undefined) {
                            set(function (param) {
                                  return v;
                                });
                            setListboxOpen(function (param) {
                                  return false;
                                });
                            return ;
                          }
                          
                        }),
                      onClose: (function () {
                          setListboxOpen(function (param) {
                                return false;
                              });
                        }),
                      onInputChange: (function (e, v, r) {
                          var isTypeEvent = (e == null) ? false : e.nativeEvent.type === "input";
                          var isClearEvent = r === "clear";
                          if (!isClearEvent) {
                            if (!isTypeEvent) {
                              return ;
                            }
                            if (v !== "") {
                              if (getOptionLabel(value) !== v) {
                                setListboxOpen(function (param) {
                                      return false;
                                    });
                                search(v);
                                setListboxOpen(function (param) {
                                      return true;
                                    });
                                return ;
                              } else {
                                return ;
                              }
                            }
                            
                          }
                          clearOptions();
                          setListboxOpen(function (param) {
                                return false;
                              });
                          setLoading(function (param) {
                                return false;
                              });
                        }),
                      onOpen: (function () {
                          setListboxOpen(function (param) {
                                return true;
                              });
                        }),
                      open: match[0],
                      placeholder: props.placeholder,
                      slotProps: {
                        listbox: {
                          sx: {
                            zIndex: "modal"
                          }
                        }
                      },
                      sx: {
                        width: Belt_Option.getWithDefault(props.width, "auto")
                      },
                      value: value
                    }),
                actionButton !== undefined ? Caml_option.valFromOption(actionButton) : null
              ]
            });
}

var make = SelectSearchInput;

exports.make = make;
/* react Not a pure module */
