// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thick = require("../../../utils/Common.bs.js");
var Dialog$Thick = require("../../common/molecules/Dialog.bs.js");
var Stack = require("@mui/joy/Stack").default;
var RelayEnv$Thick = require("../../../RelayEnv.bs.js");
var Button = require("@mui/joy/Button").default;
var $$Option = require("@mui/joy/Option").default;
var CS_Decimal$Util = require("util/src/CustomScalars/CS_Decimal.bs.js");
var Divider = require("@mui/joy/Divider").default;
var DraftUtils$Thick = require("./DraftUtils.bs.js");
var BillPayInfo$Thick = require("../billing-payments/BillPayInfo.bs.js");
var BookingDiff$Thick = require("../../../utils/BookingDiff.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var FormLabel = require("@mui/joy/FormLabel").default;
var Belt_HashMapString = require("rescript/lib/js/belt_HashMapString.js");
var PreviewModal$Thick = require("./PreviewModal.bs.js");
var Typography = require("@mui/joy/Typography").default;
var CurrencyInput$Thick = require("../../common/molecules/CurrencyInput.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var FormControl = require("@mui/joy/FormControl").default;
var PreviewChanges$Thick = require("./PreviewChanges.bs.js");
var DialogActions = require("@mui/joy/DialogActions").default;
var DialogContent = require("@mui/joy/DialogContent").default;
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var BDRefundModalQuery_graphql$Thick = require("../../../__generated__/BDRefundModalQuery_graphql.bs.js");
var BDRefundModal_RecordManualRefundMutation_graphql$Thick = require("../../../__generated__/BDRefundModal_RecordManualRefundMutation_graphql.bs.js");

var convertVariables = BDRefundModalQuery_graphql$Thick.Internal.convertVariables;

var convertResponse = BDRefundModalQuery_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = BDRefundModalQuery_graphql$Thick.Internal.convertWrapRawResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, BDRefundModalQuery_graphql$Thick.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, BDRefundModalQuery_graphql$Thick.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(BDRefundModalQuery_graphql$Thick.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(BDRefundModalQuery_graphql$Thick.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(BDRefundModalQuery_graphql$Thick.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(BDRefundModalQuery_graphql$Thick.node, convertVariables);

var Query_accountStatus_decode = BDRefundModalQuery_graphql$Thick.Utils.accountStatus_decode;

var Query_accountStatus_fromString = BDRefundModalQuery_graphql$Thick.Utils.accountStatus_fromString;

var Query_billingKind_decode = BDRefundModalQuery_graphql$Thick.Utils.billingKind_decode;

var Query_billingKind_fromString = BDRefundModalQuery_graphql$Thick.Utils.billingKind_fromString;

var Query_bookingSessionPassengerType_decode = BDRefundModalQuery_graphql$Thick.Utils.bookingSessionPassengerType_decode;

var Query_bookingSessionPassengerType_fromString = BDRefundModalQuery_graphql$Thick.Utils.bookingSessionPassengerType_fromString;

var Query_brandfamRole_decode = BDRefundModalQuery_graphql$Thick.Utils.brandfamRole_decode;

var Query_brandfamRole_fromString = BDRefundModalQuery_graphql$Thick.Utils.brandfamRole_fromString;

var Query_custSignonStatus_decode = BDRefundModalQuery_graphql$Thick.Utils.custSignonStatus_decode;

var Query_custSignonStatus_fromString = BDRefundModalQuery_graphql$Thick.Utils.custSignonStatus_fromString;

var Query_docGender_decode = BDRefundModalQuery_graphql$Thick.Utils.docGender_decode;

var Query_docGender_fromString = BDRefundModalQuery_graphql$Thick.Utils.docGender_fromString;

var Query_elevatorProximity_decode = BDRefundModalQuery_graphql$Thick.Utils.elevatorProximity_decode;

var Query_elevatorProximity_fromString = BDRefundModalQuery_graphql$Thick.Utils.elevatorProximity_fromString;

var Query_invitationType_decode = BDRefundModalQuery_graphql$Thick.Utils.invitationType_decode;

var Query_invitationType_fromString = BDRefundModalQuery_graphql$Thick.Utils.invitationType_fromString;

var Query_lifecycleStatus_decode = BDRefundModalQuery_graphql$Thick.Utils.lifecycleStatus_decode;

var Query_lifecycleStatus_fromString = BDRefundModalQuery_graphql$Thick.Utils.lifecycleStatus_fromString;

var Query_longitudinality_decode = BDRefundModalQuery_graphql$Thick.Utils.longitudinality_decode;

var Query_longitudinality_fromString = BDRefundModalQuery_graphql$Thick.Utils.longitudinality_fromString;

var Query_manifestPaxReconciliationStatus_decode = BDRefundModalQuery_graphql$Thick.Utils.manifestPaxReconciliationStatus_decode;

var Query_manifestPaxReconciliationStatus_fromString = BDRefundModalQuery_graphql$Thick.Utils.manifestPaxReconciliationStatus_fromString;

var Query_paxDataStatus_decode = BDRefundModalQuery_graphql$Thick.Utils.paxDataStatus_decode;

var Query_paxDataStatus_fromString = BDRefundModalQuery_graphql$Thick.Utils.paxDataStatus_fromString;

var Query_pmtDirection_decode = BDRefundModalQuery_graphql$Thick.Utils.pmtDirection_decode;

var Query_pmtDirection_fromString = BDRefundModalQuery_graphql$Thick.Utils.pmtDirection_fromString;

var Query_pmtLifecycleStatus_decode = BDRefundModalQuery_graphql$Thick.Utils.pmtLifecycleStatus_decode;

var Query_pmtLifecycleStatus_fromString = BDRefundModalQuery_graphql$Thick.Utils.pmtLifecycleStatus_fromString;

var Query_pmtMethod_decode = BDRefundModalQuery_graphql$Thick.Utils.pmtMethod_decode;

var Query_pmtMethod_fromString = BDRefundModalQuery_graphql$Thick.Utils.pmtMethod_fromString;

var Query_pmtProcessor_decode = BDRefundModalQuery_graphql$Thick.Utils.pmtProcessor_decode;

var Query_pmtProcessor_fromString = BDRefundModalQuery_graphql$Thick.Utils.pmtProcessor_fromString;

var Query_refundability_decode = BDRefundModalQuery_graphql$Thick.Utils.refundability_decode;

var Query_refundability_fromString = BDRefundModalQuery_graphql$Thick.Utils.refundability_fromString;

var Query_signonStatus_decode = BDRefundModalQuery_graphql$Thick.Utils.signonStatus_decode;

var Query_signonStatus_fromString = BDRefundModalQuery_graphql$Thick.Utils.signonStatus_fromString;

var Query_subtotalCategory_decode = BDRefundModalQuery_graphql$Thick.Utils.subtotalCategory_decode;

var Query_subtotalCategory_fromString = BDRefundModalQuery_graphql$Thick.Utils.subtotalCategory_fromString;

var Query_versionProvenance_decode = BDRefundModalQuery_graphql$Thick.Utils.versionProvenance_decode;

var Query_versionProvenance_fromString = BDRefundModalQuery_graphql$Thick.Utils.versionProvenance_fromString;

var Query = {
  accountStatus_decode: Query_accountStatus_decode,
  accountStatus_fromString: Query_accountStatus_fromString,
  billingKind_decode: Query_billingKind_decode,
  billingKind_fromString: Query_billingKind_fromString,
  bookingSessionPassengerType_decode: Query_bookingSessionPassengerType_decode,
  bookingSessionPassengerType_fromString: Query_bookingSessionPassengerType_fromString,
  brandfamRole_decode: Query_brandfamRole_decode,
  brandfamRole_fromString: Query_brandfamRole_fromString,
  custSignonStatus_decode: Query_custSignonStatus_decode,
  custSignonStatus_fromString: Query_custSignonStatus_fromString,
  docGender_decode: Query_docGender_decode,
  docGender_fromString: Query_docGender_fromString,
  elevatorProximity_decode: Query_elevatorProximity_decode,
  elevatorProximity_fromString: Query_elevatorProximity_fromString,
  invitationType_decode: Query_invitationType_decode,
  invitationType_fromString: Query_invitationType_fromString,
  lifecycleStatus_decode: Query_lifecycleStatus_decode,
  lifecycleStatus_fromString: Query_lifecycleStatus_fromString,
  longitudinality_decode: Query_longitudinality_decode,
  longitudinality_fromString: Query_longitudinality_fromString,
  manifestPaxReconciliationStatus_decode: Query_manifestPaxReconciliationStatus_decode,
  manifestPaxReconciliationStatus_fromString: Query_manifestPaxReconciliationStatus_fromString,
  paxDataStatus_decode: Query_paxDataStatus_decode,
  paxDataStatus_fromString: Query_paxDataStatus_fromString,
  pmtDirection_decode: Query_pmtDirection_decode,
  pmtDirection_fromString: Query_pmtDirection_fromString,
  pmtLifecycleStatus_decode: Query_pmtLifecycleStatus_decode,
  pmtLifecycleStatus_fromString: Query_pmtLifecycleStatus_fromString,
  pmtMethod_decode: Query_pmtMethod_decode,
  pmtMethod_fromString: Query_pmtMethod_fromString,
  pmtProcessor_decode: Query_pmtProcessor_decode,
  pmtProcessor_fromString: Query_pmtProcessor_fromString,
  refundability_decode: Query_refundability_decode,
  refundability_fromString: Query_refundability_fromString,
  signonStatus_decode: Query_signonStatus_decode,
  signonStatus_fromString: Query_signonStatus_fromString,
  subtotalCategory_decode: Query_subtotalCategory_decode,
  subtotalCategory_fromString: Query_subtotalCategory_fromString,
  versionProvenance_decode: Query_versionProvenance_decode,
  versionProvenance_fromString: Query_versionProvenance_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

var convertVariables$1 = BDRefundModal_RecordManualRefundMutation_graphql$Thick.Internal.convertVariables;

var convertResponse$1 = BDRefundModal_RecordManualRefundMutation_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse$1 = BDRefundModal_RecordManualRefundMutation_graphql$Thick.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables$1, BDRefundModal_RecordManualRefundMutation_graphql$Thick.node, convertResponse$1, convertWrapRawResponse$1);

var use$1 = RescriptRelay_Mutation.useMutation(convertVariables$1, BDRefundModal_RecordManualRefundMutation_graphql$Thick.node, convertResponse$1, convertWrapRawResponse$1);

var RecordManualRefundMutation_bookingDiffError_decode = BDRefundModal_RecordManualRefundMutation_graphql$Thick.Utils.bookingDiffError_decode;

var RecordManualRefundMutation_bookingDiffError_fromString = BDRefundModal_RecordManualRefundMutation_graphql$Thick.Utils.bookingDiffError_fromString;

var RecordManualRefundMutation_pmtLifecycleStatus_decode = BDRefundModal_RecordManualRefundMutation_graphql$Thick.Utils.pmtLifecycleStatus_decode;

var RecordManualRefundMutation_pmtLifecycleStatus_fromString = BDRefundModal_RecordManualRefundMutation_graphql$Thick.Utils.pmtLifecycleStatus_fromString;

var RecordManualRefundMutation_pmtMethod_decode = BDRefundModal_RecordManualRefundMutation_graphql$Thick.Utils.pmtMethod_decode;

var RecordManualRefundMutation_pmtMethod_fromString = BDRefundModal_RecordManualRefundMutation_graphql$Thick.Utils.pmtMethod_fromString;

var RecordManualRefundMutation_pmtProcessor_decode = BDRefundModal_RecordManualRefundMutation_graphql$Thick.Utils.pmtProcessor_decode;

var RecordManualRefundMutation_pmtProcessor_fromString = BDRefundModal_RecordManualRefundMutation_graphql$Thick.Utils.pmtProcessor_fromString;

var RecordManualRefundMutation = {
  bookingDiffError_decode: RecordManualRefundMutation_bookingDiffError_decode,
  bookingDiffError_fromString: RecordManualRefundMutation_bookingDiffError_fromString,
  pmtLifecycleStatus_decode: RecordManualRefundMutation_pmtLifecycleStatus_decode,
  pmtLifecycleStatus_fromString: RecordManualRefundMutation_pmtLifecycleStatus_fromString,
  pmtMethod_decode: RecordManualRefundMutation_pmtMethod_decode,
  pmtMethod_fromString: RecordManualRefundMutation_pmtMethod_fromString,
  pmtProcessor_decode: RecordManualRefundMutation_pmtProcessor_decode,
  pmtProcessor_fromString: RecordManualRefundMutation_pmtProcessor_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables$1,
  convertResponse: convertResponse$1,
  convertWrapRawResponse: convertWrapRawResponse$1,
  commitMutation: commitMutation,
  use: use$1
};

function BDRefundModal(props) {
  var refetchBilling = props.refetchBilling;
  var isCanceled = props.isCanceled;
  var generatePreviewFromDiff = props.generatePreviewFromDiff;
  var brandFamily = props.brandFamily;
  var clearPrefill = props.clearPrefill;
  var prefill = props.prefill;
  var currentUserEmail = props.currentUserEmail;
  var onClose = props.onClose;
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var dummyDiff = BookingDiff$Thick.parse(url.search);
  var isPrefilled = Belt_Option.isSome(prefill);
  var amtMaximum = prefill !== undefined ? Caml_option.some(prefill.refundableAmt) : undefined;
  var feeMaximum = prefill !== undefined ? Caml_option.some(prefill.refundableFeeAmt) : undefined;
  var initialState = BillPayInfo$Thick.Utils.paymentLineToManPayInterm(prefill, currentUserEmail);
  var match = React.useState(function () {
        return initialState;
      });
  var setUpdate = match[1];
  var update = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setSearchLoading = match$1[1];
  var match$2 = React.useState(function () {
        if (prefill !== undefined) {
          return [{
                    accountId: prefill.payerAcct,
                    fullName: prefill.payer,
                    loyaltyNumString: "",
                    emailString: ""
                  }];
        } else {
          return [];
        }
      });
  var setSelectOptions = match$2[1];
  var selectOptions = match$2[0];
  React.useEffect((function () {
          setUpdate(function (param) {
                return BillPayInfo$Thick.Utils.paymentLineToManPayInterm(prefill, currentUserEmail);
              });
          if (prefill !== undefined) {
            setSelectOptions(function (param) {
                  return [{
                            accountId: prefill.payerAcct,
                            fullName: prefill.payer,
                            loyaltyNumString: "",
                            emailString: ""
                          }];
                });
          } else {
            setSelectOptions(function (param) {
                  return [];
                });
          }
        }), [isPrefilled]);
  var selectMap = Belt_HashMapString.fromArray(Belt_Array.map(selectOptions, (function (val) {
              return [
                      CS_NonemptyStrings$Util.AccountId.toString(val.accountId),
                      val.fullName
                    ];
            })));
  var match$3 = React.useState(function () {
        return false;
      });
  var setAutocompleteOpen = match$3[1];
  var url$1 = RescriptReactRouter.useUrl(undefined, undefined);
  var diffStruct = BookingDiff$Thick.parse(url$1.search);
  var match$4 = PreviewChanges$Thick.useQuery(generatePreviewFromDiff, diffStruct, onClose);
  var clearPreviewFields = match$4.clearPreviewFields;
  var setApplyDiff = match$4.setApplyDiff;
  var setError = match$4.setError;
  var error = match$4.error;
  var generatePreview = match$4.generatePreview;
  var createFilter = function (s) {
    return {
            or: [
              {
                fullName: {
                  includesInsensitive: s
                }
              },
              {
                emailString: {
                  includesInsensitive: s
                }
              },
              {
                loyaltyNumString: {
                  includesInsensitive: s
                }
              }
            ]
          };
  };
  var clearAll = function () {
    clearPreviewFields();
    setAutocompleteOpen(function (param) {
          return false;
        });
    clearPrefill();
    setError(function (param) {
          
        });
    setUpdate(function (param) {
          return BillPayInfo$Thick.Utils.paymentLineToManPayInterm(undefined, currentUserEmail);
        });
  };
  var onClose$1 = function () {
    setError(function (param) {
          
        });
    onClose();
  };
  var match$5 = use$1();
  var mut = match$5[0];
  var match$6 = generatePreviewFromDiff(dummyDiff);
  var _voyageSlug = match$6.voyageSlug;
  var _bookingVersionNumber = match$6.bookingVersionNumber;
  var _bookingId = match$6.bookingId;
  var onSubmit = function (e) {
    e.preventDefault();
    var manualRefund = BillPayInfo$Thick.Utils.manPayIntermToDiffRec(update);
    if (manualRefund.TAG === "Ok") {
      var manualRefund$1 = manualRefund._0;
      if (isCanceled) {
        mut({
              input: {
                _brandFamilySlug: brandFamily,
                _voyageSlug: _voyageSlug,
                _bookingId: _bookingId,
                _bookingVersionNumber: _bookingVersionNumber,
                _refund: DraftUtils$Thick.canceledBookingManualRefundInput(manualRefund$1)
              }
            }, undefined, undefined, undefined, (function (res, err) {
                if (err !== undefined) {
                  console.error(err);
                  var errorString = Belt_Array.map(err, (function (e) {
                            return e.message;
                          })).join(", ");
                  setError(function (param) {
                        return errorString;
                      });
                  return ;
                }
                var match = res.recordManualRefund;
                if (match === undefined) {
                  return ;
                }
                var match$1 = match.recordManualRefundResult;
                if (match$1 === undefined) {
                  return ;
                }
                var err$1 = match$1.nullableErr;
                var match$2 = match$1.success;
                if (match$2 !== undefined) {
                  if (match$2) {
                    clearAll();
                    onClose$1();
                    refetchBilling();
                  } else if (err$1 !== undefined) {
                    console.error(err$1);
                    setError(function (param) {
                          return err$1;
                        });
                  } else {
                    setError(function (param) {
                          return "Failed to record refund.";
                        });
                  }
                  return ;
                }
                
              }), (function (err) {
                console.error(err);
                setError(function (param) {
                      return err.message;
                    });
              }), undefined);
        return ;
      }
      var applyDiff = BookingDiff$Thick.Alter.recordManualRefunds(diffStruct, manualRefund$1);
      setApplyDiff(function (param) {
            return applyDiff;
          });
      generatePreview(applyDiff);
      return ;
    }
    var str = manualRefund._0;
    setError(function (param) {
          return str;
        });
  };
  var search = function (val) {
    setSearchLoading(function (param) {
          return true;
        });
    setAutocompleteOpen(function (param) {
          return false;
        });
    setError(function (param) {
          
        });
    $$fetch(RelayEnv$Thick.environment, {
          brandFamily: brandFamily,
          filter: createFilter(val)
        }, (function (res) {
            if (res.TAG === "Ok") {
              var match = res._0.bfcustUsers;
              if (match !== undefined) {
                var results = Belt_Array.keepMap(match.nodes, (function (node) {
                        var accountId = node.accountId;
                        var fullName = node.fullName;
                        var loyaltyNumString = node.loyaltyNumString;
                        var emailString = node.emailString;
                        return {
                                accountId: accountId,
                                fullName: fullName,
                                loyaltyNumString: loyaltyNumString,
                                emailString: emailString
                              };
                      }));
                setSelectOptions(function (param) {
                      return results;
                    });
                setAutocompleteOpen(function (param) {
                      return true;
                    });
                setSearchLoading(function (param) {
                      return false;
                    });
                return ;
              }
              setError(function (param) {
                    return "No users found.";
                  });
              setSearchLoading(function (param) {
                    return false;
                  });
              return ;
            }
            console.error(res._0);
            setError(function (param) {
                  return "Something went wrong. Please try again.";
                });
            setSearchLoading(function (param) {
                  return false;
                });
          }), undefined, undefined);
  };
  var cancel = function () {
    clearAll();
    onClose$1();
  };
  var match$7 = Belt_Option.map(update.netRefundedAmount, (function (amt) {
          return CS_Decimal$Util.Decimal10_2.fromPrimitive(amt);
        }));
  var match$8 = Belt_Option.map(update.netRefundedBfFeeAmount, (function (amt) {
          return CS_Decimal$Util.Decimal10_2.fromPrimitive(amt);
        }));
  var match$9 = Belt_Option.flatMap(update.payee, (function (v) {
          return Belt_HashMapString.get(selectMap, CS_NonemptyStrings$Util.AccountId.toString(v));
        }));
  var previewModalDescription = match$7 !== undefined && match$7.TAG === "Ok" ? "You are about to record a manual refund of " + Common$Thick.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(match$7._0)) + Belt_Option.getWithDefault(Belt_Option.map(match$8, (function (f) {
                if (f.TAG === "Ok") {
                  return " with a refund fee of " + Common$Thick.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(f._0));
                } else {
                  return "";
                }
              })), "") + Belt_Option.getWithDefault(Belt_Option.map(match$9, (function (f) {
                return " to user " + f;
              })), "") + "." : "";
  var title = prefill !== undefined ? "Refund " + prefill.paymentMethod + " from " + prefill.date : "Log an Outgoing Payment";
  var match$10 = Belt_Option.map(update.processorTransactionId, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive);
  var tmp;
  tmp = match$10 !== undefined && match$10.TAG === "Ok" ? false : true;
  var tmp$1;
  if (isPrefilled) {
    tmp$1 = null;
  } else {
    var match$11 = Belt_Option.map(update.bfcustPmtMethodIdentifier, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive);
    var tmp$2;
    tmp$2 = match$11 !== undefined && match$11.TAG === "Ok" ? false : true;
    var match$12 = update.pmtMethod;
    var tmp$3;
    if (match$12 !== undefined) {
      switch (match$12) {
        case "CHECK" :
            tmp$3 = "Check Number";
            break;
        case "FCC" :
            tmp$3 = "FCC Identifier";
            break;
        default:
          tmp$3 = "Last 4 digits of account number";
      }
    } else {
      tmp$3 = "Payment Method Identifier";
    }
    tmp$1 = JsxRuntime.jsx(Joy$Util.Grid.make, {
          children: Caml_option.some(JsxRuntime.jsxs(FormControl, {
                    error: tmp$2,
                    children: [
                      JsxRuntime.jsx(FormLabel, {
                            children: Caml_option.some(tmp$3)
                          }),
                      JsxRuntime.jsx(Joy$Util.Input.make, {
                            type_: "text",
                            disabled: isPrefilled,
                            value: Belt_Option.getWithDefault(update.bfcustPmtMethodIdentifier, ""),
                            onChange: (function (e) {
                                var val = e.currentTarget.value;
                                if (val === "") {
                                  return setUpdate(function (param) {
                                              return {
                                                      payee: update.payee,
                                                      teamUserInitiator: update.teamUserInitiator,
                                                      processor: update.processor,
                                                      netRefundedAmount: update.netRefundedAmount,
                                                      netRefundedBfFeeAmount: update.netRefundedBfFeeAmount,
                                                      referencePmtPublicId: update.referencePmtPublicId,
                                                      lifecycleStatus: update.lifecycleStatus,
                                                      processorTransactionId: update.processorTransactionId,
                                                      pmtMethod: update.pmtMethod,
                                                      bfcustPmtMethodIdentifier: undefined,
                                                      bfcustPmtSubtype: update.bfcustPmtSubtype,
                                                      bfcustPmtNote: update.bfcustPmtNote
                                                    };
                                            });
                                } else {
                                  return setUpdate(function (param) {
                                              return {
                                                      payee: update.payee,
                                                      teamUserInitiator: update.teamUserInitiator,
                                                      processor: update.processor,
                                                      netRefundedAmount: update.netRefundedAmount,
                                                      netRefundedBfFeeAmount: update.netRefundedBfFeeAmount,
                                                      referencePmtPublicId: update.referencePmtPublicId,
                                                      lifecycleStatus: update.lifecycleStatus,
                                                      processorTransactionId: update.processorTransactionId,
                                                      pmtMethod: update.pmtMethod,
                                                      bfcustPmtMethodIdentifier: val,
                                                      bfcustPmtSubtype: update.bfcustPmtSubtype,
                                                      bfcustPmtNote: update.bfcustPmtNote
                                                    };
                                            });
                                }
                              })
                          })
                    ]
                  })),
          xs: 6
        });
  }
  var match$13 = update.pmtMethod;
  var tmp$4;
  tmp$4 = isPrefilled || match$13 !== "CREDIT_CARD" ? null : JsxRuntime.jsx(Joy$Util.Grid.make, {
          children: Caml_option.some(JsxRuntime.jsxs(FormControl, {
                    children: [
                      JsxRuntime.jsx(FormLabel, {
                            children: "Card Issuer (e.g. Visa)"
                          }),
                      JsxRuntime.jsx(Joy$Util.Input.make, {
                            type_: "text",
                            value: Belt_Option.getWithDefault(update.bfcustPmtSubtype, ""),
                            onChange: (function (e) {
                                var val = e.currentTarget.value;
                                if (val === "") {
                                  return setUpdate(function (param) {
                                              return {
                                                      payee: update.payee,
                                                      teamUserInitiator: update.teamUserInitiator,
                                                      processor: update.processor,
                                                      netRefundedAmount: update.netRefundedAmount,
                                                      netRefundedBfFeeAmount: update.netRefundedBfFeeAmount,
                                                      referencePmtPublicId: update.referencePmtPublicId,
                                                      lifecycleStatus: update.lifecycleStatus,
                                                      processorTransactionId: update.processorTransactionId,
                                                      pmtMethod: update.pmtMethod,
                                                      bfcustPmtMethodIdentifier: update.bfcustPmtMethodIdentifier,
                                                      bfcustPmtSubtype: undefined,
                                                      bfcustPmtNote: update.bfcustPmtNote
                                                    };
                                            });
                                } else {
                                  return setUpdate(function (param) {
                                              return {
                                                      payee: update.payee,
                                                      teamUserInitiator: update.teamUserInitiator,
                                                      processor: update.processor,
                                                      netRefundedAmount: update.netRefundedAmount,
                                                      netRefundedBfFeeAmount: update.netRefundedBfFeeAmount,
                                                      referencePmtPublicId: update.referencePmtPublicId,
                                                      lifecycleStatus: update.lifecycleStatus,
                                                      processorTransactionId: update.processorTransactionId,
                                                      pmtMethod: update.pmtMethod,
                                                      bfcustPmtMethodIdentifier: update.bfcustPmtMethodIdentifier,
                                                      bfcustPmtSubtype: val,
                                                      bfcustPmtNote: update.bfcustPmtNote
                                                    };
                                            });
                                }
                              })
                          })
                    ]
                  })),
          xs: 6
        });
  var match$14 = Belt_Option.map(update.bfcustPmtNote, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive);
  var tmp$5;
  tmp$5 = match$14 !== undefined && match$14.TAG === "Ok" ? false : true;
  var match$15 = update.pmtMethod;
  var tmp$6;
  tmp$6 = match$15 === "CHECK" ? "Memo" : "Note";
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs(Dialog$Thick.make, {
                      open_: props.open_,
                      onClose: onClose$1,
                      title: title,
                      children: [
                        JsxRuntime.jsxs(DialogContent, {
                              children: [
                                JsxRuntime.jsx(Typography, {
                                      level: "title-md",
                                      children: "Please enter all details below to record the refund."
                                    }),
                                JsxRuntime.jsxs(Stack, {
                                      direction: "column",
                                      spacing: 2,
                                      sx: {
                                        py: 1
                                      },
                                      children: [
                                        prefill !== undefined ? JsxRuntime.jsxs(FormControl, {
                                                children: [
                                                  JsxRuntime.jsx(FormLabel, {
                                                        children: "Payee"
                                                      }),
                                                  JsxRuntime.jsx(Joy$Util.Input.make, {
                                                        type_: "text",
                                                        disabled: true,
                                                        value: Belt_Option.getWithDefault(Belt_Option.flatMap(update.payee, (function (a) {
                                                                    return Belt_HashMapString.get(selectMap, CS_NonemptyStrings$Util.AccountId.toString(a));
                                                                  })), prefill.payer)
                                                      })
                                                ]
                                              }) : JsxRuntime.jsxs(FormControl, {
                                                error: Belt_Option.isNone(update.payee),
                                                children: [
                                                  JsxRuntime.jsx(FormLabel, {
                                                        children: "Payee"
                                                      }),
                                                  JsxRuntime.jsx(Joy$Util.JoyAuto.Single.make, {
                                                        options: selectOptions,
                                                        disabled: isPrefilled,
                                                        getOptionLabel: (function (option) {
                                                            var e = option.emailString;
                                                            return option.fullName + (
                                                                    e !== undefined ? " " + e : ""
                                                                  ) + " (" + option.loyaltyNumString + ")";
                                                          }),
                                                        isOptionEqualToValue: (function (option, value) {
                                                            return option.accountId === value.accountId;
                                                          }),
                                                        loading: match$1[0],
                                                        onChange: (function (param, value) {
                                                            if (value !== undefined) {
                                                              var accountId = value.accountId;
                                                              setUpdate(function (param) {
                                                                    return {
                                                                            payee: Caml_option.some(accountId),
                                                                            teamUserInitiator: update.teamUserInitiator,
                                                                            processor: update.processor,
                                                                            netRefundedAmount: update.netRefundedAmount,
                                                                            netRefundedBfFeeAmount: update.netRefundedBfFeeAmount,
                                                                            referencePmtPublicId: update.referencePmtPublicId,
                                                                            lifecycleStatus: update.lifecycleStatus,
                                                                            processorTransactionId: update.processorTransactionId,
                                                                            pmtMethod: update.pmtMethod,
                                                                            bfcustPmtMethodIdentifier: update.bfcustPmtMethodIdentifier,
                                                                            bfcustPmtSubtype: update.bfcustPmtSubtype,
                                                                            bfcustPmtNote: update.bfcustPmtNote
                                                                          };
                                                                  });
                                                              return ;
                                                            }
                                                            setUpdate(function (param) {
                                                                  return {
                                                                          payee: undefined,
                                                                          teamUserInitiator: update.teamUserInitiator,
                                                                          processor: update.processor,
                                                                          netRefundedAmount: update.netRefundedAmount,
                                                                          netRefundedBfFeeAmount: update.netRefundedBfFeeAmount,
                                                                          referencePmtPublicId: update.referencePmtPublicId,
                                                                          lifecycleStatus: update.lifecycleStatus,
                                                                          processorTransactionId: update.processorTransactionId,
                                                                          pmtMethod: update.pmtMethod,
                                                                          bfcustPmtMethodIdentifier: update.bfcustPmtMethodIdentifier,
                                                                          bfcustPmtSubtype: update.bfcustPmtSubtype,
                                                                          bfcustPmtNote: update.bfcustPmtNote
                                                                        };
                                                                });
                                                          }),
                                                        onClose: (function () {
                                                            setAutocompleteOpen(function (param) {
                                                                  return false;
                                                                });
                                                          }),
                                                        onInputChange: (function (param, v, param$1) {
                                                            if (v === "" && !isPrefilled) {
                                                              setSelectOptions(function (param) {
                                                                    return [];
                                                                  });
                                                              setAutocompleteOpen(function (param) {
                                                                    return false;
                                                                  });
                                                              setError(function (param) {
                                                                    
                                                                  });
                                                              setSearchLoading(function (param) {
                                                                    return false;
                                                                  });
                                                            } else {
                                                              search(v);
                                                            }
                                                          }),
                                                        onOpen: (function () {
                                                            setAutocompleteOpen(function (param) {
                                                                  return true;
                                                                });
                                                          }),
                                                        open: match$3[0],
                                                        placeholder: "Search for a payee"
                                                      })
                                                ]
                                              }),
                                        JsxRuntime.jsxs(Joy$Util.Grid.make, {
                                              children: [
                                                JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                      children: Caml_option.some(JsxRuntime.jsxs(FormControl, {
                                                                error: Belt_Option.isNone(update.pmtMethod),
                                                                children: [
                                                                  JsxRuntime.jsx(FormLabel, {
                                                                        children: "Payment method"
                                                                      }),
                                                                  JsxRuntime.jsx(Joy$Util.JoySelect.make, {
                                                                        children: Caml_option.some(Belt_Array.mapWithIndex(BillPayInfo$Thick.Utils.pmtMethodOptions, (function (i, param) {
                                                                                    return JsxRuntime.jsx($$Option, {
                                                                                                value: param[0],
                                                                                                children: Caml_option.some(param[1])
                                                                                              }, String(i));
                                                                                  }))),
                                                                        disabled: isPrefilled,
                                                                        onChange: (function (param, v) {
                                                                            if (v === undefined) {
                                                                              return ;
                                                                            }
                                                                            var pmtMethod = Caml_option.valFromOption(v);
                                                                            if (pmtMethod === undefined) {
                                                                              return ;
                                                                            }
                                                                            var tmp;
                                                                            switch (pmtMethod) {
                                                                              case "CREDIT_CARD" :
                                                                                  tmp = "BRAINTREEPAYMENTS_COM";
                                                                                  break;
                                                                              case "CHECK" :
                                                                                  tmp = undefined;
                                                                                  break;
                                                                              default:
                                                                                tmp = "INCREASE_COM";
                                                                            }
                                                                            var newUpdate_payee = update.payee;
                                                                            var newUpdate_teamUserInitiator = update.teamUserInitiator;
                                                                            var newUpdate_netRefundedAmount = update.netRefundedAmount;
                                                                            var newUpdate_netRefundedBfFeeAmount = update.netRefundedBfFeeAmount;
                                                                            var newUpdate_referencePmtPublicId = update.referencePmtPublicId;
                                                                            var newUpdate_lifecycleStatus = update.lifecycleStatus;
                                                                            var newUpdate_processorTransactionId = update.processorTransactionId;
                                                                            var newUpdate_pmtMethod = pmtMethod;
                                                                            var newUpdate_bfcustPmtMethodIdentifier = update.bfcustPmtMethodIdentifier;
                                                                            var newUpdate_bfcustPmtSubtype = update.bfcustPmtSubtype;
                                                                            var newUpdate_bfcustPmtNote = update.bfcustPmtNote;
                                                                            var newUpdate = {
                                                                              payee: newUpdate_payee,
                                                                              teamUserInitiator: newUpdate_teamUserInitiator,
                                                                              processor: tmp,
                                                                              netRefundedAmount: newUpdate_netRefundedAmount,
                                                                              netRefundedBfFeeAmount: newUpdate_netRefundedBfFeeAmount,
                                                                              referencePmtPublicId: newUpdate_referencePmtPublicId,
                                                                              lifecycleStatus: newUpdate_lifecycleStatus,
                                                                              processorTransactionId: newUpdate_processorTransactionId,
                                                                              pmtMethod: newUpdate_pmtMethod,
                                                                              bfcustPmtMethodIdentifier: newUpdate_bfcustPmtMethodIdentifier,
                                                                              bfcustPmtSubtype: newUpdate_bfcustPmtSubtype,
                                                                              bfcustPmtNote: newUpdate_bfcustPmtNote
                                                                            };
                                                                            setUpdate(function (param) {
                                                                                  return newUpdate;
                                                                                });
                                                                          }),
                                                                        placeholder: "Select payment method",
                                                                        required: true,
                                                                        slotProps: {
                                                                          listbox: {
                                                                            sx: {
                                                                              zIndex: "modal"
                                                                            }
                                                                          }
                                                                        },
                                                                        value: update.pmtMethod
                                                                      })
                                                                ]
                                                              })),
                                                      xs: 6
                                                    }),
                                                JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                      children: Caml_option.some(JsxRuntime.jsx(CurrencyInput$Thick.make, {
                                                                label: "Refunded Amount",
                                                                error: (function (v) {
                                                                    if (Belt_Result.isError(CS_Decimal$Util.Decimal10_2.fromPrimitive(BillPayInfo$Thick.Utils.padIfNoDecimal(v)))) {
                                                                      return true;
                                                                    } else {
                                                                      return v === "0.00";
                                                                    }
                                                                  }),
                                                                onChange: (function (amt) {
                                                                    setUpdate(function (param) {
                                                                          return {
                                                                                  payee: update.payee,
                                                                                  teamUserInitiator: update.teamUserInitiator,
                                                                                  processor: update.processor,
                                                                                  netRefundedAmount: amt,
                                                                                  netRefundedBfFeeAmount: update.netRefundedBfFeeAmount,
                                                                                  referencePmtPublicId: update.referencePmtPublicId,
                                                                                  lifecycleStatus: update.lifecycleStatus,
                                                                                  processorTransactionId: update.processorTransactionId,
                                                                                  pmtMethod: update.pmtMethod,
                                                                                  bfcustPmtMethodIdentifier: update.bfcustPmtMethodIdentifier,
                                                                                  bfcustPmtSubtype: update.bfcustPmtSubtype,
                                                                                  bfcustPmtNote: update.bfcustPmtNote
                                                                                };
                                                                        });
                                                                  }),
                                                                value: Belt_Option.getWithDefault(update.netRefundedAmount, "0.00"),
                                                                maximum: amtMaximum
                                                              })),
                                                      xs: 6
                                                    }),
                                                JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                      children: Caml_option.some(JsxRuntime.jsx(CurrencyInput$Thick.make, {
                                                                label: "Refunded Fee Amount",
                                                                error: (function (v) {
                                                                    return Belt_Result.isError(CS_Decimal$Util.Decimal10_2.fromPrimitive(BillPayInfo$Thick.Utils.padIfNoDecimal(v)));
                                                                  }),
                                                                onChange: (function (amt) {
                                                                    setUpdate(function (param) {
                                                                          return {
                                                                                  payee: update.payee,
                                                                                  teamUserInitiator: update.teamUserInitiator,
                                                                                  processor: update.processor,
                                                                                  netRefundedAmount: update.netRefundedAmount,
                                                                                  netRefundedBfFeeAmount: amt,
                                                                                  referencePmtPublicId: update.referencePmtPublicId,
                                                                                  lifecycleStatus: update.lifecycleStatus,
                                                                                  processorTransactionId: update.processorTransactionId,
                                                                                  pmtMethod: update.pmtMethod,
                                                                                  bfcustPmtMethodIdentifier: update.bfcustPmtMethodIdentifier,
                                                                                  bfcustPmtSubtype: update.bfcustPmtSubtype,
                                                                                  bfcustPmtNote: update.bfcustPmtNote
                                                                                };
                                                                        });
                                                                  }),
                                                                value: Belt_Option.getWithDefault(update.netRefundedBfFeeAmount, "0.00"),
                                                                maximum: feeMaximum
                                                              })),
                                                      xs: 6
                                                    }),
                                                JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                      children: Caml_option.some(JsxRuntime.jsxs(FormControl, {
                                                                error: Belt_Option.isNone(update.lifecycleStatus),
                                                                children: [
                                                                  JsxRuntime.jsx(FormLabel, {
                                                                        children: "Payment Status"
                                                                      }),
                                                                  JsxRuntime.jsx(Joy$Util.JoySelect.make, {
                                                                        children: Caml_option.some(Belt_Array.mapWithIndex(BillPayInfo$Thick.Utils.lifecycleStatusOptions, (function (i, param) {
                                                                                    return JsxRuntime.jsx($$Option, {
                                                                                                value: param[0],
                                                                                                children: Caml_option.some(param[1])
                                                                                              }, String(i));
                                                                                  }))),
                                                                        disabled: isPrefilled,
                                                                        onChange: (function (param, v) {
                                                                            if (v === undefined) {
                                                                              return ;
                                                                            }
                                                                            var lifecycleStatus = Caml_option.valFromOption(v);
                                                                            if (lifecycleStatus !== undefined) {
                                                                              return setUpdate(function (param) {
                                                                                          return {
                                                                                                  payee: update.payee,
                                                                                                  teamUserInitiator: update.teamUserInitiator,
                                                                                                  processor: update.processor,
                                                                                                  netRefundedAmount: update.netRefundedAmount,
                                                                                                  netRefundedBfFeeAmount: update.netRefundedBfFeeAmount,
                                                                                                  referencePmtPublicId: update.referencePmtPublicId,
                                                                                                  lifecycleStatus: lifecycleStatus,
                                                                                                  processorTransactionId: update.processorTransactionId,
                                                                                                  pmtMethod: update.pmtMethod,
                                                                                                  bfcustPmtMethodIdentifier: update.bfcustPmtMethodIdentifier,
                                                                                                  bfcustPmtSubtype: update.bfcustPmtSubtype,
                                                                                                  bfcustPmtNote: update.bfcustPmtNote
                                                                                                };
                                                                                        });
                                                                            }
                                                                            
                                                                          }),
                                                                        placeholder: "Select payment status",
                                                                        required: true,
                                                                        slotProps: {
                                                                          listbox: {
                                                                            sx: {
                                                                              zIndex: "modal"
                                                                            }
                                                                          }
                                                                        },
                                                                        value: update.lifecycleStatus
                                                                      })
                                                                ]
                                                              })),
                                                      xs: 6
                                                    }),
                                                JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                      children: Caml_option.some(JsxRuntime.jsxs(FormControl, {
                                                                error: tmp,
                                                                children: [
                                                                  JsxRuntime.jsx(FormLabel, {
                                                                        children: "Transaction ID from Processor"
                                                                      }),
                                                                  JsxRuntime.jsx(Joy$Util.Input.make, {
                                                                        type_: "text",
                                                                        disabled: isPrefilled,
                                                                        required: true,
                                                                        value: Belt_Option.getWithDefault(update.processorTransactionId, ""),
                                                                        onChange: (function (e) {
                                                                            var val = e.currentTarget.value;
                                                                            if (val === "") {
                                                                              return setUpdate(function (param) {
                                                                                          return {
                                                                                                  payee: update.payee,
                                                                                                  teamUserInitiator: update.teamUserInitiator,
                                                                                                  processor: update.processor,
                                                                                                  netRefundedAmount: update.netRefundedAmount,
                                                                                                  netRefundedBfFeeAmount: update.netRefundedBfFeeAmount,
                                                                                                  referencePmtPublicId: update.referencePmtPublicId,
                                                                                                  lifecycleStatus: update.lifecycleStatus,
                                                                                                  processorTransactionId: undefined,
                                                                                                  pmtMethod: update.pmtMethod,
                                                                                                  bfcustPmtMethodIdentifier: update.bfcustPmtMethodIdentifier,
                                                                                                  bfcustPmtSubtype: update.bfcustPmtSubtype,
                                                                                                  bfcustPmtNote: update.bfcustPmtNote
                                                                                                };
                                                                                        });
                                                                            } else {
                                                                              return setUpdate(function (param) {
                                                                                          return {
                                                                                                  payee: update.payee,
                                                                                                  teamUserInitiator: update.teamUserInitiator,
                                                                                                  processor: update.processor,
                                                                                                  netRefundedAmount: update.netRefundedAmount,
                                                                                                  netRefundedBfFeeAmount: update.netRefundedBfFeeAmount,
                                                                                                  referencePmtPublicId: update.referencePmtPublicId,
                                                                                                  lifecycleStatus: update.lifecycleStatus,
                                                                                                  processorTransactionId: val,
                                                                                                  pmtMethod: update.pmtMethod,
                                                                                                  bfcustPmtMethodIdentifier: update.bfcustPmtMethodIdentifier,
                                                                                                  bfcustPmtSubtype: update.bfcustPmtSubtype,
                                                                                                  bfcustPmtNote: update.bfcustPmtNote
                                                                                                };
                                                                                        });
                                                                            }
                                                                          })
                                                                      })
                                                                ]
                                                              })),
                                                      xs: 6
                                                    }),
                                                tmp$1,
                                                tmp$4,
                                                JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                      children: Caml_option.some(JsxRuntime.jsxs(FormControl, {
                                                                error: tmp$5,
                                                                children: [
                                                                  JsxRuntime.jsx(FormLabel, {
                                                                        children: Caml_option.some(tmp$6)
                                                                      }),
                                                                  JsxRuntime.jsx(Joy$Util.Input.make, {
                                                                        type_: "text",
                                                                        required: true,
                                                                        value: Belt_Option.getWithDefault(update.bfcustPmtNote, ""),
                                                                        onChange: (function (e) {
                                                                            var val = e.currentTarget.value;
                                                                            if (val === "") {
                                                                              return setUpdate(function (param) {
                                                                                          return {
                                                                                                  payee: update.payee,
                                                                                                  teamUserInitiator: update.teamUserInitiator,
                                                                                                  processor: update.processor,
                                                                                                  netRefundedAmount: update.netRefundedAmount,
                                                                                                  netRefundedBfFeeAmount: update.netRefundedBfFeeAmount,
                                                                                                  referencePmtPublicId: update.referencePmtPublicId,
                                                                                                  lifecycleStatus: update.lifecycleStatus,
                                                                                                  processorTransactionId: update.processorTransactionId,
                                                                                                  pmtMethod: update.pmtMethod,
                                                                                                  bfcustPmtMethodIdentifier: update.bfcustPmtMethodIdentifier,
                                                                                                  bfcustPmtSubtype: update.bfcustPmtSubtype,
                                                                                                  bfcustPmtNote: undefined
                                                                                                };
                                                                                        });
                                                                            } else {
                                                                              return setUpdate(function (param) {
                                                                                          return {
                                                                                                  payee: update.payee,
                                                                                                  teamUserInitiator: update.teamUserInitiator,
                                                                                                  processor: update.processor,
                                                                                                  netRefundedAmount: update.netRefundedAmount,
                                                                                                  netRefundedBfFeeAmount: update.netRefundedBfFeeAmount,
                                                                                                  referencePmtPublicId: update.referencePmtPublicId,
                                                                                                  lifecycleStatus: update.lifecycleStatus,
                                                                                                  processorTransactionId: update.processorTransactionId,
                                                                                                  pmtMethod: update.pmtMethod,
                                                                                                  bfcustPmtMethodIdentifier: update.bfcustPmtMethodIdentifier,
                                                                                                  bfcustPmtSubtype: update.bfcustPmtSubtype,
                                                                                                  bfcustPmtNote: val
                                                                                                };
                                                                                        });
                                                                            }
                                                                          })
                                                                      })
                                                                ]
                                                              })),
                                                      xs: 6
                                                    })
                                              ],
                                              container: true,
                                              rowSpacing: 1,
                                              columnSpacing: 1
                                            }),
                                        error !== undefined ? JsxRuntime.jsx(Typography, {
                                                color: "danger",
                                                children: Caml_option.some(error)
                                              }) : null
                                      ]
                                    })
                              ],
                              sx: {
                                maxWidth: "500px"
                              }
                            }),
                        JsxRuntime.jsx(Divider, {}),
                        JsxRuntime.jsx(DialogActions, {
                              children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                        direction: {
                                          xs: "column-reverse",
                                          sm: "row"
                                        },
                                        component: "form",
                                        justifyContent: "space-between",
                                        spacing: 1,
                                        sx: {
                                          width: "100%"
                                        },
                                        onSubmit: onSubmit,
                                        children: [
                                          JsxRuntime.jsx(Button, {
                                                onClick: cancel,
                                                variant: "outlined",
                                                color: "neutral",
                                                children: "Cancel"
                                              }),
                                          JsxRuntime.jsx(Button, {
                                                loading: match$4.loading || match$5[1],
                                                type: "submit",
                                                disabled: Belt_Result.isError(BillPayInfo$Thick.Utils.manPayIntermToDiffRec(update)),
                                                children: Caml_option.some(isCanceled ? "Record" : "Add to draft")
                                              })
                                        ]
                                      }))
                            })
                      ]
                    }),
                JsxRuntime.jsx(PreviewModal$Thick.make, {
                      billPayCurrent: props.billPayCurrent,
                      billPayNew: match$4.billPayNew,
                      applyDiff: match$4.applyDiff,
                      title: "Record Manual Refund",
                      description: previewModalDescription,
                      onClose: (function () {
                          clearAll();
                        }),
                      open_: match$4.previewModalOpen,
                      versionUrl: props.versionUrl,
                      error: error
                    })
              ]
            });
}

var make = BDRefundModal;

exports.Query = Query;
exports.RecordManualRefundMutation = RecordManualRefundMutation;
exports.make = make;
/* use Not a pure module */
