// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var DateFns = require("date-fns");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CS_Make$Util = require("util/src/CustomScalars/CS_Make.bs.js");
var Common$Thick = require("../../utils/Common.bs.js");
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var Tooltip = require("@mui/joy/Tooltip").default;
var JsxRuntime = require("react/jsx-runtime");
var SharedDrawer$Thick = require("../common/molecules/SharedDrawer.bs.js");
var Typography = require("@mui/joy/Typography").default;
var BDVersionHistory$Thick = require("./BDVersionHistory.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var CircularProgress = require("@mui/joy/CircularProgress").default;
var Restore = require("@mui/icons-material/Restore").default;
var BDHero_version_graphql$Thick = require("../../__generated__/BDHero_version_graphql.bs.js");

var convertFragment = BDHero_version_graphql$Thick.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(BDHero_version_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, BDHero_version_graphql$Thick.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(BDHero_version_graphql$Thick.node, convertFragment, fRef);
}

var VersionFragment_lifecycleStatus_decode = BDHero_version_graphql$Thick.Utils.lifecycleStatus_decode;

var VersionFragment_lifecycleStatus_fromString = BDHero_version_graphql$Thick.Utils.lifecycleStatus_fromString;

var VersionFragment_versionProvenance_decode = BDHero_version_graphql$Thick.Utils.versionProvenance_decode;

var VersionFragment_versionProvenance_fromString = BDHero_version_graphql$Thick.Utils.versionProvenance_fromString;

var VersionFragment = {
  lifecycleStatus_decode: VersionFragment_lifecycleStatus_decode,
  lifecycleStatus_fromString: VersionFragment_lifecycleStatus_fromString,
  versionProvenance_decode: VersionFragment_versionProvenance_decode,
  versionProvenance_fromString: VersionFragment_versionProvenance_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

function BDHero$Hero(props) {
  var content = props.content;
  var match = React.useState(function () {
        return false;
      });
  var setDrawerIsOpen = match[1];
  var timeSinceBookingCreation = DateFns.formatDistanceToNow(CS_Make$Util.Dateable.Plain.toDate(content.bookingCreatedAt));
  var bookingCreationDate = CS_Make$Util.Dateable.Plain.format(content.bookingCreatedAt, "PPPP pp");
  var timeSinceVersionCreation = DateFns.formatDistanceToNow(CS_Make$Util.Dateable.Plain.toDate(content.versionCreatedAt));
  var versionCreationDate = CS_Make$Util.Dateable.Plain.format(content.versionCreatedAt, "PPPP pp");
  var versionComment = content.versionComment;
  var match$1 = content.versionProvenance;
  var tmp;
  if (match$1 === "PAY_IN_FULL_COMPLETION" || match$1 === "CLAIM_NEW_PASSENGER_INVITATION" || match$1 === "ACCOUNT_UPDATE" || match$1 === "BOOKING_CHANGE_INDIRECT" || match$1 === "BOOKING_CHANGE" || match$1 === "BOOKED" || match$1 === "MIGRATED") {
    switch (match$1) {
      case "BOOKED" :
          tmp = "Booked by ";
          break;
      case "BOOKING_CHANGE" :
          tmp = "Booking modified by ";
          break;
      case "BOOKING_CHANGE_INDIRECT" :
          tmp = "Booking indirectly modified by ";
          break;
      case "ACCOUNT_UPDATE" :
          tmp = "Modified due to user account update by ";
          break;
      case "CLAIM_NEW_PASSENGER_INVITATION" :
          tmp = "Invitation claimed by ";
          break;
      case "PAY_IN_FULL_COMPLETION" :
          tmp = "Modified due to pay in full completion by ";
          break;
      case "MIGRATED" :
          tmp = "Migrated from external data source";
          break;
      
    }
  } else {
    tmp = "";
  }
  var match$2 = content.versionProvenance;
  var tmp$1;
  var exit = 0;
  if ((match$2 === "PAY_IN_FULL_COMPLETION" || match$2 === "CLAIM_NEW_PASSENGER_INVITATION" || match$2 === "ACCOUNT_UPDATE" || match$2 === "BOOKING_CHANGE_INDIRECT" || match$2 === "BOOKING_CHANGE" || match$2 === "BOOKED" || match$2 === "MIGRATED") && match$2 === "MIGRATED") {
    tmp$1 = "";
  } else {
    exit = 1;
  }
  if (exit === 1) {
    var match$3 = content.versionCreatorTeamUserEmail;
    var match$4 = content.versionCreatorBfcustName;
    var match$5 = content.versionCreatorBfcustEmail;
    tmp$1 = match$3 !== undefined ? CS_Emails$Util.Email.toString(Caml_option.valFromOption(match$3)) : (
        match$4 !== undefined && match$5 !== undefined ? match$4 + " (" + CS_Emails$Util.Email.toString(Caml_option.valFromOption(match$5)) + ")" : ""
      );
  }
  return JsxRuntime.jsxs(Sheet, {
              variant: "solid",
              color: "primary",
              sx: {
                px: 3,
                py: 2,
                borderRadius: "4px"
              },
              children: [
                JsxRuntime.jsxs(Stack, {
                      direction: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      children: [
                        JsxRuntime.jsxs("table", {
                              children: [
                                JsxRuntime.jsxs("tr", {
                                      children: [
                                        JsxRuntime.jsx("td", {
                                              children: JsxRuntime.jsxs(Stack, {
                                                    direction: "row",
                                                    alignItems: "center",
                                                    spacing: 2,
                                                    children: [
                                                      JsxRuntime.jsx(Typography, {
                                                            level: "h3",
                                                            component: "h1",
                                                            textColor: "neutral.50",
                                                            fontWeight: "400",
                                                            children: Caml_option.some(CS_NonemptyStrings$Util.BookingId.toString(content.bookingId))
                                                          }),
                                                      JsxRuntime.jsx(Stack, {
                                                            sx: {
                                                              height: "fit-content"
                                                            },
                                                            children: Caml_option.some(Common$Thick.Lifecycle.makeChip("sm", content.lifecycleStatus, content.bookingVersionNumber))
                                                          })
                                                    ]
                                                  })
                                            }),
                                        JsxRuntime.jsx("td", {
                                              children: JsxRuntime.jsx(Stack, {
                                                    sx: {
                                                      pl: 2
                                                    },
                                                    children: Caml_option.some(versionComment !== undefined ? JsxRuntime.jsx(Stack, {
                                                                children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                                          level: "title-sm",
                                                                          component: "p",
                                                                          textColor: "neutral.50",
                                                                          fontWeight: "200",
                                                                          children: Caml_option.some("Version " + String(content.bookingVersionNumber) + ": \"" + CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(versionComment)) + "\"")
                                                                        }))
                                                              }) : null)
                                                  })
                                            })
                                      ]
                                    }),
                                JsxRuntime.jsxs("tr", {
                                      children: [
                                        JsxRuntime.jsx("td", {
                                              children: JsxRuntime.jsxs(Stack, {
                                                    direction: "column",
                                                    children: [
                                                      JsxRuntime.jsx(Stack, {
                                                            sx: {
                                                              width: "fit-content"
                                                            },
                                                            children: Caml_option.some(JsxRuntime.jsx(Tooltip, {
                                                                      children: JsxRuntime.jsx(Typography, {
                                                                            color: "inherit",
                                                                            level: "body-xs",
                                                                            fontWeight: "300",
                                                                            children: Caml_option.some("Created " + timeSinceBookingCreation + " ago")
                                                                          }),
                                                                      arrow: true,
                                                                      size: "sm",
                                                                      title: Caml_option.some(bookingCreationDate),
                                                                      variant: "plain"
                                                                    }))
                                                          }),
                                                      JsxRuntime.jsxs(Stack, {
                                                            direction: "row",
                                                            alignItems: "center",
                                                            sx: {
                                                              cursor: "pointer"
                                                            },
                                                            onClick: (function (param) {
                                                                setDrawerIsOpen(function (param) {
                                                                      return true;
                                                                    });
                                                              }),
                                                            children: [
                                                              JsxRuntime.jsx(Restore, {
                                                                    color: "inherit",
                                                                    fontSize: "sm",
                                                                    sx: {
                                                                      mr: "3px"
                                                                    }
                                                                  }),
                                                              JsxRuntime.jsx(Typography, {
                                                                    color: "inherit",
                                                                    level: "body-xs",
                                                                    sx: {
                                                                      textDecoration: "underline"
                                                                    },
                                                                    fontWeight: "300",
                                                                    children: "View version history"
                                                                  })
                                                            ]
                                                          })
                                                    ]
                                                  })
                                            }),
                                        JsxRuntime.jsx("td", {
                                              children: JsxRuntime.jsxs(Stack, {
                                                    direction: "column",
                                                    sx: {
                                                      pl: 2,
                                                      alignSelf: "flex-start"
                                                    },
                                                    children: [
                                                      JsxRuntime.jsx(Typography, {
                                                            color: "inherit",
                                                            level: "body-xs",
                                                            fontWeight: "300",
                                                            children: Caml_option.some(tmp + tmp$1)
                                                          }),
                                                      JsxRuntime.jsx(Stack, {
                                                            sx: {
                                                              width: "fit-content",
                                                              display: content.bookingVersionNumber > 1 ? "flex" : "none"
                                                            },
                                                            children: Caml_option.some(JsxRuntime.jsx(Tooltip, {
                                                                      children: JsxRuntime.jsx(Typography, {
                                                                            color: "inherit",
                                                                            level: "body-xs",
                                                                            fontWeight: "300",
                                                                            children: Caml_option.some("Updated " + timeSinceVersionCreation + " ago")
                                                                          }),
                                                                      arrow: true,
                                                                      size: "sm",
                                                                      title: Caml_option.some(versionCreationDate),
                                                                      variant: "plain"
                                                                    }))
                                                          })
                                                    ]
                                                  }),
                                              style: {
                                                display: "flex",
                                                alignItems: "flex-start"
                                              }
                                            })
                                      ]
                                    })
                              ],
                              style: {
                                borderCollapse: "separate"
                              }
                            }),
                        JsxRuntime.jsx(Stack, {
                              children: Caml_option.some(props.isFetchingData ? JsxRuntime.jsx(Typography, {
                                          color: "inherit",
                                          startDecorator: Caml_option.some(JsxRuntime.jsx(CircularProgress, {
                                                    color: "primary",
                                                    size: "sm",
                                                    variant: "solid"
                                                  })),
                                          level: "body-xs",
                                          sx: {
                                            fontStyle: "italic"
                                          },
                                          children: "Syncing data..."
                                        }) : null)
                            })
                      ]
                    }),
                JsxRuntime.jsx(SharedDrawer$Thick.make, {
                      open_: match[0],
                      title: "Version History",
                      onClose: (function () {
                          setDrawerIsOpen(function (param) {
                                return false;
                              });
                        }),
                      children: JsxRuntime.jsx(BDVersionHistory$Thick.make, {
                            fragmentRefs: props.historyFrag,
                            redirect: props.redirect,
                            snapshot: props.snapshot,
                            onClose: (function () {
                                setDrawerIsOpen(function (param) {
                                      return false;
                                    });
                              })
                          })
                    })
              ]
            });
}

var Hero = {
  make: BDHero$Hero
};

function BDHero(props) {
  var status = props.status;
  var version = use(props.fragmentRefs);
  var bfcu = version.versionCreatorBfcust;
  var content = bfcu !== undefined ? ({
        bookingId: version.bookingId,
        lifecycleStatus: status,
        bookingVersionNumber: version.bookingVersionNumber,
        versionComment: version.versionComment,
        versionProvenance: version.versionProvenance,
        versionCreatorTeamUserEmail: version.versionCreatorTeamUserEmail,
        versionCreatorBfcustName: bfcu.fullName,
        versionCreatorBfcustEmail: bfcu.email,
        bookingCreatedAt: version.bookingCreatedAt,
        versionCreatedAt: version.versionCreatedAt
      }) : ({
        bookingId: version.bookingId,
        lifecycleStatus: status,
        bookingVersionNumber: version.bookingVersionNumber,
        versionComment: version.versionComment,
        versionProvenance: version.versionProvenance,
        versionCreatorTeamUserEmail: version.versionCreatorTeamUserEmail,
        versionCreatorBfcustName: undefined,
        versionCreatorBfcustEmail: undefined,
        bookingCreatedAt: version.bookingCreatedAt,
        versionCreatedAt: version.versionCreatedAt
      });
  return JsxRuntime.jsx(BDHero$Hero, {
              content: content,
              historyFrag: props.historyFrag,
              redirect: props.redirect,
              snapshot: props.snapshot,
              status: status,
              isFetchingData: props.isFetchingData
            });
}

var make = BDHero;

exports.VersionFragment = VersionFragment;
exports.Hero = Hero;
exports.make = make;
/* react Not a pure module */
