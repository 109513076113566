// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Set = require("rescript/lib/js/belt_Set.js");
var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Box = require("@mui/joy/Box").default;
var BDHero$Thick = require("../components/booking-details/BDHero.bs.js");
var Common$Thick = require("../utils/Common.bs.js");
var BDCabin$Thick = require("../components/booking-details/BDCabin.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var Stack = require("@mui/joy/Stack").default;
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var BDComments$Thick = require("../components/booking-details/BDComments.bs.js");
var BDReferrer$Thick = require("../components/booking-details/BDReferrer.bs.js");
var DraftAlert$Thick = require("../components/booking-details/drafts/DraftAlert.bs.js");
var DraftUtils$Thick = require("../components/booking-details/drafts/DraftUtils.bs.js");
var HopperState$Util = require("util/src/HopperState.bs.js");
var BookingDiff$Thick = require("../utils/BookingDiff.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var BDPreferences$Thick = require("../components/booking-details/BDPreferences.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var BDBillPaySummary$Thick = require("../components/booking-details/billing-payments/BDBillPaySummary.bs.js");
var BDBillingContact$Thick = require("../components/booking-details/BDBillingContact.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var BDAnnouncementBar$Thick = require("../components/booking-details/BDAnnouncementBar.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var BDBerthsPassengers$Thick = require("../components/booking-details/BDBerthsPassengers.bs.js");
var ManageBookingState$Thick = require("../utils/ManageBookingState.bs.js");
var BookingDetailsPage_query_graphql$Thick = require("../__generated__/BookingDetailsPage_query_graphql.bs.js");
var BookingDetailsPage_version_graphql$Thick = require("../__generated__/BookingDetailsPage_version_graphql.bs.js");
var BookingDetailsPageVersionQueryRefetch_graphql$Thick = require("../__generated__/BookingDetailsPageVersionQueryRefetch_graphql.bs.js");

var convertFragment = BookingDetailsPage_query_graphql$Thick.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(BookingDetailsPage_query_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, BookingDetailsPage_query_graphql$Thick.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(BookingDetailsPage_query_graphql$Thick.node, convertFragment, fRef);
}

var QueryFragment_brandFamilyPermission_decode = BookingDetailsPage_query_graphql$Thick.Utils.brandFamilyPermission_decode;

var QueryFragment_brandFamilyPermission_fromString = BookingDetailsPage_query_graphql$Thick.Utils.brandFamilyPermission_fromString;

var QueryFragment_lifecycleStatus_decode = BookingDetailsPage_query_graphql$Thick.Utils.lifecycleStatus_decode;

var QueryFragment_lifecycleStatus_fromString = BookingDetailsPage_query_graphql$Thick.Utils.lifecycleStatus_fromString;

var QueryFragment = {
  brandFamilyPermission_decode: QueryFragment_brandFamilyPermission_decode,
  brandFamilyPermission_fromString: QueryFragment_brandFamilyPermission_fromString,
  lifecycleStatus_decode: QueryFragment_lifecycleStatus_decode,
  lifecycleStatus_fromString: QueryFragment_lifecycleStatus_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

var convertFragment$1 = BookingDetailsPage_version_graphql$Thick.Internal.convertFragment;

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(BookingDetailsPage_version_graphql$Thick.node, convertFragment$1, fRef);
}

function useOpt$1(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, BookingDetailsPage_version_graphql$Thick.node, convertFragment$1);
}

function readResolverFragment$1(fRef) {
  return RescriptRelay_Fragment.read(BookingDetailsPage_version_graphql$Thick.node, convertFragment$1, fRef);
}

var makeRefetchVariables = BookingDetailsPageVersionQueryRefetch_graphql$Thick.Types.makeRefetchVariables;

var convertRefetchVariables = BookingDetailsPageVersionQueryRefetch_graphql$Thick.Internal.convertVariables;

function useRefetchable(fRef) {
  return RescriptRelay_Fragment.useRefetchableFragment(BookingDetailsPage_version_graphql$Thick.node, convertFragment$1, convertRefetchVariables, fRef);
}

var VersionFragment_docGender_decode = BookingDetailsPage_version_graphql$Thick.Utils.docGender_decode;

var VersionFragment_docGender_fromString = BookingDetailsPage_version_graphql$Thick.Utils.docGender_fromString;

var VersionFragment_manifestPaxReconciliationStatus_decode = BookingDetailsPage_version_graphql$Thick.Utils.manifestPaxReconciliationStatus_decode;

var VersionFragment_manifestPaxReconciliationStatus_fromString = BookingDetailsPage_version_graphql$Thick.Utils.manifestPaxReconciliationStatus_fromString;

var VersionFragment_paxDataStatus_decode = BookingDetailsPage_version_graphql$Thick.Utils.paxDataStatus_decode;

var VersionFragment_paxDataStatus_fromString = BookingDetailsPage_version_graphql$Thick.Utils.paxDataStatus_fromString;

var VersionFragment = {
  docGender_decode: VersionFragment_docGender_decode,
  docGender_fromString: VersionFragment_docGender_fromString,
  manifestPaxReconciliationStatus_decode: VersionFragment_manifestPaxReconciliationStatus_decode,
  manifestPaxReconciliationStatus_fromString: VersionFragment_manifestPaxReconciliationStatus_fromString,
  paxDataStatus_decode: VersionFragment_paxDataStatus_decode,
  paxDataStatus_fromString: VersionFragment_paxDataStatus_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$1,
  use: use$1,
  useOpt: useOpt$1,
  readResolverFragment: readResolverFragment$1,
  makeRefetchVariables: makeRefetchVariables,
  convertRefetchVariables: convertRefetchVariables,
  useRefetchable: useRefetchable
};

function BookingDetailsPage(props) {
  var isFetchingData = props.isFetchingData;
  var versionBilling = props.versionBilling;
  var billPayRec = props.billPayRec;
  var __snapshot = props.snapshot;
  var latestVersion = props.latestVersion;
  var voyage = props.voyage;
  var brandFamily = props.brandFamily;
  var bookingId = props.bookingId;
  var snapshot = __snapshot !== undefined ? __snapshot : false;
  var query = use(props.fragmentRefs);
  var redirect = "/" + CS_Slugs$Util.BrandFamilySlug.toString(brandFamily) + "/" + CS_Slugs$Util.VoyageSlug.toString(voyage) + "/bookings/" + CS_NonemptyStrings$Util.BookingId.toString(bookingId);
  var versionUrl = latestVersion !== undefined ? redirect + "/" + String(latestVersion) : redirect;
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var diffStruct = BookingDiff$Thick.parse(url.search);
  var match = query.bookingVersions;
  var booking = match !== undefined ? Belt_Array.get(match.nodes, 0) : undefined;
  var match$1 = Belt_Option.map(query.currentUserEmail, CS_Emails$Util.Email.fromPrimitive);
  var currentUserEmail = match$1 !== undefined && match$1.TAG === "Ok" ? Caml_option.some(match$1._0) : undefined;
  var userPerms = Belt_Set.fromArray(Belt_Array.keepMap(Belt_Option.getWithDefault(query.currentUserCanForBrand, []), (function (x) {
              if (x !== undefined) {
                return x;
              }
              
            })), Common$Thick.PermCmp);
  var canManage = Belt_Set.has(userPerms, "MANAGE_BOOKINGS");
  React.useEffect((function () {
          if (booking !== undefined) {
            
          } else {
            RescriptReactRouter.push(redirect);
          }
        }), []);
  if (booking === undefined) {
    return null;
  }
  var isActive = booking.lifecycleStatus === "ACTIVE";
  var isCanceled = booking.lifecycleStatus === "CANCELED";
  var status = diffStruct.hasDiff ? "DRAFT" : booking.lifecycleStatus;
  var match$2 = useRefetchable(booking.fragmentRefs);
  var refetch = match$2[1];
  var data = match$2[0];
  var refetch$1 = function (param) {
    refetch(makeRefetchVariables(undefined), "store-and-network", undefined);
  };
  var match$3 = diffStruct.addBerths;
  var match$4 = diffStruct.dropBerths;
  var numBerths = match$3 !== undefined ? (
      match$4 !== undefined ? (data.numBerths + match$3 | 0) - match$4.length | 0 : data.numBerths + match$3 | 0
    ) : (
      match$4 !== undefined ? data.numBerths - match$4.length | 0 : data.numBerths
    );
  var match$5 = data.occRange.end;
  var occMax = match$5 !== undefined ? (
      match$5.inclusive ? match$5.value : match$5.value - 1 | 0
    ) : 0;
  var modelConfig_canAddBerth = numBerths < occMax;
  var modelConfig_wasReferred = booking.referredBy;
  var modelConfig = {
    canAddBerth: modelConfig_canAddBerth,
    wasReferred: modelConfig_wasReferred
  };
  var state = HopperState$Util.Observable.useStore((function () {
          return ManageBookingState$Thick.initModel(modelConfig);
        }), undefined);
  var canAddBerth = HopperState$Util.Observable.useComputed(state, (function (m) {
          return m.canAddBerth;
        }), undefined, undefined);
  var fareClassSlug = data.fareClassSlug;
  var match$6 = ManageBookingState$Thick.generatePaxList(data.passenger.nodes, diffStruct, brandFamily, numBerths, state);
  var postMushIdxMap = match$6.postMushIdxMap;
  var generatePreviewFromDiff = DraftUtils$Thick.previewCallback(bookingId, props.bookingVersionNumber, voyage);
  var versionTimestamp = booking.versionCreatedAt;
  React.useEffect((function () {
          var match = diffStruct.postMushIdxMap;
          if (postMushIdxMap !== undefined) {
            var newPostMushIdxMap = Caml_option.valFromOption(postMushIdxMap);
            if (match !== Caml_option.some(newPostMushIdxMap)) {
              var newDiff = BookingDiff$Thick.Alter.setPostMushIdxMap(diffStruct, newPostMushIdxMap);
              DraftUtils$Thick.addToDraft(newDiff, versionUrl);
            }
            
          }
          
        }), [JSON.stringify(postMushIdxMap)]);
  var tmp;
  var exit = 0;
  if ((status === "CANCELED" || status === "STAGED" || status === "DRAFT" || status === "ACTIVE" || status === "HISTORY") && status === "ACTIVE") {
    tmp = null;
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = JsxRuntime.jsx(BDAnnouncementBar$Thick.make, {
          status: status,
          redirect: redirect,
          latestVersion: latestVersion,
          paxes: booking.paxes,
          diffStruct: diffStruct,
          versionBilling: versionBilling,
          applyArgs: generatePreviewFromDiff(diffStruct),
          modelConfig: modelConfig,
          state: state
        });
  }
  var tmp$1;
  tmp$1 = (status === "CANCELED" || status === "STAGED" || status === "DRAFT" || status === "ACTIVE" || status === "HISTORY") && status === "DRAFT" ? JsxRuntime.jsx(DraftAlert$Thick.make, {
          paxes: booking.paxes,
          versionBilling: versionBilling,
          diffStruct: diffStruct
        }) : null;
  return JsxRuntime.jsxs(Stack, {
              sx: {
                height: "100%",
                width: "100%",
                backgroundColor: "primary.50",
                position: "relative"
              },
              children: [
                tmp,
                JsxRuntime.jsx(Box, {
                      children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                sx: {
                                  width: "100%",
                                  height: "100%",
                                  maxWidth: "1536px"
                                },
                                children: Caml_option.some(JsxRuntime.jsxs(Joy$Util.Grid.make, {
                                          children: [
                                            JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                  children: Caml_option.some(JsxRuntime.jsx(BDHero$Thick.make, {
                                                            fragmentRefs: booking.fragmentRefs,
                                                            snapshot: snapshot,
                                                            historyFrag: query.fragmentRefs,
                                                            redirect: redirect,
                                                            status: status,
                                                            isFetchingData: isFetchingData
                                                          })),
                                                  xs: 12
                                                }),
                                            JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                  children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                                            spacing: 2,
                                                            children: [
                                                              JsxRuntime.jsx(BDBillingContact$Thick.make, {
                                                                    fragmentRefs: booking.fragmentRefs,
                                                                    snapshot: snapshot,
                                                                    isActive: isActive,
                                                                    canManage: canManage,
                                                                    versionUrl: versionUrl,
                                                                    billPayRec: billPayRec,
                                                                    brandFamily: brandFamily,
                                                                    generatePreviewFromDiff: generatePreviewFromDiff
                                                                  }),
                                                              JsxRuntime.jsx(BDCabin$Thick.make, {
                                                                    fragmentRefs: booking.fragmentRefs,
                                                                    brandFamily: brandFamily,
                                                                    voyage: voyage,
                                                                    billPayRec: billPayRec,
                                                                    versionUrl: versionUrl,
                                                                    generatePreviewFromDiff: generatePreviewFromDiff,
                                                                    snapshot: snapshot,
                                                                    isActive: isActive,
                                                                    canManage: canManage,
                                                                    diffStruct: diffStruct,
                                                                    bookingId: booking.bookingId,
                                                                    fareClassSlug: CS_Slugs$Util.FareClassSlug.toString(fareClassSlug),
                                                                    state: state
                                                                  }),
                                                              Belt_Set.has(userPerms, "VIEW_CUSTOMER_DETAILS") ? JsxRuntime.jsx(BDBerthsPassengers$Thick.make, {
                                                                      fragmentRefs: booking.fragmentRefs,
                                                                      countryFragmentRefs: query.fragmentRefs,
                                                                      snapshot: snapshot,
                                                                      isActive: isActive,
                                                                      canManage: canManage,
                                                                      billPayRec: billPayRec,
                                                                      versionUrl: versionUrl,
                                                                      brandFamily: brandFamily,
                                                                      generatePreviewFromDiff: generatePreviewFromDiff,
                                                                      voyage: voyage,
                                                                      refetch: refetch$1,
                                                                      shouldBlockDrop: match$6.shouldBlockDrop,
                                                                      canAddBerth: canAddBerth,
                                                                      state: state,
                                                                      numBerths: numBerths
                                                                    }) : null,
                                                              JsxRuntime.jsx(BDPreferences$Thick.make, {
                                                                    fragmentRefs: booking.fragmentRefs,
                                                                    snapshot: snapshot,
                                                                    isActive: isActive,
                                                                    canManage: canManage,
                                                                    diffStruct: diffStruct,
                                                                    billPayRec: billPayRec,
                                                                    generatePreviewFromDiff: generatePreviewFromDiff,
                                                                    versionUrl: versionUrl
                                                                  })
                                                            ]
                                                          })),
                                                  xs: 12,
                                                  md: 7
                                                }),
                                            JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                  children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                                            spacing: 2,
                                                            children: [
                                                              JsxRuntime.jsx(BDBillPaySummary$Thick.make, {
                                                                    fragmentRefs: booking.fragmentRefs,
                                                                    snapshot: snapshot,
                                                                    isActive: isActive,
                                                                    isCanceled: isCanceled,
                                                                    canManage: canManage,
                                                                    billPayRec: billPayRec,
                                                                    versionUrl: versionUrl,
                                                                    generatePreviewFromDiff: generatePreviewFromDiff,
                                                                    versionTimestamp: versionTimestamp,
                                                                    versionSuppressions: props.versionSuppressions,
                                                                    currentUserEmail: currentUserEmail,
                                                                    brandFamily: brandFamily,
                                                                    refetchBilling: props.refetchBilling,
                                                                    isFetchingData: isFetchingData
                                                                  }),
                                                              JsxRuntime.jsx(BDReferrer$Thick.make, {
                                                                    state: state,
                                                                    brandFamily: brandFamily,
                                                                    voyage: voyage,
                                                                    generatePreviewFromDiff: generatePreviewFromDiff,
                                                                    diffStruct: diffStruct,
                                                                    billPayCurrent: billPayRec,
                                                                    versionUrl: versionUrl
                                                                  }),
                                                              latestVersion !== undefined ? JsxRuntime.jsx(BDComments$Thick.make, {
                                                                      brandFamily: brandFamily,
                                                                      voyage: voyage,
                                                                      bookingId: booking.bookingId,
                                                                      bookingVersionNumber: latestVersion,
                                                                      fragmentRefs: booking.fragmentRefs
                                                                    }) : null
                                                            ]
                                                          })),
                                                  xs: 12,
                                                  md: 5
                                                })
                                          ],
                                          container: true,
                                          rowSpacing: 2,
                                          columnSpacing: 3
                                        }))
                              })),
                      sx: {
                        flex: 1,
                        overflow: "auto",
                        px: 3,
                        pb: 3,
                        pt: status === "ACTIVE" ? 3 : 2,
                        backgroundColor: "primary.50",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        borderLeft: status !== "ACTIVE" ? "3px solid" : "none",
                        borderRight: status !== "ACTIVE" ? "3px solid" : "none",
                        borderColor: Common$Thick.Lifecycle.getPrimaryColor(status)
                      }
                    }),
                tmp$1
              ]
            });
}

var make = BookingDetailsPage;

exports.QueryFragment = QueryFragment;
exports.VersionFragment = VersionFragment;
exports.make = make;
/* react Not a pure module */
