// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Menu = require("@mui/joy/Menu").default;
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var Button = require("@mui/joy/Button").default;
var $$Option = require("@mui/joy/Option").default;
var SearchBar$Thick = require("../common/molecules/SearchBar.bs.js");
var HopperState$Util = require("util/src/HopperState.bs.js");
var Dropdown = require("@mui/joy/Dropdown").default;
var MenuItem = require("@mui/joy/MenuItem").default;
var JsxRuntime = require("react/jsx-runtime");
var IconButton = require("@mui/joy/IconButton").default;
var MenuButton = require("@mui/joy/MenuButton").default;
var Typography = require("@mui/joy/Typography").default;
var ButtonGroup = require("@mui/joy/ButtonGroup").default;
var CustomersState$Thick = require("../../utils/CustomersState.bs.js");
var Add = require("@mui/icons-material/Add").default;
var ListItemContent = require("@mui/joy/ListItemContent").default;
var Merge = require("@mui/icons-material/Merge").default;
var ListItemDecorator = require("@mui/joy/ListItemDecorator").default;
var ChevronLeft = require("@mui/icons-material/ChevronLeft").default;
var ChevronRight = require("@mui/icons-material/ChevronRight").default;
var ArrowDropDown = require("@mui/icons-material/ArrowDropDown").default;

function CustTableToolbar(props) {
  var state = props.state;
  var __searchBar = props.searchBar;
  var onLoadPrev = props.onLoadPrev;
  var onLoadNext = props.onLoadNext;
  var offset = props.offset;
  var totalCount = props.totalCount;
  var searchBar = __searchBar !== undefined ? __searchBar : false;
  var activeStatusFilter = HopperState$Util.Observable.useComputed(state, (function (param) {
          return CustomersState$Thick.accountTypeFilterToString(param.listFilters.accountTypeFilter);
        }), undefined, undefined);
  var match = HopperState$Util.Observable.FormField.useState(state, CustomersState$Thick.listFilterSearchTerm, undefined);
  var setSearchTerm = match[1];
  var searchTerm = match[0];
  var triggerSearch = function () {
    HopperState$Util.Observable.notify(state, (function (m) {
            var searchTerm = m.listFilters.searchTerm;
            var init = m.listFilters;
            return {
                    custForm: m.custForm,
                    convertToPrefilled: m.convertToPrefilled,
                    listFilters: {
                      searchTerm: {
                        initialValue: searchTerm.value,
                        value: searchTerm.value,
                        lastTouched: searchTerm.lastTouched
                      },
                      accountTypeFilter: init.accountTypeFilter
                    },
                    offset: 0,
                    resendEmailVerificationForm: m.resendEmailVerificationForm,
                    url: m.url
                  };
          }));
  };
  var onSubmit = function (e) {
    e.preventDefault();
    triggerSearch();
  };
  return JsxRuntime.jsx(Sheet, {
              sx: {
                p: 1
              },
              children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                        direction: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        spacing: 1,
                        children: [
                          searchBar ? JsxRuntime.jsxs(Stack, {
                                  direction: "row",
                                  component: "form",
                                  spacing: 1,
                                  onSubmit: onSubmit,
                                  children: [
                                    JsxRuntime.jsx(SearchBar$Thick.make, {
                                          value: Belt_Option.getWithDefault(searchTerm, ""),
                                          onChange: (function (e) {
                                              setSearchTerm(function (param) {
                                                    var v = e.currentTarget.value;
                                                    if (v === "") {
                                                      return ;
                                                    } else {
                                                      return v;
                                                    }
                                                  });
                                            }),
                                          placeholder: "Search users",
                                          onClear: (function (param) {
                                              setSearchTerm(function (param) {
                                                    
                                                  });
                                              triggerSearch();
                                            }),
                                          showClear: Belt_Option.isSome(searchTerm)
                                        }),
                                    JsxRuntime.jsx(Button, {
                                          type: "submit",
                                          variant: "outlined",
                                          disabled: Belt_Option.isNone(searchTerm),
                                          children: "Search"
                                        })
                                  ]
                                }) : JsxRuntime.jsx("div", {}),
                          JsxRuntime.jsxs(Stack, {
                                direction: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                spacing: 1,
                                children: [
                                  searchBar ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                          children: [
                                            JsxRuntime.jsxs(Dropdown, {
                                                  children: [
                                                    JsxRuntime.jsxs(ButtonGroup, {
                                                          children: [
                                                            JsxRuntime.jsx(Button, {
                                                                  onClick: (function (param) {
                                                                      HopperState$Util.Observable.notify(state, (function (f) {
                                                                              return {
                                                                                      custForm: CustomersState$Thick.blankCust("NewPrefill"),
                                                                                      convertToPrefilled: f.convertToPrefilled,
                                                                                      listFilters: f.listFilters,
                                                                                      offset: f.offset,
                                                                                      resendEmailVerificationForm: f.resendEmailVerificationForm,
                                                                                      url: f.url
                                                                                    };
                                                                            }));
                                                                    }),
                                                                  startDecorator: Caml_option.some(JsxRuntime.jsx(Add, {})),
                                                                  "aria-label": "Create Prefilled Account",
                                                                  children: "Create Prefilled Account"
                                                                }),
                                                            JsxRuntime.jsx(MenuButton, {
                                                                  children: Caml_option.some(JsxRuntime.jsx(ArrowDropDown, {})),
                                                                  size: "md",
                                                                  slotProps: {
                                                                    root: {
                                                                      color: "neutral",
                                                                      variant: "outlined"
                                                                    }
                                                                  },
                                                                  slots: {
                                                                    root: IconButton
                                                                  }
                                                                })
                                                          ],
                                                          "aria-label": "outlined primary button group"
                                                        }),
                                                    JsxRuntime.jsx(Menu, {
                                                          children: Caml_option.some(JsxRuntime.jsx(MenuItem, {
                                                                    children: Caml_option.some(JsxRuntime.jsxs(Joy$Util.Link.make, {
                                                                              children: [
                                                                                JsxRuntime.jsx(ListItemDecorator, {
                                                                                      children: Caml_option.some(JsxRuntime.jsx(Merge, {
                                                                                                sx: {
                                                                                                  color: "neutral.500"
                                                                                                }
                                                                                              }))
                                                                                    }),
                                                                                JsxRuntime.jsx(ListItemContent, {
                                                                                      children: "Merge Accounts"
                                                                                    })
                                                                              ],
                                                                              href: props.mergeAccountsUrl,
                                                                              sx: {
                                                                                textDecoration: "none",
                                                                                fontSize: "inherit",
                                                                                color: "inherit",
                                                                                "&:hover": {
                                                                                  textDecoration: "none"
                                                                                }
                                                                              }
                                                                            }))
                                                                  })),
                                                          placement: "bottom-end",
                                                          size: "sm",
                                                          sx: {
                                                            zIndex: "modal"
                                                          }
                                                        })
                                                  ]
                                                }),
                                            JsxRuntime.jsx(Joy$Util.JoySelect.make, {
                                                  children: Caml_option.some(Belt_Array.map(CustomersState$Thick.accountTypeFiltrationOptions, (function (accountTypeFilterMeta) {
                                                              return JsxRuntime.jsx($$Option, {
                                                                          value: CustomersState$Thick.accountTypeFilterMetaToString(accountTypeFilterMeta),
                                                                          children: Caml_option.some(accountTypeFilterMeta.accountTypeDescriptor)
                                                                        }, CustomersState$Thick.accountTypeFilterMetaToString(accountTypeFilterMeta));
                                                            }))),
                                                  onChange: (function (param, val) {
                                                      var accountTypeFilter = Belt_Option.flatMap(Belt_Option.flatMap(val, (function (a) {
                                                                  return a;
                                                                })), CustomersState$Thick.accountTypeFilterFromString);
                                                      if (accountTypeFilter !== undefined) {
                                                        return HopperState$Util.Observable.notify(state, (function (m) {
                                                                      var init = m.listFilters;
                                                                      return {
                                                                              custForm: m.custForm,
                                                                              convertToPrefilled: m.convertToPrefilled,
                                                                              listFilters: {
                                                                                searchTerm: init.searchTerm,
                                                                                accountTypeFilter: accountTypeFilter
                                                                              },
                                                                              offset: 0,
                                                                              resendEmailVerificationForm: m.resendEmailVerificationForm,
                                                                              url: m.url
                                                                            };
                                                                    }));
                                                      }
                                                      
                                                    }),
                                                  sx: {
                                                    width: "10rem"
                                                  },
                                                  value: activeStatusFilter
                                                })
                                          ]
                                        }) : JsxRuntime.jsx("div", {}),
                                  JsxRuntime.jsxs(Stack, {
                                        direction: "row",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        children: [
                                          JsxRuntime.jsx(IconButton, {
                                                onClick: (function (param) {
                                                    onLoadPrev();
                                                  }),
                                                variant: "plain",
                                                disabled: offset === 0,
                                                children: Caml_option.some(JsxRuntime.jsx(ChevronLeft, {}))
                                              }),
                                          JsxRuntime.jsx(Typography, {
                                                level: "body-xs",
                                                children: Caml_option.some(props.stringFromOffset(offset, totalCount))
                                              }),
                                          JsxRuntime.jsx(IconButton, {
                                                onClick: (function (param) {
                                                    onLoadNext();
                                                  }),
                                                variant: "plain",
                                                disabled: (offset + props.custPerPage | 0) >= totalCount,
                                                children: Caml_option.some(JsxRuntime.jsx(ChevronRight, {}))
                                              })
                                        ]
                                      })
                                ]
                              })
                        ]
                      }))
            });
}

var make = CustTableToolbar;

exports.make = make;
/* Joy-Util Not a pure module */
