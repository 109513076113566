// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Dialog$Thick = require("../common/molecules/Dialog.bs.js");
var Stack = require("@mui/joy/Stack").default;
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var Button = require("@mui/joy/Button").default;
var RemoteData$Util = require("util/src/RemoteData.bs.js");
var HopperState$Util = require("util/src/HopperState.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var CustomersState$Thick = require("../../utils/CustomersState.bs.js");
var DialogActions = require("@mui/joy/DialogActions").default;
var DialogContent = require("@mui/joy/DialogContent").default;

function ResendEmailVerificationForm$Content(props) {
  var form = props.form;
  var match = HopperState$Util.Observable.useComputed(form, (function (param) {
          var resendEmailVerificationMutation = param.resendEmailVerificationMutation;
          var match = param.targetUser;
          var tmp;
          tmp = typeof resendEmailVerificationMutation !== "object" || resendEmailVerificationMutation.TAG !== "Failure" ? undefined : resendEmailVerificationMutation._0;
          return [
                  match.accountId,
                  match.fullName,
                  match.email,
                  tmp,
                  RemoteData$Util.isLoading(resendEmailVerificationMutation),
                  RemoteData$Util.isSuccess(resendEmailVerificationMutation)
                ];
        }), undefined, undefined);
  var showSuccess = match[5];
  var isMutating = match[4];
  var mutationError = match[3];
  var fullName = match[1];
  var accountId = match[0];
  var mut = RemoteData$Util.Relay.useMutationWithNotify(CustomersState$Thick.ResendEmailVerificationMutation.use, (function (response) {
          var match = response.resendEmailVerification;
          if (match === undefined) {
            return {
                    TAG: "Failure",
                    _0: "Resending this invitation failed."
                  };
          }
          var match$1 = match.didSucceed;
          if (match$1 !== undefined && match$1) {
            return {
                    TAG: "Success",
                    _0: undefined
                  };
          } else {
            return {
                    TAG: "Failure",
                    _0: "Resending this invitation failed."
                  };
          }
        }), (function (res) {
          HopperState$Util.Observable.notify(form, (function (v) {
                  return {
                          targetUser: v.targetUser,
                          resendEmailVerificationMutation: res
                        };
                }));
        }));
  var mutate = function () {
    mut({
          input: {
            _accountId: accountId
          }
        }, undefined, undefined, undefined, undefined);
  };
  return Belt_Option.mapWithDefault(match[2], "Error: user with no email", (function (email) {
                return JsxRuntime.jsxs(JsxRuntime.Fragment, {
                            children: [
                              JsxRuntime.jsx(DialogContent, {
                                    children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                              spacing: 2,
                                              children: [
                                                JsxRuntime.jsx(Typography, {
                                                      textColor: "text.tertiary",
                                                      children: Caml_option.some("Resend email verification to " + fullName + " (" + CS_Emails$Util.Email.toString(email) + ").\n            ")
                                                    }),
                                                JsxRuntime.jsx(Typography, {
                                                      color: "danger",
                                                      children: "Please Note: This will invalidate the previous email verification email\n            link the user received."
                                                    }),
                                                mutationError !== undefined ? JsxRuntime.jsx(Typography, {
                                                        color: "danger",
                                                        children: Caml_option.some(mutationError)
                                                      }) : null
                                              ]
                                            }))
                                  }),
                              JsxRuntime.jsx(DialogActions, {
                                    children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                              children: Caml_option.some(JsxRuntime.jsx(Button, {
                                                        loading: isMutating,
                                                        onClick: (function (param) {
                                                            mutate();
                                                          }),
                                                        color: "danger",
                                                        disabled: isMutating,
                                                        children: Caml_option.some(isMutating ? "Loading" : "Resend invitation")
                                                      }))
                                            }))
                                  }),
                              showSuccess ? JsxRuntime.jsx(DialogContent, {
                                      children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                                spacing: 2,
                                                children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                          sx: {
                                                            color: "green",
                                                            textAlign: "center"
                                                          },
                                                          children: "👍 Notification resent!"
                                                        }))
                                              }))
                                    }) : null
                            ]
                          });
              }));
}

var Content = {
  make: ResendEmailVerificationForm$Content
};

function ResendEmailVerificationForm(props) {
  var state = props.state;
  var match = HopperState$Util.Observable.useComputed(state, (function (param) {
          var resendEmailVerificationForm = param.resendEmailVerificationForm;
          return [
                  Belt_Option.isSome(resendEmailVerificationForm),
                  Belt_Option.mapWithDefault(resendEmailVerificationForm, "", (function (param) {
                          return "Resend email verification to " + param.targetUser.fullName;
                        }))
                ];
        }), undefined, undefined);
  var open_ = match[0];
  return JsxRuntime.jsx(Dialog$Thick.make, {
              open_: open_,
              onClose: (function () {
                  HopperState$Util.Observable.notify(state, (function (m) {
                          return {
                                  custForm: m.custForm,
                                  convertToPrefilled: m.convertToPrefilled,
                                  listFilters: m.listFilters,
                                  offset: m.offset,
                                  resendEmailVerificationForm: undefined,
                                  url: m.url
                                };
                        }));
                }),
              title: match[1],
              children: open_ ? JsxRuntime.jsx(ResendEmailVerificationForm$Content, {
                      form: HopperState$Util.Observable.makeExnOptionView(HopperState$Util.Observable.makeView(state, {
                                read: (function (m) {
                                    return m.resendEmailVerificationForm;
                                  }),
                                write: (function (m, resendEmailVerificationForm) {
                                    return {
                                            custForm: m.custForm,
                                            convertToPrefilled: m.convertToPrefilled,
                                            listFilters: m.listFilters,
                                            offset: m.offset,
                                            resendEmailVerificationForm: resendEmailVerificationForm,
                                            url: m.url
                                          };
                                  })
                              }, undefined))
                    }) : null
            });
}

var make = ResendEmailVerificationForm;

exports.Content = Content;
exports.make = make;
/* Dialog-Thick Not a pure module */
