// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CS_Make$Util = require("util/src/CustomScalars/CS_Make.bs.js");
var Common$Thick = require("../utils/Common.bs.js");
var Menu = require("@mui/joy/Menu").default;
var BSForms$Thick = require("../components/booking-sessions/BSForms.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var DateOnly$Util = require("util/src/DateOnly.bs.js");
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var Table = require("@mui/joy/Table").default;
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var Tooltip = require("@mui/joy/Tooltip").default;
var ErrorModal$Thick = require("../components/booking-sessions/ErrorModal.bs.js");
var HopperState$Util = require("util/src/HopperState.bs.js");
var Dropdown = require("@mui/joy/Dropdown").default;
var MenuItem = require("@mui/joy/MenuItem").default;
var JsxRuntime = require("react/jsx-runtime");
var IconButton = require("@mui/joy/IconButton").default;
var MenuButton = require("@mui/joy/MenuButton").default;
var Typography = require("@mui/joy/Typography").default;
var BSTableToolbar$Thick = require("../components/booking-sessions/BSTableToolbar.bs.js");
var BookingSession$Thick = require("../utils/BookingSession.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var DeleteOrBookModal$Thick = require("../components/booking-sessions/DeleteOrBookModal.bs.js");
var CircularProgress = require("@mui/joy/CircularProgress").default;
var MoreHoriz = require("@mui/icons-material/MoreHoriz").default;
var SendOutlined = require("@mui/icons-material/SendOutlined").default;
var PersonOutlined = require("@mui/icons-material/PersonOutlined").default;
var CS_LoyaltyAndCruiselineNumbers$Util = require("util/src/CustomScalars/CS_LoyaltyAndCruiselineNumbers.bs.js");
var VisibilityOutlined = require("@mui/icons-material/VisibilityOutlined").default;
var SupervisorAccountOutlined = require("@mui/icons-material/SupervisorAccountOutlined").default;

function BookingSessionsPage$BSTable$BSMenu(props) {
  var openDeleteOrBookModal = props.openDeleteOrBookModal;
  var form = props.form;
  var row = props.row;
  var onClick = function (param) {
    HopperState$Util.Observable.notify(form, (function (o) {
            if (o !== undefined) {
              var obj = o.target;
              if (typeof obj === "object" && Caml_obj.equal(obj._0, row.bookingSessionId)) {
                return o;
              }
              
            }
            return BookingSession$Thick.blankForm({
                        TAG: "Edit",
                        _0: row.bookingSessionId
                      });
          }));
  };
  var bookSession_sessId = row.bookingSessionId;
  var bookSession_bcId = row.billingContactAccountId;
  var bookSession_fcSlug = row.fareClassSlug;
  var bookSession = {
    sessId: bookSession_sessId,
    bcId: bookSession_bcId,
    fcSlug: bookSession_fcSlug
  };
  return JsxRuntime.jsx(JsxRuntime.Fragment, {
              children: Caml_option.some(JsxRuntime.jsxs(Dropdown, {
                        children: [
                          JsxRuntime.jsx(MenuButton, {
                                children: Caml_option.some(JsxRuntime.jsx(MoreHoriz, {})),
                                size: "sm",
                                slotProps: {
                                  root: {
                                    color: "neutral"
                                  }
                                },
                                slots: {
                                  root: IconButton
                                }
                              }),
                          JsxRuntime.jsxs(Menu, {
                                children: [
                                  JsxRuntime.jsx(MenuItem, {
                                        children: "Change Booking Session",
                                        onClick: onClick
                                      }),
                                  JsxRuntime.jsx(MenuItem, {
                                        children: "Delete Booking Session",
                                        onClick: (function (param) {
                                            openDeleteOrBookModal({
                                                  TAG: "Delete",
                                                  _0: row.bookingSessionId
                                                });
                                          })
                                      }),
                                  JsxRuntime.jsx(MenuItem, {
                                        children: "Validate Booking",
                                        onClick: (function (param) {
                                            openDeleteOrBookModal({
                                                  TAG: "Book",
                                                  _0: bookSession
                                                });
                                          })
                                      })
                                ],
                                size: "sm"
                              })
                        ]
                      }))
            });
}

var BSMenu = {
  make: BookingSessionsPage$BSTable$BSMenu
};

function getPaxList(r) {
  var n = r.forOccupancy - r.passenger.nodes.length | 0;
  return Belt_Array.concat(Belt_Array.map(r.passenger.nodes, (function (p) {
                    var match = p.bookingSessionPassengerType;
                    var tmp;
                    if (match === "NEW_INVITATION" || match === "EXISTING_USER" || match === "NEW_CONNECTED_USER") {
                      switch (match) {
                        case "EXISTING_USER" :
                            tmp = JsxRuntime.jsx(PersonOutlined, {});
                            break;
                        case "NEW_INVITATION" :
                            tmp = JsxRuntime.jsx(SendOutlined, {});
                            break;
                        case "NEW_CONNECTED_USER" :
                            tmp = JsxRuntime.jsx(SupervisorAccountOutlined, {});
                            break;
                        
                      }
                    } else {
                      tmp = null;
                    }
                    var match$1 = p.bookingSessionPassengerType;
                    var tmp$1;
                    if (match$1 === "NEW_INVITATION" || match$1 === "EXISTING_USER" || match$1 === "NEW_CONNECTED_USER") {
                      switch (match$1) {
                        case "EXISTING_USER" :
                            var match$2 = p.existing_user;
                            tmp$1 = match$2 !== undefined ? match$2.fullName + " (" + CS_LoyaltyAndCruiselineNumbers$Util.LoyaltyNumber.toString(match$2.loyaltyNumber) + ")" : "";
                            break;
                        case "NEW_INVITATION" :
                            tmp$1 = Belt_Option.mapWithDefault(p.newPreferredFirstName, "", CS_NonemptyStrings$Util.NonEmptyString.toString) + Belt_Option.mapWithDefault(p.newPreferredLastName, "", (function (v) {
                                    return " " + CS_NonemptyStrings$Util.NonEmptyString.toString(v);
                                  })) + "  (" + Belt_Option.mapWithDefault(p.invitationEmail, "", CS_Emails$Util.Email.toString) + ")";
                            break;
                        case "NEW_CONNECTED_USER" :
                            tmp$1 = Belt_Option.mapWithDefault(p.newPreferredFirstName, Belt_Option.mapWithDefault(p.newDocFirstname, "", CS_NonemptyStrings$Util.NonEmptyString.toString), (function (v) {
                                    return CS_NonemptyStrings$Util.NonEmptyString.toString(v);
                                  })) + Belt_Option.mapWithDefault(p.newPreferredLastName, Belt_Option.mapWithDefault(p.newDocLastname, "", CS_NonemptyStrings$Util.NonEmptyString.toString), (function (v) {
                                    return " " + CS_NonemptyStrings$Util.NonEmptyString.toString(v);
                                  })) + " " + Belt_Option.mapWithDefault(p.newDocBirthdate, "", (function (bd) {
                                    return "(" + DateOnly$Util.format(bd, "MM/dd/yyyy") + ")";
                                  })) + ")";
                            break;
                        
                      }
                    } else {
                      tmp$1 = "";
                    }
                    return JsxRuntime.jsx(Typography, {
                                startDecorator: Caml_option.some(tmp),
                                noWrap: true,
                                component: "span",
                                size: "sm",
                                children: Caml_option.some(tmp$1)
                              });
                  })), n !== 0 ? [JsxRuntime.jsx(Typography, {
                      noWrap: true,
                      component: "span",
                      size: "sm",
                      sx: {
                        fontStyle: "italic"
                      },
                      children: Caml_option.some("TBA x" + String(n))
                    }, "tba-" + String(n))] : []);
}

function getCabinTooltipElement(r) {
  var match = r.cabin;
  if (match === undefined) {
    return CS_NonemptyStrings$Util.CabinNumber.toEl(r.cabinNumber);
  }
  var voyageSubclassSlug = match.voyageSubclassSlug;
  var match$1 = Common$Thick.Subclass.isDefault(voyageSubclassSlug);
  return JsxRuntime.jsxs(Stack, {
              direction: "column",
              children: [
                JsxRuntime.jsxs(Typography, {
                      size: "sm",
                      children: [
                        JsxRuntime.jsx(Typography, {
                              component: "span",
                              fontWeight: "bold",
                              children: "Class: "
                            }),
                        JsxRuntime.jsx(Typography, {
                              component: "span",
                              children: Caml_option.some(CS_Slugs$Util.CabinClassSlug.toEl(match.voyageClassSlug))
                            })
                      ]
                    }),
                match$1 ? null : JsxRuntime.jsxs(Typography, {
                        size: "sm",
                        children: [
                          JsxRuntime.jsx(Typography, {
                                component: "span",
                                fontWeight: "bold",
                                children: "Subclass: "
                              }),
                          JsxRuntime.jsx(Typography, {
                                component: "span",
                                children: Caml_option.some(CS_Slugs$Util.CabinSubclassSlug.toEl(voyageSubclassSlug))
                              })
                        ]
                      }),
                JsxRuntime.jsxs(Typography, {
                      size: "sm",
                      children: [
                        JsxRuntime.jsx(Typography, {
                              component: "span",
                              fontWeight: "bold",
                              children: "Segment: "
                            }),
                        JsxRuntime.jsx(Typography, {
                              component: "span",
                              children: Caml_option.some(match.voyageSegment)
                            })
                      ]
                    })
              ]
            });
}

function BookingSessionsPage$BSTable$Row(props) {
  var row = props.row;
  var match = row.cabin;
  var cabinText = match !== undefined ? CS_Slugs$Util.CabinCategorySlug.toString(match.voyageCategorySlug).toUpperCase() + " " + CS_NonemptyStrings$Util.CabinNumber.toString(row.cabinNumber) : CS_NonemptyStrings$Util.CabinNumber.toString(row.cabinNumber);
  var paxList = getPaxList(row);
  var email = row.teamUserCreatorEmail;
  var note = Belt_Option.map(row.descriptiveNote, CS_NonemptyStrings$Util.NonEmptyString.toString);
  var match$1 = row.billing_contact;
  var tmp;
  if (match$1 !== undefined) {
    var email$1 = match$1.email;
    tmp = email$1 !== undefined ? match$1.fullName + " (" + CS_Emails$Util.Email.toString(Caml_option.valFromOption(email$1)) + ")" : null;
  } else {
    tmp = null;
  }
  var match$2 = row.billing_contact;
  var tmp$1;
  if (match$2 !== undefined) {
    var email$2 = match$2.email;
    tmp$1 = email$2 !== undefined ? match$2.fullName + " (" + CS_Emails$Util.Email.toString(Caml_option.valFromOption(email$2)) + ")" : "";
  } else {
    tmp$1 = "";
  }
  var upd = row.updatedAt;
  return JsxRuntime.jsxs("tr", {
              children: [
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(BookingSessionsPage$BSTable$BSMenu, {
                            row: row,
                            form: props.form,
                            openDeleteOrBookModal: props.openDeleteOrBookModal
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Tooltip, {
                            children: JsxRuntime.jsx(Typography, {
                                  size: "sm",
                                  sx: {
                                    width: "fit-content",
                                    cursor: "pointer"
                                  },
                                  children: Caml_option.some(row.isBfcustFacing ? "✅" : "❌")
                                }),
                            arrow: true,
                            size: "sm",
                            title: Caml_option.some(email !== undefined ? "Created by " + CS_Emails$Util.Email.toString(Caml_option.valFromOption(email)) : "Created by customer")
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: note !== undefined ? JsxRuntime.jsx(Joy$Util.TextOverflow.make, {
                              value: note
                            }) : null
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Tooltip, {
                            children: JsxRuntime.jsx(Typography, {
                                  noWrap: true,
                                  sx: {
                                    cursor: "pointer"
                                  },
                                  children: Caml_option.some(tmp)
                                }),
                            arrow: true,
                            size: "sm",
                            title: Caml_option.some(tmp$1)
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Tooltip, {
                            children: JsxRuntime.jsx(Typography, {
                                  noWrap: true,
                                  sx: {
                                    cursor: "pointer"
                                  },
                                  children: Caml_option.some(cabinText)
                                }),
                            arrow: true,
                            size: "sm",
                            title: Caml_option.some(getCabinTooltipElement(row))
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Typography, {
                            noWrap: true,
                            children: Caml_option.some(CS_Slugs$Util.FareClassSlug.toEl(row.fareClassSlug))
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Typography, {
                            noWrap: true,
                            children: Caml_option.some(Belt_Array.mapWithIndex(paxList, (function (i, el) {
                                        return JsxRuntime.jsxs(React.Fragment, {
                                                    children: [
                                                      el,
                                                      i < (paxList.length - 1 | 0) ? JsxRuntime.jsx("br", {}) : null
                                                    ]
                                                  }, String(i));
                                      })))
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Typography, {
                            noWrap: true,
                            children: Caml_option.some(CS_Slugs$Util.BookingChannelSlug.toEl(row.bookingChannelSlug))
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: upd !== undefined ? JsxRuntime.jsx(Tooltip, {
                              children: JsxRuntime.jsx(Typography, {
                                    noWrap: true,
                                    children: Caml_option.some("Updated " + CS_Make$Util.Dateable.Plain.format(Caml_option.valFromOption(upd), "M/d/yyyy pp"))
                                  }),
                              arrow: true,
                              placement: "top",
                              size: "sm",
                              title: Caml_option.some("Created " + CS_Make$Util.Dateable.Plain.format(row.createdAt, "M/d/yyyy pp"))
                            }) : JsxRuntime.jsx(Typography, {
                              noWrap: true,
                              children: Caml_option.some("Created " + CS_Make$Util.Dateable.Plain.format(row.createdAt, "M/d/yyyy pp"))
                            })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Typography, {
                            noWrap: true,
                            children: Caml_option.some(row.pifIntention ? "Yes" : "No")
                          })
                    })
              ]
            });
}

var Row = {
  getPaxList: getPaxList,
  getCabinTooltipElement: getCabinTooltipElement,
  make: BookingSessionsPage$BSTable$Row
};

function BookingSessionsPage$BSTable(props) {
  var openDeleteOrBookModal = props.openDeleteOrBookModal;
  var form = props.form;
  return JsxRuntime.jsxs(Stack, {
              sx: {
                position: "relative"
              },
              children: [
                props.tableLoading ? JsxRuntime.jsx(Stack, {
                        sx: {
                          zIndex: 1,
                          position: "absolute",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          backgroundColor: "rgba(255, 255, 255, 0.5)"
                        },
                        children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                  sx: {
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)"
                                  },
                                  children: Caml_option.some(JsxRuntime.jsx(CircularProgress, {}))
                                }))
                      }) : null,
                JsxRuntime.jsxs(Table, {
                      borderAxis: "xBetween",
                      stripe: "odd",
                      hoverRow: true,
                      sx: {
                        "& tbody": {
                          bgcolor: "background.surface"
                        },
                        "& td": {
                          textAlign: "left"
                        },
                        "& th": {
                          textAlign: "left"
                        }
                      },
                      size: "sm",
                      children: [
                        JsxRuntime.jsx("thead", {
                              children: JsxRuntime.jsxs("tr", {
                                    children: [
                                      JsxRuntime.jsx("th", {
                                            style: {
                                              width: "40px"
                                            }
                                          }),
                                      JsxRuntime.jsx("th", {
                                            children: JsxRuntime.jsx(VisibilityOutlined, {
                                                  fontSize: "lg"
                                                }),
                                            style: {
                                              width: "40px"
                                            }
                                          }),
                                      JsxRuntime.jsx("th", {
                                            children: "Memo",
                                            style: {
                                              width: "120px"
                                            }
                                          }),
                                      JsxRuntime.jsx("th", {
                                            children: "Billing Contact"
                                          }),
                                      JsxRuntime.jsx("th", {
                                            children: "Cabin",
                                            style: {
                                              width: "70px"
                                            }
                                          }),
                                      JsxRuntime.jsx("th", {
                                            children: "Fare Class",
                                            style: {
                                              width: "70px"
                                            }
                                          }),
                                      JsxRuntime.jsx("th", {
                                            children: "Passengers"
                                          }),
                                      JsxRuntime.jsx("th", {
                                            children: "Channel",
                                            style: {
                                              width: "90px"
                                            }
                                          }),
                                      JsxRuntime.jsx("th", {
                                            children: "Updated"
                                          }),
                                      JsxRuntime.jsx("th", {
                                            children: "PIF?",
                                            style: {
                                              width: "40px"
                                            }
                                          })
                                    ]
                                  })
                            }),
                        JsxRuntime.jsx("tbody", {
                              children: Belt_Array.map(props.tableRows, (function (row) {
                                      return JsxRuntime.jsx(BookingSessionsPage$BSTable$Row, {
                                                  row: row.node,
                                                  form: form,
                                                  openDeleteOrBookModal: openDeleteOrBookModal
                                                }, row.node.id);
                                    }))
                            })
                      ]
                    })
              ]
            });
}

var BSTable = {
  BSMenu: BSMenu,
  Row: Row,
  make: BookingSessionsPage$BSTable
};

function BookingSessionsPage(props) {
  var sessionsPerPage = props.sessionsPerPage;
  var state = props.state;
  var filter = props.filter;
  var voyage = props.voyage;
  var brandFamily = props.brandFamily;
  var fragmentRefs = props.fragmentRefs;
  var match = BookingSession$Thick.Query.List.useRefetchable(fragmentRefs);
  var refetch = match[1];
  var currentList = match[0];
  var list = React.useDeferredValue(currentList);
  var tableLoading = Caml_obj.notequal(currentList, list);
  var refetchList = function (state, filter) {
    refetch(BookingSession$Thick.Filter.makeRefetchVars(HopperState$Util.Observable.dangerouslyRead(state), filter), "store-and-network", undefined);
  };
  var form = BookingSession$Thick.formView(state);
  var match$1 = list.bookingSessions;
  var totalCount = match$1 !== undefined ? match$1.totalCount : 0;
  var focusedSession = HopperState$Util.Observable.useComputed(state, (function (s) {
          return s.focusedSession;
        }), undefined, undefined);
  var closeDeleteOrBookModal = function (param) {
    HopperState$Util.Observable.notify(state, (function (s) {
            return {
                    form: s.form,
                    paginationCursor: s.paginationCursor,
                    url: s.url,
                    focusedSession: undefined,
                    saveError: s.saveError,
                    offset: s.offset,
                    searchTerm: s.searchTerm,
                    sessionCreator: s.sessionCreator,
                    bookingChannel: s.bookingChannel
                  };
          }));
  };
  var openDeleteOrBookModal = function (fs) {
    HopperState$Util.Observable.notify(state, (function (s) {
            return {
                    form: s.form,
                    paginationCursor: s.paginationCursor,
                    url: s.url,
                    focusedSession: fs,
                    saveError: s.saveError,
                    offset: s.offset,
                    searchTerm: s.searchTerm,
                    sessionCreator: s.sessionCreator,
                    bookingChannel: s.bookingChannel
                  };
          }));
  };
  var errorModalOpen = HopperState$Util.Observable.useComputed(state, (function (s) {
          return s.saveError;
        }), undefined, undefined);
  var openErrorModal = function (param) {
    HopperState$Util.Observable.notify(state, (function (s) {
            return {
                    form: s.form,
                    paginationCursor: s.paginationCursor,
                    url: s.url,
                    focusedSession: s.focusedSession,
                    saveError: true,
                    offset: s.offset,
                    searchTerm: s.searchTerm,
                    sessionCreator: s.sessionCreator,
                    bookingChannel: s.bookingChannel
                  };
          }));
  };
  var closeErrorModal = function (param) {
    HopperState$Util.Observable.notify(state, (function (s) {
            return {
                    form: s.form,
                    paginationCursor: s.paginationCursor,
                    url: s.url,
                    focusedSession: s.focusedSession,
                    saveError: false,
                    offset: s.offset,
                    searchTerm: s.searchTerm,
                    sessionCreator: s.sessionCreator,
                    bookingChannel: s.bookingChannel
                  };
          }));
  };
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(Stack, {
                      spacing: 1,
                      children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                spacing: 1,
                                children: Caml_option.some(JsxRuntime.jsxs(Sheet, {
                                          variant: "outlined",
                                          sx: {
                                            borderRadius: "4px",
                                            borderLeft: "1px solid",
                                            borderRight: "1px solid",
                                            borderTop: "1px solid",
                                            borderColor: "neutral.200"
                                          },
                                          children: [
                                            JsxRuntime.jsxs(Sheet, {
                                                  variant: "solid",
                                                  color: "neutral",
                                                  sx: {
                                                    px: 3,
                                                    py: 2,
                                                    borderTopLeftRadius: "4px",
                                                    borderTopRightRadius: "4px"
                                                  },
                                                  children: [
                                                    JsxRuntime.jsx(Typography, {
                                                          level: "title-lg",
                                                          textColor: "neutral.50",
                                                          children: "Booking Sessions"
                                                        }),
                                                    JsxRuntime.jsx(Typography, {
                                                          level: "body-sm",
                                                          textColor: "neutral.300",
                                                          children: Caml_option.some(CS_Slugs$Util.BrandFamilySlug.toString(brandFamily) + " | " + CS_Slugs$Util.VoyageSlug.toString(voyage))
                                                        })
                                                  ]
                                                }),
                                            JsxRuntime.jsx(BSTableToolbar$Thick.make, {
                                                  state: state,
                                                  form: form,
                                                  filter: filter,
                                                  totalCount: totalCount,
                                                  brandFamily: brandFamily,
                                                  voyage: voyage,
                                                  sessionsPerPage: sessionsPerPage,
                                                  refetch: refetch
                                                }),
                                            Belt_Option.mapWithDefault(list.bookingSessions, "No results", (function (rows) {
                                                    return JsxRuntime.jsx(BookingSessionsPage$BSTable, {
                                                                tableRows: rows.edges,
                                                                form: form,
                                                                tableLoading: tableLoading,
                                                                openDeleteOrBookModal: openDeleteOrBookModal
                                                              });
                                                  })),
                                            JsxRuntime.jsx(BSTableToolbar$Thick.make, {
                                                  state: state,
                                                  form: form,
                                                  filter: filter,
                                                  showSearch: false,
                                                  totalCount: totalCount,
                                                  brandFamily: brandFamily,
                                                  voyage: voyage,
                                                  sessionsPerPage: sessionsPerPage,
                                                  refetch: refetch
                                                })
                                          ]
                                        }))
                              }))
                    }),
                JsxRuntime.jsx(BSForms$Thick.CreateUpdateDrawer.make, {
                      brandFamily: brandFamily,
                      voyage: voyage,
                      fragmentRefs: list.fragmentRefs,
                      bcfcFragmentRef: fragmentRefs,
                      form: form,
                      refetchList: refetchList,
                      state: state,
                      filter: filter,
                      openErrorModal: openErrorModal
                    }),
                JsxRuntime.jsx(DeleteOrBookModal$Thick.make, {
                      open_: Belt_Option.isSome(focusedSession),
                      onClose: closeDeleteOrBookModal,
                      focusedSession: focusedSession,
                      brandFamily: brandFamily,
                      voyage: voyage,
                      refetchList: refetchList,
                      state: state,
                      filter: filter
                    }),
                JsxRuntime.jsx(ErrorModal$Thick.make, {
                      open_: errorModalOpen,
                      onClose: closeErrorModal
                    })
              ]
            });
}

var make = BookingSessionsPage;

exports.BSTable = BSTable;
exports.make = make;
/* react Not a pure module */
