// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var CS_Make$Util = require("util/src/CustomScalars/CS_Make.bs.js");
var CS_Phone$Util = require("util/src/CustomScalars/CS_Phone.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var CS_ISO3166_1$Util = require("util/src/CustomScalars/CS_ISO3166_1.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var CS_SQLDatesAndTimes$Util = require("util/src/CustomScalars/CS_SQLDatesAndTimes.bs.js");
var CS_LoyaltyAndCruiselineNumbers$Util = require("util/src/CustomScalars/CS_LoyaltyAndCruiselineNumbers.bs.js");

var Types = {};

var fragmentConverter = {"__root":{"residenceCountry":{"c":"Util.CustomScalars.ISO3166_1.Alpha3.Exn"},"postalCode":{"c":"Util.CustomScalars.PostalCode.Exn"},"phone":{"c":"Util.CustomScalars.Phone.Exn"},"passenger_record_nodes_voyageSlug":{"c":"Util.CustomScalars.VoyageSlug.Exn"},"passenger_record_nodes_bookingVersionNumber":{"b":""},"passenger_record_nodes_bookingId":{"c":"Util.CustomScalars.BookingId.Exn"},"loyalty_status_pendingVoyageSlugs":{"ca":"Util.CustomScalars.VoyageSlug.Exn"},"loyalty_status_pendingTierTitle":{"c":"Util.CustomScalars.NonemptyString.Exn"},"loyalty_status_numPendingVoyages":{"b":""},"loyalty_status_numEligibleVoyages":{"b":""},"loyalty_status_eligibleVoyageSlugs":{"ca":"Util.CustomScalars.VoyageSlug.Exn"},"loyalty_status_currentTierTitle":{"c":"Util.CustomScalars.NonemptyString.Exn"},"loyaltyNumber":{"c":"Util.CustomScalars.LoyaltyNumber.Exn"},"legalDocumentLastName":{"c":"Util.CustomScalars.NonemptyString.Exn"},"legalDocumentFirstName":{"c":"Util.CustomScalars.NonemptyString.Exn"},"legalDocumentCitizenship":{"c":"Util.CustomScalars.ISO3166_1.Alpha3.Exn"},"legalDocumentBirthdate":{"c":"Util.CustomScalars.SQLDate.Exn"},"lastName":{"c":"Util.CustomScalars.NonemptyString.Exn"},"invitationExpiry":{"c":"Util.CustomScalars.SQLDateTime.Exn"},"invitationEmail":{"c":"Util.CustomScalars.Email.Exn"},"halMarinerNumber":{"c":"Util.CustomScalars.HalMarinerNumber.Exn"},"firstName":{"c":"Util.CustomScalars.NonemptyString.Exn"},"email":{"c":"Util.CustomScalars.Email.Exn"},"custodianAccountId":{"c":"Util.CustomScalars.AccountId.Exn"},"custAcquisitionSlug":{"c":"Util.CustomScalars.Slug.Exn"},"accountId":{"c":"Util.CustomScalars.AccountId.Exn"}}};

var fragmentConverterMap = {
  "Util.CustomScalars.AccountId.Exn": CS_NonemptyStrings$Util.AccountId.Exn.parse,
  "Util.CustomScalars.Slug.Exn": CS_Slugs$Util.Slug.Exn.parse,
  "Util.CustomScalars.Email.Exn": CS_Emails$Util.Email.Exn.parse,
  "Util.CustomScalars.NonemptyString.Exn": CS_NonemptyStrings$Util.NonEmptyString.Exn.parse,
  "Util.CustomScalars.HalMarinerNumber.Exn": CS_LoyaltyAndCruiselineNumbers$Util.HalMarinerNumber.Exn.parse,
  "Util.CustomScalars.SQLDateTime.Exn": CS_Make$Util.Dateable.Plain.Exn.parse,
  "Util.CustomScalars.SQLDate.Exn": CS_SQLDatesAndTimes$Util.DateOnly.Exn.parse,
  "Util.CustomScalars.ISO3166_1.Alpha3.Exn": CS_ISO3166_1$Util.Alpha3.Exn.parse,
  "Util.CustomScalars.LoyaltyNumber.Exn": CS_LoyaltyAndCruiselineNumbers$Util.LoyaltyNumber.Exn.parse,
  "Util.CustomScalars.VoyageSlug.Exn": CS_Slugs$Util.VoyageSlug.Exn.parse,
  "Util.CustomScalars.BookingId.Exn": CS_NonemptyStrings$Util.BookingId.Exn.parse,
  "Util.CustomScalars.Phone.Exn": CS_Phone$Util.PhoneImpl.Exn.parse,
  "Util.CustomScalars.PostalCode.Exn": CS_NonemptyStrings$Util.PostalCode.Exn.parse
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

function accountStatus_decode($$enum) {
  if ($$enum === "UNCLAIMED" || $$enum === "TRANSFERRING" || $$enum === "INVITED" || $$enum === "PREFILLED" || $$enum === "CLAIMED" || $$enum === "DELETED") {
    return $$enum;
  }
  
}

function accountStatus_fromString(str) {
  return accountStatus_decode(str);
}

function docGender_decode($$enum) {
  if ($$enum === "F" || $$enum === "M" || $$enum === "X") {
    return $$enum;
  }
  
}

function docGender_fromString(str) {
  return docGender_decode(str);
}

var Utils = {
  accountStatus_decode: accountStatus_decode,
  accountStatus_fromString: accountStatus_fromString,
  docGender_decode: docGender_decode,
  docGender_fromString: docGender_fromString
};

var node = ((function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CustomerTable_user",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phone",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "residenceCountry",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "postalCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "halMarinerNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "legalDocumentFirstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "legalDocumentLastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "legalDocumentGender",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "legalDocumentBirthdate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "legalDocumentCitizenship",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "custAcquisitionSlug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accountStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accountId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "custodianAccountId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "invitationEmail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "invitationExpiry",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "loyaltyNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BfcustUser",
      "kind": "LinkedField",
      "name": "custodian",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "bookingVersionLifecycleStatus": {
              "equalTo": "ACTIVE"
            }
          }
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": [
            "BOOKING_VERSION_NUMBER_DESC"
          ]
        }
      ],
      "concreteType": "PassengersConnection",
      "kind": "LinkedField",
      "name": "passenger_record",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Passenger",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "bookingVersionNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "bookingId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "voyageSlug",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "passenger_record(filter:{\"bookingVersionLifecycleStatus\":{\"equalTo\":\"ACTIVE\"}},orderBy:[\"BOOKING_VERSION_NUMBER_DESC\"])"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BfcustLoyaltyStatus",
      "kind": "LinkedField",
      "name": "loyalty_status",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currentTierTitle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pendingTierTitle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numPendingVoyages",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numEligibleVoyages",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pendingVoyageSlugs",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "eligibleVoyageSlugs",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BfcustUser",
  "abstractKey": null
};
})());

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
