// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var ConnectedToPrefilled_ConnectedToPrefilledMutation_graphql$Thick = require("../../../__generated__/ConnectedToPrefilled_ConnectedToPrefilledMutation_graphql.bs.js");

var convertVariables = ConnectedToPrefilled_ConnectedToPrefilledMutation_graphql$Thick.Internal.convertVariables;

var convertResponse = ConnectedToPrefilled_ConnectedToPrefilledMutation_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = ConnectedToPrefilled_ConnectedToPrefilledMutation_graphql$Thick.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables, ConnectedToPrefilled_ConnectedToPrefilledMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, ConnectedToPrefilled_ConnectedToPrefilledMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse);

var ConnectedToPrefilledMutation = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation(onSuccess) {
  var match = use();
  var mut = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setError = match$1[1];
  var match$2 = React.useState(function () {
        return false;
      });
  var setSuccess = match$2[1];
  var convert = function (i, state) {
    setError(function (param) {
          
        });
    mut({
          input: i
        }, undefined, undefined, undefined, (function (res, err) {
            if (err !== undefined) {
              console.error(err);
              setError(function (param) {
                    return "An error occurred converting this connected account to a prefilled one.";
                  });
              return ;
            }
            var match = res.connectedToPrefilled;
            if (match !== undefined) {
              var error = match.nullableErr;
              if (error !== undefined) {
                setError(function (param) {
                      return error;
                    });
                return ;
              }
              
            }
            setSuccess(function (param) {
                  return true;
                });
            onSuccess(state);
          }), (function (err) {
            setError(function (param) {
                  return err.message;
                });
          }), undefined);
  };
  return {
          loading: match[1],
          error: match$1[0],
          setError: setError,
          success: match$2[0],
          setSuccess: setSuccess,
          convert: convert
        };
}

exports.ConnectedToPrefilledMutation = ConnectedToPrefilledMutation;
exports.useMutation = useMutation;
/* commitMutation Not a pure module */
