// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var CS_Make$Util = require("util/src/CustomScalars/CS_Make.bs.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");

var Types = {};

var fragmentConverter = {"__root":{"versionCreatorTeamUserEmail":{"c":"Util.CustomScalars.Email.Exn"},"versionCreatorBfcust_email":{"c":"Util.CustomScalars.Email.Exn"},"versionCreatedAt":{"c":"Util.CustomScalars.SQLDateTime.Exn"},"versionComment":{"c":"Util.CustomScalars.NonemptyString.Exn"},"bookingVersionNumber":{"b":""},"bookingId":{"c":"Util.CustomScalars.BookingId.Exn"},"bookingCreatedAt":{"c":"Util.CustomScalars.SQLDateTime.Exn"}}};

var fragmentConverterMap = {
  "Util.CustomScalars.SQLDateTime.Exn": CS_Make$Util.Dateable.Plain.Exn.parse,
  "Util.CustomScalars.BookingId.Exn": CS_NonemptyStrings$Util.BookingId.Exn.parse,
  "Util.CustomScalars.NonemptyString.Exn": CS_NonemptyStrings$Util.NonEmptyString.Exn.parse,
  "Util.CustomScalars.Email.Exn": CS_Emails$Util.Email.Exn.parse
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

function lifecycleStatus_decode($$enum) {
  if ($$enum === "CANCELED" || $$enum === "STAGED" || $$enum === "DRAFT" || $$enum === "ACTIVE" || $$enum === "HISTORY") {
    return $$enum;
  }
  
}

function lifecycleStatus_fromString(str) {
  return lifecycleStatus_decode(str);
}

function versionProvenance_decode($$enum) {
  if ($$enum === "PAY_IN_FULL_COMPLETION" || $$enum === "CLAIM_NEW_PASSENGER_INVITATION" || $$enum === "ACCOUNT_UPDATE" || $$enum === "BOOKING_CHANGE_INDIRECT" || $$enum === "BOOKING_CHANGE" || $$enum === "BOOKED" || $$enum === "MIGRATED") {
    return $$enum;
  }
  
}

function versionProvenance_fromString(str) {
  return versionProvenance_decode(str);
}

var Utils = {
  lifecycleStatus_decode: lifecycleStatus_decode,
  lifecycleStatus_fromString: lifecycleStatus_fromString,
  versionProvenance_decode: versionProvenance_decode,
  versionProvenance_fromString: versionProvenance_fromString
};

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BDHero_version",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bookingId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lifecycleStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bookingVersionNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "versionComment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "versionProvenance",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bookingCreatedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "versionCreatedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "versionCreatorTeamUserEmail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BfcustUser",
      "kind": "LinkedField",
      "name": "versionCreatorBfcust",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BookingVersion",
  "abstractKey": null
};

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* CS_Make-Util Not a pure module */
