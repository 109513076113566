// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");

var Types = {};

var fragmentConverter = {"__root":{"placementComment":{"c":"Util.CustomScalars.NonemptyString.Exn"},"occRange_start_value":{"b":""},"occRange_end_value":{"b":""},"numBerths":{"b":""},"fareClassSlug":{"c":"Util.CustomScalars.FareClassSlug.Exn"},"cabin_voyageSubclassSlug":{"c":"Util.CustomScalars.CabinSubclassSlug.Exn"},"cabin_voyageClassSlug":{"c":"Util.CustomScalars.CabinClassSlug.Exn"},"cabin_voyageCategorySlug":{"c":"Util.CustomScalars.CabinCategorySlug.Exn"},"cabin_deckNumber":{"c":"Util.CustomScalars.DeckNumber.Exn"},"cabinNumber":{"c":"Util.CustomScalars.CabinNumber.Exn"}}};

var fragmentConverterMap = {
  "Util.CustomScalars.DeckNumber.Exn": CS_NonemptyStrings$Util.DeckNumber.Exn.parse,
  "Util.CustomScalars.CabinCategorySlug.Exn": CS_Slugs$Util.CabinCategorySlug.Exn.parse,
  "Util.CustomScalars.CabinClassSlug.Exn": CS_Slugs$Util.CabinClassSlug.Exn.parse,
  "Util.CustomScalars.CabinSubclassSlug.Exn": CS_Slugs$Util.CabinSubclassSlug.Exn.parse,
  "Util.CustomScalars.CabinNumber.Exn": CS_NonemptyStrings$Util.CabinNumber.Exn.parse,
  "Util.CustomScalars.FareClassSlug.Exn": CS_Slugs$Util.FareClassSlug.Exn.parse,
  "Util.CustomScalars.NonemptyString.Exn": CS_NonemptyStrings$Util.NonEmptyString.Exn.parse
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

var Utils = {};

var node = ((function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "inclusive",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BDCabin_version",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cabinNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numBerths",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "finalPlacement",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "placementComment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Cabin",
      "kind": "LinkedField",
      "name": "cabin",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "voyageClassSlug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "voyageCategorySlug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "voyageSubclassSlug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deckNumber",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "IntRange",
      "kind": "LinkedField",
      "name": "occRange",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "IntRangeBound",
          "kind": "LinkedField",
          "name": "start",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "IntRangeBound",
          "kind": "LinkedField",
          "name": "end",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fareClassSlug",
      "storageKey": null
    }
  ],
  "type": "BookingVersion",
  "abstractKey": null
};
})());

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
