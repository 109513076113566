// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CS_Decimal$Util = require("util/src/CustomScalars/CS_Decimal.bs.js");
var BillPayInfo$Thick = require("../billing-payments/BillPayInfo.bs.js");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var RecordFccPayment_RecordFccPaymentMutation_graphql$Thick = require("../../../__generated__/RecordFccPayment_RecordFccPaymentMutation_graphql.bs.js");

var convertVariables = RecordFccPayment_RecordFccPaymentMutation_graphql$Thick.Internal.convertVariables;

var convertResponse = RecordFccPayment_RecordFccPaymentMutation_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = RecordFccPayment_RecordFccPaymentMutation_graphql$Thick.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables, RecordFccPayment_RecordFccPaymentMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, RecordFccPayment_RecordFccPaymentMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse);

var RecordFccPaymentMutation_pmtLifecycleStatus_decode = RecordFccPayment_RecordFccPaymentMutation_graphql$Thick.Utils.pmtLifecycleStatus_decode;

var RecordFccPaymentMutation_pmtLifecycleStatus_fromString = RecordFccPayment_RecordFccPaymentMutation_graphql$Thick.Utils.pmtLifecycleStatus_fromString;

var RecordFccPaymentMutation_pmtMethod_decode = RecordFccPayment_RecordFccPaymentMutation_graphql$Thick.Utils.pmtMethod_decode;

var RecordFccPaymentMutation_pmtMethod_fromString = RecordFccPayment_RecordFccPaymentMutation_graphql$Thick.Utils.pmtMethod_fromString;

var RecordFccPaymentMutation = {
  pmtLifecycleStatus_decode: RecordFccPaymentMutation_pmtLifecycleStatus_decode,
  pmtLifecycleStatus_fromString: RecordFccPaymentMutation_pmtLifecycleStatus_fromString,
  pmtMethod_decode: RecordFccPaymentMutation_pmtMethod_decode,
  pmtMethod_fromString: RecordFccPaymentMutation_pmtMethod_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation(_brandFamilySlug, _voyageSlug, _bookingId, _bookingVersionNumber, onClose, refetch) {
  var match = React.useState(function () {
        
      });
  var setError = match[1];
  var match$1 = use();
  var mutate = match$1[0];
  var recordFccPayment = function (param) {
    mutate({
          input: {
            _brandFamilySlug: _brandFamilySlug,
            _voyageSlug: _voyageSlug,
            _bookingId: _bookingId,
            _bookingVersionNumber: _bookingVersionNumber,
            _pmtMethod: "FCC",
            _payer: param._payer,
            _netAmount: param._netAmount,
            _bfFeeAmount: param._bfFeeAmount,
            _lifecycleStatus: param._lifecycleStatus,
            _pmtMethodIdentifier: param._pmtMethodIdentifier,
            _pmtNote: param._pmtNote
          }
        }, undefined, undefined, undefined, (function (resp, err) {
            if (err === undefined) {
              var match = resp.recordManualPayment;
              if (match !== undefined) {
                var match$1 = match.boolean;
                if (match$1 !== undefined && match$1) {
                  console.log("Successfully recorded fcc payment");
                  refetch();
                  onClose();
                  setError(function (param) {
                        
                      });
                  return ;
                }
                
              }
              setError(function (param) {
                    return "Failed to record fcc payment";
                  });
              return ;
            }
            if (err !== undefined) {
              var error = Belt_Array.reduce(err, "", (function (carry, obj) {
                      if (carry === "") {
                        return obj.message;
                      } else {
                        return carry + ", " + obj.message;
                      }
                    }));
              console.log(error);
              setError(function (param) {
                    return "Error recording fcc payment";
                  });
              return ;
            }
            setError(function (param) {
                  return "Error recording fcc payment";
                });
          }), (function (err) {
            console.log(err);
            setError(function (param) {
                  return "A server error occurred while recording fcc payment";
                });
          }), undefined);
  };
  return {
          error: match[0],
          setError: setError,
          isMutating: match$1[1],
          recordFccPayment: recordFccPayment
        };
}

function fccIntermToFccRecord(i) {
  var match = i.payer;
  var match$1 = Belt_Option.map(i.netAmount, (function (str) {
          return CS_Decimal$Util.Decimal10_2.fromPrimitive(BillPayInfo$Thick.Utils.padIfNoDecimal(str));
        }));
  var match$2 = i.lifecycleStatus;
  var match$3 = Belt_Option.map(i.fccReference, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive);
  var match$4 = Belt_Option.map(i.comment, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive);
  if (match === undefined) {
    return {
            TAG: "Error",
            _0: "Invalid input"
          };
  }
  if (match$1 === undefined) {
    return {
            TAG: "Error",
            _0: "Invalid input"
          };
  }
  if (match$1.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "Invalid input"
          };
  }
  if (match$2 === undefined) {
    return {
            TAG: "Error",
            _0: "Invalid input"
          };
  }
  if (match$3 === undefined) {
    return {
            TAG: "Error",
            _0: "Invalid input"
          };
  }
  if (match$3.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "Invalid input"
          };
  }
  if (match$4 === undefined) {
    return {
            TAG: "Error",
            _0: "Invalid input"
          };
  }
  if (match$4.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "Invalid input"
          };
  }
  var match$5 = Belt_Option.map(i.bfFeeAmount, (function (str) {
          return CS_Decimal$Util.Decimal10_2.fromPrimitive(BillPayInfo$Thick.Utils.padIfNoDecimal(str));
        }));
  var tmp;
  tmp = match$5 !== undefined && match$5.TAG === "Ok" ? match$5._0 : CS_Decimal$Util.Decimal10_2.Exn.fromPrimitive("0.00");
  return {
          TAG: "Ok",
          _0: {
            _payer: Caml_option.valFromOption(match),
            _netAmount: match$1._0,
            _bfFeeAmount: tmp,
            _lifecycleStatus: match$2,
            _pmtMethodIdentifier: match$3._0,
            _pmtNote: match$4._0
          }
        };
}

var Utils_empty = {
  payer: undefined,
  netAmount: undefined,
  bfFeeAmount: undefined,
  lifecycleStatus: undefined,
  fccReference: undefined,
  comment: undefined
};

var Utils = {
  empty: Utils_empty,
  fccIntermToFccRecord: fccIntermToFccRecord
};

exports.RecordFccPaymentMutation = RecordFccPaymentMutation;
exports.useMutation = useMutation;
exports.Utils = Utils;
/* commitMutation Not a pure module */
