// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CS_Make$Util = require("util/src/CustomScalars/CS_Make.bs.js");
var Common$Thick = require("../../../utils/Common.bs.js");
var CS_Decimal$Util = require("util/src/CustomScalars/CS_Decimal.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var BookingDetailsRouteQuery_graphql$Thick = require("../../../__generated__/BookingDetailsRouteQuery_graphql.bs.js");

function getDepSched(data) {
  if (data === undefined) {
    return ;
  }
  var appliedArray = data.appliedPaymentPlan;
  if (appliedArray !== undefined) {
    return Belt_Array.keepMap(appliedArray, (function (app) {
                  if (app !== undefined) {
                    return {
                            periodStatus: app.periodStatus,
                            dueDate: app.period.activateAfter,
                            newAmountDue: app.newlyDueThisPeriod,
                            numericAmount: app.period.numericAmount,
                            paymentAmtType: app.period.paymentAmountType
                          };
                  }
                  
                }));
  }
  
}

function parse(v) {
  var zero = CS_Decimal$Util.Decimal10_2.Exn.fromPrimitive("0.00");
  switch (v.TAG) {
    case "Diff" :
        var diff = v._0;
        var lines = diff.quoteLines;
        var billingLines = lines !== undefined ? Belt_Array.map(Belt_Array.keepMap(lines, (function (n) {
                      if (n !== undefined) {
                        return n;
                      }
                      
                    })), (function (node) {
                  var id = node.passthruId;
                  var count = node.suppressedCount;
                  var count$1 = node.ruleApplicationCount;
                  var slug = node.fareRuleSlug;
                  var description = node.billingLineUnsuppressedDescription;
                  var match = node.hookHistory;
                  return {
                          billingIdx: node.billingIdx,
                          billingLineDescription: CS_NonemptyStrings$Util.NonEmptyString.toString(node.billingLineDescription),
                          lineAmount: node.lineAmount,
                          passthruId: id !== undefined ? CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(id)) : undefined,
                          isManualLine: node.billingKind === "MANUAL",
                          suppressedCount: count !== undefined ? count : 0,
                          ruleApplicationCount: count$1 !== undefined ? count$1 : 0,
                          unsuppressedAmount: node.unsuppressedAmount,
                          fareRuleSlug: slug !== undefined ? Caml_option.some(Caml_option.valFromOption(slug)) : undefined,
                          unsuppressedDescription: description !== undefined ? CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(description)) : undefined,
                          hookFiredAt: match !== undefined ? match.hookFiredAt : undefined,
                          subtotalCategory: node.subtotalCategory,
                          refundability: node.refundability
                        };
                })) : [];
        var paymentLines = Belt_Array.map(Belt_Array.keepMap(v._2, (function (n) {
                    if (n !== undefined) {
                      return n;
                    }
                    
                  })), (function (node) {
                var url = node.bfcustPmtImageUrl;
                return {
                        date: CS_Make$Util.Dateable.Plain.format(node.createdAt, "MM/dd/yyyy"),
                        payer: CS_NonemptyStrings$Util.AccountId.toString(node.payer),
                        payerAcct: node.payer,
                        lifecycleStatus: node.lifecycleStatus,
                        shouldRenderStatus: Common$Thick.PmtLifecycle.shouldRenderStatus(node.lifecycleStatus),
                        paymentMethod: Belt_Option.getWithDefault(Belt_Option.map(node.bfcustPmtMethodDescription, CS_NonemptyStrings$Util.NonEmptyString.toString), "Payment"),
                        paymentMethodImage: url !== undefined ? CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(url)) : undefined,
                        amount: node.netAmount,
                        processor: node.processor,
                        processorTransactionId: node.processorTransactionId,
                        pmtMethod: node.pmtMethod,
                        publicPmtId: node.publicPmtId,
                        bfcustPmtMethodIdentifier: node.bfcustPmtMethodIdentifier,
                        bfcustPmtSubtype: node.bfcustPmtSubtype,
                        pmtGroup: node.pmtGroup,
                        refundableAmt: node.refundableAmt,
                        refundableFeeAmt: node.refundableFeeAmt,
                        bfFeeAmount: node.bfFeeAmount
                      };
              }));
        var match = diff.billingAndPaymentTotals;
        var tmp;
        if (match !== undefined) {
          var amount = match.grossBilled;
          tmp = amount !== undefined ? Caml_option.valFromOption(amount) : zero;
        } else {
          tmp = zero;
        }
        var match$1 = diff.billingAndPaymentTotals;
        var tmp$1;
        if (match$1 !== undefined) {
          var amount$1 = match$1.netCompletedPaymentsAndRefunds;
          tmp$1 = amount$1 !== undefined ? Caml_option.valFromOption(amount$1) : zero;
        } else {
          tmp$1 = zero;
        }
        var match$2 = diff.billingAndPaymentTotals;
        var tmp$2;
        if (match$2 !== undefined) {
          var amount$2 = match$2.netPendingPayments;
          tmp$2 = amount$2 !== undefined ? Caml_option.valFromOption(amount$2) : zero;
        } else {
          tmp$2 = zero;
        }
        var match$3 = diff.billingAndPaymentTotals;
        var tmp$3;
        if (match$3 !== undefined) {
          var amount$3 = match$3.combinedBalanceWithPending;
          tmp$3 = amount$3 !== undefined ? Caml_option.valFromOption(amount$3) : zero;
        } else {
          tmp$3 = zero;
        }
        var match$4 = diff.billingAndPaymentTotals;
        var tmp$4;
        if (match$4 !== undefined) {
          var amount$4 = match$4.netPendingPayments;
          tmp$4 = amount$4 !== undefined ? CS_Decimal$Util.Decimal10_2.toFloat(Caml_option.valFromOption(amount$4)) > 0.0 : false;
        } else {
          tmp$4 = false;
        }
        var match$5 = diff.billingAndPaymentTotals;
        var tmp$5;
        if (match$5 !== undefined) {
          var amount$5 = match$5.combinedBalance;
          tmp$5 = amount$5 !== undefined ? Caml_option.valFromOption(amount$5) : zero;
        } else {
          tmp$5 = zero;
        }
        return {
                bookingId: v._1,
                billing: {
                  billingLines: billingLines,
                  billingLineSubtotal: tmp
                },
                payment: {
                  completedPaymentSubtotal: tmp$1,
                  pendingPaymentSubtotal: tmp$2,
                  paymentLines: paymentLines
                },
                totalAmountIncludingPending: tmp$3,
                arePendingPayments: tmp$4,
                totalAmount: tmp$5,
                depositSchedule: undefined
              };
    case "Current" :
        var data = v._0;
        var match$6 = data.billingSummary;
        var completedPaymentSubtotal = match$6 !== undefined ? match$6.netCompletedPaymentsAndRefunds : zero;
        var match$7 = data.billingSummary;
        var pendingPaymentSubtotal = match$7 !== undefined ? Belt_Option.getWithDefault(match$7.netPendingPayments, zero) : zero;
        var match$8 = data.billingSummary;
        var totalAmountIncludingPending = match$8 !== undefined ? match$8.combinedBalanceWithPending : zero;
        var match$9 = data.billingSummary;
        var billingLineSubtotal = match$9 !== undefined ? match$9.grossBilled : zero;
        var match$10 = data.billingSummary;
        var totalAmount = match$10 !== undefined ? match$10.combinedBalance : zero;
        var billingLines$1 = Belt_Array.map(data.billingLine.nodes, (function (node) {
                return {
                        billingIdx: node.billingIdx,
                        billingLineDescription: CS_NonemptyStrings$Util.NonEmptyString.toString(node.billingLineDescription),
                        lineAmount: node.lineAmount,
                        passthruId: undefined,
                        isManualLine: node.isManualLine,
                        suppressedCount: node.suppressedCount,
                        ruleApplicationCount: Belt_Option.getWithDefault(node.ruleApplicationCount, 0),
                        unsuppressedAmount: node.unsuppressedAmount,
                        fareRuleSlug: node.fareRuleSlug,
                        unsuppressedDescription: Belt_Option.map(node.billingLineUnsuppressedDescription, CS_NonemptyStrings$Util.NonEmptyString.toString),
                        hookFiredAt: node.originatedAt,
                        subtotalCategory: node.subtotalCategory,
                        refundability: node.refundability
                      };
              }));
        var paymentLines$1 = Belt_Array.map(data.payment.nodes, (function (node) {
                var match = node.payer_user;
                var url = node.bfcustPmtImageUrl;
                return {
                        date: CS_Make$Util.Dateable.Plain.format(node.createdAt, "MM/dd/yyyy"),
                        payer: match !== undefined ? match.fullName : CS_NonemptyStrings$Util.AccountId.toString(node.payer),
                        payerAcct: node.payer,
                        lifecycleStatus: node.lifecycleStatus,
                        shouldRenderStatus: Common$Thick.PmtLifecycle.shouldRenderStatus(node.lifecycleStatus),
                        paymentMethod: Belt_Option.getWithDefault(Belt_Option.map(node.bfcustPmtMethodDescription, CS_NonemptyStrings$Util.NonEmptyString.toString), "Payment"),
                        paymentMethodImage: url !== undefined ? CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(url)) : undefined,
                        amount: node.netAmount,
                        processor: node.processor,
                        processorTransactionId: node.processorTransactionId,
                        pmtMethod: node.pmtMethod,
                        publicPmtId: node.publicPmtId,
                        bfcustPmtMethodIdentifier: node.bfcustPmtMethodIdentifier,
                        bfcustPmtSubtype: node.bfcustPmtSubtype,
                        pmtGroup: node.pmtGroup,
                        refundableAmt: node.refundableAmt,
                        refundableFeeAmt: node.refundableFeeAmt,
                        bfFeeAmount: node.bfFeeAmount
                      };
              }));
        return {
                bookingId: v._1,
                billing: {
                  billingLines: billingLines$1,
                  billingLineSubtotal: billingLineSubtotal
                },
                payment: {
                  completedPaymentSubtotal: completedPaymentSubtotal,
                  pendingPaymentSubtotal: pendingPaymentSubtotal,
                  paymentLines: paymentLines$1
                },
                totalAmountIncludingPending: totalAmountIncludingPending,
                arePendingPayments: CS_Decimal$Util.Decimal10_2.toFloat(pendingPaymentSubtotal) > 0.0,
                totalAmount: totalAmount,
                depositSchedule: getDepSched(data.paymentPlan)
              };
    case "Proposed" :
        var diff$1 = v._0;
        var lines$1 = diff$1.quoteLines;
        var billingLines$2 = lines$1 !== undefined ? Belt_Array.map(Belt_Array.keepMap(lines$1, (function (n) {
                      if (n !== undefined) {
                        return n;
                      }
                      
                    })), (function (node) {
                  var id = node.passthruId;
                  var count = node.suppressedCount;
                  var count$1 = node.ruleApplicationCount;
                  var slug = node.fareRuleSlug;
                  var description = node.billingLineUnsuppressedDescription;
                  var match = node.hookHistory;
                  return {
                          billingIdx: node.billingIdx,
                          billingLineDescription: CS_NonemptyStrings$Util.NonEmptyString.toString(node.billingLineDescription),
                          lineAmount: node.lineAmount,
                          passthruId: id !== undefined ? CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(id)) : undefined,
                          isManualLine: node.billingKind === "MANUAL",
                          suppressedCount: count !== undefined ? count : 0,
                          ruleApplicationCount: count$1 !== undefined ? count$1 : 0,
                          unsuppressedAmount: node.unsuppressedAmount,
                          fareRuleSlug: slug !== undefined ? Caml_option.some(Caml_option.valFromOption(slug)) : undefined,
                          unsuppressedDescription: description !== undefined ? CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(description)) : undefined,
                          hookFiredAt: match !== undefined ? match.hookFiredAt : undefined,
                          subtotalCategory: node.subtotalCategory,
                          refundability: node.refundability
                        };
                })) : [];
        var paymentLines$2 = Belt_Array.map(Belt_Array.keepMap(v._2, (function (n) {
                    if (n !== undefined) {
                      return n;
                    }
                    
                  })), (function (node) {
                var url = node.bfcustPmtImageUrl;
                return {
                        date: CS_Make$Util.Dateable.Plain.format(node.createdAt, "MM/dd/yyyy"),
                        payer: CS_NonemptyStrings$Util.AccountId.toString(node.payer),
                        payerAcct: node.payer,
                        lifecycleStatus: node.lifecycleStatus,
                        shouldRenderStatus: Common$Thick.PmtLifecycle.shouldRenderStatus(node.lifecycleStatus),
                        paymentMethod: Belt_Option.getWithDefault(Belt_Option.map(node.bfcustPmtMethodDescription, CS_NonemptyStrings$Util.NonEmptyString.toString), "Payment"),
                        paymentMethodImage: url !== undefined ? CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(url)) : undefined,
                        amount: node.netAmount,
                        processor: node.processor,
                        processorTransactionId: node.processorTransactionId,
                        pmtMethod: node.pmtMethod,
                        publicPmtId: node.publicPmtId,
                        bfcustPmtMethodIdentifier: node.bfcustPmtMethodIdentifier,
                        bfcustPmtSubtype: node.bfcustPmtSubtype,
                        pmtGroup: node.pmtGroup,
                        refundableAmt: node.refundableAmt,
                        refundableFeeAmt: node.refundableFeeAmt,
                        bfFeeAmount: node.bfFeeAmount
                      };
              }));
        var match$11 = diff$1.billingAndPaymentTotals;
        var tmp$6;
        if (match$11 !== undefined) {
          var amount$6 = match$11.grossBilled;
          tmp$6 = amount$6 !== undefined ? Caml_option.valFromOption(amount$6) : zero;
        } else {
          tmp$6 = zero;
        }
        var match$12 = diff$1.billingAndPaymentTotals;
        var tmp$7;
        if (match$12 !== undefined) {
          var amount$7 = match$12.netCompletedPaymentsAndRefunds;
          tmp$7 = amount$7 !== undefined ? Caml_option.valFromOption(amount$7) : zero;
        } else {
          tmp$7 = zero;
        }
        var match$13 = diff$1.billingAndPaymentTotals;
        var tmp$8;
        if (match$13 !== undefined) {
          var amount$8 = match$13.netPendingPayments;
          tmp$8 = amount$8 !== undefined ? Caml_option.valFromOption(amount$8) : zero;
        } else {
          tmp$8 = zero;
        }
        var match$14 = diff$1.billingAndPaymentTotals;
        var tmp$9;
        if (match$14 !== undefined) {
          var amount$9 = match$14.combinedBalanceWithPending;
          tmp$9 = amount$9 !== undefined ? Caml_option.valFromOption(amount$9) : zero;
        } else {
          tmp$9 = zero;
        }
        var match$15 = diff$1.billingAndPaymentTotals;
        var tmp$10;
        if (match$15 !== undefined) {
          var amount$10 = match$15.netPendingPayments;
          tmp$10 = amount$10 !== undefined ? CS_Decimal$Util.Decimal10_2.toFloat(Caml_option.valFromOption(amount$10)) > 0.0 : false;
        } else {
          tmp$10 = false;
        }
        var match$16 = diff$1.billingAndPaymentTotals;
        var tmp$11;
        if (match$16 !== undefined) {
          var amount$11 = match$16.combinedBalance;
          tmp$11 = amount$11 !== undefined ? Caml_option.valFromOption(amount$11) : zero;
        } else {
          tmp$11 = zero;
        }
        return {
                bookingId: v._1,
                billing: {
                  billingLines: billingLines$2,
                  billingLineSubtotal: tmp$6
                },
                payment: {
                  completedPaymentSubtotal: tmp$7,
                  pendingPaymentSubtotal: tmp$8,
                  paymentLines: paymentLines$2
                },
                totalAmountIncludingPending: tmp$9,
                arePendingPayments: tmp$10,
                totalAmount: tmp$11,
                depositSchedule: undefined
              };
    
  }
}

var processorOptions = [
  [
    "BRAINTREEPAYMENTS_COM",
    "Braintree"
  ],
  [
    "INCREASE_COM",
    "Increase"
  ]
];

var lifecycleStatusOptions = [
  [
    "PENDING",
    "Pending"
  ],
  [
    "COMPLETED",
    "Completed"
  ],
  [
    "CANCELLED",
    "Cancelled"
  ],
  [
    "ERROR",
    "Error"
  ]
];

var pmtMethodOptions = [
  [
    "SAME_DAY_ACH",
    "Same-Day ACH"
  ],
  [
    "RTP",
    "RTP"
  ],
  [
    "WIRE",
    "Wire"
  ],
  [
    "CREDIT_CARD",
    "Credit Card"
  ],
  [
    "CHECK",
    "Check"
  ],
  [
    "FCC",
    "Future Cruise Credit"
  ]
];

function getEmptyManPayInterm(e) {
  return {
          payee: undefined,
          teamUserInitiator: e,
          processor: undefined,
          netRefundedAmount: undefined,
          netRefundedBfFeeAmount: undefined,
          referencePmtPublicId: undefined,
          lifecycleStatus: undefined,
          processorTransactionId: undefined,
          pmtMethod: undefined,
          bfcustPmtMethodIdentifier: undefined,
          bfcustPmtSubtype: undefined,
          bfcustPmtNote: undefined
        };
}

function paymentLineToManPayInterm(line, teamUserInitiator) {
  if (line === undefined) {
    return getEmptyManPayInterm(teamUserInitiator);
  }
  var proc = line.processor;
  var method = line.pmtMethod;
  return {
          payee: Caml_option.some(line.payerAcct),
          teamUserInitiator: teamUserInitiator,
          processor: proc !== undefined ? BookingDetailsRouteQuery_graphql$Thick.Utils.pmtProcessor_decode(proc) : undefined,
          netRefundedAmount: CS_Decimal$Util.Decimal10_2.toString(line.refundableAmt),
          netRefundedBfFeeAmount: CS_Decimal$Util.Decimal10_2.toString(line.refundableFeeAmt),
          referencePmtPublicId: CS_NonemptyStrings$Util.PmtTransactionId.toString(line.publicPmtId),
          lifecycleStatus: line.lifecycleStatus,
          processorTransactionId: Belt_Option.map(line.processorTransactionId, CS_NonemptyStrings$Util.NonEmptyString.toString),
          pmtMethod: method !== undefined ? BookingDetailsRouteQuery_graphql$Thick.Utils.pmtMethod_decode(method) : undefined,
          bfcustPmtMethodIdentifier: Belt_Option.map(line.bfcustPmtMethodIdentifier, CS_NonemptyStrings$Util.NonEmptyString.toString),
          bfcustPmtSubtype: Belt_Option.map(line.bfcustPmtSubtype, CS_NonemptyStrings$Util.NonEmptyString.toString),
          bfcustPmtNote: undefined
        };
}

function padIfNoDecimal(s) {
  var match = s.split(".");
  var len = match.length;
  if (len >= 3) {
    return s;
  }
  switch (len) {
    case 0 :
        return s;
    case 1 :
        var whole = match[0];
        return whole + ".00";
    case 2 :
        var whole$1 = match[0];
        var decimal = match[1];
        var match$1 = decimal.length;
        if (match$1 !== 1) {
          if (match$1 !== 2) {
            return s;
          } else {
            return whole$1 + "." + decimal;
          }
        } else {
          return whole$1 + "." + decimal + "0";
        }
    
  }
}

function makeNegative(str) {
  var match = str[0];
  if (match !== undefined) {
    if (match === "-") {
      return str.slice(1);
    } else {
      return "-" + str;
    }
  } else {
    return "-" + str;
  }
}

function manPayIntermToDiffRec(m) {
  var match = m.payee;
  var match$1 = Belt_Option.map(m.netRefundedAmount, (function (str) {
          return CS_Decimal$Util.Decimal10_2.fromPrimitive(makeNegative(padIfNoDecimal(str)));
        }));
  var match$2 = m.lifecycleStatus;
  var match$3 = m.pmtMethod;
  var match$4 = Belt_Option.map(m.bfcustPmtMethodIdentifier, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive);
  var match$5 = Belt_Option.map(m.bfcustPmtNote, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive);
  var match$6 = Belt_Option.map(m.processorTransactionId, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive);
  if (match === undefined) {
    return {
            TAG: "Error",
            _0: "Missing required fields"
          };
  }
  if (match$1 === undefined) {
    return {
            TAG: "Error",
            _0: "Missing required fields"
          };
  }
  if (match$1.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "Missing required fields"
          };
  }
  if (match$2 === undefined) {
    return {
            TAG: "Error",
            _0: "Missing required fields"
          };
  }
  if (match$3 === undefined) {
    return {
            TAG: "Error",
            _0: "Missing required fields"
          };
  }
  if (match$4 === undefined) {
    return {
            TAG: "Error",
            _0: "Missing required fields"
          };
  }
  if (match$4.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "Missing required fields"
          };
  }
  if (match$5 === undefined) {
    return {
            TAG: "Error",
            _0: "Missing required fields"
          };
  }
  if (match$5.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "Missing required fields"
          };
  }
  if (match$6 === undefined) {
    return {
            TAG: "Error",
            _0: "Missing required fields"
          };
  }
  if (match$6.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "Missing required fields"
          };
  }
  var match$7 = Belt_Option.map(m.netRefundedBfFeeAmount, (function (str) {
          return CS_Decimal$Util.Decimal10_2.fromPrimitive(makeNegative(padIfNoDecimal(str)));
        }));
  var tmp;
  tmp = match$7 !== undefined && match$7.TAG === "Ok" ? match$7._0 : CS_Decimal$Util.Decimal10_2.Exn.fromPrimitive("0.00");
  var match$8 = Belt_Option.map(m.referencePmtPublicId, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive);
  var tmp$1;
  tmp$1 = match$8 !== undefined && match$8.TAG === "Ok" ? Caml_option.some(match$8._0) : undefined;
  var match$9 = Belt_Option.map(m.bfcustPmtSubtype, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive);
  var tmp$2;
  tmp$2 = match$9 !== undefined && match$9.TAG === "Ok" ? Caml_option.some(match$9._0) : undefined;
  return {
          TAG: "Ok",
          _0: {
            payee: Caml_option.valFromOption(match),
            teamUserInitiator: m.teamUserInitiator,
            processor: m.processor,
            netRefundedAmount: match$1._0,
            netRefundedBfFeeAmount: tmp,
            referencePmtPublicId: tmp$1,
            lifecycleStatus: match$2,
            processorTransactionId: match$6._0,
            pmtMethod: match$3,
            bfcustPmtMethodIdentifier: match$4._0,
            bfcustPmtSubtype: tmp$2,
            bfcustPmtNote: match$5._0
          }
        };
}

var Utils = {
  processorOptions: processorOptions,
  lifecycleStatusOptions: lifecycleStatusOptions,
  pmtMethodOptions: pmtMethodOptions,
  getEmptyManPayInterm: getEmptyManPayInterm,
  paymentLineToManPayInterm: paymentLineToManPayInterm,
  C: undefined,
  padIfNoDecimal: padIfNoDecimal,
  makeNegative: makeNegative,
  manPayIntermToDiffRec: manPayIntermToDiffRec
};

var gracePeriod = 0.25;

var emptyPaymentPlan = {
  dueNow: undefined,
  overdueAmount: undefined,
  nextAmountDue: undefined,
  nextAmountDueAfter: undefined
};

exports.gracePeriod = gracePeriod;
exports.emptyPaymentPlan = emptyPaymentPlan;
exports.getDepSched = getDepSched;
exports.parse = parse;
exports.Utils = Utils;
/* CS_Make-Util Not a pure module */
