// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thick = require("../../utils/Common.bs.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var Belt_SortArray = require("rescript/lib/js/belt_SortArray.js");
var $$Option = require("@mui/joy/Option").default;
var Switch = require("@mui/joy/Switch").default;
var HopperState$Util = require("util/src/HopperState.bs.js");
var CS_ISO3166_1$Util = require("util/src/CustomScalars/CS_ISO3166_1.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var FormLabel = require("@mui/joy/FormLabel").default;
var CountryInput$Thick = require("../common/molecules/CountryInput.bs.js");
var Typography = require("@mui/joy/Typography").default;
var FormControl = require("@mui/joy/FormControl").default;
var FormHelperText = require("@mui/joy/FormHelperText").default;
var SelectSearchInput$Thick = require("../common/molecules/SelectSearchInput.bs.js");

function BSPInputs$Impl(props) {
  var __overridingError = props.overridingError;
  var __tooltip = props.tooltip;
  var __noErrors = props.noErrors;
  var overrideErrorText = props.overrideErrorText;
  var width = props.width;
  var __withLabels = props.withLabels;
  var children = props.children;
  var __disabled = props.disabled;
  var __required = props.required;
  var helperText = props.helperText;
  var label = props.label;
  var required = __required !== undefined ? __required : false;
  var disabled = __disabled !== undefined ? __disabled : false;
  var withLabels = __withLabels !== undefined ? __withLabels : true;
  var noErrors = __noErrors !== undefined ? __noErrors : false;
  var tooltip = __tooltip !== undefined ? Caml_option.valFromOption(__tooltip) : null;
  var overridingError = __overridingError !== undefined ? __overridingError : (function (param) {
        return false;
      });
  return JsxRuntime.jsx(HopperState$Util.Observable.FormField.Component.WithMapping.make, {
              view: props.view,
              input: props.input,
              map: props.map,
              children: (function (cargs) {
                  var result = cargs.result;
                  var wasTouched = cargs.wasTouched;
                  var match = overridingError(cargs.value);
                  var match$1 = match ? (
                      !disabled && wasTouched ? [
                          true,
                          Belt_Option.getWithDefault(overrideErrorText, "Invalid input")
                        ] : [
                          false,
                          undefined
                        ]
                    ) : (
                      result.TAG === "Ok" ? [
                          false,
                          undefined
                        ] : (
                          overrideErrorText !== undefined ? (
                              !disabled && wasTouched ? [
                                  true,
                                  overrideErrorText
                                ] : [
                                  false,
                                  undefined
                                ]
                            ) : (
                              !disabled && wasTouched ? [
                                  true,
                                  result._0
                                ] : [
                                  false,
                                  undefined
                                ]
                            )
                        )
                    );
                  var error = match$1[0];
                  var helperText$1 = error ? undefined : helperText;
                  var errorText = error ? match$1[1] : undefined;
                  return JsxRuntime.jsxs(FormControl, {
                              error: error && !noErrors && wasTouched && !disabled && Belt_Option.isSome(errorText),
                              required: required,
                              children: [
                                JsxRuntime.jsx(FormLabel, {
                                      sx: {
                                        display: withLabels ? "flex" : "none"
                                      },
                                      children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                endDecorator: Caml_option.some(tooltip),
                                                level: "inherit",
                                                children: Caml_option.some(label)
                                              }))
                                    }),
                                children(cargs),
                                withLabels ? Belt_Option.mapWithDefault(helperText$1, null, (function (helperText) {
                                          return JsxRuntime.jsx(FormHelperText, {
                                                      children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                                level: "body-sm",
                                                                children: Caml_option.some(helperText)
                                                              })),
                                                      sx: {
                                                        width: Belt_Option.getWithDefault(width, "auto")
                                                      }
                                                    });
                                        })) : null,
                                withLabels ? Belt_Option.mapWithDefault(errorText, null, (function (helperText) {
                                          return JsxRuntime.jsx(FormHelperText, {
                                                      children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                                level: "body-sm",
                                                                sx: {
                                                                  color: "red",
                                                                  width: Belt_Option.getWithDefault(width, "auto")
                                                                },
                                                                children: Caml_option.some(helperText)
                                                              }))
                                                    });
                                        })) : null
                              ]
                            });
                })
            });
}

function BSPInputs$Impl$NoFormControl(props) {
  var children = props.children;
  return JsxRuntime.jsx(HopperState$Util.Observable.FormField.Component.make, {
              view: props.view,
              input: props.input,
              children: (function (cargs) {
                  return children(cargs);
                })
            });
}

var NoFormControl = {
  make: BSPInputs$Impl$NoFormControl
};

var Impl = {
  make: BSPInputs$Impl,
  NoFormControl: NoFormControl
};

function BSPInputs$Bool(props) {
  var __withLabels = props.withLabels;
  var __fullWidth = props.fullWidth;
  var __disabled = props.disabled;
  var __required = props.required;
  var __size = props.size;
  var size = __size !== undefined ? __size : "lg";
  var required = __required !== undefined ? __required : false;
  var disabled = __disabled !== undefined ? __disabled : false;
  var fullWidth = __fullWidth !== undefined ? __fullWidth : false;
  var withLabels = __withLabels !== undefined ? __withLabels : true;
  return JsxRuntime.jsx(BSPInputs$Impl, {
              view: props.view,
              label: props.label,
              helperText: props.helperText,
              input: props.input,
              map: [
                (function (a) {
                    return a;
                  }),
                (function (a) {
                    return a;
                  })
              ],
              required: required,
              disabled: disabled,
              fullWidth: fullWidth,
              children: (function (param) {
                  var set = param.set;
                  return JsxRuntime.jsx(Switch, {
                              checked: param.value,
                              size: size,
                              onChange: (function (e) {
                                  set(function (param) {
                                        return e.currentTarget.checked;
                                      });
                                }),
                              sx: {
                                alignSelf: "flex-start"
                              }
                            });
                }),
              withLabels: withLabels
            });
}

var Bool = {
  make: BSPInputs$Bool
};

function BSPInputs$Select(props) {
  var __noErrors = props.noErrors;
  var afterSelect = props.afterSelect;
  var width = props.width;
  var __fullWidth = props.fullWidth;
  var __disabled = props.disabled;
  var __required = props.required;
  var __size = props.size;
  var options = props.options;
  var placeholder = props.placeholder;
  var size = __size !== undefined ? __size : "md";
  var required = __required !== undefined ? __required : false;
  var disabled = __disabled !== undefined ? __disabled : false;
  var fullWidth = __fullWidth !== undefined ? __fullWidth : false;
  var noErrors = __noErrors !== undefined ? __noErrors : false;
  return JsxRuntime.jsx(BSPInputs$Impl, {
              view: props.view,
              label: props.label,
              helperText: props.helperText,
              input: props.input,
              map: [
                (function (a) {
                    return a;
                  }),
                (function (a) {
                    return a;
                  })
              ],
              required: required,
              disabled: disabled,
              fullWidth: fullWidth,
              children: (function (param) {
                  var set = param.set;
                  var keyToPrimitive = function (search) {
                    return Belt_Array.get(Belt_Array.keepMap(options, (function (param) {
                                      if (search === param.key) {
                                        return Caml_option.some(param.primitive);
                                      }
                                      
                                    })), 0);
                  };
                  var primitiveToKey = function (search) {
                    return Belt_Array.get(Belt_Array.keepMap(options, (function (param) {
                                      if (Caml_obj.equal(search, param.primitive)) {
                                        return param.key;
                                      }
                                      
                                    })), 0);
                  };
                  return JsxRuntime.jsx(Joy$Util.JoySelect.make, {
                              children: Caml_option.some(Belt_Array.map(options, (function (param) {
                                          var key = param.key;
                                          return JsxRuntime.jsx($$Option, {
                                                      value: key,
                                                      children: Caml_option.some(param.niceName)
                                                    }, key);
                                        }))),
                              disabled: disabled,
                              onChange: (function (param, val) {
                                  if (val === undefined) {
                                    return ;
                                  }
                                  var v = Caml_option.valFromOption(val);
                                  if (v !== undefined) {
                                    Belt_Option.mapWithDefault(keyToPrimitive(v), undefined, (function (v) {
                                            set(function (param) {
                                                  return v;
                                                });
                                          }));
                                    Belt_Option.map(afterSelect, (function (f) {
                                            return f();
                                          }));
                                    return ;
                                  }
                                  
                                }),
                              placeholder: placeholder,
                              size: size,
                              slotProps: {
                                listbox: {
                                  sx: {
                                    zIndex: "modal"
                                  }
                                }
                              },
                              sx: {
                                width: Belt_Option.getWithDefault(width, "auto")
                              },
                              value: primitiveToKey(param.value)
                            });
                }),
              overrideErrorText: props.overrideErrorText,
              noErrors: noErrors
            });
}

var Select = {
  make: BSPInputs$Select
};

function BSPInputs$Date(props) {
  var __noErrors = props.noErrors;
  var __fullWidth = props.fullWidth;
  var __disabled = props.disabled;
  var __required = props.required;
  var __variant = props.variant;
  var placeholder = props.placeholder;
  var variant = __variant !== undefined ? __variant : "outlined";
  var required = __required !== undefined ? __required : false;
  var disabled = __disabled !== undefined ? __disabled : false;
  var fullWidth = __fullWidth !== undefined ? __fullWidth : false;
  var noErrors = __noErrors !== undefined ? __noErrors : false;
  return JsxRuntime.jsx(BSPInputs$Impl, {
              view: props.view,
              label: props.label,
              helperText: props.helperText,
              input: props.input,
              map: [
                (function (a) {
                    return Belt_Option.getWithDefault(a, "");
                  }),
                (function (a) {
                    if (a.trim() === "") {
                      return ;
                    } else {
                      return a;
                    }
                  })
              ],
              required: required,
              disabled: disabled,
              fullWidth: fullWidth,
              children: (function (param) {
                  var set = param.set;
                  return JsxRuntime.jsx(Joy$Util.Input.make, {
                              type_: "date",
                              placeholder: placeholder,
                              variant: variant,
                              value: param.value,
                              onChange: (function (e) {
                                  set(function (param) {
                                        return e.currentTarget.value;
                                      });
                                })
                            });
                }),
              noErrors: noErrors
            });
}

var $$Date = {
  make: BSPInputs$Date
};

function BSPInputs$Country(props) {
  var __noErrors = props.noErrors;
  var __fullWidth = props.fullWidth;
  var __disabled = props.disabled;
  var __required = props.required;
  var __variant = props.variant;
  var __size = props.size;
  var placeholder = props.placeholder;
  var countryFragment = props.countryFragment;
  var size = __size !== undefined ? __size : "md";
  var variant = __variant !== undefined ? __variant : "outlined";
  var required = __required !== undefined ? __required : false;
  var disabled = __disabled !== undefined ? __disabled : false;
  var fullWidth = __fullWidth !== undefined ? __fullWidth : false;
  var noErrors = __noErrors !== undefined ? __noErrors : false;
  return JsxRuntime.jsx(BSPInputs$Impl, {
              view: props.view,
              label: props.label,
              helperText: props.helperText,
              input: props.input,
              map: [
                (function (a) {
                    return a;
                  }),
                (function (a) {
                    return a;
                  })
              ],
              required: required,
              disabled: disabled,
              fullWidth: fullWidth,
              children: (function (param) {
                  var set = param.set;
                  return JsxRuntime.jsx(CountryInput$Thick.make, {
                              countryFragment: countryFragment,
                              variant: variant,
                              placeholder: placeholder,
                              size: size,
                              value: param.value,
                              onChange: (function (param, val) {
                                  set(function (param) {
                                        if (val === undefined) {
                                          return ;
                                        }
                                        var v = Caml_option.valFromOption(val);
                                        if (v !== undefined) {
                                          return Caml_option.some(Caml_option.valFromOption(v));
                                        }
                                        
                                      });
                                })
                            });
                }),
              noErrors: noErrors
            });
}

var Country = {
  make: BSPInputs$Country
};

function countryComparator(param, param$1) {
  var topOfList = Belt_MapString.fromArray(Belt_Array.mapWithIndex([
            "USA",
            "CAN",
            "AUS",
            "NZL",
            "GBR",
            "DEU"
          ], (function (idx, code) {
              return [
                      code,
                      idx + 1 | 0
                    ];
            })));
  var match = Belt_MapString.getWithDefault(topOfList, param.alpha3, 0);
  var match$1 = Belt_MapString.getWithDefault(topOfList, param$1.alpha3, 0);
  if (match === 0 && match$1 === 0) {
    return 0;
  }
  if (match$1 !== 0 && !(match !== 0 && match < match$1)) {
    return 1;
  } else {
    return -1;
  }
}

function BSPInputs$Phone$CountryCodeSelector(props) {
  var __size = props.size;
  var disabled = props.disabled;
  var size = __size !== undefined ? __size : "md";
  var countries = Belt_SortArray.stableSortBy(Belt_Array.map(Belt_Option.mapWithDefault(CountryInput$Thick.Fragment.use(props.countryFragment).countries, [], (function (param) {
                  return param.nodes;
                })), (function (param) {
              return CS_ISO3166_1$Util.Alpha3.meta(param.alpha3);
            })), countryComparator);
  return JsxRuntime.jsx(BSPInputs$Impl$NoFormControl, {
              view: {
                TAG: "Direct",
                _0: props.view
              },
              input: props.phoneCountryCode,
              children: (function (param) {
                  var set = param.set;
                  return JsxRuntime.jsx(Joy$Util.JoySelect.make, {
                              children: Caml_option.some(Belt_Array.mapWithIndex(countries, (function (i, c) {
                                          return JsxRuntime.jsx($$Option, {
                                                      value: Caml_option.some(c.callingCode),
                                                      children: Caml_option.some(Belt_Option.getExn(c.callingCode))
                                                    }, String(i));
                                        }))),
                              disabled: disabled,
                              onChange: (function (param, $$do) {
                                  if ($$do === undefined) {
                                    return ;
                                  }
                                  var c = Caml_option.valFromOption($$do);
                                  if (c === undefined) {
                                    return ;
                                  }
                                  var c$1 = Caml_option.valFromOption(c);
                                  set(function (param) {
                                        return c$1;
                                      });
                                }),
                              size: size,
                              slotProps: {
                                listbox: {
                                  sx: {
                                    zIndex: "modal"
                                  }
                                },
                                root: {
                                  sx: {
                                    borderTopRightRadius: 0,
                                    borderBottomRightRadius: 0
                                  }
                                },
                                overflow: "hidden",
                                textOverflow: "ellipsis"
                              },
                              value: Caml_option.some(param.value),
                              variant: disabled ? "solid" : "soft"
                            });
                })
            });
}

var CountryCodeSelector = {
  countryComparator: countryComparator,
  make: BSPInputs$Phone$CountryCodeSelector
};

function BSPInputs$Phone(props) {
  var __required = props.required;
  var __disabled = props.disabled;
  var phoneCountryCode = props.phoneCountryCode;
  var view = props.view;
  var countryFragment = props.countryFragment;
  var disabled = __disabled !== undefined ? __disabled : false;
  var required = __required !== undefined ? __required : false;
  var cc = HopperState$Util.Observable.useComputed(view, (function (v) {
          return phoneCountryCode.getValue(v);
        }), undefined, undefined);
  console.log(cc);
  return JsxRuntime.jsx(BSPInputs$Impl, {
              view: {
                TAG: "Direct",
                _0: view
              },
              label: props.label,
              helperText: props.helperText,
              input: props.phone,
              map: [
                (function (a) {
                    return Belt_Option.getWithDefault(a, "");
                  }),
                (function (a) {
                    if (a === "") {
                      return ;
                    } else {
                      return a;
                    }
                  })
              ],
              required: required,
              disabled: disabled,
              children: (function (param) {
                  var set = param.set;
                  return JsxRuntime.jsx(Joy$Util.Input.make, {
                              type_: "phone",
                              disabled: disabled,
                              required: required,
                              value: param.value,
                              onChange: (function (e) {
                                  set(function (param) {
                                        return e.currentTarget.value;
                                      });
                                }),
                              startDecorator: Caml_option.some(JsxRuntime.jsx(BSPInputs$Phone$CountryCodeSelector, {
                                        countryFragment: countryFragment,
                                        phoneCountryCode: phoneCountryCode,
                                        view: view,
                                        disabled: disabled
                                      })),
                              slotProps: {
                                root: {
                                  sx: {
                                    pl: 0
                                  }
                                }
                              }
                            });
                }),
              overrideErrorText: "Invalid phone number",
              overridingError: (function (value) {
                  var match = Common$Thick.phoneValidation(cc, value);
                  if (match.TAG === "Ok") {
                    return false;
                  } else {
                    return true;
                  }
                })
            });
}

var Phone = {
  CountryCodeSelector: CountryCodeSelector,
  make: BSPInputs$Phone
};

function BSPInputs$Text(props) {
  var __tooltip = props.tooltip;
  var __noErrors = props.noErrors;
  var width = props.width;
  var __fullWidth = props.fullWidth;
  var __disabled = props.disabled;
  var __required = props.required;
  var required = __required !== undefined ? __required : false;
  var disabled = __disabled !== undefined ? __disabled : false;
  var fullWidth = __fullWidth !== undefined ? __fullWidth : false;
  var noErrors = __noErrors !== undefined ? __noErrors : false;
  var tooltip = __tooltip !== undefined ? Caml_option.valFromOption(__tooltip) : null;
  return JsxRuntime.jsx(BSPInputs$Impl, {
              view: props.view,
              label: props.label,
              helperText: props.helperText,
              input: props.input,
              map: [
                (function (a) {
                    return a;
                  }),
                (function (a) {
                    return a;
                  })
              ],
              required: required,
              disabled: disabled,
              fullWidth: fullWidth,
              children: (function (param) {
                  var set = param.set;
                  return JsxRuntime.jsx(Joy$Util.Input.make, {
                              type_: "text",
                              fullWidth: fullWidth,
                              disabled: disabled,
                              required: required,
                              sx: {
                                width: Belt_Option.getWithDefault(width, "auto")
                              },
                              value: param.value,
                              onChange: (function (e) {
                                  set(function (param) {
                                        return e.currentTarget.value;
                                      });
                                })
                            });
                }),
              width: width,
              overrideErrorText: props.overrideErrorText,
              noErrors: noErrors,
              tooltip: Caml_option.some(tooltip)
            });
}

function BSPInputs$Text$Optional(props) {
  var __noErrors = props.noErrors;
  var width = props.width;
  var __fullWidth = props.fullWidth;
  var __disabled = props.disabled;
  var __required = props.required;
  var required = __required !== undefined ? __required : false;
  var disabled = __disabled !== undefined ? __disabled : false;
  var fullWidth = __fullWidth !== undefined ? __fullWidth : false;
  var noErrors = __noErrors !== undefined ? __noErrors : false;
  return JsxRuntime.jsx(BSPInputs$Impl, {
              view: props.view,
              label: props.label,
              helperText: props.helperText,
              input: props.input,
              map: [
                (function (a) {
                    return Belt_Option.getWithDefault(a, "");
                  }),
                (function (a) {
                    if (a === "") {
                      return ;
                    } else {
                      return a;
                    }
                  })
              ],
              required: required,
              disabled: disabled,
              fullWidth: fullWidth,
              children: (function (param) {
                  var set = param.set;
                  return JsxRuntime.jsx(Joy$Util.Input.make, {
                              type_: "text",
                              fullWidth: fullWidth,
                              disabled: disabled,
                              required: required,
                              sx: {
                                width: Belt_Option.getWithDefault(width, "auto")
                              },
                              value: param.value,
                              onChange: (function (e) {
                                  set(function (param) {
                                        return e.currentTarget.value;
                                      });
                                })
                            });
                }),
              width: width,
              overrideErrorText: props.overrideErrorText,
              noErrors: noErrors
            });
}

var Optional = {
  make: BSPInputs$Text$Optional
};

var $$Text = {
  make: BSPInputs$Text,
  Optional: Optional
};

function BSPInputs$SelectSearch$SSImpl(props) {
  var options = props.options;
  React.useState(function () {
        return false;
      });
  var options$1;
  options$1 = options.TAG === "Optional" ? Belt_Array.map(options._0, (function (v) {
            return {
                    primitive: Belt_Option.getWithDefault(v.primitive, ""),
                    key: v.key,
                    niceName: v.niceName
                  };
          })) : options._0;
  var idArray = Belt_Array.map(options$1, (function (param) {
          return param.key;
        }));
  var getOptionLabel = function (str) {
    return Belt_Option.getWithDefault(Belt_Array.get(Belt_Array.keepMap(options$1, (function (param) {
                          if (str === param.key) {
                            return param.niceName;
                          }
                          
                        })), 0), "");
  };
  var getOptionFromKey = function (value) {
    return Belt_Array.get(Belt_Array.keepMap(options$1, (function (param) {
                      if (value === param.key) {
                        return param.primitive;
                      }
                      
                    })), 0);
  };
  return JsxRuntime.jsx(SelectSearchInput$Thick.make, {
              placeholder: props.placeholder,
              disabled: props.disabled,
              loading: props.loading,
              value: props.value,
              set: props.set,
              options: idArray,
              getOptionLabel: getOptionLabel,
              getOptionFromKey: getOptionFromKey,
              setLoading: props.setLoading,
              clearOptions: props.clearOptions,
              search: props.search,
              actionButton: props.actionButton
            });
}

var SSImpl = {
  make: BSPInputs$SelectSearch$SSImpl
};

function BSPInputs$SelectSearch(props) {
  var actionButton = props.actionButton;
  var search = props.search;
  var clearOptions = props.clearOptions;
  var options = props.options;
  var input = props.input;
  var setLoading = props.setLoading;
  var __loading = props.loading;
  var width = props.width;
  var __fullWidth = props.fullWidth;
  var __disabled = props.disabled;
  var __required = props.required;
  var placeholder = props.placeholder;
  var required = __required !== undefined ? __required : false;
  var disabled = __disabled !== undefined ? __disabled : false;
  var fullWidth = __fullWidth !== undefined ? __fullWidth : false;
  var loading = __loading !== undefined ? __loading : false;
  return JsxRuntime.jsx(BSPInputs$Impl, {
              view: props.view,
              label: props.label,
              helperText: props.helperText,
              input: input,
              map: [
                (function (a) {
                    return a;
                  }),
                (function (a) {
                    return a;
                  })
              ],
              required: required,
              disabled: disabled,
              fullWidth: fullWidth,
              children: (function (param) {
                  var set = param.set;
                  var clearOptions$1 = function (param) {
                    set(function (param) {
                          return input.blankValue;
                        });
                    clearOptions();
                  };
                  return JsxRuntime.jsx(BSPInputs$SelectSearch$SSImpl, {
                              placeholder: placeholder,
                              disabled: disabled,
                              loading: loading,
                              value: param.value,
                              set: set,
                              options: {
                                TAG: "Required",
                                _0: options
                              },
                              width: width,
                              setLoading: setLoading,
                              clearOptions: clearOptions$1,
                              search: search,
                              actionButton: actionButton
                            });
                }),
              overrideErrorText: props.overrideErrorText
            });
}

function BSPInputs$SelectSearch$Optional(props) {
  var actionButton = props.actionButton;
  var search = props.search;
  var clearOptions = props.clearOptions;
  var options = props.options;
  var setLoading = props.setLoading;
  var __loading = props.loading;
  var width = props.width;
  var __fullWidth = props.fullWidth;
  var __disabled = props.disabled;
  var __required = props.required;
  var placeholder = props.placeholder;
  var required = __required !== undefined ? __required : false;
  var disabled = __disabled !== undefined ? __disabled : false;
  var fullWidth = __fullWidth !== undefined ? __fullWidth : false;
  var loading = __loading !== undefined ? __loading : false;
  return JsxRuntime.jsx(BSPInputs$Impl, {
              view: props.view,
              label: props.label,
              helperText: props.helperText,
              input: props.input,
              map: [
                (function (a) {
                    return Belt_Option.getWithDefault(a, "");
                  }),
                (function (a) {
                    if (a === "") {
                      return ;
                    } else {
                      return a;
                    }
                  })
              ],
              required: required,
              disabled: disabled,
              fullWidth: fullWidth,
              children: (function (param) {
                  return JsxRuntime.jsx(BSPInputs$SelectSearch$SSImpl, {
                              placeholder: placeholder,
                              disabled: disabled,
                              loading: loading,
                              value: param.value,
                              set: param.set,
                              options: {
                                TAG: "Optional",
                                _0: options
                              },
                              width: width,
                              setLoading: setLoading,
                              clearOptions: clearOptions,
                              search: search,
                              actionButton: actionButton
                            });
                }),
              overrideErrorText: props.overrideErrorText
            });
}

var Optional$1 = {
  make: BSPInputs$SelectSearch$Optional
};

var SelectSearch = {
  SSImpl: SSImpl,
  make: BSPInputs$SelectSearch,
  Optional: Optional$1
};

exports.Impl = Impl;
exports.Bool = Bool;
exports.Select = Select;
exports.$$Date = $$Date;
exports.Country = Country;
exports.Phone = Phone;
exports.$$Text = $$Text;
exports.SelectSearch = SelectSearch;
/* react Not a pure module */
