// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var List = require("@mui/joy/List").default;
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var Tooltip = require("@mui/joy/Tooltip").default;
var HopperState$Util = require("util/src/HopperState.bs.js");
var ListItem = require("@mui/joy/ListItem").default;
var JsxRuntime = require("react/jsx-runtime");
var FormLabel = require("@mui/joy/FormLabel").default;
var Typography = require("@mui/joy/Typography").default;
var MergeAccounts$Thick = require("../../utils/MergeAccounts.bs.js");
var FormControl = require("@mui/joy/FormControl").default;
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var CircularProgress = require("@mui/joy/CircularProgress").default;
var CheckCircle = require("@mui/icons-material/CheckCircle").default;
var ErrorOutline = require("@mui/icons-material/ErrorOutline").default;
var QuestionMark = require("@mui/icons-material/QuestionMark").default;
var WarningAmber = require("@mui/icons-material/WarningAmber").default;
var DoNotDisturbAlt = require("@mui/icons-material/DoNotDisturbAlt").default;

function MergeabilityCheck(props) {
  var view = props.view;
  var match = HopperState$Util.Observable.useComputed(view, (function (v) {
          return [
                  v.source.value,
                  v.dest.value,
                  v.mergeability
                ];
        }), undefined, undefined);
  var mergeability = match[2];
  var dest = match[1];
  var source = match[0];
  var setMergeability = function (m) {
    HopperState$Util.Observable.notify(view, (function (v) {
            return {
                    source: v.source,
                    dest: v.dest,
                    url: v.url,
                    sourceAcct: v.sourceAcct,
                    destAcct: v.destAcct,
                    mergeability: m,
                    mergedStatus: v.mergedStatus,
                    mergeModal: v.mergeModal
                  };
          }));
  };
  var match$1 = MergeAccounts$Thick.useCheck(props.brandFamily);
  var check = match$1.check;
  var error = match$1.error;
  React.useEffect((function () {
          var match = CS_NonemptyStrings$Util.AccountId.fromPrimitive(source);
          var match$1 = CS_NonemptyStrings$Util.AccountId.fromPrimitive(dest);
          if (match.TAG === "Ok" && match$1.TAG === "Ok") {
            check(match._0, match$1._0, setMergeability);
          } else {
            setMergeability("NotChecked");
          }
        }), [
        source,
        dest
      ]);
  var match$2 = error !== undefined ? [
      "Error",
      "The server encountered the following error checking mergeability: " + error
    ] : (
      typeof mergeability !== "object" ? (
          mergeability === "NotChecked" ? [
              "Not Checked",
              "Select source and destination accounts to check mergeability."
            ] : [
              "Mergeable",
              "These two accounts can be merged without any issues."
            ]
        ) : (
          mergeability.TAG === "MergeWithWarnings" ? [
              "Merge with Warnings",
              "These two accounts can be merged, but with the following warnings:"
            ] : [
              "Cannot Merge",
              "These two accounts cannot be merged for the following reason(s):"
            ]
        )
    );
  var tmp;
  tmp = error !== undefined || typeof mergeability !== "object" ? null : (
      mergeability.TAG === "MergeWithWarnings" ? JsxRuntime.jsx(List, {
              children: Caml_option.some(Belt_Array.mapWithIndex(mergeability._0, (function (i, warning) {
                          return JsxRuntime.jsx(ListItem, {
                                      children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                level: "body-sm",
                                                children: Caml_option.some(warning)
                                              }))
                                    }, String(i));
                        }))),
              size: "sm"
            }) : JsxRuntime.jsx(List, {
              children: Caml_option.some(Belt_Array.mapWithIndex(mergeability._0, (function (i, reason) {
                          return JsxRuntime.jsx(ListItem, {
                                      children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                level: "body-sm",
                                                children: Caml_option.some(reason)
                                              }))
                                    }, String(i));
                        }))),
              size: "sm"
            })
    );
  var title = JsxRuntime.jsxs(Sheet, {
        sx: {
          p: 1
        },
        children: [
          JsxRuntime.jsxs(Stack, {
                spacing: 1,
                children: [
                  JsxRuntime.jsx(Typography, {
                        level: "title-md",
                        children: Caml_option.some(match$2[0])
                      }),
                  JsxRuntime.jsx(Typography, {
                        level: "body-sm",
                        children: Caml_option.some(match$2[1])
                      })
                ]
              }),
          tmp
        ]
      });
  var tmp$1;
  var exit = 0;
  if (match$1.loading) {
    if (error !== undefined) {
      exit = 1;
    } else {
      tmp$1 = JsxRuntime.jsx(CircularProgress, {
            size: "md"
          });
    }
  } else if (error !== undefined) {
    exit = 1;
  } else {
    tmp$1 = typeof mergeability !== "object" ? (
        mergeability === "NotChecked" ? JsxRuntime.jsx(Tooltip, {
                children: JsxRuntime.jsx(QuestionMark, {}),
                sx: {
                  maxWidth: "450px"
                },
                title: Caml_option.some(title),
                variant: "outlined"
              }) : JsxRuntime.jsx(Tooltip, {
                children: JsxRuntime.jsx(CheckCircle, {
                      color: "success"
                    }),
                sx: {
                  maxWidth: "450px"
                },
                title: Caml_option.some(title),
                variant: "outlined"
              })
      ) : (
        mergeability.TAG === "MergeWithWarnings" ? JsxRuntime.jsx(Tooltip, {
                children: JsxRuntime.jsx(WarningAmber, {
                      color: "warning"
                    }),
                sx: {
                  maxWidth: "450px"
                },
                title: Caml_option.some(title),
                variant: "outlined"
              }) : JsxRuntime.jsx(Tooltip, {
                children: JsxRuntime.jsx(DoNotDisturbAlt, {
                      color: "danger"
                    }),
                sx: {
                  maxWidth: "450px"
                },
                title: Caml_option.some(title),
                variant: "outlined"
              })
      );
  }
  if (exit === 1) {
    tmp$1 = JsxRuntime.jsx(Tooltip, {
          children: JsxRuntime.jsx(ErrorOutline, {
                color: "danger"
              }),
          sx: {
            maxWidth: "450px"
          },
          title: Caml_option.some(title),
          variant: "outlined"
        });
  }
  return JsxRuntime.jsx(Stack, {
              direction: "column",
              children: Caml_option.some(JsxRuntime.jsxs(FormControl, {
                        sx: {
                          height: "100%"
                        },
                        children: [
                          JsxRuntime.jsx(FormLabel, {
                                children: "Mergeable?"
                              }),
                          JsxRuntime.jsx(Stack, {
                                direction: "row",
                                justifyContent: "center",
                                children: Caml_option.some(tmp$1)
                              })
                        ]
                      }))
            });
}

var make = MergeabilityCheck;

exports.make = make;
/* react Not a pure module */
