// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Set = require("rescript/lib/js/belt_Set.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var DateFns = require("date-fns");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CS_Phone$Util = require("util/src/CustomScalars/CS_Phone.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var DateOnly$Util = require("util/src/DateOnly.bs.js");
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var HopperState$Util = require("util/src/HopperState.bs.js");
var QueryString$Util = require("util/src/QueryString.bs.js");
var CS_ISO3166_1$Util = require("util/src/CustomScalars/CS_ISO3166_1.bs.js");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var VoyageDetails$Thick = require("../components/common/queries/VoyageDetails.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var CS_SQLDatesAndTimes$Util = require("util/src/CustomScalars/CS_SQLDatesAndTimes.bs.js");
var CS_LoyaltyAndCruiselineNumbers$Util = require("util/src/CustomScalars/CS_LoyaltyAndCruiselineNumbers.bs.js");
var CustomersState_customer_graphql$Thick = require("../__generated__/CustomersState_customer_graphql.bs.js");
var BookingSessionDetail_query_graphql$Thick = require("../__generated__/BookingSessionDetail_query_graphql.bs.js");
var CustomersStateFetchOneQuery_graphql$Thick = require("../__generated__/CustomersStateFetchOneQuery_graphql.bs.js");
var CustomersState_ManagePrefillMutation_graphql$Thick = require("../__generated__/CustomersState_ManagePrefillMutation_graphql.bs.js");
var CustomersState_PersistPrefillMutation_graphql$Thick = require("../__generated__/CustomersState_PersistPrefillMutation_graphql.bs.js");
var CustomersState_UpdateExistingMutation_graphql$Thick = require("../__generated__/CustomersState_UpdateExistingMutation_graphql.bs.js");
var CustomersState_ResendEmailVerificationMutation_graphql$Thick = require("../__generated__/CustomersState_ResendEmailVerificationMutation_graphql.bs.js");

var convertVariables = CustomersState_UpdateExistingMutation_graphql$Thick.Internal.convertVariables;

var convertResponse = CustomersState_UpdateExistingMutation_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = CustomersState_UpdateExistingMutation_graphql$Thick.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables, CustomersState_UpdateExistingMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, CustomersState_UpdateExistingMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse);

var UpdateAccountMutation_docGender_decode = CustomersState_UpdateExistingMutation_graphql$Thick.Utils.docGender_decode;

var UpdateAccountMutation_docGender_fromString = CustomersState_UpdateExistingMutation_graphql$Thick.Utils.docGender_fromString;

var UpdateAccountMutation = {
  docGender_decode: UpdateAccountMutation_docGender_decode,
  docGender_fromString: UpdateAccountMutation_docGender_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

var convertVariables$1 = CustomersState_PersistPrefillMutation_graphql$Thick.Internal.convertVariables;

var convertResponse$1 = CustomersState_PersistPrefillMutation_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse$1 = CustomersState_PersistPrefillMutation_graphql$Thick.Internal.convertWrapRawResponse;

var commitMutation$1 = RescriptRelay_Mutation.commitMutation(convertVariables$1, CustomersState_PersistPrefillMutation_graphql$Thick.node, convertResponse$1, convertWrapRawResponse$1);

var use$1 = RescriptRelay_Mutation.useMutation(convertVariables$1, CustomersState_PersistPrefillMutation_graphql$Thick.node, convertResponse$1, convertWrapRawResponse$1);

var PersistPrefillMutation_createBfcustUserErr_decode = CustomersState_PersistPrefillMutation_graphql$Thick.Utils.createBfcustUserErr_decode;

var PersistPrefillMutation_createBfcustUserErr_fromString = CustomersState_PersistPrefillMutation_graphql$Thick.Utils.createBfcustUserErr_fromString;

var PersistPrefillMutation_docGender_decode = CustomersState_PersistPrefillMutation_graphql$Thick.Utils.docGender_decode;

var PersistPrefillMutation_docGender_fromString = CustomersState_PersistPrefillMutation_graphql$Thick.Utils.docGender_fromString;

var PersistPrefillMutation = {
  createBfcustUserErr_decode: PersistPrefillMutation_createBfcustUserErr_decode,
  createBfcustUserErr_fromString: PersistPrefillMutation_createBfcustUserErr_fromString,
  docGender_decode: PersistPrefillMutation_docGender_decode,
  docGender_fromString: PersistPrefillMutation_docGender_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables$1,
  convertResponse: convertResponse$1,
  convertWrapRawResponse: convertWrapRawResponse$1,
  commitMutation: commitMutation$1,
  use: use$1
};

var convertVariables$2 = CustomersState_ManagePrefillMutation_graphql$Thick.Internal.convertVariables;

var convertResponse$2 = CustomersState_ManagePrefillMutation_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse$2 = CustomersState_ManagePrefillMutation_graphql$Thick.Internal.convertWrapRawResponse;

var commitMutation$2 = RescriptRelay_Mutation.commitMutation(convertVariables$2, CustomersState_ManagePrefillMutation_graphql$Thick.node, convertResponse$2, convertWrapRawResponse$2);

var use$2 = RescriptRelay_Mutation.useMutation(convertVariables$2, CustomersState_ManagePrefillMutation_graphql$Thick.node, convertResponse$2, convertWrapRawResponse$2);

var ManagePrefillMutation = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables$2,
  convertResponse: convertResponse$2,
  convertWrapRawResponse: convertWrapRawResponse$2,
  commitMutation: commitMutation$2,
  use: use$2
};

var convertVariables$3 = CustomersState_ResendEmailVerificationMutation_graphql$Thick.Internal.convertVariables;

var convertResponse$3 = CustomersState_ResendEmailVerificationMutation_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse$3 = CustomersState_ResendEmailVerificationMutation_graphql$Thick.Internal.convertWrapRawResponse;

var commitMutation$3 = RescriptRelay_Mutation.commitMutation(convertVariables$3, CustomersState_ResendEmailVerificationMutation_graphql$Thick.node, convertResponse$3, convertWrapRawResponse$3);

var use$3 = RescriptRelay_Mutation.useMutation(convertVariables$3, CustomersState_ResendEmailVerificationMutation_graphql$Thick.node, convertResponse$3, convertWrapRawResponse$3);

var ResendEmailVerificationMutation = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables$3,
  convertResponse: convertResponse$3,
  convertWrapRawResponse: convertWrapRawResponse$3,
  commitMutation: commitMutation$3,
  use: use$3
};

var convertFragment = CustomersState_customer_graphql$Thick.Internal.convertFragment;

function use$4(fRef) {
  return RescriptRelay_Fragment.useFragment(CustomersState_customer_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, CustomersState_customer_graphql$Thick.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(CustomersState_customer_graphql$Thick.node, convertFragment, fRef);
}

var CustomerFragment_accountStatus_decode = CustomersState_customer_graphql$Thick.Utils.accountStatus_decode;

var CustomerFragment_accountStatus_fromString = CustomersState_customer_graphql$Thick.Utils.accountStatus_fromString;

var CustomerFragment_docGender_decode = CustomersState_customer_graphql$Thick.Utils.docGender_decode;

var CustomerFragment_docGender_fromString = CustomersState_customer_graphql$Thick.Utils.docGender_fromString;

var CustomerFragment = {
  accountStatus_decode: CustomerFragment_accountStatus_decode,
  accountStatus_fromString: CustomerFragment_accountStatus_fromString,
  docGender_decode: CustomerFragment_docGender_decode,
  docGender_fromString: CustomerFragment_docGender_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use$4,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

var convertVariables$4 = CustomersStateFetchOneQuery_graphql$Thick.Internal.convertVariables;

var convertResponse$4 = CustomersStateFetchOneQuery_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse$4 = CustomersStateFetchOneQuery_graphql$Thick.Internal.convertWrapRawResponse;

var use$5 = RescriptRelay_Query.useQuery(convertVariables$4, CustomersStateFetchOneQuery_graphql$Thick.node, convertResponse$4);

var useLoader = RescriptRelay_Query.useLoader(convertVariables$4, CustomersStateFetchOneQuery_graphql$Thick.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(CustomersStateFetchOneQuery_graphql$Thick.node, convertResponse$4, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(CustomersStateFetchOneQuery_graphql$Thick.node, convertResponse$4, convertVariables$4);

var fetchPromised = RescriptRelay_Query.fetchPromised(CustomersStateFetchOneQuery_graphql$Thick.node, convertResponse$4, convertVariables$4);

var retain = RescriptRelay_Query.retain(CustomersStateFetchOneQuery_graphql$Thick.node, convertVariables$4);

var FetchCustomer = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables$4,
  convertResponse: convertResponse$4,
  convertWrapRawResponse: convertWrapRawResponse$4,
  use: use$5,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

var decodeDocGender = BookingSessionDetail_query_graphql$Thick.Utils.docGender_decode;

function decodeDocGenderExn(g) {
  return Belt_Option.getExn(decodeDocGender(g));
}

function docGenderToString(prim) {
  return prim;
}

var docGenderFromString = BookingSessionDetail_query_graphql$Thick.Utils.docGender_fromString;

var allLegalDocGenders = [
  "F",
  "M",
  "X"
];

function docGenderNiceName(dg) {
  switch (dg) {
    case "M" :
        return "Male on Travel Documents";
    case "F" :
        return "Female on Travel Documents";
    case "X" :
        return "X on Travel Documents";
    
  }
}

var accountEmail = HopperState$Util.Observable.FormField.makeOptionalString({
      read: (function (param) {
          return param.accountEmail;
        }),
      write: (function (form, accountEmail) {
          var newrecord = Caml_obj.obj_dup(form);
          newrecord.accountEmail = accountEmail;
          return newrecord;
        })
    }, CS_Emails$Util.Email.fromPrimitive, CS_Emails$Util.Email.toPrimitive, {
      TAG: "Error",
      _0: "This field is required"
    }, undefined, undefined);

var preferredFirstName = HopperState$Util.Observable.FormField.makeOptionalString({
      read: (function (param) {
          return param.preferredFirstName;
        }),
      write: (function (form, preferredFirstName) {
          var newrecord = Caml_obj.obj_dup(form);
          newrecord.preferredFirstName = preferredFirstName;
          return newrecord;
        })
    }, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive, CS_NonemptyStrings$Util.NonEmptyString.toPrimitive, {
      TAG: "Error",
      _0: "This field is required"
    }, undefined, undefined);

var preferredLastName = HopperState$Util.Observable.FormField.makeOptionalString({
      read: (function (param) {
          return param.preferredLastName;
        }),
      write: (function (form, preferredLastName) {
          var newrecord = Caml_obj.obj_dup(form);
          newrecord.preferredLastName = preferredLastName;
          return newrecord;
        })
    }, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive, CS_NonemptyStrings$Util.NonEmptyString.toPrimitive, {
      TAG: "Ok",
      _0: undefined
    }, undefined, (function (param, prevRes) {
        if (param.formSettings.lastNameIsRequired && prevRes.TAG === "Ok" && prevRes._0 === undefined) {
          return {
                  TAG: "Error",
                  _0: "Last name is required for this passenger type"
                };
        } else {
          return prevRes;
        }
      }));

var phone = HopperState$Util.Observable.FormField.makeOptionalString({
      read: (function (param) {
          return param.phone;
        }),
      write: (function (form, phone) {
          var newrecord = Caml_obj.obj_dup(form);
          newrecord.phone = phone;
          return newrecord;
        })
    }, (function (v) {
        return {
                TAG: "Ok",
                _0: v
              };
      }), (function (v) {
        return v;
      }), {
      TAG: "Ok",
      _0: undefined
    }, undefined, undefined);

var phoneCountryCode = HopperState$Util.Observable.FormField.makeString({
      read: (function (param) {
          return param.phoneCountryCode;
        }),
      write: (function (form, phoneCountryCode) {
          var newrecord = Caml_obj.obj_dup(form);
          newrecord.phoneCountryCode = phoneCountryCode;
          return newrecord;
        })
    }, (function (v) {
        return {
                TAG: "Ok",
                _0: v
              };
      }), (function (v) {
        return v;
      }), "+1", undefined);

var postalCode = HopperState$Util.Observable.FormField.makeOptionalString({
      read: (function (param) {
          return param.postalCode;
        }),
      write: (function (form, postalCode) {
          var newrecord = Caml_obj.obj_dup(form);
          newrecord.postalCode = postalCode;
          return newrecord;
        })
    }, CS_NonemptyStrings$Util.PostalCode.fromPrimitive, CS_NonemptyStrings$Util.PostalCode.toPrimitive, {
      TAG: "Ok",
      _0: undefined
    }, undefined, undefined);

var residenceCountry = HopperState$Util.Observable.FormField.makeOptional({
      read: (function (param) {
          return param.residenceCountry;
        }),
      write: (function (form, residenceCountry) {
          var newrecord = Caml_obj.obj_dup(form);
          newrecord.residenceCountry = residenceCountry;
          return newrecord;
        })
    }, (function (a) {
        return {
                TAG: "Ok",
                _0: a
              };
      }), (function (a) {
        return a;
      }), CS_ISO3166_1$Util.Alpha3.schema, {
      TAG: "Ok",
      _0: undefined
    }, undefined, undefined);

var halMarinerNumber = HopperState$Util.Observable.FormField.makeOptionalString({
      read: (function (param) {
          return param.halMarinerNumber;
        }),
      write: (function (form, halMarinerNumber) {
          var newrecord = Caml_obj.obj_dup(form);
          newrecord.halMarinerNumber = halMarinerNumber;
          return newrecord;
        })
    }, CS_LoyaltyAndCruiselineNumbers$Util.HalMarinerNumber.fromPrimitive, CS_LoyaltyAndCruiselineNumbers$Util.HalMarinerNumber.toPrimitive, {
      TAG: "Ok",
      _0: undefined
    }, undefined, undefined);

var docFirstname = HopperState$Util.Observable.FormField.makeOptionalString({
      read: (function (param) {
          return param.docFirstname;
        }),
      write: (function (form, docFirstname) {
          var newrecord = Caml_obj.obj_dup(form);
          newrecord.docFirstname = docFirstname;
          return newrecord;
        })
    }, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive, CS_NonemptyStrings$Util.NonEmptyString.toPrimitive, {
      TAG: "Ok",
      _0: undefined
    }, undefined, undefined);

var docLastname = HopperState$Util.Observable.FormField.makeOptionalString({
      read: (function (param) {
          return param.docLastname;
        }),
      write: (function (form, docLastname) {
          var newrecord = Caml_obj.obj_dup(form);
          newrecord.docLastname = docLastname;
          return newrecord;
        })
    }, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive, CS_NonemptyStrings$Util.NonEmptyString.toPrimitive, {
      TAG: "Ok",
      _0: undefined
    }, undefined, undefined);

var docGender = HopperState$Util.Observable.FormField.makeOptional({
      read: (function (param) {
          return param.docGender;
        }),
      write: (function (form, docGender) {
          var newrecord = Caml_obj.obj_dup(form);
          newrecord.docGender = docGender;
          return newrecord;
        })
    }, (function (a) {
        return {
                TAG: "Ok",
                _0: a
              };
      }), (function (a) {
        return a;
      }), HopperState$Util.Observable.FormField.makeStringableSchema(docGenderToString, docGenderFromString), {
      TAG: "Ok",
      _0: undefined
    }, undefined, undefined);

var docCitizenship = HopperState$Util.Observable.FormField.makeOptional({
      read: (function (param) {
          return param.docCitizenship;
        }),
      write: (function (form, docCitizenship) {
          var newrecord = Caml_obj.obj_dup(form);
          newrecord.docCitizenship = docCitizenship;
          return newrecord;
        })
    }, (function (a) {
        return {
                TAG: "Ok",
                _0: a
              };
      }), (function (a) {
        return a;
      }), CS_ISO3166_1$Util.Alpha3.schema, {
      TAG: "Ok",
      _0: undefined
    }, undefined, undefined);

var docBirthdate = HopperState$Util.Observable.FormField.makeOptionalString({
      read: (function (param) {
          return param.docBirthdate;
        }),
      write: (function (form, docBirthdate) {
          var newrecord = Caml_obj.obj_dup(form);
          newrecord.docBirthdate = docBirthdate;
          return newrecord;
        })
    }, CS_SQLDatesAndTimes$Util.DateOnly.fromPrimitive, CS_SQLDatesAndTimes$Util.DateOnly.toPrimitive, {
      TAG: "Ok",
      _0: undefined
    }, undefined, undefined);

var prefillBfcustLinkExpiry = HopperState$Util.Observable.FormField.makeOptionalString({
      read: (function (param) {
          return param.prefillLink.linkExpiry;
        }),
      write: (function (form, linkExpiry) {
          var newrecord = Caml_obj.obj_dup(form);
          var init = form.prefillLink;
          newrecord.prefillLink = {
            linkExpiry: linkExpiry,
            shouldRegenerate: init.shouldRegenerate,
            extantLink: init.extantLink,
            manageMutation: init.manageMutation
          };
          return newrecord;
        })
    }, (function (p) {
        return Belt_Result.flatMap(CS_SQLDatesAndTimes$Util.DateOnly.fromPrimitive(p), (function (d) {
                      if (DateFns.isBefore(DateOnly$Util.toDate(d), new Date())) {
                        return {
                                TAG: "Error",
                                _0: "This date must be in the future"
                              };
                      } else {
                        return {
                                TAG: "Ok",
                                _0: d
                              };
                      }
                    }));
      }), CS_SQLDatesAndTimes$Util.DateOnly.toPrimitive, {
      TAG: "Error",
      _0: "You must provide an expiry date up to 1 month in the future."
    }, undefined, undefined);

var shouldResetBfcustPrefillLink = HopperState$Util.Observable.FormField.makeBool({
      read: (function (param) {
          return param.prefillLink.shouldRegenerate;
        }),
      write: (function (form, shouldRegenerate) {
          var newrecord = Caml_obj.obj_dup(form);
          var init = form.prefillLink;
          newrecord.prefillLink = {
            linkExpiry: init.linkExpiry,
            shouldRegenerate: shouldRegenerate,
            extantLink: init.extantLink,
            manageMutation: init.manageMutation
          };
          return newrecord;
        })
    }, (function (a) {
        return {
                TAG: "Ok",
                _0: a
              };
      }), (function (a) {
        return a;
      }), false, undefined);

var targetEmail = HopperState$Util.Observable.FormField.makeOptionalString({
      read: (function (param) {
          return param.targetEmail;
        }),
      write: (function (form, targetEmail) {
          return {
                  accountId: form.accountId,
                  label: form.label,
                  targetEmail: targetEmail
                };
        })
    }, CS_Emails$Util.Email.fromPrimitive, CS_Emails$Util.Email.toPrimitive, {
      TAG: "Error",
      _0: "This field is required"
    }, undefined, undefined);

var allFilterableAccountStatuses = [
  "UNCLAIMED",
  "CLAIMED",
  "INVITED",
  "TRANSFERRING",
  "PREFILLED"
];

var acctStatus = QueryString$Util.Codec.customStringable({
      legalVals: allFilterableAccountStatuses,
      toString: (function (v) {
          return v;
        })
    });

var signonStatus = QueryString$Util.Codec.customStringable({
      legalVals: [
        "ANONYMOUS",
        "CLEAR_JWT",
        "PENDING_2FA",
        "INVITED",
        "VERIFY_EMAIL",
        "SIGNED_ON"
      ],
      toString: (function (v) {
          return v;
        })
    });

var accountTypeFilterCodec = QueryString$Util.Codec.tuple2(QueryString$Util.Codec.delimitedArray(undefined, QueryString$Util.Codec.required(acctStatus)), signonStatus);

function accountTypeFilterToString(param) {
  return QueryString$Util.Codec.Raw.paramValue.encode(accountTypeFilterCodec.encode([
                  param.onlyWithAccountStatuses,
                  param.onlyWithSignonStatus
                ]));
}

function accountTypeFilterFromString(str) {
  return Belt_Result.mapWithDefault(accountTypeFilterCodec.decode(QueryString$Util.Codec.Raw.paramValue.decode(str)), undefined, (function (param) {
                return {
                        onlyWithAccountStatuses: param[0],
                        onlyWithSignonStatus: param[1]
                      };
              }));
}

function accountTypeFilterMetaToString(v) {
  return accountTypeFilterToString(v);
}

var defaultVisibleAccountTypes = {
  onlyWithAccountStatuses: allFilterableAccountStatuses,
  onlyWithSignonStatus: undefined,
  accountTypeDescriptor: "All Accounts"
};

var accountTypeFiltrationOptions = [
  defaultVisibleAccountTypes,
  {
    onlyWithAccountStatuses: ["INVITED"],
    onlyWithSignonStatus: undefined,
    accountTypeDescriptor: "Invited"
  },
  {
    onlyWithAccountStatuses: ["CLAIMED"],
    onlyWithSignonStatus: undefined,
    accountTypeDescriptor: "Claimed - All"
  },
  {
    onlyWithAccountStatuses: ["CLAIMED"],
    onlyWithSignonStatus: "VERIFY_EMAIL",
    accountTypeDescriptor: "Claimed - Verify Email"
  },
  {
    onlyWithAccountStatuses: ["PREFILLED"],
    onlyWithSignonStatus: undefined,
    accountTypeDescriptor: "Prefilled"
  },
  {
    onlyWithAccountStatuses: ["TRANSFERRING"],
    onlyWithSignonStatus: undefined,
    accountTypeDescriptor: "Transferring"
  },
  {
    onlyWithAccountStatuses: ["UNCLAIMED"],
    onlyWithSignonStatus: undefined,
    accountTypeDescriptor: "Connected"
  }
];

var listFilterSearchTerm = HopperState$Util.Observable.FormField.makeOptionalString({
      read: (function (param) {
          return param.listFilters.searchTerm;
        }),
      write: (function (form, searchTerm) {
          var init = form.listFilters;
          return {
                  custForm: form.custForm,
                  convertToPrefilled: form.convertToPrefilled,
                  listFilters: {
                    searchTerm: searchTerm,
                    accountTypeFilter: init.accountTypeFilter
                  },
                  offset: form.offset,
                  resendEmailVerificationForm: form.resendEmailVerificationForm,
                  url: form.url
                };
        })
    }, (function (a) {
        return {
                TAG: "Ok",
                _0: a
              };
      }), (function (a) {
        return a;
      }), {
      TAG: "Ok",
      _0: undefined
    }, undefined, undefined);

function computeFilterExpression(param) {
  var match = param.accountTypeFilter;
  return {
          signonStatus: Belt_Option.map(match.onlyWithSignonStatus, (function (signOnStatus) {
                  return {
                          equalTo: signOnStatus
                        };
                })),
          accountStatus: {
            in: match.onlyWithAccountStatuses
          },
          or: Belt_Option.flatMap(param.searchTerm.initialValue, (function (str) {
                  if (str.trim() === "") {
                    return ;
                  } else {
                    return [
                            {
                              fullName: {
                                includesInsensitive: str
                              }
                            },
                            {
                              emailString: {
                                includesInsensitive: str
                              }
                            },
                            {
                              legalDocumentFullname: {
                                includesInsensitive: str
                              }
                            },
                            {
                              loyaltyNumString: {
                                includesInsensitive: str
                              }
                            },
                            {
                              migratedEmailString: {
                                includesInsensitive: str
                              }
                            },
                            {
                              migratedAccountIdString: {
                                includesInsensitive: str
                              }
                            }
                          ];
                  }
                }))
        };
}

function blankModel(url) {
  return {
          custForm: undefined,
          convertToPrefilled: undefined,
          listFilters: {
            searchTerm: listFilterSearchTerm.blankStore,
            accountTypeFilter: defaultVisibleAccountTypes
          },
          offset: 0,
          resendEmailVerificationForm: undefined,
          url: url
        };
}

function blankCust(formTarget) {
  return {
          custFormTarget: formTarget,
          formSettings: {
            lastNameIsRequired: formTarget === "NewPrefill",
            closeOnSuccess: formTarget !== "NewPrefill",
            showPrefillLinkManagement: false
          },
          accountEmail: accountEmail.blankStore,
          preferredFirstName: preferredFirstName.blankStore,
          preferredLastName: preferredLastName.blankStore,
          phone: phone.blankStore,
          phoneCountryCode: phoneCountryCode.blankStore,
          postalCode: postalCode.blankStore,
          residenceCountry: residenceCountry.blankStore,
          halMarinerNumber: halMarinerNumber.blankStore,
          docFirstname: docFirstname.blankStore,
          docLastname: docLastname.blankStore,
          docGender: docGender.blankStore,
          docCitizenship: docCitizenship.blankStore,
          docBirthdate: docBirthdate.blankStore,
          userData: undefined,
          mutation: "NotAsked",
          fetchKey: undefined,
          prefillLink: {
            linkExpiry: prefillBfcustLinkExpiry.blankStore,
            shouldRegenerate: shouldResetBfcustPrefillLink.blankStore,
            extantLink: undefined,
            manageMutation: "NotAsked"
          },
          voyageApplication: {
            updateOtherVoyages: Belt_Set.make(CS_Slugs$Util.VoyageSlug.Comparable),
            showVoyageOptions: false,
            voyageOptions: []
          }
        };
}

function blankResendVerificationForm(targetUser) {
  return {
          targetUser: targetUser,
          resendEmailVerificationMutation: "NotAsked"
        };
}

function blankConvertToPrefilled(accountId, label) {
  return {
          accountId: accountId,
          label: label,
          targetEmail: targetEmail.blankStore
        };
}

function custView(view) {
  return HopperState$Util.Observable.makeView(view, {
              read: (function (m) {
                  return m.custForm;
                }),
              write: (function (m, custForm) {
                  return {
                          custForm: custForm,
                          convertToPrefilled: m.convertToPrefilled,
                          listFilters: m.listFilters,
                          offset: m.offset,
                          resendEmailVerificationForm: m.resendEmailVerificationForm,
                          url: m.url
                        };
                })
            }, undefined);
}

function custExn(view) {
  return HopperState$Util.Observable.makeExnOptionView(view);
}

function voyageApplicationView(pass) {
  return HopperState$Util.Observable.makeView(pass, {
              read: (function (pass) {
                  return pass.voyageApplication;
                }),
              write: (function (pass, voyageApplication) {
                  var newrecord = Caml_obj.obj_dup(pass);
                  newrecord.voyageApplication = voyageApplication;
                  return newrecord;
                })
            }, undefined);
}

function convertView(view) {
  return HopperState$Util.Observable.makeView(view, {
              read: (function (m) {
                  return m.convertToPrefilled;
                }),
              write: (function (m, convertToPrefilled) {
                  return {
                          custForm: m.custForm,
                          convertToPrefilled: convertToPrefilled,
                          listFilters: m.listFilters,
                          offset: m.offset,
                          resendEmailVerificationForm: m.resendEmailVerificationForm,
                          url: m.url
                        };
                })
            }, undefined);
}

function convertExn(view) {
  return HopperState$Util.Observable.makeExnOptionView(view);
}

var custUtils = HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.emptyContainer(), preferredFirstName), preferredLastName), phone), phoneCountryCode), postalCode), residenceCountry), halMarinerNumber), docFirstname), docLastname), docGender), docCitizenship), docBirthdate);

var convertToPrefilledUtils = HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.emptyContainer(), targetEmail);

function useIsDirty(view) {
  return HopperState$Util.Observable.FormField.useIsDirty(view, custUtils);
}

function nullFieldParam(v) {
  if (Belt_Option.isNone(v)) {
    return true;
  }
  
}

function prefill(view, c, vd) {
  var defaultFutureDateForInvites = DateOnly$Util.fromDate(DateFns.add(new Date(), {
            years: 0,
            months: 0,
            days: 15,
            hours: 0,
            minutes: 0,
            seconds: 0
          }));
  HopperState$Util.Observable.notify(view, (function (f) {
          var newrecord = Caml_obj.obj_dup(f);
          var init = f.voyageApplication;
          newrecord.voyageApplication = {
            updateOtherVoyages: init.updateOtherVoyages,
            showVoyageOptions: init.showVoyageOptions,
            voyageOptions: VoyageDetails$Thick.mapPassengersFragment(vd, undefined, undefined)
          };
          var init$1 = f.prefillLink;
          newrecord.prefillLink = {
            linkExpiry: init$1.linkExpiry,
            shouldRegenerate: init$1.shouldRegenerate,
            extantLink: Belt_Option.map(c.prefilledAccountLink, (function (param) {
                    return param.bfcustRedemptionLink;
                  })),
            manageMutation: init$1.manageMutation
          };
          return shouldResetBfcustPrefillLink.set(prefillBfcustLinkExpiry.setFromValid(docBirthdate.setFromValid(docCitizenship.setFromValid(docGender.setFromValid(docLastname.setFromValid(docFirstname.setFromValid(halMarinerNumber.setFromValid(residenceCountry.setFromValid(postalCode.setFromValid(phoneCountryCode.setFromValid(phone.setFromValid(preferredLastName.setFromValid(preferredFirstName.setFromValid(accountEmail.setFromValid((newrecord.formSettings = c.accountStatus === "PREFILLED" ? ({
                                                                                    lastNameIsRequired: true,
                                                                                    closeOnSuccess: true,
                                                                                    showPrefillLinkManagement: true
                                                                                  }) : ({
                                                                                    lastNameIsRequired: false,
                                                                                    closeOnSuccess: true,
                                                                                    showPrefillLinkManagement: false
                                                                                  }), newrecord), c.email, "InitialValueAsPrefill"), Caml_option.some(c.firstName), "InitialValueAsPrefill"), c.lastName, "InitialValueAsPrefill"), Belt_Option.flatMap(c.phone, (function (p) {
                                                                        return CS_Phone$Util.Phone.getNumber(CS_Phone$Util.Phone.toString(p));
                                                                      })), "InitialValueAsPrefill"), Belt_Option.getWithDefault(Belt_Option.flatMap(c.phone, (function (p) {
                                                                        return CS_Phone$Util.Phone.getCallingCode(CS_Phone$Util.Phone.toString(p));
                                                                      })), "+1"), "InitialValueAsPrefill"), c.postalCode, "InitialValueAsPrefill"), c.residenceCountry, "InitialValueAsPrefill"), c.halMarinerNumber, "InitialValueAsPrefill"), c.legalDocumentFirstName, "InitialValueAsPrefill"), c.legalDocumentLastName, "InitialValueAsPrefill"), Belt_Option.map(c.legalDocumentGender, decodeDocGenderExn), "InitialValueAsPrefill"), c.legalDocumentCitizenship, "InitialValueAsPrefill"), c.legalDocumentBirthdate, "InitialValueAsPrefill"), Caml_option.some(Belt_Option.getWithDefault(Belt_Option.map(c.prefilledAccountLink, (function (param) {
                                        return param.expiresAfterDateOnly;
                                      })), defaultFutureDateForInvites)), "InitialValueAsPrefill"), false, "InitialValueAsPrefill");
        }));
}

function validatePhone(form) {
  var match = phone.validate(form, undefined);
  var match$1 = phoneCountryCode.validate(form, undefined);
  if (match.TAG !== "Ok") {
    return {
            TAG: "Ok",
            _0: undefined
          };
  }
  var number = match._0;
  if (number !== undefined && match$1.TAG === "Ok") {
    return Belt_Result.map(CS_Phone$Util.Phone.fromPrimitive(match$1._0 + " " + number), (function (v) {
                  return Caml_option.some(v);
                }));
  } else {
    return {
            TAG: "Ok",
            _0: undefined
          };
  }
}

function prefillOpt(view, param) {
  var c = param[0];
  prefill(HopperState$Util.Observable.makeView(view, {
            read: (function (o) {
                return Belt_Option.getWithDefault(o, blankCust({
                                TAG: "Edit",
                                _0: c.accountId
                              }));
              }),
            write: (function (param, some) {
                return some;
              })
          }, undefined), c, param[1]);
}

function validateForAccountDataUpdate(form, targetOpt) {
  var target = targetOpt !== undefined ? targetOpt : "ValidateInputValue";
  var match = form.custFormTarget;
  var match$1 = preferredFirstName.validate(form, target);
  var match$2 = preferredLastName.validate(form, target);
  var match$3 = validatePhone(form);
  var match$4 = postalCode.validate(form, target);
  var match$5 = residenceCountry.validate(form, target);
  var match$6 = form.halMarinerNumber.value;
  var match$7 = docFirstname.validate(form, target);
  var match$8 = docLastname.validate(form, target);
  var match$9 = docGender.validate(form, target);
  var match$10 = docCitizenship.validate(form, target);
  var match$11 = docBirthdate.validate(form, target);
  if (typeof match !== "object") {
    return {
            TAG: "Error",
            _0: "Please fill out all required fields"
          };
  }
  if (match$1.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "Please fill out all required fields"
          };
  }
  if (match$2.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "Please fill out all required fields"
          };
  }
  var preferredLastName$1 = match$2._0;
  if (match$3.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "Please fill out all required fields"
          };
  }
  var phone = match$3._0;
  if (match$4.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "Please fill out all required fields"
          };
  }
  var postalCode$1 = match$4._0;
  if (match$5.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "Please fill out all required fields"
          };
  }
  var residenceCountry$1 = match$5._0;
  if (match$7.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "Please fill out all required fields"
          };
  }
  var docFirstName = match$7._0;
  if (match$8.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "Please fill out all required fields"
          };
  }
  var docLastName = match$8._0;
  if (match$9.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "Please fill out all required fields"
          };
  }
  var docGender$1 = match$9._0;
  if (match$10.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "Please fill out all required fields"
          };
  }
  var docCitizenship$1 = match$10._0;
  if (match$11.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "Please fill out all required fields"
          };
  }
  var docBirthdate$1 = match$11._0;
  var _updateOtherVoyages = Belt_Set.isEmpty(form.voyageApplication.updateOtherVoyages) ? undefined : Belt_Array.map(Belt_Set.toArray(form.voyageApplication.updateOtherVoyages), (function (v) {
            return Caml_option.some(v);
          }));
  var halMarinerNumber;
  if (match$6 !== undefined && match$6 !== "") {
    var v = CS_LoyaltyAndCruiselineNumbers$Util.HalMarinerNumber.fromPrimitive(match$6);
    halMarinerNumber = v.TAG === "Ok" ? Caml_option.some(v._0) : undefined;
  } else {
    halMarinerNumber = undefined;
  }
  return {
          TAG: "Ok",
          _0: {
            _accountId: match._0,
            _patch: {
              preferredFirstName: match$1._0,
              preferredLastName: preferredLastName$1,
              halMarinerNumber: halMarinerNumber,
              phone: phone,
              residenceCountry: residenceCountry$1,
              postalCode: postalCode$1,
              docFirstName: docFirstName,
              docLastName: docLastName,
              docBirthdate: docBirthdate$1,
              docGender: docGender$1,
              docCitizenship: docCitizenship$1,
              preferredLastNameNull: nullFieldParam(preferredLastName$1),
              halMarinerNumberNull: nullFieldParam(halMarinerNumber),
              phoneNull: nullFieldParam(phone),
              residenceCountryNull: nullFieldParam(residenceCountry$1),
              postalCodeNull: nullFieldParam(postalCode$1),
              docFirstNameNull: nullFieldParam(docFirstName),
              docLastNameNull: nullFieldParam(docLastName),
              docBirthdateNull: nullFieldParam(docBirthdate$1),
              docGenderNull: nullFieldParam(docGender$1),
              docCitizenshipNull: nullFieldParam(docCitizenship$1)
            },
            _updateProfile: true,
            _updateOtherVoyages: _updateOtherVoyages
          }
        };
}

function validateForPrefillAccountCreation(form, brandFamily, targetOpt) {
  var target = targetOpt !== undefined ? targetOpt : "ValidateInputValue";
  var match = form.custFormTarget;
  var match$1 = accountEmail.validate(form, target);
  var match$2 = preferredFirstName.validate(form, target);
  var match$3 = preferredLastName.validate(form, target);
  var match$4 = validatePhone(form);
  var match$5 = postalCode.validate(form, target);
  var match$6 = residenceCountry.validate(form, target);
  var match$7 = form.halMarinerNumber.value;
  var match$8 = docFirstname.validate(form, target);
  var match$9 = docLastname.validate(form, target);
  var match$10 = docGender.validate(form, target);
  var match$11 = docCitizenship.validate(form, target);
  var match$12 = docBirthdate.validate(form, target);
  if (typeof match === "object") {
    return {
            TAG: "Error",
            _0: "Please fill out all required fields"
          };
  }
  if (match$1.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "Please fill out all required fields"
          };
  }
  var email = match$1._0;
  if (email === undefined) {
    return {
            TAG: "Error",
            _0: "Please fill out all required fields"
          };
  }
  if (match$2.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "Please fill out all required fields"
          };
  }
  var preferredFirstName$1 = match$2._0;
  if (preferredFirstName$1 === undefined) {
    return {
            TAG: "Error",
            _0: "Please fill out all required fields"
          };
  }
  if (match$3.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "Please fill out all required fields"
          };
  }
  var preferredLastName$1 = match$3._0;
  if (preferredLastName$1 === undefined) {
    return {
            TAG: "Error",
            _0: "Please fill out all required fields"
          };
  }
  if (match$4.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "Please fill out all required fields"
          };
  }
  if (match$5.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "Please fill out all required fields"
          };
  }
  if (match$6.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "Please fill out all required fields"
          };
  }
  if (match$8.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "Please fill out all required fields"
          };
  }
  if (match$9.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "Please fill out all required fields"
          };
  }
  if (match$10.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "Please fill out all required fields"
          };
  }
  if (match$11.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "Please fill out all required fields"
          };
  }
  if (match$12.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "Please fill out all required fields"
          };
  }
  var halMarinerNumber;
  if (match$7 !== undefined && match$7 !== "") {
    var v = CS_LoyaltyAndCruiselineNumbers$Util.HalMarinerNumber.fromPrimitive(match$7);
    halMarinerNumber = v.TAG === "Ok" ? Caml_option.some(v._0) : undefined;
  } else {
    halMarinerNumber = undefined;
  }
  return {
          TAG: "Ok",
          _0: {
            toBrandFamilySlug: brandFamily,
            newPrefill: {
              email: Caml_option.valFromOption(email),
              preferredFirstName: Caml_option.valFromOption(preferredFirstName$1),
              preferredLastName: Caml_option.valFromOption(preferredLastName$1),
              halMarinerNumber: halMarinerNumber,
              phone: match$4._0,
              residenceCountry: match$6._0,
              postalCode: match$5._0,
              docFirstName: match$8._0,
              docLastName: match$9._0,
              docBirthdate: match$12._0,
              docGender: match$10._0,
              docCitizenship: match$11._0
            }
          }
        };
}

function validateForPrefillLinkManagement(form, brandFamily, targetOpt) {
  var target = targetOpt !== undefined ? targetOpt : "ValidateInputValue";
  var match = form.custFormTarget;
  var match$1 = prefillBfcustLinkExpiry.validate(form, target);
  var match$2 = shouldResetBfcustPrefillLink.validate(form, target);
  if (typeof match !== "object") {
    return {
            TAG: "Error",
            _0: "Please fill out all required fields"
          };
  }
  if (match$1.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "Please fill out all required fields"
          };
  }
  var expiresOnDate = match$1._0;
  if (expiresOnDate !== undefined && match$2.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              onBrandFamily: brandFamily,
              prefilledAccountId: match._0,
              expiresOnDate: Caml_option.valFromOption(expiresOnDate),
              regenerateToken: match$2._0
            }
          };
  } else {
    return {
            TAG: "Error",
            _0: "Please fill out all required fields"
          };
  }
}

function validateForConvertToPrefilled(form, brandFamily, targetOpt) {
  var target = targetOpt !== undefined ? targetOpt : "ValidateInputValue";
  var match = targetEmail.validate(form, target);
  if (match.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "Please enter a valid email address"
          };
  }
  var email = match._0;
  if (email !== undefined) {
    return {
            TAG: "Ok",
            _0: {
              onBrandFamily: brandFamily,
              connectedAccountId: form.accountId,
              targetEmail: Caml_option.valFromOption(email)
            }
          };
  } else {
    return {
            TAG: "Error",
            _0: "Please enter a valid email address"
          };
  }
}

function queryStateLens_read(m) {
  return m;
}

function queryStateLens_write(param, m) {
  return m;
}

var queryStateLens = {
  read: queryStateLens_read,
  write: queryStateLens_write
};

function parser(url) {
  var fieldToString = function (field) {
    return "acct." + field;
  };
  var docGenderInputType = QueryString$Util.Codec.customStringable({
        legalVals: [
          "M",
          "F",
          "X"
        ],
        toString: docGenderToString
      });
  var formLens_read = function (param) {
    return param.custForm;
  };
  var formLens_write = function (m, a) {
    return {
            custForm: a,
            convertToPrefilled: m.convertToPrefilled,
            listFilters: m.listFilters,
            offset: m.offset,
            resendEmailVerificationForm: m.resendEmailVerificationForm,
            url: m.url
          };
  };
  var formLens = {
    read: formLens_read,
    write: formLens_write
  };
  var formField = function (param, field, token) {
    var changedInputLens = param.changedInputLens;
    var chained = HopperState$Util.Lens.chain(formLens, {
          read: (function (oForm) {
              return Belt_Option.flatMap(oForm, (function (form) {
                            return changedInputLens.read(form);
                          }));
            }),
          write: (function (oForm, oVal) {
              if (oForm === undefined && oVal === undefined) {
                return ;
              }
              return changedInputLens.write(Belt_Option.getWithDefault(oForm, blankCust("NewPrefill")), oVal);
            })
        });
    return QueryString$Util.Param.Singular.make(field, fieldToString, QueryString$Util.Codec.scalar(token), undefined, (function (parent, opt) {
                  return chained.write(parent, opt);
                }), (function (v) {
                  return chained.read(v);
                }));
  };
  var accountEmail$1 = formField(accountEmail, "email", QueryString$Util.Codec.string);
  var preferredFirstName$1 = formField(preferredFirstName, "pfirst", QueryString$Util.Codec.string);
  var preferredLastName$1 = formField(preferredLastName, "plast", QueryString$Util.Codec.string);
  var phone$1 = formField(phone, "phone", QueryString$Util.Codec.string);
  var phoneCountryCode$1 = formField(phoneCountryCode, "pCC", QueryString$Util.Codec.required(QueryString$Util.Codec.string));
  var postalCode$1 = formField(postalCode, "zip", QueryString$Util.Codec.string);
  var residenceCountry$1 = formField(residenceCountry, "residence", QueryString$Util.Codec.customScalar(CS_ISO3166_1$Util.Alpha3));
  var halMarinerNumber$1 = formField(halMarinerNumber, "mariner", QueryString$Util.Codec.string);
  var docFirstname$1 = formField(docFirstname, "dfirst", QueryString$Util.Codec.string);
  var docLastName = formField(docFirstname, "dlast", QueryString$Util.Codec.string);
  var docCitizenship$1 = formField(docCitizenship, "dctz", QueryString$Util.Codec.customScalar(CS_ISO3166_1$Util.Alpha3));
  var docBirthdate$1 = formField(docBirthdate, "dbday", QueryString$Util.Codec.string);
  var docGender$1 = formField(docGender, "dgender", docGenderInputType);
  return QueryString$Util.Parser.makeExtended([
              QueryString$Util.Param.Singular.make("search", fieldToString, QueryString$Util.Codec.scalar(QueryString$Util.Codec.string), undefined, (function (model, o) {
                      return listFilterSearchTerm.set(model, Belt_Option.flatMap(o, (function (v) {
                                        return v;
                                      })), undefined);
                    }), (function (model) {
                      return Caml_option.some(listFilterSearchTerm.getValue(model));
                    })),
              QueryString$Util.Param.Singular.make("acctFilter", fieldToString, accountTypeFilterCodec, undefined, (function (model, o) {
                      var init = model.listFilters;
                      return {
                              custForm: model.custForm,
                              convertToPrefilled: model.convertToPrefilled,
                              listFilters: {
                                searchTerm: init.searchTerm,
                                accountTypeFilter: Belt_Option.mapWithDefault(o, defaultVisibleAccountTypes, (function (param) {
                                        return {
                                                onlyWithAccountStatuses: param[0],
                                                onlyWithSignonStatus: param[1]
                                              };
                                      }))
                              },
                              offset: model.offset,
                              resendEmailVerificationForm: model.resendEmailVerificationForm,
                              url: model.url
                            };
                    }), (function (param) {
                      var match = param.listFilters.accountTypeFilter;
                      return [
                              match.onlyWithAccountStatuses,
                              match.onlyWithSignonStatus
                            ];
                    })),
              QueryString$Util.Param.Singular.make("edit", fieldToString, QueryString$Util.Codec.scalar(QueryString$Util.Codec.required(QueryString$Util.Codec.customScalar(CS_NonemptyStrings$Util.AccountId))), undefined, (function (model, opt) {
                      if (opt === undefined) {
                        return {
                                custForm: undefined,
                                convertToPrefilled: model.convertToPrefilled,
                                listFilters: model.listFilters,
                                offset: model.offset,
                                resendEmailVerificationForm: model.resendEmailVerificationForm,
                                url: model.url
                              };
                      }
                      var accountId = Caml_option.valFromOption(opt);
                      var newrecord = Caml_obj.obj_dup(Belt_Option.getWithDefault(model.custForm, blankCust({
                                    TAG: "Edit",
                                    _0: accountId
                                  })));
                      return {
                              custForm: (newrecord.custFormTarget = {
                                  TAG: "Edit",
                                  _0: accountId
                                }, newrecord),
                              convertToPrefilled: model.convertToPrefilled,
                              listFilters: model.listFilters,
                              offset: model.offset,
                              resendEmailVerificationForm: model.resendEmailVerificationForm,
                              url: model.url
                            };
                    }), (function (model) {
                      return Belt_Option.flatMap(model.custForm, (function (cf) {
                                    var accountId = cf.custFormTarget;
                                    if (typeof accountId !== "object") {
                                      return ;
                                    } else {
                                      return Caml_option.some(accountId._0);
                                    }
                                  }));
                    })),
              QueryString$Util.Param.Singular.make("new", fieldToString, QueryString$Util.Codec.scalar(QueryString$Util.Codec.required(QueryString$Util.Codec.bool)), undefined, (function (model, bopt) {
                      if (!Belt_Option.getWithDefault(bopt, false)) {
                        return model;
                      }
                      var newrecord = Caml_obj.obj_dup(Belt_Option.getWithDefault(model.custForm, blankCust("NewPrefill")));
                      return {
                              custForm: (newrecord.custFormTarget = "NewPrefill", newrecord),
                              convertToPrefilled: model.convertToPrefilled,
                              listFilters: model.listFilters,
                              offset: model.offset,
                              resendEmailVerificationForm: model.resendEmailVerificationForm,
                              url: model.url
                            };
                    }), (function (model) {
                      return Belt_Option.flatMap(model.custForm, (function (param) {
                                    var tmp = param.custFormTarget;
                                    if (typeof tmp !== "object") {
                                      return true;
                                    }
                                    
                                  }));
                    })),
              QueryString$Util.Param.Singular.make("p", fieldToString, QueryString$Util.Codec.scalar(QueryString$Util.Codec.required(QueryString$Util.Codec.$$int)), undefined, (function (model, offset) {
                      return {
                              custForm: model.custForm,
                              convertToPrefilled: model.convertToPrefilled,
                              listFilters: model.listFilters,
                              offset: Belt_Option.getWithDefault(offset, 0),
                              resendEmailVerificationForm: model.resendEmailVerificationForm,
                              url: model.url
                            };
                    }), (function (model) {
                      return model.offset;
                    })),
              accountEmail$1,
              preferredFirstName$1,
              preferredLastName$1,
              phone$1,
              phoneCountryCode$1,
              postalCode$1,
              residenceCountry$1,
              halMarinerNumber$1,
              docBirthdate$1,
              docFirstname$1,
              docLastName,
              docCitizenship$1,
              docBirthdate$1,
              docGender$1
            ], blankModel(url));
}

var QueryString = {
  queryStateLens: queryStateLens,
  parser: parser
};

function optionalEmailsSame(a, b) {
  var match = Belt_Option.map(a, (function (e) {
          return CS_Emails$Util.Email.toString(e).toLowerCase();
        }));
  var match$1 = Belt_Option.map(b, (function (e) {
          return CS_Emails$Util.Email.toString(e).toLowerCase();
        }));
  if (match !== undefined && match$1 !== undefined) {
    return match === match$1;
  } else {
    return false;
  }
}

var makeSchema = convertToPrefilledUtils.makeSchema;

exports.UpdateAccountMutation = UpdateAccountMutation;
exports.PersistPrefillMutation = PersistPrefillMutation;
exports.ManagePrefillMutation = ManagePrefillMutation;
exports.ResendEmailVerificationMutation = ResendEmailVerificationMutation;
exports.CustomerFragment = CustomerFragment;
exports.FetchCustomer = FetchCustomer;
exports.decodeDocGender = decodeDocGender;
exports.decodeDocGenderExn = decodeDocGenderExn;
exports.docGenderToString = docGenderToString;
exports.docGenderFromString = docGenderFromString;
exports.allLegalDocGenders = allLegalDocGenders;
exports.docGenderNiceName = docGenderNiceName;
exports.accountEmail = accountEmail;
exports.preferredFirstName = preferredFirstName;
exports.preferredLastName = preferredLastName;
exports.phone = phone;
exports.phoneCountryCode = phoneCountryCode;
exports.postalCode = postalCode;
exports.residenceCountry = residenceCountry;
exports.halMarinerNumber = halMarinerNumber;
exports.docFirstname = docFirstname;
exports.docLastname = docLastname;
exports.docGender = docGender;
exports.docCitizenship = docCitizenship;
exports.docBirthdate = docBirthdate;
exports.prefillBfcustLinkExpiry = prefillBfcustLinkExpiry;
exports.shouldResetBfcustPrefillLink = shouldResetBfcustPrefillLink;
exports.targetEmail = targetEmail;
exports.allFilterableAccountStatuses = allFilterableAccountStatuses;
exports.accountTypeFilterCodec = accountTypeFilterCodec;
exports.accountTypeFilterToString = accountTypeFilterToString;
exports.accountTypeFilterFromString = accountTypeFilterFromString;
exports.accountTypeFilterMetaToString = accountTypeFilterMetaToString;
exports.defaultVisibleAccountTypes = defaultVisibleAccountTypes;
exports.accountTypeFiltrationOptions = accountTypeFiltrationOptions;
exports.listFilterSearchTerm = listFilterSearchTerm;
exports.computeFilterExpression = computeFilterExpression;
exports.blankModel = blankModel;
exports.blankCust = blankCust;
exports.blankResendVerificationForm = blankResendVerificationForm;
exports.blankConvertToPrefilled = blankConvertToPrefilled;
exports.custView = custView;
exports.custExn = custExn;
exports.voyageApplicationView = voyageApplicationView;
exports.convertView = convertView;
exports.convertExn = convertExn;
exports.custUtils = custUtils;
exports.makeSchema = makeSchema;
exports.convertToPrefilledUtils = convertToPrefilledUtils;
exports.useIsDirty = useIsDirty;
exports.nullFieldParam = nullFieldParam;
exports.prefill = prefill;
exports.validatePhone = validatePhone;
exports.prefillOpt = prefillOpt;
exports.validateForAccountDataUpdate = validateForAccountDataUpdate;
exports.validateForPrefillAccountCreation = validateForPrefillAccountCreation;
exports.validateForPrefillLinkManagement = validateForPrefillLinkManagement;
exports.validateForConvertToPrefilled = validateForConvertToPrefilled;
exports.QueryString = QueryString;
exports.optionalEmailsSame = optionalEmailsSame;
/* commitMutation Not a pure module */
