// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Set = require("rescript/lib/js/belt_Set.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Core__Array = require("@rescript/core/src/Core__Array.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var AppConfig_frag_graphql$Thick = require("../__generated__/AppConfig_frag_graphql.bs.js");
var AppConfigFragment_graphql$Thick = require("../__generated__/AppConfigFragment_graphql.bs.js");
var AppConfigVoyages_frag_graphql$Thick = require("../__generated__/AppConfigVoyages_frag_graphql.bs.js");
var AppConfigBrandFamilies_query_graphql$Thick = require("../__generated__/AppConfigBrandFamilies_query_graphql.bs.js");
var AppConfigBookingChannels_frag_graphql$Thick = require("../__generated__/AppConfigBookingChannels_frag_graphql.bs.js");

var convertFragment = AppConfigBrandFamilies_query_graphql$Thick.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(AppConfigBrandFamilies_query_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, AppConfigBrandFamilies_query_graphql$Thick.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(AppConfigBrandFamilies_query_graphql$Thick.node, convertFragment, fRef);
}

var Fragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

function use$1(fragment) {
  var brandFamilies = Belt_Option.getExn(Belt_Option.map(use(fragment).currentUserBrandFamilies, (function (param) {
              return Belt_Array.keepMap(param.nodes, (function (param) {
                            var slug = param.brandFamilySlug;
                            var name = param.brandFamilyName;
                            if (slug !== undefined && name !== undefined) {
                              return {
                                      brandFamilySlug: Caml_option.valFromOption(slug),
                                      brandFamilyName: CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(name))
                                    };
                            }
                            
                          }));
            })));
  var bset = Belt_Set.fromArray(Belt_Array.map(brandFamilies, (function (param) {
              return param.brandFamilySlug;
            })), CS_Slugs$Util.BrandFamilySlug.Comparable);
  var checkBrandFamily = function (str) {
    return Belt_Result.flatMap(CS_Slugs$Util.BrandFamilySlug.fromPrimitive(str), (function (slug) {
                  if (Belt_Set.has(bset, slug)) {
                    return {
                            TAG: "Ok",
                            _0: slug
                          };
                  } else {
                    return {
                            TAG: "Error",
                            _0: "This voyage does not exist"
                          };
                  }
                }));
  };
  var getBrandFamilyName = function (slug) {
    return Core__Array.findMap(brandFamilies, (function (b) {
                  if (Caml_obj.equal(b.brandFamilySlug, slug)) {
                    return b.brandFamilyName;
                  }
                  
                }));
  };
  return {
          brandFamilies: brandFamilies,
          checkBrandFamily: checkBrandFamily,
          getBrandFamilyName: getBrandFamilyName
        };
}

var BrandFamilies = {
  Fragment: Fragment,
  use: use$1
};

var convertFragment$1 = AppConfigVoyages_frag_graphql$Thick.Internal.convertFragment;

function use$2(fRef) {
  return RescriptRelay_Fragment.useFragment(AppConfigVoyages_frag_graphql$Thick.node, convertFragment$1, fRef);
}

function useOpt$1(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, AppConfigVoyages_frag_graphql$Thick.node, convertFragment$1);
}

function readResolverFragment$1(fRef) {
  return RescriptRelay_Fragment.read(AppConfigVoyages_frag_graphql$Thick.node, convertFragment$1, fRef);
}

var Fragment$1 = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$1,
  use: use$2,
  useOpt: useOpt$1,
  readResolverFragment: readResolverFragment$1
};

function use$3(fragment) {
  var allVoyages = Belt_Array.map(Belt_Option.mapWithDefault(use$2(fragment).brandFamilyVoyages, [], (function (param) {
              return param.nodes;
            })), (function (param) {
          var voyageSlug = param.voyageSlug;
          return {
                  voyageSlug: voyageSlug,
                  voyageName: Belt_Option.getWithDefault(Belt_Option.map(param.voyageName, CS_NonemptyStrings$Util.NonEmptyString.toString), CS_Slugs$Util.VoyageSlug.toString(voyageSlug)),
                  voyStartDate: param.voyStartDate,
                  brandFamilySlug: param.brandFamilySlug
                };
        }));
  var vset = Belt_Set.fromArray(Belt_Array.map(allVoyages, (function (param) {
              return param.voyageSlug;
            })), CS_Slugs$Util.VoyageSlug.Comparable);
  var checkVoyageSlug = function (str) {
    return Belt_Result.flatMap(CS_Slugs$Util.VoyageSlug.fromPrimitive(str), (function (slug) {
                  if (Belt_Set.has(vset, slug)) {
                    return {
                            TAG: "Ok",
                            _0: slug
                          };
                  } else {
                    return {
                            TAG: "Error",
                            _0: "This voyage does not exist"
                          };
                  }
                }));
  };
  var getVoyageName = function (slug) {
    return Core__Array.findMap(allVoyages, (function (v) {
                  if (Caml_obj.equal(v.voyageSlug, slug)) {
                    return v.voyageName;
                  }
                  
                }));
  };
  var getVoyageData = function (slug) {
    return Core__Array.findMap(allVoyages, (function (v) {
                  if (Caml_obj.equal(v.voyageSlug, slug)) {
                    return v;
                  }
                  
                }));
  };
  var voyageIsInBrandFam = function (voy, bf) {
    var match = Belt_Array.get(Belt_Array.keep(allVoyages, (function (v) {
                return CS_Slugs$Util.VoyageSlug.toString(v.voyageSlug) === voy;
              })), 0);
    if (match !== undefined) {
      return CS_Slugs$Util.BrandFamilySlug.toString(match.brandFamilySlug) === bf;
    } else {
      return false;
    }
  };
  return {
          allVoyages: allVoyages,
          checkVoyageSlug: checkVoyageSlug,
          getVoyageName: getVoyageName,
          getVoyageData: getVoyageData,
          voyageIsInBrandFam: voyageIsInBrandFam
        };
}

var Voyages = {
  Fragment: Fragment$1,
  use: use$3
};

var convertFragment$2 = AppConfigBookingChannels_frag_graphql$Thick.Internal.convertFragment;

function use$4(fRef) {
  return RescriptRelay_Fragment.useFragment(AppConfigBookingChannels_frag_graphql$Thick.node, convertFragment$2, fRef);
}

function useOpt$2(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, AppConfigBookingChannels_frag_graphql$Thick.node, convertFragment$2);
}

function readResolverFragment$2(fRef) {
  return RescriptRelay_Fragment.read(AppConfigBookingChannels_frag_graphql$Thick.node, convertFragment$2, fRef);
}

var Fragment$2 = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$2,
  use: use$4,
  useOpt: useOpt$2,
  readResolverFragment: readResolverFragment$2
};

function use$5(fragment) {
  var allBookingChannels = Belt_Array.map(Belt_Option.mapWithDefault(use$4(fragment).bookingChannelFareClasses, [], (function (param) {
              return param.nodes;
            })), (function (param) {
          return {
                  brandFamilySlug: param.brandFamilySlug,
                  voyageSlug: param.voyageSlug,
                  bookingChannelSlug: param.bookingChannelSlug
                };
        }));
  var getBookingChannelsByBrandFamAndVoyage = function (bf, voy) {
    return Belt_Array.reduce(Belt_Array.map(Belt_Array.keep(allBookingChannels, (function (param) {
                          if (Caml_obj.equal(param.brandFamilySlug, bf)) {
                            return Caml_obj.equal(param.voyageSlug, voy);
                          } else {
                            return false;
                          }
                        })), (function (param) {
                      return param.bookingChannelSlug;
                    })), [], (function (acc, v) {
                  if (Belt_Array.some(acc, (function (a) {
                            return a === v;
                          }))) {
                    return acc;
                  } else {
                    return Belt_Array.concat(acc, [v]);
                  }
                }));
  };
  return {
          allBookingChannels: allBookingChannels,
          getBookingChannelsByBrandFamAndVoyage: getBookingChannelsByBrandFamAndVoyage
        };
}

var BookingChannels = {
  Fragment: Fragment$2,
  use: use$5
};

var convertFragment$3 = AppConfig_frag_graphql$Thick.Internal.convertFragment;

function use$6(fRef) {
  return RescriptRelay_Fragment.useFragment(AppConfig_frag_graphql$Thick.node, convertFragment$3, fRef);
}

function useOpt$3(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, AppConfig_frag_graphql$Thick.node, convertFragment$3);
}

function readResolverFragment$3(fRef) {
  return RescriptRelay_Fragment.read(AppConfig_frag_graphql$Thick.node, convertFragment$3, fRef);
}

var makeRefetchVariables = AppConfigFragment_graphql$Thick.Types.makeRefetchVariables;

var convertRefetchVariables = AppConfigFragment_graphql$Thick.Internal.convertVariables;

function useRefetchable(fRef) {
  return RescriptRelay_Fragment.useRefetchableFragment(AppConfig_frag_graphql$Thick.node, convertFragment$3, convertRefetchVariables, fRef);
}

var Fragment$3 = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$3,
  use: use$6,
  useOpt: useOpt$3,
  readResolverFragment: readResolverFragment$3,
  makeRefetchVariables: makeRefetchVariables,
  convertRefetchVariables: convertRefetchVariables,
  useRefetchable: useRefetchable
};

function useQuery(fragmentRefs) {
  var match = useRefetchable(fragmentRefs);
  var refetch = match[1];
  var query = match[0];
  return {
          brandFamilies: use$1(query.fragmentRefs),
          voyages: use$3(query.fragmentRefs),
          bookingChannels: use$5(query.fragmentRefs),
          refetchAppConfig: (function () {
              return refetch(makeRefetchVariables(), "store-and-network", undefined);
            })
        };
}

var defaultContextWhichIsBroken = React.createContext("ERROR");

function use$7() {
  return React.useContext(defaultContextWhichIsBroken);
}

var make = defaultContextWhichIsBroken.Provider;

var Provider = {
  make: make
};

var Context = {
  defaultContextWhichIsBroken: defaultContextWhichIsBroken,
  use: use$7,
  Provider: Provider
};

exports.BrandFamilies = BrandFamilies;
exports.Voyages = Voyages;
exports.BookingChannels = BookingChannels;
exports.Fragment = Fragment$3;
exports.useQuery = useQuery;
exports.Context = Context;
/* defaultContextWhichIsBroken Not a pure module */
