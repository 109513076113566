// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var Styles = require("@mui/joy/styles");
var AppConfig$Thick = require("./context/AppConfig.bs.js");
var TeamRoute$Thick = require("./routes/TeamRoute.bs.js");
var LoginRoute$Thick = require("./routes/LoginRoute.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var AccountRoute$Thick = require("./routes/AccountRoute.bs.js");
var DeckMapRoute$Thick = require("./routes/DeckMapRoute.bs.js");
var BookingsRoute$Thick = require("./routes/BookingsRoute.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var WaitlistRoute$Thick = require("./routes/WaitlistRoute.bs.js");
var DashboardRoute$Thick = require("./routes/DashboardRoute.bs.js");
var PassengersRoute$Thick = require("./routes/PassengersRoute.bs.js");
var BrandFamilyRoute$Thick = require("./routes/BrandFamilyRoute.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var MergeAccountsRoute$Thick = require("./routes/MergeAccountsRoute.bs.js");
var BookingDetailsRoute$Thick = require("./routes/BookingDetailsRoute.bs.js");
var CabinInventoryRoute$Thick = require("./routes/CabinInventoryRoute.bs.js");
var ForgotPasswordRoute$Thick = require("./routes/ForgotPasswordRoute.bs.js");
var VoyageSettingsRoute$Thick = require("./routes/VoyageSettingsRoute.bs.js");
var BookingSessionsRoute$Thick = require("./routes/BookingSessionsRoute.bs.js");
var VoyageDashboardRoute$Thick = require("./routes/VoyageDashboardRoute.bs.js");
var CustomerAccountsRoute$Thick = require("./routes/CustomerAccountsRoute.bs.js");
var ThickAppQuery_graphql$Thick = require("./__generated__/ThickAppQuery_graphql.bs.js");
var LatestBookingVersionRedirectRoute$Thick = require("./routes/LatestBookingVersionRedirectRoute.bs.js");

function ThickApp$RedirectToLogin(props) {
  React.useEffect((function () {
          RescriptReactRouter.push("/login");
        }), []);
  return JsxRuntime.jsx("div", {});
}

var RedirectToLogin = {
  make: ThickApp$RedirectToLogin
};

var convertVariables = ThickAppQuery_graphql$Thick.Internal.convertVariables;

var convertResponse = ThickAppQuery_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = ThickAppQuery_graphql$Thick.Internal.convertWrapRawResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, ThickAppQuery_graphql$Thick.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, ThickAppQuery_graphql$Thick.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(ThickAppQuery_graphql$Thick.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(ThickAppQuery_graphql$Thick.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(ThickAppQuery_graphql$Thick.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(ThickAppQuery_graphql$Thick.node, convertVariables);

var ThickAppQuery = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function setDocTitle(title) {
  return (document.title=title);
}

function ThickApp(props) {
  var urlInitial = RescriptReactRouter.useUrl(undefined, undefined);
  var url = React.useDeferredValue(urlInitial);
  var fragmentRefs = use(undefined, undefined, undefined, undefined).query.fragmentRefs;
  var appConfig = AppConfig$Thick.useQuery(fragmentRefs);
  var refetchAppConfig = appConfig.refetchAppConfig;
  var match = appConfig.voyages;
  var voyageIsInBrandFam = match.voyageIsInBrandFam;
  var getVoyageData = match.getVoyageData;
  var getVoyageName = match.getVoyageName;
  var checkVoyageSlug = match.checkVoyageSlug;
  var match$1 = appConfig.brandFamilies;
  var getBrandFamilyName = match$1.getBrandFamilyName;
  var checkBrandFamily = match$1.checkBrandFamily;
  var checkVoyAgainstBrandFam = function (v, b) {
    if (Belt_Result.isOk(checkVoyageSlug(v)) && Belt_Result.isOk(checkBrandFamily(b))) {
      return voyageIsInBrandFam(v, b);
    } else {
      return false;
    }
  };
  var match$2 = url.path;
  var match$3;
  var exit = 0;
  if (match$2) {
    var brandFamily = match$2.hd;
    var exit$1 = 0;
    switch (brandFamily) {
      case "account" :
          if (match$2.tl) {
            exit$1 = 2;
          } else {
            match$3 = [
              "Account",
              (function () {
                  return JsxRuntime.jsx(AccountRoute$Thick.make, {});
                })
            ];
          }
          break;
      case "dashboard" :
          if (match$2.tl) {
            exit$1 = 2;
          } else {
            match$3 = [
              "Dashboard",
              (function () {
                  return JsxRuntime.jsx(DashboardRoute$Thick.make, {
                              brandFamilies: appConfig.brandFamilies
                            });
                })
            ];
          }
          break;
      case "forgot-password" :
          if (match$2.tl) {
            exit$1 = 2;
          } else {
            match$3 = [
              "Forgot Password",
              (function () {
                  return JsxRuntime.jsx(ForgotPasswordRoute$Thick.make, {});
                })
            ];
          }
          break;
      case "login" :
          if (match$2.tl) {
            exit$1 = 2;
          } else {
            match$3 = [
              "Login",
              (function () {
                  return JsxRuntime.jsx(LoginRoute$Thick.make, {
                              refetchAppConfig: refetchAppConfig
                            });
                })
            ];
          }
          break;
      default:
        exit$1 = 2;
    }
    if (exit$1 === 2) {
      var match$4 = match$2.tl;
      if (match$4) {
        var voyage = match$4.hd;
        var exit$2 = 0;
        switch (voyage) {
          case "customers" :
              if (match$4.tl || !Belt_Result.isOk(checkBrandFamily(brandFamily))) {
                exit$2 = 3;
              } else {
                match$3 = [
                  "Customer Accounts | Project Hopper",
                  (function () {
                      return JsxRuntime.jsx(CustomerAccountsRoute$Thick.make, {
                                  brandFamily: CS_Slugs$Util.BrandFamilySlug.Exn.fromPrimitive(brandFamily)
                                });
                    })
                ];
              }
              break;
          case "merge-accounts" :
              if (match$4.tl || !Belt_Result.isOk(checkBrandFamily(brandFamily))) {
                exit$2 = 3;
              } else {
                match$3 = [
                  "Merge Customer Accounts | Project Hopper",
                  (function () {
                      return JsxRuntime.jsx(MergeAccountsRoute$Thick.make, {
                                  brandFamily: CS_Slugs$Util.BrandFamilySlug.Exn.fromPrimitive(brandFamily)
                                });
                    })
                ];
              }
              break;
          case "team" :
              if (match$4.tl || !Belt_Result.isOk(checkBrandFamily(brandFamily))) {
                exit$2 = 3;
              } else {
                var name = getBrandFamilyName(Belt_Result.getExn(checkBrandFamily(brandFamily)));
                match$3 = [
                  name !== undefined ? "Team | " + name : "Team | " + brandFamily,
                  (function () {
                      return JsxRuntime.jsx(TeamRoute$Thick.make, {
                                  brandFamily: CS_Slugs$Util.BrandFamilySlug.Exn.fromPrimitive(brandFamily)
                                });
                    })
                ];
              }
              break;
          default:
            exit$2 = 3;
        }
        if (exit$2 === 3) {
          var match$5 = match$4.tl;
          if (match$5) {
            switch (match$5.hd) {
              case "booking-sessions" :
                  if (match$5.tl || !checkVoyAgainstBrandFam(voyage, brandFamily)) {
                    exit = 1;
                  } else {
                    var name$1 = getVoyageName(Belt_Result.getExn(checkVoyageSlug(voyage)));
                    match$3 = [
                      name$1 !== undefined ? "Booking Sessions | " + name$1 : "Booking Sessions | " + voyage,
                      (function () {
                          return JsxRuntime.jsx(BookingSessionsRoute$Thick.make, {
                                      brandFamily: CS_Slugs$Util.BrandFamilySlug.Exn.fromPrimitive(brandFamily),
                                      voyage: CS_Slugs$Util.VoyageSlug.Exn.fromPrimitive(voyage)
                                    });
                        })
                    ];
                  }
                  break;
              case "bookings" :
                  var match$6 = match$5.tl;
                  if (match$6) {
                    var match$7 = match$6.tl;
                    var bookingId = match$6.hd;
                    if (match$7) {
                      if (match$7.tl) {
                        exit = 1;
                      } else {
                        var bookingVersionNumber = match$7.hd;
                        if (checkVoyAgainstBrandFam(voyage, brandFamily)) {
                          var name$2 = getVoyageName(Belt_Result.getExn(checkVoyageSlug(voyage)));
                          match$3 = [
                            name$2 !== undefined ? bookingId + " | " + name$2 : bookingId + " | " + voyage,
                            (function () {
                                return JsxRuntime.jsx(BookingDetailsRoute$Thick.make, {
                                            bookingId: CS_NonemptyStrings$Util.BookingId.Exn.fromPrimitive(bookingId),
                                            voyage: CS_Slugs$Util.VoyageSlug.Exn.fromPrimitive(voyage),
                                            brandFamily: CS_Slugs$Util.BrandFamilySlug.Exn.fromPrimitive(brandFamily),
                                            bookingVersionNumber: bookingVersionNumber
                                          });
                              })
                          ];
                        } else {
                          exit = 1;
                        }
                      }
                    } else if (checkVoyAgainstBrandFam(voyage, brandFamily)) {
                      match$3 = [
                        "Redirecting...",
                        (function () {
                            return JsxRuntime.jsx(LatestBookingVersionRedirectRoute$Thick.make, {
                                        brandFamily: CS_Slugs$Util.BrandFamilySlug.Exn.fromPrimitive(brandFamily),
                                        voyage: CS_Slugs$Util.VoyageSlug.Exn.fromPrimitive(voyage),
                                        bookingId: CS_NonemptyStrings$Util.BookingId.Exn.fromPrimitive(bookingId)
                                      });
                          })
                      ];
                    } else {
                      exit = 1;
                    }
                  } else if (checkVoyAgainstBrandFam(voyage, brandFamily)) {
                    var name$3 = getVoyageName(Belt_Result.getExn(checkVoyageSlug(voyage)));
                    match$3 = [
                      name$3 !== undefined ? "Bookings | " + name$3 : "Bookings | " + voyage,
                      (function () {
                          return JsxRuntime.jsx(BookingsRoute$Thick.make, {
                                      brandFamily: CS_Slugs$Util.BrandFamilySlug.Exn.fromPrimitive(brandFamily),
                                      voyage: CS_Slugs$Util.VoyageSlug.Exn.fromPrimitive(voyage)
                                    });
                        })
                    ];
                  } else {
                    exit = 1;
                  }
                  break;
              case "cabins" :
                  if (match$5.tl || !checkVoyAgainstBrandFam(voyage, brandFamily)) {
                    exit = 1;
                  } else {
                    var name$4 = getVoyageName(Belt_Result.getExn(checkVoyageSlug(voyage)));
                    match$3 = [
                      name$4 !== undefined ? "Cabin Inventory | " + name$4 : "Cabin Inventory | " + voyage,
                      (function () {
                          return JsxRuntime.jsx(CabinInventoryRoute$Thick.make, {
                                      brandFamily: CS_Slugs$Util.BrandFamilySlug.Exn.fromPrimitive(brandFamily),
                                      voyage: CS_Slugs$Util.VoyageSlug.Exn.fromPrimitive(voyage)
                                    });
                        })
                    ];
                  }
                  break;
              case "deck-map" :
                  if (match$5.tl || !checkVoyAgainstBrandFam(voyage, brandFamily)) {
                    exit = 1;
                  } else {
                    var name$5 = getVoyageName(Belt_Result.getExn(checkVoyageSlug(voyage)));
                    match$3 = [
                      name$5 !== undefined ? "Deck Map | " + name$5 : "Deck Map | " + voyage,
                      (function () {
                          return JsxRuntime.jsx(DeckMapRoute$Thick.make, {
                                      brandFamily: CS_Slugs$Util.BrandFamilySlug.Exn.fromPrimitive(brandFamily),
                                      voyage: CS_Slugs$Util.VoyageSlug.Exn.fromPrimitive(voyage)
                                    });
                        })
                    ];
                  }
                  break;
              case "passengers" :
                  if (match$5.tl || !checkVoyAgainstBrandFam(voyage, brandFamily)) {
                    exit = 1;
                  } else {
                    var name$6 = getVoyageName(Belt_Result.getExn(checkVoyageSlug(voyage)));
                    match$3 = [
                      name$6 !== undefined ? "Passengers | " + name$6 : "Passengers | " + voyage,
                      (function () {
                          return JsxRuntime.jsx(PassengersRoute$Thick.make, {
                                      brandFamily: CS_Slugs$Util.BrandFamilySlug.Exn.fromPrimitive(brandFamily),
                                      voyage: CS_Slugs$Util.VoyageSlug.Exn.fromPrimitive(voyage),
                                      voyageData: Belt_Option.getExn(getVoyageData(Belt_Result.getExn(checkVoyageSlug(voyage))))
                                    });
                        })
                    ];
                  }
                  break;
              case "settings" :
                  if (match$5.tl || !checkVoyAgainstBrandFam(voyage, brandFamily)) {
                    exit = 1;
                  } else {
                    var name$7 = getVoyageName(Belt_Result.getExn(checkVoyageSlug(voyage)));
                    match$3 = [
                      name$7 !== undefined ? "Voyage Settings | " + name$7 : "Voyage Settings | " + voyage,
                      (function () {
                          return JsxRuntime.jsx(VoyageSettingsRoute$Thick.make, {
                                      brandFamily: CS_Slugs$Util.BrandFamilySlug.Exn.fromPrimitive(brandFamily),
                                      voyage: CS_Slugs$Util.VoyageSlug.Exn.fromPrimitive(voyage),
                                      voyageData: Belt_Option.getExn(getVoyageData(Belt_Result.getExn(checkVoyageSlug(voyage))))
                                    });
                        })
                    ];
                  }
                  break;
              case "waitlist" :
                  if (match$5.tl || !checkVoyAgainstBrandFam(voyage, brandFamily)) {
                    exit = 1;
                  } else {
                    var name$8 = getVoyageName(Belt_Result.getExn(checkVoyageSlug(voyage)));
                    match$3 = [
                      name$8 !== undefined ? "Waitlist | " + name$8 : "Waitlist | " + voyage,
                      (function () {
                          return JsxRuntime.jsx(WaitlistRoute$Thick.make, {
                                      brandFamily: CS_Slugs$Util.BrandFamilySlug.Exn.fromPrimitive(brandFamily),
                                      voyage: CS_Slugs$Util.VoyageSlug.Exn.fromPrimitive(voyage),
                                      voyageData: Belt_Option.getExn(getVoyageData(Belt_Result.getExn(checkVoyageSlug(voyage))))
                                    });
                        })
                    ];
                  }
                  break;
              default:
                exit = 1;
            }
          } else if (checkVoyAgainstBrandFam(voyage, brandFamily)) {
            var name$9 = getVoyageName(Belt_Result.getExn(checkVoyageSlug(voyage)));
            match$3 = [
              name$9 !== undefined ? name$9 + " | Project Hopper" : voyage + " | Project Hopper",
              (function () {
                  return JsxRuntime.jsx(VoyageDashboardRoute$Thick.make, {
                              brandFamily: CS_Slugs$Util.BrandFamilySlug.Exn.fromPrimitive(brandFamily),
                              voyage: CS_Slugs$Util.VoyageSlug.Exn.fromPrimitive(voyage),
                              voyageData: Belt_Option.getExn(getVoyageData(Belt_Result.getExn(checkVoyageSlug(voyage))))
                            });
                })
            ];
          } else {
            exit = 1;
          }
        }
        
      } else if (Belt_Result.isOk(checkBrandFamily(brandFamily))) {
        var name$10 = getBrandFamilyName(Belt_Result.getExn(checkBrandFamily(brandFamily)));
        match$3 = [
          name$10 !== undefined ? "Dashboard | " + name$10 : "Dashboard | " + brandFamily,
          (function () {
              return JsxRuntime.jsx(BrandFamilyRoute$Thick.make, {
                          brandFamily: CS_Slugs$Util.BrandFamilySlug.Exn.fromPrimitive(brandFamily)
                        });
            })
        ];
      } else {
        exit = 1;
      }
    }
    
  } else {
    exit = 1;
  }
  if (exit === 1) {
    match$3 = [
      "Redirecting to dashboard...",
      (function () {
          return JsxRuntime.jsx(DashboardRoute$Thick.make, {
                      brandFamilies: appConfig.brandFamilies
                    });
        })
    ];
  }
  var title = match$3[0];
  React.useEffect(function () {
        setDocTitle(title);
      });
  return JsxRuntime.jsx(AppConfig$Thick.Context.Provider.make, {
              value: appConfig,
              children: JsxRuntime.jsx(React.Suspense, {
                    children: Caml_option.some(JsxRuntime.jsx(Styles.CssVarsProvider, {
                              children: Caml_option.some(match$3[1]())
                            })),
                    fallback: "Loading"
                  })
            });
}

var make = ThickApp;

exports.RedirectToLogin = RedirectToLogin;
exports.ThickAppQuery = ThickAppQuery;
exports.setDocTitle = setDocTitle;
exports.make = make;
/* use Not a pure module */
