// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var Button = require("@mui/joy/Button").default;
var $$Option = require("@mui/joy/Option").default;
var AppConfig$Thick = require("../../context/AppConfig.bs.js");
var SearchBar$Thick = require("../common/molecules/SearchBar.bs.js");
var HopperState$Util = require("util/src/HopperState.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var IconButton = require("@mui/joy/IconButton").default;
var Typography = require("@mui/joy/Typography").default;
var BookingSession$Thick = require("../../utils/BookingSession.bs.js");
var Add = require("@mui/icons-material/Add").default;
var Search = require("@mui/icons-material/Search").default;
var ChevronLeft = require("@mui/icons-material/ChevronLeft").default;
var ChevronRight = require("@mui/icons-material/ChevronRight").default;

function BSTableToolbar$Search(props) {
  var handleBookingChannelSlugSelect = props.handleBookingChannelSlugSelect;
  var handleSessionCreatorSelect = props.handleSessionCreatorSelect;
  var clearSearch = props.clearSearch;
  var search = props.search;
  var match = React.useState(function () {
        return "";
      });
  var setSearchVal = match[1];
  var searchVal = match[0];
  var match$1 = AppConfig$Thick.Context.use();
  var bookingChannels = match$1.bookingChannels.getBookingChannelsByBrandFamAndVoyage(props.brandFamily, props.voyage);
  var onSubmit = function (e) {
    e.preventDefault();
    search(searchVal);
  };
  var match$2 = bookingChannels.length;
  return JsxRuntime.jsxs(Stack, {
              direction: "row",
              spacing: 1,
              children: [
                JsxRuntime.jsxs(Stack, {
                      direction: "row",
                      component: "form",
                      spacing: 1,
                      onSubmit: onSubmit,
                      children: [
                        JsxRuntime.jsx(SearchBar$Thick.make, {
                              value: searchVal,
                              onChange: (function (e) {
                                  var val = e.currentTarget.value;
                                  setSearchVal(function (param) {
                                        return val;
                                      });
                                }),
                              placeholder: "Search",
                              onClear: (function (param) {
                                  setSearchVal(function (param) {
                                        return "";
                                      });
                                  clearSearch();
                                }),
                              showClear: searchVal !== "",
                              showDecorator: false
                            }),
                        JsxRuntime.jsx(IconButton, {
                              type: "submit",
                              variant: "solid",
                              color: "primary",
                              disabled: searchVal === "",
                              children: Caml_option.some(JsxRuntime.jsx(Search, {}))
                            })
                      ]
                    }),
                JsxRuntime.jsxs(Joy$Util.JoySelect.make, {
                      children: [
                        JsxRuntime.jsx($$Option, {
                              value: "team",
                              children: "Team-created sessions"
                            }),
                        JsxRuntime.jsx($$Option, {
                              value: "custNew",
                              children: "Recent customer sessions"
                            }),
                        JsxRuntime.jsx($$Option, {
                              value: "custStale",
                              children: "Abandoned customer sessions"
                            }),
                        JsxRuntime.jsx($$Option, {
                              value: "all",
                              children: "All sessions"
                            })
                      ],
                      onChange: (function (param, val) {
                          if (val === undefined) {
                            return ;
                          }
                          var val$1 = Caml_option.valFromOption(val);
                          if (val$1 !== undefined) {
                            return handleSessionCreatorSelect(val$1);
                          }
                          
                        }),
                      size: "sm",
                      value: props.sessionCreator
                    }),
                match$2 !== 0 ? JsxRuntime.jsxs(Joy$Util.JoySelect.make, {
                        children: [
                          JsxRuntime.jsx($$Option, {
                                value: "all",
                                children: "All booking channels"
                              }),
                          Belt_Array.map(bookingChannels, (function (bc) {
                                  return JsxRuntime.jsx($$Option, {
                                              value: bc,
                                              children: Caml_option.some(bc)
                                            });
                                }))
                        ],
                        onChange: (function (param, val) {
                            if (val === undefined) {
                              return ;
                            }
                            var val$1 = Caml_option.valFromOption(val);
                            if (val$1 !== undefined) {
                              return handleBookingChannelSlugSelect(val$1);
                            }
                            
                          }),
                        size: "sm",
                        value: props.bookingChannel
                      }) : null
              ]
            });
}

var Search$1 = {
  make: BSTableToolbar$Search
};

function BSTableToolbar$NewButton(props) {
  var form = props.form;
  return JsxRuntime.jsx(Button, {
              onClick: (function (param) {
                  HopperState$Util.Observable.notify(form, (function (param) {
                          return BookingSession$Thick.blankForm("New");
                        }));
                }),
              startDecorator: Caml_option.some(JsxRuntime.jsx(Add, {})),
              variant: "outlined",
              color: "primary",
              size: "sm",
              children: "New Booking Session"
            });
}

var NewButton = {
  make: BSTableToolbar$NewButton
};

function BSTableToolbar(props) {
  var refetch = props.refetch;
  var sessionsPerPage = props.sessionsPerPage;
  var totalCount = props.totalCount;
  var __showSearch = props.showSearch;
  var filter = props.filter;
  var state = props.state;
  var showSearch = __showSearch !== undefined ? __showSearch : true;
  var match = HopperState$Util.Observable.useComputed(state, (function (v) {
          var t = v.searchTerm;
          return [
                  v.offset,
                  t !== undefined ? t : "",
                  BookingSession$Thick.Filter.getStringFromOptionalSessionCreator(v.sessionCreator),
                  BookingSession$Thick.Filter.getStringFromOptionalBookingChannel(v.bookingChannel)
                ];
        }), undefined, undefined);
  var bookingChannel = match[3];
  var sessionCreator = match[2];
  var offset = match[0];
  var stringFromOffset = function (o, t) {
    var start = o + 1 | 0;
    var end = o + sessionsPerPage | 0;
    var end$1 = end > t ? t : end;
    if (t !== 0) {
      return String(start) + " - " + String(end$1) + " / " + String(t);
    } else {
      return "0";
    }
  };
  var clearSearch = function (param) {
    HopperState$Util.Observable.notify(state, (function (m) {
            return {
                    form: m.form,
                    paginationCursor: m.paginationCursor,
                    url: m.url,
                    focusedSession: m.focusedSession,
                    saveError: m.saveError,
                    offset: 0,
                    searchTerm: undefined,
                    sessionCreator: m.sessionCreator,
                    bookingChannel: m.bookingChannel
                  };
          }));
  };
  var search = function (t) {
    HopperState$Util.Observable.notify(state, (function (m) {
            return {
                    form: m.form,
                    paginationCursor: m.paginationCursor,
                    url: m.url,
                    focusedSession: m.focusedSession,
                    saveError: m.saveError,
                    offset: 0,
                    searchTerm: t,
                    sessionCreator: m.sessionCreator,
                    bookingChannel: m.bookingChannel
                  };
          }));
  };
  var handleSessionCreatorSelect = function (s) {
    var sessionCreator = BookingSession$Thick.Filter.getOptionalSessionCreator(s);
    HopperState$Util.Observable.notify(state, (function (m) {
            return {
                    form: m.form,
                    paginationCursor: m.paginationCursor,
                    url: m.url,
                    focusedSession: m.focusedSession,
                    saveError: m.saveError,
                    offset: 0,
                    searchTerm: m.searchTerm,
                    sessionCreator: sessionCreator,
                    bookingChannel: m.bookingChannel
                  };
          }));
  };
  var handleBookingChannelSlugSelect = function (s) {
    var bookingChannel = BookingSession$Thick.Filter.getOptionalBookingChannel(s);
    HopperState$Util.Observable.notify(state, (function (m) {
            return {
                    form: m.form,
                    paginationCursor: m.paginationCursor,
                    url: m.url,
                    focusedSession: m.focusedSession,
                    saveError: m.saveError,
                    offset: 0,
                    searchTerm: m.searchTerm,
                    sessionCreator: m.sessionCreator,
                    bookingChannel: bookingChannel
                  };
          }));
  };
  React.useEffect((function () {
          refetch(BookingSession$Thick.Filter.makeRefetchVars(HopperState$Util.Observable.dangerouslyRead(state), filter), "store-and-network", undefined);
        }), [
        offset,
        match[1],
        bookingChannel,
        sessionCreator
      ]);
  return JsxRuntime.jsx(Sheet, {
              sx: {
                p: 1
              },
              children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                        direction: "row",
                        justifyContent: "space-between",
                        children: [
                          showSearch ? JsxRuntime.jsx(BSTableToolbar$Search, {
                                  search: search,
                                  clearSearch: clearSearch,
                                  handleSessionCreatorSelect: handleSessionCreatorSelect,
                                  handleBookingChannelSlugSelect: handleBookingChannelSlugSelect,
                                  sessionCreator: sessionCreator,
                                  bookingChannel: bookingChannel,
                                  brandFamily: props.brandFamily,
                                  voyage: props.voyage
                                }) : JsxRuntime.jsx("div", {}),
                          JsxRuntime.jsxs(Stack, {
                                direction: "row",
                                spacing: 1,
                                children: [
                                  showSearch ? JsxRuntime.jsx(BSTableToolbar$NewButton, {
                                          form: props.form
                                        }) : null,
                                  JsxRuntime.jsx(Stack, {
                                        direction: "row",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        spacing: 1,
                                        children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                                  direction: "row",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                  children: [
                                                    JsxRuntime.jsx(IconButton, {
                                                          onClick: (function (param) {
                                                              var newOffset = offset - sessionsPerPage | 0;
                                                              HopperState$Util.Observable.notify(state, (function (m) {
                                                                      return {
                                                                              form: m.form,
                                                                              paginationCursor: m.paginationCursor,
                                                                              url: m.url,
                                                                              focusedSession: m.focusedSession,
                                                                              saveError: m.saveError,
                                                                              offset: newOffset,
                                                                              searchTerm: m.searchTerm,
                                                                              sessionCreator: m.sessionCreator,
                                                                              bookingChannel: m.bookingChannel
                                                                            };
                                                                    }));
                                                            }),
                                                          variant: "plain",
                                                          disabled: offset === 0,
                                                          children: Caml_option.some(JsxRuntime.jsx(ChevronLeft, {}))
                                                        }),
                                                    JsxRuntime.jsx(Typography, {
                                                          level: "body-xs",
                                                          children: Caml_option.some(stringFromOffset(offset, totalCount))
                                                        }),
                                                    JsxRuntime.jsx(IconButton, {
                                                          onClick: (function (param) {
                                                              var newOffset = offset + sessionsPerPage | 0;
                                                              HopperState$Util.Observable.notify(state, (function (m) {
                                                                      return {
                                                                              form: m.form,
                                                                              paginationCursor: m.paginationCursor,
                                                                              url: m.url,
                                                                              focusedSession: m.focusedSession,
                                                                              saveError: m.saveError,
                                                                              offset: newOffset,
                                                                              searchTerm: m.searchTerm,
                                                                              sessionCreator: m.sessionCreator,
                                                                              bookingChannel: m.bookingChannel
                                                                            };
                                                                    }));
                                                            }),
                                                          variant: "plain",
                                                          disabled: (offset + sessionsPerPage | 0) >= totalCount,
                                                          children: Caml_option.some(JsxRuntime.jsx(ChevronRight, {}))
                                                        })
                                                  ]
                                                }))
                                      })
                                ]
                              })
                        ]
                      }))
            });
}

var make = BSTableToolbar;

exports.Search = Search$1;
exports.NewButton = NewButton;
exports.make = make;
/* react Not a pure module */
