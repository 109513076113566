// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var DashboardPage_query_graphql$Thick = require("../__generated__/DashboardPage_query_graphql.bs.js");

var convertFragment = DashboardPage_query_graphql$Thick.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(DashboardPage_query_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, DashboardPage_query_graphql$Thick.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(DashboardPage_query_graphql$Thick.node, convertFragment, fRef);
}

var QueryFragment_signonStatus_decode = DashboardPage_query_graphql$Thick.Utils.signonStatus_decode;

var QueryFragment_signonStatus_fromString = DashboardPage_query_graphql$Thick.Utils.signonStatus_fromString;

var QueryFragment = {
  signonStatus_decode: QueryFragment_signonStatus_decode,
  signonStatus_fromString: QueryFragment_signonStatus_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

function DashboardPage(props) {
  use(props.fragmentRefs);
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("h1", {
                      children: "Dashboard"
                    }),
                JsxRuntime.jsx("h2", {
                      children: "Select a Brand Family"
                    }),
                JsxRuntime.jsx("ul", {
                      children: Belt_Array.mapWithIndex(props.brandFamilies.brandFamilies, (function (i, bf) {
                              return JsxRuntime.jsx("li", {
                                          children: JsxRuntime.jsx("a", {
                                                children: bf.brandFamilyName,
                                                href: "/" + CS_Slugs$Util.BrandFamilySlug.toString(bf.brandFamilySlug)
                                              })
                                        }, String(i));
                            }))
                    })
              ]
            });
}

var make = DashboardPage;

exports.QueryFragment = QueryFragment;
exports.make = make;
/* CS_Slugs-Util Not a pure module */
