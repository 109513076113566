// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var DateFns = require("date-fns");
var Http$Util = require("./Http.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Core__JSON = require("@rescript/core/src/Core__JSON.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_SetString = require("rescript/lib/js/belt_SetString.js");
var CS_Decimal$Util = require("./CustomScalars/CS_Decimal.bs.js");
var Caml_exceptions = require("rescript/lib/js/caml_exceptions.js");
var ReactState$Util = require("./ReactState.bs.js");
var RemoteData$Util = require("./RemoteData.bs.js");
var S$RescriptSchema = require("rescript-schema/src/S.bs.js");
var Sync = require("csv-stringify/sync");
var Reporting_FileSaverJs = require("./Reporting_FileSaver.js");

var UnexpectedAndBizarrePromiseState = /* @__PURE__ */Caml_exceptions.create("Reporting-Util.UnexpectedAndBizarrePromiseState");

function flatten(json) {
  var dict = Core__JSON.Classify.classify(json);
  if (typeof dict !== "object") {
    return ;
  }
  switch (dict.TAG) {
    case "Object" :
        return Belt_Array.get(Belt_Array.keepMap(Object.entries(dict._0), (function (param) {
                          return flatten(param[1]);
                        })), 0);
    case "Array" :
        var els = dict._0;
        if (els.length === 0) {
          return ;
        }
        var raws = Belt_Array.map(els, (function (param) {
                return procRow("", param);
              }));
        var headers = procHeaders(raws);
        if (headers.length === 4) {
          var match = headers[0];
          if (match === "type") {
            var match$1 = headers[1];
            if (match$1 === "title") {
              var match$2 = headers[2];
              if (match$2 === "plan") {
                var match$3 = headers[3];
                if (match$3 === "query") {
                  return ;
                }
                
              }
              
            }
            
          }
          
        }
        return {
                headers: headers,
                rows: Belt_Array.map(raws, (function (prim) {
                        return Object.fromEntries(prim);
                      }))
              };
        break;
    default:
      return ;
  }
}

function incPrefix(prefix, k) {
  if (prefix === "") {
    return k;
  } else {
    return prefix + "." + k;
  }
}

function procRow(prefix, _el) {
  while(true) {
    var el = _el;
    var dict = Core__JSON.Classify.classify(el);
    if (typeof dict !== "object") {
      return [[
                prefix,
                el
              ]];
    }
    switch (dict.TAG) {
      case "Object" :
          return Belt_Array.map(Object.entries(dict._0), (function (param) {
                        return [
                                incPrefix(prefix, param[0]),
                                param[1]
                              ];
                      }));
      case "Array" :
          var match = dict._0;
          var len = match.length;
          if (len !== 1) {
            if (len !== 0) {
              return [[
                        prefix,
                        el
                      ]];
            } else {
              return [];
            }
          }
          var oneEl = match[0];
          _el = oneEl;
          continue ;
      default:
        return [[
                  prefix,
                  el
                ]];
    }
  };
}

function procHeaders(arr) {
  return Belt_Array.reduce(arr, [
                undefined,
                []
              ], (function (param, rowArray) {
                  var seen = param[0];
                  var inorder = param[1];
                  return Belt_Array.reduce(rowArray, [
                              seen,
                              inorder
                            ], (function (param, param$1) {
                                var k = param$1[0];
                                var inorder = param[1];
                                var seen = param[0];
                                if (Belt_SetString.has(seen, k)) {
                                  return [
                                          seen,
                                          inorder
                                        ];
                                } else {
                                  return [
                                          Belt_SetString.add(seen, k),
                                          Belt_Array.concat(inorder, [k])
                                        ];
                                }
                              }));
                }))[1];
}

function __getHeader(row, header) {
  return Belt_Option.getWithDefault(row[header], null);
}

function toStr(val) {
  var b = Core__JSON.Classify.classify(val);
  if (typeof b !== "object") {
    return "";
  }
  switch (b.TAG) {
    case "Bool" :
        if (b._0) {
          return "true";
        } else {
          return "false";
        }
    case "String" :
        var s = b._0;
        var d = CS_Decimal$Util.Decimal10_2.fromPrimitive(s);
        if (d.TAG !== "Ok") {
          return s;
        }
        var d$1 = d._0;
        var options = {
          style: "currency",
          currency: "USD"
        };
        var formatter = Intl.NumberFormat("en-US", options);
        var formatted = formatter.format(Math.abs(CS_Decimal$Util.Decimal10_2.toFloat(d$1)));
        if (CS_Decimal$Util.Decimal10_2.toFloat(d$1) < 0.0) {
          return "(" + formatted + ")";
        } else {
          return formatted;
        }
    case "Number" :
        return String(b._0);
    case "Object" :
    case "Array" :
        return JSON.stringify(val);
    
  }
}

function getHeader(row, header) {
  return toStr(__getHeader(row, header));
}

function rotate(param) {
  var rows = param.rows;
  return {
          headers: Belt_Array.map(Belt_Array.range(0, rows.length), (function (prim) {
                  return String(prim);
                })),
          rows: Belt_Array.map(Belt_Array.map(param.headers, (function (hdr) {
                      return Belt_Array.concat([[
                                    "0",
                                    hdr
                                  ]], Belt_Array.mapWithIndex(rows, (function (idx, row) {
                                        return [
                                                String(idx + 1 | 0),
                                                __getHeader(row, hdr)
                                              ];
                                      })));
                    })), (function (v) {
                  return Object.fromEntries(v);
                }))
        };
}

function saveFromString(prim0, prim1, prim2) {
  Reporting_FileSaverJs.fromString(prim0, prim1, prim2);
}

var Bindings = {
  saveFromString: saveFromString
};

function request(url, gqlQuery) {
  return Http$Util.jsonRequest(url, "POST", [
              Object.fromEntries([[
                      "query",
                      gqlQuery
                    ]]),
              S$RescriptSchema.json(true)
            ], [], S$RescriptSchema.json(true));
}

function getTable(url, gqlQuery) {
  return RemoteData$Util.$$Promise.mapSuccess(request(url, gqlQuery), (function (v) {
                return flatten(v);
              }));
}

function getRotated(url, gqlQuery) {
  return RemoteData$Util.$$Promise.mapSuccess(getTable(url, gqlQuery), (function (v) {
                return Belt_Option.map(v, rotate);
              }));
}

function fire(url, gqlQuery, rotateOpt) {
  var rotate = rotateOpt !== undefined ? rotateOpt : false;
  if (rotate) {
    return getRotated(url, gqlQuery);
  } else {
    return getTable(url, gqlQuery);
  }
}

function useTable(url, gqlQuery, rotateOpt) {
  var rotate = rotateOpt !== undefined ? rotateOpt : false;
  var state = React.useState(function () {
        return "NotAsked";
      });
  React.useEffect((function () {
          RemoteData$Util.$$Promise.makeStatefulEffect(fire(url, gqlQuery, rotate), state);
        }), []);
  return ReactState$Util.getter(state);
}

function getDownloadActionForTableAsCSV(url, gqlQuery, fileName, rotateOpt, addDateToFileNameOpt, headersOverride) {
  var rotate = rotateOpt !== undefined ? rotateOpt : false;
  var addDateToFileName = addDateToFileNameOpt !== undefined ? addDateToFileNameOpt : true;
  var parts = Belt_Array.keep(fileName.split("."), (function (s) {
          return s.trim() !== "";
        }));
  var parts$1 = parts.length === 0 ? ["Report"] : parts;
  var match = Belt_Array.getExn(parts$1, parts$1.length - 1 | 0);
  var parts$2 = match === "csv" ? Belt_Array.slice(parts$1, 0, parts$1.length - 1 | 0) : parts$1;
  var cleanNameNoExtension = parts$2.join(".");
  var formatDate = DateFns.format(new Date(), "yyyy_MM_dd_HHmm");
  var fileName$1 = addDateToFileName ? cleanNameNoExtension + "_" + formatDate + ".csv" : cleanNameNoExtension + ".csv";
  var dumbCSV = function (message) {
    saveFromString(Sync.stringify([[message]], {
              header: false,
              bom: true,
              columns: ["error"]
            }), fileName$1, "text/csv;charset=utf-8");
  };
  return function () {
    (
        rotate ? getRotated(url, gqlQuery) : getTable(url, gqlQuery)
      ).then(function (remoteData) {
          if (typeof remoteData !== "object") {
            if (remoteData === "NotAsked") {
              throw {
                    RE_EXN_ID: UnexpectedAndBizarrePromiseState,
                    Error: new Error()
                  };
            }
            throw {
                  RE_EXN_ID: UnexpectedAndBizarrePromiseState,
                  Error: new Error()
                };
          } else {
            if (remoteData.TAG !== "Success") {
              return dumbCSV(remoteData._0);
            }
            var match = remoteData._0;
            if (match !== undefined) {
              return saveFromString(Sync.stringify(match.rows, {
                              header: true,
                              bom: true,
                              columns: headersOverride !== undefined ? headersOverride : match.headers
                            }), fileName$1, "text/csv;charset=utf-8");
            } else {
              return dumbCSV("No rows were returned");
            }
          }
        });
  };
}

var Graphql = {
  request: request,
  getTable: getTable,
  getRotated: getRotated,
  fire: fire,
  useTable: useTable,
  getDownloadActionForTableAsCSV: getDownloadActionForTableAsCSV
};

exports.UnexpectedAndBizarrePromiseState = UnexpectedAndBizarrePromiseState;
exports.flatten = flatten;
exports.__getHeader = __getHeader;
exports.getHeader = getHeader;
exports.rotate = rotate;
exports.incPrefix = incPrefix;
exports.toStr = toStr;
exports.procRow = procRow;
exports.procHeaders = procHeaders;
exports.Bindings = Bindings;
exports.Graphql = Graphql;
/* react Not a pure module */
