// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Js_math = require("rescript/lib/js/js_math.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_MapInt = require("rescript/lib/js/belt_MapInt.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CS_Make$Util = require("util/src/CustomScalars/CS_Make.bs.js");
var Common$Thick = require("../../../utils/Common.bs.js");
var CS_Phone$Util = require("util/src/CustomScalars/CS_Phone.bs.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var RelayEnv$Thick = require("../../../RelayEnv.bs.js");
var BookingDiff$Thick = require("../../../utils/BookingDiff.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var VoyageDetails$Thick = require("../../common/queries/VoyageDetails.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var DraftUtilsUserQuery_graphql$Thick = require("../../../__generated__/DraftUtilsUserQuery_graphql.bs.js");
var PreviewChangesQuery_graphql$Thick = require("../../../__generated__/PreviewChangesQuery_graphql.bs.js");
var CS_LoyaltyAndCruiselineNumbers$Util = require("util/src/CustomScalars/CS_LoyaltyAndCruiselineNumbers.bs.js");

var genderOptions = [
  "F",
  "M",
  "X"
];

function genderToText(x) {
  if (!(x === "F" || x === "M" || x === "X")) {
    return "Unsupported";
  }
  switch (x) {
    case "M" :
        return "Male";
    case "F" :
        return "Female";
    case "X" :
        return "Unspecified";
    
  }
}

var Pax = {
  genderOptions: genderOptions,
  genderToText: genderToText
};

function postMushIdxMap(paxes) {
  return Belt_Array.reduceWithIndex(paxes, undefined, (function (acc, pax, i) {
                return Belt_MapInt.set(acc, pax.idx, i + 1 | 0);
              }));
}

function makeBookingDiffInput(diffStruct) {
  var postMushIdxMap = diffStruct.postMushIdxMap;
  var changeCabinU = diffStruct.changeCabinU;
  var changeCabinUnbooked = changeCabinU !== undefined ? ({
        cabinNumber: Caml_option.valFromOption(changeCabinU),
        overrideHold: true
      }) : undefined;
  var dropBerths = Belt_Option.map(diffStruct.dropBerths, (function (berths) {
          return Belt_Array.map(berths, (function (b) {
                        return b;
                      }));
        }));
  var detachPax = Belt_Option.map(diffStruct.detachPax, (function (pax) {
          return Belt_Array.map(pax, (function (a) {
                        return a;
                      }));
        }));
  var reindexPax = Belt_Option.map(diffStruct.reindexPax, (function (berths) {
          return Belt_Array.map(berths, (function (b) {
                        var idx = Belt_MapInt.get(Belt_Option.getWithDefault(postMushIdxMap, undefined), b);
                        if (idx !== undefined) {
                          return idx;
                        } else {
                          return b;
                        }
                      }));
        }));
  var suppressManualBillingLines = Belt_Option.map(diffStruct.suppressManualBillingLines, (function (lines) {
          return Belt_Array.map(lines, (function (l) {
                        return l;
                      }));
        }));
  var intMapToOptionWrappedArray = function (innerMapFn, intMap, idxMap) {
    return Belt_Array.map(Belt_MapInt.toArray(intMap), (function (p) {
                  return innerMapFn(p, idxMap);
                }));
  };
  var stringMapToOptionWrappedArray = function (innerMapFn, stringMap) {
    return Belt_Array.map(Belt_MapString.toArray(stringMap), (function (p) {
                  return innerMapFn(p);
                }));
  };
  var mapPaxApplication = function (p, idxMap) {
    var idx = p[0];
    var idx$1 = idxMap !== undefined ? Belt_Option.getWithDefault(Belt_MapInt.get(Caml_option.valFromOption(idxMap), idx), idx) : idx;
    return {
            idx: idx$1,
            accountId: p[1]
          };
  };
  var mapPaxInvitation = function (p, idxMap) {
    var reco = p[1];
    var idx = p[0];
    var idx$1 = idxMap !== undefined ? Belt_Option.getWithDefault(Belt_MapInt.get(Caml_option.valFromOption(idxMap), idx), idx) : idx;
    return {
            idx: idx$1,
            invitationEmail: reco.email,
            preferredFirstName: reco.firstName,
            preferredLastName: reco.lastName
          };
  };
  var mapNewConnectedPax = function (p, idxMap) {
    var reco = p[1];
    var idx = p[0];
    var idx$1 = idxMap !== undefined ? Belt_Option.getWithDefault(Belt_MapInt.get(Caml_option.valFromOption(idxMap), idx), idx) : idx;
    return {
            idx: idx$1,
            custodianAccountId: reco.custodianAccountId,
            docFirstname: reco.docFirstName,
            docLastname: reco.docLastName,
            docGender: Belt_Option.getWithDefault(PreviewChangesQuery_graphql$Thick.Utils.docGender_decode(reco.docGender), "X"),
            docCitizenship: reco.docCitizenship,
            docBirthdate: reco.docBirthdate,
            preferredFirstName: reco.preferredFirstName,
            preferredLastName: reco.preferredLastName,
            halMarinerNumber: reco.halMarinerNumber
          };
  };
  var mapManualBillingLine = function (p) {
    var line = p[1];
    return {
            subtotalCategory: Belt_Option.getWithDefault(PreviewChangesQuery_graphql$Thick.Utils.subtotalCategory_decode(line.subtotalCategory), "NON_CORE_BOOKING_FEES"),
            amount: line.amount,
            description: line.description,
            passthruId: Caml_option.some(Belt_Result.getWithDefault(CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(p[0]), CS_NonemptyStrings$Util.NonEmptyString.Exn.fromPrimitive("exn_passthru_" + String(Js_math.random_int(1, 1000))))),
            refundability: Belt_Option.getWithDefault(PreviewChangesQuery_graphql$Thick.Utils.refundability_decode(line.refundability), "POLICY")
          };
  };
  var mapSuppressAutoBillingLine = function (p) {
    var suppression = p[1];
    return {
            fareRuleSlug: suppression.fareRuleSlug,
            hookFiredAt: suppression.hookFiredAt,
            nSuppressed: suppression.nSuppressed,
            passthruId: Caml_option.some(CS_NonemptyStrings$Util.NonEmptyString.Exn.fromPrimitive(p[0]))
          };
  };
  var attachExistingPax = Belt_Option.map(diffStruct.attachExistingPax, (function (m) {
          return intMapToOptionWrappedArray(mapPaxApplication, m, postMushIdxMap);
        }));
  var replacePaxExisting = Belt_Option.map(diffStruct.replacePaxExisting, (function (m) {
          return intMapToOptionWrappedArray(mapPaxApplication, m, undefined);
        }));
  var revokePaxInvitation = Belt_Option.map(diffStruct.revokePaxInvitation, (function (m) {
          return intMapToOptionWrappedArray(mapPaxApplication, m, undefined);
        }));
  var attachInvitedPax = Belt_Option.map(diffStruct.attachInvitedPax, (function (m) {
          return intMapToOptionWrappedArray(mapPaxInvitation, m, postMushIdxMap);
        }));
  var replacePaxInvited = Belt_Option.map(diffStruct.replaceInvitedPax, (function (m) {
          return intMapToOptionWrappedArray(mapPaxInvitation, m, undefined);
        }));
  var attachNewConnectedPax = Belt_Option.map(diffStruct.attachNewConnectedPax, (function (m) {
          return intMapToOptionWrappedArray(mapNewConnectedPax, m, postMushIdxMap);
        }));
  var replaceNewConnectedPax = Belt_Option.map(diffStruct.replaceNewConnectedPax, (function (m) {
          return intMapToOptionWrappedArray(mapNewConnectedPax, m, undefined);
        }));
  var updateBookingPreferences = Belt_Option.map(diffStruct.updateBookingPreferences, (function (param) {
          var cabinLongitudePref = Belt_Option.map(param.cabinLongitudePref, (function (p) {
                  return Belt_Option.getWithDefault(PreviewChangesQuery_graphql$Thick.Utils.longitudinality_decode(p), "FORE");
                }));
          var cabinElevatorPref = Belt_Option.map(param.cabinElevatorPref, (function (p) {
                  return Belt_Option.getWithDefault(PreviewChangesQuery_graphql$Thick.Utils.elevatorProximity_decode(p), "FARELEVATORS");
                }));
          return {
                  bedsTogetherPref: param.bedsTogetherPref,
                  cabinLongitudePref: cabinLongitudePref,
                  cabinElevatorPref: cabinElevatorPref,
                  cabinPlacementRequest: param.cabinPlacementRequest,
                  earlyDinnerSeatingPref: param.earlyDinnerSeatingPref,
                  generalPref: param.generalPref
                };
        }));
  var changeCabinBooked = Belt_Option.map(diffStruct.changeCabinB, (function (param) {
          return {
                  cabinNumber: param.cabinNumber,
                  bookingId: param.bookingId,
                  bookingVersionNumber: param.bookingVersionNumber
                };
        }));
  var addManualBillingLines = Belt_Option.map(diffStruct.addManualBillingLines, (function (m) {
          return stringMapToOptionWrappedArray(mapManualBillingLine, m);
        }));
  var suppressAutoBillingLines = Belt_Option.map(diffStruct.suppressAutoBillingLines, (function (m) {
          return stringMapToOptionWrappedArray(mapSuppressAutoBillingLine, m);
        }));
  var mapPaxPatch = function (p, param) {
    var reco = p[1];
    var updateOtherVoyages = reco.updateOtherVoyages;
    var docGender = Belt_Option.map(reco.pDocGender, (function (g) {
            return Belt_Option.getWithDefault(PreviewChangesQuery_graphql$Thick.Utils.docGender_decode(g), "X");
          }));
    var updateOtherVoyages$1 = updateOtherVoyages.length === 0 ? undefined : Belt_Array.map(updateOtherVoyages, (function (v) {
              return Caml_option.some(v);
            }));
    return {
            accountId: reco.accountId,
            docFirstName: reco.pDocFirstName,
            docLastName: reco.pDocLastName,
            docBirthdate: reco.pDocBirthdate,
            docGender: docGender,
            docCitizenship: reco.pDocCitizenship,
            updateOtherVoyages: updateOtherVoyages$1,
            updateUserAccount: reco.updateUserAccount
          };
  };
  var mapManualRefunds = function (p) {
    var reco = p[1];
    var referencePmtPublicId = Belt_Option.map(reco.referencePmtPublicId, CS_NonemptyStrings$Util.NonEmptyString.toString);
    var lifecycleStatus = PreviewChangesQuery_graphql$Thick.Utils.pmtLifecycleStatus_decode(reco.lifecycleStatus);
    return {
            payee: reco.payee,
            teamUserInitiator: reco.teamUserInitiator,
            processor: reco.processor,
            netRefundedAmount: reco.netRefundedAmount,
            netRefundedBfFeeAmount: reco.netRefundedBfFeeAmount,
            referencePmtPublicId: referencePmtPublicId,
            lifecycleStatus: lifecycleStatus,
            processorTransactionId: Caml_option.some(reco.processorTransactionId),
            pmtMethod: reco.pmtMethod,
            bfcustPmtMethodIdentifier: reco.bfcustPmtMethodIdentifier,
            bfcustPmtSubtype: reco.bfcustPmtSubtype,
            bfcustPmtNote: reco.bfcustPmtNote
          };
  };
  var mapMovePayments = function (p) {
    var publicPmtId = CS_NonemptyStrings$Util.PmtTransactionId.toString(p.publicPmtId);
    return {
            toBookingId: Caml_option.some(p.toBookingId),
            toBookingVersionNumber: p.toBookingVersionNumber,
            publicPmtId: publicPmtId
          };
  };
  var patchPax = Belt_Option.map(diffStruct.patchPax, (function (m) {
          return intMapToOptionWrappedArray(mapPaxPatch, m, undefined);
        }));
  var recordManualRefunds = Belt_Option.map(diffStruct.recordManualRefunds, (function (extra) {
          return Belt_Array.map(Belt_MapInt.toArray(extra), (function (p) {
                        return mapManualRefunds(p);
                      }));
        }));
  var movePayments = Belt_Option.map(diffStruct.movePayments, (function (extra) {
          return Belt_Array.map(extra, (function (p) {
                        return mapMovePayments(p);
                      }));
        }));
  var match = diffStruct.removeWasReferred;
  var match$1 = diffStruct.addWasReferred;
  var match$2 = diffStruct.referredTimeStamp;
  var updateWasReferred;
  var exit = 0;
  if (match !== undefined && match) {
    updateWasReferred = {};
  } else {
    exit = 1;
  }
  if (exit === 1) {
    updateWasReferred = match$1 !== undefined && match$2 !== undefined ? ({
          referredBy: Caml_option.some(Caml_option.valFromOption(match$1)),
          referralMadeTimestamp: Caml_option.some(Caml_option.valFromOption(match$2))
        }) : undefined;
  }
  return {
          dropBerths: dropBerths,
          addBerths: diffStruct.addBerths,
          detachPax: detachPax,
          attachExistingPax: attachExistingPax,
          attachInvitedPax: attachInvitedPax,
          attachNewConnectedPax: attachNewConnectedPax,
          replacePaxExisting: replacePaxExisting,
          replacePaxInvited: replacePaxInvited,
          replaceNewConnectedPax: replaceNewConnectedPax,
          revokePaxInvitation: revokePaxInvitation,
          changeCabinUnbooked: changeCabinUnbooked,
          changeCabinBooked: changeCabinBooked,
          changeBillingContact: diffStruct.changeBillingContact,
          suppressAutoBillingLines: suppressAutoBillingLines,
          suppressManualBillingLines: suppressManualBillingLines,
          addManualBillingLines: addManualBillingLines,
          updateBookingPreferences: updateBookingPreferences,
          patchPax: patchPax,
          recordManualRefunds: recordManualRefunds,
          movePayments: movePayments,
          cancellationReason: diffStruct.cancellationReason,
          reindexPax: reindexPax,
          finalPlacement: diffStruct.finalPlacement,
          placementComment: diffStruct.placementComment,
          updateWasReferred: updateWasReferred
        };
}

function previewCallback(bookingId, bookingVersionNumber, voyage) {
  return function (diff) {
    return {
            bookingDiff: diff,
            bookingId: bookingId,
            bookingVersionNumber: bookingVersionNumber,
            voyageSlug: voyage
          };
  };
}

function canceledBookingManualRefundInput(mr) {
  var processor = mr.processor;
  var referencePmtPublicId = Belt_Option.map(mr.referencePmtPublicId, CS_NonemptyStrings$Util.NonEmptyString.toString);
  var lifecycleStatus = PreviewChangesQuery_graphql$Thick.Utils.pmtLifecycleStatus_decode(mr.lifecycleStatus);
  var processorTransactionId = mr.processorTransactionId;
  var bfcustPmtSubtype = mr.bfcustPmtSubtype;
  return {
          payee: mr.payee,
          teamUserInitiator: mr.teamUserInitiator,
          processor: processor,
          netRefundedAmount: mr.netRefundedAmount,
          netRefundedBfFeeAmount: mr.netRefundedBfFeeAmount,
          referencePmtPublicId: referencePmtPublicId,
          lifecycleStatus: lifecycleStatus,
          processorTransactionId: Caml_option.some(processorTransactionId),
          pmtMethod: mr.pmtMethod,
          bfcustPmtMethodIdentifier: mr.bfcustPmtMethodIdentifier,
          bfcustPmtSubtype: bfcustPmtSubtype,
          bfcustPmtNote: mr.bfcustPmtNote
        };
}

var convertVariables = DraftUtilsUserQuery_graphql$Thick.Internal.convertVariables;

var convertResponse = DraftUtilsUserQuery_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = DraftUtilsUserQuery_graphql$Thick.Internal.convertWrapRawResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, DraftUtilsUserQuery_graphql$Thick.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, DraftUtilsUserQuery_graphql$Thick.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(DraftUtilsUserQuery_graphql$Thick.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(DraftUtilsUserQuery_graphql$Thick.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(DraftUtilsUserQuery_graphql$Thick.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(DraftUtilsUserQuery_graphql$Thick.node, convertVariables);

var FetchAllBfCustUsers_accountStatus_decode = DraftUtilsUserQuery_graphql$Thick.Utils.accountStatus_decode;

var FetchAllBfCustUsers_accountStatus_fromString = DraftUtilsUserQuery_graphql$Thick.Utils.accountStatus_fromString;

var FetchAllBfCustUsers_billingKind_decode = DraftUtilsUserQuery_graphql$Thick.Utils.billingKind_decode;

var FetchAllBfCustUsers_billingKind_fromString = DraftUtilsUserQuery_graphql$Thick.Utils.billingKind_fromString;

var FetchAllBfCustUsers_bookingSessionPassengerType_decode = DraftUtilsUserQuery_graphql$Thick.Utils.bookingSessionPassengerType_decode;

var FetchAllBfCustUsers_bookingSessionPassengerType_fromString = DraftUtilsUserQuery_graphql$Thick.Utils.bookingSessionPassengerType_fromString;

var FetchAllBfCustUsers_brandfamRole_decode = DraftUtilsUserQuery_graphql$Thick.Utils.brandfamRole_decode;

var FetchAllBfCustUsers_brandfamRole_fromString = DraftUtilsUserQuery_graphql$Thick.Utils.brandfamRole_fromString;

var FetchAllBfCustUsers_custSignonStatus_decode = DraftUtilsUserQuery_graphql$Thick.Utils.custSignonStatus_decode;

var FetchAllBfCustUsers_custSignonStatus_fromString = DraftUtilsUserQuery_graphql$Thick.Utils.custSignonStatus_fromString;

var FetchAllBfCustUsers_docGender_decode = DraftUtilsUserQuery_graphql$Thick.Utils.docGender_decode;

var FetchAllBfCustUsers_docGender_fromString = DraftUtilsUserQuery_graphql$Thick.Utils.docGender_fromString;

var FetchAllBfCustUsers_elevatorProximity_decode = DraftUtilsUserQuery_graphql$Thick.Utils.elevatorProximity_decode;

var FetchAllBfCustUsers_elevatorProximity_fromString = DraftUtilsUserQuery_graphql$Thick.Utils.elevatorProximity_fromString;

var FetchAllBfCustUsers_invitationType_decode = DraftUtilsUserQuery_graphql$Thick.Utils.invitationType_decode;

var FetchAllBfCustUsers_invitationType_fromString = DraftUtilsUserQuery_graphql$Thick.Utils.invitationType_fromString;

var FetchAllBfCustUsers_lifecycleStatus_decode = DraftUtilsUserQuery_graphql$Thick.Utils.lifecycleStatus_decode;

var FetchAllBfCustUsers_lifecycleStatus_fromString = DraftUtilsUserQuery_graphql$Thick.Utils.lifecycleStatus_fromString;

var FetchAllBfCustUsers_longitudinality_decode = DraftUtilsUserQuery_graphql$Thick.Utils.longitudinality_decode;

var FetchAllBfCustUsers_longitudinality_fromString = DraftUtilsUserQuery_graphql$Thick.Utils.longitudinality_fromString;

var FetchAllBfCustUsers_manifestPaxReconciliationStatus_decode = DraftUtilsUserQuery_graphql$Thick.Utils.manifestPaxReconciliationStatus_decode;

var FetchAllBfCustUsers_manifestPaxReconciliationStatus_fromString = DraftUtilsUserQuery_graphql$Thick.Utils.manifestPaxReconciliationStatus_fromString;

var FetchAllBfCustUsers_paxDataStatus_decode = DraftUtilsUserQuery_graphql$Thick.Utils.paxDataStatus_decode;

var FetchAllBfCustUsers_paxDataStatus_fromString = DraftUtilsUserQuery_graphql$Thick.Utils.paxDataStatus_fromString;

var FetchAllBfCustUsers_pmtDirection_decode = DraftUtilsUserQuery_graphql$Thick.Utils.pmtDirection_decode;

var FetchAllBfCustUsers_pmtDirection_fromString = DraftUtilsUserQuery_graphql$Thick.Utils.pmtDirection_fromString;

var FetchAllBfCustUsers_pmtLifecycleStatus_decode = DraftUtilsUserQuery_graphql$Thick.Utils.pmtLifecycleStatus_decode;

var FetchAllBfCustUsers_pmtLifecycleStatus_fromString = DraftUtilsUserQuery_graphql$Thick.Utils.pmtLifecycleStatus_fromString;

var FetchAllBfCustUsers_pmtMethod_decode = DraftUtilsUserQuery_graphql$Thick.Utils.pmtMethod_decode;

var FetchAllBfCustUsers_pmtMethod_fromString = DraftUtilsUserQuery_graphql$Thick.Utils.pmtMethod_fromString;

var FetchAllBfCustUsers_pmtProcessor_decode = DraftUtilsUserQuery_graphql$Thick.Utils.pmtProcessor_decode;

var FetchAllBfCustUsers_pmtProcessor_fromString = DraftUtilsUserQuery_graphql$Thick.Utils.pmtProcessor_fromString;

var FetchAllBfCustUsers_refundability_decode = DraftUtilsUserQuery_graphql$Thick.Utils.refundability_decode;

var FetchAllBfCustUsers_refundability_fromString = DraftUtilsUserQuery_graphql$Thick.Utils.refundability_fromString;

var FetchAllBfCustUsers_signonStatus_decode = DraftUtilsUserQuery_graphql$Thick.Utils.signonStatus_decode;

var FetchAllBfCustUsers_signonStatus_fromString = DraftUtilsUserQuery_graphql$Thick.Utils.signonStatus_fromString;

var FetchAllBfCustUsers_subtotalCategory_decode = DraftUtilsUserQuery_graphql$Thick.Utils.subtotalCategory_decode;

var FetchAllBfCustUsers_subtotalCategory_fromString = DraftUtilsUserQuery_graphql$Thick.Utils.subtotalCategory_fromString;

var FetchAllBfCustUsers_versionProvenance_decode = DraftUtilsUserQuery_graphql$Thick.Utils.versionProvenance_decode;

var FetchAllBfCustUsers_versionProvenance_fromString = DraftUtilsUserQuery_graphql$Thick.Utils.versionProvenance_fromString;

var FetchAllBfCustUsers = {
  accountStatus_decode: FetchAllBfCustUsers_accountStatus_decode,
  accountStatus_fromString: FetchAllBfCustUsers_accountStatus_fromString,
  billingKind_decode: FetchAllBfCustUsers_billingKind_decode,
  billingKind_fromString: FetchAllBfCustUsers_billingKind_fromString,
  bookingSessionPassengerType_decode: FetchAllBfCustUsers_bookingSessionPassengerType_decode,
  bookingSessionPassengerType_fromString: FetchAllBfCustUsers_bookingSessionPassengerType_fromString,
  brandfamRole_decode: FetchAllBfCustUsers_brandfamRole_decode,
  brandfamRole_fromString: FetchAllBfCustUsers_brandfamRole_fromString,
  custSignonStatus_decode: FetchAllBfCustUsers_custSignonStatus_decode,
  custSignonStatus_fromString: FetchAllBfCustUsers_custSignonStatus_fromString,
  docGender_decode: FetchAllBfCustUsers_docGender_decode,
  docGender_fromString: FetchAllBfCustUsers_docGender_fromString,
  elevatorProximity_decode: FetchAllBfCustUsers_elevatorProximity_decode,
  elevatorProximity_fromString: FetchAllBfCustUsers_elevatorProximity_fromString,
  invitationType_decode: FetchAllBfCustUsers_invitationType_decode,
  invitationType_fromString: FetchAllBfCustUsers_invitationType_fromString,
  lifecycleStatus_decode: FetchAllBfCustUsers_lifecycleStatus_decode,
  lifecycleStatus_fromString: FetchAllBfCustUsers_lifecycleStatus_fromString,
  longitudinality_decode: FetchAllBfCustUsers_longitudinality_decode,
  longitudinality_fromString: FetchAllBfCustUsers_longitudinality_fromString,
  manifestPaxReconciliationStatus_decode: FetchAllBfCustUsers_manifestPaxReconciliationStatus_decode,
  manifestPaxReconciliationStatus_fromString: FetchAllBfCustUsers_manifestPaxReconciliationStatus_fromString,
  paxDataStatus_decode: FetchAllBfCustUsers_paxDataStatus_decode,
  paxDataStatus_fromString: FetchAllBfCustUsers_paxDataStatus_fromString,
  pmtDirection_decode: FetchAllBfCustUsers_pmtDirection_decode,
  pmtDirection_fromString: FetchAllBfCustUsers_pmtDirection_fromString,
  pmtLifecycleStatus_decode: FetchAllBfCustUsers_pmtLifecycleStatus_decode,
  pmtLifecycleStatus_fromString: FetchAllBfCustUsers_pmtLifecycleStatus_fromString,
  pmtMethod_decode: FetchAllBfCustUsers_pmtMethod_decode,
  pmtMethod_fromString: FetchAllBfCustUsers_pmtMethod_fromString,
  pmtProcessor_decode: FetchAllBfCustUsers_pmtProcessor_decode,
  pmtProcessor_fromString: FetchAllBfCustUsers_pmtProcessor_fromString,
  refundability_decode: FetchAllBfCustUsers_refundability_decode,
  refundability_fromString: FetchAllBfCustUsers_refundability_fromString,
  signonStatus_decode: FetchAllBfCustUsers_signonStatus_decode,
  signonStatus_fromString: FetchAllBfCustUsers_signonStatus_fromString,
  subtotalCategory_decode: FetchAllBfCustUsers_subtotalCategory_decode,
  subtotalCategory_fromString: FetchAllBfCustUsers_subtotalCategory_fromString,
  versionProvenance_decode: FetchAllBfCustUsers_versionProvenance_decode,
  versionProvenance_fromString: FetchAllBfCustUsers_versionProvenance_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

var emptyPax_voyageOptions = [];

var emptyPax = {
  paxDataStatus: "TBA",
  idx: 0,
  reorderedIdx: 0,
  accountId: undefined,
  email: undefined,
  fullName: undefined,
  firstName: undefined,
  lastName: undefined,
  loyaltyNumber: undefined,
  halMarinerNumber: undefined,
  legalDocumentFirstName: undefined,
  legalDocumentLastName: undefined,
  legalDocumentBirthdate: undefined,
  legalDocumentGender: undefined,
  legalDocumentCitizenship: undefined,
  phone: undefined,
  phoneCountryCode: "+1",
  postalCode: undefined,
  residenceCountry: undefined,
  shouldAttachToIdx: 0,
  invitationEmail: undefined,
  invitationExpiry: undefined,
  invitationExpired: false,
  custodianAccountId: undefined,
  currentTierTitle: undefined,
  numEligibleVoyages: 0,
  cruiselineBookingNumber: undefined,
  paxReconciliationStatus: "UNRECONCILED",
  isNewConnected: false,
  voyageOptions: emptyPax_voyageOptions
};

function generateAvatarInitials(pax) {
  var match = pax.firstName;
  var match$1 = pax.lastName;
  var match$2 = pax.legalDocumentFirstName;
  var match$3 = pax.legalDocumentLastName;
  if (match !== undefined) {
    if (match$1 === undefined) {
      return match[0].toLocaleUpperCase();
    }
    var firstInitial = match[0];
    var lastInitial = match$1[0];
    return (firstInitial + lastInitial).toLocaleUpperCase();
  }
  if (match$1 !== undefined) {
    return match$1[0].toLocaleUpperCase();
  }
  if (match$2 === undefined) {
    return "TBA";
  }
  if (match$3 === undefined) {
    return "TBA";
  }
  var firstInitial$1 = match$2[0];
  var lastInitial$1 = match$3[0];
  return (firstInitial$1 + lastInitial$1).toLocaleUpperCase();
}

function generateInvitedNames(paxInvite) {
  var lastName = paxInvite.lastName;
  if (lastName === undefined) {
    return {
            fullName: CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(paxInvite.firstName),
            firstName: CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(paxInvite.firstName),
            lastName: undefined
          };
  }
  var lastName$1 = Caml_option.valFromOption(lastName);
  return {
          fullName: CS_NonemptyStrings$Util.NonEmptyString.toString(paxInvite.firstName) + " " + CS_NonemptyStrings$Util.NonEmptyString.toString(lastName$1),
          firstName: CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(paxInvite.firstName),
          lastName: CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(lastName$1)
        };
}

function generateNewConnectedNames(pax) {
  var match = pax.preferredFirstName;
  var match$1 = pax.preferredLastName;
  var fullName;
  if (match !== undefined) {
    var fn = Caml_option.valFromOption(match);
    fullName = match$1 !== undefined ? CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(fn) + " " + CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(Caml_option.valFromOption(match$1)) : CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(fn);
  } else {
    fullName = match$1 !== undefined ? CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(Caml_option.valFromOption(match$1)) : CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(pax.docFirstName) + " " + CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(pax.docLastName);
  }
  return {
          fullName: fullName,
          firstName: Belt_Option.map(pax.preferredFirstName, (function (fn) {
                  return CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(fn);
                })),
          lastName: Belt_Option.map(pax.preferredLastName, (function (ln) {
                  return CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(ln);
                })),
          legalDocumentFirstName: CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(pax.docFirstName),
          legalDocumentLastName: CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(pax.docLastName),
          legalDocumentBirthdate: Caml_option.some(pax.docBirthdate),
          legalDocumentGender: pax.docGender,
          legalDocumentCitizenship: Caml_option.some(pax.docCitizenship),
          halMarinerNumber: pax.halMarinerNumber,
          custodianAccountId: Caml_option.some(pax.custodianAccountId)
        };
}

function getExistingUsers(diff, brandFamily) {
  var match = React.useState(function () {
        
      });
  var setToAttachOrReplaceExisting = match[1];
  React.useEffect((function () {
          var match = diff.attachExistingPax;
          var match$1 = diff.replacePaxExisting;
          var exit = 0;
          if (match !== undefined || match$1 !== undefined) {
            exit = 1;
          } else {
            setToAttachOrReplaceExisting(function (param) {
                  
                });
          }
          if (exit === 1) {
            var attachExistingMap = Belt_Option.getWithDefault(diff.attachExistingPax, undefined);
            var replaceExistingMap = Belt_Option.getWithDefault(diff.replacePaxExisting, undefined);
            var fullMap = Belt_MapInt.fromArray(Belt_Array.concat(Belt_MapInt.toArray(attachExistingMap), Belt_MapInt.toArray(replaceExistingMap)));
            var acctIds = Belt_Array.concat(Belt_MapInt.valuesToArray(attachExistingMap), Belt_MapInt.valuesToArray(replaceExistingMap));
            var acctIdToIdx = Belt_MapString.fromArray(Belt_Array.map(Belt_MapInt.toArray(fullMap), (function (param) {
                        return [
                                CS_NonemptyStrings$Util.AccountId.toString(param[1]),
                                param[0]
                              ];
                      })));
            var filter_accountId = {
              in: acctIds
            };
            var filter = {
              accountId: filter_accountId
            };
            $$fetch(RelayEnv$Thick.environment, {
                  brandFamily: brandFamily,
                  filter: filter
                }, (function (res) {
                    if (res.TAG === "Ok") {
                      var match = res._0.bfcustUsers;
                      if (match !== undefined) {
                        var idMap = Belt_MapString.fromArray(Belt_Array.map(match.nodes, (function (node) {
                                    var idx = Belt_MapString.getExn(acctIdToIdx, CS_NonemptyStrings$Util.AccountId.toString(node.accountId));
                                    var email = node.email;
                                    var name = node.lastName;
                                    var hmn = node.halMarinerNumber;
                                    var name$1 = node.legalDocumentFirstName;
                                    var name$2 = node.legalDocumentLastName;
                                    var email$1 = node.invitationEmail;
                                    var expiry = node.invitationExpiry;
                                    var tmp;
                                    if (expiry !== undefined) {
                                      var expiry$1 = Caml_option.valFromOption(expiry);
                                      tmp = CS_Make$Util.Dateable.Plain.isInThePast(expiry$1) ? "Invitation has expired! Please resend or revoke." : "Invitation expires on " + CS_Make$Util.Dateable.Plain.format(expiry$1, "MM/dd/yyyy") + " at " + CS_Make$Util.Dateable.Plain.format(expiry$1, "hh:mm b") + ".";
                                    } else {
                                      tmp = undefined;
                                    }
                                    var expiry$2 = node.invitationExpiry;
                                    var ls = node.loyalty_status;
                                    var tmp$1;
                                    if (ls !== undefined) {
                                      var ctt = ls.currentTierTitle;
                                      tmp$1 = ctt !== undefined ? CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(ctt)) : undefined;
                                    } else {
                                      tmp$1 = undefined;
                                    }
                                    var ls$1 = node.loyalty_status;
                                    var n_paxDataStatus = Belt_MapInt.has(attachExistingMap, idx) ? ({
                                          TAG: "Draft",
                                          _0: "attach"
                                        }) : ({
                                          TAG: "Draft",
                                          _0: "replace"
                                        });
                                    var n_accountId = Caml_option.some(node.accountId);
                                    var n_email = email !== undefined ? CS_Emails$Util.Email.toString(Caml_option.valFromOption(email)) : undefined;
                                    var n_fullName = node.fullName;
                                    var n_firstName = CS_NonemptyStrings$Util.NonEmptyString.toString(node.firstName);
                                    var n_lastName = name !== undefined ? CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(name)) : undefined;
                                    var n_loyaltyNumber = CS_LoyaltyAndCruiselineNumbers$Util.LoyaltyNumber.toString(node.loyaltyNumber);
                                    var n_halMarinerNumber = hmn !== undefined ? CS_LoyaltyAndCruiselineNumbers$Util.HalMarinerNumber.toString(Caml_option.valFromOption(hmn)) : undefined;
                                    var n_legalDocumentFirstName = name$1 !== undefined ? CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(name$1)) : undefined;
                                    var n_legalDocumentLastName = name$2 !== undefined ? CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(name$2)) : undefined;
                                    var n_legalDocumentBirthdate = node.legalDocumentBirthdate;
                                    var n_legalDocumentGender = node.legalDocumentGender;
                                    var n_legalDocumentCitizenship = node.legalDocumentCitizenship;
                                    var n_phone = Belt_Option.flatMap(node.phone, (function (v) {
                                            return CS_Phone$Util.Phone.getNumber(CS_Phone$Util.Phone.toPrimitive(v));
                                          }));
                                    var n_phoneCountryCode = Belt_Option.getWithDefault(Belt_Option.flatMap(node.phone, (function (p) {
                                                return CS_Phone$Util.Phone.meta(p).callingCode;
                                              })), "+1");
                                    var n_postalCode = Belt_Option.map(node.postalCode, CS_NonemptyStrings$Util.PostalCode.toString);
                                    var n_residenceCountry = node.residenceCountry;
                                    var n_invitationEmail = email$1 !== undefined ? CS_Emails$Util.Email.toPrimitive(Caml_option.valFromOption(email$1)) : undefined;
                                    var n_invitationExpired = expiry$2 !== undefined && CS_Make$Util.Dateable.Plain.isInThePast(Caml_option.valFromOption(expiry$2)) ? true : false;
                                    var n_custodianAccountId = node.custodianAccountId;
                                    var n_numEligibleVoyages = ls$1 !== undefined ? ls$1.numEligibleVoyages : 0;
                                    var n_voyageOptions = VoyageDetails$Thick.mapFromDraftUtils(node.passenger_record, undefined, undefined);
                                    var n = {
                                      paxDataStatus: n_paxDataStatus,
                                      idx: idx,
                                      reorderedIdx: idx,
                                      accountId: n_accountId,
                                      email: n_email,
                                      fullName: n_fullName,
                                      firstName: n_firstName,
                                      lastName: n_lastName,
                                      loyaltyNumber: n_loyaltyNumber,
                                      halMarinerNumber: n_halMarinerNumber,
                                      legalDocumentFirstName: n_legalDocumentFirstName,
                                      legalDocumentLastName: n_legalDocumentLastName,
                                      legalDocumentBirthdate: n_legalDocumentBirthdate,
                                      legalDocumentGender: n_legalDocumentGender,
                                      legalDocumentCitizenship: n_legalDocumentCitizenship,
                                      phone: n_phone,
                                      phoneCountryCode: n_phoneCountryCode,
                                      postalCode: n_postalCode,
                                      residenceCountry: n_residenceCountry,
                                      shouldAttachToIdx: idx,
                                      invitationEmail: n_invitationEmail,
                                      invitationExpiry: tmp,
                                      invitationExpired: n_invitationExpired,
                                      custodianAccountId: n_custodianAccountId,
                                      currentTierTitle: tmp$1,
                                      numEligibleVoyages: n_numEligibleVoyages,
                                      cruiselineBookingNumber: undefined,
                                      paxReconciliationStatus: "UNRECONCILED",
                                      isNewConnected: false,
                                      voyageOptions: n_voyageOptions
                                    };
                                    return [
                                            Belt_Option.mapWithDefault(n_accountId, String(idx), CS_NonemptyStrings$Util.AccountId.toString),
                                            n
                                          ];
                                  })));
                        setToAttachOrReplaceExisting(function (param) {
                              return Caml_option.some(idMap);
                            });
                      }
                      return ;
                    }
                    console.error(res._0);
                  }), undefined, undefined);
          }
          
        }), [
        JSON.stringify(diff.attachExistingPax),
        JSON.stringify(diff.replacePaxExisting)
      ]);
  return match[0];
}

function calcNewIdx(oldIdx, reorderArr) {
  if (reorderArr === undefined) {
    return oldIdx;
  }
  var $$new = Belt_Array.getIndexBy(reorderArr, (function (x) {
          return x === oldIdx;
        }));
  if ($$new !== undefined) {
    return $$new + 1 | 0;
  } else {
    return oldIdx + Belt_Array.reduce(reorderArr, 0, (function (acc, x) {
                  if (x > oldIdx) {
                    return 1 + acc | 0;
                  } else {
                    return acc;
                  }
                })) | 0;
  }
}

function paxify(dataPaxes, diff) {
  return Belt_Array.map(dataPaxes, (function (node) {
                var match = node.bfcustUser;
                var match$1 = node.bfcustUser;
                var tmp;
                if (match$1 !== undefined) {
                  var email = match$1.email;
                  tmp = email !== undefined ? CS_Emails$Util.Email.toString(Caml_option.valFromOption(email)) : undefined;
                } else {
                  tmp = undefined;
                }
                var match$2 = node.bfcustUser;
                var match$3 = node.bfcustUser;
                var match$4 = node.bfcustUser;
                var tmp$1;
                if (match$4 !== undefined) {
                  var name = match$4.lastName;
                  tmp$1 = name !== undefined ? CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(name)) : undefined;
                } else {
                  tmp$1 = undefined;
                }
                var match$5 = node.bfcustUser;
                var match$6 = node.bfcustUser;
                var tmp$2;
                if (match$6 !== undefined) {
                  var halMarinerNumber = match$6.halMarinerNumber;
                  tmp$2 = halMarinerNumber !== undefined ? CS_LoyaltyAndCruiselineNumbers$Util.HalMarinerNumber.toString(Caml_option.valFromOption(halMarinerNumber)) : undefined;
                } else {
                  tmp$2 = undefined;
                }
                var name$1 = node.docFirstName;
                var name$2 = node.docLastName;
                var docBirthdate = node.docBirthdate;
                var docGender = node.docGender;
                var docCitizenship = node.docCitizenship;
                var match$7 = node.bfcustUser;
                var match$8 = node.bfcustUser;
                var match$9 = node.bfcustUser;
                var match$10 = node.bfcustUser;
                var match$11 = node.bfcustUser;
                var tmp$3;
                if (match$11 !== undefined) {
                  var email$1 = match$11.invitationEmail;
                  tmp$3 = email$1 !== undefined ? CS_Emails$Util.Email.toString(Caml_option.valFromOption(email$1)) : undefined;
                } else {
                  tmp$3 = undefined;
                }
                var match$12 = node.bfcustUser;
                var tmp$4;
                if (match$12 !== undefined) {
                  var expiry = match$12.invitationExpiry;
                  if (expiry !== undefined) {
                    var expiry$1 = Caml_option.valFromOption(expiry);
                    tmp$4 = CS_Make$Util.Dateable.Plain.isInThePast(expiry$1) ? "Invitation has expired! Please resend or revoke." : "Invitation expires on " + CS_Make$Util.Dateable.Plain.format(expiry$1, "MM/dd/yyyy") + " at " + CS_Make$Util.Dateable.Plain.format(expiry$1, "hh:mm b") + ".";
                  } else {
                    tmp$4 = undefined;
                  }
                } else {
                  tmp$4 = undefined;
                }
                var match$13 = node.bfcustUser;
                var tmp$5;
                if (match$13 !== undefined) {
                  var expiry$2 = match$13.invitationExpiry;
                  tmp$5 = expiry$2 !== undefined && CS_Make$Util.Dateable.Plain.isInThePast(Caml_option.valFromOption(expiry$2)) ? true : false;
                } else {
                  tmp$5 = false;
                }
                var match$14 = node.bfcustUser;
                var bfcu = node.bfcustUser;
                var tmp$6;
                if (bfcu !== undefined) {
                  var ls = bfcu.loyalty_status;
                  if (ls !== undefined) {
                    var ctt = ls.currentTierTitle;
                    tmp$6 = ctt !== undefined ? CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(ctt)) : undefined;
                  } else {
                    tmp$6 = undefined;
                  }
                } else {
                  tmp$6 = undefined;
                }
                var bfcu$1 = node.bfcustUser;
                var tmp$7;
                if (bfcu$1 !== undefined) {
                  var ls$1 = bfcu$1.loyalty_status;
                  tmp$7 = ls$1 !== undefined ? ls$1.numEligibleVoyages : 0;
                } else {
                  tmp$7 = 0;
                }
                var cruiselineBookingNumber = node.cruiselineBookingNumber;
                var match$15 = node.bfcustUser;
                return {
                        paxDataStatus: {
                          TAG: "Status",
                          _0: node.paxDataStatus
                        },
                        idx: node.idx,
                        reorderedIdx: calcNewIdx(node.idx, diff.reindexPax),
                        accountId: match !== undefined ? Caml_option.some(match.accountId) : undefined,
                        email: tmp,
                        fullName: match$2 !== undefined ? match$2.fullName : undefined,
                        firstName: match$3 !== undefined ? CS_NonemptyStrings$Util.NonEmptyString.toString(match$3.firstName) : undefined,
                        lastName: tmp$1,
                        loyaltyNumber: match$5 !== undefined ? CS_LoyaltyAndCruiselineNumbers$Util.LoyaltyNumber.toString(match$5.loyaltyNumber) : undefined,
                        halMarinerNumber: tmp$2,
                        legalDocumentFirstName: name$1 !== undefined ? CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(name$1)) : undefined,
                        legalDocumentLastName: name$2 !== undefined ? CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(name$2)) : undefined,
                        legalDocumentBirthdate: docBirthdate !== undefined ? Caml_option.some(Caml_option.valFromOption(docBirthdate)) : undefined,
                        legalDocumentGender: docGender !== undefined ? docGender : undefined,
                        legalDocumentCitizenship: docCitizenship !== undefined ? Caml_option.some(Caml_option.valFromOption(docCitizenship)) : undefined,
                        phone: match$7 !== undefined ? Belt_Option.flatMap(match$7.phone, (function (v) {
                                  return CS_Phone$Util.Phone.getNumber(CS_Phone$Util.Phone.toPrimitive(v));
                                })) : undefined,
                        phoneCountryCode: match$8 !== undefined ? Belt_Option.getWithDefault(Belt_Option.flatMap(match$8.phone, (function (p) {
                                      return CS_Phone$Util.Phone.meta(p).callingCode;
                                    })), "+1") : "+1",
                        postalCode: match$9 !== undefined ? Belt_Option.map(match$9.postalCode, CS_NonemptyStrings$Util.PostalCode.toString) : undefined,
                        residenceCountry: match$10 !== undefined ? match$10.residenceCountry : undefined,
                        shouldAttachToIdx: node.idx,
                        invitationEmail: tmp$3,
                        invitationExpiry: tmp$4,
                        invitationExpired: tmp$5,
                        custodianAccountId: match$14 !== undefined ? match$14.custodianAccountId : undefined,
                        currentTierTitle: tmp$6,
                        numEligibleVoyages: tmp$7,
                        cruiselineBookingNumber: cruiselineBookingNumber !== undefined ? CS_NonemptyStrings$Util.CruiselineBookingNumber.toString(Caml_option.valFromOption(cruiselineBookingNumber)) : undefined,
                        paxReconciliationStatus: Common$Thick.PaxReconciliationStatus.fromInput(node.reconciliationStatus),
                        isNewConnected: false,
                        voyageOptions: match$15 !== undefined ? VoyageDetails$Thick.mapFromBDPage(match$15.passenger_record, undefined, undefined) : []
                      };
              }));
}

function replaceDropDetachOrReturn(paxes, diff, toAttachOrReplaceExisting) {
  var existingPaxMap = Belt_Option.getWithDefault(toAttachOrReplaceExisting, undefined);
  return Belt_Array.keep(Belt_Array.keep(Belt_Array.keep(Belt_Array.map(Belt_Array.map(Belt_Array.map(paxes, (function (pax) {
                                    var invited = Belt_MapInt.get(Belt_Option.getWithDefault(diff.replaceInvitedPax, undefined), pax.idx);
                                    if (invited === undefined) {
                                      return pax;
                                    }
                                    var match = generateInvitedNames(invited);
                                    var newrecord = Caml_obj.obj_dup(emptyPax);
                                    newrecord.shouldAttachToIdx = pax.idx;
                                    newrecord.lastName = match.lastName;
                                    newrecord.firstName = match.firstName;
                                    newrecord.fullName = match.fullName;
                                    newrecord.email = CS_Emails$Util.Email.toString(invited.email);
                                    newrecord.reorderedIdx = calcNewIdx(pax.idx, diff.reindexPax);
                                    newrecord.idx = pax.idx;
                                    newrecord.paxDataStatus = {
                                      TAG: "Draft",
                                      _0: "invite"
                                    };
                                    return newrecord;
                                  })), (function (pax) {
                                var replaceNew = Belt_MapInt.get(Belt_Option.getWithDefault(diff.replaceNewConnectedPax, undefined), pax.idx);
                                if (replaceNew === undefined) {
                                  return pax;
                                }
                                var match = generateNewConnectedNames(replaceNew);
                                var newrecord = Caml_obj.obj_dup(emptyPax);
                                newrecord.isNewConnected = true;
                                newrecord.custodianAccountId = match.custodianAccountId;
                                newrecord.shouldAttachToIdx = pax.idx;
                                newrecord.legalDocumentCitizenship = match.legalDocumentCitizenship;
                                newrecord.legalDocumentGender = match.legalDocumentGender;
                                newrecord.legalDocumentBirthdate = match.legalDocumentBirthdate;
                                newrecord.legalDocumentLastName = match.legalDocumentLastName;
                                newrecord.legalDocumentFirstName = match.legalDocumentFirstName;
                                newrecord.halMarinerNumber = Belt_Option.map(match.halMarinerNumber, CS_LoyaltyAndCruiselineNumbers$Util.HalMarinerNumber.toString);
                                newrecord.lastName = match.lastName;
                                newrecord.firstName = match.firstName;
                                newrecord.fullName = match.fullName;
                                newrecord.reorderedIdx = calcNewIdx(pax.idx, diff.reindexPax);
                                newrecord.idx = pax.idx;
                                newrecord.paxDataStatus = {
                                  TAG: "Draft",
                                  _0: "replace"
                                };
                                return newrecord;
                              })), (function (pax) {
                            var accountId = Belt_MapInt.get(Belt_Option.getWithDefault(diff.replacePaxExisting, undefined), pax.idx);
                            if (accountId === undefined) {
                              return pax;
                            }
                            var passenger = Belt_MapString.get(existingPaxMap, CS_NonemptyStrings$Util.AccountId.toString(Caml_option.valFromOption(accountId)));
                            if (passenger !== undefined) {
                              return passenger;
                            } else {
                              return pax;
                            }
                          })), (function (pax) {
                        var match = diff.dropBerths;
                        var match$1 = pax.idx;
                        if (match !== undefined) {
                          return !Belt_Array.some(match, (function (b) {
                                        return b === match$1;
                                      }));
                        } else {
                          return true;
                        }
                      })), (function (pax) {
                    var match = diff.detachPax;
                    var match$1 = pax.idx;
                    if (match !== undefined) {
                      return !Belt_Array.some(match, (function (b) {
                                    return b === match$1;
                                  }));
                    } else {
                      return true;
                    }
                  })), (function (pax) {
                var match = diff.revokePaxInvitation;
                var match$1 = pax.paxDataStatus;
                var match$2 = pax.idx;
                if (match === undefined) {
                  return true;
                }
                if (typeof match$1 !== "object") {
                  return true;
                }
                if (match$1.TAG !== "Status") {
                  return true;
                }
                var tmp = match$1._0;
                if ((tmp === "INCOMPLETE" || tmp === "CONFIRMED" || tmp === "INVITED") && tmp === "INVITED") {
                  return !Belt_MapInt.has(Caml_option.valFromOption(match), match$2);
                } else {
                  return true;
                }
              }));
}

function attachOrReturn(paxes, diff, toAttachOrReplaceExisting) {
  var existingPaxMap = Belt_Option.getWithDefault(toAttachOrReplaceExisting, undefined);
  return Belt_Array.map(Belt_Array.map(Belt_Array.map(paxes, (function (pax) {
                        var invited = Belt_MapInt.get(Belt_Option.getWithDefault(diff.attachInvitedPax, undefined), pax.idx);
                        if (invited === undefined) {
                          return pax;
                        }
                        var match = generateInvitedNames(invited);
                        var newrecord = Caml_obj.obj_dup(emptyPax);
                        newrecord.shouldAttachToIdx = pax.idx;
                        newrecord.lastName = match.lastName;
                        newrecord.firstName = match.firstName;
                        newrecord.fullName = match.fullName;
                        newrecord.email = CS_Emails$Util.Email.toString(invited.email);
                        newrecord.reorderedIdx = calcNewIdx(pax.idx, diff.reindexPax);
                        newrecord.idx = pax.idx;
                        newrecord.paxDataStatus = {
                          TAG: "Draft",
                          _0: "invite"
                        };
                        return newrecord;
                      })), (function (pax) {
                    var paxNewConnected = Belt_MapInt.get(Belt_Option.getWithDefault(diff.attachNewConnectedPax, undefined), pax.idx);
                    if (paxNewConnected === undefined) {
                      return pax;
                    }
                    var match = generateNewConnectedNames(paxNewConnected);
                    var newrecord = Caml_obj.obj_dup(emptyPax);
                    newrecord.isNewConnected = true;
                    newrecord.custodianAccountId = match.custodianAccountId;
                    newrecord.shouldAttachToIdx = pax.idx;
                    newrecord.legalDocumentCitizenship = match.legalDocumentCitizenship;
                    newrecord.legalDocumentGender = match.legalDocumentGender;
                    newrecord.legalDocumentBirthdate = match.legalDocumentBirthdate;
                    newrecord.legalDocumentLastName = match.legalDocumentLastName;
                    newrecord.legalDocumentFirstName = match.legalDocumentFirstName;
                    newrecord.halMarinerNumber = Belt_Option.map(match.halMarinerNumber, CS_LoyaltyAndCruiselineNumbers$Util.HalMarinerNumber.toString);
                    newrecord.lastName = match.lastName;
                    newrecord.firstName = match.firstName;
                    newrecord.fullName = match.fullName;
                    newrecord.reorderedIdx = calcNewIdx(pax.idx, diff.reindexPax);
                    newrecord.idx = pax.idx;
                    newrecord.paxDataStatus = {
                      TAG: "Draft",
                      _0: "attach"
                    };
                    return newrecord;
                  })), (function (pax) {
                var accountId = Belt_MapInt.get(Belt_Option.getWithDefault(diff.attachExistingPax, undefined), pax.idx);
                if (accountId === undefined) {
                  return pax;
                }
                var passenger = Belt_MapString.get(existingPaxMap, CS_NonemptyStrings$Util.AccountId.toString(Caml_option.valFromOption(accountId)));
                if (passenger !== undefined) {
                  return passenger;
                } else {
                  return pax;
                }
              }));
}

function patchPaxes(paxes, diff) {
  return Belt_Array.map(paxes, (function (pax) {
                var reco = Belt_MapInt.get(Belt_Option.getWithDefault(diff.patchPax, undefined), pax.idx);
                if (reco === undefined) {
                  return pax;
                }
                var newrecord = Caml_obj.obj_dup(pax);
                newrecord.legalDocumentCitizenship = pax.legalDocumentCitizenship === reco.pDocCitizenship ? pax.legalDocumentCitizenship : reco.pDocCitizenship;
                newrecord.legalDocumentGender = pax.legalDocumentGender === reco.pDocGender ? pax.legalDocumentGender : reco.pDocGender;
                newrecord.legalDocumentBirthdate = pax.legalDocumentBirthdate === reco.pDocBirthdate ? pax.legalDocumentBirthdate : reco.pDocBirthdate;
                newrecord.legalDocumentLastName = pax.legalDocumentLastName === Belt_Option.map(reco.pDocLastName, CS_NonemptyStrings$Util.NonEmptyString.toString) ? pax.legalDocumentLastName : Belt_Option.map(reco.pDocLastName, CS_NonemptyStrings$Util.NonEmptyString.toString);
                newrecord.legalDocumentFirstName = Caml_obj.equal(pax.legalDocumentFirstName, Belt_Option.map(reco.pDocFirstName, CS_NonemptyStrings$Util.NonEmptyString.toString)) ? pax.legalDocumentFirstName : Belt_Option.map(reco.pDocFirstName, CS_NonemptyStrings$Util.NonEmptyString.toString);
                return newrecord;
              }));
}

function createTBAs(attachIndex, paxCount, numBerths, diff) {
  var _i = attachIndex;
  var _paxCount = paxCount;
  var _acc = [];
  while(true) {
    var acc = _acc;
    var paxCount$1 = _paxCount;
    var i = _i;
    if (paxCount$1 >= numBerths) {
      return acc;
    }
    var newrecord = Caml_obj.obj_dup(emptyPax);
    _acc = Belt_Array.concat(acc, [(newrecord.shouldAttachToIdx = attachIndex, newrecord.reorderedIdx = calcNewIdx(i, diff.reindexPax), newrecord.idx = i, newrecord)]);
    _paxCount = paxCount$1 + 1 | 0;
    _i = i + 1 | 0;
    continue ;
  };
}

function addToDraft(diff, versionUrl) {
  var searchStr = BookingDiff$Thick.serialize(diff);
  var url = searchStr === "" ? versionUrl : versionUrl + "?" + searchStr;
  RescriptReactRouter.push(url);
}

exports.Pax = Pax;
exports.postMushIdxMap = postMushIdxMap;
exports.makeBookingDiffInput = makeBookingDiffInput;
exports.previewCallback = previewCallback;
exports.canceledBookingManualRefundInput = canceledBookingManualRefundInput;
exports.FetchAllBfCustUsers = FetchAllBfCustUsers;
exports.emptyPax = emptyPax;
exports.generateAvatarInitials = generateAvatarInitials;
exports.generateInvitedNames = generateInvitedNames;
exports.generateNewConnectedNames = generateNewConnectedNames;
exports.getExistingUsers = getExistingUsers;
exports.calcNewIdx = calcNewIdx;
exports.paxify = paxify;
exports.replaceDropDetachOrReturn = replaceDropDetachOrReturn;
exports.attachOrReturn = attachOrReturn;
exports.patchPaxes = patchPaxes;
exports.createTBAs = createTBAs;
exports.addToDraft = addToDraft;
/* use Not a pure module */
