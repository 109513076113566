// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var HopperState$Util = require("util/src/HopperState.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var MergeAccounts$Thick = require("../../utils/MergeAccounts.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");

function AccountDetailFetcher(props) {
  var view = props.view;
  var match = HopperState$Util.Observable.useComputed(view, (function (v) {
          return [
                  v.source.value,
                  v.sourceAcct,
                  v.dest.value,
                  v.destAcct
                ];
        }), undefined, undefined);
  var destAcct = match[3];
  var dest = match[2];
  var sourceAcct = match[1];
  var source = match[0];
  var setSrc = function (s) {
    HopperState$Util.Observable.notify(view, (function (v) {
            return {
                    source: v.source,
                    dest: v.dest,
                    url: v.url,
                    sourceAcct: s,
                    destAcct: v.destAcct,
                    mergeability: v.mergeability,
                    mergedStatus: v.mergedStatus,
                    mergeModal: v.mergeModal
                  };
          }));
  };
  var setDest = function (d) {
    HopperState$Util.Observable.notify(view, (function (v) {
            return {
                    source: v.source,
                    dest: v.dest,
                    url: v.url,
                    sourceAcct: v.sourceAcct,
                    destAcct: d,
                    mergeability: v.mergeability,
                    mergedStatus: v.mergedStatus,
                    mergeModal: v.mergeModal
                  };
          }));
  };
  var match$1 = MergeAccounts$Thick.useFetchOne(props.brandFamily);
  var fetchOne = match$1.fetchOne;
  var error = match$1.error;
  React.useEffect((function () {
          var match = CS_NonemptyStrings$Util.AccountId.fromPrimitive(source);
          if (match.TAG === "Ok") {
            var source$1 = match._0;
            if (sourceAcct !== undefined && !Caml_obj.notequal(sourceAcct.accountId, source$1)) {
              
            } else {
              fetchOne(source$1, setSrc);
            }
          }
          
        }), [source]);
  React.useEffect((function () {
          var match = CS_NonemptyStrings$Util.AccountId.fromPrimitive(dest);
          if (match.TAG === "Ok") {
            var dest$1 = match._0;
            if (destAcct !== undefined && !Caml_obj.notequal(destAcct.accountId, dest$1)) {
              
            } else {
              fetchOne(dest$1, setDest);
            }
          }
          
        }), [dest]);
  if (error !== undefined) {
    return JsxRuntime.jsx(Typography, {
                color: "danger",
                level: "body-sm",
                children: Caml_option.some(error)
              });
  } else {
    return null;
  }
}

var make = AccountDetailFetcher;

exports.make = make;
/* react Not a pure module */
