import * as jquery from 'jquery';
// look idk... sorry?
window.$ = jquery;

/*
  NOTES ABOUT THIS FILE:

  This is some of the oldest code in the JoCo Booking Engine codebase.
  It was originally written at the booth at Pax in 2014 (I think). It has been
  altered to fit in the old engine as it changed and into this one.

  This requires jquery and operates on bulk SVGs with a draw cycle that
  doesn't make sense anymore. It barely needs jquery, but it uses it for
  event binding and stuff which would take some research to slot into plain js.

  It would be better to remove the jquery dependency and alter this file
  to do more straightforward drawing on a single SVG and to bind to those
  functions and control the rendering more explicitly with clearer objects etc
  from Rescript.

  What this file does do it draw the map correctly and
  find the SVG elements and act on them, which is not always so easy. For SVG
  manipulation I would not recommend trying to do it all natively in rescript,
  there is a lot of annoying binding that would need to happen. That said,
  javascript code could return the SVG dom elements as some custom type which
  could be acted upon in rescript and could clean this up significantly.

  In the end, once fully adjusted, I believe there are like 50 lines of SVG
  manipulation code necessary for rescript to control this process and render
  a nice cabin map.
*/

// https://parceljs.org/languages/javascript/#url-dependencies
// the first arg is a string relative to THIS file
export const nadmDeckUrls = {
  1: new URL("./../static/thick/deckmap/Nieuw-Deck-01-R5.svg", import.meta.url),
  4: new URL("./../static/thick/deckmap/Nieuw-Deck-04-R5.svg", import.meta.url),
  5: new URL("./../static/thick/deckmap/Nieuw-Deck-05-R5.svg", import.meta.url),
  6: new URL("./../static/thick/deckmap/Nieuw-Deck-06-R5.svg", import.meta.url),
  7: new URL("./../static/thick/deckmap/Nieuw-Deck-07-R5.svg", import.meta.url),
  8: new URL("./../static/thick/deckmap/Nieuw-Deck-08-R5.svg", import.meta.url),
  10: new URL("./../static/thick/deckmap/Nieuw-Deck-10-R5.svg", import.meta.url),
  11: new URL("./../static/thick/deckmap/Nieuw-Deck-11-R5.svg", import.meta.url)
}


// global variables
const svgns = "http://www.w3.org/2000/svg";
const cabinpat = /^c_(\d{4,5})\s*$/;
const showHidePredicateName = "Inventory Segment";
let listmaker = {};
let curHoverCab = null;
let showHideColor = { name: 'default hardcoded color', hex: "#C9CAD9" }
// functions
function insertAfter(referenceNode, newNode) {
  referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
}

function a_wrapper_id(cabNo) {
  return 'a_' + cabNo;
}

function text_box_id(cabNo) {
  return 't_' + cabNo;
}

// map to every svg unless the lambda returns something, in which
// case return that thing (used for search across the svgs)
function for_each_svg(lambda) {
  let svgs = document.querySelectorAll('object.shipdeck')
  //console.log(svgs)
  for (let i = 0; i < svgs.length; i++) {
    // pass the parent container as the second argument... this container
    // is needed to get the relative position of the svg in the larger document
    // as the contentDocument inside is sorta blind to its parent
    let rv = lambda(svgs[i].contentDocument.querySelector('svg'), svgs[i]);
    //console.log(svgs[i].contentDocument.querySelector('svg'))
    if (rv) {
      return rv;
    }
  }
  return undefined;
}

function get_element_multi(id) {
  return for_each_svg(function (svg) {
    return svg.getElementById(id);
  });
}

function cabin_scalefactor(cabin) {
  //return scalefactormap[$(cabin).closest('svg').attr('id')];
  return 1;
}

function colorCabin(cabin, strokeHex, fillHex, width) {
  width = width / cabin_scalefactor(cabin);
  strokeHex && cabin.setAttribute('stroke', '#' + strokeHex);
  fillHex && cabin.setAttribute('fill', '#' + fillHex);
  width && cabin.setAttribute('stroke-width', width);
}


function printlist() {
  let str = 'THE LIST OF CABINS IS: \n\n';
  $.each(listmaker, function (key, value) {
    console.log(key, value);

    str = str + "\n" + key;
  });
  console.log(str);
}

// cabInfo may not have info for this cabin!
function initCabin(cabin, cabNo, cabInfo, showHideColor) {
  // wrap cabins in allotment with a tag
  let wrapper = document.createElementNS(svgns, 'a');
  wrapper.setAttributeNS(null, "id", a_wrapper_id(cabNo));
  wrapper.setAttributeNS(null, "class", "cabHoverInfo");
  //wrapper.setAttributeNS(null, "title", cabInfo['hoverHTML']);
  let clonedCabin = cabin.cloneNode(true);
  wrapper.appendChild(clonedCabin);
  cabin.parentNode.replaceChild(wrapper, cabin);
  cabin = clonedCabin;

  // create SVG text element naming this cabin
  let tbox = document.createElementNS(svgns, "text");
  tbox.setAttributeNS(null, "id", text_box_id(cabNo));
  tbox.setAttributeNS(null, "font-family", "courier");
  tbox.setAttributeNS(null, "font-size", Math.ceil(8 / cabin_scalefactor(cabin)));

  let origx = parseFloat(cabin.getAttribute("x"));
  let origy = parseFloat(cabin.getAttribute("y"));
  let origw = parseFloat(cabin.getAttribute("width"));
  let origh = parseFloat(cabin.getAttribute("height"));

  if (origw >= origh) {
    // draw text horizontally
    tbox.setAttributeNS(null, "x", origx + 2);
    tbox.setAttributeNS(null, "y", origy + origh - 3);
    tbox.setAttributeNS(null, "width", origw - 4);
    tbox.setAttributeNS(null, "height", origh - 4);
  } else {
    // draw text vertically
    // these settings somewhat 'divined'
    let rx = origx + 4;
    let ry = origy + 2;
    tbox.setAttributeNS(null, "transform", `rotate(90, ${rx}, ${ry})`);
    tbox.setAttributeNS(null, "x", rx);
    tbox.setAttributeNS(null, "y", ry);
    tbox.setAttributeNS(null, "width", origh);
    tbox.setAttributeNS(null, "height", origw);
  }
  insertAfter(cabin, tbox);

  /*   let countspan = $('.countpcs[title="' + showHideColor.name + '"]');
    countspan.text(parseInt(countspan.text()) + 1);
   */
  return cabin;
}



export function draw(cabins, cabinBgColorPalette, borderPredicatesMap, hideTheseCabins) {
  // get svg reference and rects that could be cabins; process each rect
  let rects = [];
  // this returns an HTML collection so we convert to an array
  // http://stackoverflow.com/questions/222841/most-efficient-way-to-convert-an-htmlcollection-to-an-array
  for_each_svg(function (svg) {
    //console.log(svg)
    Array.prototype.push.apply(rects, Array.from(svg.getElementsByTagName('rect')));
  });

  //console.log(rects)
  //return;

  for (let i = 0; i < rects.length; i++) {
    // if is a cabin rect
    let m = rects[i].id.match(cabinpat);
    if (!m) {
      continue;
    }

    let cabin = rects[i];
    let wasInitted = $(cabin).parents('.cabHoverInfo').length > 0
    let cabNo = m[1];
    let cabText = m[1];

    // grey outline with white background default cabin color
    colorCabin(cabin, '777', 'fff', 1);

    let cabInfo = cabins[cabNo];
    if (cabInfo) {
      showHideColor = cabinBgColorPalette[cabInfo['predicates'][showHidePredicateName]];
      cabText += ' ' + cabInfo['cabinClass'];
    }
    if (!wasInitted) {
      // init any cabins we should
      cabin = initCabin(cabin, cabNo, cabInfo, showHideColor);
    }

    // delete any prior text for this cabin
    let tbox = get_element_multi(text_box_id(cabNo));
    if (tbox && tbox.hasChildNodes()) {
      while (tbox.childNodes.length >= 1) {
        tbox.removeChild(tbox.firstChild);
      }
    }

    // do we need to color any border predicates?
    // start with a blank map
    /*     borderPredicatesMap = jQuery.extend({}, borderPredicatesMapBlank);
        $('.predicate-select').each(function () {
          borderPredicatesMap[$(this).find(':selected').val()] = $(this).data('color');
        });
     */

    let paintThis = false;
    // in the group days we wouldn't show a number on the
    // cabins not in our allotment
    let showAllCabNos = true;//!!$('#showAllCabNos:checked').length;
    // paint things the color we are supposed to
    if (cabInfo) {
      //paintThis = !$('.invsegcheckbox:checked[name="' + cabInfo['predicates'][showHidePredicateName] + '"]').length;
      paintThis = !!!hideTheseCabins.find(e => e == cabInfo['predicates'][showHidePredicateName])
      paintThis && colorCabin(cabin, false, showHideColor.hex);
      $.each(cabInfo.predicates, function (k, v) {
        if (v && borderPredicatesMap[k]) {
          colorCabin(cabin, borderPredicatesMap[k], false, 4);
        }
      });
    }

    if (paintThis || showAllCabNos) {
      tbox && tbox.appendChild(document.createTextNode(cabText));
    }


  }

  /*   $('.countpcs').each(function () {
      if (!parseInt($(this).text())) {
        $(this).parent().hide();
      }
    });
   */
}


// ttStatusCallback = tooltipStatusCallback
// returns info needed by the app to show its tooltips
export function init(
  cabins, cabinBgColorPalette, borderPredicatesMap,
  hideTheseCabins, ttStatusCallback, setXSetYIsSwapOpenDoSwapTuple
) {
  // initialize the map
  draw(cabins, cabinBgColorPalette, borderPredicatesMap, hideTheseCabins);

  $($('object.shipdeck').contents()).children('svg').find('.cabHoverInfo').unbind('hover');
  // watch for hovering...
  $($('object.shipdeck').contents()).children('svg').find('.cabHoverInfo').hover(function () {
    curHoverCab = cabins[$(this).attr('id').toString().substring(2)];

    // go thru the SVGs, find the one with this cabin
    // use the second parameter to the for_each_svg_lambda
    // which will let us access something which has an offset
    let { top: ta, left: la } = for_each_svg((svg, parent) => {
      //console.log($(this).attr('id').toString());
      if ($(svg).find("#" + $(this).attr('id').toString()).length > 0) {
        return $(parent).offset()
      }
    })

    let { top: tb, left: lb } = $(this).offset();

    ttStatusCallback(curHoverCab ? [curHoverCab, { top: ta + tb, left: la + lb }] : undefined)
  }, function () {
    curHoverCab = null;
    ttStatusCallback(undefined)
  });

  let [setCabinX, setCabinY, isSwapOpen, doSwapAction] = setXSetYIsSwapOpenDoSwapTuple

  let keypressPredicates = [
    { "name": "Open Modbooking", "keypresses": ["M", "m"], "type": "redirect" },
    {
      name: 'Assign Cabin X',
      type: 'complex',
      keypresses: ['x', 'q'],
      action: function () {
        setCabinX(_ => curHoverCab.cabinNumber)
      }
    },
    {
      name: 'Assign Cabin Y',
      type: 'complex',
      keypresses: ['y', 'w'],
      action: function () {
        setCabinY(_ => curHoverCab.cabinNumber)
      }
    },
    {
      name: '*Do* Cabin Swap X / Y',
      type: 'independent',
      keypresses: ['D'],
      action: function () {
        doSwapAction();
      }
    },
    {
      name: 'Cabin to List Maker',
      type: 'complex',
      keypresses: ['t'],
      action: function () {
        if (curHoverCab !== null) {
          listmaker[curHoverCab['cabinNumber']] = true;
        }
      }
    }
  ];


  $(document).unbind("keypress")
  $(document).keypress(function (event) {
    $.each(keypressPredicates, function (idx, predicate) {
      $.each(predicate.keypresses, function (idx, char) {
        char = char.charCodeAt(0);
        // if swap drawer is open... we do not want to act on
        // any of these!!
        if (!isSwapOpen() && event.which == char) {
          if (curHoverCab) {
            switch (predicate.type) {

              case 'redirect':
                window.open(curHoverCab.predicates[predicate.name]);
                break;

              case 'complex':
                predicate.action();
                break;

              default:
                break;
            }
          }

          // the cabin swap thing isn't related to a hover cab
          switch (predicate.type) {
            case 'independent':
              predicate.action();
              break;

            default:
              break;
          }


        }
      });
    });
  });

  $.each(keypressPredicates, function () {
    $(`.kbsc_pred[data-name="${this.name}"]`).remove();
    $('#kbsc').append(`<span class="kbsc_pred" data-name="${this.name}" style="display: block;"><strong>${this.name}:</strong> ${this.keypresses.join(' OR ')}</span>`)
  });
}


// debug stuff
window['cmap'] = {
  draw: draw,
  init: init,
}
window.printlist = printlist;